import React, {Component, Fragment} from 'react';
import AudioAnalyser from './AudioAnalyser';
// import PropTypes from 'prop-types';
import styled from 'styled-components'
import {Button, OutlinedButton} from "../../../ui/Button";

import ls from 'local-storage'

export default class Microphone extends Component {
    state = {
        audio: null,
        audioSelect: null,
        listAudios: [],
        valueAudio: ''
    };
    audioinput = [];
    elementRef = new React.createRef();
    enumerateDevices = new Promise(resolve => {
        resolve(navigator.mediaDevices.enumerateDevices());
    });

    constructor(props) {
        super(props);
        this.toggleMicrophone = this.toggleMicrophone.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        console.log('Camera: handleChange: event = ', event);
        console.log('Camera: handleChange: event.target.value = ', event.target.value);
        window.camMicId = event.target.value;
        if (window.camMicId != undefined) {
            ls('sh_cam_mic_id', window.camMicId);
        }
        const constraints = {
            audio: {
                deviceId: event.target.value ? {exact: event.target.value} : undefined
            },
            video: false
        };

        this.props.changeKindId(false);

        this.setState({
            audioSelect: navigator.mediaDevices.getUserMedia(constraints),
            value: event.target.value,
            audio: false
        }, () => {
            this.getMicrophone();
        });
    }

    componentDidMount() {
        this.enumerateDevices
            .then(devices => {
                devices.map(device => {
                    if (device.kind == 'audioinput') {
                        this.audioinput.push({
                            id: device.deviceId,
                            kind: device.kind,
                            label: device.label
                        });
                    }
                });
                this.setState(prevState => ({
                    listAudios: [...prevState.listAudios, this.audioinput]
                }));
            })
            .catch(function (err) {
                console.log(`${err.name}: ${err.message}`);
            });
    }

    getMicrophone() {
        if (this.state.audioSelect) {
            this.props.changeKindId(this.state.value);
            this.setState({
                audio: this.state.audioSelect
            });
        }
    }

    stopMicrophone() {
        this.state.audio.then(audio => {
            audio.getTracks().forEach(track => track.stop());
        });
        this.props.changeKindId(false);
        this.setState({audio: null});
    }

    toggleMicrophone() {
        if (this.state.audio) {
            this.stopMicrophone();
        } else {
            this.getMicrophone();
        }
    }

    listMicrophones() {
        const listMicrophone = this.state.listAudios[0];
        return (
            <select
                disabled={this.state.audio !== null}
                value={this.state.value}
                onChange={this.handleChange}
            >
                <option value="">Выбрать</option>
                {listMicrophone.map(item => (
                    <option key={item.id} value={item.id}>
                        {item.label}
                    </option>
                ))}
            </select>
        );
    }

    render() {
        // const { text } = this.props;
        return (
            <Wrapper>
                <div>
                    <form action="javascript:void(0);">
                        <fieldset>
                            <label htmlFor="microphone" style={{marginRight: 10}}>Выберите микрофон</label>
                            <br/>
                            {this.state.listAudios.length > 0 && this.listMicrophones()}
                        </fieldset>
                    </form>

                    {this.state.value == undefined ? null :
                        <OutlinedButton
                            onClick={this.toggleMicrophone}
                        >
                            {this.state.audio ? 'Выключить микрофон' : 'Проверить свой микрофон'}
                        </OutlinedButton>
                    }

                </div>
                {this.state.audio ? <AudioAnalyser audio={this.state.audio}/> : ''}
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    background: whitesmoke;
`;
