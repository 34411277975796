import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import * as lessonsActions from "../../../redux/actions/LessonsActions";
import * as teacherGroupsActions from "../../../redux/actions/TeacherGroupsActions";
import * as usersActions from "../../../redux/actions/UsersActions";
import {API_ENDPOINT} from "../../../constants/config";
import NiceModal from "../../modals/NiceModal";
import NiceModalWithHeading from "../../modals/NiceModalWithHeading";
import ChessHelper from "../../../helpers/ChessHelper";
import Workspace from "../../rubius/exercise/Workspace/Workspace";
import IllegalWorkspace from "../../rubius/exercise/Workspace/IllegalWorkspace";

import {PgnViewerWrapper, Chessboard} from "../../rubius";

const getSortedListByName = arr => {
    return arr.sort((a, b) => {
        let s1 = (a == undefined || a.name == undefined) ? '' : a.name.toLowerCase();
        let s2 = (b == undefined || b.name == undefined) ? '' : b.name.toLowerCase();
        if (s1 > s2) {
            return 1;
        }
        if (s1 < s2) {
            return -1;
        }
        return 0;
    });
}

export default function LessonExercisesResultsPanel(props) {
    const {id} = props;
    let {currentUser, loading, lesson, groupsMap, uId, teacherGroup, students, exercises} = useMappedState(useCallback(state => {
        let uId = state.users.currentUserId;
        let lesson = state.lessons.lessonsMap.get(id);
        let teacherGroup = (lesson == undefined) ? undefined : state.teacherGroups.teacherGroupsMap.get(lesson.teacherGroupId);
        let exercisesIds = (lesson == undefined || lesson.exercisesIds == undefined) ? [] : lesson.exercisesIds;

        let students = (teacherGroup == undefined || teacherGroup.studentsIds == undefined) ? [] : teacherGroup.studentsIds.map(x => state.users.usersMap.get(x)).filter(a => (a != undefined));

        return {
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            loading: state.lessons.loading,
            lesson: state.lessons.lessonsMap.get(id),
            uId: uId,
            teacherGroup: teacherGroup,
            students: students,
            exercises: getSortedListByName(exercisesIds.map(exId => state.smartExercises.smartExercisesMap.get(exId)).filter(a => (a != undefined)))
        }
    }, [id]));
    const dispatch = useDispatch();

    const [answers, setAnswers] = useState([]);
    const [selectedAnswer, setSelectedAnswer] = useState(undefined);
    const [isPreviewingExercise, setIsPreviewingExercise] = useState(false);


    useEffect(() => {
        dispatch(lessonsActions.loadAllLessons()); // todo: optimize
        dispatch(teacherGroupsActions.loadAllTeacherGroupsOfTeacher(uId));
        dispatch(usersActions.loadAllUsers());
        axios.get(`${API_ENDPOINT}/lesson/${id}/users_answers_exercises`).then(d => d.data.result).then(arr => {
            setAnswers(arr);
        });
    }, [uId]);

    // console.log('--->>> exercises = ', exercises);
    // console.log('--->>> answers = ', answers);
    // console.log('selectedAnswer = ', selectedAnswer);
    let selectedExercise = (selectedAnswer == undefined) ? undefined : exercises.filter(ex => (ex.id == selectedAnswer.exerciseId))[0];
    let selectedStudent = (selectedAnswer == undefined) ? undefined : students.filter(ex => (ex.id == selectedAnswer.userId))[0];

    console.log('selectedAnswer = ', selectedAnswer);
    console.log('selectedExercise = ', selectedExercise);

    let selectedSolutions = (selectedExercise == undefined || selectedExercise.solutions == undefined) ? [] : selectedExercise.solutions;

    // let gStudents = students.concat(students).concat(students).concat(students).concat(students).concat(students).concat(students).concat(students);
    let gStudents = students;

    return (
        <Wrapper>

            <InnerWrapper>

                <TopTableHeading>
                    <NameCol>
                        Задание
                    </NameCol>
                    {gStudents.map((st, i) => {
                        return (
                            <StudentCol key={st.id}>
                                {`${st.firstName} ${st.lastName}`}
                            </StudentCol>
                        )
                    })}
                </TopTableHeading>

                {exercises.map((ex, i) => {
                    let {solutions = []} = ex;
                    return (
                        <TaskRow key={ex.id}>
                            <NameCol>
                                {ex.name}
                            </NameCol>
                            {gStudents.map((st, i) => {
                                let ansArr = answers.filter(a => (a.userId == st.id && a.exerciseId == ex.id));
                                let ans = (ansArr.length == 0) ? undefined : ansArr[0];
                                let subResultsObject = (ans == undefined) ? {} : (ans.subResultsObject == undefined ? {} : ans.subResultsObject);
                                let winsNumber = solutions.map(sol => subResultsObject[sol.id]).filter(x => (x != undefined)).filter(a => (a.assessment == 'win')).length;
                                let losesNumber = solutions.map(sol => subResultsObject[sol.id]).filter(x => (x != undefined)).filter(a => (a.assessment == 'lose')).length;
                                let drawNumber = solutions.map(sol => subResultsObject[sol.id]).filter(x => (x != undefined)).filter(a => (a.assessment == 'draw')).length;

                                return (
                                    <StudentCol key={`${st.id}_${i}`} onClick={() => {
                                        if (ans != undefined) {
                                            setSelectedAnswer(ans);
                                        }
                                    }}>
                                        {ans == undefined ? '-' :
                                            <NormSpan>
                                                {winsNumber == 0 ? null :
                                                    <WLSpan style={{color: 'green'}}>{`+${winsNumber}`}</WLSpan>
                                                }
                                                {losesNumber == 0 ? null :
                                                    <WLSpan style={{color: 'red'}}>{`-${losesNumber}`}</WLSpan>
                                                }
                                                {drawNumber == 0 ? null :
                                                    <WLSpan style={{color: 'lightgrey'}}>{`[${drawNumber}]`}</WLSpan>
                                                }
                                                {' / '}
                                                <WLSpan style={{color: 'grey'}}>
                                                    {solutions.length}
                                                </WLSpan>
                                            </NormSpan>
                                        }
                                    </StudentCol>
                                )
                            })}

                        </TaskRow>
                    )
                })}

                {(selectedAnswer == undefined || selectedExercise == undefined || selectedStudent == undefined) ? null :
                    <NiceModalWithHeading
                        width={620}
                        heading={
                            <div style={{cursor: 'pointer'}} onClick={() => {
                                setIsPreviewingExercise(true);
                            }}>
                                {`${selectedExercise.name}`}
                            </div>
                        }
                        subHeading={`${selectedStudent.firstName} ${selectedStudent.lastName}`}
                        onClose={() => {
                            setSelectedAnswer(undefined);
                        }}>

                        <SelectedAnswerPlaceholder>
                            {selectedSolutions.map((sol, i) => {
                                let subResultsObject = (selectedAnswer == undefined) ? {} : (selectedAnswer.subResultsObject == undefined ? {} : selectedAnswer.subResultsObject);
                                let d = subResultsObject[sol.id];
                                let isWin = (d != undefined && d.assessment == 'win');
                                let isLose = (d != undefined && d.assessment == 'lose');
                                let isDraw = (d != undefined && d.assessment == 'draw');
                                let isSkip = (d == undefined);
                                let color = (isWin == true) ? 'green' : (isLose == true ? 'red' : (isDraw == true ? 'lightgrey' : 'grey'));
                                let label = (isWin == true) ? 'пройдено' : (isLose == true ? 'ошибка' : (isDraw == true ? 'не лучший ход' : 'пропущено'));
                                let winVariant = sol.variants.filter(x => (x.type == 'win'))[0];

                                return (
                                    <SelaSol key={sol.id}>
                                        {winVariant == undefined ? null :
                                            <SelaSolBoardImg src={ChessHelper.getFenImageUrl(winVariant.fen)}/>
                                        }
                                        <div style={{color: color, width: 120, textAlign: 'left'}}>
                                            {label}
                                        </div>
                                    </SelaSol>
                                )
                            })}
                        </SelectedAnswerPlaceholder>

                    </NiceModalWithHeading>
                }


                {(selectedAnswer == undefined || selectedExercise == undefined || selectedStudent == undefined || isPreviewingExercise == false) ? null :
                    <NiceModalWithHeading
                        width={920}
                        heading={`${selectedExercise.name}`}
                        subHeading={`${selectedStudent.firstName} ${selectedStudent.lastName}`}
                        onClose={() => {
                            setIsPreviewingExercise(false);
                        }}>

                        <SelectedViewInner>

                            <PgnViewerWrapper
                                boardWidthFraction={0.5}
                                pgn={selectedExercise.pgn}>
                                <Chessboard/>
                            </PgnViewerWrapper>

                            {/*{JSON.stringify(selectedExercise)}*/}
                        </SelectedViewInner>


                    </NiceModalWithHeading>
                }


            </InnerWrapper>

        </Wrapper>
    );
}

const SelectedViewInner = styled.div`
    width: 100%;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
`;

const SelaSol = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom: 1px solid lightgrey;
    :last-of-type{
      border-bottom: none;
    }
`;

const SelaSolBoardImg = styled.img`
    width: 160px;
    height: 160px;
    margin-right: 20px;
`;

const SelectedAnswerPlaceholder = styled.div`
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const NormSpan = styled.span`
    cursor: pointer;
    opacity: 0.9;
    :hover{
      opacity: 1;
    }
`;

const WLSpan = styled.span`
    margin-left: 3px;
    margin-right: 3px;
`;

const Wrapper = styled.div`
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
    position: relative;
`;

const InnerWrapper = styled.div`
    background: white;
`;

const StudentCol = styled.div`
    min-width: 200px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-right: 1px solid rgba(255,255,255,0.41);
`;

const NameCol = styled.div`
    width: 280px;
    min-width: 280px;
    box-sizing: border-box;
    padding-left: 31px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-right: 1px solid rgba(255,255,255,0.41);
`;

const TaskRow = styled.div`
    padding-top: 27px;
    padding-bottom: 27px;
    border-bottom: 0.4px solid rgba(81, 139, 219, 0.4);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 31px;
    box-sizing: border-box;
    background: ${props => (props.selected != true ? 'white' : 'linear-gradient(0deg, #EDF8FF, #EDF8FF), #FFFFFF;')};
`;


const TopTableHeading = styled.div`
    background: #FFA71B;
    border-radius: 4px;
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #202224;
    box-sizing: border-box;
    padding-right: 31px;
`;
