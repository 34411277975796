import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import TwoPlayersPlayTool from "../tools/TwoPlayersPlayTool";

import * as usersActions from '../../../redux/actions/UsersActions'

export default function TwoPlayersGamePanel(props) {
    const {roomId = ''} = props;
    let {currentUser, currentUserId} = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        return {
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            currentUserId: state.users.currentUserId
        }
    }, [roomId]));
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(usersActions.loadUserById(currentUserId))
    }, [roomId]);

    if (currentUser == undefined) {
        return (
            <div>
                пожалуйста, авторизайтесь
            </div>
        )
    }

    return (
        <Wrapper>
            <TwoPlayersPlayTool roomId={roomId} currentUser={currentUser}/>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;
