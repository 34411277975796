import React from 'react';
import styled from 'styled-components';
import {Input} from '../../ui/Input';
import {Textarea} from '../../ui/Textarea';
import {SOLUTIONS_TYPES_OPTIONS, SOLUTIONS_TAGS_OPTIONS} from '../const';
import Select from 'react-select'
import {Button} from '@blueprintjs/core'

import ls from 'local-storage'
import {translate} from "../../../../utils/Translate";

export const FIELDS = {
    TYPE: 'type',
    NAME: 'name',
    DESCRIPTION: 'description',
    TAGS: 'tags',
    RATING: 'rating'
}

export default function SolutionForm(props) {
    let {
        name = '',
        description = '',
        rating = 0,
        tags = [],
        variant = {},
        onDelete = id => id,
        onSave = e => e,
        onChange = e => e
    } = props;

    return (
        <Wrapper>
            <Row>
                <Half>
                    <Field>
                        <Label>{translate('rating')}</Label>

                        <Input value={rating}
                               style={{height: 39}}
                               onChange={evt => {
                                   let x = +evt.target.value.trim();
                                   onChange({
                                       id: variant.id,
                                       name: FIELDS.RATING,
                                       value: x
                                   });
                                   ls('editor-new-sub-solution', x);
                               }}/>
                    </Field>
                </Half>

                <Half>
                    <Field>
                        <Label>{'Тип'}</Label>

                        <Select options={SOLUTIONS_TYPES_OPTIONS}
                                isMulti={false}
                                isSearchable={false}
                                isClearable={false}
                                value={SOLUTIONS_TYPES_OPTIONS.find(t => t.value === variant.type)}
                                onChange={a => onChange({
                                    id: variant.id,
                                    name: FIELDS.TYPE,
                                    value: a.value
                                })}/>
                    </Field>
                </Half>
            </Row>

            <Field>
                <Label>{'Название'}</Label>

                <Input value={name}
                       placeholder={''}
                       onChange={evt => onChange({
                           id: variant.id,
                           name: FIELDS.NAME,
                           value: evt.target.value
                       })}/>
            </Field>

            <Field>
                <Label>{'Описание'}</Label>

                <Textarea value={description}
                          style={{height: '4em', minHeight: '2em'}}
                          onChange={evt => onChange({
                              id: variant.id,
                              name: FIELDS.DESCRIPTION,
                              value: evt.target.value
                          })}/>
            </Field>

            <Field>
                <Label>{'Теги'}</Label>

                <Select options={SOLUTIONS_TAGS_OPTIONS}
                        isMulti={true}
                        isSearchable={false}
                        isClearable={false}
                        placeholder={'Выберите тег'}
                        value={SOLUTIONS_TAGS_OPTIONS.filter(t => tags.find(tag => tag === t.value))}
                        onChange={a => onChange({
                            id: variant.id,
                            name: FIELDS.TAGS,
                            value: a.map(m => m.value)
                        })}/>
            </Field>

            <BottomField>
                <Button minimal={true}
                        text={translate('save')}
                        onClick={e => {
                            e.stopPropagation();
                            onSave(e);
                        }}/>

                <DeleteSpan onClick={() => onDelete(variant.id)}>{'Удалить'}</DeleteSpan>
            </BottomField>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100%;
`;

const Field = styled.div`
    margin-bottom: 2px;
`;

const Label = styled.div`
    font-weight: bold;
    font-size: 10px;
`;

const Row = styled.div``;

const Half = styled.div`
    box-sizing: border-box;
    width: 50%;
    display: inline-block;
    vertical-align: top;
    :first-of-type{
      padding-right: 5px;
    }
    :last-of-type{
      padding-left: 5px;
    }
`;

const DeleteSpan = styled.span`
    cursor: pointer;
    color: red;
    opacity: 0.5;
    font-size: 12px;
    :hover{
      opacity: 1;
    }
`;

const BottomField = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
`;
