import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import SimpleInteractiveCommutePanel from "./SimpleInteractiveCommutePanel";
import CommonHelper from "../../../helpers/CommonHelper";

import * as lessonsActions from '../../../redux/actions/LessonsActions'
import * as teacherGroupsActions from '../../../redux/actions/TeacherGroupsActions'
import * as usersActions from '../../../redux/actions/UsersActions'

import ls from 'local-storage'

import {Button} from "../../ui/Button";

import WebRtcTestComponent from '../../webrtc/checker'
import FirebaseAPI from "../../../api/FirebaseAPI";
import {API_ENDPOINT} from "../../../constants/config";

import NchanAPI from '../../../api/NchanAPI'
import LoginApp from "../../apps/LoginApp";
import BrandedLoginApp from "../../apps/BrandedLoginApp";
import IraLoginApp from "../../apps/IraLoginApp";
import JitsiAccessOverlayPanel from "./JitsiAccessOverlayPanel";
import {translate} from "../../../utils/Translate";

export default function LessonCommutePanel(props) {
    const {id, allowGuests = false} = props;
    let {
        currentUser, loading, lesson, groupStudents, teacherGroup,
        exercisesIds,
        students, isOrganizer, units, exercises
    } = useMappedState(useCallback(state => {
        let lesson = state.lessons.lessonsMap.get(id);
        let currentUser = (allowGuests == true) ? {id: 'guest'} : state.users.usersMap.get(state.users.currentUserId);

        let isTeacher = (currentUser != undefined && currentUser.userRole == 'teacher');
        let teacherGroupId = (lesson == undefined) ? undefined : lesson.teacherGroupId;
        let teacherGroup = state.teacherGroups.teacherGroupsMap.toArray().filter(g => (g.id == teacherGroupId))[0];
        let studentsIds = (teacherGroup == undefined || teacherGroup.studentsIds == undefined) ? [] : teacherGroup.studentsIds;
        let isOrganizer = (teacherGroup != undefined && teacherGroup.teacherId == state.users.currentUserId) || (isTeacher == true);
        let unitsIds = (lesson == undefined || lesson.unitsIds == undefined) ? [] : lesson.unitsIds;
        let exercisesIds = (lesson == undefined || lesson.exercisesIds == undefined) ? [] : lesson.exercisesIds;
        let units = unitsIds.map(a => state.courses.unitsMap.get(a)).filter(a => (a != undefined));
        let smartExercises = exercisesIds.map(xId => state.smartExercises.smartExercisesMap.get(xId)).filter(x => (x != undefined)).sort((a, b) => {
            if (a.name > b.name) {
                return 1;
            }
            if (a.name < b.name) {
                return -1;
            }
            return 0;
        });
        return {
            currentUser: currentUser,
            loading: state.lessons.loading || state.users.loading,
            lesson: lesson,
            teacherGroup: teacherGroup,
            isOrganizer: isOrganizer,
            students: studentsIds.map(a => (state.users.usersMap.get(a))).filter(a => (a != undefined)),
            unitsIds: unitsIds,
            units: units,
            exercises: smartExercises,
            exercisesIds: exercisesIds
        }
    }, [id]));
    const dispatch = useDispatch();
    const [ready, setReady] = useState(false);
    const [roomRealtimeState, setRoomRealtimeState] = useState({});

    const [isJitsiReady, setIsJitsiReady] = useState(false);

    useEffect(() => {
        dispatch(lessonsActions.loadLessonById(id)).then(pld => {
            console.log('--->>> then pld = ', pld);
            if (teacherGroup == undefined && pld.lessons != undefined && pld.lessons.length > 0) {
                console.log('loadAllTeacherGroupsOfTeacher:');
                dispatch(teacherGroupsActions.loadTeacherGroupById(pld.lessons[0].teacherGroupId)).then(pld2 => {
                    let {teacherGroups} = pld2;
                    if (teacherGroups.length > 0) {
                        let g = teacherGroups[0];
                        console.log('groups loaded! g = ', g);
                        let {studentsIds} = g;
                        dispatch(usersActions.loadUsersByIds(studentsIds));
                    }
                });
            }
        });
    }, [id]);

    useEffect(() => {
        let key = `onl_not_vis_${id}`;
        let alreadyVisited = (ls(key) == 'yes');
        if (alreadyVisited == true) {
            return;
        }
        ls(key, 'yes');
        axios.post(`${API_ENDPOINT}/observing/stats/signal`, {
            type: 'JOIN_ONLINE_ROOM',
            lessonId: id
        }).then(pld => {

        });
    }, [id]);

    useEffect(() => {
        NchanAPI.getDefaultChannelMessage(id).then(x => {
            let dd = (x == undefined) ? {} : x;
            setRoomRealtimeState(dd);
            NchanAPI.subscribe(id, (msg) => {
                let x = {};
                try {
                    x = JSON.parse(msg);
                    console.log('-->> x = ', x);
                } catch (exc) {
                }
                setRoomRealtimeState(JSON.parse(JSON.stringify(x)));
            });
        });
        return () => {
            try {
                NchanAPI.unsubscribe(id);
            } catch (exc) {

            }
        }
    }, [id]);

    if (currentUser == undefined && allowGuests == false) {
        return (
            <IraLoginApp/>
        )
    }

    console.log('lesson, teacherGroup, currentUser = ', lesson, teacherGroup, currentUser);

    if (lesson == undefined || teacherGroup == undefined || currentUser == undefined) {
        return (
            <Code/>
        )
    }
    console.log('render: lesson = ', lesson);
    console.log('render: exercisesIds = ', exercisesIds);
    console.log('render: teacherGroup = ', teacherGroup);
    let lessonName = (lesson.name == undefined || lesson.name.trim() == '') ? `${translate('lesson')} ${moment(lesson.startTimestamp).format('DD.MM.YYYY HH:mm')}` : lesson.name;
    lessonName = `${teacherGroup.name} - ${lessonName}`;

    let realReady = (ready || ((lesson != undefined) && (lesson.communicationTool == 'jitsi')))

    return (
        <Wrapper>

            {/*<JitsiAccessOverlayPanel*/}
            {/*    user={currentUser}*/}
            {/*    lessonId={id}*/}
            {/*    onReady={() => {*/}
            {/*        setIsJitsiReady(true);*/}
            {/*    }}*/}
            {/*/>*/}

            {realReady && <SimpleInteractiveCommutePanel
                isHeadless={allowGuests}
                isOrganizer={isOrganizer}
                currentUser={currentUser}
                name={lessonName}
                roomId={`lesson_${id}`}
                participants={students}
                hasVideo={true}
                units={units}
                exercises={exercises}
                currentUserId={currentUser.id}
                {...lesson}
                communicationTool={(lesson == undefined || lesson.communicationTool == undefined) ? 'jitsi' : lesson.communicationTool}
                lessonId={lesson.id}
                onBack={() => {

                }}
                onPairAdd={async (d, onAdded = () => {
                }) => {
                    let oldPairs = (lesson.pairs == undefined ? [] : lesson.pairs);
                    let newPairs = oldPairs.concat([d]);
                    let roomId = `board_two_l_${id}_w_${d.whiteUserId}_b_${d.blackUserId}`;
                    await dispatch(lessonsActions.updateLesson({pairs: newPairs, id: id}));
                    await FirebaseAPI.sendFenToSimpleBoard(roomId, d.fen);
                    onAdded();
                }}
                onPairsChange={async newPairs => {
                    await dispatch(lessonsActions.updateLesson({pairs: newPairs, id: id}));
                }}
                onSettingsOpen={async () => {
                    await dispatch(lessonsActions.loadLessonById(id));
                }}
            />}

            {!realReady &&
            (<Overlay>
                <Inner>
                    <TopInner>

                        <TopTopInnerHeading>
                            {`Группа "${teacherGroup.name}". ${translate('lesson')} "${lesson.name}"`}
                        </TopTopInnerHeading>

                        <TopTopInnerHeading style={{marginBottom: 40}}>
                            <Button onClick={() => {
                                setReady(true);
                            }}>
                                {translate('go_to_lesson')}
                            </Button>
                        </TopTopInnerHeading>

                        <TopInnerHeading>
                            {translate('connection_checking')}
                        </TopInnerHeading>

                        <InnerContent>
                            <WebRtcTestComponent/>
                        </InnerContent>


                    </TopInner>
                </Inner>
            </Overlay>)
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const Overlay = styled.div`
  position: fixed;
  z-index: 20;
  background: white;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Inner = styled.div`
  width: 720px;
  margin: 0 auto;
  box-sizing: border-box;
  height: 70vh;
  max-height: 70vh;
  overflow-y: auto;
  @media (max-width: 720px) {
    width: 100%;
  }
`;

const TopInner = styled.div`

`;

const TopTopInnerHeading = styled.div`
  text-align: left;
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LogoImg = styled.img`
  height: 32px;
  margin-right: 20px;
`;

const TopInnerHeading = styled.div`
  text-align: left;
  font-weight: bold;
`;

const InnerContent = styled.div`

`;

