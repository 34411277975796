import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const AnswersAPI = {

    getAllAnswers() {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/user_exercise_answers/all`).then(d => d.data).then(user_exercise_answers => {
                if (user_exercise_answers.error != undefined) {
                    return reject(user_exercise_answers.error);
                }
                resolve(user_exercise_answers);
            }).catch(err => reject(err));
        });
    },

    getAnswer(id) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/user_exercise_answer/${id}`).then(d => d.data).then(user_exercise_answers => {
                if (user_exercise_answers.error != undefined) {
                    return reject(user_exercise_answers.error);
                }
                resolve(user_exercise_answers);
            }).catch(err => reject(err));
        });
    },

    getLazyAnswerByUserIdAndExerciseId(userId, exerciseId) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/user_exercise_answer/${userId}/${exerciseId}/lazy`).then(d => d.data).then(user_exercise_answer => {
                if (user_exercise_answer.error != undefined) {
                    return reject(user_exercise_answer.error);
                }
                resolve(user_exercise_answer);
            }).catch(err => reject(err));
        });
    },


    createAnswer(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/user_exercise_answers/create`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    updateAnswer(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/user_exercise_answers/update`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    deleteAnswer(id) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/user_exercise_answers/delete/${id}`).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

}

export default AnswersAPI;
