import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import {Code} from 'react-content-loader'

import {Button} from "../../ui/Button";
import {DARK_SIDE, BROWN} from "../../ui/Colors";


import * as actions from '../../../redux/actions/CoursesActions'

import UpdateUnitPanel from './UpdateUnitPanel'
import CommonHelper from "../../../helpers/CommonHelper";

export default function CourseUnitsPanel(props) {
    const {courseId, unitsExpanded = true} = props;
    const [selectedId, setSelectedId] = useState(undefined);

    const {units, loading, userRole, isAdmin} = useMappedState(useCallback(state => {
        let units = state.courses.unitsMap.toArray()
            .filter(u => (u.courseId == courseId))
            .map(a => (a.number == undefined ? {...a, number: a.timestamp} : a))
            .sort((a, b) => (+a.number - +b.number));
        return {
            units: units,
            loading: state.courses.loading,
            userRole: CommonHelper.getCurrentUserRole(state),
            isAdmin: ('admin' == CommonHelper.getCurrentUserRole(state) || 'methodist' == CommonHelper.getCurrentUserRole(state))
        }
    }, [courseId]));
    const dispatch = useDispatch();
    let selectedUnit = (selectedId == undefined) ? undefined : units.filter(u => (u.id == selectedId))[0];
    useEffect(() => {
        dispatch(actions.loadCourseUnits(courseId));
    }, [courseId]);

    if (units.length == 0 && loading == true) {
        return (
            <Code/>
        )
    }
    if (units.length == 0) {
        return (
            <div>
                {isAdmin == false ? null :
                    <Button onClick={() => {
                        dispatch(actions.createUnit({
                            courseId: courseId,
                            name: `Новый модуль ${moment().format('HH:mm:ss')}`,
                            description: `Без описания`
                        })).then(pld => setSelectedId(pld.unit.id));
                    }}>
                        Добавить первый модуль
                    </Button>
                }
            </div>
        )
    }


    return (
        <Wrapper>

            <Left expanded={unitsExpanded}>
                <UnitsList>
                    {units.map((u, k) => {
                        let {name, description, exercisesIds = []} = u;
                        let isSelected = (selectedId == u.id);
                        return (
                            <UnitItemOuter key={u.id}>
                                <UnitItem
                                    canEdit={isAdmin}
                                    selected={isSelected} onClick={() => {
                                    setSelectedId(u.id);
                                }}>
                                    {`${name}`}
                                    {exercisesIds.length == 0 ? null :
                                        <span style={{marginLeft: 4, background: 'lightgrey', borderRadius: 4, padding: 4, paddingLeft: 8, paddingRight: 8}}>
                                            {`${exercisesIds.length} 🔥 `}
                                        </span>
                                    }

                                </UnitItem>
                                {isAdmin == false ? null :
                                    <UnitItemControlsPlaceholder>
                                        {k == 0 ? null :
                                            <ArrowImg
                                                style={{transform: `rotate(180deg)`}}
                                                src={require('../../../assets/images/arrow-point-to-down.svg')}
                                                onClick={async () => {
                                                    let i1 = k - 1, i2 = k;
                                                    let id1 = units[i1].id;
                                                    let id2 = units[i2].id;
                                                    let number1 = units[i2].number;
                                                    let number2 = units[i1].number;
                                                    dispatch(actions.updateUnit({id: id1, number: number1}));
                                                    dispatch(actions.updateUnit({id: id2, number: number2}));
                                                }}
                                            />
                                        }
                                        {k == units.length - 1 ? null :
                                            <ArrowImg
                                                src={require('../../../assets/images/arrow-point-to-down.svg')}
                                                onClick={async () => {
                                                    let i1 = k, i2 = k + 1;
                                                    let id1 = units[i1].id;
                                                    let id2 = units[i2].id;
                                                    let number1 = units[i2].number;
                                                    let number2 = units[i1].number;
                                                    dispatch(actions.updateUnit({id: id1, number: number1}));
                                                    dispatch(actions.updateUnit({id: id2, number: number2}));
                                                }}
                                            />
                                        }
                                    </UnitItemControlsPlaceholder>
                                }
                            </UnitItemOuter>
                        )
                    })}
                </UnitsList>

                {isAdmin == false ? null :
                    <BottomPlaceholder>
                        <Button onClick={() => {
                            dispatch(actions.createUnit({
                                courseId: courseId,
                                name: `Новый модуль ${moment().format('HH:mm:ss')}`,
                                description: `Без описания`
                            })).then(pld => setSelectedId(pld.unit.id));
                        }}>
                            Добавить модуль
                        </Button>
                    </BottomPlaceholder>
                }

            </Left>

            <Right expanded={unitsExpanded}>
                {selectedUnit == undefined ?
                    <SelectedUnitPlaceholder>
                        ⬅ выберите модуль из списка слева
                    </SelectedUnitPlaceholder>
                    :
                    <SelectedUnitPlaceholder key={`a__a_${selectedId}`}>
                        <UpdateUnitPanel id={selectedId} canEdit={isAdmin}/>
                    </SelectedUnitPlaceholder>
                }
            </Right>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
`;

const leftWidth = 240;
const leftMiniWidth = 60;

const Left = styled.div`
    width: ${props => (props.expanded == true ? leftWidth : leftMiniWidth)}px;
    font-size: ${props => (props.expanded == true ? 14 : 10)}px;
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    border-right: 1px solid whitesmoke;
`;

const Right = styled.div`
    width: calc(100% - ${props => (props.expanded == true ? leftWidth : leftMiniWidth)}px);
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
    height: 100%;
    max-height: calc(100vh - 50px);
    overflow-y: auto;
`;

const UnitsList = styled.div`
    max-height: calc(100vh - 120px);
    overflow-y: auto;
    overflow-x: hidden;
`;

const UnitItem = styled.div`
    box-sizing: border-box;
    width: 100%;
    padding: 7px;
    cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
    color: ${props => (props.selected == true ? 'white' : 'black')};
    background-color: ${props => (props.selected == true ? BROWN : 'transparent')};
    :hover{
      opacity: ${props => (props.selected == true ? 1 : 0.8)};
    }
`;

const UnitItemOuter = styled.div`
    box-sizing: border-box;
    width: 100%;
    padding: 0px;
    border-bottom: 1px solid whitesmoke;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const UnitItemControlsPlaceholder = styled.div`
    width: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const BottomPlaceholder = styled.div`
    padding-top: 10px;
    margin-bottom: 10px;
    border-top: 1px solid whitesmoke;
`;

const SelectedUnitPlaceholder = styled.div`
    padding: 10px;
    box-sizing: border-box;
`;

const ArrowImg = styled.img`
    display: inline-block;
    width: 10px;
    height: 10px;
    cursor: pointer;
    opacity: 0.2;
    :hover{
      opacity: 1;
    }
`;
