import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';
import {BROWN, DARK_SIDE} from "./Colors";


export default function Tabs(props) {
    const [selectedTabNumber, setSelectedTabNumber] = useState(0);
    let tabs = (props.tabs == undefined) ? [] : props.tabs;
    const {extraTopButton = null, center = false,
        wrapperStyle = {},
        selectedContainerStyle = {}
    } = props;
    const goodTabs = tabs.filter(a => (a != undefined));
    let selectedTab = (goodTabs.length == 0) ? undefined : goodTabs[selectedTabNumber];

    if (goodTabs.length == 0) {
        return null;
    }
    if (goodTabs.length == 1) {
        return goodTabs[0].content;
    }

    return (
        <Wrapper style={wrapperStyle} >

            <TabNamesItems center={center} >
                {goodTabs.map((tb, k) => {
                    let {label, content} = tb;
                    return (
                        <TabName selected={(+selectedTabNumber == +k)} key={k} onClick={() => {
                            setSelectedTabNumber(k);
                        }}>
                            {label}
                        </TabName>
                    )
                })}
                {extraTopButton == undefined ? null :
                    <TabName>
                        {extraTopButton}
                    </TabName>
                }
            </TabNamesItems>

            {selectedTab == undefined ? null :
                <ContentPlaceholder key={`num_${selectedTabNumber}`} style={selectedContainerStyle} >
                    {selectedTab.content}
                </ContentPlaceholder>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
`;

const TabNamesItems = styled.div`
    border-bottom: 1px solid whitesmoke;
    text-align: ${props => (props.center == true ? 'center' : 'left')};
`;

const TabName = styled.div`
    display: inline-block;
    vertical-align: top;
    cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
    margin-right: 15px;
    border-bottom: ${props => (props.selected == true ? `2px solid ${DARK_SIDE}` : 'none')};
    font-weight: ${props => (props.selected == true ? 'bold' : 'normal')};
    :hover{
      opacity: 0.8;
    }
`;

const ContentPlaceholder = styled.div`
    padding-top: 5px;
    width: 100%;
`;

export const TabItem = styled.div`
    width: 100%;
    padding-top: 10px;
`;
