import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import {Map, Set} from 'immutable'


export default function SolutionSquaresView(props) {
    let {
        solutions = [],
        currentIds = [],
        mainId = undefined,
        userSolutionsMap = Map()

    } = props;

    console.log('SolutionSquaresView: render: currentIds, mainId = ', currentIds, mainId);
    console.log('SolutionSquaresView: render: solutions = ', solutions);
    let maxNumber = 0;
    for (let i in solutions) {
        let sol = solutions[i];
        if (sol.number == undefined) {
            continue;
        }
        if (+sol.number > +maxNumber) {
            maxNumber = +sol.number;
        }
    }
    const numbers = Array.from({length: maxNumber + 1}).map((a, j) => j);
    console.log('numbers = ', numbers);

    return (
        <Wrapper>

            <NumbersList>
                {numbers.map((num, j) => {
                    let sols = solutions.filter((a => {
                        return ((a.number == undefined && num == 0) || (+a.number == +num));
                    }));
                    let isPassedNumber = sols.reduce((f, s) => (f || ((userSolutionsMap.get(s.id) != undefined) && userSolutionsMap.get(s.id).correct)), false);
                    return (
                        <NumberItem key={num} isPassed={isPassedNumber}>
                            <NumName>
                                <NumNameCircle isPassed={isPassedNumber}>
                                    {`${+num + 1}`}
                                </NumNameCircle>
                            </NumName>
                            <NumContent>
                                {sols.map((sol, k) => {
                                    let uAnswer = userSolutionsMap.get(sol.id);
                                    let isCurrent = currentIds.indexOf(sol.id) > -1;
                                    let isSolved = (uAnswer == undefined) ? undefined : uAnswer.correct;
                                    let isMistaken = (uAnswer == undefined) ? undefined : uAnswer.correct;

                                    let isMain = (mainId == sol.id);
                                    return (
                                        <Square key={sol.id} current={isCurrent}
                                                solved={isSolved}
                                                mistaken={isMistaken}
                                                main={isMain}>

                                            <SquareTop>
                                                {isCurrent == false ? null :
                                                    <DotImg
                                                        src={require('../../../assets/images/gps-localization-interface-symbol.svg')}/>
                                                }
                                            </SquareTop>

                                            <Box main={isMain} solved={isSolved}>
                            <span style={{
                                fontSize: (isCurrent == true) ? 20 : 16,
                                textDecoration: (isCurrent == true) ? 'underline' : 'none'
                            }}>
                                {+k + 1}
                            </span>
                                            </Box>
                                            <SquareBottom>
                                                <Dot color={
                                                    {'win': 'green', 'lose': 'red', 'draw': 'wheat'}[sol.type]
                                                }/>
                                            </SquareBottom>
                                        </Square>
                                    )
                                })}
                            </NumContent>
                        </NumberItem>
                    )
                })}
            </NumbersList>

            {/*{solutions.map((sol, k) => {*/}
            {/*let uAnswer = userSolutionsMap.get(sol.id);*/}
            {/*let isCurrent = currentIds.indexOf(sol.id) > -1;*/}
            {/*let isSolved = (uAnswer == undefined) ? undefined : uAnswer.correct;*/}
            {/*let isMistaken = (uAnswer == undefined) ? undefined : uAnswer.correct;*/}

            {/*let isMain = (mainId == sol.id);*/}
            {/*return (*/}
            {/*<Square key={sol.id} current={isCurrent}*/}
            {/*solved={isSolved}*/}
            {/*mistaken={isMistaken}*/}
            {/*main={isMain}>*/}

            {/*<SquareTop>*/}
            {/*{isCurrent == false ? null :*/}
            {/*<DotImg src={require('../../../assets/images/gps-localization-interface-symbol.svg')}/>*/}
            {/*}*/}
            {/*</SquareTop>*/}

            {/*<Box main={isMain} solved={isSolved}>*/}
            {/*<span style={{*/}
            {/*fontSize: (isCurrent == true) ? 20 : 16,*/}
            {/*textDecoration: (isCurrent == true) ? 'underline' : 'none'*/}
            {/*}}>*/}
            {/*{+k + 1}*/}
            {/*</span>*/}
            {/*</Box>*/}
            {/*<SquareBottom>*/}
            {/*<Dot color={*/}
            {/*{'win': 'green', 'lose': 'red', 'draw': 'wheat'}[sol.type]*/}
            {/*}/>*/}
            {/*</SquareBottom>*/}
            {/*</Square>*/}
            {/*)*/}
            {/*})}*/}

        </Wrapper>
    );
}

const boxSize = 50;
const currMult = 1.0;

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    //justify-content: flex-end;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
`;

const Square = styled.div`
    display: inline-block;
    box-sizing: border-box;
    margin: 2px;
    width: ${(props => (props.main == true ? currMult : 1.0) * boxSize)}px;
    text-align: center;
    vertical-align: top;
    opacity: ${props => (props.current == true ? 1 : 0.7)};
`;

const Box = styled.div`
    width: ${(props => (props.main == true ? currMult : 1.0) * boxSize) - 4}px;
    height: ${(props => (props.main == true ? currMult : 1.0) * boxSize - 4)}px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${props => (props.solved == undefined ? 'black' : 'white')};
    border: 1px solid whitesmoke;
    border-radius: 3px;
    border: ${(props => (props.main == true ? '2px solid #B58863' : '2px solid whitesmoke'))};
    background: ${props => (props.solved == undefined ? 'white' : (props.solved == true ? 'green' : 'red'))};
`;

const SquareTop = styled.div`
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
`;

const SquareBottom = styled.div`
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const DotImg = styled.img`
    width: 12px;
    height: 12px;
`;

const Dot = styled.div`
    width: 8px;
    height: 8px;
    background: ${props => props.color};
    border-radius: 1000px;
    opacity: 0.3;
    border: 1px solid black;
`;

const NumbersList = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
`;

const NumberItem = styled.div`
    padding: 10px;
    border: 1px solid lightgrey;
    border-radius: 4px;
    margin: 5px;
    flex: 1;
    background: ${props => (props.isPassed ? 'lightgreen' : 'transparent')};
`;

const NumName = styled.div`
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const NumNameCircle = styled.div`
    border-radius: 1000px;
    width: 32px;
    height: 32px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid whitesmoke;
    background: ${props => (props.isPassed == true ? 'green' : 'transparent')};
    color: ${props => (props.isPassed == true ? 'white' : 'black')};
`;

const NumContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;
