import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import * as actions from "../../../redux/actions/TestsActions";
import * as foldersActions from "../../../redux/actions/ExercisesFoldersActions";
import TestsList from "../lists/TestsList";

export default function TestsPicker(props) {
    const dispatch = useDispatch();
    const {
        testsIds = [],
        onChange = newIds => {

        }
    } = props; // selected testsIds
    let {currentUser, loading, tests} = useMappedState(useCallback(state => {
        let currentUserId = state.users.currentUserId;
        let currentUser = state.users.usersMap.get(currentUserId);
        return {
            currentUser: currentUser,
            loading: state.users.loading || state.tests.loading,
            tests: state.tests.testsMap.toArray(),
        }
    }, []));
    useEffect(() => {
        dispatch(actions.loadAllTests()); // todo: optimize
        dispatch(foldersActions.loadAllExercisesFolders());
    }, []);

    return (
        <Wrapper>

            <ListPlaceholder>
                <TestsList
                    selectable={false}
                    pickedSet={testsIds.reduce((st, a) => st.add(a), Set())}
                    searchable={true}
                    tests={tests}
                    onSelect={a => {
                        let newTestsIds = (testsIds.indexOf(a) > -1) ? testsIds.filter(eId => (eId != a)) : testsIds.concat([a]);
                        onChange(newTestsIds);
                    }}/>
            </ListPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const ListPlaceholder = styled.div`
    
`;
