import FENBoard from "fen-chess-board";
import {API_ENDPOINT, PIECES_MAP} from "../constants/config";
import Chess from "chess.js";
import {Map} from "immutable";
import ls from "local-storage";

const PK_PAYMENT_URL = `https://shagi.server.paykeeper.ru/create/`;
const DEFAULT_SERVICE_NAME = 'Предварительная оплата (аванс) за услуги на сайте shagi.online';

const PaykeeperHelper = {

    sendPost(path, params, method = 'post') {
        const form = document.createElement('form');
        form.method = method;
        form.action = path;
        form.target = '_blank';
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                const hiddenField = document.createElement('input');
                hiddenField.type = 'hidden';
                hiddenField.name = key;
                hiddenField.value = params[key];
                form.appendChild(hiddenField);
            }
        }
        document.body.appendChild(form);
        form.submit();
    },

    submitReplenishmentRequest(data) {
        let {sum, userId, orderId, email} = data;
        let params = {
            sum: sum,
            orderid: orderId,
            service_name: DEFAULT_SERVICE_NAME,
            clientid: userId,
            user_result_callback: window.location.href,
            client_email: email

        };
        this.sendPost(PK_PAYMENT_URL, params);
    }


}

export default PaykeeperHelper;
