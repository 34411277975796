import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import HorChessTemplate from "../templates/HorChessTemplate";

import AdminAllTeachersPanel from "../admin/panels/AdminAllTeachersPanel";

export default function AdminTeachersApp(props) {

    return (
        <HorChessTemplate active={'teachers'}>

            <AdminAllTeachersPanel/>

        </HorChessTemplate>
    );
}

