import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';

import ChatAPI from "../../../api/ChatAPI";

import {Code} from 'react-content-loader'
import CommonHelper from "../../../helpers/CommonHelper";
import SendMessageControl from "../tools/SendMessageControl";
import MessagesList from "../tools/MessagesList";

const scrollMessagesToBottom = (objId = 'batches_list') => {
    setTimeout(() => {
        try {
            let objDiv = document.getElementById(objId);
            if (objDiv != undefined){
                objDiv.scrollTop = objDiv.scrollHeight;
            }
        } catch (e) {

        }
    }, 16)
}

const loadAndSubscribe = (ref, roomId, pld, onRefresh = () => {

}) => {
    ref.usersMap = pld.usersMap;
    ref.room = pld.room;
    ref.messages = (pld.messages == undefined) ? [] : pld.messages;
    ChatAPI.subscribeToRoomMessages(roomId, msgs => {
        console.log('subscribeToRoomMessages: roomId, msgs = ', roomId, msgs);
        let msgsIds = msgs.map(a => a.id);
        let newMessages = ref.messages.filter(a => (msgsIds.indexOf(a.id) == -1)).concat(msgs).sort((a, b) => (+new Date(a.t) - +new Date(b.t)));
        ref.messages = newMessages;
        let ff = newMessages.reduce((f, a) => (ref.usersMap || {})[a.senderId] == undefined ? true : f, false)

        if (ff) {
            // ChatkitAPI.getRoomInfo(roomId).then(a => {
            //     ref.usersMap = a.usersMap;
            //     ref.room = a.room;
            //     onRefresh(m);
            // });
            ChatAPI.getRoomUsersMap(roomId).then(a => {
                ref.usersMap = a;
                onRefresh();
            });

        } else {
            onRefresh();
        }

    });
};

export default function RoomChatPanel(props) {
    const {roomId} = props;
    let {currentUser, currentUserId} = useMappedState(useCallback(state => {
        return {
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            currentUserId: state.users.currentUserId
        }
    }, [roomId]));

    const [loading, setLoading] = useState(true);
    const [latestUpdateTimestamp, setLatestUpdateTimestamp] = useState(0);
    const [sending, setSending] = useState(false);

    const chatData = useRef({
        messages: [],
        usersMap: {},
        room: undefined
    });

    useEffect(() => {
        if (currentUserId == undefined) {
            return;
        }
        setLoading(true);
        console.log('EFFECT! roomId = ', roomId);
        ChatAPI.getRoomInfo(roomId, true).then(pld => {
            console.log('room info pld = ', pld);
            chatData.current.messages = pld.messages;
            loadAndSubscribe(chatData.current, roomId, pld, () => {
                setLatestUpdateTimestamp(+new Date());
                scrollMessagesToBottom(`room_messages_${roomId}`);
            });
            setLoading(false);
            setLatestUpdateTimestamp(+new Date())
            return () => {
                ChatAPI.cancelSubscription(roomId);
            }
        });
    }, [roomId, currentUserId]);

    if (loading == true) {
        return (
            <Code/>
        )
    }

    return (
        <Wrapper>

            <MessagesPlaceholder id={`room_messages_${roomId}`}>
                <MessagesList {...chatData.current} />
            </MessagesPlaceholder>


            <SendControlPlaceholder>
                <SendMessageControl
                    hasAttachment={true}
                    sending={sending} onSend={async txt => {
                    setSending(true);
                    let m = await ChatAPI.sendTextMessage(roomId, txt);
                    console.log('m = ', m);
                    setSending(false);
                }}/>
            </SendControlPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
`;

const MessagesPlaceholder = styled.div`
    flex: 1;
    overflow-y: auto;
    background: white;
`;

const SendControlPlaceholder = styled.div`
    height: 80px;
    border-top: 1px dashed whitesmoke;
`;
