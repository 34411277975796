import React, {useState} from 'react';
import styled from 'styled-components';
import {Button} from '@blueprintjs/core'
// import {Scrollbars} from 'react-custom-scrollbars';
import Solution, {newSolution} from './Solution';
import PgnHelper from "../../pgnViewer/PgnHelper";

export default function Solutions(props) {
    const {
        fen = '',
        node,
        solutions: initSolutions = [],
        onSave = solutions => solutions,
        onSubExerciseSelect = e => e
    } = props;
    console.log('render: initSolutions = ', initSolutions);

    const [solutions, setSolutions] = useState(initSolutions);

    const add = () => {
        // const tempSolutions = [...solutions];
        let newSol = newSolution(fen, PgnHelper.getUid(node));
        // tempSolutions.push(newSol);
        setSolutions([...solutions, newSol]);
    };

    const save = solution => {
        console.log('Solutions: save occured: solution = ', solution);
        const tempSolutions = [...solutions];
        console.log('tempSolutions = ', tempSolutions);
        const match = tempSolutions.find(item => item.id === solution.id);

        if (match) {
            Object.assign(match, solution);
            setSolutions(tempSolutions);
            onSave(tempSolutions);
        }
    }

    const remove = ({solution}) => {
        if (!solution.variants.length) {
            let tempSolutions = [...solutions];

            tempSolutions = tempSolutions.filter(item => item.id !== solution.id);

            setSolutions(tempSolutions);

            onSave(tempSolutions);
        }
    }

    return (
        <Wrapper>
            {/*<Scrollbars>*/}
            <SolutionsList>
                {
                    solutions.map((solution, index) => (
                        <SolutionBox key={solution.id}>
                            <Solution
                                index={++index}
                                fen={fen}
                                node={node}
                                solution={solution}
                                onDelete={remove}
                                onSave={save}
                                onSubExerciseSelect={onSubExerciseSelect}/>
                        </SolutionBox>
                    ))
                }
            </SolutionsList>
            <ButtonContainer>
                <Button disabled={!fen}
                        text={'Добавить задачу'}
                        onClick={add}/>
            </ButtonContainer>
            {/*</Scrollbars>*/}
        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    max-width: 100%;
    overflow: hidden;
`;

const ButtonContainer = styled.div`
    text-align: right;
    margin-top: 10px;
`;

const SolutionsList = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
`;

const SolutionBox = styled.div`
    box-sizing: border-box;
    margin: 5px;
    background: white;
    :first-of-type{
      margin-left: 0px;
    }
`;
