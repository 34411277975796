import ExercisesGroupsAPI from '../../api/ExercisesGroupsAPI'

import * as types from '../ActionTypes'

let loadExercisesGroups_ = () => {
    return {
        type: types.LOAD_EXERCISES_GROUPS
    }
}
let loadExercisesGroupsSuccess = (exercisesGroups) => {
    return {
        type: types.LOAD_EXERCISES_GROUPS_SUCCESS,
        exercisesGroups: exercisesGroups
    }
}
let loadExercisesGroupsFailed = (error) => {
    return {
        type: types.LOAD_EXERCISES_GROUPS_FAIL,
        error: error
    }
}

//thunk
export function loadAllExercisesGroups() {
    return (dispatch, getState) => {
        dispatch(loadExercisesGroups_());
        return ExercisesGroupsAPI.getAllExercisesGroups().then(
            exercisesGroups => dispatch(loadExercisesGroupsSuccess(exercisesGroups)),
            error => dispatch(loadExercisesGroupsFailed(error))
        )
    }
}

export function loadExercisesGroupById(id) {
    return (dispatch, getState) => {
        dispatch(loadExercisesGroups_());
        return ExercisesGroupsAPI.getExercisesGroup(id).then(
            exercisesGroup => dispatch(loadExercisesGroupsSuccess([exercisesGroup])),
            error => dispatch(loadExercisesGroupsFailed(error))
        )
    }
}

let createExercisesGroup_ = () => {
    return {
        type: types.CREATE_EXERCISES_GROUP
    }
}
let createExercisesGroupSuccess = (exercisesGroup) => {
    return {
        type: types.CREATE_EXERCISES_GROUP_SUCCESS,
        exercisesGroup: exercisesGroup
    }
}
let createExercisesGroupFailed = (error) => {
    return {
        type: types.CREATE_EXERCISES_GROUP_FAIL,
        error: error
    }
}

//thunk
export function createExercisesGroup(data) {
    return (dispatch, getState) => {
        dispatch(createExercisesGroup_());
        return ExercisesGroupsAPI.createExercisesGroup(data).then(
            exercisesGroup => dispatch(createExercisesGroupSuccess(exercisesGroup)),
            error => dispatch(createExercisesGroupFailed(error))
        )
    }
}

let updateExercisesGroup_ = () => {
    return {
        type: types.UPDATE_EXERCISES_GROUP
    }
}
let updateExercisesGroupSuccess = (exercisesGroup) => {
    return {
        type: types.UPDATE_EXERCISES_GROUP_SUCCESS,
        exercisesGroup: exercisesGroup
    }
}
let updateExercisesGroupFailed = (error) => {
    return {
        type: types.UPDATE_EXERCISES_GROUP_FAIL,
        error: error
    }
}

//thunk
export function updateExercisesGroup(data) {
    return (dispatch, getState) => {
        dispatch(updateExercisesGroup_());
        return ExercisesGroupsAPI.updateExercisesGroup(data).then(
            exercisesGroup => dispatch(updateExercisesGroupSuccess(exercisesGroup)),
            error => dispatch(updateExercisesGroupFailed(error))
        )
    }
}

let deleteExercisesGroup_ = () => {
    return {
        type: types.DELETE_EXERCISES_GROUP
    }
}
let deleteExercisesGroupSuccess = (id) => {
    return {
        type: types.DELETE_EXERCISES_GROUP_SUCCESS,
        id: id
    }
}
let deleteExercisesGroupFailed = (error) => {
    return {
        type: types.DELETE_EXERCISES_GROUP_FAIL,
        error: error
    }
}

//thunk
export function deleteExercisesGroup(id) {
    return (dispatch, getState) => {
        dispatch(deleteExercisesGroup_());
        return ExercisesGroupsAPI.deleteExercisesGroup(id).then(
            () => dispatch(deleteExercisesGroupSuccess(id)),
            error => dispatch(deleteExercisesGroupFailed(error))
        )
    }
}
