import TeacherGroupsAPI from '../../api/TeacherGroupsAPI'

import * as types from '../ActionTypes'

let loadTeacherGroups_ = () => {
    return {
        type: types.LOAD_TEACHER_GROUPS
    }
}
let loadTeacherGroupsSuccess = (teacherGroups) => {
    return {
        type: types.LOAD_TEACHER_GROUPS_SUCCESS,
        teacherGroups: teacherGroups
    }
}
let loadTeacherGroupsFailed = (error) => {
    return {
        type: types.LOAD_TEACHER_GROUPS_FAIL,
        error: error
    }
}

//thunk
export function loadAllTeacherGroups() {
    return (dispatch, getState) => {
        dispatch(loadTeacherGroups_());
        return TeacherGroupsAPI.getAllTeacherGroups().then(
            teacherGroups => dispatch(loadTeacherGroupsSuccess(teacherGroups)),
            error => dispatch(loadTeacherGroupsFailed(error))
        )
    }
}

export function loadAllTeacherGroupsOfTeacher(teacherId) {
    return (dispatch, getState) => {
        dispatch(loadTeacherGroups_());
        return TeacherGroupsAPI.getAllTeacherGroupsOfTeacher(teacherId).then(
            teacherGroups => dispatch(loadTeacherGroupsSuccess(teacherGroups)),
            error => dispatch(loadTeacherGroupsFailed(error))
        )
    }
}

export function loadTeacherGroupById(id) {
    return (dispatch, getState) => {
        dispatch(loadTeacherGroups_());
        return TeacherGroupsAPI.getTeacherGroupById(id).then(
            teacherGroup => dispatch(loadTeacherGroupsSuccess([teacherGroup])),
            error => dispatch(loadTeacherGroupsFailed(error))
        )
    }
}

let createTeacherGroup_ = () => {
    return {
        type: types.CREATE_TEACHER_GROUP
    }
}
let createTeacherGroupSuccess = (teacherGroup) => {
    return {
        type: types.CREATE_TEACHER_GROUP_SUCCESS,
        teacherGroup: teacherGroup
    }
}
let createTeacherGroupFailed = (error) => {
    return {
        type: types.CREATE_TEACHER_GROUP_FAIL,
        error: error
    }
}

//thunk
export function createTeacherGroup(data) {
    return (dispatch, getState) => {
        dispatch(createTeacherGroup_());
        return TeacherGroupsAPI.createTeacherGroup(data).then(
            teacherGroup => dispatch(createTeacherGroupSuccess(teacherGroup)),
            error => dispatch(createTeacherGroupFailed(error))
        )
    }
}

let updateTeacherGroup_ = () => {
    return {
        type: types.UPDATE_TEACHER_GROUP
    }
}
let updateTeacherGroupSuccess = (teacherGroup) => {
    return {
        type: types.UPDATE_TEACHER_GROUP_SUCCESS,
        teacherGroup: teacherGroup
    }
}
let updateTeacherGroupFailed = (error) => {
    return {
        type: types.UPDATE_TEACHER_GROUP_FAIL,
        error: error
    }
}

//thunk
export function updateTeacherGroup(data) {
    return (dispatch, getState) => {
        dispatch(updateTeacherGroup_());
        return TeacherGroupsAPI.updateTeacherGroup(data).then(
            teacherGroup => dispatch(updateTeacherGroupSuccess(teacherGroup)),
            error => dispatch(updateTeacherGroupFailed(error))
        )
    }
}

export function toggleStudentMembership(groupId, studentId) {
    return (dispatch, getState) => {
        dispatch(updateTeacherGroup_());
        return TeacherGroupsAPI.toggleStudentMembership(groupId, studentId).then(
            teacherGroup => dispatch(updateTeacherGroupSuccess(teacherGroup)),
            error => dispatch(updateTeacherGroupFailed(error))
        )
    }
}


let deleteTeacherGroup_ = () => {
    return {
        type: types.DELETE_TEACHER_GROUP
    }
}
let deleteTeacherGroupSuccess = (id) => {
    return {
        type: types.DELETE_TEACHER_GROUP_SUCCESS,
        id: id
    }
}
let deleteTeacherGroupFailed = (error) => {
    return {
        type: types.DELETE_TEACHER_GROUP_FAIL,
        error: error
    }
}

//thunk
export function deleteTeacherGroup(id) {
    return (dispatch, getState) => {
        dispatch(deleteTeacherGroup_());
        return TeacherGroupsAPI.deleteTeacherGroup(id).then(
            () => dispatch(deleteTeacherGroupSuccess(id)),
            error => dispatch(deleteTeacherGroupFailed(error))
        )
    }
}
