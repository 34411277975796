import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import Select from 'react-select'

export default function TimePicker(props) {
    let {
        hour, minute, onChange = d => {

        }
    } = props;
    const hours = Array.from(new Array(24)).map((a, k) => (+k));
    const minutes = Array.from(new Array(11)).map((a, k) => ((+k) * 5));
    let af = a => (a < 10 ? `0${a}` : `${a}`);

    return (
        <Wrapper>

            <SelPlaceholder>
                <Label>
                    Часы
                </Label>
                <Select options={hours.map(h => ({label: af(h), value: h}))}
                        placeholder={'Часы'}
                        value={{label: af(+hour), value: +hour}}
                        onChange={a => {
                            onChange({
                                hour: a.value,
                                minute
                            })
                        }}
                />
            </SelPlaceholder>

            <SelPlaceholder>
                <Label>
                    Минуты
                </Label>
                <Select
                    options={minutes.map(h => ({label: af(h), value: h}))}
                    placeholder={'Минуты'}
                    value={{label: af(+minute), value: +minute}}
                    onChange={a => {
                        onChange({
                            minute: a.value,
                            hour
                        })
                    }}
                />
            </SelPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;    
    width: 100%;
`;

const SelPlaceholder = styled.div`
    flex: 1;
    :first-of-type{
      padding-right: 5px;
    }
    :last-of-type{
      padding-left: 5px;
    }
`;

const Label = styled.div`
    font-size: 12px;
    opacity: 0.5;
    font-weight: bold;
`;
