import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import ReactHelper from "../../../helpers/ReactHelper";
import {translate} from "../../../utils/Translate";

const gs = a => (+a < 10 ? `0${a}` : `${a}`)

export default function TimerSpan(props) {
    let {startTimestamp = 0} = props;
    const wrapperRef = useRef();

    ReactHelper.useInterval(() => {
        let total = +new Date() - +startTimestamp;
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
        let s = `${gs(hours)}:${gs(minutes)}:${gs(seconds)}`;
        if (total < 0) {
            s = `${translate('lesson_will_start')} ${moment(startTimestamp).format('DD.MM.YYYY')} ${moment(startTimestamp).format('HH:mm')}`;
        }
        try {
            wrapperRef.current.innerText = s;
        } catch (e) {
        }
    }, 1000);

    return (
        <Wrapper ref={wrapperRef}>

        </Wrapper>
    );
}

const Wrapper = styled.span`
    
`;
