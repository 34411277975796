import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import Tabs from '../../ui/Tabs'

import CourseUnitsPanel from './CourseUnitsPanel'
import * as actions from '../../../redux/actions/CoursesActions'

import NameDescriptionForm from '../../common/forms/NameDescriptionForm'

import FullPagePreloader from '../../preloader/FullPagePreloader'
import {Code} from 'react-content-loader'
import CommonHelper from "../../../helpers/CommonHelper";
import UpdateCourseInfoForm from "../forms/UpdateCourseInfoForm";



export default function CoursePanel(props) {
    let {id, unitsExpanded = true} = props;
    const {course, loading, isAdmin} = useMappedState(useCallback(state => ({
        course: state.courses.coursesMap.get(id),
        loading: state.courses.loading,
        isAdmin: ('admin' == CommonHelper.getCurrentUserRole(state) || 'methodist' == CommonHelper.getCurrentUserRole(state))
    }), [id]));
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.loadCourseById(id));
    }, [id]);

    if (course == undefined && loading == true) {
        return (
            <Code/>
        )
    }

    if (course == undefined) {
        return null;
    }

    let canEdit = (isAdmin == true);

    return (
        <Wrapper>

            <Tabs tabs={[
                {
                    label: 'Модули',
                    content: (
                        <TabContent>
                            <CourseUnitsPanel
                                unitsExpanded={unitsExpanded}
                                canEdit={isAdmin}
                                courseId={id}
                            />
                        </TabContent>
                    )
                },
                {
                    label: 'О курсе',
                    content: (
                        <TabContent>
                            <FormPlaceholder>

                                {canEdit == false ? null :
                                    <UpdateCourseInfoForm {...course}
                                                          loading={loading}
                                                          onSave={async d => {
                                                              await dispatch(actions.updateCourse({id: id, ...d}));
                                                          }}
                                                          canDelete={true}
                                                          onDelete={async () => {
                                                              await dispatch(actions.deleteCourse(id));
                                                              CommonHelper.linkTo(`/`);
                                                          }}
                                    />
                                }

                                {canEdit == true ? null :
                                    <div>
                                        <Name>
                                            {course.name}
                                        </Name>
                                        <p>
                                            {course.price == undefined || course.price == 0 ? 'Бесплатный' : `${course.price} руб.`}
                                        </p>
                                        <p>
                                            {course.description}
                                        </p>
                                    </div>
                                }

                                {/*<NameDescriptionForm {...course}*/}
                                {/*canEdit={isAdmin}*/}
                                {/*onSubmit={data => {*/}
                                {/*dispatch(actions.updateCourse({*/}
                                {/*id: id,*/}
                                {/*...data*/}
                                {/*}));*/}
                                {/*}}/>*/}

                            </FormPlaceholder>
                        </TabContent>
                    )
                }
            ]}/>

            {/*<FullPagePreloader visible={loading} />*/}

        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
`;

const TabContent = styled.div`
    width: 100%;
`;

const FormPlaceholder = styled.div`
    text-align: left;
    padding-top: 10px;
`;

const Name = styled.div`
    font-weight: bold;
    font-size: 18px;
`;
