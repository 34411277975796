import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

export default function FacesListTool(props) {
    const {
        faces = [],
        maxFacesNumber = 5
    } = props;
    const restNum = faces.length - maxFacesNumber;
    let visibleFaces = faces.slice(0, maxFacesNumber);

    return (
        <Wrapper>


            {visibleFaces.map((face, i) => {
                let hasAvatar = (face.avatar != undefined);
                return (
                    <ItemBox key={face.id} src={face.avatar}>
                        {hasAvatar == true ? null :
                            <span>
                                {face.email.slice(0, 2).toUpperCase()}
                            </span>
                        }
                    </ItemBox>
                )
            })}

            {restNum <= 0 ? null :
                <ItemBox>
                    {`+${restNum}`}
                </ItemBox>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    
`;

const ItemBox = styled.div`
    font-size: 12px;
    line-height: 15px;
    
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    background-color: #C7C8CF;
    margin-right: 10px;
    border: 2px solid #FFFFFF;
    box-shadow: 0px 10px 20px rgba(31, 32, 65, 0.1);
    border-radius: 100px;
    width: 31px;
    height: 31px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: ${props => (props.src == undefined ? 'none' : `url(${props.src})`)};
`;
