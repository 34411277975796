import React, {useState, useRef} from 'react';
import styled from 'styled-components';
import Chessboard from '../../chessboard/Chessboard';
import PgnViewer from '../../pgnViewer/PgnViewer';
import PgnHelper from '../../pgnViewer/PgnHelper';
import Solutions from '../Solutions/Solutions';
import useComponentSize from "@rehooks/component-size";


export default function Editor(props) {
    console.log('Editor: render: props = ', props);
    const {
        allowHandleKeyEvents,
        borderWidthFraction = 0.3,
        fen: initFen = '',
        pgn = '',
        boardHeight = 0,
        minBoardHeight = 400,
        rightColWidth = 400,
        solutions = [],
        onSave = e => e,
        onSubExerciseSelect = e => e,
    } = props;
    const [fen, setFen] = useState({fen: initFen, node: null});
    const [arrows, setArrows] = useState([]);
    const [highlightedSquares, setHighlightedSquares] = useState([]);
    // const boardWidth = Math.max(boardHeight, minBoardHeight);
    const chessboardRef = useRef();
    const wrapperRef = useRef();
    const wrapperSize = useComponentSize(wrapperRef);

    const OnPgnMoveClick = ({node}) => {
        const nodeFen = PgnHelper.getFen(node)
        setFen({
            fen: nodeFen,
            node
        });
        chessboardRef.current.loadFen(nodeFen);
        const [arrows, highlightedSquares] = PgnHelper.getArrowsAndHighlightedSquares(node);
        setArrows(arrows);
        setHighlightedSquares(highlightedSquares);
    }

    const boardWidth = wrapperSize.width * borderWidthFraction;

    return (
        <Wrapper ref={wrapperRef}>

            <Top>
                <BoardPlaceholder width={boardWidth}>
                    <Chessboard
                        ref={chessboardRef}
                        fen={fen.fen}
                        width={boardWidth}
                        arrows={arrows}
                        highlightedSquares={highlightedSquares}/>
                </BoardPlaceholder>
                <PgnViewerPlaceholder>
                    <PgnViewer pgn={pgn} onMoveClick={OnPgnMoveClick} allowHandleKeyEvents={allowHandleKeyEvents}/>
                </PgnViewerPlaceholder>
            </Top>

            <Bottom>
                <Solutions fen={fen.fen === initFen ? '' : fen.fen}
                           node={fen.node}
                           solutions={solutions}
                           onSubExerciseSelect={onSubExerciseSelect}
                           onSave={onSave}/>
            </Bottom>

        </Wrapper>
    );
}


const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;

const Top = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

const Bottom = styled.div`
    box-sizing: border-box;
    margin-top: 5px;
    margin-bottom: 5px;
`;

const BoardPlaceholder = styled.div`
    width: ${props => props.width}px;
    height: ${props => props.height}px;
`;

const PgnViewerPlaceholder = styled.div`
    flex: 1;
    box-sizing: border-box;
    padding-left: 10px;
`;
