import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import HorChessTemplate from "../templates/HorChessTemplate";


import {Container} from "../ui/Container";
import AllExercisesPanel from "../exercises/panels/AllExercisesPanel";

import Tabs, {TabItem} from "../ui/Tabs";
import AllExercisesFoldersPanel from "../exercises_folders/panels/AllExercisesFoldersPanel";
import CoolExercisesFoldersPanel from "../exercises_folders/panels/CoolExercisesFoldersPanel";
import IraTeacherTemplate from "../templates/IraTeacherTemplate";
import UpdateSmartExercisePanel from "../smart_exercises/panels/UpdateSmartExercisePanel";

export default function AdminExercisesApp(props) {
    let {id} = props.match.params;

    return (
        <IraTeacherTemplate active={`exercises/${id}`}>

            <UpdateSmartExercisePanel id={id}/>

        </IraTeacherTemplate>
    );
}

