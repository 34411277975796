import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

export default function UpdateSlotsForm(props) {
    const {
        slots = [], onChange = newSlots => {

        }
    } = props;

    const availableSet = slots.reduce((st, h) => st.add(h), Set())

    return (
        <Wrapper>

            <DaysList>
                {[0, 1, 2, 3, 4, 5, 6].map((day, k) => {
                    return (
                        <Day key={k}>
                            <DayName>
                                {`${['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье'][day]}`}
                            </DayName>
                            <HoursList>
                                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23].map((hour, j) => {
                                    let p = 24 * day + hour;
                                    let isSelected = availableSet.has(p);
                                    return (
                                        <Hour key={j} selected={isSelected} onClick={() => {
                                            if (isSelected == true) {
                                                onChange(availableSet.delete(p).toArray().sort());
                                            } else {
                                                onChange(availableSet.add(p).toArray().sort());
                                            }
                                        }}>
                                            {`${getPrettyNumber(+hour)}:00 - ${getPrettyNumber(+hour + 1)}:00`}
                                        </Hour>
                                    )
                                })}
                            </HoursList>
                        </Day>
                    )
                })}
            </DaysList>

        </Wrapper>
    );
}


const Wrapper = styled.div`
    width: 100%;   
`;

const DaysList = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: stretch;
`;

const Day = styled.div`
    flex: 1;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    border-right: 1px solid whitesmoke;
`;

const DayName = styled.div`
    text-align: center;
    font-weight: bold;
    background-color: lightgray;
    padding-top: 5px;
    padding-bottom: 5px;
    height: 30px;
    line-height: 20px;
    box-sizing: border-box;
`;

const HoursList = styled.div`
    
`;

const Hour = styled.div`
    background-color: ${props => (props.selected == true ? 'lightgreen' : 'white')};
    padding: 10px;
    box-sizing: border-box;
    font-weight: ${props => (props.selected == true ? 'bold' : 'normal')};
    border-bottom: 1px solid whitesmoke;
    padding-bottom: 7px;
    padding-top: 7px;
    cursor: pointer;
    font-size: 12px;
    text-align: center;
`;

const getPrettyNumber = (a) => (a < 10 ? `0${a}` : `${a}`);
