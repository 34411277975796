/**
 * Created by sabir on 19.07.17.
 */

import {Map, Stack, Set} from 'immutable'
import * as types from '../ActionTypes.js'

const initialState = {
    loading: false,

    lessonsMap: Map(),
    error: undefined

}

const startLoading = (state, action) => {
    return { ...state, loading: true, error: undefined}
}

const stopLoading = (state, action) => {
    return { ...state, loading: false, error: action.error}
}

const LessonsReducer =  (state = initialState, action = {}) => {

    switch (action.type) {


        case types.CREATE_LESSON:
        case types.UPDATE_LESSON:
        case types.DELETE_LESSON:
        case types.LOAD_LESSONS:
            return {
                ...state,
                loading: true,
                error: undefined
            }

        case types.CREATE_LESSON_FAIL:
        case types.UPDATE_LESSON_FAIL:
        case types.DELETE_LESSON_FAIL:
        case types.LOAD_LESSONS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case types.CREATE_LESSON_SUCCESS:
        case types.UPDATE_LESSON_SUCCESS:
            return {
                ...state,
                loading: false,
                lessonsMap: state.lessonsMap.set(action.lesson.id, action.lesson)
            }


        case types.DELETE_LESSON_SUCCESS:
            return {
                ...state,
                loading: false,
                lessonsMap: state.lessonsMap.delete(action.id),
            }



        case types.LOAD_LESSONS_SUCCESS:
            return {
                ...state,
                loading: false,
                lessonsMap: state.lessonsMap
                    .merge(action.lessons.reduce((map, form) => map.set(form.id, form), Map()))
            }


        default:
            return state;
    }

}

export default LessonsReducer;
