import {combineReducers} from 'redux';

import ExFoldersReducer from './ExFoldersReducer.js';
import SmartExercisesReducer from './SmartExercisesReducer.js';
import UsersReducer from './UsersReducer.js';
import CoursesReducer from './CoursesReducer.js';
import GroupsReducer from './GroupsReducer.js';
import RoomsReducer from './RoomsReducer.js';
import LessonsReducer from './LessonsReducer.js';
import SchoolsReducer from './SchoolsReducer.js';
import DocumentsReducer from './DocumentsReducer.js';
import ExercisesReducer from './ExercisesReducer.js';
import ExercisesGroupsReducer from './ExercisesGroupsReducer.js';
import AnswersReducer from './AnswersReducer.js';
import TeacherGroupsReducer from './TeacherGroupsReducer.js';
import TagsReducer from './TagsReducer.js';
import GamesReducer from './GamesReducer.js';
import TournamentsReducer from './TournamentsReducer.js';
import TestsReducer from './TestsReducer.js';
import ExercisesFoldersReducer from './ExercisesFoldersReducer.js';
import UiReducer from './UiReducer.js';


export const reducer = combineReducers({
    smartExercises: SmartExercisesReducer,
    exFolders: ExFoldersReducer,

    ui: UiReducer,
    users: UsersReducer,
    courses: CoursesReducer,
    groups: GroupsReducer,
    rooms: RoomsReducer,
    schools: SchoolsReducer,
    documents: DocumentsReducer,
    lessons: LessonsReducer,
    exercises: ExercisesReducer,

    answers: AnswersReducer,
    exercisesGroups: ExercisesGroupsReducer,
    teacherGroups: TeacherGroupsReducer,
    tags: TagsReducer,
    games: GamesReducer,
    tournaments: TournamentsReducer,
    tests: TestsReducer,
    exercisesFolders: ExercisesFoldersReducer
});
