import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import {Input, Button, LinedText} from '../ui'

import Spin from 'arui-feather/spin'

export default function StyledLoginForm(props) {
    const {
        onSubmit = () => {

        },
        onSignUpClick = () => {
        },
        onLostPasswordClick = () => {
        }, loading = false
    } = props;
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    return (
        <Wrapper>

            <TopPlaceholder>
                <LinedText text={'Вход'}/>
            </TopPlaceholder>

            <ContentPlaceholder>
                <Field>
                    <Input value={email} type={'email'} placeholder={'Ваш E-Mail'} onChange={e => {
                        setEmail(e.target.value);
                    }}/>
                </Field>
                <Field>
                    <Input value={password} type={'password'} placeholder={'Пароль'}
                           onKeyPress={e => {
                               if (e.key === 'Enter') {
                                   onSubmit({email, password})
                               }
                           }}
                           onChange={e => {

                               setPassword(e.target.value);
                           }}/>
                </Field>
                <Field>
                    <Button onClick={() => {
                        onSubmit({email, password})
                    }}>
                        <Spin visible={loading}/>
                        {loading == true ? null :
                            'Войти'
                        }
                    </Button>
                </Field>
            </ContentPlaceholder>

            <BottomPlaceholder>
                <Link onClick={() => {
                    onLostPasswordClick();
                }}>
                    Забыли пароль?
                </Link>
                <Link onClick={() => {
                    onSignUpClick();
                }}>
                    Регистрация
                </Link>
            </BottomPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    //width: 100%;
    //height: 100%;
    padding: 20px;
    box-sizing: border-box;
    width: 400px;
    @media(max-width: 920px){
      width: 100%;
    }
`;

const TopPlaceholder = styled.div`
    width: 100%;
    margin-bottom: 40px;
`;

const ContentPlaceholder = styled.div`
    
`;

const BottomPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
`;

const Field = styled.div`
    margin-bottom: 10px;
    width: 100%;
`;

const Link = styled.div`
    color: #999999;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
    :hover{
      opacity: 0.8;
    }
`;
