import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

export default function QualitySwitcher(props) {
    const {
        hdUrl = undefined,
        sdUrl = undefined,

        selectedUrl = undefined,

        onSelect = u => {

        }

    } = props;

    let selectedLabel = (selectedUrl == hdUrl) ? 'HD' : 'SD';

    let firstUrl = (selectedUrl == hdUrl) ? hdUrl : sdUrl;
    let secondUrl = (selectedUrl == hdUrl) ? sdUrl : hdUrl;

    let firstLabel = (selectedUrl == hdUrl) ? 'HD' : 'SD';
    let secondLabel = (selectedUrl == hdUrl) ? 'SD' : 'HD';

    return (
        <Wrapper>
            <HoverInnerPlaceholder>
                <Item selected={(secondUrl == selectedUrl)} onClick={() => {
                    onSelect(secondUrl);
                }}>
                    {secondLabel}
                </Item>
                <Item selected={(firstUrl == selectedUrl)} onClick={() => {
                    onSelect(firstUrl);
                }}>
                    {firstLabel}
                </Item>
            </HoverInnerPlaceholder>
            <Cont>
                {selectedLabel}
            </Cont>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

const Item = styled.div`
    background: ${props => (props.selected == true ? '#6a8baf' : 'white')};
    color: ${props => (props.selected == true ? 'white' : 'black')};
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    :hover{
      opacity: 0.8; 
      background: #6a8baf;
      color: white;
    }
`;

const Cont = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const HoverInnerPlaceholder = styled.div`
    display: none;
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 2;
    background: white;
    ${Wrapper}:hover &{
      display: block;
    }
`;
