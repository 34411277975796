import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import LessonTasksPanel from "../panels/LessonTasksPanel";
import ExerciseViewPanel from "../../exercises/panels/ExerciseViewPanel";

export default function ManyExercisesPlayPanel(props) {
    const {exercisesIds = [], userId} = props;
    const [number, setNumber] = useState(0);
    if (exercisesIds.length == 0) {
        return null;
    }
    let exId = exercisesIds[number];


    return (
        <Wrapper>

            <NumbersPlaceholder>
                {exercisesIds.map((eId, k) => {
                    let selected = (eId == exId);
                    return (
                        <NumberItem
                            selected={selected}
                            key={`${k}_${eId}`}
                            onClick={() => {
                                setNumber(k);
                            }}
                        >
                            {`${+k + 1}`}
                        </NumberItem>
                    )
                })}
            </NumbersPlaceholder>

            <SelectedExercisePlaceholder key={`${number}_${exId}`}>
                <ExerciseViewPanel id={exId} userId={userId} />
            </SelectedExercisePlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const NumbersPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
`;

const NumberItem = styled.div`
    padding: 4px;
    background: ${props => (props.selected == true ? 'blue' : 'white')};
    color: ${props => (props.selected == true ? 'white' : 'black')};
    width: 32px;
    height: 32px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    :hover{
      opacity: 0.7;
    }
`;

const SelectedExercisePlaceholder = styled.div`
    height: 500px;
    //background: whitesmoke;  
`;
