import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import {Button} from '@blueprintjs/core'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'
import TagsAPI from "../../../api/TagsAPI";

import {Spinner} from '@blueprintjs/core'

import Select from 'react-select'

export default function TagsSelector(props) {
    const {
        tags = [],
        onChange = newTags => {

        }
    } = props;
    const [loading, setLoading] = useState(true);
    const [allTags, setAllTags] = useState([]);

    useEffect(() => {
        TagsAPI.getAllTags().then(aTags => {
            setAllTags(aTags);
            setLoading(false);
        })
    }, []);

    if (loading == true) {
        return (
            <Spinner size={20}/>
        )
    }

    return (
        <Wrapper>

            <Select
                value={tags.map(a => ({value: a, label: a}))}
                options={allTags.map(a => ({value: a.name, label: a.name}))}
                isMulti={true}
                isSearchable={true}
                placeholder={'Выберите теги'}
                onChange={a => {
                    onChange(a.map(aa => (aa.value)));
                }}
            />

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;
