import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import RoomChatPanel from "../../chat/panels/RoomChatPanel";
import JitsiPanel from "../../jitsi/panels/JitsiPanel";

export default function CommutePanel(props) {
    let {roomId,
        hasVideo = true,
        renderVideoSection = (rId, hVideo = true) => {
            return (
                <CameraPlaceholder>
                    {hVideo == false ? null :
                        <JitsiPanel roomName={`room_${rId}`}
                                    canShare={true}
                        />
                    }
                </CameraPlaceholder>
            )
        }
    } = props;
    let {currentUser, loading, currentUserId} = useMappedState(useCallback(state => {
        let currentUserId = state.users.currentUserId;
        let currentUser = state.users.usersMap.get(currentUserId);
        return {
            currentUser: currentUser,
            loading: state.users.loading || state.tournaments.loading || state.games.loading,
            currentUserId: currentUserId
        }
    }, [roomId]));
    const dispatch = useDispatch();

    return (
        <Wrapper>

            <Left>
                {props.children}
            </Left>

            <Right>
                {/*<CameraPlaceholder>*/}
                    {/*{hasVideo == false ? null :*/}
                        {/*<JitsiPanel roomName={`room_${roomId}`}*/}
                                    {/*canShare={true}*/}
                        {/*/>*/}
                    {/*}*/}
                {/*</CameraPlaceholder>*/}
                {renderVideoSection(roomId, hasVideo)}
                <ChatPlaceholder>
                    <RoomChatPanel roomId={roomId} />
                </ChatPlaceholder>
            </Right>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
`;

const Left = styled.div`
    flex: 1;
    background-color: whitesmoke;
`;

const Right = styled.div`
    width: calc(100vh - 100px);
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
    @media(orientation: portrait){
      width: 30vw;
    }
`;

const CameraPlaceholder = styled.div`
    width: 100%;
    height: 50vh;
    background: black;
`;

const ChatPlaceholder = styled.div`
    flex: 1;
    background: lightgrey;
    overflow-y: auto;
    overflow-x: hidden;
`;
