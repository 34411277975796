import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';
import HorChessTemplate from "../templates/HorChessTemplate";

import AllExercisesGroupsPanel from "../exercises_groups/panels/AllExercisesGroupsPanel";

export default function AdminExercisesApp(props) {

    return (
        <HorChessTemplate active={'exercises_groups'}>

            <Wrapper>
                <AllExercisesGroupsPanel/>
            </Wrapper>

        </HorChessTemplate>
    );
}

const Wrapper = styled.div`
    background: white;
    padding: 10px;
    border-radius: 4px;
`;
