import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import * as actions from '../../../redux/actions/LessonsActions'

import UpdateLessonForm from "../forms/UpdateLessonForm";
import ExerciseViewPanel from "../../exercises/panels/ExerciseViewPanel";
import ManyExercisesPlayPanel from "../view/ManyExercisesPlayPanel";
import ManyUnitsPlayPanel from "../view/ManyUnitsPlayPanel";
import * as testsActions from '../../../redux/actions/TestsActions'
import ManyTestsPlayPanel from "../view/ManyTestsPlayPanel";


export default function LessonTestsPanel(props) {
    let {id} = props;

    const {lesson, loading, testsMap} = useMappedState(useCallback(state => {
        let lesson = state.lessons.lessonsMap.get(id);
        return {
            lesson: lesson,
            loading: state.lessons.loading,
            currentUserId: state.users.currentUserId,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            testsMap: state.tests.testsMap
        }
    }, [id]));
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(testsActions.loadAllTests()) // todo: optimize it
    }, []);
    if (loading == true) {
        return (
            <Code/>
        )
    }
    if (lesson == undefined) {
        return null;
    }
    let {exercisesIds, unitsIds = [], testsIds = []} = lesson;
    console.log('LessonUnitsPanel: render: unitsIds = ', unitsIds);


    return (
        <Wrapper>

            <ListPlaceholder>

                <ManyTestsPlayPanel testsIds={testsIds} testsMap={testsMap}/>

            </ListPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const FormPlaceholder = styled.div`
    
`;

const ListPlaceholder = styled.div`
    width: 920px;
    box-sizing: border-box;
    margin: 0 auto;
    @media(max-width: 920px){
      width: 100%;
    }
`;

const Item = styled.div`
    
`;
