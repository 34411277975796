import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';
import {useDispatch, useMappedState} from 'redux-react-hook'

import IraTeacherTemplate from "../templates/IraTeacherTemplate";
import AllSmartExercisesPanel from "../smart_exercises/panels/AllSmartExercisesUpdatePanel";
import SmartExercisesAndFoldersPanel from "../smart_exercises/panels/SmartExercisesAndFoldersPanel";
import NiceModal from "../modals/NiceModal";
import IllegalWorkspace from "../rubius/exercise/Workspace/IllegalWorkspace";
import Workspace from "../rubius/exercise/Workspace/Workspace";
import SmartExercisesAPI from "../../api/SmartExercisesAPI";

export default function StudentExercisesApp(props) {
    const dispatch = useDispatch();
    const {currentUserId, exercisesMap} = useMappedState(useCallback(state => ({
        currentUserId: state.users.currentUserId,
        currentUser: state.users.usersMap.get(state.users.currentUserId),
        exercisesMap: state.smartExercises.smartExercisesMap
    }), []));

    const [selectedExerciseId, setSelectedExerciseId] = useState(undefined);

    let selectedExercise = (selectedExerciseId == undefined) ? undefined : exercisesMap.get(selectedExerciseId);

    console.log('StudentExercisesApp: render: -> currentUserId = ', currentUserId);

    return (
        <IraTeacherTemplate active={'exercises'}>

            <Wrapper key={`x-${currentUserId}`}>

                {/*<AllSmartExercisesPanel/>*/}

                <SmartExercisesAndFoldersPanel
                    studentId={currentUserId}
                    onExerciseClick={exId => {
                        setSelectedExerciseId(exId);
                    }}
                />

            </Wrapper>

            {selectedExercise == undefined ? null :
                <NiceModal onClose={() => {
                    setSelectedExerciseId(undefined);
                }} >

                    <ModalContent>

                        {selectedExercise.isIllegal ?
                            <IllegalWorkspace {...selectedExercise}
                                              boardWidthFraction={0.6}
                                              onExerciseFinish={() => {

                                              }}
                                              onStepLimitReached={() => {

                                              }}
                                              onSubExerciseStart={() => {

                                              }}
                                              onTimeFinished={() => {

                                              }}/>
                            :
                            <Workspace {...selectedExercise}
                                       allowHandleKeyEvents={() => {

                                       }}
                                       boardWidthFraction={0.6}
                                       onSubExerciseStart={(a, b, c) => {
                                           console.log('onSubExerciseStart: a, b, c = ', a, b, c);
                                       }}
                                       onSubExerciseEnd={async (a, b, c) => {
                                           let tsk = JSON.parse(JSON.stringify(a.task));
                                           let dd = {
                                               [tsk.id]: {
                                                   result: tsk.result,
                                                   rating: tsk.rating,
                                                   duration: tsk.duration,
                                                   assessment: tsk.assessment
                                               }
                                           };
                                           await SmartExercisesAPI.saveUserExerciseAnswer(selectedExercise.id, dd);
                                       }}
                                       onExerciseFinish={(a, b, c) => {
                                           console.log('onExerciseFinish: a, b, c = ', a, b, c);
                                       }}
                                       onTimeFinished={(a, b, c) => {
                                           // console.log('onTimeFinished: a, b, c = ', a, b, c);
                                       }}
                                       onTimerTick={(a, b, c) => {
                                           // console.log('onTimerTick: a, b, c = ', a, b, c);
                                       }}/>
                        }

                    </ModalContent>

                </NiceModal>
            }

        </IraTeacherTemplate>
    );
}

const ModalContent = styled.div`
  background: #FFFFFF;
  border: 1px solid rgba(8, 91, 255, 0.1);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 30px;
  height: 80vh;
  max-height: 100vh;
  overflow-y: auto;
  width: 80vw;
`;

const Wrapper = styled.div`
  padding-top: 31px;
  padding-left: 65px;
  padding-right: 65px;
  box-sizing: border-box;
  @media (max-width: 1300px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;
