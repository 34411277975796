import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import CommonHelper from "../../../helpers/CommonHelper";

const getReducedMessages = messages => {
    let arr = [];
    if (messages.length < 2) {
        return messages;
    }
    let prevM = messages[0], currParts = messages[0].parts;
    for (let i = 0; i < messages.length; i++) {
        let m = messages[i];
        let prevSenderId = prevM.senderId;
        if (prevSenderId == m.senderId) {
            currParts = currParts.concat(m.parts);
            if (i == messages.length - 1) {
                arr.push({
                    ...prevM,
                    parts: currParts
                });
            }
            prevM = m;
            continue;
        } else {
            arr.push({
                ...prevM,
                parts: currParts
            });
            currParts = m.parts;
            if (i == messages.length - 1) {
                arr.push(m);
            }
            prevM = m;
        }
    }
    return arr;
}

export default function MessagesList(props) {
    const {comments = [], usersMap = {}} = props;

    return (
        <Wrapper>

            {comments.map((m, i) => {
                let u = usersMap[m.userId];
                return (
                    <MessageItem key={`${i}_${m.id}`}>
                        <MessageLeftPlaceholder>
                            <Ava src={u == undefined ? undefined : u.avatar}/>
                        </MessageLeftPlaceholder>
                        <MessageRightPlaceholder>
                            <MessageInfoPlaceholder>
                                <MessageUserName>
                                    {`${u == undefined ? `user_${m.userId}` : CommonHelper.getUserName(u)}`}
                                </MessageUserName>
                                <MessageDatePlaceholder>
                                    {moment(m.timestamp).format('DD.MM.YYYY HH:mm:ss')}
                                </MessageDatePlaceholder>
                            </MessageInfoPlaceholder>
                            <MessageContentPlaceholder>
                                {m.text}
                            </MessageContentPlaceholder>
                        </MessageRightPlaceholder>
                    </MessageItem>
                )
            })}

        </Wrapper>
    );
}

const Wrapper = styled.div`
    box-sizing: border-box;
    padding: 5px;
`;

const avaSize = 32;

const MessageItem = styled.div`
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-bottom: 10px;
    border-bottom: 1px solid whitesmoke;
    :last-of-type{
      border-bottom: none;
    }
`;

const MessageLeftPlaceholder = styled.div`
    width: ${avaSize}px;
    margin-right: 4px;
`;

const Ava = styled.div`
    width: ${avaSize}px;
    height: ${avaSize}px;
    border-radius: 4px;
    background: lightgrey;
    border: 1px solid whitesmoke;
    background-image: ${props => (props.src == undefined ? undefined : `url(${props.src})`)};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
`;

const MessageRightPlaceholder = styled.div`
    flex: 1;
`;

const MessageInfoPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
`;

const MessageUserName = styled.div`
    font-weight: bold;
    font-size: 14px;
`;

const MessageDatePlaceholder = styled.div`
    font-size: 12px;
    opacity: 0.5;
`;

const MessageContentPlaceholder = styled.div`
    
`;
