import React, { useState, useEffect, useRef, useCallback, useReducer, useMemo } from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

const SAMPLE_START_FEN = 'r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R';

export default function SabirChessBoard(props) {
    const {
        boardId = `simple_board`,
        fen = undefined
    } = props;
    const board = useRef(null);
    useEffect(() => {
        board.current = new window.ChessBoard(boardId, fen);
    }, []);

    return (
        <Wrapper id={boardId} >

        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
`;
