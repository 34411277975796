import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import SmartPgnViewer from "../../chess/view/smart/SmartPgnViewer";
import SolutionsList from "../tools/SolutionsList";

import Sidebar from 'arui-feather/sidebar'
import SolutionForm from "./SolutionForm";

// import {Button} from "../../ui/Button";
import {Button} from '@blueprintjs/core'

import SmartSolutionsList from "../tools/SmartSolutionsList";
import ChessHelper from "../../../helpers/ChessHelper";
import {AppToaster} from "../../toaster/AppToaster";

export default function ExerciseSolutionsForm(props) {
    let {
        loading, onSave = () => {

        },
        pgn = undefined
    } = props;

    const [selectedNumber, setSelectedNumber] = useState(undefined);
    const [solutions, setSolutions] = useState(props.solutions == undefined ? [] : props.solutions);
    const [selectedId, setSelectedId] = useState(undefined);
    const [addModalVisible, setAddModalVisible] = useState(false);

    const [selectedFen, setSelectedFen] = useState(undefined);
    const [selectedHistory, setSelectedHistory] = useState([]);

    const defaultFen = useMemo(() => {
        return ChessHelper.getFenFromPgn(pgn);
    }, [pgn]);
    if (pgn == undefined) {
        return null;
    }
    const selectedSolution = (selectedId == undefined) ? undefined : solutions.filter(sol => (sol.id == selectedId))[0];
    let sFen = (selectedFen == undefined) ? defaultFen : selectedFen;
    console.log('ExerciseSolutionsForm: render: selectedFen = ', selectedFen);
    let currMaxNumber = 0;
    for (let i in solutions) {
        if (solutions[i].number != undefined && +solutions[i].number > +currMaxNumber) {
            currMaxNumber = +solutions[i].number;
        }
    }

    return (
        <Wrapper>

            <TopPlaceholder>

                <Left>
                    <PgnViewerPlaceholder>
                        <SmartPgnViewer pgnString={pgn}
                                        selectedFen={selectedFen}
                                        selectedHistory={selectedHistory}
                                        onPositionChange={(newFen, newHistory) => {
                                            console.log('newFen, newHistory = ', newFen, newHistory);
                                            setSelectedFen(newFen);
                                            setSelectedHistory(newHistory);
                                            if (selectedSolution != undefined) {
                                                let newSolutions = solutions.map((ss) => {
                                                    if (ss.id == selectedSolution.id) {
                                                        return {
                                                            ...selectedSolution,
                                                            fen: newFen,
                                                            history: newHistory
                                                        }
                                                    }
                                                    return ss;
                                                });
                                                setSolutions(newSolutions);
                                                onSave({solutions: newSolutions});
                                            }
                                        }}/>
                    </PgnViewerPlaceholder>

                </Left>

                <Right>

                    <ListPlaceholder>
                        <SmartSolutionsList
                            solutions={solutions}
                            selectedId={selectedId}
                            defaultFen={defaultFen}
                            loading={loading}
                            onNumberSelect={(num, sol) => {
                                setSelectedNumber(num);
                                setSelectedId(sol.id);
                                if (sol != undefined) {
                                    setSelectedFen(sol.fen);
                                    setSelectedHistory(sol.history);
                                }
                            }}
                            selectedNumber={selectedNumber}
                            onItemDelete={(itemId, nn) => {
                                let newSolutions = solutions.filter(a => (a.id != itemId));
                                let nextSelectedId = (newSolutions.filter(a => (a.number == nn)).length == 0) ? undefined : newSolutions.filter(a => (a.number == nn))[newSolutions.filter(a => (a.number == nn)).length - 1].id;
                                let nextSolutionsCands = (solutions.filter(a => (a.id == nextSelectedId)));
                                let nextSolution = (nextSolutionsCands.length == 0) ? undefined : nextSolutionsCands[0];
                                setSolutions(newSolutions);
                                setSelectedId(nextSelectedId);
                                if (nextSolution != undefined) {
                                    setSelectedFen(nextSolution.fen);
                                    setSelectedHistory(nextSolution.history);
                                }
                                onSave({solutions: newSolutions});
                            }}
                            onAdd={num => {
                                console.log('--->>>   onAdd: num = ', num);
                                let newId = uuid();
                                let sol = {
                                    id: newId,
                                    fen: sFen.trim().split(' ')[0],
                                    history: selectedHistory,
                                    number: num,
                                    type: 'win'
                                };
                                let newSolutions = solutions.concat([sol]);
                                setSolutions(newSolutions);
                                setAddModalVisible(false);
                                onSave({solutions: newSolutions});
                                setSelectedId(newId);
                            }}
                            onSave={updSol => {
                                let newSolutions = solutions.map((ss) => {
                                    if (ss.id == selectedSolution.id) {
                                        return {
                                            ...selectedSolution,
                                            ...updSol
                                        }
                                    }
                                    return ss;
                                });
                                setSolutions(newSolutions);
                                onSave({solutions: newSolutions});
                            }}
                            onSolutionClick={(solId, sol) => {
                                console.log('onSolutionClick: solId, sol = ', solId, sol);
                                setSelectedId(solId);
                                setSelectedFen(sol.fen);
                                setSelectedHistory(sol.history);
                            }}
                            onMove={(num, i1, i2) => {
                                // let newSolutions = solutions.slice(0, i1).concat(solutions[i2], solutions.slice(i1 + 1, i2), solutions[i1], solutions.slice(i2 + 1));
                                let newSolutions = solutions.map(sol => {
                                    if (sol.number == i1) {
                                        return {
                                            ...sol,
                                            number: i2
                                        }
                                    }
                                    if (sol.number == i2) {
                                        return {
                                            ...sol,
                                            number: i1
                                        }
                                    }
                                    return sol;
                                });
                                setSolutions(newSolutions);
                                setSelectedId(undefined);
                                setSelectedNumber(undefined);
                                setSelectedFen(defaultFen);
                                setSelectedHistory([]);
                                onSave({solutions: newSolutions});
                            }}
                        />

                        <ControlsPlaceholder>
                            <Button onClick={() => {
                                let newId = uuid();
                                let newNumber = (solutions.length == 0) ? 0 : +currMaxNumber + 1;
                                let sol = {
                                    type: 'win',
                                    id: newId,
                                    number: newNumber,
                                    fen: sFen.trim().split(' ')[0],
                                    history: selectedHistory
                                };
                                let newSolutions = solutions.concat([sol]);
                                setSolutions(newSolutions);
                                onSave({solutions: newSolutions});
                                setSelectedId(newId);
                                setSelectedNumber(newNumber);
                            }}>
                                Добавить задачу
                            </Button>
                        </ControlsPlaceholder>

                        {/*<SolutionsList solutions={solutions}*/}
                        {/*selectedId={selectedId}*/}
                        {/*onItemClick={itemId => {*/}
                        {/*setSelectedId(itemId);*/}
                        {/*}}*/}
                        {/*onMove={(itemId, i1, i2) => {*/}
                        {/*let newSolutions = solutions.slice(0, i1).concat(solutions[i2], solutions.slice(i1 + 1, i2), solutions[i1], solutions.slice(i2 + 1));*/}
                        {/*setSolutions(newSolutions);*/}
                        {/*onSave({solutions: newSolutions});*/}
                        {/*}}*/}
                        {/*onItemDelete={itemId => {*/}
                        {/*let newSolutions = solutions.filter(a => (a.id != itemId));*/}
                        {/*setSolutions(newSolutions);*/}
                        {/*onSave({solutions: newSolutions});*/}
                        {/*}}*/}
                        {/*/>*/}
                    </ListPlaceholder>

                </Right>

            </TopPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
`;

const PgnViewerPlaceholder = styled.div`
    width: 100%;
`;

const ControlsPlaceholder = styled.div`
    padding-left: 5px;
    margin-bottom: 10px;
`;

const ListPlaceholder = styled.div`
    
`;

const TopPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    border-top: 1px solid whitesmoke;
`;

const Left = styled.div`
    flex: 1;
`;

const Right = styled.div`
    width: 400px;
    max-height: calc(100vh - 80px);
    overflow-y: auto;
    overflow-x: hidden;
`;
