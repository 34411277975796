import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import * as actions from "../../../redux/actions/TestsActions";
import * as foldersActions from "../../../redux/actions/ExercisesFoldersActions";
import TestsList from "../lists/TestsList";
import UpdateTestForm from "../forms/UpdateTestForm";

import {Button} from "../../ui/Button";
import Tabs, {TabItem} from "../../ui/Tabs";
import AllExercisesFoldersPanel from "../../exercises_folders/panels/AllExercisesFoldersPanel";
import ManyExercisesPlayPanel from "../../lessons/view/ManyExercisesPlayPanel";
import {translate} from "../../../utils/Translate";

export default function AllTestsPanel(props) {
    const {canUpdate = false} = props;
    const dispatch = useDispatch();
    const [selectedId, setSelectedId] = useState(undefined);
    const [addModalVisible, setAddModalVisible] = useState(false);
    const [expandedFolderId, setExpandedFolderId] = useState(undefined);

    let {currentUser, loading, tests} = useMappedState(useCallback(state => {
        let currentUserId = state.users.currentUserId;
        let currentUser = state.users.usersMap.get(currentUserId);
        return {
            currentUser: currentUser,
            loading: state.users.loading || state.tests.loading,
            tests: state.tests.testsMap.toArray(),
        }
    }, []));
    useEffect(() => {
        dispatch(actions.loadAllTests()); // todo: optimize
        dispatch(foldersActions.loadAllExercisesFolders());
    }, []);

    if (currentUser == undefined) {
        return null;
    }
    let selectedTest = (selectedId == undefined) ? undefined : tests.filter(a => (a.id == selectedId))[0];

    return (
        <Wrapper>

            <TopPlaceholder>
                <Button onClick={() => {
                    setAddModalVisible(true);
                }}>
                    Добавить тест
                </Button>
            </TopPlaceholder>

            <ListPlaceholder>
                <TestsList tests={tests} selectedId={selectedId} onSelect={a => {
                    setSelectedId(a);
                }}/>
            </ListPlaceholder>

            <Sidebar width={Math.min(920, window.innerWidth)} visible={(selectedTest != undefined)}
                     onCloserClick={() => {
                         setSelectedId(undefined);
                     }}>

                {selectedTest == undefined ? null :
                    <div>
                        <Tabs tabs={[
                            {
                                label: 'Информация',
                                content: (
                                    <TabItem>
                                        <UpdateTestForm {...selectedTest}
                                                        loading={loading}
                                                        onSave={async d => {
                                                            await dispatch(actions.updateTest({id: selectedTest.id, ...d}));
                                                        }}/>
                                    </TabItem>
                                )
                            },
                            {
                                label: 'Упражнения',
                                content: (
                                    <TabItem>
                                        <Tabs tabs={[
                                            {
                                                label: 'Добавление',
                                                content: (
                                                    <TabItem>
                                                        <AllExercisesFoldersPanel
                                                            expandId={expandedFolderId}
                                                            onFolderSelect={id => {
                                                                setExpandedFolderId(id);
                                                            }}
                                                            canEdit={false}
                                                            openExerciseOnClick={false}
                                                            canAddExercise={false}
                                                            selectedExercisesIds={selectedTest.exercisesIds == undefined ? [] : selectedTest.exercisesIds}
                                                            onExerciseClick={async exId => {
                                                                let newExercisesIds = (selectedTest.exercisesIds.indexOf(exId) > -1) ? selectedTest.exercisesIds.filter(eId => (eId != exId)) : selectedTest.exercisesIds.concat([exId]);
                                                                await dispatch(actions.updateTest({id: selectedTest.id, exercisesIds: newExercisesIds}));
                                                            }}

                                                        />
                                                    </TabItem>
                                                )
                                            },
                                            {
                                                label: translate('view'),
                                                content: (
                                                    <TabItem>
                                                        <ManyExercisesPlayPanel exercisesIds={selectedTest.exercisesIds}/>
                                                    </TabItem>
                                                )
                                            }
                                        ]}/>

                                    </TabItem>
                                )
                            }
                        ]}/>
                    </div>
                }

            </Sidebar>

            <Sidebar visible={addModalVisible}
                     width={Math.min(820, window.innerWidth)}
                     onCloserClick={() => {
                         setAddModalVisible(false);
                     }}>

                {addModalVisible == false ? null :
                    <UpdateTestForm
                        loading={loading}
                        onSave={async d => {
                            let data = {
                                ...d,
                                userId: currentUser.id
                            };
                            let pld = await dispatch(actions.createTest(data));
                            setAddModalVisible(false);
                            setSelectedId(pld.test.id);
                        }}/>
                }

            </Sidebar>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const ListPlaceholder = styled.div`
    
`;

const TopPlaceholder = styled.div`
    margin-bottom: 10px;
`;
