import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Set} from 'immutable'
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {Dialog, Button, Elevation} from '@blueprintjs/core'
import AllExercisesPanel from "../../exercises/panels/AllExercisesPanel";

export default function FoldersList(props) {
    const {
        allFolders = [],
        canEdit = false,
        expandedSet = Set(),
        selectedId = undefined,
        parentId = undefined,
        allExercises = [],
        onSelect = selId => {

        },
        isAdmin = false,
        onFolderExpand = folderid => {

        },
        onFolderUnExpand = fId => {

        },
        onAddSubFolder = fId => {

        },
        onFolderDelete = fId => {

        }

    } = props;

    let folders = allFolders.filter(a => (a.parentId == parentId));

    return (
        <Wrapper>

            {folders.map((folder, k) => {
                let isExpanded = (expandedSet.has(folder.id));
                let isSelected = (folder.id == selectedId);
                let childFolders = allFolders.filter(a => (a.parentId == folder.id));
                let folderExercises = allExercises.filter(a => (a.foldersIds.indexOf(folder.id) > -1));
                return (
                    <FolderItem key={folder.id} selected={isSelected}>
                        <Card interactive={false}
                              elevation={Elevation.ONE}>
                            <InnerCard>
                                <InnerInfoCard onClick={() => {
                                    if (isExpanded == true) {
                                        onFolderUnExpand(folder.id);
                                    } else {
                                        onFolderExpand(folder.id)
                                    }
                                }}>
                                    <Name>
                                        {isExpanded == true ? 'v' : '>'}
                                        {' '}
                                        {folder.name}
                                    </Name>
                                    <DescriptionP>
                                        {folder.description}
                                    </DescriptionP>
                                </InnerInfoCard>

                                {(canEdit == false || isAdmin == false) ? null :
                                    <Button icon="edit" text="Редактировать" onClick={() => {
                                        console.log('Edit button clicked: folder = ', folder);
                                        onSelect(folder.id);
                                    }}/>
                                }
                            </InnerCard>

                            {isExpanded == false ? null :
                                <SelectedContent>
                                    <AddSubFolderPlaceholder>
                                        <AddSubFolderSpan onClick={() => {
                                            onAddSubFolder(folder.id);
                                        }}>
                                            добавить подпапку
                                        </AddSubFolderSpan>
                                        <AddSubFolderSpan style={{color: 'red', marginLeft: 10}} onClick={() => {
                                            if (childFolders.length > 0) {
                                                window.alert('Удаление запрещено! Пернесите сначала папки внутри папки!');
                                                return;
                                            }
                                            if (folderExercises.length > 0) {
                                                window.alert('Удаление запрещено! Пернесите сначала упражнения внутри папки!');
                                                return;
                                            }
                                            onFolderDelete(folder.id);
                                        }}>
                                            {`удалить папку "${folder.name}"`}
                                        </AddSubFolderSpan>
                                    </AddSubFolderPlaceholder>
                                    {childFolders.length == 0 ? null :
                                        <ChildFoldersPlaceholder>
                                            <FoldersList {...props} parentId={folder.id}/>
                                        </ChildFoldersPlaceholder>
                                    }

                                    <ExercisesPlaceholder>
                                        <AllExercisesPanel
                                            {...props}
                                            canEdit={canEdit}
                                            folderId={folder.id}/>
                                    </ExercisesPlaceholder>

                                    {/*<AllExercisesPanel*/}
                                    {/*selectedExercisesIds={selectedExercisesIds}*/}
                                    {/*{...props}*/}
                                    {/*canEdit={canEdit}*/}
                                    {/*folderId={expandId}/>*/}
                                </SelectedContent>
                            }

                        </Card>


                    </FolderItem>
                )
            })}

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const FolderItem = styled.div`
    border-radius: 4px;
    margin-bottom: 10px;
    background: ${props => (props.selected == true) ? 'lightgrey' : 'transparent'};
    padding: ${props => (props.selected == true) ? 10 : 0}px;
    border: ${props => (props.selected == true) ? '1px solid whitesmoke' : 'none'};
`;


const InnerCard = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const InnerInfoCard = styled.div`
    
`;

const Name = styled.div`
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
    :hover{
      opacity: 0.75;
    }
`;

const DescriptionP = styled.p`
    cursor: pointer;
    :hover{
      opacity: 0.75;
    }
`;

const SelectedContent = styled.div`
    margin-top: 20px;
    width: 100%;
    box-sizing: border-box;
    //padding: 10px;
    background: whitesmoke;
    border-left: 3px solid lightgrey;
    padding: 10px;
`;

const ChildFoldersPlaceholder = styled.div`
    padding-left: 10px;
    margin-left: 10px;
    border-left: 2px solid lightgrey;
`;

const Card = styled.div`
    padding: 10px;
    border-radius: 4px;
    background: white;
`;

const AddSubFolderPlaceholder = styled.div`
    text-align: right;
    font-size: 12px;
    margin-bottom: 3px;
`;

const AddSubFolderSpan = styled.span`
    cursor: pointer;
    opacity: 0.5;
    color: grey;
    text-decoration: underline;
    :hover{
      opacity: 1;
    }
`;

const ExercisesPlaceholder = styled.div`
    
`;
