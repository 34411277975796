import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

export default function PartsView(props) {
    const {parts = []} = props;

    return (
        <Wrapper>
            {parts.map((p, i) => {
                let content = (p.content || (p.payload == undefined ? undefined : p.payload.content));
                return (
                    <PartItem key={i}>
                        {content == undefined ? null :
                            <div dangerouslySetInnerHTML={{__html: content.replace(/\n/g, '<br/>')}}></div>
                        }
                    </PartItem>
                )
            })}
        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const PartItem = styled.div`
    margin-bottom: 5px;
`;
