import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import HorChessTemplate from "../templates/HorChessTemplate";
import TeacherGroupsPanel from "../teacher_groups/panels/TeacherGroupsPanel";
import IraTeacherTemplate from "../templates/IraTeacherTemplate";


export default function TeacherGroupsApp(props) {

    return (
        <IraTeacherTemplate active={'groups'}>

            <Wrapper>
                <TeacherGroupsPanel/>
            </Wrapper>

        </IraTeacherTemplate>
    );
}

const Wrapper = styled.div`
    padding-top: 31px;
    padding-left: 65px;
    padding-right: 65px;
    box-sizing: border-box;
    @media(max-width: 1300px){
      padding-left: 15px;
      padding-right: 15px;
    }
`;
