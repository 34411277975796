import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'
import InlineMove from "../../chess/tools/InlineMove";

export default function SimpleHistoryViewer(props) {
    let {history = []} = props;
    let items = Array.from({length: +Math.floor(history.length / 2) + +Math.ceil(history.length % 2)}, (a, j) => ({id: uuid()}));
    console.log('SimpleHistoryViewer: history, items = ', history, items);
    let p = (history.length == 0) ? 0 : (history[0].color == 'b' ? 1 : 0);
    for (let i in history) {
        let j = Math.floor((+i + +p) / 2);
        let h = history[i];
        items[j] = (items[j] == undefined) ? {id: uuid()} : items[j];
        items[j][h.color] = h;
    }
    console.log('filled up items = ', items);


    return (
        <Wrapper>
            {items.map((it, k) => {
                let {w, b} = it;
                return (
                    <Row key={`item_${it.id}_${k}`}>
                        <Half>
                            <span>
                                {`${+k + 1}) `}
                            </span>
                            {w == undefined ? null :
                                <span>
                                    <InlineMove move={w.san}/>
                                </span>
                            }
                        </Half>
                        <Half>
                            {b == undefined ? null :
                                <span>
                                    <InlineMove move={b.san}/>
                                </span>
                            }
                        </Half>
                    </Row>
                );
            })}
        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Row = styled.div`
    height: 32px;
`;

const Half = styled.div`
    box-sizing: border-box;
    width: 50%;
    display: inline-block;
    vertical-align: top;
    padding: 5px;
    border: 1px solid lightgrey;
`;
