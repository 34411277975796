import React, {useState} from 'react';
import styled from 'styled-components';
import {v4 as uuid4} from 'uuid';
import SolutionForm, {FIELDS} from './SolutionForm';
import {SOLUTIONS_TYPES, COLORS_MAP} from '../const';
import PgnHelper from "../../pgnViewer/PgnHelper";

export const newVariant = (fen, uid) => {
    return {
        type: SOLUTIONS_TYPES.WIN,
        id: uuid4(),
        fen,
        uid
    };
}

export default function SolutionEditor(props) {
    const {
        fen = '',
        node,
        solution: initSolution = {},
        onDelete = e => e,
        onSave = solution => solution,
        canAdd = () => !solution.variants.find(variant => node && variant.uid === PgnHelper.getUid(node))
    } = props;

    const [selectedVariant, setSelectedVariant] = useState(initSolution.variants[0]);
    const [solution, setSolution] = useState(initSolution);

    const update = e => {
        const {id, name, value} = e;
        const tempSolution = {...solution};

        if (name === FIELDS.TYPE) {
            const match = tempSolution.variants.find(variant => variant.id === id);

            if (match) {
                match[name] = value;
            }
        } else {
            tempSolution[name] = value;
        }

        setSolution(tempSolution);
    };
    const remove = variantId => {
        const tempSolution = {...solution};
        tempSolution.variants = tempSolution.variants.filter(variant => variant.id !== variantId);

        setSolution(tempSolution);

        onDelete({
            variantId,
            solution: tempSolution
        });
    };
    const add = () => {
        const tempSolution = {...solution};
        const variants = tempSolution.variants;
        let newVar = newVariant(fen, PgnHelper.getUid(node));
        tempSolution.variants = variants.concat([newVar]);
        setSolution(tempSolution);
        setSelectedVariant(newVar);
    };

    return (
        <Wrapper>
            {!selectedVariant ? null :
                <SelectedPlaceholder>
                    <div key={selectedVariant.id}>
                        <SolutionForm {...solution}
                                      variant={selectedVariant}
                                      canDelete={true}
                                      onDelete={remove}
                                      onChange={update}
                                      onSave={() => onSave(solution)}/>
                    </div>
                </SelectedPlaceholder>
            }

            <Tabs>
                {solution.variants.map((variant, idx) => (
                    <Tab key={variant.id}
                         selected={variant.id === selectedVariant.id}
                         onClick={() => setSelectedVariant(variant)}
                    >
                        <StatusLine color={COLORS_MAP[variant.type]}/>
                        {idx + 1}
                    </Tab>
                ))}

                <AddTab isActive={canAdd()} onClick={() => {
                    canAdd() && add()
                }}>
                    {'+'}
                </AddTab>
            </Tabs>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
`;

const SelectedPlaceholder = styled.div`
    height: calc(100% - 30px);
    width: 100%; 
    background: whitesmoke;
    padding-left: 5px;
    padding-right: 5px;
    box-sizing: border-box;
`;

const Tabs = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
    height: 30px;
    width: 100%;
    margin-top: 10px;
`;

const Tab = styled.div`
    border: 1px solid whitesmoke;
    display: flex;
    flex: 1;
    align-items: center;
    position: relative;
    justify-content: center;
    background: ${props => (props.selected ? '#293742' : 'white')};
    color: ${props => (props.selected ? 'white' : 'black')};
    cursor: ${props => (props.selected ? 'default' : 'pointer')};    
    :hover{opacity: ${props => (props.selected ? 1 : 0.7)};  }
`;

const AddTab = styled.div`
    border: 1px solid whitesmoke;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    background: ${props => (props.selected ? '#293742' : 'white')};
    color: ${props => (props.selected ? 'white' : 'black')};
    cursor: ${props => (props.selected ? 'default' : 'pointer')};
    flex: 1;
    opacity: ${props => (props.isActive ? 1 : 0.5)}; 
    :hover{${props => (props.isActive ? 'opacity: 0.7' : '')};}
`;

const StatusLine = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 3px;
    background-color: ${props => props.color};    
`;
