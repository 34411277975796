import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import CommonHelper from '../../../helpers/CommonHelper'

const muteImg = require('../../../assets/images/mute.svg');
const soundImg = require('../../../assets/images/volume.svg');

const hasVideoImage = require('../../../assets/images/film.svg');
const noVideoImage = require('../../../assets/images/no-video.svg');

export default function UserVideoBox(props) {
    const {
        scaled = false,
        isMe = false,
        user = undefined,
        hasAudio = false,
        hasVideo = true,
        canToggle = true,
        streamManager = undefined,

        toggleVideo = () => {

        },

        toggleAudio = () => {

        }

    } = props;
    const videoRef = useRef();
    const [ready, setReady] = useState(false);
    let streamId = props.streamManager.stream.streamId;

    useEffect(() => {
        if (props && !!videoRef.current) {
            // let videoId = `video_${user == undefined ? 'me' : user.id}`;
            let videoId = `video_${user == undefined ? 'me' : `${user.id}_${streamId}`}`;
            let vid = document.getElementById(videoId);
            console.log('UserVideoBox: useEffect: vid = ', vid);

            // props.streamManager.addVideoElement(videoRef.current);
            props.streamManager.addVideoElement(vid);
            // console.log('props.streamManager.addVideoElement(videoRef.current); props.streamManager = ', props.streamManager);
            // setTimeout(() => {
            //     try {
            //         // videoRef.current.play();
            //         vid.play();
            //     } catch (ee) {
            //         console.log('can not play! ee = ', ee);
            //     }
            // }, 1000);
        }
    }, [isMe, (user == undefined) ? 'aa' : user.id]);

    useEffect(() => {
        if (ready == false) {
            return;
        }
        setTimeout(() => {
            try {
                let videoId = `video_${user == undefined ? 'me' : user.id}`;
                let vid = document.getElementById(videoId);
                if (vid != undefined) {
                    vid.play();
                }
            } catch (ee) {
                console.log('can not play! ee = ', ee);
            }
        }, 10);
    }, [ready]);

    console.log('UserVideoBox: render: user = ', user);
    let vi_id = `video_${user == undefined ? 'me' : user.id}`;

    return (
        <Wrapper>

            <VideoPlaceholder>
                <Video
                    id={`video_${user == undefined ? 'me' : `${user.id}_${streamId}`}`}
                    ref={videoRef}
                    onCanPlay={() => {
                        console.log('onCanPlay!');
                        setReady(true);
                    }}
                    onClick={() => {
                        try {
                            let el = document.getElementById(`video_${user == undefined ? 'me' : user.id}`);
                            if (el != undefined) {
                                el.play();
                            }
                        } catch (e) {
                            console.log('click: e = ', e);
                        }
                    }}
                ></Video>
            </VideoPlaceholder>

            <UserInfoPlaceholder>
                {(isMe == true || user == undefined) ? 'Я' : CommonHelper.getUserName(user)}
            </UserInfoPlaceholder>

            {canToggle == false ? null :
                <VideoControlsPlaceholder>
                    <ControlRoundButton onClick={() => {
                        toggleAudio();
                    }}>
                        <ControlImg src={hasAudio ? soundImg : muteImg}/>
                    </ControlRoundButton>

                    <ControlRoundButton onClick={() => {
                        toggleVideo();
                    }}>
                        <ControlImg src={hasVideo ? hasVideoImage : noVideoImage}/>
                    </ControlRoundButton>

                </VideoControlsPlaceholder>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    background: black;
    box-sizing: border-box;
    position: relative;
`;

const VideoPlaceholder = styled.div`
    width: 100%;    
    height: 100%;
    position: relative;
    padding-top: 20px;
`;


const Video = styled.video`
    width: 100%;
    height: 100%;
`;

const UserInfoPlaceholder = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 2;
    box-sizing: border-box;
    padding: 4px;
    background: whitesmoke;
    color: black;
`;

const VideoControlsPlaceholder = styled.div`
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 3;
    box-sizing: border-box;
    padding: 4px;
    background: transparent;
    color: black;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const ControlRoundButton = styled.div`
    background: white;
    border-radius: 1000px;
    background: rgba(255, 255, 255, 0.8);
    cursor: pointer;
    opacity: 0.12;
    margin-left: 10px;
    margin-right: 10px;
    width: 32px;
    height: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${Wrapper}:hover & {
      opacity: 0.6;
    }
    :hover{
      opacity: 1 !important;
    }
`;

const ControlImg = styled.img`
    width: 24px;
    height: 24px;
`;
