import ru from './i18n/ru.json';
import en from './i18n/en.json';
import fr from './i18n/fr.json';
import cn from './i18n/cn.json';
import de from './i18n/de.json';
import ls from 'local-storage'
import {getDefaultLang} from "./LangSwitcherDropdown";

export function getCurrentLang(){
    let lang = ls('lang') || 'ru';
    return lang;
}

export const translate = (key) => {
    let lang = ls('lang') || getDefaultLang();
    let langData = ru; // default

    if (lang === 'ru') {
        langData = ru;
    }
    if (lang === 'en') {
        langData = en;
    }
    if (lang === 'fr') {
        langData = fr;
    }
    if (lang === 'cn') {
        langData = cn;
    }
    if (lang === 'de') {
        langData = de;
    }
    return langData[key];
}

export const printAllEnPhrases = () => {
    let keys = Object.keys(en);
    let vals = keys.map(x => en[x]);
    let s = vals.join('\n');
    console.log(s);
}