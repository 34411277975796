import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import CommonHelper from "../../../helpers/CommonHelper";

import * as actions from '../../../redux/actions/UsersActions'
import Tabs, {TabItem} from "../../ui/Tabs";
import TeacherGroupsPanel from "../../teacher_groups/panels/TeacherGroupsPanel";
import UpdateUserInfoForm from "../../users/forms/UpdateUserInfoForm";

export default function AdminTeacherPanel(props) {
    let {id} = props;
    const dispatch = useDispatch();
    let {currentUser, loading, teacher} = useMappedState(useCallback(state => {
        let teacher = state.users.usersMap.get(id);
        return {
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            loading: state.users.loading,
            teacher: teacher,

        }
    }, [id]));

    let name = (teacher == undefined) ? '' : CommonHelper.getUserName(teacher);

    useEffect(() => {
        console.log('loading loadTeacherLinks');
        dispatch(actions.loadUserById(id));
        dispatch(actions.loadTeacherLinks(id)).then(pld => {
            if (pld.links != undefined) {
                dispatch(actions.loadUsersByIds(pld.links.map(a => a.studentId)))
            }
        });
    }, [id]);

    if (teacher == undefined) {
        return (
            <Code/>
        )
    }

    return (
        <Wrapper>

            <Heading>
                <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => {
                    CommonHelper.linkTo(`/teachers`);
                }}>
                    Все преподаватели
                </span>
                {' / '}
                <span>
                    {name}
                </span>
            </Heading>

            <Cont>
                <Tabs tabs={[
                    {
                        label: 'Группы',
                        content: (
                            <TabItem>
                                <TeacherGroupsPanel teacherId={id}/>
                            </TabItem>
                        )
                    },
                    {
                        label: 'Информация',
                        content: (
                            <TabItem>
                                <UpdateUserInfoForm {...teacher} loading={loading} onSave={async d => {
                                    await dispatch(actions.updateUser({id: teacher.id, ...d}))
                                }} />
                            </TabItem>
                        )
                    }
                ]}/>
            </Cont>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Cont = styled.div`
    padding-top: 10px;
`;

const Heading = styled.div`
    
`;


const SubHeading = styled.div`
    
`;
