import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import validator from 'validator'

import {Button} from "../../ui/Button";
import {Input} from "../../ui/Input";

import Spin from 'arui-feather/spin'

export default function LoginForm(props) {
    let {
        loading, onSubmit = () => {
        }
    } = props;
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    let canSubmit = (password != undefined && password.length > 3 && validator.isEmail(email) == true);

    return (
        <Wrapper>

            <Field>
                <Input placeholder={'Email'} value={email} onChange={e => {
                    setEmail(e.target.value);
                }}/>
            </Field>

            <Field>
                <Input type={'password'} placeholder={'Пароль'} value={password} onChange={e => {
                    setPassword(e.target.value);
                }}/>
            </Field>

            <Field>
                {loading == true ?
                    <Spin visible={true} /> :
                    <Button disabled={!canSubmit} style={{marginLeft: 0}} onClick={() => {
                        onSubmit({email, password})
                    }}>
                        Войти
                    </Button>
                }

            </Field>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    padding: 20px;
`;

const Label = styled.div`
    font-weight: bold;
`;

const Field = styled.div`
    margin-bottom: 10px;
`;

