import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'normalize.css/normalize.css'
import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/icons/lib/css/blueprint-icons.css'

import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";

import App from './App';
import * as serviceWorker from './serviceWorker';

import axios from 'axios'

const getToken = () => {
    let tk = window.localStorage.getItem('auth_token');
    if (tk == undefined || tk == '') {
        return;
    }
    return tk;
};

axios.interceptors.request.use((config) => {
    let token = getToken();
    if (token != undefined) {
        let url = new URL(config.url);
        url.searchParams.set('token', token);
        config.url = url.toString();
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

Sentry.init({
    dsn: "https://243347fa3b684dd58f2991371aa6d3ff@o455397.ingest.sentry.io/5446916",
    integrations: [
        new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
});

ReactDOM.render(<App/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
