import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';


export default function SimpleTexturesView(props) {

    return (
        <Wrapper>
            <FigImg src={props.whiteTextureUrl}/>
            <FigImg src={props.blackTextureUrl}/>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const FigImg = styled.img`
    width: 80px;
    height: 80px;
    margin: 5px;
    display: inline-block;
    vertical-align: top;
`;
