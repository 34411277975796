import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const ExercisesAPI = {

    getAllExercises() {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/exercises/all/smart`).then(d => d.data).then(exercises => {
                if (exercises.error != undefined) {
                    return reject(exercises.error);
                }
                resolve(exercises);
            }).catch(err => reject(err));
        });
    },

    getExercise(id) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/exercise/${id}`).then(d => d.data).then(exercises => {
                if (exercises.error != undefined) {
                    return reject(exercises.error);
                }
                resolve(exercises);
            }).catch(err => reject(err));
        });
    },

    createExercise(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/exercises/create`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    updateExercise(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/exercises/update`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    deleteExercise(id) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/exercises/delete/${id}`).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

}

export default ExercisesAPI;
