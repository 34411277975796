const OpenviduHelper = {

    getClientDataBySub(sub){
        try{
            let cs = (sub.stream == undefined) ? undefined : (sub.stream.connection == undefined ? undefined : (sub.stream.connection.data == undefined ? undefined : sub.stream.connection.data));
            let uData = cs == undefined ? undefined : JSON.parse(cs).clientData;
            return uData;
        }catch(e){
            return undefined;
        }
    }

}

export default OpenviduHelper;
