import React, {useRef, useState, useEffect} from 'react';
import styled from 'styled-components';
import Workspace from './Workspace/Workspace';
import IllegalWorkspace, {getOptimalBoardWidth} from './Workspace/IllegalWorkspace';
import Info from './Info/Info';
import Editor from './Editor/Editor';
import useComponentSize from "@rehooks/component-size";
import {translate} from "../../../utils/Translate";

const TAB_OPTIONS = [
    {
        label: 'Информация',
        value: 'info'
    },
    {
        label: 'Редактор решений',
        value: 'editor'
    },
    {
        label: translate('view'),
        value: 'view'
    }
]

export default function Exercise(props) {
    const {
        height = '100%',
        headerHeight = '55px',
        tabsHeight = '21px',
        paddingHeight = '10px',
        data = {},
        canEdit = true,
        allowHandleKeyEvents = true,
        onSave = data => data,
        onSaveInfo = e => e,
        onSaveSolution = e => e,
        onHeaderClick = e => e,
        onStepLimitReached = e => {
            console.log('onStepLimitReached', e);
        },
        onTimeFinished = () => {
            console.log('onTimeFinished');
        },
        onTimerTick = e => {
            //console.log('onTimerTick', e);
        },
        onSubExerciseStart = e => {
            console.log('onSubExerciseStart', e);
        },
        onSubExerciseEnd = e => {
            console.log('onSubExerciseEnd', e);
        },
        onSubExerciseSelect = e => {
            console.log('onSubExerciseSelect', e);
        },
        onExerciseFinish = e => {
            console.log('onExerciseFinish', e);
        },

        boardWidthFraction = 0.4 // board is 40% width

    } = props;
    const [mode, setMode] = useState(canEdit ? 'info' : 'view');

    console.log('Exercise: render: data = ', data);

    return (
        <Wrapper>

            {canEdit && (
                <TabNamesList>
                    {TAB_OPTIONS.map(a => (
                        <TabName key={a.value}
                                 selected={(a.value == mode)}
                                 onClick={() => {
                                     setMode(a.value);
                                 }}>
                            {a.label}
                        </TabName>
                    ))}
                </TabNamesList>
            )}

            <Content>

                {(mode != 'view') ? null :
                    <>
                    {data.isIllegal ?
                        <IllegalWorkspace {...data}
                                          boardWidthFraction={boardWidthFraction}
                                          onExerciseFinish={onExerciseFinish}
                                          onStepLimitReached={onStepLimitReached}
                                          onSubExerciseStart={onSubExerciseStart}
                                          onTimeFinished={onTimeFinished}/>
                        :
                        <Workspace {...data}
                                   allowHandleKeyEvents={allowHandleKeyEvents}
                                   boardWidthFraction={boardWidthFraction}
                                   onSubExerciseStart={onSubExerciseStart}
                                   onSubExerciseEnd={onSubExerciseEnd}
                                   onExerciseFinish={onExerciseFinish}
                                   onTimeFinished={onTimeFinished}
                                   onTimerTick={onTimerTick}/>}
                    </>
                }

                {(mode != 'info') ? null :
                    <Info data={data}
                          onSave={info => {
                              onSave({...data, ...info});
                              onSaveInfo(info);
                          }}/>
                }

                {(mode != 'editor') ? null :
                    <Editor {...data}
                            allowHandleKeyEvents={allowHandleKeyEvents}
                            onSubExerciseSelect={onSubExerciseSelect}
                            onSave={solutions => {
                                console.log('onSave: solutions = ', solutions);
                                data.solutions = solutions;
                                onSave(data);
                                onSaveSolution(solutions);
                            }}/>
                }


            </Content>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    font-family: Montserrat;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;


const TabNamesList = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;
    justify-content: center;
    height: 70px;
    background: white;
`;


const TabName = styled.div`
    color: ${props => (props.selected ? '#085BFF' : '#8593A8')};
    font-weight: bold;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    margin-left: 24px;
    margin-right: 24px;
    cursor: ${props => (props.selected ? 'default' : 'pointer')};
    :hover{
      opacity: ${props => (props.selected ? 1 : 0.8)};
    }
`;


const Content = styled.div`
    flex: 1;
`;

