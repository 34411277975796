import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import {Map} from "immutable";

import {useDispatch, useMappedState} from 'redux-react-hook'

import * as coursesActions from '../../../redux/actions/CoursesActions'

import {Code} from 'react-content-loader'
import CommonHelper from "../../../helpers/CommonHelper";
import axios from "axios";
import {API_ENDPOINT} from "../../../constants/config";
import {translate} from "../../../utils/Translate";

async function getAllShortUnitsMap() {
    let arr = (await axios.get(`${API_ENDPOINT}/all-short-units`)).data.result;
    let map = arr.reduce((mp, m) => {
        let oldArr = (mp.get(m.courseId) == undefined) ? [] : mp.get(m.courseId);
        let newArr = oldArr.concat([m]);
        return mp.set(m.courseId, newArr);
    }, Map());
    return map;
}

function getSortedCourses(arr) {
    let gs = x => (x == undefined ? '' : x.toLowerCase());
    return arr.sort((a, b) => {
        let s1 = gs(a.name);
        let s2 = gs(b.name);
        if (s1 > s2) {
            return 1;
        }
        if (s1 < s2) {
            return -1;
        }
        return 0;
    })
}

export default function StudentCoursesPanel() {


    const [unitsMap, setUnitsMap] = useState(Map());

    const {loading, courses, currentUser, isAdmin} = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let isAdmin = ((currentUser != undefined) && (currentUser.userRole == 'admin' || currentUser.userRole == 'methodist'));
        return {
            loading: state.courses.loading,
            courses: state.courses.coursesMap.toArray().sort((a, b) => (+b.timestamp - +a.timestamp)),
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            isAdmin: isAdmin
        }
    }, []));
    const dispatch = useDispatch();
    const [xCourses, setXCourses] = useState([]);

    useEffect(() => {
        dispatch(coursesActions.loadMyCourses()).then(pld => {
            console.log('loadMyCourses: pld = ', pld);
            setXCourses(pld.courses);
        });
        getAllShortUnitsMap().then(x => {
            setUnitsMap(x);
        });
    }, []);

    if (courses.length == 0 && loading == true) {
        return (
            <Code/>
        )
    }

    console.log('StudentCoursesPanel: render: courses = ', courses);
    console.log('StudentCoursesPanel: render: xCourses = ', xCourses);
    let sortedCourses = getSortedCourses(xCourses);

    return (
        <Wrapper>

            <CoursesList>
                {sortedCourses.map((c, k) => {
                    let {name, description, exercisesIds = []} = c;
                    let units = (unitsMap.get(c.id) == undefined) ? [] : unitsMap.get(c.id);
                    return (
                        <CourseItem key={c.id} onClick={() => {
                            CommonHelper.linkTo(`/course/${c.id}`);
                        }}>

                            <Name>
                                {name}
                            </Name>

                            <RowsPlaceholder>
                                <Row>
                                    <RowName>
                                        {translate('number_of_modules')}
                                    </RowName>
                                    <RowValue>
                                        {units.length}
                                    </RowValue>
                                </Row>

                                <Row>
                                    <RowName>
                                        {translate('rating')}
                                    </RowName>
                                    <RowValue>
                                        {c.minRating == undefined ? null :
                                            `${translate('from')} ${c.minRating}`
                                        }
                                    </RowValue>
                                </Row>

                                {/*<Row>*/}
                                {/*    <RowName>*/}
                                {/*        Упражнений*/}
                                {/*    </RowName>*/}
                                {/*    <RowValue>*/}

                                {/*    </RowValue>*/}
                                {/*</Row>*/}


                            </RowsPlaceholder>

                            {/*<Description>*/}
                            {/*    {description}*/}
                            {/*</Description>*/}

                        </CourseItem>
                    )
                })}
            </CoursesList>

        </Wrapper>
    );
}

const RowsPlaceholder = styled.div`

`;

const RowValue = styled.div`
  color: rgba(31, 32, 65, 0.75);
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
`;

const RowName = styled.div`

`;

const Row = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(31, 32, 65, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  :last-of-type {
    border-bottom: none;
    padding-bottom: 0px;
  }
`;

const Wrapper = styled.div`

`;

const CoursesList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: 920px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 620px) {
    grid-template-columns: 1fr;
  }
`;

const CourseItem = styled.div`
  border: 1px solid whitesmoke;
  border-radius: 4px;
  cursor: pointer;
  background: white;
  margin: 15px;
  padding: 25px;

  :hover {
    opacity: 0.8;
  }
`;

const Name = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 20px;
`;

const Description = styled.div`

`;

const BottomPlaceholder = styled.div`
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid whitesmoke;
`;
