import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import {Set} from 'immutable'
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import {FACELESS_AVATAR} from "../../../constants/config";


import * as actions from '../../../redux/actions/TeacherGroupsActions'
import * as lessonsActions from '../../../redux/actions/LessonsActions'
import RecordingsAPI from "../../../api/RecordingsAPI";
import NiceModal from "../../modals/NiceModal";
import {translate} from "../../../utils/Translate";

export default function StudentRecordingsPanel(props) {
    let {userId} = props;
    const dispatch = useDispatch();
    const [recordings, setRecordings] = useState([]);
    const [selectedId, setSelectedId] = useState(undefined);
    const [fetching, setFetching] = useState(false);

    let {
        currentUser,
        loading,
        currentUserId,
        teacherGroups,
        getUsersByIds,
        uId,
        lessons
    } = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let uId = (userId == undefined) ? currentUserId : userId;
        let teacherGroups = state.teacherGroups
            .teacherGroupsMap.toArray()
            .filter(g => (g.studentsIds != undefined && g.studentsIds.indexOf(uId) > -1))
            .filter(a => (a != undefined && a.deleted != true))
            .sort((a, b) => (+b.timestamp - +a.timestamp));
        let getUsersByIds = ids => ids.map(a => state.users.usersMap.get(a)).filter(a => (a != undefined));
        return {
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            loading: state.users.loading || state.teacherGroups.loading,
            currentUserId: state.users.currentUserId,
            teacherGroups: teacherGroups,
            getUsersByIds: getUsersByIds,
            lessons: state.lessons.lessonsMap.toArray().sort((a, b) => (+b.startTimestamp - +a.startTimestamp)),
            uId: uId
        }
    }, []));

    useEffect(() => {
        setFetching(true);
        dispatch(actions.loadAllTeacherGroups()).then(d => {
            dispatch((lessonsActions.loadAllLessons())).then(d => {
                // console.log('all lessons loaded! d = ', d);
                let ids = d.lessons.map(x => x.id);
                RecordingsAPI.getManyRecordings(ids).then(arr => {
                    console.log('loaded recordings = ', arr);
                    setRecordings(arr);
                    setFetching(false);
                })
                // RecordingsAPI.getManyRecordings()
            })
        });
    }, [uId]);

    if (loading == true || fetching == true) {
        return (
            <Code/>
        )
    }

    let recordingsSet = recordings.reduce((st, x) => (st.add(x.sessionId)), Set());
    let lessonsWithRecordings = lessons.filter(x => (recordingsSet.has(x.id)));

    const selectedLesson = (selectedId == undefined) ? undefined : lessons.filter(x => (x.id == selectedId))[0];
    const selRecs = (selectedLesson == undefined) ? [] : recordings.filter(x => (x.sessionId == selectedId)).sort((a, b) => (+b.timestamp - +a.timestamp));


    return (
        <Wrapper>

            <TopPlaceholder>
                <TopHeading>
                    {translate('lessons_recordings')}
                </TopHeading>
            </TopPlaceholder>

            <RecordingsList>
                {lessonsWithRecordings.map((x, i) => {
                    let recs = recordings.filter(xx => (xx.sessionId == x.id));
                    return (
                        <RecItem key={x.id} onClick={() => {
                            setSelectedId(x.id);
                        }}>
                            <div>
                                {`${translate('lesson')} ${moment(x.startTimestamp).format('DD.MM.YYYY')} (${recs.length})`}
                            </div>
                            {/*<ol>*/}
                            {/*    {recs.map((z, j) => {*/}
                            {/*        return (*/}
                            {/*            <li key={z.id} >*/}
                            {/*                {`${moment(z.timestamp).format('HH:mm')}`}*/}
                            {/*            </li>*/}
                            {/*        )*/}
                            {/*    })}*/}
                            {/*</ol>*/}
                        </RecItem>
                    )
                })}
            </RecordingsList>

            {selectedLesson == undefined ? null :
                <NiceModal onClose={() => {
                    setSelectedId(undefined);
                }}>

                    <InnerModal>
                        <h1>
                            {`${translate('lesson')} ${moment(selectedLesson.startTimestamp).format('DD.MM.YYYY')}`}
                        </h1>

                        <div>
                            {selRecs.map((x, i) => {
                                return (
                                    <div key={x.id}>
                                        <video controls={true}>
                                            <source src={x.resultUrl}/>
                                        </video>
                                    </div>
                                )
                            })}
                        </div>

                    </InnerModal>

                </NiceModal>
            }

        </Wrapper>
    );
}

const InnerModal = styled.div`
  width: 580px;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Wrapper = styled.div`
  padding: 20px;
  box-sizing: border-box;
`;

const InnerWrapper = styled.div`

`;

const RecItem = styled.div`
  padding: 5px;
  margin-bottom: 10px;
  border-bottom: 1px solid whitesmoke;
  background: white;
  border-radius: 6px;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

const RecordingsList = styled.div`

`;

const TopPlaceholder = styled.div`
  margin-bottom: 43px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TopHeading = styled.div`
  font-size: 24px;
  line-height: 26px;
  letter-spacing: 0.166667px;
  color: #333539;
`;