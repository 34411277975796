import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'
import * as actions from "../../../redux/actions/TeacherGroupsActions";
import CommonHelper from "../../../helpers/CommonHelper";
import * as usersActions from "../../../redux/actions/UsersActions";

import Spin from 'arui-feather/spin'

import {Label} from "../../ira/ui/Inputs";

import {Button} from "../../ui/Button";
import UpdateTeacherGroupMembershipPanel from "./UpdateTeacherGroupMembershipPanel";

import Tabs, {TabItem} from "../../ui/Tabs";
import TeacherGroupLessonsPanel from "../../lessons/panels/TeacherGroupLessonsPanel";
import RoomChatPanel from "../../chat/panels/RoomChatPanel";
import NiceModal from "../../modals/NiceModal";
import UpdateTeacherGroupForm from "../forms/UpdateTeacherGroupForm";
import {RedButton, BlueButton} from "../../ira/ui/Buttons";
import NiceConfirm from "../../modals/NiceConfirm";
import NiceModalWithHeading from "../../modals/NiceModalWithHeading";
import SmartSelect from "../../ira/ui/SmartSelect";
import {translate} from "../../../utils/Translate";

export default function StudentGroupPanel(props) {
    let {id} = props;
    const dispatch = useDispatch();
    const [selectedId, setSelectedId] = useState(undefined);
    const [modalVisible, setModalVisible] = useState(false);
    const [editVisible, setEditVisible] = useState(false);
    const [addStudentModalVisible, setAddStudentModalVisible] = useState(false);

    const [deleting, setDeleting] = useState(false);
    const [deleteId, setDeleteId] = useState(undefined);
    const [deleteVisible, setDeleteVisible] = useState(false);

    const {student, currentUser, userRole, currentUserId, loading, teacherGroup, groupStudents, teacherStudents} = useMappedState(useCallback(state => {
        let group = state.teacherGroups.teacherGroupsMap.get(id);
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let currentUserRole = (currentUser == undefined) ? undefined : currentUser.userRole;
        let tId = (group == undefined) ? undefined : group.teacherId;
        console.log('!!! tId = ', tId);
        let links = state.users.linksMap.toArray().filter(a => (a.teacherId == tId));
        console.log('links = ', links);
        let teacherStudents = links.map(l => state.users.usersMap.get(l.studentId)).filter(a => (a != undefined));
        let studentsIds = (group == undefined || group.studentsIds == undefined) ? [] : group.studentsIds;
        let groupStudents = studentsIds.map(stId => state.users.usersMap.get(stId)).filter(a => (a != undefined));
        return {
            userRole: currentUserRole,
            teacherGroup: group,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            currentUserId: state.users.currentUserId,
            loading: state.users.loading || state.teacherGroups.loading,
            groupStudents: groupStudents,
            teacherStudents: teacherStudents
        }
    }, [id]));
    let canEdit = (currentUser != undefined && (currentUser.userRole == 'admin' || currentUser.userRole == 'teacher'));

    useEffect(() => {
        dispatch(actions.loadTeacherGroupById(id)).then(pld => {
            console.log('qqw_ > pld = ', pld);
            if (pld.teacherGroups != undefined && pld.teacherGroups.length > 0) {
                let gr = pld.teacherGroups[0];
                dispatch(usersActions.loadTeacherLinks(gr.teacherId)).then(pld => {
                    if (pld.links != undefined) {
                        dispatch(usersActions.loadUsersByIds(pld.links.map(a => a.studentId)))
                    }
                });
            }
        });
    }, [id]);

    if (teacherGroup == undefined && loading == true) {
        return (
            <Code/>
        )
    }

    if (teacherGroup == undefined) {
        return null;
    }

    let {name, studentsIds, minRating, maxRating} = teacherGroup;
    let selectedStudent = (selectedId == undefined) ? undefined : groupStudents.filter(a => (a.id == selectedId))[0];

    const deleteStudent = (deleteId == undefined) ? undefined : groupStudents.filter(a => (a.id == deleteId))[0];

    return (
        <Wrapper>

            <TopSection>
                <TopBackPlaceholder onClick={() => {
                    CommonHelper.linkTo(`/groups`);
                }}>
                    <BackImg src={require('../../../assets/images/group_back.svg')}/>
                    <span>
                        {translate('back_to_groups')}
                    </span>
                </TopBackPlaceholder>
                <TopRightPlaceholder>
                </TopRightPlaceholder>
            </TopSection>

            <InfoBox>
                <InfoBoxField>
                    <Circle>
                        <img src={require('../../../assets/images/people.svg')} style={{width: 31}}/>
                    </Circle>
                    <GroupName>
                        {name}
                    </GroupName>
                </InfoBoxField>
                <InfoBoxField>
                    <img src={require('../../../assets/images/last_task_img.svg')}
                         style={{width: 20, height: 22, marginRight: 10}}/>
                    <GroupInfoSpan style={{marginRight: 10}}>
                        {`${translate('lesson')}:`}
                    </GroupInfoSpan>
                    <GroupInfoSpan style={{color: 'rgba(31, 32, 65, 0.9)'}}>
                        {translate('not_created')}
                    </GroupInfoSpan>
                </InfoBoxField>
                <InfoBoxField>
                    <img src={require('../../../assets/images/group_level_img.svg')}
                         style={{width: 20, height: 22, marginRight: 10}}/>
                    <GroupInfoSpan style={{marginRight: 10}}>
                        {`${translate('rating')}:`}
                    </GroupInfoSpan>
                    <GroupInfoSpan style={{color: 'rgba(31, 32, 65, 0.9)'}}>
                        {`${minRating == undefined ? '' : minRating } - ${maxRating == undefined ? '' : maxRating}`}
                    </GroupInfoSpan>
                </InfoBoxField>

                {/*<EditCircleButton src={require('../../../assets/images/group_edit_circle.svg')}*/}
                {/*onClick={() => {*/}
                {/*setEditVisible(true);*/}
                {/*}}/>*/}

            </InfoBox>

            <GroupStudentsTopPlaceholder>
                <TopHeading>
                    {translate('students_in_group')}
                </TopHeading>

                <TopRightPlaceholder>
                </TopRightPlaceholder>

            </GroupStudentsTopPlaceholder>

            <UsersPlaceholder>
                <TopTableHeading>
                    <NameCol>
                        {translate('students')}
                    </NameCol>
                    {userRole == 'student' ? null :
                        <EmailCol>
                            {translate('email')}
                        </EmailCol>
                    }
                    <OptionsCol>
                        {translate('options')}
                    </OptionsCol>
                </TopTableHeading>

                {groupStudents.map((st, k) => {
                    return (
                        <StudentRow key={`${st.id}_${k}`}>
                            <NameCol>
                                <UserAvatar src={st.avatar}/>
                                <UserNameSpan>
                                    {CommonHelper.getUserName(st)}
                                </UserNameSpan>
                            </NameCol>
                            {userRole == 'student' ? null :
                                <EmailCol>
                                    {st.email}
                                </EmailCol>
                            }
                            <OptionsCol>
                                {/*<TrashImg*/}
                                {/*src={require('../../../assets/images/trash-2.svg')}*/}
                                {/*onClick={() => {*/}
                                {/*setDeleteId(st.id);*/}
                                {/*}}*/}
                                {/*/>*/}
                            </OptionsCol>
                        </StudentRow>
                    )
                })}

            </UsersPlaceholder>



            {/*<ContentPlaceholder>*/}

            {/*<Tabs tabs={[*/}
            {/*{*/}
            {/*label: 'Ученики',*/}
            {/*content: (*/}
            {/*<TabItem>*/}
            {/*<SubHeading>*/}
            {/*<span>*/}
            {/*{`Всего ${groupStudents.length} учеников`}*/}
            {/*</span>*/}
            {/*</SubHeading>*/}
            {/*<StudentsList>*/}
            {/*{groupStudents.map((st, k) => {*/}
            {/*return (*/}
            {/*<StudentItem key={`${st.id}_${k}`} onClick={() => {*/}
            {/*setSelectedId(st.id);*/}
            {/*}}>*/}
            {/*<Name>*/}
            {/*{CommonHelper.getUserName(st)}*/}
            {/*</Name>*/}
            {/*</StudentItem>*/}
            {/*)*/}
            {/*})}*/}
            {/*</StudentsList>*/}
            {/*</TabItem>*/}
            {/*)*/}
            {/*},*/}
            {/*{*/}
            {/*label: 'Занятия',*/}
            {/*content: (*/}
            {/*<TabItem>*/}
            {/*<TeacherGroupLessonsPanel*/}
            {/*teacherGroupId={teacherGroup.id} teacherId={currentUserId}/>*/}
            {/*</TabItem>*/}
            {/*)*/}
            {/*},*/}
            {/*{*/}
            {/*label: 'Чат',*/}
            {/*content: (*/}
            {/*<TabItem>*/}
            {/*<ChatPlaceholder>*/}
            {/*<RoomChatPanel roomId={`group_${id}`}/>*/}
            {/*</ChatPlaceholder>*/}
            {/*</TabItem>*/}
            {/*)*/}
            {/*},*/}
            {/*]}/>*/}


            {/*</ContentPlaceholder>*/}

            {/*<Sidebar width={Math.min(920, window.innerWidth)}*/}
            {/*visible={(selectedStudent != undefined)}*/}
            {/*onCloserClick={() => {*/}
            {/*setSelectedId(undefined);*/}
            {/*}}>*/}
            {/*{selectedStudent == undefined ? null :*/}
            {/*<div>*/}
            {/*{JSON.stringify(selectedStudent)}*/}
            {/*</div>*/}
            {/*}*/}
            {/*</Sidebar>*/}

            {addStudentModalVisible == false ? null :
                <NiceModalWithHeading
                    heading={translate('add_student')}
                    subHeading={translate('select_students_from_the_list_below')}
                    onClose={() => {
                        setAddStudentModalVisible(false);
                    }}>

                    <SgUsersDropdownPlaceholder>

                        <Label>
                            {translate('students_list')}
                        </Label>

                        <SmartSelect
                            options={teacherStudents.map(u => ({value: u.id, label: CommonHelper.getUserName(u)}))}
                            placeholder={'Ученики'}
                            isMulti={true}
                            closeOnSelect={false}
                            selectedValues={groupStudents.map(a => a.id)}
                            onSelect={async a => {
                                await dispatch(actions.updateTeacherGroup({id: id, studentsIds: a}))
                            }}
                        />
                    </SgUsersDropdownPlaceholder>

                    <BlueButton onClick={() => {
                        setAddStudentModalVisible(false);
                    }}>
                        OK
                    </BlueButton>

                </NiceModalWithHeading>
            }

            {deleteStudent == undefined ? null :
                <NiceConfirm
                    heading={'Удалить ученика из группы'}
                    subHeading={`${CommonHelper.getUserName(deleteStudent)} будет удален из этой группы. Вы уверены?`}
                    loading={loading}
                    onCancel={() => {
                        setDeleteId(undefined);
                    }}
                    onConfirm={async () => {
                        let studentsIds = groupStudents.map(a => a.id).filter(a => (a != deleteStudent.id));
                        await dispatch(actions.updateTeacherGroup({id: id, studentsIds: studentsIds}))
                        setDeleteId(undefined);
                    }}
                    confirmText={'УДАЛИТЬ'}
                />
            }

            {editVisible == false ? null :
                <NiceModal onClose={() => {
                    setEditVisible(false);
                }}>

                    <EditInner>
                        <EditInnerHeader>
                            Редактировать группу
                        </EditInnerHeader>

                        <UpdateTeacherGroupForm
                            {...teacherGroup}
                            loading={loading}
                            onSave={async data => {
                                await dispatch(actions.updateTeacherGroup({...data, id: id}));
                                setEditVisible(false);
                            }}/>

                    </EditInner>

                </NiceModal>
            }

            {deleteVisible == false ? null :
                <NiceModal onClose={() => {
                    setDeleteVisible(false);
                }}>

                    <DeleteInner>
                        <EditInnerHeader>
                            {translate('delete_group')}
                        </EditInnerHeader>

                        <SubModalHeading>
                            {translate('are_you_sure_you_want_to_delete_group')}
                        </SubModalHeading>

                        <DeleteButtonsPlaceholder>
                            <DeleteOutlineButton onClick={() => {
                                setDeleteVisible(false);
                            }}>
                                {translate('cancel')}
                            </DeleteOutlineButton>
                            <DeleteActionButton onClick={async () => {
                                setDeleting(true);
                                await dispatch(actions.updateTeacherGroup(({id: id, deleted: true})));
                                setDeleting(false);
                                CommonHelper.linkTo('/groups');
                            }}>
                                <Spin visible={deleting} theme={'alfa-on-color'}/>
                                {deleting == true ? null :
                                    translate('delete')
                                }
                            </DeleteActionButton>
                        </DeleteButtonsPlaceholder>


                    </DeleteInner>

                </NiceModal>
            }


        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const SgUserPlaceholder = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
`;

const SgUsersDropdownPlaceholder = styled.div`
    width: 100%;
    margin-bottom: 43px;
`;


const TrashImg = styled.img`
    width: 24px;
    height: 24px;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;


const TopHeading = styled.div`
    font-weight: normal;
    font-size: 24px;
    line-height: 26px;
    display: flex;
    align-items: center;
    letter-spacing: 0.166667px;
    color: #333539;
`;


const GroupStudentsTopPlaceholder = styled.div`
    margin-bottom: 43px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;


const UsersPlaceholder = styled.div`
    background: white;
    border-radius: 4px;
`;

const UserAvatar = styled.div`
    width: 32px;
    height: 32px;
    background: #8593A8;
    border-radius: 1000px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: ${props => (props.src == undefined ? 'none' : `url(${props.src})`)};
    margin-right: 10px;
`;

const TopTableHeading = styled.div`
    background: #FFA71B;
    border-radius: 4px;
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #202224;
    box-sizing: border-box;
    padding-right: 31px;
`;

const StudentRow = styled.div`
    padding-top: 27px;
    padding-bottom: 27px;
    border-bottom: 0.4px solid rgba(81, 139, 219, 0.4);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 31px;
    box-sizing: border-box;
`;

const NameCol = styled.div`
    width: 350px;
    box-sizing: border-box;
    padding-left: 31px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const OptionsCol = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;

const UserNameSpan = styled.span`
    color: #202224;
    mix-blend-mode: normal;
    opacity: 0.9;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
`;

const EmailCol = styled.div`
    width: 200px;
`;

const SubModalHeading = styled.div`
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.171429px;
    color: rgba(31, 32, 65, 0.7);
    margin-bottom: 43px;
`;

const DeleteOutlineButton = styled.div`
    width: 194px;
    margin: 7px;
    height: 44px;
    border: 1px solid #333333;
    box-sizing: border-box;
    border-radius: 100px;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    justify-content: center;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    color: #333333;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;

const DeleteActionButton = styled.div`
    margin: 7px;
    width: 194px;
    height: 44px;
    background: #FF624F;
    padding-left: 36px;
    padding-right: 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: white;
    cursor: pointer;
    border-radius: 1000px;
    font-size: 12px;
    line-height: 15px;
    :hover{
      opacity: 0.8;
    }
`;

const DeleteButtonsPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const DeleteInner = styled.div`
    width: 420px;
    @media(max-width: 540px){
      width: 100%;
    }
`;

const EditInner = styled.div`
    width: 540px;
    @media(max-width: 640px){
      width: 100%;
    }
`;


const EditInnerHeader = styled.div`
    text-align: center;
    letter-spacing: 0.166667px;
    color: #333539;
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 29px;
`;


const EditCircleButton = styled.img`
    width: 44px;
    height: 44px;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;

const GroupInfoSpan = styled.span`
    letter-spacing: 0.171429px;
    color: rgba(31, 32, 65, 0.5);
`;

const InfoBox = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 5px 10px rgba(31, 32, 65, 0.05);
    border-radius: 4px;
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 85px;
`;

const InfoBoxField = styled.div`
    flex: 1;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const GroupName = styled.div`
    font-size: 19px;
    line-height: 23px;
    color: #1F2041;
    font-weight: bold;
`;

const Circle = styled.div`
    background: #A36EFF;
    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 10px 20px rgba(31, 32, 65, 0.1);
    border-radius: 32px;
    width: 64px;
    height: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 21px;
`;

const CreateLessonButton = styled.div`
    height: 44px;
    background: #FF624F;
    font-size: 12px;
    line-height: 15px;
    padding-left: 36px;
    padding-right: 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: white;
    cursor: pointer;
    border-radius: 1000px;
    :hover{
      opacity: 0.8;
    }
`;

const TopSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
`;

const BackImg = styled.img`
    width: 7.5px;
    height: 12px;
    margin-right: 6px;
`;

const TopBackPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.166667px;
    color: #8593A8;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;

const TopRightPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Heading = styled.div`
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 20px;
`;

const SubHeading = styled.div`
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 10px;
    opacity: 0.5;
`;


const ContentPlaceholder = styled.div`
    
`;


const StudentsList = styled.div`
    
`;

const StudentItem = styled.div`
    background: white;
    margin: 5px;
    padding: 10px;
    border: 1px solid whitesmoke;
    border-radius: 4px;
    display: inline-block;
    width: calc(33.333% - 10px);
    vertical-align: top;
    :nth-of-type(3n + 1){
      margin-left: 0px;
    }
    @media(max-width: 920px){
      display: block;
      width: 100%;
      margin-bottom: 10px;
    }
`;

const Name = styled.div`
    font-weight: bold;
    font-size: 16px;
`;

const TopControlsPlaceholder = styled.div`
    
`;

const ChatPlaceholder = styled.div`
    height: calc(100vh - 190px);
    border: 1px dashed lightgrey;
`;
