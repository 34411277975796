import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import SimpleUploadPanel from "../../../upload/SimpleUploadPanel";

const ITEMS = Array.from({length: 8}).map((a, j) => (+j));

const getFigure = (i, j) => {
    if (i == 1) {
        return 'p';
    }
    if (i == 6) {
        return 'P';
    }
    const getDFig = x => {
        if (x == 0 || x == 7) {
            return 'r';
        }
        if (x == 1 || x == 6) {
            return 'n';
        }
        if (x == 2 || x == 5) {
            return 'b';
        }
        if (x == 3) {
            return 'q'
        }
        if (x == 4) {
            return 'k'
        }
    }
    if (i == 0) {
        return getDFig(j);
    }
    if (i == 7) {
        return getDFig(j).toUpperCase();
    }
}

export default function SimpleBoardViewTool(props) {
    const {
        whiteColor = 'white',
        blackColor = 'black',
        data = {},
        onChange = newData => {

        },
        canUpdate = false,
        whiteTextureUrl = undefined,
        blackTextureUrl = undefined,
        hasFigures = true
    } = props;

    console.log('SimpleBoardViewTool: data = ', data);

    return (
        <Wrapper>

            {ITEMS.map((it, i) => {
                return (
                    <Row key={i}>
                        {ITEMS.map((c, j) => {
                            let fig = getFigure(i, j);
                            let url = data[fig];
                            let isWhite = ((i + j) % 2 == 0);
                            let col = [whiteColor, blackColor][(i + j) % 2];
                            return (
                                <Col color={col} key={j} texture={isWhite ? whiteTextureUrl : blackTextureUrl}>
                                    {hasFigures == false ? null :
                                        <>
                                        {canUpdate == false ?
                                            <>
                                            {url == undefined ? (fig == undefined ? null :
                                                <BubbleSpan>{fig}</BubbleSpan>) :
                                                <FigureImage src={url}/>
                                            }
                                            </>
                                            :
                                            <>
                                            <SimpleUploadPanel onUploaded={url => {
                                                let newD = {...data, [fig]: url};
                                                console.log('SimpleUploadPanel: newData = ', newD);
                                                onChange(newD);
                                            }}>
                                                <UploadPlaceholder>
                                                    {url == undefined ? (fig == undefined ? null :
                                                        <BubbleSpan>{fig}</BubbleSpan>) :
                                                        <FigureImage src={url}/>
                                                    }
                                                </UploadPlaceholder>
                                            </SimpleUploadPanel>
                                            </>
                                        }
                                        </>
                                    }
                                </Col>
                            )
                        })}
                    </Row>
                )
            })}

        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex: 1;
`;

const Col = styled.div`
    background-color: ${props => (props.color)};
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background-image: ${props => (props.texture != undefined ? `url(${props.texture})` : 'none')};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
`;

const FigureImage = styled.img`
    height: 80%;
    width: 80%;
`;

const UploadPlaceholder = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const BubbleSpan = styled.div`
    padding: 4px;
    font-size: 10px;
    line-height: 10px;
    box-sizing: border-box;
    width: 40%;
    height: 40%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    text-align: center;
    background-color: #9bb2c7;
    color: white;
`;
