import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import HorChessTemplate from "../templates/HorChessTemplate";
import {useDispatch, useMappedState} from "redux-react-hook";

import Tabs, {TabItem} from "../ui/Tabs";
import StudentGroupPanel from "../teacher_groups/panels/StudentGroupPanel";
import IraTeacherTemplate from "../templates/IraTeacherTemplate";
import StudentExecisesStatsTool from "../stats/tools/StudentExecisesStatsTool";
import NiceModal from "../modals/NiceModal";
import NiceModalWithHeading from "../modals/NiceModalWithHeading";
import ExerciseTagsUserRatingPanel from "../ratings/panels/ExerciseTagsUserRatingPanel";
import CommonHelper from "../../helpers/CommonHelper";
import SimpleStudentExercisesStatsTool from "../stats/tools/SimpleStudentExercisesStatsTool";
import {translate} from "../../utils/Translate";

export default function StudentRatingApp(props) {

    const [modalVisible, setModalVisible] = useState(false);
    const [statsModalVisible, setStatsModalVisible] = useState(false);

    const {
        student, currentUser, currentUserId,
        smartExercisesMap,
        loading, teacherGroup, groupStudents, teacherStudents
    } = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        return {
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            currentUserId: state.users.currentUserId,
            smartExercisesMap: state.smartExercises.smartExercisesMap
        }
    }, []));

    let lastRating = (currentUser == undefined) ? undefined : currentUser.lastRating;

    return (
        <IraTeacherTemplate active={`rating`}>

            <Wrapper>
                {/*<StudentGroupPanel id={id}/>*/}

                <TopTableHeading>

                    <StatsCol style={{textAlign: 'center'}}>
                        {translate('name')}
                    </StatsCol>

                    <StatsCol style={{textAlign: 'center'}}>
                        {translate('exercises_solved')}
                    </StatsCol>

                    <StatsCol style={{textAlign: 'center'}}>
                        {translate('rating')}
                    </StatsCol>

                </TopTableHeading>

                <StudentRow>

                    <StatsCol onClick={() => {

                    }}>
                        <span style={{fontWeight: 'bold'}}>
                                        {CommonHelper.getUserName(currentUser)}
                                    </span>

                    </StatsCol>

                    <StatsCol style={{textAlign: 'center', cursor: 'pointer'}} onClick={() => {
                        setStatsModalVisible(true);
                    }}>
                        <SimpleStudentExercisesStatsTool
                            student={currentUser}
                            smartExercisesMap={smartExercisesMap}
                            studentId={currentUserId}
                        />
                    </StatsCol>

                    <StatsCol>
                                <span style={{cursor: 'pointer'}} onClick={() => {
                                    setModalVisible(true);
                                }}>
                                    <span style={{fontWeight: 'bold'}}>
                                        {lastRating == undefined ? null : lastRating}
                                    </span>

                                </span>
                    </StatsCol>

                </StudentRow>

                <BoxPlaceholder>
                    <ExerciseTagsUserRatingPanel id={currentUser.id}/>
                </BoxPlaceholder>


            </Wrapper>


            {modalVisible == false ? null :
                <NiceModal
                    onClose={() => {
                        setModalVisible(false);
                    }}
                >
                    <div style={{width: 840}}>
                        {/*<UserRatingPanel id={currentUser.id}/>*/}
                        <ExerciseTagsUserRatingPanel id={currentUser.id}/>
                    </div>

                </NiceModal>
            }

            {statsModalVisible == false ? null :
                <NiceModal
                    onClose={() => {
                        setStatsModalVisible(false);
                    }}
                >
                    <div style={{width: 840}}>
                        {/*<UserRatingPanel id={currentUser.id}/>*/}
                        {/*<ExerciseTagsUserRatingPanel id={currentUser.id}/>*/}
                        <StudentExecisesStatsTool
                            student={currentUser}
                            smartExercisesMap={smartExercisesMap}
                            studentId={currentUserId}
                        />
                    </div>

                </NiceModal>
            }

        </IraTeacherTemplate>
    );
}

const BoxPlaceholder = styled.div`
  padding: 30px;
  background: white;
  margin-bottom: 10px;
  margin-top: 10px;
`;

const Wrapper = styled.div`
  padding-top: 31px;
  padding-left: 65px;
  padding-right: 65px;
  box-sizing: border-box;
  width: 1020px;
  margin: 0 auto;
  @media (max-width: 1300px) {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
  }
`;

const UsersPlaceholder = styled.div`
  background: white;
  border-radius: 4px;
`;

const UserAvatar = styled.div`
  width: 32px;
  height: 32px;
  background: #8593A8;
  border-radius: 1000px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${props => (props.src == undefined ? 'none' : `url(${props.src})`)};
  margin-right: 10px;
`;

const TopTableHeading = styled.div`
  background: #FFA71B;
  border-radius: 4px;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #202224;
  box-sizing: border-box;
  padding-right: 31px;
`;

const StudentRow = styled.div`
  padding-top: 27px;
  padding-bottom: 27px;
  border-bottom: 0.4px solid rgba(81, 139, 219, 0.4);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 31px;
  box-sizing: border-box;
  margin-bottom: 5px;
`;

const NameCol = styled.div`
  width: 350px;
  box-sizing: border-box;
  padding-left: 31px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const OptionsCol = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const StatsCol = styled(OptionsCol)`
  justify-content: center;
  line-height: 18px;
`;

const UserNameSpan = styled.span`
  color: #202224;
  mix-blend-mode: normal;
  opacity: 0.9;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
`;

const EmailCol = styled.div`
  width: 200px;
`;

const SubModalHeading = styled.div`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.171429px;
  color: rgba(31, 32, 65, 0.7);
  margin-bottom: 43px;
`;

const DeleteOutlineButton = styled.div`
  width: 194px;
  margin: 7px;
  height: 44px;
  border: 1px solid #333333;
  box-sizing: border-box;
  border-radius: 100px;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  justify-content: center;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  color: #333333;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const DeleteActionButton = styled.div`
  margin: 7px;
  width: 194px;
  height: 44px;
  background: #FF624F;
  padding-left: 36px;
  padding-right: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: white;
  cursor: pointer;
  border-radius: 1000px;
  font-size: 12px;
  line-height: 15px;

  :hover {
    opacity: 0.8;
  }
`;

const DeleteButtonsPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const DeleteInner = styled.div`
  width: 420px;
  @media (max-width: 540px) {
    width: 100%;
  }
`;

const EditInner = styled.div`
  width: 540px;
  @media (max-width: 640px) {
    width: 100%;
  }
`;


const EditInnerHeader = styled.div`
  text-align: center;
  letter-spacing: 0.166667px;
  color: #333539;
  font-size: 24px;
  line-height: 40px;
  margin-bottom: 29px;
`;


const EditCircleButton = styled.img`
  width: 44px;
  height: 44px;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const GroupInfoSpan = styled.span`
  letter-spacing: 0.171429px;
  color: rgba(31, 32, 65, 0.5);
`;

const InfoBox = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 5px 10px rgba(31, 32, 65, 0.05);
  border-radius: 4px;
  padding: 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 85px;
`;

const InfoBoxField = styled.div`
  flex: 1;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const GroupName = styled.div`
  font-size: 19px;
  line-height: 23px;
  color: #1F2041;
  font-weight: bold;
`;

const Circle = styled.div`
  background: #A36EFF;
  border: 2px solid #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0px 10px 20px rgba(31, 32, 65, 0.1);
  border-radius: 32px;
  width: 64px;
  height: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 21px;
`;

const CreateLessonButton = styled.div`
  height: 44px;
  background: #FF624F;
  font-size: 12px;
  line-height: 15px;
  padding-left: 36px;
  padding-right: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: white;
  cursor: pointer;
  border-radius: 1000px;

  :hover {
    opacity: 0.8;
  }
`;

const TopSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
`;

const BackImg = styled.img`
  width: 7.5px;
  height: 12px;
  margin-right: 6px;
`;

const TopBackPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.166667px;
  color: #8593A8;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

