import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import CommonHelper from "../../../helpers/CommonHelper";
import PaginatorWrapper from "../../paginator/PaginatorWrapper";

const getFranchisee = (frId, allFranchisees) => {
    let arr = allFranchisees.filter(a => (a.id == frId));
    if (arr.length == 0) {
        return null;
    }
    return arr[0];
}

const getSchool = (teacherId, allSchools) => {
    let arr = allSchools.filter(a => (a.teachersIds.indexOf(teacherId) > -1));
    if (arr.length == 0) {
        return null;
    }
    return arr[0];
}


export default function AdminAllTeachersPanel(props) {
    const dispatch = useDispatch();
    let {currentUser, loading, schools, userRole, franchisees, teachers} = useMappedState(useCallback(state => {
        let userRole = (currentUser != undefined) ? currentUser.userRole : undefined;
        let franchisees = state.users.usersMap.toArray().filter(u => (u.userRole == 'franchisee'));
        let students = state.users.usersMap.toArray().filter(a => (a.userRole == 'student'))
        let teachers = state.users.usersMap.toArray().filter(a => (a.userRole == 'teacher'))
        return {
            userRole: userRole,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            currentUserId: state.users.currentUserId,
            loading: state.users.loading,
            schools: state.schools.schoolsMap.toArray().sort((a, b) => (+b.timestamp - +a.timestamp)),
            franchisees: franchisees,
            teachers: teachers
        }
    }, []));
    if (currentUser == undefined || userRole != 'admin') {
        return (
            <Wrapper>
                Access denied
            </Wrapper>
        )
    }

    const sortedTeachers = teachers.sort((a, b) => {
        let s1 = a.lastName;
        let s2 = b.lastName;
        if (s1 > s2) {
            return 1;
        }
        if (s1 < s2) {
            return -1;
        }
        return 0;
    });

    return (
        <Wrapper>

            <Heading>
                Тренеры ({sortedTeachers.length})
            </Heading>

            <PaginatorWrapper items={sortedTeachers} renderItems={items => {
                return (
                    <SchoolsList>
                        {items.map((c, k) => {
                            let {id, firstName, lastName, schoolId, franchiseeId, email} = c;
                            let fr = getFranchisee(franchiseeId, franchisees);
                            let sc = getSchool(id, schools);
                            return (
                                <SchoolItem key={id} onClick={() => {
                                    CommonHelper.linkTo(`/teacher/${id}`);
                                }}>
                                    <Name>
                                        {`${lastName} ${firstName}`}
                                        {' '}
                                        ({email})
                                    </Name>
                                    {fr == undefined ? null :
                                        <FranchiseeInfoPlaceholder>
                                            <FrImg src={require('../../../assets/images/manager-avatar.svg')}/>
                                            <span>
                                        {`${fr.firstName} ${fr.lastName}`}
                                    </span>
                                            {sc == undefined ? null :
                                                <span style={{marginLeft: 10}}>
                                            <FrImg src={require('../../../assets/images/university.svg')}/>
                                            <span>
                                                {`${sc.name}`}
                                            </span>
                                        </span>
                                            }
                                        </FranchiseeInfoPlaceholder>
                                    }
                                </SchoolItem>
                            )
                        })}
                    </SchoolsList>
                )
            }}/>


        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const SchoolsList = styled.div`
    
`;

const SchoolItem = styled.div`
    margin-bottom: 10px;
    background-color: white;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    :hover{
      opacity: 0.75;
    }
`;

const Name = styled.div`
    font-weight: bold;
`;

const FranchiseeInfoPlaceholder = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
`;

const FrImg = styled.img`
    height: 18px;
    margin-right: 5px;
`;

const Heading = styled.div`
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 10px;
    font-weight: bold;
`;
