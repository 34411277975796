import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import EloRating from 'elo-rating'

export default function TestElloPanel(props) {
    const [a, setA] = useState('100');
    const [b, setB] = useState('100');

    const elo = EloRating.calculate(+a, +b, true);

    return (
        <Wrapper>

            <div>
                A: <input placeholder={'A'} value={a} onChange={evt => {
                    setA(evt.target.value);
                }}/>

                {' '}

                B: <input placeholder={'B'} value={b} onChange={evt => {
                    setB(evt.target.value);
                }}/>
            </div>

            <div>
                {`новый рейтинг А: ${elo.playerRating}, новый рейтинг B = ${elo.opponentRating}`}
            </div>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 720px;
    margin: 0 auto;
    margin-top: 120px;
`;
