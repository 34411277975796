import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'

import styled from 'styled-components';
import HorChessTemplate from "../templates/HorChessTemplate";

import {Container, Heading} from "../ui/Container";
import CommonHelper from "../../helpers/CommonHelper";

import CoursePanel from '../courses/panels/CoursePanel'
import IraTeacherTemplate from "../templates/IraTeacherTemplate";

export default function CourseApp(props) {
    let {id} = props.match.params;
    const {course} = useMappedState(useCallback(state => ({
        course: state.courses.coursesMap.get(id)
    }), [id]));
    const [unitsExpanded, setUnitsExpanded] = useState(true);

    return (
        <IraTeacherTemplate active={`course/${id}`} hasLeft={false} >

            <Wrapper>
                <Heading style={{marginBottom: 20, marginTop: 20}}>
                    <TopLink onClick={() => {
                        CommonHelper.linkTo(`/courses`);
                    }}>
                        ⬅ Все курсы
                    </TopLink>

                    {course == undefined ? null :
                        <span>
                        {` / ${course.name}`}
                    </span>
                    }

                    <TopTogglerPlaceholder onClick={() => {
                        setUnitsExpanded(!unitsExpanded);
                    }}>
                        <TogglerImg src={require('../../assets/images/frame_er.svg')}/>
                    </TopTogglerPlaceholder>

                </Heading>

                <Container style={{padding: 0, paddingLeft: 5, paddingTop: 5}}>
                    <CoursePanel id={id} unitsExpanded={unitsExpanded}

                    />
                </Container>
            </Wrapper>

        </IraTeacherTemplate>
    );
}

const Wrapper = styled.div`
    
`;

const TopLink = styled.div`
    display: inline-block;
    text-decoration: underline;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;

const TopTogglerPlaceholder = styled.div`
    position: absolute;
    right: 0px;
    top: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const TogglerImg = styled.img`
    cursor: pointer;
    opacity: 0.4;
    height: 18px;
    :hover{
      opacity: 1;
    }
`;
