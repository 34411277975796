import React, { useState, useEffect, useRef, useCallback, useReducer } from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';


export default function UnderConstructionPanel() {

    return (
        <Wrapper>

            <ImagePlaceholder>
                <Img src={require('../../assets/images/under_construction_image.jpg')} />
            </ImagePlaceholder>

            <ContentPlaceholder>
                Раздел находится в разработке
            </ContentPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 640px;
    padding: 20px;
    background: white;
    margin: 0 auto;
    border-radius: 6px;
    @media(max-width: 640px){
      width: 100%;
    }
`;

const ImagePlaceholder = styled.div`
    width: 100%;
    margin-bottom: 20px;
`;

const Img = styled.img`
    width: 100%;
`;

const ContentPlaceholder = styled.div`
    padding: 20px;
    text-align: center;
    font-size: 24px;
    line-height: 32px;
    color: black;
    opacity: 0.6;
`;
