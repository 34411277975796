import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import Draggable from 'react-draggable';
import SimpleRealtimeBoard from "./SimpleRealtimeBoard";

export default function DraggableRealtimeBoardWidget(props) {
    const {
        boardId = 'lesson_cNnBqwahWp',
        topLeftContent = null,
        topRightContent = null
    } = props;

    return (
        <Wrapper>
            <Draggable
                handle=".handle"
                defaultPosition={{x: 0, y: 0}}
                position={null}
                scale={1}>
                <BoardPlaceholder>
                    <BoardTopPlaceholder className="handle">
                        <TopLeft>
                            {topLeftContent}
                        </TopLeft>
                        <TopRight>
                            {topRightContent}
                        </TopRight>
                    </BoardTopPlaceholder>
                    <Board>
                        <SimpleRealtimeBoard boardId={boardId}
                                             hasPaddings={false}
                                             adminMode={true}
                        />
                    </Board>
                </BoardPlaceholder>
            </Draggable>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const topHeight = 30;
const boardSize = 300;


const BoardTopPlaceholder = styled.div`
    background: lightgrey;
    cursor: grab;
    height: ${topHeight}px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
`;

const TopLeft = styled.div`
    
`;

const TopRight = styled.div`
    padding-right: 5px;
    box-sizing: border-box;
`;

const BoardPlaceholder = styled.div`
    height: ${boardSize + topHeight}px;
    width: ${boardSize}px;
    background: whitesmoke;
    box-sizing: border-box;
    border-radius: 3px;
`;

const Board = styled.div`
    height: ${boardSize}px;
    width: ${boardSize}px;
    box-sizing: border-box;
    padding: 4px;
    background: white;
    -webkit-box-shadow: 10px 9px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 9px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 10px 9px 5px 0px rgba(0,0,0,0.75);
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
`;
