import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const LessonsAPI = {

    getAllLessons() {
        return new Promise((resolve, reject) => {
            // axios.get(`${API_ENDPOINT}/lessons/all`).then(d => d.data).then(exercises => {
            axios.get(`${API_ENDPOINT}/lessons`).then(d => d.data.result).then(exercises => {
                if (exercises.error != undefined) {
                    return reject(exercises.error);
                }
                resolve(exercises);
            }).catch(err => reject(err));
        });
    },

    createLesson(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/lessons/create`, data).then(d => d.data).then(pld => {
                if (pld.error != undefined) {
                    return reject(pld.error);
                }
                resolve(pld);
            }).catch(err => reject(err));
        });
    },

    updateLesson(data) {
        console.log('updateLesson: data = ', data);
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/lessons/update`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    deleteLesson(id) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/lessons/delete/${id}`).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    getLesson(id) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/lesson/${id}`).then(d => d.data).then(exercises => {
                if (exercises.error != undefined) {
                    return reject(exercises.error);
                }
                resolve(exercises);
            }).catch(err => reject(err));
        });
    },

}

export default LessonsAPI;
