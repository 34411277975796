/**
 * Created by sabir on 19.07.17.
 */

import {Map, Stack, Set} from 'immutable'
import * as types from '../ActionTypes.js'

const initialState = {
    draggableLesson: undefined

}

const UiReducer = (state = initialState, action = {}) => {

    switch (action.type) {

        case types.SET_DRAGGABLE_LESSON:
            return {
                ...state,
                draggableLesson: action.lesson
            }

        case types.UNSET_DRAGGABLE_LESSON:
            return {
                ...state,
                draggableLesson: null
            }


        default:
            return state;
    }

}

export default UiReducer;
