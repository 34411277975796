/**
 * Created by sabir on 19.07.17.
 */

import {Map, Stack, Set} from 'immutable'
import * as types from '../ActionTypes.js'

const initialState = {
    loading: false,

    exercisesFoldersMap: Map(),
    error: undefined

}

const startLoading = (state, action) => {
    return { ...state, loading: true, error: undefined}
}

const stopLoading = (state, action) => {
    return { ...state, loading: false, error: action.error}
}

const ExercisesFoldersReducer =  (state = initialState, action = {}) => {

    switch (action.type) {


        case types.CREATE_EXERCISES_FOLDER:
        case types.UPDATE_EXERCISES_FOLDER:
        case types.DELETE_EXERCISES_FOLDER:
        case types.LOAD_EXERCISES_FOLDERS:
            return {
                ...state,
                loading: true,
                error: undefined
            }

        case types.CREATE_EXERCISES_FOLDER_FAIL:
        case types.UPDATE_EXERCISES_FOLDER_FAIL:
        case types.DELETE_EXERCISES_FOLDER_FAIL:
        case types.LOAD_EXERCISES_FOLDERS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case types.CREATE_EXERCISES_FOLDER_SUCCESS:
        case types.UPDATE_EXERCISES_FOLDER_SUCCESS:
            return {
                ...state,
                loading: false,
                exercisesFoldersMap: state.exercisesFoldersMap.set(action.exercisesFolder.id, action.exercisesFolder)
            }


        case types.DELETE_EXERCISES_FOLDER_SUCCESS:
            return {
                ...state,
                loading: false,
                exercisesFoldersMap: state.exercisesFoldersMap.delete(action.id),
            }



        case types.LOAD_EXERCISES_FOLDERS_SUCCESS:
            return {
                ...state,
                loading: false,
                exercisesFoldersMap: state.exercisesFoldersMap
                    .merge(action.exercisesFolders.reduce((map, form) => map.set(form.id, form), Map()))
            }


        default:
            return state;
    }

}

export default ExercisesFoldersReducer;
