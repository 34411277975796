import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import useDebouncedCallback from "use-debounce/lib/callback";
import {LIGHT_BROWN} from "../../ui/Colors";

import {Map, Set} from 'immutable'

import Spin from 'arui-feather/spin'

import axios from 'axios'


import * as smartExercisesActions from '../../../redux/actions/SmartExercisesActions'
import * as exFoldersActions from '../../../redux/actions/ExFoldersActions'

import NiceModal from "../../modals/NiceModal";
import {Exercise} from "../../rubius";
import SmartExercisesAndFoldersViewTool from "../tools/SmartExercisesAndFoldersViewTool";
import NiceModalWithHeading from "../../modals/NiceModalWithHeading";
import UpdateExFolderForm from "../forms/UpdateExFolderForm";
import UpdateShortExerciseForm from "../forms/UpdateShortExerciseForm";
import {BlueButton} from "../../ira/ui/Buttons";
import {API_ENDPOINT} from "../../../constants/config";
import CommonHelper from "../../../helpers/CommonHelper";
import PaginatorWrapper from "../../paginator/PaginatorWrapper";
import UpdateExerciseTagsAndRatingsTool from "../tools/UpdateExerciseTagsAndRatingsTool";
import {PgnViewerWrapper, Chessboard} from "../../rubius";
import TagsAPI from "../../../api/TagsAPI";
import SimpleTagsSelector from "../tools/SimpleTagsSelector";

export default function SmartExercisesTagsPanel(props) {
    const {} = props;

    const [selectedId, setSelectedId] = useState(undefined);
    const [tagsLoading, setTagsLoading] = useState(true);
    const [allTags, setAllTags] = useState([]);
    const [allExTags, setAllExTags] = useState([]);

    let {
        currentUser,
        loading,
        smartExercises,
        userRole,
        isAdmin,
        currentUserId,
        exFolders
    } = useMappedState(useCallback(state => {
        let userRole = (currentUser != undefined) ? currentUser.userRole : undefined;
        return {
            userRole: userRole,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            currentUserId: state.users.currentUserId,
            loading: state.smartExercises.loading,
            smartExercises: state.smartExercises.smartExercisesMap.toArray().sort((a, b) => {
                if (a.name > b.name) {
                    return 1;
                }
                if (a.name < b.name) {
                    return -1;
                }
                return 0;
            }),
            isAdmin: (userRole == 'admin' || userRole == 'methodist')
        }
    }, []));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(smartExercisesActions.loadAllSmartExercises());
    }, []);

    useEffect(() => {
        TagsAPI.getAllTags().then(arr => {
            console.log('all tags = ', arr);
            let sortedArr = arr.sort((a, b) => {
                let y = (a.isMain == true) ? 1 : 0;
                let x = (b.isMain == true) ? 1 : 0;
                if (x == y) {
                    if (a.name > b.name) {
                        return 1;
                    }
                    if (a.name < b.name) {
                        return -1;
                    }
                    return 0;
                }
                return (x - y);
            });

            setAllExTags(sortedArr.filter(x => (x.type == 'exercise')));
            setAllTags(sortedArr.filter(x => (x.type == 'sub-exercise')));
            setTagsLoading(false);
        })
    }, []);

    if (isAdmin == false || tagsLoading == true) {
        return <div></div>
    }

    const selectedExercise = smartExercises.filter(x => (x.id == selectedId))[0];

    return (
        <Wrapper>

            <ListPlaceholder>
                <PaginatorWrapper renderItems={items => {
                    return (
                        <ListPlaceholder>
                            {items.map((ex, i) => {
                                return (
                                    <ExItem key={`ex_${ex.id}_${i}`}>
                                        <ItemName onClick={() => {
                                            setSelectedId(ex.id);
                                        }}>
                                            {ex.name}
                                        </ItemName>

                                        <div style={{marginTop: 5, marginBottom: 15}}>
                                            <SimpleTagsSelector {...props}
                                                                allTags={allExTags}
                                                                tags={ex.tags == undefined ? [] : ex.tags}
                                                                onChange={async arr => {
                                                                    await dispatch(smartExercisesActions.updateSmartExercise({
                                                                        id: ex.id,
                                                                        tags: arr
                                                                    }));
                                                                }}/>
                                        </div>

                                        <UpdateExerciseTagsAndRatingsTool {...ex} allTags={allTags} loading={loading}
                                                                          onSave={async (solutions) => {
                                                                              console.log('new solutions = ', solutions);
                                                                              await dispatch(smartExercisesActions.updateSmartExercise({
                                                                                  id: ex.id,
                                                                                  solutions: solutions
                                                                              }));
                                                                          }}/>
                                    </ExItem>
                                )
                            })}
                        </ListPlaceholder>)
                }} items={smartExercises}/>
            </ListPlaceholder>

            {selectedExercise == undefined ? null :
                <NiceModalWithHeading
                    width={920}
                    heading={selectedExercise.name} subHeading={''} onClose={() => {
                    setSelectedId(undefined);
                }}>

                    <PgnViewerWrapper
                        boardWidthFraction={0.5}
                        pgn={selectedExercise.pgn}>
                        <Chessboard/>
                    </PgnViewerWrapper>

                </NiceModalWithHeading>
            }


        </Wrapper>
    );
}

const SelectedViewInner = styled.div`
  width: 100%;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
`;

const ExItem = styled.div`
  margin-bottom: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid lightgrey;
`;

const Wrapper = styled.div`

`;

const ListPlaceholder = styled.div`

`;

const ItemName = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
  cursor: pointer;
`;
