import React from 'react';

const formatTime = timeInSeconds => {
  var hours   = '' + Math.floor(timeInSeconds / 3600);
  var minutes = '' + Math.floor((timeInSeconds % 3600) / 60);
  var seconds = '' + timeInSeconds % (60);

  seconds = seconds.padStart(2, '0');
  
  if(hours > 0) {
    minutes = minutes.padStart(2, '0');
    return `${hours}:${minutes}.${seconds}`;
  }

  return `${minutes}.${seconds}`;
}

export default function Timer({ time, ...restProps }) {
  return <div {...restProps} >{`${formatTime(time)}`}</div>
}

