/**
 * Created by sabir on 19.07.17.
 */

import {Map, Stack, Set} from 'immutable'
import * as types from '../ActionTypes.js'

const initialState = {
    loading: false,
    gamesMap: Map(),
    error: undefined

}

const startLoading = (state, action) => {
    return { ...state, loading: true, error: undefined}
}

const stopLoading = (state, action) => {
    return { ...state, loading: false, error: action.error}
}

const GamesReducer =  (state = initialState, action = {}) => {

    switch (action.type) {


        case types.CREATE_GAME:
        case types.UPDATE_GAME:
        case types.DELETE_GAME:
        case types.LOAD_GAMES:
            return {
                ...state,
                loading: true,
                error: undefined
            }

        case types.CREATE_GAME_FAIL:
        case types.UPDATE_GAME_FAIL:
        case types.DELETE_GAME_FAIL:
        case types.LOAD_GAMES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case types.CREATE_GAME_SUCCESS:
        case types.UPDATE_GAME_SUCCESS:
            return {
                ...state,
                loading: false,
                gamesMap: state.gamesMap.set(action.game.id, action.game)
            }


        case types.DELETE_GAME_SUCCESS:
            return {
                ...state,
                loading: false,
                gamesMap: state.gamesMap.delete(action.id),
            }



        case types.LOAD_GAMES_SUCCESS:
            return {
                ...state,
                loading: false,
                gamesMap: state.gamesMap
                    .merge(action.games.reduce((map, form) => map.set(form.id, form), Map()))
            }


        default:
            return state;
    }

}

export default GamesReducer;
