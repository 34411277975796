import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';
import HorChessTemplate from "../templates/HorChessTemplate";

import AllExercisesGroupsPanel from "../exercises_groups/panels/AllExercisesGroupsPanel";
import AllTestsPanel from "../tests/panels/AllTestsPanel";

export default function RecordingApp(props) {
    let {recordingId} = props.match.params;
    let src = `https://s3.timeweb.com./cr86728-shagi-recordings/recording_${recordingId}.webm`;

    return (
        <OutWrapper active={'tests'}>

            <Video controls={true} src={src}></Video>

        </OutWrapper>
    );
}

const Wrapper = styled.div`
  background: white;
  padding: 10px;
  border-radius: 4px;
`;

const Video = styled.video`
  width: 720px;
  height: 520px;
`;


const OutWrapper = styled.div`
  box-sizing: border-box;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;