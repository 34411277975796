import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import useComponentSize from "@rehooks/component-size";

export default function BoxesLayoutTool(props) {
    const {
        boxes = [],
        minBoxWidth = 200,
        proportion = 4.0 / 3.0
    } = props;
    const ref = useRef();
    const size = useComponentSize(ref);
    let mult = [1, 2, 3, 4].reduce((res, m) => (1.0 * size.width / m > minBoxWidth ? m : res), 1);
    mult = Math.min(mult, boxes.length);

    let boxWidth = 1.0 * size.width / mult;
    let boxHeight = 1.0 * boxWidth / proportion;

    console.log('render: BoxesLayoutTool: boxes = ', boxes);

    return (
        <Wrapper ref={ref}>

            {boxes.map((a, i) => {
                let {user, content, streamId} = a;
                let key = `u_${user == undefined ? 'ff' : user.id}`;
                return (
                    <Box  width={boxWidth}
                         height={boxHeight}
                          key={streamId == undefined ? 'mee' : streamId}
                    >
                        <BoxInner>
                            {content}
                        </BoxInner>
                    </Box>
                )
            })}

        </Wrapper>
    );
}

const Wrapper = styled.div`
    background: grey;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: block;
`;

const Box = styled.div`
    display: inline-block;
    vertical-align: top; 
    background: grey;
    border-radius: 4px;
    box-sizing: border-box;
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    :hover{
      opacity: 0.85;
    }
`;

const BoxInner = styled.div`
    width: 100%;
    height: 100%;
    background: white;
    box-sizing: border-box;
`;
