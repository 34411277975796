import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import useDebouncedCallback from "use-debounce/lib/callback";
import {LIGHT_BROWN} from "../../ui/Colors";

import Spin from 'arui-feather/spin'

import axios from 'axios'

import Tabs from "../../ui/Tabs";
import SmartPgnViewer from "../../chess/view/smart/SmartPgnViewer";

import * as smartExercisesActions from '../../../redux/actions/SmartExercisesActions'
import * as exFoldersActions from '../../../redux/actions/ExFoldersActions'

import Sidebar from 'arui-feather/sidebar'

import {Button} from "../../ui/Button";

import SubjectCommentsPanel from "../../comments/panels/SubjectCommentsPanel";
import NiceModal from "../../modals/NiceModal";
import {Exercise} from "../../rubius";
import SmartExercisesAndFoldersViewTool from "../tools/SmartExercisesAndFoldersViewTool";
import NiceModalWithHeading from "../../modals/NiceModalWithHeading";
import UpdateExFolderForm from "../forms/UpdateExFolderForm";
import UpdateShortExerciseForm from "../forms/UpdateShortExerciseForm";
import {BlueButton} from "../../ira/ui/Buttons";
import {API_ENDPOINT} from "../../../constants/config";
import CommonHelper from "../../../helpers/CommonHelper";

export default function SmartExercisesSelectorPanel(props) {
    const {
        exercisesIds = [],
        onChange = newIds => {

        }
    } = props;

    let {
        currentUser,
        loading,
        smartExercises,
        userRole,
        isAdmin,
        currentUserId,
        exFolders
    } = useMappedState(useCallback(state => {
        let userRole = (currentUser != undefined) ? currentUser.userRole : undefined;
        return {
            userRole: userRole,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            currentUserId: state.users.currentUserId,
            loading: state.smartExercises.loading || state.exFolders.loading,
            smartExercises: state.smartExercises.smartExercisesMap.toArray().sort((a, b) => {
                if (a.name > b.name) {
                    return 1;
                }
                if (a.name < b.name) {
                    return -1;
                }
                return 0;
            }),
            exFolders: state.exFolders.exFoldersMap.toArray().sort((a, b) => {
                if (a.name > b.name) {
                    return 1;
                }
                if (a.name < b.name) {
                    return -1;
                }
                return 0;
            }),
            isAdmin: (userRole == 'admin'),

        }
    }, []));
    const dispatch = useDispatch();

    const [selectedAddFolderId, setSelectedAddFolderId] = useState(undefined);
    const [selectedEditFolderId, setSelectedEditFolderId] = useState(undefined);
    const [addExerciseFolderId, setAddExerciseFolderId] = useState(undefined);
    const [moving, setMoving] = useState(false);

    const [moveData, setMoveData] = useState(undefined);

    const [targetFolderId, setTargetFolderId] = useState(undefined);

    let selectedEditFolder = (selectedEditFolderId == undefined) ? undefined : exFolders.filter(a => (a.id == selectedEditFolderId))[0];

    useEffect(() => {
        dispatch(exFoldersActions.loadAllExFolders());
        dispatch(smartExercisesActions.loadAllSmartExercises());
    }, []);

    return (
        <Wrapper>

            <SmartExercisesAndFoldersViewTool
                exFolders={exFolders}
                smartExercises={smartExercises}
                selectedExercisesIds={exercisesIds}
                onFolderAdd={aId => {
                    setSelectedAddFolderId(aId == undefined ? '' : aId);
                }}
                onFolderEdit={aId => {
                    setSelectedEditFolderId(aId);
                }}
                onExerciseAdd={fId => {
                    setAddExerciseFolderId(fId == undefined ? '' : fId);
                }}
                onMove={(exercisesIds, foldersIds, fromFolderId) => {
                    setMoveData({
                        exercisesIds: exercisesIds,
                        foldersIds: foldersIds,
                        fromFolderId: fromFolderId
                    });
                }}
                onExerciseClick={exId => {

                }}
                onSelectedExercisesIdsChange={newIds => {
                    console.log('onSelectedExercisesIdsChange: newIds = ', newIds);
                    onChange(newIds);
                }}
                hasControls={false}
                noFoldersSelectionName={''}
                canSelectFolder={false}
            />

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;
