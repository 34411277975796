import {Map} from 'immutable'
import * as types from '../ActionTypes.js'

const initialState = {
    loading: false,

    answersMap: Map(),
    error: undefined

}

const AnswersReducer = (state = initialState, action = {}) => {

    switch (action.type) {

        case types.CREATE_ANSWER:
        case types.UPDATE_ANSWER:
        case types.DELETE_ANSWER:
        case types.LOAD_ANSWERS:
            return {
                ...state,
                loading: true,
                error: undefined
            }

        case types.CREATE_ANSWER_FAIL:
        case types.UPDATE_ANSWER_FAIL:
        case types.DELETE_ANSWER_FAIL:
        case types.LOAD_ANSWERS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case types.CREATE_ANSWER_SUCCESS:
        case types.UPDATE_ANSWER_SUCCESS:
            return {
                ...state,
                loading: false,
                answersMap: state.answersMap.set(action.answer.id, action.answer)
            }

        case types.DELETE_ANSWER_SUCCESS:
            return {
                ...state,
                loading: false,
                answersMap: state.answersMap.delete(action.id),
            }

        case types.LOAD_ANSWERS_SUCCESS:
            return {
                ...state,
                loading: false,
                answersMap: state.answersMap
                    .merge(action.answers.reduce((map, form) => map.set(form.id, form), Map()))
            }

        default:
            return state;
    }

}

export default AnswersReducer;
