import moment from 'moment-timezone'
import {API_ENDPOINT, DEFAULT_TIMEZONE, UNITPAY_ID} from "../constants/config";
import axios from 'axios'

export const RU_MONTHS_ARRAY = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь'
];

const CommonHelper = {

    linkTo(patientUrl, inNewTab = false) {
        let url = window.location.origin + window.location.pathname + '#' + patientUrl;
        if (inNewTab == false) {
            window.location.href = url;
        } else {
            let win = window.open(url, '_blank');
            win.focus();
        }
    },

    getRuMonthByTimestamp(t) {
        let m = +moment(t).tz(DEFAULT_TIMEZONE).format('M') - 1;
        return RU_MONTHS_ARRAY[+m];
    },

    getCurrentUserRole(state) {
        if (state == undefined) {
            return false;
        }
        let {currentUserId, usersMap} = state.users;
        let currentUser = usersMap.get(currentUserId);
        if (currentUser == undefined) {
            return undefined;
        }
        return currentUser.userRole;
    },

    getRuNameOfRole(role) {
        let map = {
            franchisee: 'Франчайзи',
            admin: 'Админ',
            teacher: 'Тренер',
            student: 'Ученик',
        }
        return map[role];
    },

    getUserName(user) {
        if (user == undefined) {
            return 'Guest';
        }
        let {firstName, lastName, name} = user;
        let fn = (firstName == undefined || firstName == '') ? '' : firstName;
        let ln = (lastName == undefined || lastName == '') ? '' : lastName;
        if (fn == '' && ln == '') {
            return user.email;
        }
        return `${fn} ${ln}`;
    }

};

export default CommonHelper;
