import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import ChessHelper from "../../../helpers/ChessHelper";
import SolutionInfoUpdateTool from "./SolutionInfoUpdateTool";

export default function UpdateExerciseTagsAndRatingsTool(props) {
    const {
        loading = false,
        onSave = newSolutions => {

        },
        allTags = []
    } = props;
    const [solutions, setSolutions] = useState(props.solutions == undefined ? [] : props.solutions);


    return (
        <Wrapper>

            <SolutionsList>
                {solutions.map((sol, i) => {
                    let {variants = []} = sol;
                    if (variants.length == 0) {
                        return null;
                    }
                    let winVariants = variants.filter(x => (x.type == 'win'));
                    let variant = (winVariants.length > 0) ? winVariants[0] : variants[0];

                    return (
                        <SolItem key={sol.id}>
                            <SolBoardImage src={ChessHelper.getFenImageUrl(variant.fen)}/>
                            <SolutionInfoUpdateTool {...props} {...sol}
                                                    loading={loading}
                                                    onChange={d => {
                                                        console.log('onChange: d = ', d);
                                                        let updSol = {...sol, ...d};
                                                        console.log('upd sol = ', updSol);
                                                        let newSolutions = solutions.map((q, j) => (q.id == sol.id ? updSol : q));
                                                        setSolutions(newSolutions);
                                                        onSave(newSolutions);
                                                    }}/>
                        </SolItem>
                    )
                })}
            </SolutionsList>
        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const SolBoardImage = styled.img`
  width: 160px;
  height: 160px;
  margin-right: 20px;
`;

const SolutionsList = styled.div`

`;

const SolItem = styled.div`
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
