import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';

const ROWS = [1, 2, 3, 4, 5, 6, 7, 8].reverse();
const COLS = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h'];

export default function UpdateArrowTool(props) {
    const {
        orientation = 'white',
        onChange = () => {

        }
    } = props;


    const [selectedFrom, setSelectedFrom] = useState(undefined);
    const [selectedTo, setSelectedTo] = useState(undefined);

    const hasActiveArrow = !(selectedFrom == undefined || selectedTo == undefined || (selectedFrom != undefined && selectedTo != undefined && selectedTo.x == selectedFrom.x && selectedFrom.y == selectedTo.y));

    console.log('render: selectedFrom, selectedTo = ', selectedFrom, selectedTo);
    let rows = (orientation == 'white') ? ROWS : ROWS.reverse();
    let cols = (orientation == 'white') ? COLS : COLS.reverse();
    let isBoth = (selectedFrom != undefined && selectedTo != undefined);

    useEffect(() => {
        if (selectedFrom == undefined || selectedTo == undefined) {
            onChange(undefined);
        } else {
            console.log('--->>>>> useEffect: selectedFrom, selectedTo = ', selectedFrom, selectedTo);
            onChange({
                from: selectedFrom.coordinate,
                to: selectedTo.coordinate
            })
        }
    }, [JSON.stringify(selectedFrom), JSON.stringify(selectedTo)]);

    return (
        <Wrapper>

            {rows.map((row, i) => {
                return (
                    <Row key={i} style={{height: (`${100.0 / ROWS.length}%`)}}>
                        {cols.map((col, j) => {
                            let isFrom = (selectedFrom != undefined && selectedFrom.x == i && selectedFrom.y == j);
                            let isTo = (selectedTo != undefined && selectedTo.x == i && selectedTo.y == j);
                            let currPoint = {
                                x: i,
                                y: j,
                                coordinate: `${col}${row}`
                            }
                            return (
                                <Square
                                    key={j}
                                    onClick={() => {
                                        if (selectedFrom != undefined && isTo == false) {
                                            console.log('COND1! ');
                                            if (i == selectedFrom.x && j == selectedFrom.y) {
                                                setSelectedTo(undefined);
                                                setSelectedFrom(undefined);
                                            } else {
                                                setSelectedTo(currPoint)
                                            }
                                        }
                                        if (selectedFrom == undefined) {
                                            console.log('COND2! ');
                                            setSelectedFrom(currPoint)
                                        }
                                        if (selectedFrom != undefined && selectedTo != undefined) {
                                            setSelectedTo(undefined);
                                            setSelectedFrom(undefined);
                                        }
                                    }}>

                                    {(isFrom == false || isBoth == true) ? null :
                                        <SquareInner color={'white'} onClick={() => {

                                        }}/>
                                    }

                                    {(isTo == false || isBoth == true) ? null :
                                        <SquareInner color={'white'} onClick={() => {

                                        }}/>
                                    }

                                </Square>
                            )
                        })}
                    </Row>
                )
            })}

        </Wrapper>
    );
}

const SquareInner = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 4px solid ${props => (props.color)};
`;

const Row = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: stretch;
`;

const Square = styled.div`
    box-sizing: border-box;
    position: relative;
    flex: 1;
    border: 1px solid rgba(0, 0, 0, 0.2);
    :hover{
      background: rgba(255, 255, 255, 0.8);
    }
`;

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    position: relative;
`;
