import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import HorChessTemplate from "../templates/HorChessTemplate";


import {Container} from "../ui/Container";

import AdminAllStudentsPanel from "../admin/panels/AdminAllStudentsPanel";

export default function AdminStudentsApp(props) {

    return (
        <HorChessTemplate active={'students'}>

            <AdminAllStudentsPanel />

        </HorChessTemplate>
    );
}

