import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import CommonHelper from "../../../helpers/CommonHelper";

import Tabs from '../../ui/Tabs'
import LessonsCalendar from "../../lessons/tools/LessonsCalendar";
import {DARK_SIDE} from "../../ui/Colors";
import * as actions from "../../../redux/actions/LessonsActions";

import Sidebar from 'arui-feather/sidebar'
import {translate} from "../../../utils/Translate";

const getItemById = (arr, id) => {
    let aArr = arr.filter(a => (a.id == id));
    if (aArr.length == 0) {
        return undefined;
    }
    return aArr[0];
}

const now = +new Date();

const renderLessons = ({lessons, teachers, groups, rooms, setSelectedId, selectedId}) => {
    return (
        <LessonsList>
            {lessons.map((item, k) => {
                let {teacherId, startTimestamp, groupId, roomId, name} = item;
                let tchr = getItemById(teachers, teacherId);
                let grp = getItemById(groups, groupId);
                let rm = getItemById(rooms, roomId);
                return (
                    <LessonItem
                        selected={(selectedId == item.id)}
                        key={`${item.id}_${k}`}
                        onClick={() => {
                            setSelectedId(item.id);
                        }}>

                        <ItemInfo>

                            <ItemName>
                                {name}
                            </ItemName>

                            <DatePlaceholder>
                                {moment(startTimestamp).format('DD.MM.YYYY HH:mm')}
                            </DatePlaceholder>
                        </ItemInfo>

                        <ItemSub>
                                                <span>
                                                    {`${translate('lesson')} `}
                                                </span>
                            {grp == undefined ? null :
                                <span>
                                                        {`c группой `}
                                    <B>
                                                            {grp.name}
                                                        </B>
                                                    </span>
                            }
                            {tchr == undefined ? null :
                                <span>{` ведет `}
                                    <B>{`${tchr.firstName} ${tchr.lastName}`}</B> {' '}</span>
                            }
                            {rm == undefined ? null :
                                <span>{`в помещении `}<B>{rm.name}</B></span>
                            }
                        </ItemSub>

                    </LessonItem>
                )
            })}
        </LessonsList>
    )
}


export default function StudentPersonalPanel(props) {
    let {id} = props;
    const [selectedId, setSelectedId] = useState(undefined);
    let {groups} = useMappedState(useCallback(state => {
        let student = state.users.usersMap.get(id);
        let groups = state.teacherGroups.teacherGroupsMap.toArray().filter(g => (g.studentsIds.indexOf(id) > -1));

        return {
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            loading: state.users.loading,
            student: student,
            groups: groups
        }
    }, [id]));


    return (
        <Wrapper>

            <Heading>
                Группы
            </Heading>

            {groups.map((gr, k) => {
                return (
                    <LessonItem key={gr.id} onClick={() => {
                        CommonHelper.linkTo(`/group/${gr.id}`);
                    }}>
                        <GroupName>
                            {gr.name}
                        </GroupName>
                    </LessonItem>
                )
            })}


        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const TabsPlaceholder = styled.div`
    
`;

const TabItem = styled.div`
    padding-top: 10px;
    width: 100%;
`;

const LessonsCalendarPlaceholder = styled.div`
    height: 500px;
    width: 100%;
    background: white;
`;


const DatePlaceholder = styled.div`
    
`;

const ItemInfo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const ItemName = styled.div`
    font-weight: bold;
`;

const ItemSub = styled.div`
    opacity: 0.8;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 12px;
`;

const B = styled.span`
    font-weight: bold;
    opacity: 0.7;
`;


const LessonsList = styled.div`
    
`;

const Heading = styled.div`
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 10px;
    font-weight: bold;
`;

const LessonItem = styled.div`
    background: ${props => (props.selected == true ? DARK_SIDE : 'white')};
    color: ${props => (props.selected == true ? 'white' : 'black')};
    padding: 10px;
    cursor: pointer;
    border-radius: 4px;
    margin-bottom: 10px;
    :hover{
      opacity: 0.7;
    }
`;

const GroupName = styled.div`
    font-weight: bold;
`;
