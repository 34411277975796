import React from "react";

export default class HighlightedSquares extends React.Component {

    state = {
        svgParams: [],
        prevSquares: [],
        boardWidth: 0,
        strokeWidth: 5,
    };

    componentDidUpdate() {
        const {
            highlightedSquares,
            getSquareCoordinates,
            boardWidth,
            orientation,
        } = this.props;

        const {prevSquares, strokeWidth} = this.state;
        if (squaresChanged(highlightedSquares, prevSquares)) {
            const svgParams = []
            let i = 0;
            const base = orientation === 'black' ? getSquareCoordinates('h1') : getSquareCoordinates('a8');
            const xOffset = base.sourceSquare.x - strokeWidth / 2;
            const yOffset = base.sourceSquare.y - strokeWidth / 2;
            highlightedSquares.forEach(square => {
                const position = getSquareCoordinates(square.position);
                svgParams[i] = {
                    x: position.sourceSquare.x - xOffset,
                    y: position.sourceSquare.y - yOffset,
                    color: square.color,
                }
                i++;
            });
            this.setState({prevSquares: highlightedSquares, svgParams, boardWidth});
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const {
            highlightedSquares,
            getSquareCoordinates,
            boardWidth,
            orientation,
        } = nextProps;
        const svgParams = []
        const {prevSquares, strokeWidth} = this.state;
        let i = 0;
        const base = orientation === 'black' ? getSquareCoordinates('h1') : getSquareCoordinates('a8');
        const xOffset = base.sourceSquare.x - strokeWidth / 2;
        const yOffset = base.sourceSquare.y - strokeWidth / 2;
        highlightedSquares.forEach(square => {
            const position = getSquareCoordinates(square.position);
            svgParams[i] = {
                x: position.sourceSquare.x - xOffset,
                y: position.sourceSquare.y - yOffset,
                color: square.color,
            }
            i++;
        });
        this.setState({prevSquares: highlightedSquares, svgParams, boardWidth});
    }

    render() {
        const {svgParams, boardWidth, strokeWidth} = this.state;
        const width = boardWidth / 8 - strokeWidth;
        if (svgParams.length < 1) return (null);
        const svgHighlights = svgParams.map((param, i) => (
            <rect key={`id-${i}`} x={param.x} y={param.y} width={width} height={width} strokeWidth={strokeWidth}
                  stroke={param.color} rx={15} fill="none"/>
        ));
        return (
            <svg style={{position: 'absolute', pointerEvents: 'none', zIndex: '6'}} width={boardWidth}
                 height={boardWidth}>{svgHighlights}</svg>
        )
    }
}

function squaresChanged(newSquares, prevSquares) {
    return JSON.stringify(newSquares) !== JSON.stringify(prevSquares);
}