import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';
import {BROWN, LIGHT_BROWN, RED} from "./Colors";

const DEFAULT_BACKGROUND = LIGHT_BROWN;

export const Container = styled.div`
    padding: 7px;
    padding-left: 15px;
    padding-right: 15px;
    background: white;
    border-radius: 4px;
    box-sizing: border-box;
`;

export const Heading = styled.div`
    padding-left: 15px;
    padding-right: 15px;
    line-height: 20px;
    font-size: 18px;
    position: relative;
`;
