import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

const FIGS = ['r', 'n', 'b', 'q', 'k', 'p', 'R', 'N', 'B', 'Q', 'K', 'P'];

export default function SimpleFiguresView(props) {

    const items = FIGS.filter(a => (props[a] != undefined));

    return (
        <Wrapper>
            {items.map((f, j) => {
                return (
                    <FigImg key={j} src={props[f]}/>
                )
            })}
        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const FigImg = styled.img`
    width: 24px;
    height: 24px;
    margin: 5px;
    display: inline-block;
    vertical-align: top;
`;
