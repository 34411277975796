import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import FirebaseAPI from "../../../api/FirebaseAPI";
import ChessHelper from "../../../helpers/ChessHelper";

import Chessboard from 'chessboardjsx'

import {Button} from "../../ui/Button";
import CommonHelper from "../../../helpers/CommonHelper";
import * as actions from "../../../redux/actions/TeacherGroupsActions";
import SmartSelect from "../../ira/ui/SmartSelect";
import {translate} from "../../../utils/Translate";

const COLORS_OPTIONS = [
    {
        label: translate('white_to_move'),
        value: 'w'
    },
    {
        label: translate('black_to_move'),
        value: 'b'
    }
]

const START_FEN = 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1';
const EMPTY_FEN = '8/8/8/8/8/8/8/8';

const filledCheck = require('../../../assets/images/che_filled.svg');
const voidCheck = require('../../../assets/images/void_ccc.svg');

const renderTick = (selected, text, onClick = () => {
}) => {
    return (
        <>
        <CheckImg src={(selected == true) ? filledCheck : voidCheck} onClick={() => {
            onClick();
        }}/>
        <CheckSpan onClick={() => {
            onClick();
        }}>
            {text}
        </CheckSpan>
        </>
    )
}


export default function SimpleFenEditor(props) {
    const {
        onSave = newFen => {
            console.log('onSave: newFen = ', newFen);
        },
        onInstantChange = newFen => {

        },
        canSave = true
    } = props;
    const [fen, setFen] = useState(props.fen == undefined ? START_FEN : props.fen);

    const [hasWhiteShort, setHasWhiteShort] = useState(false);
    const [hasWhiteLong, setHasWhiteLong] = useState(false);

    const [hasBlackShort, setHasBlackShort] = useState(false);
    const [hasBlackLong, setHasBlackLong] = useState(false);

    const [move, setMove] = useState('w');

    useEffect(() => {
        let sFen = fen.split(' ')[0];
        sFen = `${sFen} ${move} ${castString} - 0 1`;
        onInstantChange(sFen);
    }, [fen, move, castString]);

    const getPosition = useCallback((pos, a, b) => {
        let newFen = ChessHelper.chessboardJsxPositionToFen(pos);
        setFen(newFen);
    }, []);

    let castString = `${hasWhiteShort ? 'K' : ''}${hasWhiteLong ? 'Q' : ''}${hasBlackShort ? 'k' : ''}${hasBlackLong ? 'q' : ''}`;
    if (castString == '') {
        castString = '-';
    }

    return (
        <Wrapper>
            <TopSettingsPlaceholder>
                <TopControlSpan onClick={() => {
                    setFen(START_FEN);
                }}>
                    Начальная позиция
                </TopControlSpan>
                <TopControlSpan onClick={() => {
                    setFen(EMPTY_FEN);
                }}>
                    Пустая доска
                </TopControlSpan>
            </TopSettingsPlaceholder>

            <ContPlaceholder>

                <BoardPlaceholder key={fen}>
                    <Chessboard position={fen}
                                sparePieces={true}
                                width={360}
                                getPosition={getPosition}
                    />
                </BoardPlaceholder>

                <SubBoardPlaceholder>

                    <SmartSelect
                        options={COLORS_OPTIONS}
                        placeholder={'Чей ход'}
                        isMulti={false}
                        closeOnSelect={true}
                        selectedValues={[move]}
                        onSelect={a => {
                            console.log('onSelect: a = ', a);
                            setMove(a[0]);
                        }}
                    />

                    <ChRow style={{fontWeight: 'bold', marginTop: 10}}>
                        Рокировка
                    </ChRow>

                    <ChRow>
                        {renderTick(hasWhiteShort, 'Белые О-О', () => {
                            setHasWhiteShort(!hasWhiteShort)
                        })}
                    </ChRow>
                    <ChRow>
                        {renderTick(hasBlackShort, 'Черные О-О', () => {
                            setHasBlackShort(!hasBlackShort)
                        })}
                    </ChRow>
                    <ChRow>
                        {renderTick(hasWhiteLong, 'Белые О-О-O', () => {
                            setHasWhiteLong(!hasWhiteLong)
                        })}
                    </ChRow>
                    <ChRow>
                        {renderTick(hasBlackLong, 'Черные О-О-O', () => {
                            setHasBlackLong(!hasBlackLong)
                        })}
                    </ChRow>
                </SubBoardPlaceholder>

            </ContPlaceholder>

            {canSave == false ? null :
                <SavePlaceholder>
                    <Button onClick={() => {
                        let sFen = fen.split(' ')[0];
                        sFen = `${sFen} ${move} ${castString} - 0 1`;
                        // console.log('fen = ', fen);
                        // console.log('sFen = ', sFen);
                        onSave(sFen);
                    }}>
                        {translate('save')}
                    </Button>
                </SavePlaceholder>
            }

        </Wrapper>
    );
}

const ContPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    
`;

const Wrapper = styled.div`
    width: 100%;
`;

const CheckSpan = styled.div`
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #000000;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;

const CheckImg = styled.img`
    width: 20px;
    height: 20px;
    margin-right: 10px;
    cursor: pointer;
`;

const ChRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
`;

const TopSettingsPlaceholder = styled.div`
    
`;

const SubBoardPlaceholder = styled.div`
    margin-top: 50px;
    padding-left: 20px;
`;

const BottomPlaceholder = styled.div`
    
`;

const TopControlSpan = styled.span`
    margin-right: 10px;
    opacity: 0.7;
    cursor: pointer;
    :hover{
      opacity: 1;
    }
`;

const BoardPlaceholder = styled.div`
    margin-top: 10px;
    margin-right: 10px;
`;

const SavePlaceholder = styled.div`
    
`;
