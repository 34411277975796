import React from "react";

export default class Arrows extends React.Component {

    state = {
        svgParams: [],
        prevArrows: [],
        boardWidth: 0,
    };

    componentDidUpdate() {
        const {
            arrows,
            getSquareCoordinates,
            boardWidth,
            orientation,
        } = this.props;
        console.log('Arrows: componentDidUpdate: orientation = ', orientation);

        const {prevArrows} = this.state;
        if (arrowsChanged(arrows, prevArrows)) {
            const svgParams = []
            const base = orientation === 'black' ? getSquareCoordinates('h1') : getSquareCoordinates('a8');
            const xOffset = base.sourceSquare.x - boardWidth / 16;
            const yOffset = base.sourceSquare.y - boardWidth / 16;
            arrows.forEach((arrow, i) => {
                const from = getSquareCoordinates(arrow.from);
                const to = getSquareCoordinates(arrow.to);
                svgParams[i] = {
                    x1: from.sourceSquare.x - xOffset,
                    x2: to.sourceSquare.x - xOffset,
                    y1: from.sourceSquare.y - yOffset,
                    y2: to.sourceSquare.y - yOffset,
                    color: arrow.color,
                }
            });
            this.setState({prevArrows: arrows, svgParams, boardWidth});
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const {
            arrows,
            getSquareCoordinates,
            boardWidth,
            orientation,
        } = nextProps;
        const svgParams = []
        const base = orientation === 'black' ? getSquareCoordinates('h1') : getSquareCoordinates('a8');
        const xOffset = base.sourceSquare.x - boardWidth / 16;
        const yOffset = base.sourceSquare.y - boardWidth / 16;
        arrows.forEach((arrow, i) => {
            const from = getSquareCoordinates(arrow.from);
            const to = getSquareCoordinates(arrow.to);
            svgParams[i] = {
                x1: from.sourceSquare.x - xOffset,
                x2: to.sourceSquare.x - xOffset,
                y1: from.sourceSquare.y - yOffset,
                y2: to.sourceSquare.y - yOffset,
                color: arrow.color,
            }
        });
        this.setState({prevArrows: arrows, svgParams, boardWidth});
    }


    render() {
        const {svgParams, boardWidth} = this.state;
        const {arrowSize = 3, orientation} = this.props;


        if (svgParams.length < 1) return (null);

        const cos60 = 0.866;

        const svgArrows = svgParams.map((param, i) => (
            <g key={`arrow-${i}`} opacity="0.5">
                <defs>
                    <marker id={`arrow-${i}`} markerWidth={arrowSize} markerHeight={arrowSize} refX={arrowSize / 2}
                            refY={arrowSize / 2} orient="auto" markerUnits="strokeWidth">
                        <path d={`M0,0 L0,${arrowSize} L${arrowSize * cos60},${arrowSize / 2} z`} fill={param.color}/>
                    </marker>
                </defs>
                <line x1={param.x1} x2={param.x2} y1={param.y1} y2={param.y2} strokeWidth={arrowSize * 2.5}
                      stroke={param.color} markerEnd={`url(#arrow-${i})`}/>
            </g>
        ));
        return (
            <svg style={{position: 'absolute', pointerEvents: 'none', zIndex: '6'}} width={boardWidth}
                 height={boardWidth}>{svgArrows}</svg>
        )
    }
}

function arrowsChanged(newArrows, prevArrows) {
    return JSON.stringify(newArrows) !== JSON.stringify(prevArrows);
}
