/**
 * Created by sabir on 19.07.17.
 */

import {Map, Stack, Set} from 'immutable'
import * as types from '../ActionTypes.js'

const initialState = {
    loading: false,

    tagsMap: Map(),
    error: undefined

}

const startLoading = (state, action) => {
    return { ...state, loading: true, error: undefined}
}

const stopLoading = (state, action) => {
    return { ...state, loading: false, error: action.error}
}

const TagsReducer =  (state = initialState, action = {}) => {

    switch (action.type) {


        case types.CREATE_TAG:
        case types.UPDATE_TAG:
        case types.DELETE_TAG:
        case types.LOAD_TAGS:
            return {
                ...state,
                loading: true,
                error: undefined
            }

        case types.CREATE_TAG_FAIL:
        case types.UPDATE_TAG_FAIL:
        case types.DELETE_TAG_FAIL:
        case types.LOAD_TAGS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case types.CREATE_TAG_SUCCESS:
        case types.UPDATE_TAG_SUCCESS:
            return {
                ...state,
                loading: false,
                tagsMap: state.tagsMap.set(action.tag.id, action.tag)
            }


        case types.DELETE_TAG_SUCCESS:
            return {
                ...state,
                loading: false,
                tagsMap: state.tagsMap.delete(action.id),
            }



        case types.LOAD_TAGS_SUCCESS:
            return {
                ...state,
                loading: false,
                tagsMap: state.tagsMap
                    .merge(action.tags.reduce((map, form) => map.set(form.id, form), Map()))
            }


        default:
            return state;
    }

}

export default TagsReducer;
