import ExercisesFoldersAPI from '../../api/ExercisesFoldersAPI'

import * as types from '../ActionTypes'

let loadExercisesFolders_ = () => {
    return {
        type: types.LOAD_EXERCISES_FOLDERS
    }
}
let loadExercisesFoldersSuccess = (exercisesFolders) => {
    return {
        type: types.LOAD_EXERCISES_FOLDERS_SUCCESS,
        exercisesFolders: exercisesFolders
    }
}
let loadExercisesFoldersFailed = (error) => {
    return {
        type: types.LOAD_EXERCISES_FOLDERS_FAIL,
        error: error
    }
}

//thunk
export function loadAllExercisesFolders() {
    return (dispatch, getState) => {
        dispatch(loadExercisesFolders_());
        return ExercisesFoldersAPI.getAllExercisesFolders().then(
            exercisesFolders => dispatch(loadExercisesFoldersSuccess(exercisesFolders)),
            error => dispatch(loadExercisesFoldersFailed(error))
        )
    }
}

export function loadExercisesFolderById(id) {
    return (dispatch, getState) => {
        dispatch(loadExercisesFolders_());
        return ExercisesFoldersAPI.getExercisesFolder(id).then(
            exercisesFolder => dispatch(loadExercisesFoldersSuccess([exercisesFolder])),
            error => dispatch(loadExercisesFoldersFailed(error))
        )
    }
}

let createExercisesFolder_ = () => {
    return {
        type: types.CREATE_EXERCISES_FOLDER
    }
}
let createExercisesFolderSuccess = (exercisesFolder) => {
    return {
        type: types.CREATE_EXERCISES_FOLDER_SUCCESS,
        exercisesFolder: exercisesFolder
    }
}
let createExercisesFolderFailed = (error) => {
    return {
        type: types.CREATE_EXERCISES_FOLDER_FAIL,
        error: error
    }
}

//thunk
export function createExercisesFolder(data) {
    return (dispatch, getState) => {
        dispatch(createExercisesFolder_());
        return ExercisesFoldersAPI.createExercisesFolder(data).then(
            exercisesFolder => dispatch(createExercisesFolderSuccess(exercisesFolder)),
            error => dispatch(createExercisesFolderFailed(error))
        )
    }
}

let updateExercisesFolder_ = () => {
    return {
        type: types.UPDATE_EXERCISES_FOLDER
    }
}
let updateExercisesFolderSuccess = (exercisesFolder) => {
    return {
        type: types.UPDATE_EXERCISES_FOLDER_SUCCESS,
        exercisesFolder: exercisesFolder
    }
}
let updateExercisesFolderFailed = (error) => {
    return {
        type: types.UPDATE_EXERCISES_FOLDER_FAIL,
        error: error
    }
}

//thunk
export function updateExercisesFolder(data) {
    return (dispatch, getState) => {
        dispatch(updateExercisesFolder_());
        return ExercisesFoldersAPI.updateExercisesFolder(data).then(
            exercisesFolder => dispatch(updateExercisesFolderSuccess(exercisesFolder)),
            error => dispatch(updateExercisesFolderFailed(error))
        )
    }
}

let deleteExercisesFolder_ = () => {
    return {
        type: types.DELETE_EXERCISES_FOLDER
    }
}
let deleteExercisesFolderSuccess = (id) => {
    return {
        type: types.DELETE_EXERCISES_FOLDER_SUCCESS,
        id: id
    }
}
let deleteExercisesFolderFailed = (error) => {
    return {
        type: types.DELETE_EXERCISES_FOLDER_FAIL,
        error: error
    }
}

//thunk
export function deleteExercisesFolder(id) {
    return (dispatch, getState) => {
        dispatch(deleteExercisesFolder_());
        return ExercisesFoldersAPI.deleteExercisesFolder(id).then(
            () => dispatch(deleteExercisesFolderSuccess(id)),
            error => dispatch(deleteExercisesFolderFailed(error))
        )
    }
}
