import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import * as actions from "../../../redux/actions/ExercisesActions";
import * as foldersActions from "../../../redux/actions/ExercisesFoldersActions";
import TestsList from "../lists/TestsList";
import AllExercisesFoldersPanel from "../../exercises_folders/panels/AllExercisesFoldersPanel";

export default function ExercisesPicker(props) {
    const dispatch = useDispatch();
    const {
        exercisesIds = [],
        onChange = newIds => {

        }
    } = props; // selected testsIds
    const [expandedFolderId, setExpandedFolderId] = useState(undefined);

    let {currentUser, loading, tests} = useMappedState(useCallback(state => {
        let currentUserId = state.users.currentUserId;
        let currentUser = state.users.usersMap.get(currentUserId);
        return {
            currentUser: currentUser,
            loading: state.users.loading || state.tests.loading,
            tests: state.tests.testsMap.toArray(),
        }
    }, []));

    useEffect(() => {
        dispatch(actions.loadAllExercises()); // todo: optimize
        dispatch(foldersActions.loadAllExercisesFolders());
    }, []);

    return (
        <Wrapper>

            <ListPlaceholder>

                {/*<AllExercisesFoldersPanel*/}
                    {/*expandId={expandedFolderId}*/}
                    {/*onFolderSelect={id => {*/}
                        {/*setExpandedFolderId(id);*/}
                    {/*}}*/}
                    {/*canEdit={false}*/}
                    {/*openExerciseOnClick={false}*/}
                    {/*canAddExercise={false}*/}
                    {/*selectedExercisesIds={exercisesIds == undefined ? [] : exercisesIds}*/}
                    {/*onExerciseClick={exId => {*/}
                        {/*let newExercisesIds = (exercisesIds.indexOf(exId) > -1) ? exercisesIds.filter(eId => (eId != exId)) : exercisesIds.concat([exId]);*/}
                        {/*onChange(newExercisesIds);*/}
                    {/*}}*/}
                {/*/>*/}


            </ListPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const ListPlaceholder = styled.div`
    
`;
