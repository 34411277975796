import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const TournamentsAPI = {

    getAllTournaments() {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/tournaments/all`).then(d => d.data).then(exercises => {
                if (exercises.error != undefined) {
                    return reject(exercises.error);
                }
                resolve(exercises);
            }).catch(err => reject(err));
        });
    },

    createTournament(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/tournaments/create`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    updateTournament(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/tournaments/update`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    getTournament(id) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/tournament/${id}`).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    deleteTournament(id) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/tournaments/delete/${id}`).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

}

export default TournamentsAPI;
