import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Set} from 'immutable'
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import useDebouncedCallback from "use-debounce/lib/callback";
import ExercisesGroupsList from "../lists/ExercisesGroupsList";

import Sidebar from 'arui-feather/sidebar'
import UpdateExercisesGroupForm from "../forms/UpdateExercisesGroupForm";

import * as actions from '../../../redux/actions/ExercisesGroupsActions'

export default function AllExercisesGroupsPanel(props) {
    const dispatch = useDispatch();
    const [selectedExercisesGroupId, setSelectedExercisesGroupId] = useState(undefined);
    const [createModalVisible, setCreateModalVisible] = useState(false);
    const [createParentId, setCreateParentId] = useState(undefined);
    const [expandedSet, setExpandedSet] = useState(Set());
    let {currentUser, loading, exercises, userRole, exercisesGroups} = useMappedState(useCallback(state => {
        let userRole = (currentUser != undefined) ? currentUser.userRole : undefined;
        return {
            userRole: userRole,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            currentUserId: state.users.currentUserId,
            loading: state.exercisesGroups.loading || state.exercises.loading,
            exercisesGroups: state.exercisesGroups.exercisesGroupsMap.toArray().sort((a, b) => (+b.timestamp - +a.timestamp)),
            exercises: state.exercises.exercisesMap.toArray().sort((a, b) => (+b.timestamp - +a.timestamp))
        }
    }, []));

    const getGroupExercises = useCallback((grId) => {
        return (exercises.filter(ex => (ex != undefined)).filter(ex => (ex.groupsIds != undefined && ex.groupsIds.indexOf(grId) > -1)));
    }, []);

    const getGroupById = useCallback((grId) => {
        let arr = exercisesGroups.filter(g => (g.id == grId));
        if (arr.length > 0) {
            return arr[0];
        } else {
            return undefined;
        }
    }, []);

    let selectedExercisesGroup = (selectedExercisesGroupId == undefined) ? undefined : exercisesGroups.filter(ex => (ex.id == selectedExercisesGroupId))[0];

    return (
        <Wrapper>

            <ExercisesGroupsList exercisesGroups={exercisesGroups}
                                 expandedSet={expandedSet}
                                 level={0}
                                 getGroupExercises={getGroupExercises}
                                 onExercisesGroupToggleExpandClick={grId => {
                                     if (expandedSet.has(grId) == true) {
                                         setExpandedSet(expandedSet.delete(grId))
                                     } else {
                                         setExpandedSet(expandedSet.add(grId))
                                     }
                                 }}
                                 onShowExercisesClick={grId => {

                                 }}
                                 onEditClick={grId => {
                                     setSelectedExercisesGroupId(grId)
                                 }}
                                 onAddExercisesGroupClick={pId => {
                                     setCreateModalVisible(true);
                                     setCreateParentId(pId);
                                 }}

            />

            <Sidebar visible={(selectedExercisesGroup != undefined)} onCloserClick={() => {
                setSelectedExercisesGroupId(undefined);
            }}>

                {selectedExercisesGroup == undefined ? null :
                    <div>
                        <UpdateExercisesGroupForm {...selectedExercisesGroup}
                                                  canEdit={(userRole == 'admin')}
                                                  loading={loading} onSubmit={d => {
                            d.id = selectedExercisesGroup.id;
                            dispatch(actions.updateExercisesGroup(d)).then(pld => {
                                if (pld.error != undefined) {
                                    window.alert(pld.error.message);
                                } else {
                                    setSelectedExercisesGroupId(undefined);
                                }
                            });
                        }}/>
                    </div>
                }

            </Sidebar>

            <Sidebar visible={createModalVisible} onCloserClick={() => {
                setCreateModalVisible(false);
                setCreateParentId(undefined);
            }}>

                {createModalVisible == false ? null :
                    <div>
                        <UpdateExercisesGroupForm {...selectedExercisesGroup}
                                                  canEdit={(userRole == 'admin')}
                                                  loading={loading} onSubmit={d => {
                            const parentGroup = getGroupById(createParentId);
                            let data = {
                                ...d,
                                parentId: createParentId,
                                level: (parentGroup == undefined) ? 0 : (+parentGroup.level + 1)
                            }
                            dispatch(actions.createExercisesGroup(data)).then(pld => {
                                if (pld.error != undefined) {
                                    window.alert(pld.error.message);
                                } else {
                                    setCreateModalVisible(false);
                                    setCreateParentId(undefined);
                                }
                            });
                        }}/>
                    </div>
                }

            </Sidebar>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;
