import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import {Input} from "../../ui/Input";

let isIn = (a, b) => (a != undefined && b != undefined && (b.trim() == '' || a.toLowerCase().indexOf(b.toLowerCase()) > -1));

export default function TestsList(props) {
    const {
        tests = [],
        loading = false,
        selectedId, onSelect = id => {

        },
        selectable = true,
        searchable = true,
        pickedSet = Set()
    } = props;

    const [text, setText] = useState('');
    let fiteredTests = tests.filter(a => (a.name != undefined && (isIn(a.name, text) || isIn(a.description, text))));

    const searchBarVisible = (searchable && (tests.length > 5));;

    return (
        <Wrapper>

            {searchBarVisible == false ? null :
                <TopPlaceholder>
                    <Input placeholder={'Поиск по названию или описанию'} onChange={evt => {
                        setText(evt.target.value);
                    }}/>
                </TopPlaceholder>
            }

            <ListPlaceholder>
                {fiteredTests.map((a, k) => {
                    let isSelected = (a.id == selectedId);
                    let isPicked = (pickedSet.has(a.id));
                    return (
                        <TestItem selected={isSelected && selectable} picked={isPicked} key={a.id} onClick={() => {
                            onSelect(a.id);
                        }}>
                            <InfoPlaceholder>
                                <Name>
                                    {a.name}
                                </Name>
                                <Description>
                                    {a.description}
                                </Description>
                            </InfoPlaceholder>
                            <ExNumsPlaceholder>
                                {selectable == false ?
                                    <>
                                        <ChessImg src={require('../../../assets/images/chess.svg')}/>
                                        <span>
                                            {a.exercisesIds.length}
                                        </span>
                                    </> :
                                    <>
                                    {isPicked == false ? null : <CheckImg src={require('../../../assets/images/check.svg')} />}
                                    </>
                                }

                            </ExNumsPlaceholder>
                        </TestItem>
                    )
                })}
            </ListPlaceholder>

        </Wrapper>
    );
}

const TopPlaceholder = styled.div`
    margin-bottom: 10px;
`;

const Wrapper = styled.div`
    
`;

const ListPlaceholder = styled.div`
    
`;

const TestItem = styled.div`
    margin-bottom: 10px;
    background: ${props => (props.selected == true ? 'blue' : 'white')};
    color: ${props => (props.selected == true ? 'white' : 'black')};
    padding: 5px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: ${props => (props.picked == true ? '1px solid blue' : '1px solid lightgrey')};
    :hover{
      opacity: 0.7;
    }
`;

const Name = styled.div`
    font-weight: bold;
    font-size: 16px;
`;

const Description = styled.div`
    opacity: 0.7;
    font-size: 12px;
    font-style: italic;
`;

const InfoPlaceholder = styled.div`
    flex: 1;
`;

const ExNumsPlaceholder = styled.div`
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 18px;
`;

const ChessImg = styled.img`
    width: 20px;
    height: 20px;
    margin-right: 5px;
`;

const CheckImg = styled.img`
    
`;
