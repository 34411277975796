import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';
import HorChessTemplate from "../templates/HorChessTemplate";

import AllExercisesGroupsPanel from "../exercises_groups/panels/AllExercisesGroupsPanel";
import AllTestsPanel from "../tests/panels/AllTestsPanel";

export default function AdminTestsApp(props) {

    return (
        <HorChessTemplate active={'tests'}>

            <AllTestsPanel canUpdate={true} />

        </HorChessTemplate>
    );
}

const Wrapper = styled.div`
    background: white;
    padding: 10px;
    border-radius: 4px;
`;
