import React, {useState, useEffect} from 'react';
import moment from 'moment';
import styled from 'styled-components';
import RecordingsAPI from "../../../api/RecordingsAPI";
import {BlueButton, GreenButton, RedButton} from "../../ira/ui/Buttons";
import {Code} from "react-content-loader";

import Spin from 'arui-feather/spin'
import NiceModal from "../../modals/NiceModal";

export default function LessonRecordingsPanel(props) {
    const {
        lessonId,
        onChange = () => {

        }
    } = props;
    const [loading, setLoading] = useState(false);
    const [recordings, setRecordings] = useState([]);
    const [initialized, setInitialized] = useState(false);
    const [selectedId, setSelectedId] = useState(undefined);

    useEffect(() => {
        setLoading(true);
        RecordingsAPI.getLessonRecordings(lessonId).then(arr => {
            setRecordings(arr);
            setLoading(false);
            setInitialized(true);
        });
    }, [lessonId]);

    let sortedRecordings = recordings.sort((a, b) => (+b.timestamp - +a.timestamp));
    let latestRecording = (sortedRecordings.length == 0) ? undefined : sortedRecordings[0];
    let canStartRecording = (latestRecording == undefined || (latestRecording.status == 'finished' || latestRecording.status == 'failed'));
    let canStopRecording = (latestRecording != undefined && latestRecording.status != 'finished' && latestRecording.status != 'failed');

    if (initialized == false) {
        return (
            <Code/>
        )
    }

    let goodRecordings = recordings.filter(x => (x.status != 'failed'));
    const selectedRecording = (selectedId == undefined) ? undefined : recordings.filter(x => (x.id == selectedId))[0];
    let selUrl = (selectedRecording == undefined) ? undefined : (selectedRecording.resultUrl || selectedRecording.webmUrl);

    return (
        <Wrapper>

            {goodRecordings.length == 0 ? null :
                <RecordingsList>
                    <h2>
                        Записи урока
                    </h2>
                    {goodRecordings.map((a, i) => {
                        let {status} = a;
                        return (
                            <RecordingItem key={a.id}>
                                <RowLeft style={{cursor: (status == 'new' ? 'default' : 'pointer')}} onClick={() => {
                                    if (status == 'finished') {
                                        setSelectedId(a.id);
                                    }
                                }}>
                                    {moment(a.timestamp).format('DD.MM.YYYY HH:mm:ss')}
                                </RowLeft>
                                <RowRight recording={status == 'new'}>
                                    {status == 'finished' ? 'Запись завершена' : 'Идет запись...'}
                                </RowRight>
                            </RecordingItem>
                        )
                    })}
                </RecordingsList>
            }


            <TopPlaceholder>
                {canStartRecording == false ? null :
                    <GreenButton onClick={async () => {
                        if (loading == true) {
                            return;
                        }
                        setLoading(true);
                        await RecordingsAPI.startRecording(lessonId);
                        let arr = await RecordingsAPI.getLessonRecordings(lessonId);
                        setRecordings(arr);
                        setLoading(false);
                        onChange();
                    }}>
                        <Spin visible={loading}/>
                        {loading ? null : 'Начать запись'}
                    </GreenButton>
                }
                {canStopRecording == false ? null :
                    <RedButton onClick={async () => {
                        if (loading == true) {
                            return;
                        }
                        setLoading(true);
                        await RecordingsAPI.stopRecording(lessonId);
                        let arr = await RecordingsAPI.getLessonRecordings(lessonId);
                        setRecordings(arr);
                        setLoading(false);
                        onChange();
                    }}>
                        <Spin visible={loading}/>
                        {loading ? null : 'Остановить запись'}
                    </RedButton>
                }
            </TopPlaceholder>

            {selectedRecording == undefined ? null :
                <NiceModal onClose={() => {
                    setSelectedId(undefined);
                }}>
                    <InnerPlaceholder>
                        {selUrl == undefined ?
                            <VoidErrorPlaceholder>
                                Видео обрабатывается...
                            </VoidErrorPlaceholder>
                            :
                            <Video controls={true}>
                                <source src={selUrl}/>
                            </Video>
                        }
                    </InnerPlaceholder>
                </NiceModal>
            }

        </Wrapper>
    );
}

const VoidErrorPlaceholder = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InnerPlaceholder = styled.div`
  width: 620px;
  height: 400px;
`;

const Video = styled.video`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`;

const RowLeft = styled.div`
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

const RowRight = styled.div`
  opacity: 0.5;
  color: ${props => (props.recording == true ? 'green' : 'black')};
`;

const RecordingItem = styled.div`
  margin-bottom: 10px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid lightgrey;
  padding-bottom: 15px;
`;


const Wrapper = styled.div`

`;

const TopPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;


const RecordingsList = styled.div`
  margin-bottom: 20px;
`;