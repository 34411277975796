import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import UiAPI from "../../../../api/UiAPI";
import SimpleBoardViewTool from "../tools/SimpleBoardViewTool";


export default function SelectStyleBoardPanel(props) {
    const {
        selectedId, onSelect = (id, d) => {

        }
    } = props;
    const [boards, setBoards] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(true);
        UiAPI.getAllBoards().then(arr => {
            setBoards(arr);
            setLoading(false);
        })
    }, [selectedId]);

    if (loading == true && boards.length == 0) {
        return (
            <Code/>
        );
    }

    return (
        <Wrapper>

            <BoardsItems>
                {boards.map((a, j) => {
                    return (
                        <BoardItem selected={(selectedId == a.id)} key={a.id} onClick={() => {
                            onSelect(a.id, a);
                        }}>
                            <Name>
                                {a.name}
                            </Name>
                            <BoardPlaceholder>
                                <SimpleBoardViewTool {...a} data={a}/>
                            </BoardPlaceholder>
                        </BoardItem>
                    )
                })}
            </BoardsItems>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const BoardsItems = styled.div`
    padding: 10px;
`;

const BoardItem = styled.div`
    padding: 10px;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 10px;
    border: ${props => (props.selected == true ? '2px solid blue' : '2px dashed lightgrey')};
    cursor: pointer;
    :hover{
      border: 2px solid blue;
      background: whitesmoke;
    }
`;

const Name = styled.div`
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 5px;
`;

const BoardPlaceholder = styled.div`
    width: 360px;
    height: 360px;
    border: 1px solid lightgrey;
`;

