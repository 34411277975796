import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'

import FirebaseAPI from '../../../api/FirebaseAPI'
import SizeDetector from '@atlaskit/size-detector'
import PlayableSabirChessBoard from "../../chessboard/tools/PlayableSabirChessBoard";

import {STARTING_FEN} from "../../../constants/config";
import ChessHelper from "../../../helpers/ChessHelper";
import CommonHelper from "../../../helpers/CommonHelper";
import SimpleHistoryViewer from "../tools/SimpleHistoryViewer";

import {Textarea} from "../../ui/Textarea";

export default function RealtimeBoard(props) {
    let {
        boardId = 'sabir',
        currentUserId,
        outerLoading = false,
        myColor = undefined,
        showLoadingText = true,
        orientation = 'white', shouldShowBottomInfo = true,
        startingFen = STARTING_FEN,
        adminMode = false,
        whiteUserName = undefined,
        blackUserName = undefined,
        gameName = undefined
    } = props;
    const [boardData, setBoardData] = useState(undefined);
    const [wrapperDimensions, setWrapperDimensions] = useState(undefined);
    const [initialized, setInitialized] = useState(false);
    const roomName = `room_${boardId}`;
    const currRef = useRef(undefined);
    const [historyModalVisible, setHistoryModalVisible] = useState(false);
    console.log('--->>>   !!!   --->>>   RealtimeBoard: render: boardId = ', boardId);
    console.log('--->>>   !!!   --->>>   RealtimeBoard: render: initialized = ', initialized);


    useEffect(() => {
        FirebaseAPI.initFirebase();
        FirebaseAPI.subscribeOnChanges(roomName, chData => {
            console.log('chData = ', chData);
            if (chData == undefined) {
                return
            }
            setBoardData(chData.boardData);
        }, startingFen).then(pld => {
            // setTimeout(() => {
            //     setInitialized(true);
            // }, 200)
            setInitialized(true);
            console.log('subscribeOnChanges: then: pld = ', pld);
            if (pld.unsubscribeFunction != undefined) {
                currRef.current = {unsubscribeFunction: pld.unsubscribeFunction};
            }
            if (pld == undefined || pld.data == undefined) {
                return;
            }
            console.log('RealtimeBoard: unsubscribeFunction = ', pld.unsubscribeFunction);
            // setBoardData(pld.data);
            if (!(pld == undefined || pld.data == undefined)) {
                setBoardData(pld.data);
            }
        }).catch(err => {
            console.log('RealtimeBoard: can not subscribe!');
        });
        return () => {
            console.log('trying to unsubscribe!');
            try {
                currRef.current.unsubscribeFunction();
            } catch (exc) {
                console.log('can not unsubscribe');
            }
        }
    }, [boardId]);

    const changeBoardState = useCallback((newBoardData) => {
        console.log('changeBoardState: newBoardData = ', newBoardData);
        FirebaseAPI.updateBoardFireState(roomName, newBoardData);
    }, [boardId]);

    let bh = (shouldShowBottomInfo == true) ? borderBottomHeight : 0;

    const boardDimensions = (wrapperDimensions == undefined) ? undefined : {
        width: (wrapperDimensions == undefined) ? undefined : Math.min(wrapperDimensions.height, wrapperDimensions.width) - bh,
        height: (wrapperDimensions == undefined) ? undefined : Math.min(wrapperDimensions.height, wrapperDimensions.width) - bh
    };

    const history = (boardData == undefined) ? [] : (boardData.history == undefined ? [] : boardData.history);
    const fen = (boardData == undefined) ? STARTING_FEN : (boardData.startingFen == undefined ? STARTING_FEN : boardData.startingFen);
    console.log('RealtimeBoard: boardData = ', boardData);
    // const fen = startingFen;
    let currentFen = ChessHelper.getFenByInitialFenAndMoves(fen, history.map(a => ({...a, move: a.san})));
    console.log('RealtimeBoard: startingFen, boardData = ', startingFen, boardData);
    console.log('RealtimeBoard: fen = ', fen);
    let moveColor = currentFen.split(' ')[1];
    let isMyTurn = (moveColor == myColor);
    let chessObj = ChessHelper.getChessObjectByFenAndMoves(fen, history);
    if (gameName != undefined) {
        chessObj.header('Event', gameName);
    }

    return (
        <Wrapper>

            <SizeDetector onResize={d => {
                setWrapperDimensions(d);
            }}>
                {({width, height}) => (
                    <Inner style={{width: Math.min(width, height), height: Math.min(width, height)}}
                           className={(isMyTurn == true) ? 'my_turn' : ''}
                    >
                        {((boardDimensions == undefined) || (boardData == undefined) || (initialized == false) || (outerLoading == true)) ?
                            <LoadingPlaceholder>
                                <div>
                                    <div>
                                        <LoaderImg src={require('../../../assets/images/shagi_logo_wide_500.png')}/>
                                    </div>
                                    {showLoadingText == false ? null :
                                        <div>
                                            загрузка...
                                        </div>
                                    }
                                </div>
                            </LoadingPlaceholder>
                            :
                            <BoardPlaceholder style={{width: boardDimensions.width}}
                                              className={(isMyTurn == true) ? 'my_turn' : ''}
                            >
                                <PlayableSabirChessBoard
                                    fen={fen}
                                    shouldShowBottomInfo={shouldShowBottomInfo}
                                    canMakeMoves={(isMyTurn == true) || (adminMode == true)}
                                    orientation={orientation}
                                    startHistory={history}
                                    canChangeOrientation={false}
                                    onOrientationChange={() => {

                                    }}
                                    canViewHistory={true}
                                    onHistoryOpen={() => {
                                        setHistoryModalVisible(true);
                                    }}
                                    hasSettingsButton={adminMode}
                                    boardId={boardId}
                                    canGoBack={adminMode}
                                    onMove={(newFen, newPgn, newHistory) => {
                                        console.log('--->>>   XXX---XXXX   !!! onMove: newFen, newPgn, newHistory = ', newFen, newPgn, newHistory);
                                        changeBoardState({
                                            ...boardData,
                                            history: newHistory
                                        });
                                    }}
                                    onGoBack={() => {
                                        if (history != undefined && history.length > 0) {
                                            if (window.confirm('Вы уверены, что хотите вернуть ход?') == false) {
                                                return;
                                            }
                                            changeBoardState({
                                                ...boardData,
                                                history: history.slice(0, history.length - 1)
                                            });
                                        }
                                    }}
                                />
                            </BoardPlaceholder>
                        }
                    </Inner>
                )}
            </SizeDetector>

            <Sidebar width={Math.min(620, window.innerWidth)} visible={historyModalVisible} onCloserClick={() => {
                setHistoryModalVisible(false);
            }}>
                {historyModalVisible == false ? null :
                    <div>
                        <Heading>
                            Ходы
                        </Heading>
                        <MovesPlaceholder>
                            <MovesPlaceholderInner>
                                <SimpleHistoryViewer history={history}/>
                            </MovesPlaceholderInner>
                        </MovesPlaceholder>

                        <Heading>
                            Экспорт в PGN
                        </Heading>
                        <div>
                            <Textarea value={chessObj.pgn()}/>
                        </div>

                    </div>
                }
            </Sidebar>

        </Wrapper>
    );
}

const borderBottomHeight = 40;

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
`;

const Inner = styled.div`
    position: relative;
`;

const BoardPlaceholder = styled.div`
    margin: 0 auto;
    height: 100%;
    box-sizing: border-box;
    &.my_turn{
      background-color: rgba(144,238,144,0.29);
    }
`;

const LoadingPlaceholder = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;  
    font-size: 24px;
    text-align: center;
    height: 100%;
    width: 100%;
    background: whitesmoke;
`;

const LoaderImg = styled.img`
    height: 80px;
    display: inline-block;
`;

const MovesPlaceholder = styled.div`
    margin-bottom: 20px;
`;

const MovesPlaceholderInner = styled.div`
    width: 300px;
    background: whitesmoke;
`;

const Heading = styled.div`
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
`;
