import axios from 'axios'

export const getPubEndpoint = () => {
    try {
        return window.NCHAN_PUB_ENDPOINT || 'https://pubsub.cloud12.patient.sabir.pro/pub';
    } catch (exc) {

    }
}

export const getSubEndpoint = () => {
    try {
        return (window.NCHAN_SUB_ENDPOINT || 'https://pubsub.cloud12.patient.sabir.pro/sub');
    } catch (exc) {

    }
}

export default class NchanAPI {

    static channelsMap = {}

    static subscribe(channelName, onMessage = d => {

    }) {
        console.log('subscribe to ', channelName);
        this.channelsMap[channelName] = new window.EventSource(`${getSubEndpoint()}/${channelName}`);
        this.channelsMap[channelName].addEventListener('message', e => {
            onMessage(e.data);
        });
    }

    static unsubscribe(channelName) {
        try {
            console.log(`unsubscribing from ${channelName}`);
            this.channelsMap[channelName].close();
        } catch (exc) {

        }
    }

    static async getDefaultChannelMessage(channelName) {
        try {
            let oldData = (await axios.get(`${getPubEndpoint()}/${channelName}`)).data;
            console.log('getDefaultChannelMessage: oldData = ', oldData);
            return oldData;
        } catch (exc) {

        }
    }

    static async publish(channelName, data = {}) {
        let dd = {};
        try {
            // try {
            //     let oldData = (await axios.get(`${getPubEndpoint()}/${channelName}`)).data;
            //     dd = (oldData == undefined) ? {} : oldData;
            // } catch (exc0) {
            //
            // }
            dd = {...dd, ...data};
            console.log('Nchan: publish: dd = ', dd);
            await axios.post(`${getPubEndpoint()}/${channelName}`, dd);
        } catch (exc) {

        }
    }

}
