import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const ArrowsAPI = {

    async getConfiguration(roomId, fen) {
        let d = (await axios.get(`${API_ENDPOINT}/room/${roomId}/board_conf`, {
            params: {
                fen: fen
            }
        })).data.result;
        return d;
    },

    async updateConfiguration(roomId, fen, data) {
        let d = (await axios.put(`${API_ENDPOINT}/room/${roomId}/board_conf`, {fen: fen, data: data})).data;
        return d;
    },


}

export default ArrowsAPI;
