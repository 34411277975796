import cloneDeep from 'lodash.clonedeep';

const kokopu = require('kokopu');

const SPECIAL_NAGS_LOOKUP = {
    3: '!!',      // very good move
    1: '!',       // good move
    5: '!?',      // interesting move
    6: '?!',      // questionable move
    2: '?',       // bad move
    4: '??',      // very bad move
    18: '+\u2212', // White has a decisive advantage
    16: '\u00b1',  // White has a moderate advantage
    14: '\u2a72',  // White has a slight advantage
    10: '=',       // equal position
    11: '=',       // equal position (ChessBase)
    15: '\u2a71',  // Black has a slight advantage
    17: '\u2213',  // Black has a moderate advantage
    19: '\u2212+', // Black has a decisive advantage
    7: '\u25a1',  // Only move
    8: '\u25a1',  // Only move (ChessBase)
    13: '\u221e',  // unclear position
    22: '\u2a00',  // Zugzwang
    32: '\u27f3',  // Development advantage
    36: '\u2191',  // Initiative
    40: '\u2192',  // Attack
    132: '\u21c6',  // Counterplay
    138: '\u2295',  // Zeitnot
    140: '\u2206',  // With idea
    142: '\u2313',  // Better is
    146: 'N'        // Novelty
};

const FIGURES = ['K', 'Q', 'R', 'B', 'N', 'P'];

const PgnHelper = {
    parse(pgn, gameIndex = 0) {
        let game = null;
        let error = null;

        if (typeof pgn !== 'string') {
            pgn = '*';
        }

        pgn = pgn.replace(/^\s+|\s+$/g, '');

        try {
            game = kokopu.pgnRead(pgn, gameIndex);
        } catch (err) {
            if (err instanceof kokopu.exception.InvalidPGN) {
                game = null;
                error = err;
            } else {
                game = null;
                throw error;
            }
        }

        return {
            game,
            error
        };
    },

    selectNext(groups, node, callback = () => {
    }) {
        const groupIdx = PgnHelper.getGroupId(node);
        const nodeIdx = PgnHelper.getSerialNumber(node);

        const currentGroup = groups[groupIdx];
        const currentNode = currentGroup.nodes[nodeIdx];
        const nextNode = currentGroup.nodes[nodeIdx + 1];
        const nextGroup = groups[groupIdx + 1];

        const doSelect = (currentNode, nextNode) => {
            PgnHelper.setSelected(currentNode, false);
            PgnHelper.setSelected(nextNode, true);
            callback(nextNode);
        }

        if (nextNode) {
            doSelect(currentNode, nextNode);
        } else if (nextGroup) {
            doSelect(currentNode, nextGroup.nodes[0]);
        }
    },

    selectPrev(groups, node, callback = () => {
    }) {
        const groupIdx = PgnHelper.getGroupId(node);
        const nodeIdx = PgnHelper.getSerialNumber(node);

        const currentGroup = groups[groupIdx];
        const currentNode = currentGroup.nodes[nodeIdx];
        const prevNode = currentGroup.nodes[nodeIdx - 1];
        const prevGroup = groups[groupIdx - 1];

        const doSelect = (currentNode, prevNode) => {
            PgnHelper.setSelected(currentNode, false);
            PgnHelper.setSelected(prevNode, true);
            callback(prevNode);
        }

        if (prevNode) {
            doSelect(currentNode, prevNode);
        } else if (prevGroup) {
            doSelect(currentNode, prevGroup.nodes[prevGroup.nodes.length - 1]);
        }
    },

    markAsSelected(groups, selected) {
        groups.forEach(group => {
            group.nodes.forEach(node => {
                if (PgnHelper.getNodeId(node) === PgnHelper.getNodeId(selected)) {
                    PgnHelper.setSelected(node, true);
                } else {
                    PgnHelper.setSelected(node, false);
                }
            });
        });
    },

    setSelected(node, selected) {
        node._pgn_selected = selected;
        return node;
    },

    isSelected(node) {
        return node._pgn_selected;
    },

    setUid(groups, node) {
        const path = new Path();

        let uid = PgnHelper.getNotation(node);

        path.build(groups, node, ({node}) => {
            uid = uid + PgnHelper.getNotation(node);
        });

        node._uid = uid;

        return node;
    },

    getUid(node) {
        return node._uid;
    },

    groupList(nodes, game) {
        let comment = '';

        if (game && typeof game.mainVariation === 'function') {
            const variant = game.mainVariation();

            if (variant && typeof variant.comment === 'function') {
                comment = variant.comment();
            }
        }

        const groups = PgnHelper._groupList(nodes, 0, comment)
            .filter(group => (group.nodes && group.nodes.length));

        return groups.map((group, gid) => {
            group.nodes = group.nodes.map((node, idx) => {
                node = PgnHelper.setGroupId(node, gid);
                node = PgnHelper.setSerialNumber(node, idx);
                node = PgnHelper.setUid(groups, node);

                return node;
            });
            return {...group, id: gid};
        });
    },

    setNodeId(node, id) {
        try {
            node._pid = id;
            return node;
        } catch (exc) {

        }
    },

    getNodeId(node) {
        return node._pid;
    },

    setGroupId(node, id) {
        if (node != undefined) {
            node._gid = id;
        }
        return node;
    },

    getGroupId(node) {
        if (node == undefined) {
            return undefined;
        }
        return node._gid;
    },

    setSerialNumber(node, idx) {
        try {
            node._serialNumber = idx;
            return node;
        } catch (exc) {

        }
    },

    getSerialNumber(node) {
        if (node == undefined) {
            return undefined;
        }
        return node._serialNumber;
    },

    nodes(game) {
        return game ? game.mainVariation().nodes() : [];
    },

    getComment(node) {
        return node && node.comment() ? ' ' + node.comment() : '';
    },

    getStartFen(game) {
        return game.initialPosition().fen();
    },

    getFen(node) {
        return node.position().fen();
    },

    getNotation(node) {
        return node ? node.notation() : '';
    },

    getMoveNumber(node) {
        return node ? node.fullMoveNumber() : '';
    },

    isWColor(node) {
        return (node && node.moveColor() === 'w');
    },

    getNags(node) {
        const nags = node.nags();
        let output = [];

        for (let k = 0; k < nags.length; ++k) {
            output.push(PgnHelper._formatNag(nags[k]));
        }

        return output.join(' ');
    },

    getArrowsAndHighlightedSquares(node) {
        if (node.tags().length < 1) {
            return [[], []];
        }

        const ARROWS_MATCH_REGEX = /([GRY])([a-h][1-8][a-h][1-8])/gs;
        const SQUARES_MATCH_REGEX = /([GRY])([a-h][1-8])/gs;

        const arrowsTag = node.tag("cal");
        const squaresTag = node.tag("csl");

        const pgnArrows = !arrowsTag ? null : arrowsTag.match(ARROWS_MATCH_REGEX);
        const pgnHighlightedSquares = !squaresTag ? null : squaresTag.match(SQUARES_MATCH_REGEX);


        const arrows = !pgnArrows ? [] : pgnArrows.map(pgnArrow => {
            const color = PgnHelper._getColor(pgnArrow[0]);
            const from = pgnArrow.substring(1, 3);
            const to = pgnArrow.substring(3, 5);
            return {color, from, to};
        });
        const highlightedSquares = !pgnHighlightedSquares ? [] : pgnHighlightedSquares.map(pgnSquare => {
            const color = PgnHelper._getColor(pgnSquare[0]);
            const position = pgnSquare.substring(1, 3);
            return {color, position};
        })

        return [arrows, highlightedSquares];
    },

    getFigureAndField(node) {
        let notation = PgnHelper.getNotation(node);
        let figureAndField = null;

        FIGURES.forEach((figure) => {
            if (notation.indexOf(figure) === 0) {
                const field = notation.replace(figure, '');
                figureAndField = {
                    figure,
                    field
                }
            }
        });

        return figureAndField;
    },

    consoleLog(game) {
        for (let node = game.mainVariation().first(); node; node = node.next()) {
            console.log(node.fullMoveNumber() + ' ' + node.notation() + (node.comment() ? ' ' + node.comment() : ''));

            node.variations().forEach(function (variation, index) {
                let text = '  variation ' + (index + 1) + ' ->';

                if (variation.comment()) {
                    text += ' ' + variation.comment();
                }

                for (let nodeInVariation = variation.first(); nodeInVariation; nodeInVariation = nodeInVariation.next()) {
                    text += ' ' + nodeInVariation.fullMoveNumber() + ' ' + nodeInVariation.notation();
                    if (nodeInVariation.comment()) {
                        text += ' ' + nodeInVariation.comment();
                    }
                }

                console.log(text);
            });
        }

        console.log(Array(100).fill('*').join(''));
    },

    _idx() {
        let idx = -1;
        return () => ++idx;
    },

    _groupList(nodes, level = 0, comment = '', idx = PgnHelper._idx(), groupIdx = PgnHelper._idx()) {
        let groups = [{
            id: groupIdx(),
            level,
            comment,
            nodes: []
        }];

        nodes.forEach(node => {
            let newNode = PgnHelper.setNodeId(node, idx());
            const group = groups[groups.length - 1];

            newNode = PgnHelper.setGroupId(newNode, group.id);

            group.nodes.push(newNode);

            const variations = node.variations();

            if (variations.length) {
                variations.forEach((variation, index) => {
                    groups = groups.concat(
                        PgnHelper._groupList(variation.nodes(), level + 1, variation.comment(), idx, groupIdx)
                    );
                });

                groups.push({
                    id: groupIdx(),
                    level,
                    comment: '',
                    nodes: []
                })
            }
        });

        return groups;
    },

    _formatNag(nag) {
        if (nag in SPECIAL_NAGS_LOOKUP) {
            return SPECIAL_NAGS_LOOKUP[nag];
        } else {
            return '$' + nag;
        }
    },

    _getColor(colorLiteral) {
        return colorLiteral === 'G' ? 'green' :
            colorLiteral === 'R' ? 'red' : 'yellow';
    }
};

export const isEqualFens = (a, b) => {
    a = a.trim().split(' ')[0];
    b = b.trim().split(' ')[0];

    return a === b;
};

export const getAttachedVariant = (fen, variants) => {
    const iterator = new Iterator(variants);

    do {
        const variant = iterator.current();

        if (!variant) {
            return null;
        }

        if (isEqualFens(variant.fen, fen)) {
            return variant;
        }
    } while (iterator.next());

    return null;
};

export const getAttachedVariantByUid = (uid, variants) => {
    const iterator = new Iterator(variants);

    do {
        const variant = iterator.current();

        if (!variant) {
            return null;
        }

        if (variant.uid === uid) {
            return variant;
        }
    } while (iterator.next());

    return null;
};

export function Path(iterator) {
    return {
        get: function (node) {
            const data = iterator.data();
            const branchIndex = PgnHelper.getGroupId(node);
            const nodeIndex = PgnHelper.getSerialNumber(node);
            const branches = cloneDeep(data.slice(0, branchIndex + 1));

            branches[branchIndex].nodes = branches[branchIndex].nodes.slice(0, nodeIndex + 1);

            return branches;
        },
        build: function (groups, node, callback = e => e) {
            const iterator = new NodeIterator(groups, [
                PgnHelper.getGroupId(node),
                PgnHelper.getSerialNumber(node)
            ]);

            let baseLevel = iterator.branch().level;
            let startGroupId = PgnHelper.getGroupId(node);

            if (iterator.prev()) {
                do {
                    const currentNode = iterator.current();
                    const currentBrunch = iterator.branch();
                    const currentLevel = currentBrunch.level;
                    const currentMoveNumber = PgnHelper.getMoveNumber(currentNode);
                    const currentGroupId = PgnHelper.getGroupId(currentNode);

                    const nextNode = iterator.nextItem();
                    const nextBranch = iterator.nextBranch();
                    const nextBranchLevel = nextBranch ? nextBranch.level : null;
                    const nextMoveNumber = nextNode ? PgnHelper.getMoveNumber(nextNode) : null;

                    /** Skipping alternative scenarios for same move number */
                    if (currentLevel !== 0 && currentGroupId !== startGroupId && currentLevel === nextBranchLevel) {
                        continue;
                    }

                    if (currentLevel === baseLevel) {
                        baseLevel = currentLevel;
                        callback({node: currentNode});
                    }

                    if (currentLevel < baseLevel) {
                        baseLevel = currentLevel;

                        if (currentMoveNumber !== nextMoveNumber) {
                            callback({node: currentNode});
                        }
                    }

                } while (iterator.prev())
            }
        }
    };
}

export function Iterator(data = [], idx = 0) {
    let index = idx;

    return {
        current: function () {
            return data[index];
        },
        prev: function () {
            const prev = this.prevItem();

            if (prev) {
                index--;
            }

            return prev;
        },
        next: function () {
            const next = this.nextItem();

            if (next) {
                index++;
            }

            return next;
        },
        index: function () {
            return index;
        },
        prevItem: function () {
            return data[index - 1];
        },
        nextItem: function () {
            return data[index + 1];
        },
        data: function () {
            return data;
        },
    }
}

export function NodeIterator(data = [], idx = [0, 0]) {
    const branches = new Iterator(data, idx[0]);

    let index = idx[1];

    return {
        current: function () {
            return this.nodes()[index];
        },
        prev: function () {
            const prevNode = this.nodes()[index - 1];

            if (prevNode) {
                index--;
                return prevNode;
            } else {
                const prevBranch = branches.prev();

                if (prevBranch) {
                    this.reset(prevBranch.nodes.length - 1);
                    return this.current();
                }
            }

            return null;
        },
        prevItem: function () {
            const prevNode = this.nodes()[index - 1];

            if (prevNode) {
                return prevNode;
            } else {
                const prevBranch = this.prevBranch();

                if (prevBranch) {
                    const prevNodes = prevBranch.nodes;
                    return prevNodes[prevNodes.length - 1];
                }
            }

            return null;
        },
        prevBranch: function () {
            return branches.prevItem();
        },
        next: function () {
            const nextNode = this.nodes()[index + 1];

            if (nextNode) {
                index++;
                return nextNode;
            } else {
                const nextBranch = branches.next();

                if (nextBranch) {
                    this.reset();
                    return this.current();
                }
            }

            return null;
        },
        nextItem: function () {
            const nextNode = this.nodes()[index + 1];

            if (nextNode) {
                return nextNode;
            } else {
                const nextBranch = this.nextBranch();

                if (nextBranch) {
                    return nextBranch.nodes[0];
                }
            }

            return null;
        },
        nextBranch: function () {
            return branches.nextItem();
        },
        nodes: function () {
            return (branches.current() || {}).nodes || [];
        },
        reset: function (idx = 0) {
            index = idx;
        },
        index: function () {
            return index;
        },
        data: function () {
            return data;
        },
        branch: function () {
            return branches.current();
        },
        getScenarioBranch: function (startBranch, solutionIterator) {
            const resultBranches = [startBranch];

            const startBranchLevel = startBranch.level;
            const startBranchLevelFirstMoveNumber = PgnHelper.getMoveNumber(startBranch.nodes[0]);
            let idx = startBranch.id + 1;
            let nextBranch = data[idx];

            while (nextBranch && nextBranch.level >= startBranchLevel) {
                const firstNode = nextBranch.nodes[0];
                const firstNodeMoveNumber = PgnHelper.getMoveNumber(firstNode);

                if (nextBranch.level === startBranchLevel && firstNodeMoveNumber !== startBranchLevelFirstMoveNumber) {
                    resultBranches.push(nextBranch);
                }

                ++idx;
                nextBranch = data[idx];
            }

            /** Cutting by solution*/
            const resultFilteredBranches = []
            const iterator = new NodeIterator(resultBranches);

            do {
                const node = iterator.current();

                if ((solutionIterator.data() || []).find((solution, idx) => {
                        return (
                            idx > solutionIterator.index()
                            && solution.variants.length
                            && getAttachedVariantByUid(PgnHelper.getUid(node), solution.variants)
                        );
                    })) {
                    break;
                }

                const branch = iterator.branch();
                const matchBranch = resultFilteredBranches.find(branch => branch.id === PgnHelper.getGroupId(node));

                if (matchBranch) {
                    matchBranch.nodes.push(node);
                } else {
                    const newBranch = cloneDeep(branch);
                    newBranch.nodes = [node];
                    resultFilteredBranches.push(newBranch);
                }
            } while (iterator.next())


            return resultFilteredBranches.length ? resultFilteredBranches : resultBranches;
        },
        foundScenario: function (fen, computerStopNode) {
            if (!computerStopNode) {
                return null;
            }

            const startBranch = data[PgnHelper.getGroupId(computerStopNode)];
            const startLevel = startBranch.level;
            let idx = startBranch.id + 1;
            let nextBranch = data[idx];
            const firstMoveNumberOfFirstNextBranch = nextBranch ? PgnHelper.getMoveNumber(nextBranch.nodes[0]) : null;

            while (nextBranch && nextBranch.level > startLevel) {
                const firstNode = nextBranch.nodes[0];
                const firstNodeMoveNumber = PgnHelper.getMoveNumber(firstNode);

                if (firstNodeMoveNumber === firstMoveNumberOfFirstNextBranch && isEqualFens(fen, PgnHelper.getFen(firstNode))) {
                    return nextBranch;
                }

                ++idx;
                nextBranch = data[idx];
            }

            return null;
        }
    }
}

export default PgnHelper;
