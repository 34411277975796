const ChessboardHelper = {
  gameStates: {
    inProgress: 'inProgress',
    inCheck: 'inCheck',
    inDraw: 'inDraw',
    whiteIsCheckmated: 'whiteIsCheckmated',
    blackIsCheckmated: 'blackIsCheckmated',
  },
  
  isValidMove(game, from, to) {
    const validMoves = game.moves({
      square: from,
      verbose: true
    });
    return validMoves.find(move => move.to === to) ? true : false;
  },

  areAllAimsTaken(fen) {
    fen = fen.toLowerCase();
    return !(fen.includes('a') || fen.includes('e')  || fen.includes('i'));
  },

  isAimPiece(piece) {
    return piece.type === 'a' || piece.type === 'e' || piece.type === 'i';
  },

  getGameState(game) {
    if(game.in_draw()) return this.gameStates.inDraw;
    if(game.in_checkmate()) {
      if (game.turn() === 'b') {
        return this.gameStates.blackIsCheckmated;
      } else {
        return this.gameStates.whiteIsCheckmated;
      }
    }
    if(game.in_check()) return this.gameStates.inCheck;
    return game.in_progress();
  },

  needsPromotion(game, from, to) {
    if(!this.isValidMove(game, from, to)) return false;
    const piece = game.get(from);
    const row = to[1];
    return piece && piece.type === game.PAWN
      && ((piece.color === game.WHITE && row === '8')
        || (piece.color === game.BLACK && row === '1')
      );
  },

  playSoundOnMove(sounds) {
    if (sounds && sounds.moveSound) this._playSound(sounds.moveSound);
  },

  playSoundsOnGameOver(game, sounds) {
    const state = this.getGameState(game);
    switch (state) {
      case this.gameStates.whiteIsCheckmated || this.gameStates.blackIsCheckmated:
        if (sounds && sounds.checkMateSound) this._playSound(sounds.checkMateSound);
        break;
      case this.gameStates.inDraw:
        if (sounds && sounds.inDrawSound) this._playSound(sounds.inDrawSound);
        break;
      default:
        break; 
    }
  },
  
  _playSound(sound) {
    const audio = new Audio(sound);
    audio.play();
  },
}

export default ChessboardHelper;