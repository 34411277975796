import React, { useState, useEffect } from 'react';

export default function useChessTimer ({whiteMaxTime, blackMaxTime, whiteAddTime, blackAddTime, timerPauseProp, game, onTimeOver, onTimerTick }) {
  const [timerPause, setTimerPause] = useState(timerPauseProp);
  useEffect(() => {
    setTimerPause(timerPauseProp);
  }, [timerPauseProp]);

  const [whiteTime, setWhiteTime] = useState(whiteMaxTime);
  const [blackTime, setBlackTime] = useState(blackMaxTime);

  const addTimeOnMove = () => {
    game.turn() === game.BLACK 
    ? setWhiteTime(whiteTime + whiteAddTime)
    : setBlackTime(blackTime + blackAddTime);
  }

  useEffect(() => {
    const timerInterval = setInterval(() => {
      if(timerPause) return;

      if (game.turn() === game.BLACK) {
        if (blackTime > 0) {
          setBlackTime(blackTime - 1);
        } else {
          onTimeOver(game.turn());
          clearInterval(timerInterval);
        }
      } else {
        if (whiteTime > 0) {
          setWhiteTime(whiteTime - 1);
        } else {
          onTimeOver(game.turn());
          clearInterval(timerInterval);
        }
      }
      onTimerTick();
    }, 1000);

    return () => { clearInterval(timerInterval); };
  }, [whiteTime, blackTime]);

  return [whiteTime, blackTime, setTimerPause, addTimeOnMove];
}