import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'
import SolutionForm from "../forms/SolutionForm";

const COLORS_MAP = {
    lose: 'salmon',
    win: 'green',
    draw: 'yellow'
}

export default function SmartItem(props) {
    const {
        solutions = [],
        number,
        onSolutionSelect = (solutionId, sol) => {

        },
        onItemDelete = itemId => {

        },
        onAdd = (num) => {

        },
        selectedId,
        onSave = updSol => {

        },
        loading = false
    } = props;

    let hasSelected = ((solutions.filter(sol => (sol.id == selectedId))).length > 0);
    let selectedSolution = hasSelected == false ? undefined : (solutions.filter(sol => (sol.id == selectedId)))[0];
    if (selectedSolution == undefined && solutions.length > 0) {
        selectedSolution = solutions[0];
    }

    return (
        <Wrapper>

            {selectedSolution == undefined ? null :
                <SelectedPlaceholder>
                    <div key={`${selectedId == undefined ? '' : selectedId}_dsd_f`}>
                        <SolutionForm {...selectedSolution}
                                      loading={loading}
                                      canDelete={true}
                                      onDelete={() => {
                                          onItemDelete(selectedId);
                                      }}
                                      onSave={updSol => {
                                          console.log('onSave: updSol = ', updSol);
                                          onSave(updSol);
                                      }}
                        />
                    </div>
                </SelectedPlaceholder>
            }

            <TabsPlaceholder>
                {solutions.map((sol, k) => {
                    let isSelected = (sol.id == selectedId);
                    return (
                        <TabItem key={sol.id} selected={isSelected} onClick={() => {
                            onSolutionSelect(sol.id, sol);
                        }}>
                            <StatusLine color={COLORS_MAP[sol.type]} >
                            </StatusLine>
                            {+k + 1}
                        </TabItem>
                    );
                })}
                <TabItem onClick={() => {
                    onAdd(number);
                }}>
                    +
                </TabItem>
            </TabsPlaceholder>

        </Wrapper>
    );
}

const bottomHeight = 30;

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
`;

const SelectedPlaceholder = styled.div`
    height: calc(100% - ${bottomHeight}px);
    width: 100%; 
    background: whitesmoke;
    padding-left: 5px;
    padding-right: 5px;
`;

const TabsPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
    height: ${bottomHeight}px;
    width: 100%;
`;

const TabItem = styled.div`
    border: 1px solid whitesmoke;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    background: ${props => (props.selected == true ? '#293742' : 'white')};
    color: ${props => (props.selected == true ? 'white' : 'black')};
    cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
    flex: 1;
    :hover{
        opacity: ${props => (props.selected == true ? 1 : 0.7)};  
    }
`;

const StatusLine = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 3px;
    background-color: ${props => props.color};
    
`;
