import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const GamesAPI = {

    getAllGames() {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/games/all`).then(d => d.data).then(exercises => {
                if (exercises.error != undefined) {
                    return reject(exercises.error);
                }
                resolve(exercises);
            }).catch(err => reject(err));
        });
    },

    getGame(id) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/game/${id}`).then(d => d.data).then(game => {
                if (game.error != undefined) {
                    return reject(game.error);
                }
                resolve(game);
            }).catch(err => reject(err));
        });
    },

    createGame(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/games/create`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    updateGame(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/games/update`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    deleteGame(id) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/games/delete/${id}`).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

}

export default GamesAPI;
