import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import Select from 'react-select'
import CommonHelper from "../../../helpers/CommonHelper";

import {BlueButton} from "../../ira/ui/Buttons";

export default function UpdatePairTool(props) {
    const {
        users = [], blackUserId: bUserId, whiteUserId: wUserId, onAdd = d => {

        }
    } = props;
    const [blackUserId, setBlackUserId] = useState(bUserId);
    const [whiteUserId, setWhiteUserId] = useState(wUserId);

    const options = users.map(a => ({value: a.id, label: CommonHelper.getUserName(a)}));

    let canSave = (blackUserId != undefined && whiteUserId != undefined);

    return (
        <Wrapper>

            <Field>
                <Label>
                    За белых
                </Label>
                <Select options={options}
                        placeholder={'Выберите ученика'}
                        value={options.filter(a => (a.value == whiteUserId))[0]}
                        onChange={a => {
                            setWhiteUserId(a.value);
                        }}
                />
            </Field>

            <Field>
                <Label>
                    За черных
                </Label>
                <Select options={options}
                        placeholder={'Выберите ученика'}
                        value={options.filter(a => (a.value == blackUserId))[0]}
                        onChange={a => {
                            setBlackUserId(a.value);
                        }}
                />
            </Field>

            <Field>
                <Label style={{opacity: 0}}>
                    {'_'}
                </Label>
                <BlueButton disabled={!canSave}
                            onClick={() => {
                                if (canSave == false) {
                                    return;
                                }
                                onAdd({
                                    whiteUserId: whiteUserId,
                                    blackUserId: blackUserId
                                });
                            }}>
                    Добавить
                </BlueButton>
            </Field>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
`;

const Field = styled.div`
    padding-left: 5px;
    padding-right: 5px;
    flex: 1;
`;

const Label = styled.div`
    font-weight: bold;
    margin-bottom: 5px;
`;
