import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import {Input} from "../../ira/ui/Inputs";
import {BlueButton} from "../../ira/ui/Buttons";
import {translate} from "../../../utils/Translate";

export default function UpdateBaseRatingForm(props) {
    const {
        baseRating,
        loading = false,
        onSave = s => {

        }
    } = props;

    const [text, setText] = useState(props.baseRating == undefined ? '1000' : `${props.baseRating}`);

    return (
        <Wrapper>
            <Field>
                <Label>
                    Начальный рейтинг
                </Label>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <Input value={text} onChange={evt => {
                        setText(evt.target.value);
                    }}/>
                    <BlueButton style={{marginLeft: 20}} onClick={() => {
                        onSave(text);
                    }}>
                        <Spin visible={loading}/>
                        {loading == true ? null :
                            translate('save')
                        }

                    </BlueButton>
                </div>
            </Field>

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const Field = styled.div`

`;

const Label = styled.div`

`;

const timeout = ms => new Promise(res => setTimeout(res, ms));