const JivoAPI = {


    showHelp() {
        try {
            window.sabir_jivo_open = 1;
            document.getElementById('jvlabelWrap').style.display = 'block';
            window.jivo_api.open();
        } catch (exc) {

        }
    },

    hideHelp() {
        try {
            window.sabir_jivo_open = 0;
            window.jivo_api.close();
            setTimeout(() => {
                document.getElementById('jvlabelWrap').style.display = 'none';
            }, 200);
        } catch (exc) {

        }
    },

    toggleHelp() {
        if (window.sabir_jivo_open == 1) {
            this.hideHelp();
        } else {
            this.showHelp();
        }
    }

}

export default JivoAPI;
