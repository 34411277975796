import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import SimpleUploadPanel from "../upload/SimpleUploadPanel";
import MonthCalendar from "../calendars/MonthCalendar";
import TimePicker from "../ui/TimePicker";
import DateTimePicker from "../ui/DateTimePicker";
import TestElloPanel from "../elo/TestElloPanel";
import AllExercisesGroupsPanel from "../exercises_groups/panels/AllExercisesGroupsPanel";
import InlinePiece from "../chess/tools/InlinePiece";
import InlineMove from "../chess/tools/InlineMove";
import SabirChessBoard from "../chessboard/tools/SabirChessBoard";
import PlayableSabirChessBoard from "../chessboard/tools/PlayableSabirChessBoard";
import PlayExerciseTool from "../exercises/tools/PlayExerciseTool";
import {AppToaster} from "../toaster/AppToaster";

import {Button} from '@blueprintjs/core'
import Chess from "chess.js";

import axios from 'axios'
import {API_ENDPOINT} from "../../constants/config";
import JitsiPanel from "../jitsi/panels/JitsiPanel";
import RealtimeBoard from "../realtime/board/RealtimeBoard";
import SimpleIllegalBoard from "../chessboard/tools/SimpleIllegalBoard";
import FideInfoTool from "../fide/tools/FideInfoTool";
import SimpleBoardViewTool from "../settings/board/tools/SimpleBoardViewTool";
import UpdateBoardForm from "../settings/board/forms/UpdateBoardForm";
import AdminUpdateBoardsPanel from "../settings/board/panels/AdminUpdateBoardsPanel";
import RoomChatPanel from "../chat/panels/RoomChatPanel";
import CommutePanel from "../commute/panels/CommutePanel";
import AwPanel from "../draw/panels/AwPanel";
import SimpleInteractiveCommutePanel from "../commute/panels/SimpleInteractiveCommutePanel";
import DraggableRealtimeBoardWidget from "../realtime/board/DraggableRealtimeBoardWidget";
import SimpleFenEditor from "../realtime/tools/SimpleFenEditor";
import OpenviduRoomPanel from "../openvidu/panels/OpenviduRoomPanel";

import GridLayout from 'react-grid-layout';

import WebRtcTestComponent from '../webrtc/checker'

import Chessboard from '../rubius/chessboard/Chessboard'
import IraTeacherTemplate from "../templates/IraTeacherTemplate";
import {Exercise} from "../rubius";

import {GreenButton, PurpleButton} from "../ira/ui/Buttons";
import SmartSelect from "../ira/ui/SmartSelect";
import WeekCalendar from "../ira/calendars/WeekCalendar";
import SmartExercisesSelectorPanel from "../smart_exercises/panels/SmartExercisesSelectorPanel";
import WarmupEditorTool from "../warmup/tools/WarmupEditorTool";
import BoardWithArrowsExampleTool from "../arrows/tools/BoardWithArrowsExampleTool";
import UpdateArrowsAndSquaresTool from "../arrows/tools/UpdateArrowsAndSquaresTool";


export default function DevApp() {
    const [pieces, setPieces] = useState([]);
    const [t, setT] = useState(undefined);

    const areaRef = useRef(undefined);

    const layout = [
        {i: 'a', x: 0, y: 0, w: 1, h: 2, static: true},
        {i: 'b', x: 1, y: 0, w: 3, h: 2, minW: 2, maxW: 4},
        {i: 'c', x: 4, y: 0, w: 1, h: 2}
    ];

    return (
        <Wrapper>

            {/*<iframe*/}
            {/*    width={1200}*/}
            {/*    height={800}*/}
            {/*    src={'https://mskchess.ru/eoftRGQH'}*/}
            {/*/>*/}

            {/*<iframe src="https://lichess.org/embed/FGZS2Hyd#6?theme=auto&bg=auto"*/}
            {/*        width={600} height={397} frameborder={0}></iframe>*/}

            {/*<div style={{width: 500, height: 500}}>*/}
            {/*    <BoardWithArrowsExampleTool/>*/}
            {/*</div>*/}


            {/*<div>*/}
            {/*<WarmupEditorTool/>*/}
            {/*</div>*/}

            {/*<PurpleButton>*/}
            {/*Hello there!*/}
            {/*</PurpleButton>*/}

            {/*<div style={{width: 920, height: 700}} >*/}
            {/*<WeekCalendar/>*/}
            {/*</div>*/}

            {/*<SmartSelect/>*/}

            {/*<IraTeacherTemplate>*/}

            {/*<div style={{width: '100%', height: window.innerHeight - 120}}>*/}
            {/*<Exercise*/}
            {/*onSave={d => {*/}
            {/*console.log('onSave: d = ', d);*/}
            {/*}}*/}
            {/*onSaveInfo={a => {*/}
            {/*console.log('onSaveInfo: a = ', a);*/}
            {/*}}*/}
            {/*onSaveSolution={a => {*/}
            {/*console.log('onSaveSolution: a = ', a);*/}
            {/*}}*/}
            {/*/>*/}
            {/*</div>*/}

            {/*</IraTeacherTemplate>*/}

            {/*<Chessboard/>*/}
            {/*<Chessboard/>*/}

            {/*<WebRtcTestComponent/>*/}

            {/*<div style={{width: '70vw', height: '70vh', backgroundColor: 'pink'}}>*/}
            {/*<GridLayout className="layout" layout={layout} cols={12} rowHeight={30} width={1200}>*/}
            {/*<div key="a">*/}
            {/*a*/}
            {/*</div>*/}
            {/*<div key="b">b</div>*/}
            {/*<div key="c">c</div>*/}
            {/*</GridLayout>*/}
            {/*</div>*/}


            {/*<DraggableRealtimeBoardWidget/>*/}
            {/*<SimpleFenEditor/>*/}
            {/*<OpenviduRoomPanel/>*/}

            {/*<CommutePlaceholder>*/}
            {/*/!*<CommutePanel roomId={'sabir'} />*!/*/}
            {/*<SimpleInteractiveCommutePanel roomId={'sabir'}  />*/}
            {/*</CommutePlaceholder>*/}

            {/*<div style={{border: '1px solid grey', height: '50vh', width: 500}}>*/}
            {/*<RoomChatPanel roomId={'test_room2'}/>*/}
            {/*</div>*/}


            {/*<UpdateBoardForm/>*/}
            {/*<AdminUpdateBoardsPanel/>*/}

            {/*<FideInfoTool playerId={'4162722'} />*/}

            {/*<div style={{width: 500, height: 500, backgroundColor: 'whitesmoke'}}>*/}
            {/*<RealtimeBoard/>*/}
            {/*</div>*/}

            {/*<div style={{width: 400, height: 400}}>*/}
            {/*<JitsiPanel canShare={true} />*/}
            {/*</div>*/}


            {/*<div style={{width: 240, height: 300}} >*/}
            {/*<DateTimePicker timestamp={t} onChange={setT} />*/}
            {/*</div>*/}

            {/*<div style={{backgroundColor: 'white', height: 400, width: 720, margin: '0 auto', marginTop: 10}} >*/}
            {/*<MonthCalendar/>*/}
            {/*</div>*/}

            {/*<TestElloPanel/>*/}

            {/*<AllExercisesGroupsPanel/>*/}

            {/*<div style={{width: 40, height: 40, backgroundColor: 'pink'}} onClick={() => {*/}
            {/*AppToaster.show({message: 'Добавлена новая задача!'});*/}
            {/*}}>*/}
            {/*/!*<InlinePiece piece={'K'} />*!/*/}
            {/*test*/}
            {/*</div>*/}

            {/*<textarea ref={areaRef} style={{width: '70vw', height: '70vh'}}>*/}
            {/*</textarea>*/}

            {/*<Button onClick={async () => {*/}
            {/*let pgnString = areaRef.current.value;*/}
            {/*// console.log('val = ', pgnString);*/}
            {/*// let chess = new Chess();*/}
            {/*// chess.load_pgn(pgnString);*/}
            {/*// console.log('moves = ', chess.moves({verbose: true}));*/}
            {/*// axios.post(`${API_ENDPOINT}/pgn/parse`, {*/}
            {/*//     content: pgnString*/}
            {/*// }).then(d => d.data).then(pld => {*/}
            {/*//     console.log();*/}
            {/*// })*/}
            {/*let result = (await axios.post(`${API_ENDPOINT}/pgn/parse`, {content: pgnString})).data;*/}
            {/*console.log('result = ', result);*/}

            {/*}}>*/}
            {/*test*/}
            {/*</Button>*/}


            {/*<InlineMove move={'Rg4'}/>*/}

            {/*<InlineMove move={'fxg6'}/>*/}

            {/*<div style={{width: 500, height: 500}} >*/}
            {/*<SabirChessBoard/>*/}
            {/*</div>*/}


            {/*<div style={{width: 500, height: 500}}>*/}
            {/*<PlayableSabirChessBoard*/}
            {/*boardId={'board0'} canMakeMoves={true}*/}
            {/*allowIllegal={true}*/}
            {/*canViewHistory={true} />*/}
            {/*</div>*/}

            {/*<div style={{width: 500, height: 500}}>*/}
            {/*<SimpleIllegalBoard*/}
            {/*boardId={'board1'} canMakeMoves={true}*/}
            {/*allowIllegal={true}*/}
            {/*canViewHistory={true} />*/}
            {/*</div>*/}


            {/*<div style={{width: 920, margin: '0 auto', backgroundColor: 'yellow'}}>*/}
            {/*<PlayExerciseTool boardId={'board1'} />*/}
            {/*</div>*/}


        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const CommutePlaceholder = styled.div`
  width: 100vw;
  height: 100vh;
`;
