import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import {Input} from "../../ui/Input";
import {Button} from "../../ui/Button";
import CommonHelper from "../../../helpers/CommonHelper";
import {API_ENDPOINT} from "../../../constants/config";
import PaymentTool from "../tools/PaymentTool";


export default function UserBalancePanel(props) {
    const {currentUser, userId} = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        return {
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            userId: state.users.currentUserId
        }
    }, []));
    const [modalVisible, setModalVisible] = useState(false);
    const [balance, setBalance] = useState(0);

    useEffect(() => {
        axios.get(`${API_ENDPOINT}/user/${userId}/balance`).then(d => d.data).then(pld => {
            setBalance(pld.balance);
        })
    }, [userId]);

    return (
        <Wrapper>
            <BalanceSpan onClick={() => {
                setModalVisible(true);
            }}>
                {`${balance} руб.`}
            </BalanceSpan>
            <Sidebar width={Math.min(720, window.innerWidth)} visible={modalVisible} onCloserClick={() => {
                setModalVisible(false);
            }}>

                {currentUser == undefined ? null :
                    <PaymentTool userId={userId} email={currentUser.email} />
                }

            </Sidebar>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const BalanceSpan = styled.div`
    opacity: 0.5;
    text-align: center;
    color: black;
    padding: 10px;
    cursor: pointer;
    :hover{
      opacity: 1;
    }
`;
