import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import {Map, Set} from 'immutable';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import * as actions from "../../../redux/actions/LessonsActions";
import {DARK_SIDE} from "../../ui/Colors";

import Sidebar from 'arui-feather/sidebar'
import {Button} from "../../ui/Button";
import UpdateLessonForm from "../forms/UpdateLessonForm";

import Tabs from '../../ui/Tabs'
import LessonsCalendar from "../tools/LessonsCalendar";
import LessonAttendancePanel from "./LessonAttendancePanel";
import CommonHelper from "../../../helpers/CommonHelper";
import LessonTasksPanel from "./LessonTasksPanel";
import LessonUnitsPanel from "./LessonUnitsPanel";
import UserExercisePanel from "../../exercises/panels/UserExercisePanel";
import ManyExercisesPlayPanel from "../view/ManyExercisesPlayPanel";
import {translate} from "../../../utils/Translate";


const getItemById = (arr, id) => {
    let aArr = arr.filter(a => (a.id == id));
    if (aArr.length == 0) {
        return undefined;
    }
    return aArr[0];
}

const now = +new Date();

const renderLessons = ({lessons, setSelectedId, selectedId}) => {
    return (
        <LessonsList>
            {lessons.map((item, k) => {
                let {teacherId, startTimestamp, groupId, roomId, name} = item;
                return (
                    <LessonItem
                        selected={(selectedId == item.id)}
                        key={`${item.id}_${k}`}
                        onClick={() => {
                            setSelectedId(item.id);
                        }}>

                        <ItemInfo>

                            <ItemName>
                                {name}
                            </ItemName>

                            <DatePlaceholder>
                                {moment(startTimestamp).format('DD.MM.YYYY HH:mm')}
                            </DatePlaceholder>
                        </ItemInfo>

                    </LessonItem>
                )
            })}
        </LessonsList>
    )
}

export default function TeacherGroupLessonsPanel(props) {
    const {teacherGroupId, teacherId, canEdit = true} = props;
    let {currentUser, loading, lessons, groupStudents} = useMappedState(useCallback(state => {
        let lessons = state.lessons.lessonsMap.toArray().filter(u => ((u.teacherGroupId == teacherGroupId)));
        let group = state.teacherGroups.teacherGroupsMap.get(teacherGroupId);
        let studentsIds = (group == undefined || group.studentsIds == undefined) ? [] : group.studentsIds;
        let groupStudents = studentsIds.map(stId => state.users.usersMap.get(stId)).filter(a => (a != undefined));
        return {
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            loading: state.lessons.loading,
            lessons: lessons,
            currentUserIsTeacher: ('teacher' == CommonHelper.getCurrentUserRole(state)),
            groupStudents: groupStudents
        }
    }, [teacherGroupId, teacherId]));
    const [addModalVisible, setAddModalVisible] = useState(false);
    const [selectedId, setSelectedId] = useState(false);
    const dispatch = useDispatch();
    const selectedLesson = (selectedId == undefined) ? undefined : lessons.filter(g => (g.id == selectedId))[0];

    return (
        <Wrapper>

            <Tabs tabs={[
                {
                    label: 'В виде календаря',
                    content: (
                        <TabItem>
                            <LessonsCalendarPlaceholder>
                                <LessonsCalendar
                                    selectedId={selectedId}
                                    lessons={lessons} onLessonClick={(lId, l) => {
                                    setSelectedId(lId);
                                }}/>
                            </LessonsCalendarPlaceholder>
                        </TabItem>
                    )
                },

                {
                    label: 'Предстоящие занятия списком',
                    content: (
                        <TabItem>
                            {renderLessons({
                                lessons: lessons.filter(a => (+a.startTimestamp > +now)).sort((a, b) => (+a.startTimestamp - +b.startTimestamp)),
                                selectedId, setSelectedId
                            })}
                        </TabItem>
                    )
                },

                {
                    label: 'Все занятия списком',
                    content: (
                        <TabItem>
                            {renderLessons({
                                lessons: lessons.sort((a, b) => (+b.startTimestamp - +a.startTimestamp)),
                                selectedId,
                                setSelectedId
                            })}
                        </TabItem>
                    )
                }

            ]}/>


            {canEdit == false ? null :
                <BottomPlaceholder>
                    <Button onClick={() => {
                        setAddModalVisible(true);
                    }} style={{marginLeft: 0}}>
                        {translate('add_lesson')}
                    </Button>
                </BottomPlaceholder>
            }

            <Sidebar width={Math.min(840, window.innerWidth)} visible={addModalVisible} onCloserClick={() => {
                setAddModalVisible(false);
            }}>

                {addModalVisible == false ? null :
                    <div>
                        <Heading>
                            Создание занятия
                        </Heading>
                        <UpdateLessonForm

                            loading={loading}

                            shouldSelectTeacher={(teacherId == undefined)}
                            teacherId={teacherId}


                            onSave={d => {
                                d.teacherGroupId = teacherGroupId;
                                dispatch(actions.createLesson(d)).then(pld => {
                                    if (pld.error != undefined) {
                                        window.alert(JSON.stringify(pld.error));
                                    } else {
                                        setAddModalVisible(false);
                                    }
                                });
                            }}/>
                    </div>
                }

            </Sidebar>

            <Sidebar width={Math.min(window.innerWidth)} visible={(selectedLesson != undefined)}
                     onCloserClick={() => {
                         setSelectedId(undefined);
                     }}>

                {selectedLesson == undefined ? null :
                    <div>

                        <div style={{marginBottom: 10}} >
                            <div style={{cursor: 'pointer'}} onClick={() => {
                                CommonHelper.linkTo(`/online/${selectedLesson.id}`);
                            }}>
                                перейти к онлайн-уроку
                            </div>
                        </div>

                        {canEdit == true ?
                            <Tabs tabs={[
                                {
                                    label: 'Информация о занятии',
                                    content: (
                                        <TabItem>
                                            <UpdateLessonForm
                                                {...selectedLesson}
                                                loading={loading}
                                                onSave={async d => {
                                                    let pld = await dispatch(actions.updateLesson({id: selectedId, ...d}));
                                                    if (pld.error != undefined) {
                                                        window.alert(JSON.stringify(pld.error));
                                                    } else {
                                                        // setSelectedId(undefined);
                                                    }
                                                }}
                                                shouldSelectTeacher={(teacherId == undefined)}
                                                performanceContent={(
                                                    <>
                                                    {groupStudents.map((st, w) => {
                                                        return (
                                                            <StudentPerfItem key={`${st.id}_${w}`}>
                                                                {/*{JSON.stringify(st)}*/}
                                                                {/*UserExercisePanel*/}
                                                                <h3>
                                                                    {CommonHelper.getUserName(st)}
                                                                </h3>
                                                                <div style={{height: 650, marginBottom: 400}}>
                                                                    <ManyExercisesPlayPanel
                                                                        exercisesIds={selectedLesson.exercisesIds}
                                                                        userId={st.id}/>
                                                                </div>

                                                                {selectedLesson.exercisesIds.map((exId, q) => {
                                                                    return (
                                                                        <div key={`e_${q}_${exId}`} style={{
                                                                            minHeight: 400,
                                                                            height: 400,
                                                                            width: '100%'
                                                                        }}>
                                                                            <UserExercisePanel id={exId}
                                                                                               userId={st.id}/>
                                                                        </div>
                                                                    )
                                                                })}

                                                            </StudentPerfItem>
                                                        )
                                                    })}
                                                    </>
                                                )}
                                            />
                                        </TabItem>
                                    )
                                }
                            ]}/> :
                            <div>
                                <Tabs tabs={[
                                    {
                                        label: 'Задачи',
                                        content: (
                                            <TabItem>
                                                <LessonTasksPanel id={selectedLesson.id}/>
                                            </TabItem>
                                        )
                                    },
                                    {
                                        label: 'Модули',
                                        content: (
                                            <TabItem>
                                                <LessonUnitsPanel id={selectedLesson.id}/>
                                            </TabItem>
                                        )
                                    }
                                ]}/>

                            </div>
                        }


                    </div>
                }

            </Sidebar>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const LessonsPlaceholder = styled.div`
    
`;

const LessonsList = styled.div`
    
`;

const LessonItem = styled.div`
    background: ${props => (props.selected == true ? DARK_SIDE : 'white')};
    color: ${props => (props.selected == true ? 'white' : 'black')};
    padding: 10px;
    cursor: pointer;
    border-radius: 4px;
    margin-bottom: 10px;
    :hover{
      opacity: 0.7;
    }
`;

const Name = styled.div`
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
`;

const Description = styled.div`
    opacity: 0.7;
`;

const Heading = styled.div`
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 10px;
`;

const BottomPlaceholder = styled.div`
    margin-top: 10px;
`;

const DatePlaceholder = styled.div`
    
`;

const ItemInfo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const ItemName = styled.div`
    font-weight: bold;
`;

const ItemSub = styled.div`
    opacity: 0.8;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 12px;
`;

const B = styled.span`
    font-weight: bold;
    opacity: 0.7;
`;

const TabItem = styled.div`
    padding-top: 10px;
    width: 100%;
`;

const LessonsCalendarPlaceholder = styled.div`
    //height: 80vh;
    height: 500px;
    width: 100%;
    background: white;
    //padding: 5px;
    //box-sizing: border-box;
`;

const StudentPerfItem = styled.div`
    
`;
