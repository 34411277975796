import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import axios from 'axios'

export default function SmartSelect(props) {
    const {
        options = [
            {
                label: 'Опция 1',
                value: 'opt1',
                number: 2
            },
            {
                label: 'Опция 2',
                value: 'opt2',
                number: 15
            },
            {
                label: 'Опция 3',
                value: 'opt3',
                number: 44
            },
            {
                label: 'Опция 4',
                value: 'opt4',
                number: 2312
            }
        ],
        isMulti = false,
        closeOnSelect = true,
        placeholder = '',
        selectedValues = [],
        onSelect = a => {

        },
        hasRightNumbers = false
    } = props;
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const node = useRef();
    const handleClick = e => {
        if (node == undefined || node.current == undefined) {
            return;
        }
        if (node.current.contains(e.target)) {
            return;
        }
        setDropdownVisible(false);
    };
    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    let selectedOptions = (selectedValues == undefined) ? undefined : options.filter(a => (selectedValues.indexOf(a.value) > -1));

    return (
        <Wrapper ref={node}>
            <InputPlaceholder>
                <InputDiv selected={dropdownVisible}
                          expanded={dropdownVisible}
                          onClick={() => {
                              setDropdownVisible(!dropdownVisible);
                          }}>
                    <MonthName>
                        {(selectedOptions == undefined || selectedOptions.length == 0) ?
                            <PlaceholderSpan
                                expanded={dropdownVisible}>{placeholder}</PlaceholderSpan> : selectedOptions.map(a => a.label).join(', ')}
                    </MonthName>
                    <ArrowImage src={require('../../../assets/images/expand_more.svg')}
                                style={{transform: `rotate(${dropdownVisible == true ? 180 : 0}deg)`}}/>
                </InputDiv>
            </InputPlaceholder>
            {dropdownVisible == false ? null :
                <DropdownPlaceholder>

                    <ResultsPlaceholder wide={hasRightNumbers}>
                        {options.map((opt, k) => {
                            let isSelected = selectedOptions.map(a => a.value).indexOf(opt.value) > -1;
                            return (
                                <Item selected={isSelected}
                                      disabled={opt.disabled}
                                      key={`${k}_${opt.label}`}
                                      onClick={() => {
                                          if (opt.disabled == true) {
                                              return;
                                          }
                                          let newVals = selectedValues;
                                          if (isSelected) {
                                              newVals = selectedValues.filter(a => (a != opt.value));
                                          } else {
                                              if (isMulti) {
                                                  newVals = selectedValues.concat([opt.value]);
                                              } else {
                                                  newVals = [opt.value];
                                              }

                                          }
                                          console.log('onSelect: newVals = ', newVals);
                                          onSelect(newVals);
                                          if (closeOnSelect == true) {
                                              setDropdownVisible(false);
                                          }
                                      }}>
                                    <ItemName>
                                        {opt.label}
                                    </ItemName>

                                    {isSelected == false ? null :
                                        <SelectedCheck src={require('../../../assets/images/blue-check.svg')}/>
                                    }

                                    {(hasRightNumbers == false || opt.number == undefined) ? null :
                                        <ItemNumber>
                                            {opt.number}
                                        </ItemNumber>
                                    }
                                </Item>
                            )
                        })}
                    </ResultsPlaceholder>

                </DropdownPlaceholder>
            }
        </Wrapper>
    );
}

const PlaceholderSpan = styled.span`
    color: ${props => (props.expanded ? '#444' : 'rgba(31, 32, 65, 0.25)')};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

const SelectedCheck = styled.img`
    width: 20px;
    height: 20px;
`;

const Wrapper = styled.div`
    position: relative;
    display: inline-block;
    width: 100%;
`;

const InputPlaceholder = styled.div`
    height: 44px;
    box-sizing: border-box;
`;

const DropdownPlaceholder = styled.div`
    position: absolute;
    top: 44px;
    background: white;
    border: 1px solid #085BFF;
    z-index: 1000;
    left: 0px;
    right: 0px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    
`;

const InputDiv = styled.div`
    font-size: 14px;
    background: #FFFFFF;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    max-width: 100%;
    color: #444;
    padding-left: 10px;
    height: 44px;
    line-height: 21px;
    box-sizing: border-box;
    border-radius: 4px;
    
    border-bottom-right-radius: ${props => (props.expanded == true ? 0 : 4)}px;
    border-bottom-left-radius: ${props => (props.expanded == true ? 0 : 4)}px;
    border: 1px solid ${props => (props.red == true ? 'red' : (props.expanded == true ? '#085BFF' : '#8593A8'))};
    border-bottom-width: ${props => (props.expanded == true ? 0 : 1)}px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    :hover{
      opacity: 0.85;
    }
`;

const MonthName = styled.div`
    font-size: 14px;
    line-height: 24px;
    color: #1F2041;
`;


const ArrowImage = styled.img`
    width: 14px;
    height: 14px;
    margin-right: 8px;
    opacity: 0.5;
    transition-duration: 0.2s;
    margin-left: 8px;
`;

const Input = styled.input`
    box-sizing: border-box;
    width: 100%;
    font-size: 16px;
    border: 1px solid rgb(170, 170, 170);
    line-height: 16px;
    height: 26px;
    padding: 4px;
    outline: none;
`;

const VoidPlaceholder = styled.div`
    padding: 4px;
    line-height: 20px;
    font-size: 14px;
    margin-bottom: 4px;
`;

const InnerInputPlaceholder = styled.div`
    padding: 4px;
    box-sizing: border-box;
`;

const ResultsPlaceholder = styled.div`
    max-height: 200px;
    overflow-y: auto;
    width: 100%;
`;

const Item = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    box-sizing: border-box;
    height: 44px;
    padding: 6px;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 32px;
    background: ${props => (props.selected == true ? 'rgba(134,209,255,0.40)' : 'white')};
    color: ${props => (props.selected == true ? '#212529' : '#1F2041')};
    font-size: 14px;
    opacity: ${props => (props.disabled == true ? 0.4 : 1)};
    cursor: ${props => (props.disabled == true ? 'default' : 'pointer')};
    :hover{
      background-color: rgba(134,209,255,0.40);
    }
`;

const ItemName = styled.div`
    
`;

const ItemNumber = styled.div`
    color: #212121;
    background-color: #ebebeb;
    border-radius: 3px;
    padding: 2px 5px;
    font-weight: 700;
    font-size: 12px;
`;
