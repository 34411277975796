import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';
import {useDispatch, useMappedState} from 'redux-react-hook'

import HorChessTemplate from '../templates/HorChessTemplate'
import StudentPersonalPanel from "../students/panels/StudentPersonalPanel";
import IraUserLessonsPanel from "../lessons/panels/IraUserLessonsPanel";
import IraTeacherTemplate from "../templates/IraTeacherTemplate";
import CommonHelper from "../../helpers/CommonHelper";
import UserCopeLessonPanel from "../lessons/panels/UserCopeLessonPanel";
import TwoPlayersGamePanel from "../play/panels/TwoPlayersGamePanel";


export default function TwoPlayApp(props) {
    let {id} = props.match.params;

    const {currentUserId, currentUser} = useMappedState(useCallback(state => ({
        currentUserId: state.users.currentUserId,
        currentUser: state.users.usersMap.get(state.users.currentUserId)
    }), []));
    const dispatch = useDispatch();

    if (currentUser == undefined) {
        return null;
    }

    return (
        <Wrapper>

            <TwoPlayersGamePanel roomId={id}/>

        </Wrapper>
    );
}


const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    overflow: hidden;
`;


const topHeight = 70;

const HorHeading = styled.div`
    width: 100%;
    height: ${topHeight}px;
    background: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding-left: 40px;
    padding-right: 40px;
`;

