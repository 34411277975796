import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import {AW_API_KEY} from "../../../constants/config";

export default function AwPanel(props) {
    const {roomId = 'sabir'} = props;
    const divId = `aw_${roomId}`
    const awRef = useRef();

    useEffect(() => {
        awRef.current = new window.AwwBoard(`#${divId}`, {
            apiKey: AW_API_KEY,
            multiPage: true,
            autojoin: true,
            showUserPointers: true,
            boardLink: divId,
            sizes: [3, 5, 8, 13, 20],
            fontSizes: [10, 12, 16, 22, 30],
            menuOrder: ['colors', 'sizes', 'tools'],
            tools: ['pencil', 'eraser', 'text', 'image'],
            colors: ["#000000", "#f44336", "#4caf50", "#2196f3",
                "#ffc107", "#9c27b0", "#e91e63", "#795548"],
            defaultColor: "#000000",
            defaultSize: 8,
            defaultTool: 'pencil',
        });
        return () => {
            try {
                awRef.current.leaveBoard();
            } catch (e) {

            }
        }
    }, [roomId]);

    return (
        <Wrapper id={divId}>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background: whitesmoke;
`;
