import React, {Component, Fragment} from 'react';
// import Microphone from './Microphone/microphone';
import Microphone from './Microphone/Microphone';

import styled from 'styled-components'

// import 'milligram/dist/milligram.css';
import Camera from './Camera/Camera';
import Error from './Error/Error';
// import Audio from './Audio/Audio';

export default class WebRtcTestComponent extends Component {
    state = {
        permissionMic: true,
        permissionCam: true,
        kindId: null
    };

    componentDidMount() {
        navigator.mediaDevices
            .getUserMedia({
                audio: false,
                video: true
            })
            .catch(() => {
                this.setState({
                    permissionCam: false
                });
            });
        navigator.mediaDevices
            .getUserMedia({
                audio: true,
                video: false
            })
            .catch(() => {
                this.setState({
                    permissionMic: false
                });
            });
    }

    changeKindId(kindId) {
        this.setState({kindId});
    }

    render() {
        return (
            <Wrapper>
                <Container>
                    <Half>
                        {this.state.permissionMic ? (
                            <Microphone changeKindId={kindId => this.changeKindId(kindId)}/>
                        ) : (
                            <Error
                                text={'К сожалению, мы не смогли обнаружить ваш микрофон'}
                            />
                        )}
                    </Half>
                    <Half>
                        {this.state.permissionCam ? (
                            <Camera/>
                        ) : (
                            <Error
                                text={'К сожалению, мы не смогли обнаружить вашу камеру'}
                            />
                        )}
                    </Half>
                </Container>
                {/*<div className={`container ${styles.app} ${styles.topAudio}`}>*/}
                {/*<div className={`row`}>*/}
                {/*{this.state.kindId && <Audio kindId={this.state.kindId} />}*/}
                {/*</div>*/}
                {/*</div>*/}
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
    
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    
`;

const Half = styled.div`
    flex: 1;
    margin: 5px;
`;
