import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';

import {Code} from 'react-content-loader'

import SlotsAPI from '../../../api/SlotsAPI'
import UpdateSlotsForm from "../forms/UpdateSlotsForm";

export default function UserSlotsPanel(props) {
    const {userId} = props;
    const [slots, setSlots] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        SlotsAPI.getUserSlots(userId).then(arr => {
            setSlots(arr);
            setLoading(false);
        })
    }, [userId]);

    if (slots.length == 0 && loading == true) {
        return (
            <Code/>
        )
    }

    return (
        <Wrapper>
            {/*{JSON.stringify(slots)}*/}
            <UpdateSlotsForm slots={slots} onChange={async newSlots => {
                await SlotsAPI.updateUserSlots(userId, newSlots);
                let updSlots = await SlotsAPI.getUserSlots(userId);
                setSlots(updSlots);
            }}/>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;
