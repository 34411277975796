import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import HorChessTemplate from "../templates/HorChessTemplate";
import {useDispatch, useMappedState} from "redux-react-hook";

import TeacherGroupPanel from "../teacher_groups/panels/TeacherGroupPanel";

import Tabs, {TabItem} from "../ui/Tabs";
import SimpleInteractiveCommutePanel from "../commute/panels/SimpleInteractiveCommutePanel";
import LessonCommutePanel from "../commute/panels/LessonCommutePanel";

export default function OnlineLessonApp(props) {
    let {id} = props.match.params;

    return (
        <Wrapper>
            <LessonCommutePanel id={id}/>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;  
`;
