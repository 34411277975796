import React, { useState, useEffect, useRef, useCallback, useReducer, useMemo } from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import {Button} from "../../ui/Button";
import {Textarea} from "../../ui/Textarea";


import Tabs from '../../ui/Tabs'
import SmartPgnViewer from "../../chess/view/smart/SmartPgnViewer";
import {translate} from "../../../utils/Translate";

export default function SimplePgnEditor(props) {
    const [pgn, setPgn] = useState(props.pgn == undefined ? '' : props.pgn);
    const {
        onSubmit = () => {

        }
    } = props;

    return (
        <Wrapper>

            <TabsPlaceholder>
                <Tabs tabs={[
                    {
                        label: 'Редактирование',
                        content: (
                            <TabItem>
                            <Textarea value={pgn} onChange={evt => {
                                setPgn(evt.target.value);
                            }} placeholder={'PGN'} />
                            </TabItem>
                        )
                    },
                    {
                        label: 'Превью',
                        content: (
                            <TabItem>
                                <SmartPgnViewer pgnString={pgn} />
                            </TabItem>
                        )
                    }
                ]} />
            </TabsPlaceholder>

            <BottomPlaceholder>
                <Button onClick={() => {
                    onSubmit(pgn);
                }} >
                    {translate('Save')}
                </Button>
            </BottomPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const TabItem = styled.div`
    width: 100%;
    padding-top: 5px;
`;

const TabsPlaceholder = styled.div`
    
`;

const BottomPlaceholder = styled.div`
    
`;
