import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'

import {Button} from "../../ui/Button";
import AddByEmailForm from "../forms/AddByEmailForm";

import * as actions from '../../../redux/actions/UsersActions'
import * as teacherGroupsActions from '../../../redux/actions/TeacherGroupsActions'
import CommonHelper from "../../../helpers/CommonHelper";

import Spin from 'arui-feather/spin'

import {RedButton} from "../../ira/ui/Buttons";
import NiceModal from "../../modals/NiceModal";
import NiceConfirm from "../../modals/NiceConfirm";
import NiceModalWithHeading from "../../modals/NiceModalWithHeading";

import {BlueButton} from "../../ira/ui/Buttons";
import {Input, Label} from "../../ira/ui/Inputs";
import SimpleUserView from "../../ira/ui/user/SimpleUserView";
import SmartSelect from "../../ira/ui/SmartSelect";
import UserRatingPanel from "../../ratings/panels/UserRatingPanel";
import ExerciseTagsUserRatingPanel from "../../ratings/panels/ExerciseTagsUserRatingPanel";
import {translate} from "../../../utils/Translate";

export default function TeacherStudentsPanel(props) {
    let {teacherId} = props;
    const dispatch = useDispatch();
    const [newUserEmail, setNewUserEmail] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [deleteId, setDeleteId] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [selGroupUserId, setSelGroupUserId] = useState(undefined);
    const [selectedGroupId, setSelectedGroupId] = useState(undefined);
    const [ratingUserId, setRatingUserId] = useState(undefined);

    const {
        student, currentUser, currentUserId, links,
        getStudentGroups,
        loading, teacher, students, teaId, teacherGroups, getGroupById

    } = useMappedState(useCallback(state => {
        let currentUserId = state.users.currentUserId;
        let tId = (teacherId == undefined) ? currentUserId : teacherId;
        let links = state.users.linksMap.toArray().filter(a => (a.teacherId == tId));
        let students = links.map(l => state.users.usersMap.get(l.studentId)).filter(a => (a != undefined));
        let teacherGroups = state.teacherGroups
            .teacherGroupsMap.toArray()
            .filter(g => (g.teacherId == tId))
            .filter(a => (a != undefined && a.deleted != true))
            .sort((a, b) => (+b.timestamp - +a.timestamp));
        return {
            teacher: state.users.usersMap.get(tId),
            students: students,
            currentUser: state.users.usersMap.get(currentUserId),
            currentUserId: currentUserId,
            loading: state.users.loading || state.teacherGroups.loading,
            teaId: tId,
            links: links,
            teacherGroups: teacherGroups,
            getGroupById: grId => (teacherGroups.filter(a => (a.id == grId))[0]),
            getStudentGroups: stId => (teacherGroups.filter(a => ((a.studentsIds != undefined) && (a.studentsIds.indexOf(stId) > -1))))
        }
    }, [teacherId]));

    useEffect(() => {
        dispatch(actions.loadTeacherLinks(teaId)).then(pld => {
            if (pld.links != undefined) {
                dispatch(actions.loadUsersByIds(pld.links.map(a => a.studentId)))
            }
        });
        if (teaId != undefined) {
            dispatch(teacherGroupsActions.loadAllTeacherGroupsOfTeacher(teaId))
        }
    }, [teaId]);

    if (students.length == 0 && loading == true) {
        return (
            <Code/>
        )
    }

    if (teacher == undefined) {
        return null;
    }

    const deleteStudent = (deleteId == undefined) ? undefined : students.filter(a => (a.id == deleteId))[0];
    const deleteLink = (deleteStudent == undefined) ? undefined : links.filter(a => (a.studentId == deleteStudent.id))[0];

    let ratingStudent = (ratingUserId == undefined) ? undefined : students.filter(a => (a.id == ratingUserId))[0];

    let selGrStudent = (selGroupUserId == undefined) ? undefined : students.filter(a => (a.id == selGroupUserId))[0];
    let groupsSelectorOptions = teacherGroups.map(a => ({value: a.id, label: a.name}));

    let selectedGrStudentGroups = getStudentGroups(selGroupUserId);
    let selectedGrStudentGroupsIds = selectedGrStudentGroups.map(a => a.id);


    return (
        <Wrapper>

            <TopPlaceholder>
                <TopHeading>
                    {translate('all_students')}
                </TopHeading>

                <TopRightPlaceholder>
                    <RedButton
                        style={{marginLeft: 0}}
                        onClick={() => {
                            setModalVisible(true);
                        }}>
                        {translate('add_student')}
                    </RedButton>
                </TopRightPlaceholder>

            </TopPlaceholder>

            <UsersPlaceholder>
                <TopTableHeading>
                    <NameCol>
                        {translate('students')}
                    </NameCol>
                    <EmailCol>
                        {translate('email')}
                    </EmailCol>
                    <EmailCol>
                        {translate('rating')}
                    </EmailCol>
                    <OptionsCol>
                        {translate('options')}
                    </OptionsCol>
                </TopTableHeading>

                {students.map((st, k) => {
                    return (
                        <StudentRow key={`${st.id}_${k}`}>
                            <NameCol>
                                <UserAvatar src={st.avatar}/>
                                <UserNameSpan>
                                    {CommonHelper.getUserName(st)}
                                </UserNameSpan>
                            </NameCol>
                            <EmailCol>
                                {st.email}
                            </EmailCol>

                            <EmailCol style={{cursor: 'pointer'}} onClick={() => {
                                setRatingUserId(st.id);
                            }}>
                                {st.lastRating}
                            </EmailCol>

                            <OptionsCol>
                                <TrashImg
                                    src={require('../../../assets/images/trash-2.svg')}
                                    onClick={() => {
                                        setDeleteId(st.id);
                                    }}
                                    style={{marginRight: 25}}
                                />
                                <TrashImg
                                    src={require('../../../assets/images/user-check.svg')}
                                    onClick={() => {
                                        setSelGroupUserId(st.id);
                                    }}
                                />
                            </OptionsCol>
                        </StudentRow>
                    )
                })}

            </UsersPlaceholder>

            {deleteLink == undefined ? null :
                <NiceConfirm
                    heading={'Удалить ученика'}
                    subHeading={`${CommonHelper.getUserName(deleteStudent)} будет удален из списка Ваших учеников. Вы уверены?`}
                    loading={loading}
                    onCancel={() => {
                        setDeleteId(undefined);
                    }}
                    onConfirm={async () => {
                        await dispatch(actions.deleteStudentTeacherLink(deleteLink.id));
                        setDeleteId(undefined);
                    }}
                    confirmText={translate('delete')}
                />
            }

            {modalVisible == false ? null :
                <NiceModalWithHeading
                    heading={translate('add_student')}
                    subHeading={'Введите Email ученика'}
                    onClose={() => {
                        setModalVisible(false);
                    }}>

                    <div>

                        <Label>
                            Email
                        </Label>

                        <Input value={newUserEmail}
                               placeholder={'Введите почту ученика'}
                               onChange={evt => {
                                   setNewUserEmail(evt.target.value);
                               }}/>

                        <CenterFlex style={{marginTop: 23}}>
                            <BlueButton disabled={loading} onClick={async () => {
                                let em = newUserEmail.replace(/ /g, '');
                                let pld = await dispatch(actions.createStudentTeacherLinkByStudentEmail(teaId, em));
                                if (pld.error != undefined) {
                                    setErrorMessage(pld.error.message);
                                } else {
                                    setNewUserEmail('');
                                }
                                setModalVisible(false);
                            }}>
                                <Spin visible={loading} theme={'alfa-on-color'}/>
                                {loading == true ? null : 'ДОБАВИТЬ УЧЕНИКА'}
                            </BlueButton>
                        </CenterFlex>

                    </div>

                </NiceModalWithHeading>
            }

            {errorMessage == undefined ? null :
                <NiceModalWithHeading subHeading={''} heading={errorMessage} onClose={() => {
                    setErrorMessage(undefined);
                }}>

                    <BlueButton onClick={() => {
                        setErrorMessage(undefined);
                    }}>
                        OK
                    </BlueButton>

                </NiceModalWithHeading>
            }

            {ratingStudent == undefined ? null :
                <NiceModal
                    onClose={() => {
                        setRatingUserId(undefined);
                    }}
                >
                    <div style={{width: 840}}>
                        {/*<UserRatingPanel id={ratingStudent.id}/>*/}
                        <ExerciseTagsUserRatingPanel id={ratingStudent.id}/>
                    </div>

                </NiceModal>
            }


            {selGrStudent == undefined ? null :
                <NiceModalWithHeading subHeading={''} heading={translate('add_student_to_group')} onClose={() => {
                    setSelGroupUserId(undefined);
                }}>

                    <SgUserPlaceholder>
                        <SimpleUserView user={selGrStudent}/>
                    </SgUserPlaceholder>

                    <SgUsersDropdownPlaceholder>

                        <Label>
                            {translate('groups_list')}
                        </Label>

                        <SmartSelect options={groupsSelectorOptions}
                                     placeholder={translate('select_group')}
                                     isMulti={true}
                                     closeOnSelect={false}
                                     selectedValues={selectedGrStudentGroups.map(a => a.id)}
                                     onSelect={async a => {
                                         let groupsToAddTo = a.filter(pretGrId => (selectedGrStudentGroupsIds.indexOf(pretGrId) == -1)).map(aa => getGroupById(aa)).filter(aa => (aa != undefined));
                                         let groupsToRemoveUserFrom = selectedGrStudentGroupsIds.filter(pretId => (a.indexOf(pretId) == -1)).map(aa => getGroupById(aa)).filter(aa => (aa != undefined));
                                         for (let i in groupsToAddTo) {
                                             let gr = groupsToAddTo[i];
                                             let newStudentsIds = (gr.studentsIds == undefined ? [] : gr.studentsIds).concat([selGrStudent.id]);
                                             await dispatch(teacherGroupsActions.updateTeacherGroup({
                                                 id: gr.id,
                                                 studentsIds: newStudentsIds
                                             }));
                                         }
                                         for (let i in groupsToRemoveUserFrom) {
                                             let gr = groupsToRemoveUserFrom[i];
                                             let newStudentsIds = (gr.studentsIds == undefined ? [] : gr.studentsIds).filter(x => (x != selGrStudent.id));
                                             await dispatch(teacherGroupsActions.updateTeacherGroup({
                                                 id: gr.id,
                                                 studentsIds: newStudentsIds
                                             }));
                                         }
                                     }}
                        />
                    </SgUsersDropdownPlaceholder>

                    <BlueButton onClick={async () => {
                        setSelGroupUserId(undefined);
                    }}>
                        OK
                    </BlueButton>

                </NiceModalWithHeading>
            }

        </Wrapper>
    );
}

const SgUserPlaceholder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

const SgUsersDropdownPlaceholder = styled.div`
  width: 100%;
  margin-bottom: 43px;
`;

const Wrapper = styled.div`

`;

const CenterFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TrashImg = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const TopPlaceholder = styled.div`
  margin-bottom: 43px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TopRightPlaceholder = styled.div`

`;

const UsersPlaceholder = styled.div`
  background: white;
  border-radius: 4px;
`;

const UserAvatar = styled.div`
  width: 32px;
  height: 32px;
  background: #8593A8;
  border-radius: 1000px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${props => (props.src == undefined ? 'none' : `url(${props.src})`)};
  margin-right: 10px;
`;

const TopTableHeading = styled.div`
  background: #FFA71B;
  border-radius: 4px;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #202224;
  box-sizing: border-box;
  padding-right: 31px;
`;

const StudentRow = styled.div`
  padding-top: 27px;
  padding-bottom: 27px;
  border-bottom: 0.4px solid rgba(81, 139, 219, 0.4);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 31px;
  box-sizing: border-box;
`;

const NameCol = styled.div`
  width: 350px;
  box-sizing: border-box;
  padding-left: 31px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const OptionsCol = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const UserNameSpan = styled.span`
  color: #202224;
  mix-blend-mode: normal;
  opacity: 0.9;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
`;

const EmailCol = styled.div`
  width: 200px;
  padding-right: 5px;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const TopHeading = styled.div`
  font-weight: normal;
  font-size: 24px;
  line-height: 26px;
  display: flex;
  align-items: center;
  letter-spacing: 0.166667px;
  color: #333539;
`;

const StudentsList = styled.div`

`;

const StudentItem = styled.div`
  background: white;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
`;

const StudentInfo = styled.div`

`;

const Name = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const Info = styled.div`

`;


