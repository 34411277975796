import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import {Input} from "../../ira/ui/Inputs";
import {BlueButton} from "../../ira/ui/Buttons";
import SimpleTagsSelector from "./SimpleTagsSelector";
import {translate} from "../../../utils/Translate";

export default function SolutionInfoUpdateTool(props) {
    const {
        allTags = [],
        onChange = data => {

        },
        loading = false
    } = props;
    const [tags, setTags] = useState(props.tags == undefined ? [] : props.tags);
    const [rating, setRating] = useState(props.rating == undefined ? 0 : props.rating);

    return (
        <Wrapper>
            <Field>
                <Label>
                    {translate('rating')}
                </Label>
                <Input value={rating} onChange={evt => {
                    let s = evt.target.value.trim();
                    setRating(s);
                }}/>
            </Field>
            <Field>
                <Label>
                    Теги
                </Label>
                <SimpleTagsSelector {...props} tags={tags} onChange={arr => {
                    setTags(arr);
                }}/>
            </Field>

            <Field>
                <BlueButton style={{height: 24, paddingLeft: 10, paddingRight: 10, display: 'inline-flex'}}
                            onClick={() => {
                                onChange({
                                    tags: tags,
                                    rating: +rating
                                });
                            }}>
                    <Spin visible={loading}/>
                    {loading == true ? null :
                        translate('save')
                    }
                </BlueButton>
            </Field>


        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Field = styled.div`
    margin-bottom: 10px;
    :last-of-type{
      margin-bottom: 0px;
    }
`;

const Label = styled.div`
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 5px;
    opacity: 0.5;
`;
