import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import useDebouncedCallback from "use-debounce/lib/callback";
import {LIGHT_BROWN} from "../../ui/Colors";

import './style.css'

import Tabs from "../../ui/Tabs";
import ExerciseForm from "../forms/ExerciseForm";
import SmartPgnViewer from "../../chess/view/smart/SmartPgnViewer";

import * as exercisesActions from '../../../redux/actions/ExercisesActions'

import Sidebar from 'arui-feather/sidebar'

// import {Button} from "../../ui/Button";
import {Button} from '@blueprintjs/core'
import ExerciseSolutionsForm from "../forms/ExerciseSolutionsForm";
import PlayExerciseTool from "../tools/PlayExerciseTool";
import SmartSizePlayExerciseTool from "../tools/SmartSizePlayExerciseTool";
import SubjectCommentsPanel from "../../comments/panels/SubjectCommentsPanel";
import {translate} from "../../../utils/Translate";

export default function AllExercisesPanel(props) {
    const dispatch = useDispatch();
    const {
        selectedExercisesIds = [],
        folderId, onExerciseClick = exId => {

        }, openExerciseOnClick = true, canAddExercise = true
    } = props;
    const [searchString, setSearchString] = useState('');
    const [selectedExerciseId, setSelectedExerciseId] = useState(undefined);
    const [createModalVisible, setCreateModalVisible] = useState(false);
    let {currentUser, loading, exercises, userRole, isAdmin} = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let userRole = (currentUser != undefined) ? currentUser.userRole : undefined;
        return {
            userRole: userRole,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            currentUserId: state.users.currentUserId,
            loading: state.exercises.loading,
            exercises: state.exercises.exercisesMap.toArray().sort((a, b) => (+a.number - +b.number)),
            isAdmin: (userRole == 'admin')
        }
    }, []));
    const [debouncedSearch] = useDebouncedCallback((a) => {
        console.log('will set ', a);
        setSearchString(a);
    }, 300, []);
    if (currentUser == undefined) {
        return (
            <Wrapper>
                Access denied
            </Wrapper>
        )
    }
    let selectedExercise = (selectedExerciseId == undefined) ? undefined : exercises.filter(ex => (ex.id == selectedExerciseId))[0];
    const filteredExercises = (folderId == undefined) ? exercises : exercises.filter(ex => (ex.foldersIds.indexOf(folderId) > -1));
    let canEdit = (userRole == 'admin');
    console.log('AllExercisesPanel: render: userRole = ', userRole);

    let tabs = [
        {
            label: translate('view'),
            content: (
                <TabItem>
                    <SmartSizePlayExerciseTool {...selectedExercise} />
                    {/*<SmartPgnViewer pgnString={selectedExercise.pgn}/>*/}
                </TabItem>
            )
        }
    ];

    if (canEdit == true) {
        tabs = tabs.concat([{
            label: 'Информация',
            content: (
                <ExerciseForm {...selectedExercise}
                              canEdit={userRole == 'admin'}
                              loading={loading} onSave={d => {
                    let updData = {
                        ...d,
                        id: selectedExerciseId
                    };
                    dispatch(exercisesActions.updateExercise(updData)).then(pld => {
                        setSelectedExerciseId(undefined);
                    });
                }}/>
            )
        },
            {
                label: 'Редактор решений',
                content: (
                    <TabItem>
                        {userRole != 'admin' ? 'Только администратор имеет право на редактирование' :
                            <ExerciseSolutionsForm {...selectedExercise}
                                                   loading={loading}
                                                   canEdit={userRole == 'admin'}
                                                   onSave={d => {
                                                       console.log('onSave: d = ', d);
                                                       let updData = {
                                                           ...d,
                                                           id: selectedExerciseId
                                                       };
                                                       dispatch(exercisesActions.updateExercise(updData));
                                                   }}/>
                        }
                    </TabItem>
                )
            },
            {
                label: 'Комментарии пользователей',
                content: (
                    <TabItem>
                        {selectedExercise == undefined ? null :
                            <SubjectCommentsPanel subjectId={selectedExercise.id} />
                        }
                    </TabItem>
                )
            }

            ]);
    }

    return (
        <Wrapper>

            {canAddExercise == false || canEdit == false ? null :
                <TopExercisesPlaceholder>
                    <Button icon={'plus'} onClick={() => {
                        setCreateModalVisible(true);
                    }}>
                        Добавить упражнение
                    </Button>
                </TopExercisesPlaceholder>
            }

            <ExercisesList>
                {filteredExercises.map((ex, k) => {
                    let selected = (ex.id == selectedExerciseId);
                    let picked = (selectedExercisesIds.indexOf(ex.id) > -1);
                    return (
                        <ExerciseItem picked={picked} selected={selected} key={`${ex.id}_${k}_${ex.number}`}>
                            <ExInfo onClick={() => {
                                console.log('AllExercisesPanel: ExerciseItem: onClick: ', ex.id);
                                onExerciseClick(ex.id);
                                if (openExerciseOnClick == true) {
                                    setSelectedExerciseId(ex.id);
                                }
                            }}>
                                <ExName>
                                    {ex.name}
                                </ExName>
                                {ex.description == undefined || ex.description == '' ? null :
                                    <ExDescription>
                                        {ex.description}
                                    </ExDescription>
                                }
                            </ExInfo>
                            {isAdmin == false ? null :
                                <ExControls>
                                    {k == 0 ? null :
                                        <MiniArrow
                                            style={{transform: `rotate(180deg)`}}
                                            src={require('../../../assets/images/arrow-point-to-down.svg')}
                                            onClick={async () => {
                                                let num1 = filteredExercises[+k - 1].number;
                                                let id1 = ex.id;
                                                let id2 = filteredExercises[+k - 1].id;
                                                let num2 = filteredExercises[+k].number;
                                                await dispatch(exercisesActions.updateExercise({
                                                    id: id1,
                                                    number: num1
                                                }));
                                                await dispatch(exercisesActions.updateExercise({
                                                    id: id2,
                                                    number: num2
                                                }));
                                            }}
                                        />
                                    }
                                    {k == filteredExercises.length - 1 ? null :
                                        <MiniArrow src={require('../../../assets/images/arrow-point-to-down.svg')}
                                                   onClick={async () => {
                                                       let num1 = filteredExercises[+k + 1].number;
                                                       let id1 = ex.id;
                                                       let id2 = filteredExercises[+k + 1].id;
                                                       let num2 = filteredExercises[+k].number;
                                                       await dispatch(exercisesActions.updateExercise({
                                                           id: id1,
                                                           number: num1
                                                       }));
                                                       await dispatch(exercisesActions.updateExercise({
                                                           id: id2,
                                                           number: num2
                                                       }));
                                                   }}
                                        />
                                    }
                                </ExControls>
                            }

                        </ExerciseItem>
                    )
                })}
            </ExercisesList>


            <Sidebar visible={(selectedExercise != undefined)}
                     hasCloser={false}
                     width={window.innerWidth - 40}
                     className={'all_exercises_selected_sidebar'}
            >

                <div key={`b_${selectedExerciseId}`}>
                    {selectedExercise == undefined ? null :
                        <div>
                            <SelectedModalHeader>
                                <AllExercisesSpan onClick={() => {
                                    setSelectedExerciseId(undefined);
                                }}>
                                    Упражнения
                                </AllExercisesSpan>
                                {' / '}
                                <span>
                                    {selectedExercise.name}
                                </span>
                            </SelectedModalHeader>

                            <Tabs tabs={tabs}/>
                        </div>
                    }
                </div>
            </Sidebar>

            <Sidebar onCloserClick={() => setCreateModalVisible(false)}
                     visible={createModalVisible}
                     headerContent={null}
            >

                {createModalVisible == false ? null :
                    <div>
                        <ExerciseForm foldersIds={folderId == undefined ? [] : [folderId]} loading={loading}
                                      canEdit={canEdit}
                                      onSave={data => {
                                          dispatch(exercisesActions.createExercise(data)).then(pld => {
                                              setSelectedExerciseId(pld.exercise.id);
                                              setCreateModalVisible(false);
                                          })
                                      }}/>
                    </div>
                }

            </Sidebar>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    //display: flex;
    //flex-direction: row;
    //justify-content: flex-start;
    //align-items: stretch;
`;


const ExercisesList = styled.div`
    
`;

const ExerciseItem = styled.div`
    padding: 5px;
    background: ${props => (props.selected == true ? LIGHT_BROWN : 'white')};
    color: ${props => (props.selected == true ? 'white' : 'black')};
    border: 1px solid ${props => (props.picked == true ? 'blue' : 'whitesmoke')};
    margin-bottom: 5px;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const MiniArrow = styled.img`
    opacity: 0.5;
    cursor: pointer;
    margin: 2px;
    width: 8px;
    :hover{
      opacity: 1;
    }
`;

const ExInfo = styled.div`
    flex: 1;
    cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
`;

const ExControls = styled.div`
    width: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const ExName = styled.div`
    font-weight: bold;
    font-size: 16px;
`;

const ExDescription = styled.div`
    font-size: 12px;
    opacity: 0.5;
`;

const TabItem = styled.div`
    padding-top: 5px;
    width: 100%;
`;

const AllExercisesSpan = styled.span`
    cursor: pointer;
    text-decoration: underline;
    opacity: 0.8;
    :hover{
      opacity: 1;
    }
`;


const SelectedModalHeader = styled.div`
    margin-bottom: 25px;
    font-size: 24px;
`;

const TopExercisesPlaceholder = styled.div`
    margin-bottom: 5px;
    flex-direction: row;
    align-items: center;
    display: flex;
`;
