import React, {useEffect, useState} from "react";
import PgnHelper from "../../rubius/pgnViewer/PgnHelper";
import styled from "styled-components";

import {Input, Textarea, Label} from "../../ira/ui/Inputs";
import ColorSelector from "../../chess/tools/ColorSelector";
import {BlueButton} from "../../ira/ui/Buttons";

import Spin from 'arui-feather/spin'

const emptyBox = require('../../../assets/images/void_check.svg');
const checkedImg = require('../../../assets/images/ch_check.svg');

export default function UpdateSmartExerciseForm(props) {
    let {loading} = props;
    let onSave = !props.onSave ? () => {
    } : props.onSave;
    let [formData, setFormData] = useState(
        {
            name: props.name,
            vendorCode: props.vendorCode,

            fromRating: props.fromRating,
            toRating: props.toRating,

            isIllegal: props.isIllegal || false,
            description: props.description,
            color: props.color,
            tags: !props.tags ? [] : props.tags,
            foldersIds: !props.foldersIds ? [] : props.foldersIds,
            pgn: !props.pgn ? '' : props.pgn,
            fen: !props.fen ? '' : props.fen,
            stepLimit: props.stepLimit || 0,
            duration: props.duration || 0,
            finalImageUrl: props.finalImageUrl,
            finalAudioUrl: props.finalAudioUrl,
            finalVideoUrl: props.finalVideoUrl,
            finalText: !props.finalText ? '' : props.finalText,
            finalHtml: !props.finalHtml ? '' : props.finalHtml
        }
    );

    const setFen = () => {
        if (!formData.isIllegal) {
            const {game} = PgnHelper.parse(formData.pgn);
            const fen = game ? PgnHelper.getStartFen(game) : '';

            setFormData({...formData, fen});
        }
    }

    useEffect(() => setFen(), [formData.pgn]);

    return (
        <Wrapper>

            <Field>
                <Label>
                    Название упражнения
                </Label>
                <Input value={formData.name}
                       placeholder={'Название'}
                       autoFocus={true} onChange={evt => {
                    let newData = {...formData, name: evt.target.value};
                    setFormData(newData);
                }}/>
            </Field>

            <Field>
                <Label>
                    Артикул
                </Label>
                <Input value={formData.vendorCode}
                       placeholder={'Артикул'}
                       autoFocus={true} onChange={evt => {
                    let newData = {...formData, vendorCode: evt.target.value};
                    setFormData(newData);
                }}/>
            </Field>


            <Field>
                <Label>
                    Нелегальная позиция
                </Label>
                <div>
                    <CheckImg src={formData.isIllegal ? checkedImg : emptyBox} onClick={() => {
                        let newData = {...formData, isIllegal: !formData.isIllegal};
                        setFormData(newData);
                    }}/>
                </div>
            </Field>

            <Field>
                <Label>
                    Описание
                </Label>
                <Textarea value={formData.description} onChange={evt => {
                    let newData = {...formData, description: evt.target.value};
                    setFormData(newData);
                }}/>
            </Field>

            <Field>
                <Label>
                    PGN
                    <InfoSpan>
                        <a target={'_blank'} href={'https://lichess.org/editor'}>
                            <InfoImage src={infImg}/>
                        </a>
                    </InfoSpan>
                </Label>
                <Textarea value={formData.pgn}
                          placeholder={'Вставить PGN'}
                          style={{height: `20em`, minHeight: `20em`}}
                          onChange={evt => {
                              let newPgn = evt.target.value;
                              let newData = {...formData, pgn: newPgn};
                              setFormData(newData);
                          }}/>
            </Field>

            <Field>
                <Label>
                    FEN
                </Label>
                <Input value={formData.fen}
                       placeholder={'Вставить FEN начальной позиции'}
                       onChange={evt => {
                           let newData = {...formData, fen: evt.target.value};
                           setFormData(newData);
                       }}/>
                <div style={{textAlign: 'right'}}>
                    <BSpan onClick={() => setFen()}>
                        вставить FEN из PGN
                    </BSpan>
                </div>
            </Field>

            <Field>
                <Label>
                    Цвет игрока
                </Label>
                <ColorSelector color={formData.color} onChange={newColor => {
                    let newData = {...formData, color: newColor};
                    setFormData(newData);
                }}/>
            </Field>

            <Field>
                <Label>
                    Минимальный рейтинг
                </Label>
                <Input value={formData.fromRating} onChange={evt => {
                    let newData = {...formData, fromRating: +evt.target.value};
                    setFormData(newData);
                }}/>
            </Field>

            <Field>
                <Label>
                    Максимальный рейтинг
                </Label>
                <Input value={formData.toRating} onChange={evt => {
                    let newData = {...formData, toRating: +evt.target.value};
                    setFormData(newData);
                }}/>
            </Field>

            <Field>
                <Label>
                    Ограничение ходов
                </Label>

                <Input value={formData.stepLimit} onChange={evt => {
                    let newData = {...formData, stepLimit: +evt.target.value};
                    setFormData(newData);
                }}/>
            </Field>

            <Field>
                <Label>
                    Ограничение по времени
                </Label>

                <Input value={formData.duration} onChange={evt => {
                    let newData = {...formData, duration: +evt.target.value};
                    setFormData(newData);
                }}/>
            </Field>

            <Field>
                <BlueButton onClick={() => onSave(formData)}>
                    {loading ? <Spin visible={true}/> : <span>Сохранить</span>}
                </BlueButton>
            </Field>
        </Wrapper>
    );
}

const CheckImg = styled.img`
  width: 16px;
  height: 16px;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const Wrapper = styled.div`
  text-align: left;
  background: white;
  padding: 30px;
  margin: 30px;
  box-sizing: border-box;
  border: 1px solid rgba(8, 91, 255, 0.1);
`;

const Field = styled.div`
  margin-bottom: 20px;
`;


const InfoSpan = styled.span`
  float: right;
`;

const infImg = require('../../rubius/assets/images/info_svg_.svg');

const InfoImage = styled.img`
  height: 16px;
  cursor: pointer;
  opacity: 0.4;

  :hover {
    opacity: 1;
  }
`;

const BSpan = styled.span`
  cursor: pointer;
  opacity: 0.4;
  font-size: 12px;
  font-style: italic;

  :hover {
    opacity: 0.8;
    text-decoration: underline;
  }
`;
