import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const TestsAPI = {

    getAllTests() {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/tests/all`).then(d => d.data).then(exercises => {
                if (exercises.error != undefined) {
                    return reject(exercises.error);
                }
                resolve(exercises);
            }).catch(err => reject(err));
        });
    },

    createTest(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/tests/create`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    updateTest(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/tests/update`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    deleteTest(id) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/tests/delete/${id}`).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

}

export default TestsAPI;
