import React, { Component } from 'react';

export default class Error extends Component {
  render () {
    return (
      <div>
        <p style={{color: 'red'}} >{this.props.text}</p>
      </div>
    );
  }
}
