import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import axios from 'axios';
import {Map, Set} from 'immutable';
import styled from 'styled-components';

import './style.css'

import Tabs, {TabItem} from "../../ui/Tabs";
import SimpleRealtimeBoard from "../../realtime/board/SimpleRealtimeBoard";

import Select from 'react-select'
import CommonHelper from "../../../helpers/CommonHelper";

import {Input} from "../../ui/Input";

import FirebaseAPI from '../../../api/FirebaseAPI'
import RoomChatPanel from "../../chat/panels/RoomChatPanel";
import SimpleFenEditor from "../../realtime/tools/SimpleFenEditor";
import OpenviduRoomPanel from "../../openvidu/panels/OpenviduRoomPanel";

import GridLayout from 'react-grid-layout';
import 'react-grid-layout/css/styles.css'

// import { Col, ColsWrapper, Row, RowsWrapper } from 'react-grid-resizable'

import useComponentSize from "@rehooks/component-size/index";
import NiceModal from "../../modals/NiceModal";
import TimerSpan from "../tools/TimerSpan";
import BrutalOverlayModal from "../../modals/BrutalOverlayModal";
import UnitsPgnSelectorTool from "../tools/UnitsPgnSelectorTool";
import {PgnViewer} from "../../rubius";
import PgnHelper from "../../rubius/pgnViewer/PgnHelper";
import ChessHelper from "../../../helpers/ChessHelper";
import MovesHistoryList from "../tools/MovesHistoryList";
import NiceModalWithHeading from "../../modals/NiceModalWithHeading";
import WarmupEditorTool from "../../warmup/tools/WarmupEditorTool";
import {API_ENDPOINT} from "../../../constants/config";
import {BlueButton} from "../../ira/ui/Buttons";
import ExercisesPgnSelectorTool from "../tools/ExercisesPgnSelectorTool";
import UpdateArrowsAndSquaresTool from "../../arrows/tools/UpdateArrowsAndSquaresTool";
import RecordingInfoSpan from "../../recordings/panels/RecordingInfoSpan";

const jitsiDivId = 'jitsi_placeholder';

const getUName = u => {
    if (u == undefined) {
        return `No name`;
    }
    let ie = x => (x == undefined || x == '');
    if (ie(u.firstName) || ie(u.lastName)) {
        return u.email;
    }
    return `${u.firstName} ${u.lastName}`;
}

const originalLayout = getFromLS("layout") || [];

// axios.get(`${API_ENDPOINT}/key/landing_webinar_items`).then(d => d.data.result).then(a => {
//     console.log('landing_webinar_items: a = ', a);
//     let arr = (a == undefined) ? [] : a;
//     setItems(arr);
//     setLoading(false);
// });

async function getRoomPairs(roomName) {
    try {
        let key = `pairs_${roomName}`;
        let d = (await axios.get(`${API_ENDPOINT}/key/${key}`)).data.result;
        if (d == undefined) {
            d = [];
        }
        return d;
    } catch (exc) {
        return [];
    }
}

export default function SimpleInteractiveCommutePanel(props) {
    const {
        isHeadless = false,
        startTimestamp,
        currentUser = undefined,
        roomId = 'sabir',
        participants = [],
        name = '',
        isOrganizer = true,
        hasTopSection = true,
        canGoBack = true,
        hasVideo = true,
        lessonId,
        exercises = [],
        communicationTool = 'openvidu',
        onBack = () => {

        },
        onPairAdd = (d, onAdded) => {

        },
        onPairsChange = arr => {

        },
        units = [],

        onSettingsOpen = () => {

        },

        currentUserId = undefined
    } = props;

    const jitsiRef = useRef();

    const [lastChangingTime, setLastChangingTime] = useState(0);

    const [problemUpdT, setProblemUpdT] = useState(0);
    const [hasProblem, setHasProblem] = useState(false);

    const partOptions = participants.map(a => ({label: CommonHelper.getUserName(a), value: a.id}));
    const [selectedParticipantId, setSelectedParticipantId] = useState('nouser');
    const [orientation, setOrientation] = useState('white');

    const [selectedPgn, setSelectedPgn] = useState(undefined);
    const [selectedUnitId, setSelectedUnitId] = useState(undefined);
    const [selectedExerciseId, setSelectedExerciseId] = useState(undefined);
    const [chatVisible, setChatVisible] = useState(false);

    const [warmupSettingsOpen, setWarmupSettingsOpen] = useState(false);

    const [settingsOpen, setSettingsOpen] = useState(false);
    const [moving, setMoving] = useState(false);
    const [layout, setLayout] = useState(JSON.parse(JSON.stringify(originalLayout)));

    let selectedOption = partOptions.filter(a => (a.value == selectedParticipantId))[0];
    const [mode, setMode] = useState('board');
    const [currentFen, setCurrentFen] = useState();
    const wrapperRef = useRef();
    const size = useComponentSize(wrapperRef);

    const [boardUpdT, setBoardUpdT] = useState(0);

    useEffect(() => {
        if (settingsOpen == true || warmupSettingsOpen == true) {
            setTimeout(() => {
                FirebaseAPI.getLazySimpleBoard(roomId, {}).then(pld => {
                    console.log('--->>> pld = ', pld);
                    setCurrentFen(pld.fen);
                });
            }, 300);
        }
    }, [settingsOpen, warmupSettingsOpen]);

    useEffect(() => {
        if (communicationTool != 'jitsi') {
            return;
        }
        try {
            jitsiRef.current.api = new window.JitsiMeetExternalAPI(window.JITSI_SERVER_URL || 'videoconf2.sabir.pro', {
                parentNode: document.querySelector(`#${jitsiDivId}`),
                roomName: `room_${roomId}`,
                userInfo: {
                    email: (window.CURRENT_USER == undefined ? '' : window.CURRENT_USER.email),
                    displayName: getUName(window.CURRENT_USER)
                },
                muted: isHeadless,
                interfaceConfigOverwrite: {
                    disableDeepLinking: true,
                    SHOW_WATERMARK_FOR_GUESTS: false,
                    DEFAULT_REMOTE_DISPLAY_NAME: 'Собеседник',
                    DISABLE_VIDEO_BACKGROUND: true,
                    DISABLE_DOMINANT_SPEAKER_INDICATOR: true,
                    DISABLE_FOCUS_INDICATOR: true,
                    SHOW_BRAND_WATERMARK: true,
                    SHOW_JITSI_WATERMARK: false,
                    DEFAULT_LOGO_URL: 'https://www.englishpatient.org/english-patient-files/1f2e3795-a753-4275-81a5-820d1546879a.png',
                    DEFAULT_WELCOME_PAGE_LOGO_URL: 'https://www.englishpatient.org/english-patient-files/1f2e3795-a753-4275-81a5-820d1546879a.png',
                    APP_NAME: 'Shagi',
                    NATIVE_APP_NAME: 'Shagi',
                    PROVIDER_NAME: 'Shagi',
                    AUTHENTICATION_ENABLE: false,
                    MOBILE_APP_PROMO: false,
                    TOOLBAR_BUTTONS: isHeadless ? [] : [
                        'desktop', 'microphone', 'camera', 'tileview', 'fullscreen', 'raisehand', 'fodeviceselection',
                        'etherpad', 'select-background', 'settings', 'videoquality', 'filmstrip', 'shortcuts'
                    ],
                    SETTINGS_SECTIONS: (isHeadless) ? [] : ['devices']
                },
                configOverwrite: {
                    ignoreStartMuted: true,
                    muted: isHeadless,
                    disableAudioLevels: true,
                    disableDeepLinking: true,
                },


            });
        } catch (exc) {

        }

    }, []);


    // useEffect(() => {
    //     if (selectedPgn == undefined) {
    //         return;
    //     }
    //     console.log('selectedPgn changed! selectedPgn = ', selectedPgn);
    //
    // }, [selectedPgn]);

    // const layout = [
    //     {i: 'a', x: 0, y: 0, w: 15, h: 30, minW: 10},
    //     {i: 'b', x: 16, y: 0, w: 3, h: 2, minW: 2, maxW: 4},
    // ];

    const fensHistoryArr = ChessHelper.getFensHistoryFromLocalStorage(roomId);

    return (
        <Wrapper>
            {/*<TopHeading style={{display: 'none'}}>*/}
            <TopHeading>

                <TopLeftPlaceholder>
                    <BackPlaceholder>
                        <LogoImg src={require('../../../assets/images/onl_logo.svg')}/>
                    </BackPlaceholder>
                </TopLeftPlaceholder>

                <TopCenterPlaceholder>
                    {name == undefined || name.trim() == '' ? null :
                        <NamePlaceholder>
                            {name}
                        </NamePlaceholder>
                    }
                </TopCenterPlaceholder>

                <TopSpanPlaceholder>
                    <TimerSpan startTimestamp={startTimestamp}/>
                </TopSpanPlaceholder>

                <TopRightPlaceholder>


                    <TopRightSettingsPlaceholder>
                        {/*<SettingsIcon*/}
                        {/*src={require('../../../assets/images/enlarge.svg')}*/}
                        {/*// onClick={() => {*/}
                        {/*//     setMode({board: 'noboard', noboard: 'board'}[mode]);*/}
                        {/*// }}*/}
                        {/*/>*/}

                        {isOrganizer == false ? null :
                            <RecordingInfoSpan
                                lessonId={lessonId}
                                onClick={() => {
                                    setOrientation({white: 'black', black: 'white'}[orientation]);
                                }}
                            />
                        }

                        <SettingsIcon
                            style={{background: (chatVisible == true ? 'whitesmoke' : 'transparent')}}
                            src={require('../../../assets/images/chat_round.svg')}
                            onClick={() => {
                                setChatVisible(!chatVisible);
                            }}
                        />


                        {isOrganizer == false ? null :
                            <SettingsIcon
                                src={require('../../../assets/images/board_sw.svg')}
                                onClick={() => {
                                    setOrientation({white: 'black', black: 'white'}[orientation]);
                                }}
                            />
                        }

                        <SettingsIcon
                            src={require('../../../assets/images/d_play.svg')}
                            onClick={() => {
                                setWarmupSettingsOpen(true);
                            }}
                        />


                        {isOrganizer == false ? null :
                            <SettingsIcon
                                src={require('../../../assets/images/board_round.svg')}
                                onClick={() => {
                                    setSettingsOpen(true);
                                    onSettingsOpen();
                                }}
                            />
                        }

                    </TopRightSettingsPlaceholder>

                    {isOrganizer == false ? null :
                        <TopRightSelectPlaceholder>
                            {participants.length == 0 ? null :
                                <Select
                                    isClearable={true}
                                    placeholder={'Активный ученик'}
                                    className={'participants-react-select'}
                                    options={partOptions}
                                    value={selectedOption}
                                    onChange={a => {
                                        if (a == undefined) {
                                            setSelectedParticipantId(undefined)
                                        } else {
                                            setSelectedParticipantId(a.value);
                                        }
                                    }}
                                    onReady={d => {
                                        if (d == undefined) {
                                            return;
                                        }
                                        let selUserId = d.selectedUserId;
                                        setSelectedParticipantId(selUserId);
                                        setOrientation(d.orientation == undefined ? 'white' : d.orientation);
                                    }}
                                />
                            }
                        </TopRightSelectPlaceholder>
                    }

                </TopRightPlaceholder>

            </TopHeading>

            {/*<RowsWrapper>*/}
            {/*<Row initialHeight={100} />*/}
            {/*<Row initialHeight={100} />*/}
            {/*<Row />*/}
            {/*</RowsWrapper>*/}

            <GridWrapper ref={wrapperRef}>
                <GridLayout className="layout"
                            layout={layout}
                            onLayoutChange={ll => {
                                saveToLS("layout", ll);
                                setLayout(ll);
                            }}
                            preventCollision={true}
                            autoSize={true}
                            cols={20}
                            draggableHandle={'.my-drag-handle'}
                            margin={[0, 0]}
                            colWidth={window.innerWidth / 20.0}
                            rowHeight={(window.innerHeight - 60) / 20.0}
                            width={window.innerWidth}
                            height={window.innerHeight - 60}

                >
                    {
                        [
                            <LeftSection key="a"
                                         className={'no_tr_sab'}
                                         data-grid={{
                                             i: 'a', x: 0,
                                             y: 0,
                                             w: 10,
                                             h: 20,
                                             minW: 6,
                                             minH: 6,
                                             isDraggable: false
                                         }}>

                                {(settingsOpen == true || warmupSettingsOpen == true) ? null :
                                    <div style={{width: '100%', height: '100%', position: 'relative'}}
                                         key={problemUpdT} id={'realtime_board_placeholder'}>
                                        <SimpleRealtimeBoard
                                            isIllegal={false}
                                            adminMode={isOrganizer}
                                            boardId={`${roomId}`}
                                            participants={participants}
                                            orientation={orientation}
                                            selectedUserId={selectedParticipantId == undefined ? 'nouser' : selectedParticipantId}
                                            currentUserId={currentUserId}
                                            onBoardStateChange={() => {
                                                console.log('onBoardStateChange occured!');
                                                setBoardUpdT(+new Date());
                                            }}
                                            onReady={d => {
                                                console.log('SimpleRealtimeBoard: onReady: d = ', d);
                                            }}
                                        />
                                        {/*<div style={{*/}
                                        {/*position: 'absolute',*/}
                                        {/*zIndex: 2,*/}
                                        {/*left: 10,*/}
                                        {/*right: 10,*/}
                                        {/*top: 0,*/}
                                        {/*bottom: 20*/}
                                        {/*}}>*/}
                                        {/*<UpdateArrowsAndSquaresTool/>*/}
                                        {/*</div>*/}
                                    </div>
                                }

                            </LeftSection>,
                            <ConfPlaceholder key={'videos'} className={'my-drag-handle'} data-grid={{
                                i: 'videos',
                                x: 12,
                                y: 0,
                                w: 8,
                                h: 8,
                                minW: 2,
                                minH: 5,
                                maxW: 15,
                                maxH: 20,
                                isDraggable: true,
                                isDroppable: true
                            }}>
                                <div style={{width: '100%', height: '100%'}} key={problemUpdT}>
                                    {communicationTool != 'jitsi' ? null :
                                        <div style={{
                                            width: '100%',
                                            height: '100%',
                                            boxSizing: 'border-box',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'stretch'
                                        }}>
                                            <div style={{height: 7, backgroundColor: 'grey'}}>
                                            </div>
                                            <div id={jitsiDivId} style={{flex: 1}}>

                                            </div>
                                        </div>
                                    }
                                    {communicationTool != 'openvidu' ? null :
                                        <OpenviduRoomPanel roomName={`room_${roomId}`}
                                                           user={currentUser}
                                                           isTeacher={isOrganizer}
                                                           onProblem={() => {
                                                               setHasProblem(true);
                                                           }}
                                        />
                                    }

                                </div>
                            </ConfPlaceholder>,
                            (selectedPgn != undefined && isOrganizer == true) &&
                            <SelectedPgnPlaceholder key={'pgn'} className={'my-drag-handle'} data-grid={{
                                i: 'pgn',
                                x: 12,
                                y: 8,
                                w: 8,
                                h: 8,
                                minW: 2,
                                minH: 5,
                                maxW: 15,
                                maxH: 20,
                                isDroppable: true
                            }}>

                                {selectedPgn == undefined ? null :
                                    <PgnViewerWrapper key={`p_${selectedPgn}_${lastChangingTime}`}>
                                        <CurrentSelectedPgnTopPlaceholder>
                                            <CurrentSelectedPgnTopControlItem>
                                                <CurrentSelectedPgnTopControlButton onClick={async () => {
                                                    setLastChangingTime(+new Date());
                                                    console.log('selectedPgn = ', selectedPgn);
                                                    let fen = ChessHelper.getInitialFenFromPgn(selectedPgn);
                                                    console.log('CurrentSelectedPgnTopControlButton: fen = ', fen);
                                                    await FirebaseAPI.sendFenToSimpleBoard(roomId, fen, selectedParticipantId, orientation);
                                                    setCurrentFen(fen);
                                                }}>
                                                    начальная позиция
                                                </CurrentSelectedPgnTopControlButton>
                                            </CurrentSelectedPgnTopControlItem>
                                            <CurrentSelectedPgnTopControlItem>

                                            </CurrentSelectedPgnTopControlItem>
                                        </CurrentSelectedPgnTopPlaceholder>
                                        <PgnViewer pgn={selectedPgn} onMoveClick={async ({node}) => {
                                            const nodeFen = PgnHelper.getFen(node);
                                            console.log('render: nodeFen = ', nodeFen);
                                            await FirebaseAPI.sendFenToSimpleBoard(roomId, nodeFen, selectedParticipantId, orientation);
                                            setCurrentFen(nodeFen);

                                        }}/>
                                    </PgnViewerWrapper>
                                }

                            </SelectedPgnPlaceholder>,
                            (selectedPgn == undefined && isOrganizer == true) &&
                            <SelectedPgnPlaceholder key={'moves_hist'}
                                                    className={'my-drag-handle'} data-grid={{
                                i: 'pgn',
                                x: 12,
                                y: 16,
                                w: 4,
                                h: 4,
                                minW: 2,
                                minH: 5,
                                maxW: 15,
                                maxH: 20,
                                isDraggable: true,
                                isDroppable: true
                            }}>
                                <div style={{height: '100%', maxHeight: '100%'}}>
                                    <MovesHistoryList updT={boardUpdT} boardId={roomId}
                                                      onFenSelect={async fFen => {
                                                          console.log('onFenSelect: orientation, fFen = ', orientation, fFen);
                                                          await FirebaseAPI.sendFenToSimpleBoard(roomId, fFen, selectedParticipantId, orientation, true);
                                                          setCurrentFen(fFen);
                                                      }}/>
                                </div>
                            </SelectedPgnPlaceholder>
                        ].filter(a => a)
                    }

                </GridLayout>
            </GridWrapper>

            {chatVisible == false ? null :
                <ChatModalPlaceholder>
                    <ChatModalInner>
                        <RoomChatPanel roomId={roomId}/>
                    </ChatModalInner>
                </ChatModalPlaceholder>
            }

            {hasProblem == false ? null :
                <NiceModalWithHeading width={400} zIndex={200} onClose={() => {
                    setHasProblem(false);
                    setProblemUpdT(+new Date());
                }} heading={'Проблема с соединением'} subHeading={'Убедитесь, что у Вас стабильное соединение.'}>

                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <BlueButton onClick={() => {
                            setProblemUpdT(+new Date());
                            setHasProblem(false);
                        }}>
                            OK
                        </BlueButton>
                    </div>


                </NiceModalWithHeading>
            }

            {warmupSettingsOpen == false ? null :
                <NiceModalWithHeading width={800} zIndex={100} onClose={() => {
                    setWarmupSettingsOpen(false);
                }} heading={''} subHeading={''}>
                    <div key={currentFen}>
                        <WarmupEditorTool
                            {...props}
                            fen={currentFen}
                            users={participants}
                            lessonId={lessonId}
                            onAdd={(d, onAdded) => {
                                onPairAdd(d, onAdded);
                            }}
                            onPairsChange={newPairs => {
                                onPairsChange(newPairs)
                            }}
                            currentUser={currentUser}
                            studentId={(currentUser != undefined && currentUser.userRole == 'student') ? currentUser.id : undefined}
                        />
                    </div>

                </NiceModalWithHeading>
            }


            {settingsOpen == false ? null :
                <BrutalOverlayModal zIndex={1000} width={window.innerWidth} onClose={() => {
                    setSettingsOpen(false);
                }}>
                    {settingsOpen == false ? null :
                        <InnerSettings>
                            <Tabs
                                tabs={[
                                    {
                                        label: 'Доска из модулей',
                                        content: (
                                            <TabItem>
                                                <div style={{zIndex: 10000}}>
                                                    <UnitsPgnSelectorTool units={units}
                                                                          selectedUnitId={selectedUnitId}
                                                                          onSelect={async (p, unitId) => {

                                                                              try {
                                                                                  let re = /FEN \"(.*)\"/i;
                                                                                  let match = re.exec(p);
                                                                                  if (match.length < 2) {
                                                                                      return;
                                                                                  }
                                                                                  let resF = match[1];
                                                                                  if (resF == undefined) {
                                                                                      return;
                                                                                  }
                                                                                  console.log('--->>> parsed FEN = ', resF);
                                                                                  // let newFen = ChessHelper.getFenFromPgn(selectedPgn);
                                                                                  await FirebaseAPI.sendFenToSimpleBoard(roomId, resF, selectedParticipantId, orientation);
                                                                              } catch (e) {

                                                                              }

                                                                              setSelectedPgn(p);
                                                                              setSelectedUnitId(unitId);
                                                                              setSettingsOpen(false);
                                                                          }}
                                                    />
                                                </div>
                                            </TabItem>
                                        )
                                    },
                                    {
                                        label: 'Доска из упражнений',
                                        content: (
                                            <TabItem key={`ddd_${selectedExerciseId}`}>
                                                <div style={{zIndex: 10000}}>
                                                    <ExercisesPgnSelectorTool
                                                        exercises={exercises}
                                                        selectedExerciseId={selectedExerciseId}
                                                        onSelect={async (p, exId) => {
                                                            try {
                                                                let re = /FEN \"(.*)\"/i;
                                                                let match = re.exec(p);
                                                                if (match.length < 2) {
                                                                    return;
                                                                }
                                                                let resF = match[1];
                                                                if (resF == undefined) {
                                                                    return;
                                                                }
                                                                console.log('--->>> parsed FEN = ', resF);
                                                                // let newFen = ChessHelper.getFenFromPgn(selectedPgn);
                                                                await FirebaseAPI.sendFenToSimpleBoard(roomId, resF, selectedParticipantId, orientation);
                                                            } catch (e) {

                                                            }

                                                            setSelectedPgn(p);
                                                            setSelectedExerciseId(exId);
                                                            setSettingsOpen(false);
                                                        }}
                                                    />
                                                </div>
                                            </TabItem>
                                        )
                                    },
                                    {
                                        label: 'Редактор доски',
                                        content: (
                                            <TabItem>
                                                <div style={{zIndex: 10000}}>
                                                    {currentFen == undefined ? null :
                                                        <SimpleFenEditor fen={currentFen} onSave={async newFen => {
                                                            ChessHelper.flushBoardFenLocalHistory(roomId);
                                                            await FirebaseAPI.sendFenToSimpleBoard(roomId, newFen, selectedParticipantId, orientation);
                                                            setSelectedPgn(undefined);
                                                            setCurrentFen(newFen);
                                                            setSettingsOpen(false);
                                                        }}/>
                                                    }
                                                </div>
                                            </TabItem>
                                        )
                                    },
                                    {
                                        label: 'Загрузить из FEN',
                                        content: (
                                            <TabItem>
                                                <Input placeholder={'Вставьте FEN и нажмите Enter'}
                                                       onKeyDown={async e => {
                                                           if (e.key === 'Enter') {
                                                               await FirebaseAPI.sendFenToSimpleBoard(roomId, e.target.value, selectedParticipantId, orientation);
                                                               setSettingsOpen(false);
                                                           }
                                                       }}/>
                                            </TabItem>
                                        )
                                    }
                                ]}
                            />

                        </InnerSettings>
                    }
                </BrutalOverlayModal>
            }

        </Wrapper>
    );
}

const PgnViewerWrapper = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

const CurrentSelectedPgnTopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const CurrentSelectedPgnTopControlItem = styled.div`

`;

const CurrentSelectedPgnTopControlButton = styled.span`
  cursor: pointer;
  opacity: 0.75;

  :hover {
    opacity: 1;
  }
`;

const ChatModalPlaceholder = styled.div`
  position: fixed;
  top: 60px;
  right: 0px;
  bottom: 0px;
  background: white;
  width: 300px;
  box-sizing: border-box;
`;

const ChatModalInner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const InnerSettings = styled.div`
  width: 1020px;
  box-sizing: border-box;
  margin: 0 auto;
  @media (max-width: 1020px) {
    width: 100%;
  }
`;

const LeftSection = styled.div`
  background: lightgrey;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const ConfPlaceholder = styled.div`
  background: white;
`;

const SelectedPgnPlaceholder = styled.div`
  background: whitesmoke;
  box-sizing: border-box;
  padding: 5px;
`;

const RightSection = styled.div`
  //background: green;
`;

const topHeight = 60;

const TopHeading = styled.div`
  height: ${topHeight}px;
  background: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 30px;
  justify-content: space-between;
`;

const TopSpanPlaceholder = styled.div`
  color: #FF624F;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

const GridWrapper = styled.div`
  width: 100vw;
  height: calc(100vh - ${topHeight}px);
  background: #8593A8;
  position: relative;
`;

const LogoImg = styled.img`
  height: 32px;
`;

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: whitesmoke;
`;

const InterPlaceholder = styled.div`
  width: 100%;
  //height: calc(100vh - 80px);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //background: brown;
`;

const TopPlaceholder = styled.div`
  height: ${topHeight}px;
  width: 100%;
  background: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 5px;
`;

const BackPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

const NamePlaceholder = styled.div`

`;

const TopLeftPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 30px;
  box-sizing: border-box;
  margin-right: 90px;
  width: 250px;
`;

const TopCenterPlaceholder = styled.div`
  color: #8593A8;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TopRightPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: white;
  z-index: 100;
`;

const TopRightSettingsPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const TopRightSelectPlaceholder = styled.div`
  width: 200px;
`;

const BackArrow = styled.img`
  cursor: pointer;
  opacity: 0.8;
  height: 20px;

  :hover {
    opacity: 1;
  }
`;

const TabsPlaceholder = styled.div`
  flex: 1;
  background: white;
`;

const SettingsIcon = styled.img`
  height: 44px;
  width: 44px;
  cursor: pointer;
  opacity: 0.5;
  margin-right: 10px;
  border-radius: 1000px;

  :hover {
    opacity: 0.9;
  }
`;


const Left = styled.div`
  flex: 1;
  background-color: #8593A8;
`;

const Right = styled.div`
  width: ${props => (props.narrow == false ? `calc(100vh - 100px)` : '300px')};
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 100%;
  width: 100%;
  @media (orientation: portrait) {
    width: ${props => (props.narrow == false ? '30vw' : '300px')};
  }
`;

const ChatPlaceholder = styled.div`
  flex: 1;
  background: lightgrey;
  overflow-y: auto;
  overflow-x: hidden;
`;

const OuterWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
`;


function getFromLS(key) {
    let ls = {};
    if (window.localStorage) {
        try {
            ls = JSON.parse(window.localStorage.getItem("rgl-7")) || {};
        } catch (e) {
            /*Ignore*/
        }
    }
    return ls[key];
}

function saveToLS(key, value) {
    if (window.localStorage) {
        window.localStorage.setItem(
            "rgl-7",
            JSON.stringify({
                [key]: value
            })
        );
    }
}

function iOS() {
    return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}
