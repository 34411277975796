import CoursesAPI from '../../api/CoursesAPI'

import * as types from '../ActionTypes'

let loadCourses_ = () => {
    return {
        type: types.LOAD_COURSES
    }
}
let loadCoursesSuccess = (courses) => {
    return {
        type: types.LOAD_COURSES_SUCCESS,
        courses: courses
    }
}
let loadCoursesFailed = (error) => {
    return {
        type: types.LOAD_COURSES_FAIL,
        error: error
    }
}

//thunk
export function loadAllCourses() {
    return (dispatch, getState) => {
        dispatch(loadCourses_());
        return CoursesAPI.getAllCourses().then(
            courses => dispatch(loadCoursesSuccess(courses)),
            error => dispatch(loadCoursesFailed(error))
        )
    }
}

export function loadMyCourses() {
    return (dispatch, getState) => {
        let {currentUserId, usersMap} = getState().users;
        dispatch(loadCourses_());
        // return CoursesAPI.getAllCourses().then(
        return CoursesAPI.getStudentCourses(currentUserId).then(
            courses => dispatch(loadCoursesSuccess(courses)),
            error => dispatch(loadCoursesFailed(error))
        )
    }
}

export function loadCourseById(id) {
    return (dispatch, getState) => {
        dispatch(loadCourses_());
        return CoursesAPI.getCourse(id).then(
            course => dispatch(loadCoursesSuccess([course])),
            error => dispatch(loadCoursesFailed(error))
        )
    }
}

let createCourse_ = () => {
    return {
        type: types.CREATE_COURSE
    }
}
let createCourseSuccess = (course) => {
    return {
        type: types.CREATE_COURSE_SUCCESS,
        course: course
    }
}
let createCourseFailed = (error) => {
    return {
        type: types.CREATE_COURSE_FAIL,
        error: error
    }
}

//thunk
export function createCourse(data) {
    return (dispatch, getState) => {
        dispatch(createCourse_());
        return CoursesAPI.createCourse(data).then(
            course => dispatch(createCourseSuccess(course)),
            error => dispatch(createCourseFailed(error))
        )
    }
}

let updateCourse_ = () => {
    return {
        type: types.UPDATE_COURSE
    }
}
let updateCourseSuccess = (course) => {
    return {
        type: types.UPDATE_COURSE_SUCCESS,
        course: course
    }
}
let updateCourseFailed = (error) => {
    return {
        type: types.UPDATE_COURSE_FAIL,
        error: error
    }
}

//thunk
export function updateCourse(data) {
    return (dispatch, getState) => {
        dispatch(updateCourse_());
        return CoursesAPI.updateCourse(data).then(
            course => dispatch(updateCourseSuccess(course)),
            error => dispatch(updateCourseFailed(error))
        )
    }
}

let deleteCourse_ = () => {
    return {
        type: types.DELETE_COURSE
    }
}
let deleteCourseSuccess = (data) => {
    return {
        type: types.DELETE_COURSE_SUCCESS,
        data: data
    }
}
let deleteCourseFailed = (error) => {
    return {
        type: types.DELETE_COURSE_FAIL,
        error: error
    }
}

//thunk
export function deleteCourse(id) {
    return (dispatch, getState) => {
        dispatch(deleteCourse_());
        return CoursesAPI.deleteCourse(id).then(
            () => dispatch(deleteCourseSuccess(id)),
            error => dispatch(deleteCourseFailed(error))
        )
    }
}

//units

let loadUnits_ = () => {
    return {
        type: types.LOAD_UNITS
    }
}
let loadUnitsSuccess = (units) => {
    return {
        type: types.LOAD_UNITS_SUCCESS,
        units: units
    }
}
let loadUnitsFailed = (error) => {
    return {
        type: types.LOAD_UNITS_FAIL,
        error: error
    }
}

//thunk
export function loadCourseUnits(courseId) {
    return (dispatch, getState) => {
        dispatch(loadUnits_());
        return CoursesAPI.getCourseUnits(courseId).then(
            units => dispatch(loadUnitsSuccess(units)),
            error => dispatch(loadUnitsFailed(error))
        )
    }
}

//thunk
export function loadAllUnits() {
    return (dispatch, getState) => {
        dispatch(loadUnits_());
        return CoursesAPI.loadAllUnits().then(
            units => dispatch(loadUnitsSuccess(units)),
            error => dispatch(loadUnitsFailed(error))
        )
    }
}

export function loadMyUnits() {
    return (dispatch, getState) => {
        dispatch(loadUnits_());
        return CoursesAPI.getStudentUnits(getState().users.currentUserId).then(
            units => dispatch(loadUnitsSuccess(units)),
            error => dispatch(loadUnitsFailed(error))
        )
    }
}


let createUnit_ = () => {
    return {
        type: types.CREATE_UNIT
    }
}
let createUnitSuccess = (unit) => {
    return {
        type: types.CREATE_UNIT_SUCCESS,
        unit: unit
    }
}
let createUnitFailed = (error) => {
    return {
        type: types.CREATE_UNIT_FAIL,
        error: error
    }
}

//thunk
export function createUnit(data) {
    return (dispatch, getState) => {
        dispatch(createUnit_());
        return CoursesAPI.createUnit(data).then(
            unit => dispatch(createUnitSuccess(unit)),
            error => dispatch(createUnitFailed(error))
        )
    }
}

let updateUnit_ = () => {
    return {
        type: types.UPDATE_UNIT
    }
}
let updateUnitSuccess = (unit) => {
    return {
        type: types.UPDATE_UNIT_SUCCESS,
        unit: unit
    }
}
let updateUnitFailed = (error) => {
    return {
        type: types.UPDATE_UNIT_FAIL,
        error: error
    }
}

//thunk
export function updateUnit(data) {
    return (dispatch, getState) => {
        dispatch(updateUnit_());
        return CoursesAPI.updateUnit(data).then(
            unit => dispatch(updateUnitSuccess(unit)),
            error => dispatch(updateUnitFailed(error))
        )
    }
}

let deleteUnit_ = () => {
    return {
        type: types.DELETE_UNIT
    }
}
let deleteUnitSuccess = (id) => {
    return {
        type: types.DELETE_UNIT_SUCCESS,
        id: id
    }
}
let deleteUnitFailed = (error) => {
    return {
        type: types.DELETE_UNIT_FAIL,
        error: error
    }
}

//thunk
export function deleteUnit(id) {
    return (dispatch, getState) => {
        dispatch(deleteUnit_());
        return CoursesAPI.deleteUnit(id).then(
            () => dispatch(deleteUnitSuccess(id)),
            error => dispatch(deleteUnitFailed(error))
        )
    }
}
