import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import Sidebar from 'arui-feather/sidebar'

import './style.css'

import Chess from 'chess.js'
import useComponentSize from "@rehooks/component-size/index";

// const SAMPLE_START_FEN = 'r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R';
const SAMPLE_START_FEN = 'rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R b KQkq - 1 2';
const STARTING_FEN = 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1';

// const COOL_BG_IMAGE = 'https://www.englishpatient.org/english-patient-files/068b72bb-4d97-487f-a22a-5217b1c5de4a.png';
const COOL_BG_IMAGE = require('../../../assets/images/nice_shagi_dot.png');

const greySquare = (boardId, square) => {
    let squareEl = window.$(`#${boardId} .square-` + square);
    let background = '#bfdaf3';
    let backgroundImage = `url('${COOL_BG_IMAGE}')`;
    if (squareEl.hasClass('black-3c85d') === true) {
        background = '#9bb2c7';
    }
    // squareEl.css('background', background);
    squareEl.css('background-image', backgroundImage);
};

const removeGreySquares = (boardId) => {
    // window.$(`#${boardId} .square-55d63`).css('background', '');
    window.$(`#${boardId} .square-55d63`).css('background-image', 'none');
};

export default function PlayableSabirChessBoard(props) {
    const {
        shouldShowBottomInfo = true,
        startHistory = [],
        canMakeMoves = false,
        boardId = `simple_board`,
        fen = STARTING_FEN,
        orientation = 'white',
        canChangeOrientation = true,
        canGoBack = false,
        canViewHistory = false,
        allowIllegal = false,
        onOrientationChange = () => {

        },
        hasSettingsButton = false,

        onHistoryOpen = () => {

        },

        onSettingsClick = () => {

        },

        onGoBack = () => {

        },

        onMove = (newFen, newPgn, newHistory) => {
            console.log('onMove: newFen, newPgn, newHistory = ', newFen, newPgn, newHistory);
        }
    } = props;
    const [currentMoveColor, setCurrentMoveColor] = useState(fen == undefined || fen.split(' ').length < 2 ? undefined : fen.split(' ')[1].toLowerCase());

    const [historyVisible, setHistoryVisible] = useState(false);

    const board = useRef(null);
    const game = useRef(null);
    const ref = useRef(undefined);
    const size = useComponentSize(ref);
    console.log('render: size = ', size);
    useEffect(() => {
        console.log('PlayableSabirChessBoard: useEffect: fen = ', fen);
        game.current = new Chess(fen);
        for (let i in startHistory) {
            game.current.move(startHistory[i].san);
        }
        let updateStatus = () => {
            let status = '';
            let moveColor = 'w';
            if (game.current.turn() === 'b') {
                moveColor = 'b';
            }
            if (game.current.in_checkmate() === true) {
                status = 'Game over, ' + moveColor + ' is in checkmate.';
            }
            else if (game.current.in_draw() === true) {
                status = 'Game over, drawn position';
            }
            else {
                status = moveColor + ' to move';
                // check?
                if (game.current.in_check() === true) {
                    status += ', ' + moveColor + ' is in check';
                }
            }
            onMove(game.current.fen(), game.current.pgn(), game.current.history({verbose: true}));
            setCurrentMoveColor(moveColor);
            // statusEl.html(status);
            // fenEl.html(game.fen());
            // pgnEl.html(game.pgn());
        }
        board.current = new window.ChessBoard(boardId, {
            position: game.current.fen(),
            draggable: canMakeMoves,
            moveSpeed: 'slow',
            snapbackSpeed: 500,
            snapSpeed: 100,
            orientation: orientation,
            onDragStart: (source, piece, position, orientation) => {
                console.log('onDragStart occured! source, piece, position, orientation = ', source, piece, position, orientation);

                if (canMakeMoves == false) {
                    return false;
                }
                if (game.current.game_over() === true ||
                    (((game.current.turn() === 'w' && piece.search(/^b/) !== -1) ||
                        (game.current.turn() === 'b' && piece.search(/^w/) !== -1)) && (allowIllegal == false))
                ) {
                    return false;
                }
            },
            onDrop: (source, target) => {
                if (canMakeMoves == false) {
                    return;
                }
                let move = game.current.move({
                    from: source,
                    to: target,
                    promotion: 'q' // NOTE: always promote to a queen for example simplicity
                });
                // illegal move
                if ((move === null) && (allowIllegal == false)) return 'snapback';
                updateStatus();
            },
            onMouseoutSquare: (square, piece) => {
                if (canMakeMoves == false) {
                    return;
                }
                removeGreySquares(boardId);
            },
            onMouseoverSquare: (square, piece) => {
                if (canMakeMoves == false) {
                    return;
                }
                // console.log('onMouseoverSquare: square = ', square);
                let moves = game.current.moves({
                    square: square,
                    verbose: true
                });
                // console.log('moves = ', moves);
                if (moves.length === 0) return;
                greySquare(boardId, square);
                for (let i = 0; i < moves.length; i++) {
                    greySquare(boardId, moves[i].to);
                }
            },
            onSnapEnd: () => {
                if (canMakeMoves == false) {
                    return;
                }
                board.current.position(game.current.fen());
            }
        });
        setCurrentMoveColor(game.current.turn());
        return () => {
            try {
                board.current.destroy();
                console.log('board has been destroyed!');
            } catch (exc) {
                console.log('can not destroy board');
            }
        }
    }, [startHistory.length]);

    useEffect(() => {
        try {
            board.current.orientation(orientation);
        } catch (exc) {

        }
    }, [orientation]);

    return (
        <Wrapper ref={ref}>
            <BoardPlaceholder id={boardId} style={{
                width: size.width == undefined || size.width == 0 ? '100%' : size.width,
                height: size.width == undefined || size.width == 0 ? '100%' : size.width
            }}>

            </BoardPlaceholder>

            {shouldShowBottomInfo == false || currentMoveColor == undefined ? null :
                <BottomPlaceholder>
                    <div>
                        {`${{
                            'w': 'Ходят белые',
                            'b': 'Ходят черные'
                        }[currentMoveColor]}`}
                    </div>

                    <RightControlsPlaceholder>
                        {canChangeOrientation == false ? null :
                            <OrientationToggler onClick={() => {
                                onOrientationChange();
                            }}>
                                <ToggleImageButton src={require('../../../assets/images/exchange.svg')}/>
                            </OrientationToggler>
                        }

                        {canGoBack == false ? null :
                            <OrientationToggler onClick={() => {
                                onGoBack();
                            }}>
                                <ToggleImageButton src={require('../../../assets/images/return.svg')}/>
                            </OrientationToggler>
                        }

                        {hasSettingsButton == false ? null :
                            <OrientationToggler onClick={() => {
                                onSettingsClick();
                            }}>
                                <ToggleImageButton src={require('../../../assets/images/settings_a.svg')}/>
                            </OrientationToggler>
                        }

                        {canViewHistory == false ? null :
                            <OrientationToggler onClick={() => {
                                onHistoryOpen();
                            }}>
                                <ToggleImageButton src={require('../../../assets/images/note.svg')}/>
                            </OrientationToggler>
                        }

                    </RightControlsPlaceholder>

                </BottomPlaceholder>
            }


        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
`;

const BoardPlaceholder = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    //background: purple;
`;

const BottomPlaceholder = styled.div`
    box-sizing: border-box;
    padding: 5px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 15px;
`;

const OrientationToggler = styled.div`
    margin-left: 10px;
`;

const ToggleImageButton = styled.img`
    height: 18px;
    opacity: 0.5;
    cursor: pointer;
    :hover{
      opacity: 1;
    }
`;

const RightControlsPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;
