import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'



export default function ExerciseCopeViewTool(props) {
    const {solutions = [], copeMap = {}} = props;
    console.log('ExerciseCopeViewTool: render solutions, copeMap = ', solutions, copeMap);

    return (
        <Wrapper>
            {solutions.map((a, i) => {
                let isNotPassed = (copeMap[a.id] == undefined);
                let win = !isNotPassed && (copeMap[a.id].result == 'win');
                let lose = !isNotPassed && (copeMap[a.id].result == 'lose');
                let color = isNotPassed ? 'lightgrey' : (win ? 'green' : 'red');

                return (
                    <Circle key={`${i}_${a.id}`} color={color} >
                        {+i + 1}
                    </Circle>
                )
            })}

        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Circle = styled.div`
    margin: 5px;
    height: 32px;
    width: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    background-color: ${props => (props.color)};
    border: 1px solid grey;
    border-radius: 1000px;
`;
