/**
 * Created by sabir on 19.07.17.
 */

import {Map, Stack, Set} from 'immutable'
import * as types from '../ActionTypes.js'

const initialState = {
    loading: false,

    coursesMap: Map(),
    unitsMap: Map(),
    error: undefined

}

const startLoading = (state, action) => {
    return {...state, loading: true, error: undefined}
}

const stopLoading = (state, action) => {
    return {...state, loading: false, error: action.error}
}

const CoursesReducer = (state = initialState, action = {}) => {

    switch (action.type) {


        case types.CREATE_COURSE:
        case types.UPDATE_COURSE:
        case types.DELETE_COURSE:
        case types.LOAD_COURSES:
            return {
                ...state,
                loading: true,
                error: undefined
            }

        case types.CREATE_COURSE_FAIL:
        case types.UPDATE_COURSE_FAIL:
        case types.DELETE_COURSE_FAIL:
        case types.LOAD_COURSES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case types.CREATE_COURSE_SUCCESS:
        case types.UPDATE_COURSE_SUCCESS:
            return {
                ...state,
                loading: false,
                coursesMap: state.coursesMap.set(action.course.id, action.course)
            }


        case types.DELETE_COURSE_SUCCESS:
            return {
                ...state,
                loading: false,
                coursesMap: state.coursesMap.delete(action.id)
            }


        case types.LOAD_COURSES_SUCCESS:
            return {
                ...state,
                loading: false,
                coursesMap: state.coursesMap
                    .merge(action.courses.reduce((map, form) => map.set(form.id, form), Map()))
            }

        case types.CREATE_UNIT:
        case types.UPDATE_UNIT:
        case types.DELETE_UNIT:
        case types.LOAD_UNITS:
            return {
                ...state,
                loading: true,
                error: undefined
            }

        case types.CREATE_UNIT_FAIL:
        case types.UPDATE_UNIT_FAIL:
        case types.DELETE_UNIT_FAIL:
        case types.LOAD_UNITS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case types.CREATE_UNIT_SUCCESS:
        case types.UPDATE_UNIT_SUCCESS:
            return {
                ...state,
                loading: false,
                unitsMap: state.unitsMap.set(action.unit.id, action.unit)
            }


        case types.DELETE_UNIT_SUCCESS:
            return {
                ...state,
                loading: false,
                unitsMap: state.unitsMap.delete(action.id)
            }


        case types.LOAD_UNITS_SUCCESS:
            return {
                ...state,
                loading: false,
                unitsMap: state.unitsMap
                    .merge(action.units.reduce((map, form) => map.set(form.id, form), Map()))
            }

        default:
            return state;
    }

}

export default CoursesReducer;
