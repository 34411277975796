import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import HorChessTemplate from "../templates/HorChessTemplate";

import {useDispatch, useMappedState} from "redux-react-hook/dist/index";
import AdminTeacherPanel from "../teachers/panels/AdminTeacherPanel";
import CommonHelper from "../../helpers/CommonHelper";
import JitsiPanel from "../jitsi/panels/JitsiPanel";
import RealtimeBoard from "../realtime/board/RealtimeBoard";
import SimpleRealtimeBoard from "../realtime/board/SimpleRealtimeBoard";

export default function SharedBoardApp(props) {
    let {room} = props.match.params;

    return (
        <Wrapper>
            <TopPlaceholder>

                <TopLeft>
                    <TopName>

                        <LogoImage style={{marginRight: 10}}
                                   src={require('../../assets/images/wide_named_logo_shagi_only.png')}
                                   onClick={() => {
                                       CommonHelper.linkTo(`/`)
                                   }}
                        />


                    </TopName>
                </TopLeft>

                <TopRight>

                </TopRight>


            </TopPlaceholder>
            <ContentPlaceholder>
                <BoardPlaceholder>
                    {/*<RealtimeBoard boardId={room} adminMode={true} />*/}
                    <SimpleRealtimeBoard boardId={room} adminMode={true} />
                </BoardPlaceholder>
            </ContentPlaceholder>
        </Wrapper>
    );
}

const topHeight = 40;

const Heading = styled.div`
    height: 60px;
    width: 100%;
    background: white;
`;


const TopPlaceholder = styled.div`
    height: ${topHeight}px;
    width: 100%;
    background: white;
    box-sizing: border-box;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: space-between;
    border-bottom: 1px solid whitesmoke;
`;


const ContentPlaceholder = styled.div`
    height: calc(100% - ${topHeight}px);
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const BoardPlaceholder = styled.div`
    height: calc(100vh - ${topHeight}px - 10px);
    width: calc(100vh - ${topHeight}px  - 10px);
`;

const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    background: whitesmoke;
`;

const TopLeft = styled.div`
    
`;

const TopRight = styled.div`
    
`;


const TopName = styled.div`
    font-weight: bold;
    font-size: 16px;
    flex-direction: row;
    display: flex;
    align-items: center;
`;

const BackArrowImage = styled.img`
    height: 18px;
    cursor: pointer;
    opacity: 0.5;
    :hover{
      opacity: 1;
    }
`;

const LogoImage = styled.img`
    height: 40px;
    cursor: pointer;
    opacity: 0.8;
    :hover{
      opacity: 1;
    }
`;
