import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import SmartPgnViewer from "../../chess/view/smart/SmartPgnViewer";

import './style.css'

import {Map, Set} from 'immutable'
import ChessHelper from "../../../helpers/ChessHelper";
import {PgnViewerWrapper, Chessboard} from "../../rubius";
import YoutubePlayer from "../../players/YoutubePlayer";

export default function UnitView(props) {
    let {
        items = [], canEdit = true, selectedItemId, onItemSelect = () => {

        }, onMove = (itemId, i1, i2) => {

        },
        currentUserId = undefined,
        autoSendFen = false,
        canSendFen = true,
        canSelectPgn = false,
        onFenSend = fFen => {

        },
        onSelectPgn = (p) => {

        }
    } = props;
    console.log('UnitView: render: items = ', items);
    const [openSet, setOpenSet] = useState(items.reduce((st, it) => st.add(it.id), Set()));
    const [currFen, setCurrFen] = useState(undefined);

    console.log('unit view: render: items = ', items);

    return (
        <Wrapper className={'my_unit_view'}>

            <ItemsList>
                {items.map((item, k) => {
                    let {id, text, type, videoUrl, imageUrl, pieces = [], pgn} = item;
                    let isOpenEye = openSet.has(id);
                    return (
                        <Item key={`item_${id}_${k}`}>

                            <ControlsPlaceholder>

                                {canSelectPgn == false ? null :
                                    <MiniInlineImg
                                        onClick={() => {
                                            onSelectPgn(pgn);
                                        }}
                                        src={require('../../../assets/images/fan.svg')}/>
                                }

                                {type != 'chess' ? null :
                                    <MiniInlineImg
                                        onClick={() => {
                                            if (isOpenEye == true) {
                                                setOpenSet(openSet.delete(id));
                                            } else {
                                                setOpenSet(openSet.add(id));
                                            }
                                        }}
                                        src={(isOpenEye == true) ? require('../../../assets/images/eye_opened.svg') : require('../../../assets/images/eye_closed.svg')}/>
                                }

                                {/*{(canSendFen != true || type != 'chess') ? null :*/}
                                {/*<MiniInlineImg*/}
                                {/*onClick={() => {*/}
                                {/*if (currFen == undefined) {*/}
                                {/*onFenSend(ChessHelper.getFenFromPgn(pgn))*/}
                                {/*return;*/}
                                {/*}*/}
                                {/*onFenSend(currFen);*/}
                                {/*}}*/}
                                {/*src={require('../../../assets/images/fan.svg')}/>*/}
                                {/*}*/}


                                {canEdit == false ? null :
                                    <span>

                                        <span style={{cursor: 'pointer'}} onClick={() => {
                                            onItemSelect(id);
                                        }}>
                                            📝
                                        </span>
                                        {k < items.length - 1 ?
                                            <ArrowSpan style={{padding: 3}} onClick={() => {
                                                let i1 = k, i2 = k + 1;
                                                onMove(id, i1, i2);
                                            }}>
                                                v
                                            </ArrowSpan> : null
                                        }
                                        {k == 0 ? null :
                                            <ArrowSpan style={{padding: 3}} onClick={() => {
                                                let i1 = k - 1, i2 = k;
                                                onMove(id, i1, i2);
                                            }}>
                                                ^
                                            </ArrowSpan>
                                        }
                                    </span>
                                }
                            </ControlsPlaceholder>


                            {type != 'text' ? null :
                                <TextSection>
                                    <div dangerouslySetInnerHTML={{__html: text}}></div>
                                </TextSection>

                            }

                            {type != 'video' ? null :
                                <VideoPlaceholder>
                                    <VideoInner>
                                        {videoUrl.indexOf('youtube') == -1 ?
                                            <Video controls={true} key={videoUrl}>
                                                <source src={videoUrl}/>
                                            </Video>
                                            :
                                            <>
                                                {/*<YoutubePlayer youtubeUrl={videoUrl} />*/}
                                                <Iframe
                                                    width={'100%'}
                                                    height={'100%'}
                                                    src={ChessHelper.getYoutubeEmbedUrl(videoUrl)} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="1" ></Iframe>
                                            </>

                                        }

                                    </VideoInner>
                                </VideoPlaceholder>
                            }

                            {type != 'image' ? null :
                                <ImagePlaceholder>
                                    <ImagePlaceholder src={imageUrl}/>
                                </ImagePlaceholder>
                            }

                            {/*{type != 'chess' ? null :*/}
                            {/*<SmartPgnViewer*/}
                            {/*boardId={`item${k}_${item.id}`}*/}
                            {/*currentUserId={currentUserId}*/}
                            {/*pgnString={pgn}*/}
                            {/*showHistory={isOpenEye}*/}
                            {/*onPositionChange={newFen => {*/}
                            {/*console.log('newFen = ', newFen);*/}
                            {/*if (autoSendFen == true) {*/}
                            {/*onFenSend(newFen);*/}
                            {/*} else {*/}
                            {/*setCurrFen(newFen);*/}
                            {/*}*/}
                            {/*}}*/}
                            {/*/>*/}
                            {/*}*/}

                            {type != 'chess' ? null :
                                <RubChessPlaceholder>
                                    <PgnViewerWrapper
                                        boardWidthFraction={0.5}
                                        pgn={pgn}>
                                        <Chessboard/>
                                    </PgnViewerWrapper>
                                </RubChessPlaceholder>
                            }

                        </Item>
                    )
                })}
            </ItemsList>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const RubChessPlaceholder = styled.div`
    width: 100%;
    //min-height: 200px;
    box-sizing: border-box;
    overflow: hidden;
    margin-bottom: 10px;
    //height: calc(100vh - 300px);
`;

const ItemsList = styled.div`
    
`;

const Item = styled.div`
    position: relative;
`;

const ControlsPlaceholder = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 1;
`;

const TextSection = styled.div`
    
`;


const VideoPlaceholder = styled.div`
    display: block;
    width: 100%;
    background: whitesmoke;
    position: relative;
`;

const VideoInner = styled.div`
    display: block;
    height: 420px;
    margin: 0 auto;
    width: 720px;
    @media(max-width: 1020px){
      width: 100%;
    }
`;

const Video = styled.video`
    width: 100%;
    height: 100%;
`;

const Iframe = styled.iframe`
    width: 100%;
    height: 100%;
    border: none;
`;

const ImagePlaceholder = styled.div`
    width: 100%;
    height: 420px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url('${props => props.src}');
    position: relative;
    margin-bottom: 10px;
    margin-top: 10px;
    background-color: whitesmoke;
`;

const BoardPlaceholder = styled.div`
    width: 100%;
    height: 420px;
`;

const ArrowSpan = styled.span`
    padding: 3px;
    cursor: pointer;
    :hover{
      opacity: 0.7;
    }
`;

const MiniInlineImg = styled.img`
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    width: 14px;
    height: 14px;
    margin-top: 4px;
    cursor: pointer;
    opacity: 0.8;
    :hover{
      opacity: 1;
    }
`;
