import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import UnitView from '../view/UnitView'

import Sidebar from 'arui-feather/sidebar'

import {Button, OutlinedButton} from "../../ui/Button";
import {Textarea} from "../../ui/Textarea";

import HtmlEditor from '../editors/HtmlEditor'
import Spin from 'arui-feather/spin'
import SimpleUploadPanel from "../../upload/SimpleUploadPanel";
import NameDescriptionForm from "../../common/forms/NameDescriptionForm";
import SimplePgnEditor from "./SimplePgnEditor";
import VideoUrlSelectorTool from "../tools/VideoUrlSelectorTool";


export default function UnitItemsEditor(props) {
    let {
        items = [], loading = false, onChange = newItems => {
        }, canEdit = true,
        currentUserId = undefined,
        autoSendFen = false,
        onFenSend = fF => {

        }
    } = props;
    const [visibleMode, setVisibleMode] = useState(undefined);
    const [selectedItemId, setSelectedItemId] = useState(undefined);
    let selectedItem = (selectedItemId == undefined) ? undefined : items.filter(it => (it.id == selectedItemId))[0];

    const updateItem = d => {
        let updatedItems = items.map((it, k) => {
            if (it.id == selectedItemId) {
                return {
                    ...selectedItem,
                    ...d
                }
            } else {
                return it;
            }
        });
        onChange(updatedItems);
        setTimeout(() => {
            setSelectedItemId(undefined);
        }, 100);
    }

    return (
        <Wrapper>

            <UnitViewPlaceholder>
                <UnitView items={items} canEdit={canEdit}
                          currentUserId={currentUserId}
                          onItemSelect={itemId => {
                              console.log('onItemSelect: itemId = ', itemId);
                              setSelectedItemId(itemId);
                          }}
                          onMove={(itemId, i1, i2) => {
                              let newItems = items.slice(0, i1).concat(items[i2], items.slice(i1 + 1, i2), items[i1], items.slice(i2 + 1));
                              onChange(newItems);
                          }}
                          canSendFen={true}
                          autoSendFen={autoSendFen}
                          onFenSend={fFen => {
                              onFenSend(fFen);
                          }}
                />
            </UnitViewPlaceholder>

            {canEdit == false ? null :
                <BottomPlaceholder>
                    <OutlinedButton onClick={() => {
                        setVisibleMode('text');
                    }}>
                        + текст
                    </OutlinedButton>
                    <OutlinedButton onClick={() => {
                        setVisibleMode('image');
                    }}>
                        + картинка
                    </OutlinedButton>
                    <OutlinedButton onClick={() => {
                        setVisibleMode('video');
                    }}>
                        + видео
                    </OutlinedButton>
                    <OutlinedButton onClick={() => {
                        setVisibleMode('chess');
                    }}>
                        + доска
                    </OutlinedButton>
                </BottomPlaceholder>
            }


            <Sidebar width={720} visible={(visibleMode != undefined)} onCloserClick={() => {
                setVisibleMode(undefined);
            }}>

                {visibleMode == undefined ? null :
                    <div>
                        {visibleMode != 'text' ? null :
                            <div>
                                <HtmlEditor loading={loading} onSubmit={text => {
                                    let item = {
                                        id: uuid(),
                                        text: text,
                                        type: 'text'
                                    };
                                    onChange(items.concat([item]));
                                }}/>
                            </div>
                        }
                        {visibleMode != 'image' ? null :
                            <div>
                                <SimpleUploadPanel onUploaded={url => {
                                    let item = {
                                        id: uuid(),
                                        imageUrl: url,
                                        type: 'image'
                                    };
                                    onChange(items.concat([item]));
                                    setVisibleMode(undefined);
                                }}>
                                    <UploadPlaceholder>
                                        загрузить картинку
                                    </UploadPlaceholder>
                                </SimpleUploadPanel>
                            </div>
                        }
                        {visibleMode != 'video' ? null :
                            <div>

                                {/*<SimpleUploadPanel onUploaded={url => {*/}
                                {/*let item = {*/}
                                {/*id: uuid(),*/}
                                {/*videoUrl: url,*/}
                                {/*type: 'video'*/}
                                {/*};*/}
                                {/*onChange(items.concat([item]));*/}
                                {/*setVisibleMode(undefined);*/}
                                {/*}}>*/}
                                {/*<UploadPlaceholder>*/}
                                {/*загрузить видео (в формате .mp4)*/}
                                {/*</UploadPlaceholder>*/}
                                {/*</SimpleUploadPanel>*/}

                                <VideoUrlSelectorTool onChange={url => {
                                    let item = {
                                        id: uuid(),
                                        videoUrl: url,
                                        type: 'video'
                                    };
                                    onChange(items.concat([item]));
                                    setVisibleMode(undefined);
                                }}/>

                            </div>
                        }
                        {visibleMode != 'chess' ? null :
                            <div>
                                <SimplePgnEditor onSubmit={(pgn) => {
                                    let item = {
                                        id: uuid(),
                                        pgn: pgn,
                                        type: 'chess'
                                    };
                                    onChange(items.concat([item]));
                                    setVisibleMode(undefined);
                                }}/>
                            </div>
                        }
                    </div>
                }

            </Sidebar>

            <Sidebar width={720} visible={(selectedItemId != undefined)} onCloserClick={() => {
                setSelectedItemId(undefined);
            }}>

                {selectedItem == undefined ? null :
                    <div>
                        {selectedItem.type != 'text' ? null :
                            <div>
                                <HtmlEditor loading={loading} html={selectedItem.text} onSubmit={text => {
                                    updateItem({text: text});
                                }}/>
                            </div>
                        }

                        {selectedItem.type != 'image' ? null :
                            <div>
                                <div>
                                    <Img src={selectedItem.imageUrl}/>
                                    <SimpleUploadPanel onUploaded={url => {
                                        let newItems = items.map(a => (a.id == selectedItem.id ? ({
                                            ...a,
                                            imageUrl: url
                                        }) : a));
                                        onChange(newItems);
                                    }}>
                                        <UploadPlaceholder>
                                            загрузить другую картинку
                                        </UploadPlaceholder>
                                    </SimpleUploadPanel>
                                </div>

                            </div>
                        }

                        {selectedItem.type != 'video' ? null :
                            <div>
                                {/*<Video controls={true} key={selectedItem.videoUrl}>*/}
                                {/*<source src={selectedItem.videoUrl}/>*/}
                                {/*</Video>*/}
                                {/*<SimpleUploadPanel onUploaded={url => {*/}
                                {/*let newItems = items.map(a => (a.id == selectedItem.id ? ({*/}
                                {/*...a,*/}
                                {/*videoUrl: url*/}
                                {/*}) : a));*/}
                                {/*onChange(newItems);*/}
                                {/*}}>*/}
                                {/*<UploadPlaceholder>*/}
                                {/*загрузить другое видео (в формате .mp4)*/}
                                {/*</UploadPlaceholder>*/}
                                {/*</SimpleUploadPanel>*/}

                                <VideoUrlSelectorTool url={selectedItem.videoUrl}
                                                      onChange={u => {
                                                          console.log('VideoUrlSelectorTool: onChange: u = ', u);
                                                          let newItems = items.map(a => (a.id == selectedItem.id ? ({
                                                              ...a,
                                                              videoUrl: u
                                                          }) : a));
                                                          console.log('newItems = ', newItems);
                                                          onChange(newItems);
                                                      }}/>

                            </div>
                        }

                        {selectedItem.type != 'chess' ? null :
                            <div>
                                <SimplePgnEditor pgn={selectedItem.pgn} onSubmit={(pgn) => {
                                    let newItems = items.map(a => (a.id == selectedItem.id ? ({
                                        ...a,
                                        pgn: pgn
                                    }) : a));
                                    onChange(newItems);
                                }}/>
                            </div>
                        }

                        <DangerPlaceholder>
                            <DangerSpan onClick={() => {
                                if (window.confirm('Вы уверены?') == false) {
                                    return;
                                }
                                let newItems = items.filter(a => (a.id != selectedItem.id));
                                setSelectedItemId(undefined);
                                onChange(newItems);
                            }}>
                                Удалить
                            </DangerSpan>
                        </DangerPlaceholder>

                    </div>
                }

            </Sidebar>


        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const UnitViewPlaceholder = styled.div`
    
`;

const BottomPlaceholder = styled.div`
    text-align: center;
`;

const UploadPlaceholder = styled.div`
    padding: 20px;
    border-radius: 4px;
    background: whitesmoke;
    text-align: center;
    margin-top: 10px;
    :hover{
      opacity: 0.7;
    }
`;

const Video = styled.video`
    background: white;
    height: 300px;
    width: 100%;
`;

const Img = styled.img`
    height: 300px;
    display: block;
    margin: 0 auto;
`;

const DangerSpan = styled.span`
    color: red;
    text-decoration: underline;
    cursor: pointer;
    :hover{
      opacity: 0.7;
    }
`;

const DangerPlaceholder = styled.div`
    padding: 5px;
    text-align: center;
`;
