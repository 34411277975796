import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

// import {AreaChart, XAxis, YAxis, CartesianGrid, Area, Tooltip,} from 'recharts'

import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Area, AreaChart
} from 'recharts';

import EloHelper from '../../../helpers/EloHelper'

export default function SimpleRatingChart(props) {
    const {
        points = [],
        baseRating = 1000
    } = props;

    console.log('SimpleRatingChart: render: points = ', points);

    let dailyRatingArr = EloHelper.getDailyEloRating(points, baseRating);

    console.log('SimpleRatingChart: render: dailyRatingArr = ', dailyRatingArr);

    return (
        <Wrapper>

            <AreaChart width={800} height={380} data={dailyRatingArr}
                       margin={{top: 10, right: 30, left: 0, bottom: 0}}>
                {/*<defs>*/}
                {/*    <linearGradient id="colorNum" x1="0" y1="0" x2="0" y2="1">*/}
                {/*        <stop offset="5%" stopColor="#327CDC" stopOpacity={0.8}/>*/}
                {/*        <stop offset="95%" stopColor="#8884d8" stopOpacity={0.1}/>*/}
                {/*    </linearGradient>*/}
                {/*</defs>*/}

                <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="0%" stopColor="#8884d8" stopOpacity={0.8}/>
                        <stop offset="95%" stopColor="#8884d8" stopOpacity={0.1}/>
                    </linearGradient>
                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="0%" stopColor="#82ca9d" stopOpacity={0.8}/>
                        <stop offset="95%" stopColor="#82ca9d" stopOpacity={0.1}/>
                    </linearGradient>
                </defs>

                <XAxis dataKey="t" tickFormatter={(t) => {
                    return moment(t).format('DD.MM');
                }}/>

                <Tooltip labelFormatter={t => moment(t).format('DD.MM.YYYY')}/>

                <YAxis/>

                {/*<Line type="monotone" dataKey="rating"*/}
                {/*      strokeWidth={3}*/}
                {/*      stroke="#8884d8" activeDot={{r: 4}}/>*/}

                <Area type="monotone" dataKey="rating" strokeWidth={3} stroke="#018137" fillOpacity={1} fill="url(#colorPv)" />


                {/*<Area type="monotone" dataKey="rating" stroke="#8884d8" fillOpacity={1} fill="url(#colorNum)"/>*/}

            </AreaChart>

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;
