import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import HorChessTemplate from "../templates/HorChessTemplate";
import {useDispatch, useMappedState} from "redux-react-hook";

import TeacherGroupPanel from "../teacher_groups/panels/TeacherGroupPanel";

import Tabs, {TabItem} from "../ui/Tabs";
import IraTeacherTemplate from "../templates/IraTeacherTemplate";

export default function TeacherGroupApp(props) {
    let {id} = props.match.params;

    return (
        <IraTeacherTemplate active={`group/${id}`}>

            <Wrapper>
                <TeacherGroupPanel id={id}/>
            </Wrapper>

        </IraTeacherTemplate>
    );
}

const Wrapper = styled.div`
    padding-top: 31px;
    padding-left: 65px;
    padding-right: 65px;
    box-sizing: border-box;
    @media(max-width: 1300px){
      padding-left: 15px;
      padding-right: 15px;
    }
`;
