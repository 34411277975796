import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import {Button} from "../../ui/Button";

import Spin from 'arui-feather/spin'
import {translate} from "../../../utils/Translate";

export default function HtmlEditor(props) {
    let {
        html = '', loading = false, onSubmit = () => {
        }
    } = props;
    const [text, setText] = useState(html);

    return (
        <Wrapper>
            <QuillPlaceholder>
                <ReactQuill value={text}
                            onChange={txt => {
                                setText(txt);
                            }}/>
            </QuillPlaceholder>

            <BottomPlaceholder>
                <Button onClick={() => {
                    console.log('onSubmit occured!');
                    onSubmit(text);
                }}>
                    <Spin visible={loading}/>
                    {loading == true ? null :
                        <span>
                            {translate('Save')}
                        </span>
                    }

                </Button>
            </BottomPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const QuillPlaceholder = styled.div`
    
`;

const BottomPlaceholder = styled.div`
    
`;
