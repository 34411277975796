import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'

import {CopyToClipboard} from 'react-copy-to-clipboard';

export default function ClipToolSpan(props) {
    let {text = 'sabir', copiedLabel = 'Скопировано в буфер обмена'} = props;
    let [copied, setCopied] = useState(false);

    return (
        <Wrapper>
            <CopyToClipboard text={text} onCopy={() => {
                setCopied(true);
            }}>
                <InnerSpan copied={copied}>
                    {props.children}
                </InnerSpan>
            </CopyToClipboard>
            {copied == false ? null :
                <CopiedSpan>
                    {copiedLabel}
                </CopiedSpan>
            }
        </Wrapper>
    );
}

const Wrapper = styled.span`
    
`;

const CopiedSpan = styled.span`
    color: green;
    margin-left: 10px;
`;

const InnerSpan = styled.span`
    opacity: ${props => (props.copied == true ? 0.5 : 1)};
    font-style: ${props => (props.copied == true ? 'italic' : 'normal')};
    cursor: pointer;
    text-decoration: underline;
    :hover{
      opacity: 0.5;
    }
`;
