/**
 * Created by sabir on 19.07.17.
 */

import {Map, Stack, Set} from 'immutable'
import * as types from '../ActionTypes.js'

const initialState = {
    loading: false,

    schoolsMap: Map(),
    error: undefined

}

const startLoading = (state, action) => {
    return { ...state, loading: true, error: undefined}
}

const stopLoading = (state, action) => {
    return { ...state, loading: false, error: action.error}
}

const SchoolsReducer =  (state = initialState, action = {}) => {

    switch (action.type) {


        case types.CREATE_SCHOOL:
        case types.UPDATE_SCHOOL:
        case types.DELETE_SCHOOL:
        case types.LOAD_SCHOOLS:
            return {
                ...state,
                loading: true,
                error: undefined
            }

        case types.CREATE_SCHOOL_FAIL:
        case types.UPDATE_SCHOOL_FAIL:
        case types.DELETE_SCHOOL_FAIL:
        case types.LOAD_SCHOOLS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case types.CREATE_SCHOOL_SUCCESS:
        case types.UPDATE_SCHOOL_SUCCESS:
            return {
                ...state,
                loading: false,
                schoolsMap: state.schoolsMap.set(action.school.id, action.school)
            }


        case types.DELETE_SCHOOL_SUCCESS:
            return {
                ...state,
                loading: false,
                schoolsMap: state.schoolsMap.delete(action.id),
            }



        case types.LOAD_SCHOOLS_SUCCESS:
            return {
                ...state,
                loading: false,
                schoolsMap: state.schoolsMap
                    .merge(action.schools.reduce((map, form) => map.set(form.id, form), Map()))
            }


        default:
            return state;
    }

}

export default SchoolsReducer;
