import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import HorChessTemplate from "../templates/HorChessTemplate";

import {Container} from "../ui/Container";

import UnderConstructionPanel from '../construction/UnderConstructionPanel'
import TeacherPanel from "../teachers/panels/TeacherPanel";
import CommonHelper from "../../helpers/CommonHelper";
import {useDispatch, useMappedState} from "redux-react-hook/dist/index";
import AdminTeacherPanel from "../teachers/panels/AdminTeacherPanel";

export default function TeacherApp(props) {
    let {id} = props.match.params;

    // let {currentUser, loading, teacher, userRole} = useMappedState(useCallback(state => {
    //     let teacher = state.schools.schoolsMap.get(id);
    //     let currentUser = state.users.usersMap.get(state.users.currentUserId);
    //     let userRole = (currentUser == undefined) ? undefined : currentUser.userRole;
    //     return {
    //         currentUser: currentUser,
    //         loading: state.users.loading,
    //         teacher: teacher,
    //         userRole: userRole
    //     }
    // }, [id]));

    return (
        <HorChessTemplate active={`teacher/${id}`}>

            <AdminTeacherPanel id={id}/>

            {/*<TeacherPanel id={id}/>*/}

        </HorChessTemplate>
    );
}

const Heading = styled.div`
    
`;

