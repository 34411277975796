import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import SimpleFenEditor from "../../realtime/tools/SimpleFenEditor";

import CommonHelper from '../../../helpers/CommonHelper'
import ChessHelper from "../../../helpers/ChessHelper";
import UpdatePairTool from "./UpdatePairTool";
import LessonPairsPanel from "../panels/LessonPairsPanel";


const START_FEN = 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1';


export default function WarmupEditorTool(props) {
    const {
        studentId,
        users = [], fen, lessonId,
        onAdd = a => {

        },
        onPairsChange = newPairs => {

        },
        loading = false,
        pairs: lessonPairs = []
    } = props;
    const [commonFen, setCommonFen] = useState(props.fen == undefined ? START_FEN : props.fen);
    const [tab, setTab] = useState((studentId == undefined) ? 'new' : 'pairs');

    const fenUrl = ChessHelper.getFenImageUrl(commonFen);

    let canEdit = (studentId == undefined);

    return (
        <Wrapper>

            {canEdit == false ? null :
                <TopPlaceholder>
                    <TabsPlaceholder>
                        <TabItem selected={(tab == 'new')} onClick={() => {
                            setTab('new');
                        }}>
                            Создать партию
                        </TabItem>
                        <TabItem selected={(tab == 'pairs')} onClick={() => {
                            setTab('pairs');
                        }}>
                            Все партии
                        </TabItem>
                    </TabsPlaceholder>
                </TopPlaceholder>
            }

            <ContentPlaceholder>

                {tab != 'new' ? null :
                    <BoardEditorPlaceholder>
                        <BoardEditorInnerPlaceholder>
                            <BoardImagePlaceholder>
                                <BoardImage src={fenUrl}/>
                                <div style={{width: 300}}>
                                    <UpdatePairTool
                                        loading={loading}
                                        users={users}
                                        onAdd={d => {
                                            let dd = {
                                                ...d,
                                                id: uuid(),
                                                t: +new Date(),
                                                fen: commonFen,
                                                active: true,
                                                updT: +new Date()
                                            };
                                            onAdd(dd, () => {
                                                setTab('pairs');
                                            });
                                        }}/>
                                </div>
                            </BoardImagePlaceholder>
                        </BoardEditorInnerPlaceholder>
                    </BoardEditorPlaceholder>
                }

                {tab != 'pairs' ? null :
                    <PairsListPlaceholder key={lessonPairs.map(a => `${a.updT}`).join('_')}>
                        <LessonPairsPanel
                            users={users} id={lessonId}
                            studentId={studentId}
                            onChange={newPairs => {
                                onPairsChange(newPairs);
                            }}/>
                    </PairsListPlaceholder>
                }

            </ContentPlaceholder>

        </Wrapper>
    );
}

const PairsListPlaceholder = styled.div`
    
`;

const BoardImage = styled.img`
    width: 240px;
    height: 240px;
`;

const BoardImagePlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    justify-content: center;
`;

const PairItem = styled.div`
    margin-bottom: 5px;
`;

const Wrapper = styled.div`
    padding: 5px;
`;

const BoardEditorPlaceholder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const BoardEditorInnerPlaceholder = styled.div`
    width: 720px;
`;

const ContentPlaceholder = styled.div`
    box-sizing: border-box;
    padding: 20px;
`;


const TopPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const TabsPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(31, 32, 65, 0.25);
    box-sizing: border-box;
    border-radius: 4px;
`;

const TabItem = styled.div`
    height: 60px;
    padding-left: 35px;
    padding-right: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background: ${props => (props.selected == true ? '#A36EFF' : 'white')};
    color: ${props => (props.selected == true ? 'white' : 'black')};
    cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
    font-size: 18px;
    line-height: 24px;
`;
