import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import {Button} from "../../ui/Button";
import {Textarea} from "../../ui/Textarea";
import {Input} from "../../ui/Input";
import SimpleUploadPanel from "../../upload/SimpleUploadPanel";

const ie = a => (a == undefined || `${a}`.trim() == '');

export default function ExerciseResultForm(props) {
    const {
        onSave = dd => {

        },
        loading = false
    } = props;

    const [finalVideoUrl, setFinalVideoUrl] = useState(props.finalVideoUrl == undefined ? '' : props.finalVideoUrl);
    const [finalAudioUrl, setFinalAudioUrl] = useState(props.finalAudioUrl == undefined ? '' : props.finalAudioUrl);
    const [finalHtml, setFinalHtml] = useState(props.finalHtml == undefined ? '' : props.finalHtml);
    const [finalText, setFinalText] = useState(props.finalText == undefined ? '' : props.finalText);
    const [finalImageUrl, setFinalImageUrl] = useState(props.finalImageUrl == undefined ? '' : props.finalImageUrl);

    useEffect(() => {
        onSave({
            finalText: finalText,
            finalImageUrl: finalImageUrl,
            finalVideoUrl: finalVideoUrl,
            finalAudioUrl: finalAudioUrl,
            finalHtml: finalHtml
        });
    }, [finalImageUrl, finalText, finalHtml, finalAudioUrl, finalVideoUrl]);

    return (
        <Wrapper>


            <Field>
                <Label>
                    Финальный текст
                </Label>
                <Input value={finalText}
                       onChange={evt => {
                           let txt = evt.target.value;
                           setFinalText(txt);
                       }}/>
            </Field>

            <Field>
                <Label>
                    Финальная картинка
                </Label>
                {ie(finalImageUrl) ? null :
                    <>
                    <img style={{height: 80}}
                         src={finalImageUrl}/>
                    <div>
                        <DelSpan onClick={() => {
                            setFinalImageUrl('');
                        }}>
                            удалить
                        </DelSpan>
                    </div>
                    </>
                }
                <SimpleUploadPanel onUploaded={u => {
                    setFinalImageUrl(u);
                }}>
                    <div>
                        загрузить картинку
                    </div>
                </SimpleUploadPanel>
            </Field>

            <Field>
                <Label>
                    Финальное видео
                </Label>
                {ie(finalVideoUrl) ? null :
                    <>
                    <video controls={true} style={{height: 200}}>
                        <source src={finalVideoUrl}/>
                    </video>
                    <div>
                        <DelSpan onClick={() => {
                            setFinalVideoUrl('');
                        }}>
                            удалить
                        </DelSpan>
                    </div>
                    </>

                }
                <SimpleUploadPanel onUploaded={u => {
                    setFinalVideoUrl(u);
                }}>
                    <div>
                        загрузить видео
                    </div>
                </SimpleUploadPanel>
            </Field>

            <Field>
                <Label>
                    Финальное аудио
                </Label>
                {ie(finalAudioUrl) ? null :
                    <>
                    <audio controls={true}>
                        <source src={finalAudioUrl}/>
                    </audio>
                    <div>
                        <DelSpan onClick={() => {
                            setFinalAudioUrl('');
                        }}>
                            удалить
                        </DelSpan>
                    </div>
                    </>
                }
                <SimpleUploadPanel onUploaded={u => {
                    // setFormData(ps => ({...ps.formData, finalAudioUrl: u}));
                    setFinalAudioUrl(u);
                }}>
                    <div>
                        загрузить аудио
                    </div>
                </SimpleUploadPanel>
            </Field>

            <Field>
                <Label>
                    Финальный HTML
                </Label>
                <Textarea value={finalHtml}
                          placeholder={'Вставить HTML'}
                          style={{height: `20em`, minHeight: `20em`}}
                          onChange={evt => {
                              let txt = evt.target.value;
                              setFinalHtml(txt);
                          }}/>
            </Field>

            {/*<Field>*/}
                {/*{loading == true ?*/}
                    {/*<Spin visible={loading}/>*/}
                    {/*:*/}
                    {/*<Button onClick={() => {*/}
                        {/*onSave({*/}
                            {/*finalText: finalText,*/}
                            {/*finalImageUrl: finalImageUrl,*/}
                            {/*finalVideoUrl: finalVideoUrl,*/}
                            {/*finalAudioUrl: finalAudioUrl,*/}
                            {/*finalHtml: finalHtml*/}
                        {/*});*/}
                    {/*}}>*/}
                        {/*Save*/}
                    {/*</Button>*/}
                {/*}*/}

            {/*</Field>*/}

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;


const Field = styled.div`
    margin-bottom: 10px;
`;

const Label = styled.div`
    font-weight: bold;
`;

const DelSpan = styled.span`
    cursor: pointer;
    color: red;
    :hover{
      opacity: 0.5;
    }
`;
