import React, {useState, useEffect, useRef} from "react";
import ChessboardUI from './chessboard-ui';
import Timer from './Timer/Timer';
import Sidebar from 'arui-feather/sidebar';
import useChessTimer from './Timer/useChessTimer';
import ChessboardHelper from './ChessboardHelper';
import styled from 'styled-components';
import * as Chess from './lib/chess';
import * as IllegalChess from './lib/illegalChess';
import {forwardRef} from "react";
import {useImperativeHandle} from "react";
import NiceModal from "../../modals/NiceModal";

const Chessboard = forwardRef((props, ref) => {
    const {
        id,
        isIllegal = false,
        fen = 'start',
        width,
        arrows = [],
        highlightedSquares = [],
        orientation = 'white',
        canMakeMoves = true,
        draggable = canMakeMoves ? true : false,
        showPossibleMoves = canMakeMoves ? true : false,
        possibleMovesHighlightStyle = {
            background: "radial-gradient(circle, #fffc00 36%, transparent 40%)",
            borderRadius: "50%"
        },
        transitionDuration = 400,
        showTimer = false,
        timerPause: timerPauseProp = false,
        whiteMaxTime = 60,
        whiteAddTime = 0,
        blackMaxTime = 60,
        blackAddTime = 0,
        timerStyle = {},
        timerClassName,
        lightSquareStyle,
        darkSquareStyle,
        pieces,
        sounds = {},
        onReady = () => {
        },
        onPieceClick: onPieceClickProp = (piece, square) => {
        },
        onPieceRightClick = (piece, square) => {
        },
        onSquareClick: onSquareClickFromProp = (square) => {
        },
        onDragStart: onDragStartFromProp = (piece, from) => {
        },
        onDragEnd = (piece, from, to) => {
        },
        onMove: onMoveProp = (fen, piece, from, to) => {
        },
        onCapture = (allAimsTaken) => {
        },
        onGameOver: onGameOverProp = (state) => {
        },
        onTimeOver = () => {
        },
        onTimerTick = () => {
        },
        renderWhiteTimer = (time) => {
            return <Timer
                className={timerClassName}
                style={{margin: 8, fontSize: 28, ...timerStyle}}
                time={time}/>
        },
        renderBlackTimer = (time) => {
            return <Timer
                className={timerClassName}
                style={{margin: 8, fontSize: 28, ...timerStyle}}
                time={time}/>
        },
    } = props;

    useEffect(() => {
        onReady();
    }, []);

    const game = useRef(isIllegal ? new IllegalChess(fen) : new Chess(fen));
    window.game = game.current;
    const [updT, setUpdT] = useState(0);
    const [position, setPosition] = useState(fen);
    useEffect(() => {
        setPosition(fen);
        game.current.load(fen);
    }, [fen]);

    const [pieceSquare, setPieceSquare] = useState("");
    const [squareStyles, setSquareStyles] = useState([]);

    const [whiteTime, blackTime, setTimerPause, addTimeOnMove] = !showTimer ? [0, 0, () => {
    }, () => {
    }] : useChessTimer({
        whiteMaxTime,
        blackMaxTime,
        whiteAddTime,
        blackAddTime,
        timerPauseProp,
        game: game.current,
        onTimeOver,
        onTimerTick,
    });

    const [showPromotionChoice, setShowPromotionChoice] = useState(false);
    const [pendingMove, setPendingMove] = useState([]);

    useImperativeHandle(ref, () => ({
        getGame() {
            return game.current;
        },

        getCurrentFen() {
            return game.current.fen();
        },

        getCurrentPgn() {
            return game.current.pgn();
        },

        getPieces() {
            return game.current.board();
        },

        getPieceOnPosition(square) {
            return game.current.get(square);
        },

        getGameState() {
            return ChessboardHelper.getGameState(game.current);
        },

        getMovesHistory() {
            return game.current.history();
        },

        loadFen(fen) {
            setPosition(fen);
        },

        makeMove(from, to, promotion) {
            makeMove(from, to, promotion);
        },

        getCurrentTimersInfo() {
            return {
                whiteTime,
                whiteAddTime,
                blackTime,
                blackAddTime,
            }
        },

        pauseTimer() {
            setTimerPause(true);
        },

        resumeTimer() {
            setTimerPause(false);
        }
    }));

    const makeMove = (from, to, promotion) => {
        showPossibleMoves && removeHighlightPossibleMoves();

        if (!ChessboardHelper.isValidMove(game.current, from, to)) return;

        if (!promotion && ChessboardHelper.needsPromotion(game.current, from, to)) {
            setShowPromotionChoice(true);
            setPendingMove([from, to]);
            return;
        }

        const isCapture = game.current.get(to) ? true : false;

        const move = game.current.move({from, to, promotion});

        if (move) {
            const newFen = game.current.fen();
            onMove(isCapture, newFen, game.current.get(to), from, to)
        }
        return move;
    }

    const onMove = (isCapture, newFen, piece, from, to) => {
        setPosition(newFen);
        showTimer && addTimeOnMove();
        ChessboardHelper.playSoundOnMove(sounds);
        isCapture
        && onCapture(isIllegal && ChessboardHelper.areAllAimsTaken(newFen));
        game.current.game_over()
        && onGameOver();
        onMoveProp(newFen, piece, from, to);
        isCapture && (() => {
            setUpdT(+new Date());
        })();
    }

    const onGameOver = () => {
        setTimerPause(true);
        onGameOverProp(ChessboardHelper.getGameState(game.current));
    }

    const onDragStart = ({from, piece}) => {
        onDragStartFromProp(from, piece);
    }

    const onDrop = ({sourceSquare, targetSquare, piece}) => {
        onDragEnd(sourceSquare, targetSquare, piece);
        makeMove(sourceSquare, targetSquare);
    };

    const onSquareClick = square => {
        onSquareClickFromProp(square);

        if (!canMakeMoves) return;

        const clickedPiece = game.current.get(square);
        clickedPiece && onPieceClick(clickedPiece, square);
        if (pieceSquare !== "") {
            if (clickedPiece
                && !ChessboardHelper.isAimPiece(clickedPiece) && (game.current.get(pieceSquare) != undefined)
                && game.current.get(pieceSquare).color === clickedPiece.color) {
                setPieceSquare(square);
            } else {
                makeMove(pieceSquare, square);
                setPieceSquare("");
            }
        }

        if (pieceSquare === "" && clickedPiece) {
            setPieceSquare(square);
        }
    };

    const onSquareRightClick = square => {
        const clickedPiece = game.current.get(square);
        if (clickedPiece) onPieceRightClick(clickedPiece, square);
    };

    const onPieceClick = (clickedPiece, square) => {
        if (showPossibleMoves) {
            const moves = game.current.moves({
                square: square,
                verbose: true
            });

            if (moves.length === 0) return;

            const squaresToHighlight = moves.map(move => move.to);
            highlightPossibleMoves([square, ...squaresToHighlight]);
        }

        onPieceClickProp(clickedPiece, square);
    }

    const onPromotionChoiceClick = (pieceType) => {
        const [from, to] = pendingMove;
        makeMove(from, to, pieceType);
        setShowPromotionChoice(false);
    };

    const removeHighlightPossibleMoves = () => setSquareStyles([]);

    const highlightPossibleMoves = (squaresToHighlight) => {
        const highlightStyles = [...squaresToHighlight].reduce(
            (a, c) => {
                return {
                    ...a,
                    ...{
                        [c]: {...possibleMovesHighlightStyle}
                    }
                };
            },
            {}
        );
        setSquareStyles(highlightStyles);
    };

    return (
        <Wrapper>
            {showTimer &&
            <SidePlaceholder>
                {orientation === 'black' ? renderWhiteTimer(whiteTime) : renderBlackTimer(blackTime)}
            </SidePlaceholder>
            }
            {/*{showTimer && orientation === 'black' ? renderWhiteTimer(whiteTime) : renderBlackTimer(blackTime)}*/}
            <BoardPlaceholder className={updT} >
                <ChessboardUI
                    id={id}
                    position={position}
                    orientation={orientation}
                    width={width}
                    draggable={draggable}
                    transitionDuration={transitionDuration}
                    squareStyles={squareStyles}
                    lightSquareStyle={lightSquareStyle}
                    darkSquareStyle={darkSquareStyle}
                    pieces={pieces}
                    arrows={arrows}
                    highlightedSquares={highlightedSquares}
                    onSquareClick={onSquareClick}
                    onSquareRightClick={onSquareRightClick}
                    onDragStart={onDragStart}
                    onDrop={onDrop}>
                </ChessboardUI>
            </BoardPlaceholder>

            {showTimer &&
            <SidePlaceholder>
                {orientation === 'black' ? renderBlackTimer(blackTime) : renderWhiteTimer(whiteTime)}
            </SidePlaceholder>
            }

            {/*{showTimer && orientation === 'black' ? renderBlackTimer(blackTime) : renderWhiteTimer(whiteTime)}*/}

            {showPromotionChoice == false ? null :
                <NiceModal onClose={() => {
                    setShowPromotionChoice(false);
                }}>
                    <ReplacementRow>
                        <ReplacementImage src={require('../assets/images/pieces/black_knight.svg')}
                                          onClick={() => onPromotionChoiceClick('n')}/>
                        <ReplacementImage src={require('../assets/images/pieces/black_bishop.svg')}
                                          onClick={() => onPromotionChoiceClick('b')}/>
                        <ReplacementImage src={require('../assets/images/pieces/black_rook.svg')}
                                          onClick={() => onPromotionChoiceClick('r')}/>
                        <ReplacementImage src={require('../assets/images/pieces/black_queen.svg')}
                                          onClick={() => onPromotionChoiceClick('q')}/>
                    </ReplacementRow>
                </NiceModal>
            }


        </Wrapper>
    );
});

export default Chessboard;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const SidePlaceholder = styled.div`

`;

const BoardPlaceholder = styled.div`
  flex: 1;
`;

const ReplacementRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ReplacementImage = styled.img`
  width: 64px;
  height: 64px;
  margin: 5px;
`;
