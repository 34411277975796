import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import {Input} from "../../ui/Input";
import {Textarea} from "../../ui/Textarea";
import {Button} from "../../ui/Button";

import Spin from 'arui-feather/spin'
import {translate} from "../../../utils/Translate";

export default function UpdateExercisesGroupForm(props) {
    let {
        onSubmit = () => {
        }, loading = false,
        canEdit = true
    } = props;
    console.log('render: UpdateExercisesGroupForm props = ', props);
    const [name, setName] = useState((props.name == undefined) ? '' : props.name);
    const [description, setDescription] = useState((props.description == undefined) ? '' : props.description);
    const [rating, setRating] = useState((props.rating == undefined) ? 0 : props.rating);
    console.log('name, description = ', name, description);

    return (
        <Wrapper>

            <Field>
                <Label>
                    Название
                </Label>
                {canEdit == true ?
                    <Input value={name} onChange={evt => {
                        setName(evt.target.value);
                    }}/> :
                    <div>
                        {name}
                    </div>
                }
            </Field>

            <Field>
                <Label>
                    Описание
                </Label>
                {canEdit == true ?
                    <Textarea value={description} onChange={evt => {
                        setDescription(evt.target.value);
                    }}/> : <div dangerouslySetInnerHTML={{__html: description.replace(/\n/g, '<br/>')}}></div>
                }

            </Field>

            <Field>
                <Label>
                    {translate('rating')}
                </Label>
                {canEdit == true ?
                    <Input value={rating} onChange={evt => {
                        setRating(evt.target.value);
                    }}/> :
                    <div>
                        {rating}
                    </div>
                }
            </Field>

            {canEdit == false ? null :
                <Field>
                    <Button onClick={() => {
                        if (loading == true) {
                            return;
                        }
                        onSubmit({name, description, rating: +rating});
                    }}>
                        {loading == true ?
                            <Spin visible={true}/>
                            :
                            <span>
                                {translate('save')}
                            </span>
                        }

                    </Button>
                </Field>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Field = styled.div`
    margin-bottom: 10px;
`;

const Label = styled.div`
    font-weight: bold;
`;
