import AnswersAPI from '../../api/AnswersAPI'

import * as types from '../ActionTypes'

let loadAnswers_ = () => {
    return {
        type: types.LOAD_ANSWERS
    }
}
let loadAnswersSuccess = (answers) => {
    return {
        type: types.LOAD_ANSWERS_SUCCESS,
        answers: answers
    }
}
let loadAnswersFailed = (error) => {
    return {
        type: types.LOAD_ANSWERS_FAIL,
        error: error
    }
}

//thunk
export function loadAllAnswers() {
    return (dispatch, getState) => {
        dispatch(loadAnswers_());
        return AnswersAPI.getAllAnswers().then(
            answers => dispatch(loadAnswersSuccess(answers)),
            error => dispatch(loadAnswersFailed(error))
        )
    }
}

export function loadAnswerById(id) {
    return (dispatch, getState) => {
        dispatch(loadAnswers_());
        return AnswersAPI.getAnswer(id).then(
            answer => dispatch(loadAnswersSuccess([answer])),
            error => dispatch(loadAnswersFailed(error))
        )
    }
}

export function loadLazyAnswerByUserIdAndExerciseId(userId, exerciseId) {
    return (dispatch, getState) => {
        dispatch(loadAnswers_());
        return AnswersAPI.getLazyAnswerByUserIdAndExerciseId(userId, exerciseId).then(
            answer => dispatch(loadAnswersSuccess([answer])),
            error => dispatch(loadAnswersFailed(error))
        )
    }
}

let createAnswer_ = () => {
    return {
        type: types.CREATE_ANSWER
    }
}
let createAnswerSuccess = (answer) => {
    return {
        type: types.CREATE_ANSWER_SUCCESS,
        answer: answer
    }
}
let createAnswerFailed = (error) => {
    return {
        type: types.CREATE_ANSWER_FAIL,
        error: error
    }
}

//thunk
export function createAnswer(data) {
    return (dispatch, getState) => {
        dispatch(createAnswer_());
        return AnswersAPI.createAnswer(data).then(
            answer => dispatch(createAnswerSuccess(answer)),
            error => dispatch(createAnswerFailed(error))
        )
    }
}

let updateAnswer_ = () => {
    return {
        type: types.UPDATE_ANSWER
    }
}
let updateAnswerSuccess = (answer) => {
    return {
        type: types.UPDATE_ANSWER_SUCCESS,
        answer: answer
    }
}
let updateAnswerFailed = (error) => {
    return {
        type: types.UPDATE_ANSWER_FAIL,
        error: error
    }
}

//thunk
export function updateAnswer(data) {
    return (dispatch, getState) => {
        dispatch(updateAnswer_());
        return AnswersAPI.updateAnswer(data).then(
            answer => dispatch(updateAnswerSuccess(answer)),
            error => dispatch(updateAnswerFailed(error))
        )
    }
}

let deleteAnswer_ = () => {
    return {
        type: types.DELETE_ANSWER
    }
}
let deleteAnswerSuccess = (id) => {
    return {
        type: types.DELETE_ANSWER_SUCCESS,
        id: id
    }
}
let deleteAnswerFailed = (error) => {
    return {
        type: types.DELETE_ANSWER_FAIL,
        error: error
    }
}

//thunk
export function deleteAnswer(id) {
    return (dispatch, getState) => {
        dispatch(deleteAnswer_());
        return AnswersAPI.deleteAnswer(id).then(
            () => dispatch(deleteAnswerSuccess(id)),
            error => dispatch(deleteAnswerFailed(error))
        )
    }
}
