import React, {Component, Fragment} from 'react';
// import PropTypes from 'prop-types';

import styled from 'styled-components'

import {Button, OutlinedButton} from "../../../ui/Button";
import ls from "local-storage";

export default class Camera extends Component {
    state = {
        video: null,
        videoSelect: null,
        listVideos: [],
        permission: true,
        src: ''
    };
    videoinput = [];
    player = null;
    elementRef = new React.createRef();
    enumerateDevices = new Promise(resolve => {
        resolve(navigator.mediaDevices.enumerateDevices());
    });

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.toggleCamera = this.toggleCamera.bind(this);
    }

    handleChange(event) {
        console.log('Camera: handleChange: event = ', event);
        console.log('Camera: handleChange: event.target.value = ', event.target.value);
        window.camVideoId = event.target.value;
        if (window.camVideoId != undefined) {
            ls('sh_cam_video_id', window.camVideoId);
        }
        const video = navigator.mediaDevices.getUserMedia({
            audio: false,
            video: {
                deviceId: event.target.value ? {exact: event.target.value} : undefined
            }
        });

        this.setState({
            videoSelect: video,
            value: event.target.value,
            video: false
        }, () => {
            this.getCamera();
        });
    }

    componentDidMount() {
        this.player = this.elementRef.current;

        this.enumerateDevices
            .then(devices => {
                devices.map(device => {
                    if (device.kind == 'videoinput') {
                        this.videoinput.push({
                            id: device.deviceId,
                            kind: device.kind,
                            label: device.label
                        });
                    }
                });
                this.setState(prevState => ({
                    listVideos: [...prevState.listVideos, this.videoinput]
                }));
            })
            .catch(function (err) {
                console.log(`${err.name}: ${err.message}`);
            });
    }

    listCameras() {
        const listMicrophone = this.state.listVideos[0];
        return (
            <select
                disabled={this.state.video !== null}
                value={this.state.value}
                onChange={this.handleChange}
            >
                <option value="">Выбрать</option>
                {listMicrophone.map(item => (
                    <option key={item.id} value={item.id}>
                        {item.label}
                    </option>
                ))}
            </select>
        );
    }

    stopCamera() {
        this.state.videoSelect.then(video => {
            video.getTracks().forEach(track => track.stop());
        });
        this.setState({video: null});
    }

    toggleCamera() {
        if (this.state.video) {
            this.stopCamera();
        } else {
            this.getCamera();
        }
    }

    getCamera() {
        if (this.state.videoSelect) {
            this.state.videoSelect.then(stream => {
                this.handleUserMedia(stream);
            });
        }
    }

    handleUserMedia(stream) {
        this.stream = stream;
        this.setState({video: true});
        try {
            this.player.srcObject = stream;
        } catch (error) {
            this.setState({
                src: window.URL.createObjectURL(stream)
            });
        }
    }

    render() {
        // const { text } = this.props;
        console.log('render: this.state.value = ', this.state.value);

        return (
            <Wrapper>
                <Row>
                    <div>
                        <form action="javascript:void(0);">
                            <fieldset>
                                <label htmlFor="microphone" style={{marginRight: 10}}>Выберите камеру</label>
                                <br/>
                                {this.state.listVideos.length > 0 && this.listCameras()}
                            </fieldset>
                        </form>
                    </div>
                    {this.state.value == undefined ? null :
                        <OutlinedButton
                            onClick={this.toggleCamera}
                        >
                            {this.state.video ? 'Выключить камеру' : 'Проверить камеру'}
                        </OutlinedButton>
                    }
                </Row>


                <Row style={{height: (this.state.value == undefined) ? '0px' : 'auto'}}>
                    <video
                        autoPlay
                        height="160"
                        width="320"
                        src={this.state.src}
                        muted={false}
                        playsInline
                        ref={this.elementRef}
                    />
                </Row>


            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    background: whitesmoke;
`;

const Row = styled.div`
    
`;
