import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const SlotsAPI = {

    async getUserSlots(userId) {
        let d = (await axios.get(`${API_ENDPOINT}/user/${userId}/schedule/slots`)).data.result.slots;
        return d;
    },

    async updateUserSlots(userId, slots = []) {
        let d = (await axios.post(`${API_ENDPOINT}/user/${userId}/schedule`, {slots: slots})).data;
        return d;
    },


}

export default SlotsAPI;
