import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'
import {Map, Set} from 'immutable'

import * as actions from '../../../redux/actions/ExercisesFoldersActions'

import {Dialog, Button, Card, Elevation} from '@blueprintjs/core'
import NameDescriptionForm from "../../common/forms/NameDescriptionForm";
// import AllExercisesPanel from "../../exercises/panels/AllExercisesPanel";
import FoldersList from "../views/FoldersList";

export default function CoolExercisesFoldersPanel(props) {
    const dispatch = useDispatch();
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedId, setSelectedId] = useState(undefined);
    const [expandedSet, setExpandedSet] = useState(Set());
    const [addParentId, setAddParentId] = useState(undefined);
    const {
        selectedExercisesIds = [],
        onExerciseClick = exId => {

        }, openExerciseOnClick = true,
        canAddExercise = true
    } = props;

    // const {
    //     onFolderSelect = fId => {
    //
    //     },
    //     expandId = undefined
    // } = props;

    const {folders, loading, selectedExercises, currentUser, isAdmin, userRole, allExercises} = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let isAdmin = (currentUser == undefined) ? false : (currentUser.userRole == 'admin');
        let userRole = (currentUser == undefined) ? undefined : currentUser.userRole;
        return {
            allExercises: state.exercises.exercisesMap.toArray().sort((a, b) => (+a.number - +b.number)),
            currentUser: currentUser,
            userRole: userRole,
            isAdmin: isAdmin,
            folders: state.exercisesFolders.exercisesFoldersMap.toArray().sort((a, b) => (+b.timestamp - +a.timestamp)),
            loading: state.exercisesFolders.loading,
            selectedExercises: selectedExercisesIds.map(exId => (state.exercises.exercisesMap.get(exId))).filter(a => (a != undefined))
        }
    }, []));

    useDispatch(() => {
        dispatch(actions.loadAllExercisesFolders());
    }, []);
    let canEdit = (userRole == 'admin');

    if (folders.length == 0 && loading == true) {
        return (
            <Code/>
        )
    }

    let selectedExercisesFolder = (selectedId == undefined) ? undefined : folders.filter(a => (a.id == selectedId))[0];

    return (
        <Wrapper>

            {canAddExercise == false || canEdit == false ? null :
                <TopControlsPlaceholder>
                    <Button onClick={() => {
                        setModalVisible(true);
                    }} icon={'plus'}>
                        Добавить папку с упражнениями
                    </Button>
                </TopControlsPlaceholder>
            }

            <FoldersListPlaceholder>

                <FoldersList allFolders={folders}
                             isAdmin={isAdmin}
                             allExercises={allExercises}
                             expandedSet={expandedSet}
                             canEdit={canEdit}
                             onFolderExpand={fId => {
                                 console.log('onFolderExpand: fId = ', fId);
                                 setExpandedSet(expandedSet.add(fId))
                             }}
                             onFolderUnExpand={fId => {
                                 console.log('onFolderUnExpand: fId == ', fId);
                                 setExpandedSet(expandedSet.delete(fId))
                             }}
                             onAddSubFolder={fId => {
                                 setAddParentId(fId);
                             }}
                             onSelect={aId => {
                                 setSelectedId(aId);
                             }}
                             onFolderDelete={async fId => {
                                 if (window.confirm('Вы действительно хотите удалить папку?') == false) {
                                     return;
                                 }
                                 await dispatch(actions.deleteExercisesFolder(fId))
                             }}
                />

            </FoldersListPlaceholder>

            <Dialog isOpen={(selectedExercisesFolder != undefined)} title={'Редактирование папки'} onClose={() => {
                setSelectedId(undefined);
            }}>

                <InnerDialog>
                    <NameDescriptionForm {...selectedExercisesFolder} loading={loading} onSubmit={data => {
                        let d = {
                            ...selectedExercisesFolder,
                            ...data
                        }
                        dispatch(actions.updateExercisesFolder(d)).then(pld => {
                            setSelectedId(undefined);
                        });
                    }}/>
                </InnerDialog>

            </Dialog>

            <Dialog isOpen={modalVisible || addParentId != undefined} title={'Создание папки'} onClose={() => {
                setModalVisible(false);
                setAddParentId(undefined);
            }}>

                <InnerDialog>
                    <NameDescriptionForm loading={loading} onSubmit={data => {
                        if (addParentId != undefined) {
                            data.parentId = addParentId;
                        }
                        dispatch(actions.createExercisesFolder(data)).then(pld => {
                            setAddParentId(undefined);
                            setModalVisible(false);
                        });
                    }}/>
                </InnerDialog>

            </Dialog>

        </Wrapper>
    );


}

const Wrapper = styled.div`
    
`;


const FoldersListPlaceholder = styled.div`
    
`;
const FolderItem = styled.div`
    border-radius: 4px;
    margin-bottom: 10px;
    background: ${props => (props.selected == true) ? 'lightgrey' : 'transparent'};
    padding: ${props => (props.selected == true) ? 10 : 0}px;
    border: ${props => (props.selected == true) ? '1px solid whitesmoke' : 'none'};
`;

const TopControlsPlaceholder = styled.div`
    margin-bottom: 10px;
`;

const InnerDialog = styled.div`
    padding: 10px;
`;

const Name = styled.div`
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
    :hover{
      opacity: 0.75;
    }
`;

const DescriptionP = styled.p`
    cursor: pointer;
    :hover{
      opacity: 0.75;
    }
`;

const SelectedContent = styled.div`
    margin-top: 20px;
    width: 100%;
    box-sizing: border-box;
    
`;

const InnerInfoCard = styled.div`
    
`;

const InnerCard = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;
