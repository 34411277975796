import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {STARTING_FEN} from "../../../constants/config";
import FirebaseAPI from "../../../api/FirebaseAPI";
import ChessHelper from "../../../helpers/ChessHelper";

// import Chessboard from 'chessboardjsx'
import Chessboard from '../../rubius/chessboard/Chessboard'
import CommonHelper from "../../../helpers/CommonHelper";
import useComponentSize from "@rehooks/component-size/index";
import ls from 'local-storage'
import ArrowsAPI from "../../../api/ArrowsAPI";

import {useKeyPress} from "../../../helpers/HooksHelper";
import UpdateArrowsAndSquaresTool from "../../arrows/tools/UpdateArrowsAndSquaresTool";

const getArrowsOverlayStyle = (divId = 'realtime_board') => {
    try {
        let dd = JSON.parse(JSON.stringify(document.getElementById(divId).getBoundingClientRect()));
        return {
            ...dd,
            position: 'fixed',
            zIndex: 1000,
            backgroundColor: `rgba(255, 255, 255, 0.01)`
        };
    } catch (exc) {

    }
    return {
        x: 0,
        y: 0,
        top: 0,
        left: 0,
        display: 'none'
    }
}

export default function SimpleRealtimeBoard(props) {
    let {

        boardDivId = 'realtime_board',

        hasArrowsTool = true,

        isIllegal = true,
        boardId = 'sabir',
        currentUserId,
        outerLoading = false,
        myColor = undefined,
        showLoadingText = true,

        shouldShowBottomInfo = true,
        startingFen = STARTING_FEN,
        adminMode = false,
        whiteUserName = undefined,
        blackUserName = undefined,
        gameName = undefined,
        selectedUserId = undefined,
        orientation = 'white',

        forcedOrientation,

        participants = [],
        hasPaddings = true,

        arrowsUpdT = 0,

        onReady = d => {

        },

        onBoardStateChange = () => {

        }


    } = props;

    const letterOnePress = useKeyPress('1');
    const letterTwoPress = useKeyPress('2');

    const [arrowsMode, setArrowsMode] = useState(false);
    const [arrowsUpdStateT, setArrowsUpdStateT] = useState(0);

    const [arrows, setArrows] = useState(props.arrows == undefined ? [] : props.arrows);
    const [squares, setSquares] = useState(props.squares == undefined ? [] : props.squares);

    const [boardData, setBoardData] = useState(undefined);
    const [wrapperDimensions, setWrapperDimensions] = useState(undefined);
    const [initialized, setInitialized] = useState(false);
    const roomName = `${boardId}`;
    const currRef = useRef(undefined);
    const boardDataRef = useRef(undefined);
    console.log('--->>>   !!!   --->>>   RealtimeBoard: render: boardId = ', boardId);
    console.log('--->>>   !!!   --->>>   RealtimeBoard: render: initialized = ', initialized);
    const wrapperRef = useRef();
    const size = useComponentSize(wrapperRef);

    const fen = (boardDataRef.current == undefined) ? STARTING_FEN : (boardDataRef.current.fen == undefined ? STARTING_FEN : boardDataRef.current.fen);


    useEffect(() => {
        FirebaseAPI.initFirebase();
        FirebaseAPI.subscribeOnSimpleBoardChanges(roomName, chData => {
            console.log('subscribeOnChanges: chData = ', chData);
            if (chData == undefined) {
                return;
            }
            if (ChessHelper.saveBoardFenToLocalHistory(chData.fen, boardId, chData.synthetic) == true) {
                onBoardStateChange();
            }
            // boardDataRef.current = chData.boardData;
            // setBoardData(chData.boardData);
            boardDataRef.current = chData;
            setBoardData(chData);

            ArrowsAPI.getConfiguration(roomName, chData.fen).then(xx => {
                console.log('ArrowsAPI.getConfiguration: xx = ', xx);
                if (xx != undefined && xx.data != undefined && xx.data.arrows != undefined) {
                    setArrows(xx.data.arrows);
                } else {
                    setArrows([]);
                }
                if (xx != undefined && xx.data != undefined && xx.data.squares != undefined) {
                    setSquares(xx.data.squares);
                } else {
                    setSquares([]);
                }
            });

        }, {fen: startingFen, selectedUserId: 'nouser'}).then(pld => {
            console.log('--->>> pld = ', pld);
            // setTimeout(() => {
            //     setInitialized(true);
            // }, 200)
            onReady()
            setInitialized(true);
            console.log('subscribeOnChanges: then: pld = ', pld);
            if (pld.unsubscribeFunction != undefined) {
                currRef.current = {unsubscribeFunction: pld.unsubscribeFunction};
            }
            // if (pld == undefined || pld.data == undefined) {
            //     return;
            // }
            // console.log('RealtimeBoard: unsubscribeFunction = ', pld.unsubscribeFunction);
            // setBoardData(pld.data);
            if (!(pld == undefined || pld.data == undefined)) {
                setBoardData(pld.data);
                boardDataRef.current = pld.data;

                ArrowsAPI.getConfiguration(roomName, pld.data.fen).then(xx => {
                    console.log('ArrowsAPI.getConfiguration: xx = ', xx);
                    if (xx != undefined && xx.data != undefined && xx.data.arrows != undefined) {
                        setArrows(xx.data.arrows);
                    }
                    if (xx != undefined && xx.data != undefined && xx.data.squares != undefined) {
                        setSquares(xx.data.squares);
                    }
                });

            }

        }).catch(err => {
            console.log('RealtimeBoard: can not subscribe!');
        });
        return () => {
            console.log('trying to unsubscribe!');
            try {
                currRef.current.unsubscribeFunction();
            } catch (exc) {
                console.log('can not unsubscribe');
            }
        }
    }, [boardId]);

    // const changeBoardState = useCallback((newBoardData) => {
    //     console.log('changeBoardState: newBoardData = ', newBoardData);
    //     FirebaseAPI.updateBoardFireState(roomName, newBoardData);
    // }, [boardId]);

    const getPosition = useCallback((pos, a, b) => {
        if (initialized == false) {
            return;
        }
        console.log('getPosition: pos, a, b = ', pos, a, b);
        let fen = ChessHelper.chessboardJsxPositionToFen(pos);
        console.log('-->> fen = ', fen);
        console.log('old boardData = ', boardDataRef.current.boardData);
        let newD = {...boardDataRef.current, fen: fen};
        boardDataRef.current = newD;
        setBoardData(newD);
        // FirebaseAPI.updateBoardFireState(roomName, newD);
        FirebaseAPI.updateSimpleBoardData(roomName, newD).then(() => {

        });
    }, [boardId, initialized]);

    let bh = (hasPaddings == true) ? 30 : 1;
    // let bh = 0;

    const boardDimensions = (size == undefined) ? undefined : {
        width: (size == undefined) ? undefined : Math.min(size.height, size.width) - bh,
        height: (size == undefined) ? undefined : Math.min(size.height, size.width) - bh
    };
    // const fen = (boardData == undefined) ? STARTING_FEN : (boardData.fen == undefined ? STARTING_FEN : boardData.fen);


    useEffect(() => {
        if (initialized == false || boardDataRef.current == undefined || boardDataRef.current.fen == undefined) {
            return;
        }
        console.log('selectedUserId changed! ', selectedUserId);
        // let newD = {...boardData, fen: fen, selectedUserId: selectedUserId};
        let newD = {...boardDataRef.current, selectedUserId: selectedUserId, orientation: orientation};
        FirebaseAPI.updateSimpleBoardData(roomName, newD).then(() => {
        });
        setBoardData(newD);
    }, [selectedUserId, initialized, orientation]);

    useEffect(() => {
        window.REALTIME_BOARD_DIMENSIONS = boardDimensions;
    }, [JSON.stringify(boardDimensions)]);

    useEffect(() => {
        if (hasArrowsTool == false) {
            return;
        }
        if (letterOnePress == true) {
            setArrowsMode(true);
        }
        if (letterTwoPress == true) {
            setArrowsMode(false);
        }

    }, [letterOnePress, letterTwoPress]);

    console.log('RealtimeBoard: boardData = ', boardData);
    console.log('RealtimeBoard: boardDimensions = ', boardDimensions);
    // console.log('boardDimensions = ', boardDimensions);

    // let isMeActive = (boardData != undefined && currentUserId == boardData.selectedUserId);
    let isMeActive = (boardDataRef.current != undefined && currentUserId == boardDataRef.current.selectedUserId);

    let canMakeMoves = (adminMode == true || isMeActive == true);
    // let selUserId = (boardData == undefined) ? undefined : boardData.selectedUserId;
    let selUserId = (boardDataRef.current == undefined) ? undefined : boardDataRef.current.selectedUserId;
    let orient = (boardDataRef.current == undefined) ? undefined : (boardDataRef.current.orientation == undefined ? 'white' : boardDataRef.current.orientation);
    let selectedParticipant = (selUserId == undefined) ? undefined : participants.filter(a => (a.id == selUserId))[0];

    if (forcedOrientation != undefined) {
        orient = forcedOrientation;
    }

    console.log('SimpleRealtimeBoard render: fen = ', fen);
    console.log('adminMode = ', adminMode);
    console.log('canMakeMoves = ', canMakeMoves);
    console.log('isMeActive = ', isMeActive);
    console.log('aa--> arrows, squares = ', arrows, squares);
    let figuresNumber = (fen == undefined) ? 0 : `${fen}`.split(' ')[0].replace(/\//g, '').replace(/[0-9]/g, '').length;
    console.log('figuresNumber = ', figuresNumber);

    return (
        <Wrapper ref={wrapperRef}>

            <Inner>
                {((boardDimensions == undefined) || (boardDataRef.current == undefined) || (initialized == false) || (outerLoading == true)) ?
                    <LoadingPlaceholder>
                        <LoaderImg src={require('../../../assets/images/shagi_logo_wide_500.png')}/>
                        {showLoadingText == false ? null :
                            <div>
                                загрузка...
                            </div>
                        }
                    </LoadingPlaceholder>
                    :
                    <BoardPlaceholder key={`fig_num_${figuresNumber}`}>
                        <div id={boardDivId} style={{
                            // width: boardDimensions.width,
                            // height: boardDimensions.height,
                            // margin: '0 auto'
                            // position: 'relative'
                        }}>
                            <Chessboard fen={fen}
                                        isIllegal={isIllegal}
                                        draggable={canMakeMoves}
                                // getPosition={getPosition}
                                        arrows={arrows}
                                        highlightedSquares={squares}
                                        orientation={orient}
                                        width={boardDimensions.width}
                                        onMove={async newFen => {
                                            let newD = {...boardDataRef.current, fen: newFen, synthetic: false};
                                            boardDataRef.current = newD;
                                            setBoardData(newD);
                                            // FirebaseAPI.updateBoardFireState(roomName, newD);
                                            if (arrows.length > 0 || squares.length > 0) {
                                                await ArrowsAPI.updateConfiguration(roomName, newFen, {
                                                    arrows: arrows,
                                                    squares: squares
                                                })
                                            }
                                            await FirebaseAPI.updateSimpleBoardData(roomName, newD);
                                        }}
                                // calcWidth={() => {
                                //     return boardDimensions.width;
                                // }}
                            />
                            {/*<ArrowsToolPlaceholder>*/}
                            {/*<ArrowsToolPlaceholderInner {...boardDimensions} >*/}

                            {/*</ArrowsToolPlaceholderInner>*/}
                            {/*</ArrowsToolPlaceholder>*/}
                        </div>
                        {selectedParticipant == undefined ? null :
                            <div>
                                {`активный ученик: ${CommonHelper.getUserName(selectedParticipant)}`}
                            </div>
                        }
                    </BoardPlaceholder>
                }
            </Inner>

            {/*<SizeDetector onResize={d => {*/}
            {/*// setWrapperDimensions(d);*/}
            {/*}}>*/}
            {/*{({width, height}) => (*/}
            {/**/}
            {/*)}*/}
            {/*</SizeDetector>*/}

            {arrowsMode == false ? null :
                <div style={getArrowsOverlayStyle(boardDivId)}>
                    <div style={{width: '100%', height: '100%'}}>
                        <UpdateArrowsAndSquaresTool
                            orientation={orient}
                            arrows={arrows} squares={squares}
                            active={true}
                            defaultVisible={true}
                            onChange={async d => {
                                console.log('---->>> UpdateArrowsAndSquaresTool: onChange: d = ', d);
                                setArrows(d.arrows);
                                setSquares(d.squares);
                                await ArrowsAPI.updateConfiguration(roomName, fen, d);
                                let newD = {...boardDataRef.current, synthetic: false};
                                await FirebaseAPI.updateSimpleBoardData(roomName, newD);
                            }}
                        />
                    </div>
                </div>
            }

        </Wrapper>
    );
}

const ArrowsToolPlaceholder = styled.div`
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 2;
`;

const ArrowsToolPlaceholderInner = styled.div`
    position: relative;
    width: ${props => props.width}px;
    height: ${props => props.height}px;
`;

const borderBottomHeight = 40;


const Wrapper = styled.div`
    width: 100%;
    height: 100%;
`;

const Inner = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
`;

const BoardPlaceholder = styled.div`
    margin: 0 auto;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    &.my_turn{
      background-color: rgba(144,238,144,0.29);
    }
`;

const LoadingPlaceholder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;  
    font-size: 24px;
    text-align: center;
    height: 100%;
    width: 100%;
    background: whitesmoke;
`;

const LoaderImg = styled.img`
    height: 80px;
    display: inline-block;
`;

const MovesPlaceholder = styled.div`
    margin-bottom: 20px;
`;

const MovesPlaceholderInner = styled.div`
    width: 300px;
    background: whitesmoke;
`;

const Heading = styled.div`
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
`;
