import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import {Input} from "../../../ui/Input";

import {SketchPicker} from 'react-color';
import SimpleUploadPanel from "../../../upload/SimpleUploadPanel";

import {Button} from "../../../ui/Button";
import SimpleBoardViewTool from "../tools/SimpleBoardViewTool";

import Select from 'react-select';
import {translate} from "../../../../utils/Translate";

const FIG_OPTIONS = ['r', 'n', 'b', 'q', 'k', 'p', 'R', 'N', 'B', 'Q', 'K', 'P'];
const FIG_NAMES_MAP = {
    r: 'ладья',
    n: 'конь',
    b: 'офицер',
    q: 'ферзь',
    k: 'король',
    p: 'пешка'
};

const TYPE_OPTIONS = [
    {
        label: 'Фигуры',
        value: 'figures'
    },
    {
        label: 'Клетки',
        value: 'squares'
    },
];


export default function UpdateBoardForm(props) {
    const {
        onSave = d => {
            console.log('UpdateBoardForm: onSave: d = ', d);
        },
        loading = false
    } = props;

    const [type, setType] = useState(props.type == undefined ? 'figures' : props.type);
    const [name, setName] = useState(props.name == undefined ? '' : props.name);
    const [whiteColor, setWhiteColor] = useState(props.whiteColor == undefined ? '#FFFFFF' : props.whiteColor);
    const [blackColor, setBlackColor] = useState(props.blackColor == undefined ? '#000000' : props.blackColor);

    const [whiteTextureUrl, setWhiteTextureUrl] = useState(props.whiteTextureUrl);
    const [blackTextureUrl, setBlackTextureUrl] = useState(props.blackTextureUrl);

    const [figuresData, setFiguresData] = useState(FIG_OPTIONS.reduce((mp, a) => ({...mp, [a]: props[a]}), {}));

    console.log('UpdateBoardForm: render: figuresData = ', figuresData);

    return (
        <Wrapper>

            <Half>
                <Field>
                    <Label>
                        Тип
                    </Label>
                    <Select options={TYPE_OPTIONS}
                            value={TYPE_OPTIONS.filter(a => (a.value == type))[0]}
                            onChange={a => {
                                console.log('onChange: a = ', a);
                                setType(a.value);
                            }}/>
                </Field>

                <Field>
                    <Label>
                        Название
                    </Label>
                    <Input value={name} onChange={evt => {
                        setName(evt.target.value);
                    }}/>
                </Field>

                <Field>
                    <Label>
                        {type == 'figures' ? 'Картинки фигур' : 'Дизайн клеток'}
                    </Label>
                    <BoardPlaceholder key={JSON.stringify(figuresData)}>
                        <SimpleBoardViewTool
                            whiteColor={(type == 'figures') ? 'white' : whiteColor}
                            blackColor={(type == 'figures') ? 'black' : blackColor}
                            whiteTextureUrl={(type == 'squares') ? whiteTextureUrl : undefined}
                            blackTextureUrl={(type == 'squares') ? blackTextureUrl : undefined}

                            data={figuresData}
                            canUpdate={(type == 'figures')}
                            onChange={newD => {
                                setFiguresData(newD);
                            }}/>
                    </BoardPlaceholder>
                </Field>


                <Field>
                    <Button onClick={() => {
                        onSave({
                            ...figuresData,
                            name,
                            whiteColor,
                            blackColor,
                            type,
                            whiteTextureUrl,
                            blackTextureUrl
                        });
                    }}>
                        <Spin vsible={loading}/>
                        {loading == true ? null :
                            translate('save')
                        }
                    </Button>
                </Field>

            </Half>


            {type != 'squares' ? null :
                <Half>
                    <Field>
                        <Label>
                            Цвет белой клетки
                        </Label>
                        <SketchPicker color={whiteColor} onChangeComplete={a => {
                            setWhiteColor(a.hex);
                        }}/>
                    </Field>

                    <Field>
                        <Label>
                            Текстура белой клетки
                        </Label>
                        <TextureSquarePlaceholder>
                            <UploadPlaceholder>
                                <SimpleUploadPanel onUploaded={url => {
                                    setWhiteTextureUrl(url);
                                }}>
                                    {whiteTextureUrl == undefined ?
                                        <span>
                                            загрузите текстуру картинки
                                        </span>
                                        :
                                        <span>
                                            <TextureImage src={whiteTextureUrl}/>
                                        </span>
                                    }
                                </SimpleUploadPanel>
                            </UploadPlaceholder>
                        </TextureSquarePlaceholder>
                    </Field>


                    <Field>
                        <Label>
                            Цвет черной клетки
                        </Label>
                        <SketchPicker color={blackColor} onChangeComplete={a => {
                            setBlackColor(a.hex);
                        }}/>
                    </Field>

                    <Field>
                        <Label>
                            Текстура черной клетки
                        </Label>
                        <TextureSquarePlaceholder>
                            <UploadPlaceholder>
                                <SimpleUploadPanel onUploaded={url => {
                                    setBlackTextureUrl(url);
                                }}>
                                    {blackTextureUrl == undefined ?
                                        <span>
                                            загрузите текстуру картинки
                                        </span>
                                        :
                                        <span>
                                            <TextureImage src={blackTextureUrl}/>
                                        </span>
                                    }
                                </SimpleUploadPanel>
                            </UploadPlaceholder>
                        </TextureSquarePlaceholder>
                    </Field>

                </Half>
            }


        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 920px;
    box-sizing: border-box;
    @media(max-width: 920px){
      width: 100%;
    }
`;

const Field = styled.div`
    margin-bottom: 15px;
`;

const Label = styled.div`
    font-weight: bold;
    margin-bottom: 5px;
`;

const Val = styled.div`
    
`;

const PieceImage = styled.img`
    width: 40px;
    height: 40px;
`;

const BoardPlaceholder = styled.div`
    width: 400px;
    height: 400px;
    border: 1px solid whitesmoke;
`;

const Half = styled.div`
    flex: 1;
    box-sizing: border-box;
    padding: 10px;
`;

const TextureImage = styled.img`
    height: 100%;
    width: 100%;
`;

const TextureSquarePlaceholder = styled.div`
    width: 100px;
    height: 100px;
`;

const UploadPlaceholder = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
