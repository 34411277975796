import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {API_ENDPOINT} from "../../../constants/config";
import CommonHelper from "../../../helpers/CommonHelper";
import SimpleRealtimeBoard from "../../realtime/board/SimpleRealtimeBoard";
import OpenviduRoomPanel from "../../openvidu/panels/OpenviduRoomPanel";
import SimpleJitsiRoomTool from "../../jitsi/tools/SimpleJitsiRoomTool";

// board_two_l_{lessonId}_w_{whiteUserId}_b_{blackUserId}
// http://localhost:3000/#/play/board_two_l_dfdfdf_w_CWZ2VJspHS_b_YNCKYqgO1x

const checkRoomId = s => {
    if (s == undefined || s.trim() == '') {
        return false;
    }
    let items = s.split('_');
    if (items.length != 8) {
        return false;
    }
    if (s.indexOf('board_two_') == -1) {
        return false;
    }
    if (items[6] != 'b') {
        return false;
    }
    return true;
};

const getUsersByRoomId = (roomId) => {
    return new Promise((resolve, reject) => {
        if (checkRoomId(roomId) == false) {
            return resolve({
                blackUser: undefined,
                whiteUser: undefined
            });
        } else {
            let arr = roomId.split('_');
            let whiteUserId = arr[5];
            let blackUserId = arr[7];
            axios.post(`${API_ENDPOINT}/users/by_ids`, {
                ids: [whiteUserId, blackUserId]
            }).then(d => d.data).then(arr => {
                let blackUser = arr.filter(a => (a.id == blackUserId))[0];
                let whiteUser = arr.filter(a => (a.id == whiteUserId))[0];
                resolve({
                    blackUser: blackUser,
                    whiteUser: whiteUser
                });
            })
        }
    });
}

export default function TwoPlayersPlayTool(props) {
    const {roomId = '', currentUser} = props;
    let isCorrectId = checkRoomId(roomId);
    const [usersData, setUsersData] = useState({});

    useEffect(() => {
        getUsersByRoomId(roomId).then(pld => {
            setUsersData(pld);
        });
    }, [roomId]);

    if (isCorrectId == false) {
        return (
            <div>
                Неверный идентификатор доски
            </div>
        )
    }
    let arr = roomId.split('_');
    let whiteUserId = arr[5];
    let blackUserId = arr[7];
    if ([whiteUserId, blackUserId].indexOf(currentUser.id) == -1 && !(currentUser.userRole == 'admin' || currentUser.userRole == 'teacher')) {
        return (
            <div>
                Доступ запрещен
            </div>
        )
    }
    let {whiteUser, blackUser} = usersData;
    if (whiteUser == undefined || blackUser == undefined) {
        return (
            <Code/>
        )
    }
    console.log('TwoPlayersPlayTool: whiteUserId = ', whiteUserId);
    console.log('TwoPlayersPlayTool: blackUserId = ', blackUserId);
    console.log('TwoPlayersPlayTool: currentUser = ', currentUser);
    let participants = [whiteUser, blackUser];
    let meIsWhite = (whiteUserId == currentUser.id);
    let opponentUser = (meIsWhite == true) ? blackUser : whiteUser;

    let orientation = (currentUser.id == whiteUserId) ? 'white' : 'black';

    console.log('ORIENTATION = ', orientation);

    return (
        <Wrapper key={orientation} >
            <TopHeader>
                <TopLogoPlaceholder>
                    <LogoImg src={require('../../../assets/images/onl_logo.svg')}/>
                </TopLogoPlaceholder>
                <TopRightPlaceholder>
                    <span>
                        <span style={{fontWeight: 'bold'}}>
                            {`${CommonHelper.getUserName(whiteUser)}(белые)`}
                        </span>
                        <span style={{marginLeft: 5, marginRight: 5}}>
                            против
                        </span>
                        <span style={{fontWeight: 'bold'}}>
                            {`${CommonHelper.getUserName(blackUser)}(черные)`}
                        </span>
                    </span>
                </TopRightPlaceholder>
            </TopHeader>
            <ContentPlaceholder>
                <BoardPlaceholder>
                    <SimpleRealtimeBoard
                        isIllegal={false}
                        adminMode={true}
                        boardId={`${roomId}`}
                        participants={participants}
                        forcedOrientation={orientation}
                        currentUserId={currentUser.id}
                        onBoardStateChange={() => {

                        }}
                        onReady={d => {
                            console.log('SimpleRealtimeBoard: onReady: d = ', d);
                        }}
                    />
                </BoardPlaceholder>
                {/*<CommutePlaceholder>*/}
                {/*    <SimpleJitsiRoomTool roomId={roomId} />*/}
                {/*</CommutePlaceholder>*/}
            </ContentPlaceholder>
        </Wrapper>
    );
}

// const commuteWidth = 480;
const commuteWidth = 0;
const topHeight = 50;

const BoardPlaceholder = styled.div`
    display: inline-block;
    vertical-align: top;
    width: calc(100vw - ${commuteWidth}px);
    height: calc(100vh - ${topHeight}px);
    box-sizing: border-box;
    padding-top: 10px;
`;

const CommutePlaceholder = styled.div`
    display: inline-block;
    vertical-align: top;
    width: ${commuteWidth}px;
    height: calc(100vh - ${topHeight}px);
    box-sizing: border-box;
    border-left: 1px solid whitesmoke;
`;

const Wrapper = styled.div`
    width: 100vw;    
    height: 100vh;    
`;

const LogoImg = styled.img`
    height: 100%;
`;


const TopHeader = styled.div`
    height: ${topHeight}px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid whitesmoke;
    justify-content: space-between;
`;

const TopRightPlaceholder = styled.div`
    height: 100%;
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const TopLogoPlaceholder = styled.div`
    height: ${topHeight * 0.6}px;
    padding-left: 10px;
`;

const ContentPlaceholder = styled.div`
    width: 100%;
    height: calc(100vh - ${topHeight}px);
`;
