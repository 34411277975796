import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import UnitView from "../../units/view/UnitView";

export default function UnitsPgnSelectorTool(props) {
    const {
        units = [],
        onSelect = (pgn) => {

        }
    } = props;
    const [selectedId, setSelectedId] = useState(props.selectedUnitId);
    let selectedUnit = (selectedId == undefined) ? undefined : units.filter(a => (a.id == selectedId))[0];

    return (
        <Wrapper>

            <TopPlaceholder>
                <div style={{width: 302 * units.length, height: '100%'}} >
                    {units.map((u, i) => {
                        let isSelected = (u.id == selectedId);
                        let fName = `${u.name}`;
                        if (fName.length > 34){
                            fName = `${u.name.slice(0, 34)}...`
                        }
                        return (
                            <TabItem key={u.id} selected={isSelected} onClick={() => {
                                setSelectedId(u.id);
                            }}>
                                {fName}
                            </TabItem>
                        )
                    })}
                </div>
            </TopPlaceholder>

            {selectedUnit == undefined ? null :
                <MiddlePlaceholder>
                    <UnitView items={selectedUnit.items}
                              canEdit={false}
                              canSelectPgn={true}
                              onSelectPgn={p => {
                                  onSelect(p, selectedUnit.id);
                              }}
                    />
                </MiddlePlaceholder>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const TopPlaceholder = styled.div`
    //display: flex;
    //flex-direction: row;
    //align-items: stretch;
    height: 80px;
    background: #FFFFFF;
    border: 1px solid rgba(31, 32, 65, 0.25);
    box-sizing: border-box;
    border-radius: 4px;
    overflow-x: auto;
    max-width: 100%;
    width: 100%;
`;


const TabItem = styled.div`
    max-width: 300px;
    display: inline-flex;
    vertical-align: top;
    height: 100%;
    padding-left: 30px;
    padding-right: 30px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    background: ${props => (props.selected == true ? '#A36EFF' : 'white')};
    color: ${props => (props.selected == true ? '#FFFFFF' : '#333333')};
    :hover{
      
    }
`;


const MiddlePlaceholder = styled.div`
    padding-top: 10px;
`;
