import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import ChessHelper from "../../../../helpers/ChessHelper";
import SmartPgnMovesViewer from "./SmartPgnMovesViewer";

import useComponentSize from '@rehooks/component-size'

import Chess from 'react-chess'
import FirebaseAPI from "../../../../api/FirebaseAPI";
import SimpleIllegalBoard from "../../../chessboard/tools/SimpleIllegalBoard";

const defaultBoardKey = 'd_b_key';
const movedBoardKey = 'm_b_key';

function updateRealtimeData(currentUserId, newHistory, chessDataRef) {
    console.log('updateRealtimeData: currentUserId, newHistory, chessDataRef = ', currentUserId, newHistory, chessDataRef);
    try {
        FirebaseAPI.updateBoardFireState(`room_board-u-${currentUserId}`, {
            history: newHistory.map(a => ({...a, comment: a.comment == undefined ? '' : a.comment})),
            lastUpdateTimestamp: +new Date(),
            startingFen: chessDataRef.current.headers.FEN
        });
    } catch (exc) {

    }
}

export default function SmartPgnViewer(props) {
    const {
        onPositionChange = (newFen) => {
            console.log('onPositionChange: newFen = ', newFen);
        },
        hasBoard = true,
        selectedFen = undefined,
        selectedHistory = [],
        showHistory = true,
        currentUserId = undefined,
        boardId = 'simple_b_id'
    } = props;
    const ref = useRef(undefined);
    const chessDataRef = useRef(undefined);
    const movesPathRef = useRef([]);
    const maxMovesPathRef = useRef([]);

    let size = useComponentSize(ref);
    const {pgnString} = props;
    const [loading, setLoading] = useState(true);
    const [boardKey, setBoardKey] = useState(defaultBoardKey);
    const [chessData, setChessData] = useState(undefined);
    const [movesPath, setMovesPath] = useState([]);

    useEffect((() => {
        ChessHelper.parsePgnString(pgnString).then(pld => {
            console.log('SmartPgnViewer: pld = ', pld);
            setLoading(false);
            setChessData(pld);
            chessDataRef.current = pld;
        });
    }), [pgnString]);

    const refreshMovesPath = () => {
        setMovesPath(movesPathRef.current);
        // movesPathRef.current = [];
    }

    function downHandler({keyCode}) {
        if (keyCode == '37') { // left
            console.log('back');
            try {
                console.log('chessData = ', chessDataRef.current);
                console.log('movesPath.length = ', movesPath.length);
                let newMovesPath = maxMovesPathRef.current.slice(0, movesPathRef.current.length - 1);
                console.log('chessData.headers.FEN = ', chessDataRef.current.headers.FEN);
                let newFen = ChessHelper.getFenByInitialFenAndMoves(chessDataRef.current.headers.FEN, newMovesPath);
                console.log('newMovesPath, newFen = ', newMovesPath, newFen);
                onPositionChange(newFen);
                setMovesPath(newMovesPath);
                movesPathRef.current = newMovesPath;

                let newHistory = ChessHelper.getHistoryByInitialFenAndMoves(chessDataRef.current.headers.FEN, newMovesPath).map((hh, j) => {
                    return {
                        ...hh,
                        comment: newMovesPath[j].comment
                    }
                });
                updateRealtimeData(currentUserId, newHistory, chessDataRef);

            } catch (exc) {
                console.log('!exc = ', exc);
            }
            // onMove(selectedNumber)
        }
        if (keyCode == '39') { // right
            console.log('forward');
            try {
                console.log('chessData = ', chessDataRef.current);
                console.log('movesPath.length = ', movesPath.length);
                let newMovesPath = maxMovesPathRef.current.slice(0, movesPathRef.current.length + 1);
                console.log('chessData.headers.FEN = ', chessDataRef.current.headers.FEN);
                let newFen = ChessHelper.getFenByInitialFenAndMoves(chessDataRef.current.headers.FEN, newMovesPath);
                console.log('newMovesPath, newFen = ', newMovesPath, newFen);
                onPositionChange(newFen);
                setMovesPath(newMovesPath);
                movesPathRef.current = newMovesPath;

                let newHistory = ChessHelper.getHistoryByInitialFenAndMoves(chessDataRef.current.headers.FEN, newMovesPath).map((hh, j) => {
                    return {
                        ...hh,
                        comment: newMovesPath[j].comment
                    }
                });
                updateRealtimeData(currentUserId, newHistory, chessDataRef);

            } catch (exc) {
                console.log('!exc = ', exc);
            }
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', downHandler);
        // Remove event listeners on cleanup
        return () => {
            window.removeEventListener('keydown', downHandler);
        };
    }, []);

    useEffect((() => {
        if (chessData == undefined) {
            return;
        }
        let sMoves = chessData.moves;
        console.log('SmartPgnViewer: selectedFen, selectedHistory changed! : ', selectedFen, selectedHistory);
        let updPath = ChessHelper.findMovePathByHistory(sMoves, selectedHistory);
        if (updPath.length == 0) {
            return;
        }
        setMovesPath(updPath);
        movesPathRef.current = updPath;
        maxMovesPathRef.current = updPath;
    }), [selectedFen, selectedHistory]);


    if (loading == true) {
        return (
            <Code/>
        )
    }
    if (chessData == undefined) {
        return (
            <Wrapper ref={ref}>
            </Wrapper>
        )
    }
    const {moves = [], headers = {}} = chessData;
    let lastMove = (movesPath.length == 0) ? undefined : movesPath[movesPath.length - 1];
    const startFromBlack = ((moves.length > 1) && (moves[0].move_number != undefined) && (moves[1].move_number != undefined) && (moves[0].move_number + 1 == moves[1].move_number))
    const shift = (startFromBlack == true) ? 1 : 0;
    const currentStringPath = movesPath.map((mv, k) => {
        // let mvNum = mv.move_number;
        let mvNum = Math.floor((+k + +shift) / 2) + 1;
        let dots = (k == 0 && startFromBlack == true) ? '1...' : '';
        // if (mvNum == undefined){
        //     if (+k > )
        // }
        // console.log('mapping: mv.nags = ', mv.nags);
        let nagsString = ChessHelper.getSymbolsStringByNagsArray(mv.nags);

        let s = `${dots}${((+k + shift) % 2 == 1) ? '' : `${mvNum}.`} ${mv.move}${nagsString == undefined ? '' : ` ${nagsString}`}`;
        return s;
    }).join(' ');
    const currentPgnString = `[FEN "${headers.FEN}"]\n\n${currentStringPath}`;
    const currentPieces = ChessHelper.getPiecesByFenAndMoves(headers.FEN, movesPath);
    const currentFen = ChessHelper.getFenByInitialFenAndMoves(headers.FEN, movesPath);
    console.log('render: size = ', size);
    console.log('render: currentPieces = ', currentPieces);
    console.log('render: currentFen = ', currentFen);
    const maxRightHeight = (size.width - movesWidth)
    const boardWidth = Math.min(window.innerHeight - 80, +size.width - movesWidth);

    // const flatMovesRegistry = ChessHelper.makeMovesFlat(moves, [], [], 0);
    // console.log('flatMovesRegistry = ', flatMovesRegistry);

    return (
        <Wrapper ref={ref}>

            <TopPlaceholder>

                {hasBoard == false ? null :
                    <BoardPlaceholder key={boardKey + currentFen} style={{width: boardWidth}}>
                        {/*<Chess pieces={currentPieces}*/}
                               {/*allowMoves={true}*/}
                               {/*onMovePiece={() => {*/}
                                   {/*console.log('Chess: onMovePiece occured!');*/}
                                   {/*setBoardKey(movedBoardKey);*/}
                               {/*}}*/}
                        {/*/>*/}
                        <SimpleIllegalBoard
                            boardId={boardId}
                            fen={currentFen}
                        />
                    </BoardPlaceholder>
                }

                <MovesPlaceholer style={{
                    opacity: (boardKey == defaultBoardKey ? 1 : 0.5),
                    maxHeight: (hasBoard == false ? '100%' : (boardWidth)),
                    width: (hasBoard == false ? '100%' : (size.width - boardWidth)),
                    visibility: (showHistory == true) ? 'visible' : 'hidden'
                }}>
                    {(chessData == undefined || chessData.comment == undefined || chessData.comment.trim() == '') ? null :
                        <FirstCommentPlaceholder>
                            {chessData.comment}
                        </FirstCommentPlaceholder>
                    }
                    <SmartPgnMovesViewer
                        selectedMovesPath={movesPath}
                        moves={moves}
                        startFromBlack={startFromBlack}
                        onMoveClick={(mv, mvPath) => {
                            setBoardKey(defaultBoardKey);
                            console.log('mvPath = ', mvPath);
                            let newFen = ChessHelper.getFenByInitialFenAndMoves(headers.FEN, mvPath);
                            let newHistory = ChessHelper.getHistoryByInitialFenAndMoves(headers.FEN, mvPath).map((hh, j) => {
                                return {
                                    ...hh,
                                    comment: mvPath[j].comment
                                }
                            });
                            console.log('newFen, newHistory = ', newFen, newHistory);
                            onPositionChange(newFen, newHistory);
                            setMovesPath(mvPath);
                            if (maxMovesPathRef.current.length <= mvPath.length) {
                                maxMovesPathRef.current = mvPath;
                            }
                            movesPathRef.current = mvPath;
                            updateRealtimeData(currentUserId, newHistory, chessDataRef);
                            // try{
                            //     FirebaseBoardAPI.updateBoardFireState(`room_board-u-${currentUserId}`, {
                            //         history: newHistory.map(a => ({...a, comment: a.comment == undefined ? '' : a.comment})),
                            //         lastUpdateTimestamp: +new Date(),
                            //         startingFen: chessDataRef.current.headers.FEN
                            //     });
                            // }catch(exc){
                            //
                            // }
                        }}/>
                </MovesPlaceholer>
            </TopPlaceholder>

            {(currentStringPath == undefined || currentStringPath == '') ? null :
                <BottomPlaceholder className={'smart_pgn_viewer_bottom_placeholder'}>
                    {(currentStringPath == undefined || currentStringPath == '') ? <div>
                            <div></div>
                        </div> :
                        <div>
                            <div>
                                {currentStringPath}
                            </div>
                            {lastMove == undefined || lastMove.comment == undefined ? null :
                                <CommentPlaceholder>
                                    {lastMove.comment}
                                </CommentPlaceholder>
                            }
                        </div>
                    }
                    <BottomControlsPlaceholder>

                        <BottomControlSpan onClick={() => {
                            // selectedMovesPath
                            let newFen = ChessHelper.getFenByInitialFenAndMoves(headers.FEN, []);
                            onPositionChange(undefined);
                            setMovesPath([]);
                            movesPathRef.current = [];
                        }}>
                            <ReuseImg src={require('../../../../assets/images/reuse.svg')}/>
                            <span>
                                начальная позиция
                            </span>
                        </BottomControlSpan>

                        {movesPath.length < 2 ? null :
                            <BottomControlSpan onClick={() => {
                                // selectedMovesPath
                                let newMovesPath = movesPath.slice(0, movesPath.length - 1);
                                let newFen = ChessHelper.getFenByInitialFenAndMoves(headers.FEN, newMovesPath);
                                onPositionChange(newFen);
                                setMovesPath(newMovesPath);
                                movesPathRef.current = newMovesPath;
                            }}>
                                <ReuseImg src={require('../../../../assets/images/return.svg')}/>
                                <span>
                                    шаг назад
                                </span>
                            </BottomControlSpan>
                        }


                    </BottomControlsPlaceholder>
                </BottomPlaceholder>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    background: whitesmoke;
`;

const TopPlaceholder = styled.div`
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
`;


const BoardPlaceholder = styled.div`
    //flex: 1;
    //width: 100%;
`;

const movesWidth = 260;

const MovesPlaceholer = styled.div`
    width: ${movesWidth}px;
    box-sizing: border-box;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    background: whitesmoke;
    overflow-y: auto;
`;

const BottomPlaceholder = styled.div`
    padding: 10px;
    border-top: 1px solid whitesmoke;
`;

const CommentPlaceholder = styled.div`
    padding: 5px;
    font-size: 16px;
    font-style: italic;
`;

const BottomControlsPlaceholder = styled.div`
    margin-top: 5px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
`;

const BottomControlSpan = styled.div`
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    font-size: 12px;
    opacity: 0.6;
    margin-left: 15px;
    :hover{
      opacity: 1;
    }
`;

const ReuseImg = styled.img`
    width: 16px;
    height: 16px;
    margin-right: 4px;
`;

const FirstCommentPlaceholder = styled.div`
    font-size: 15px;
    font-style: italic;
    padding-left: 4px;
`;
