import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import HorChessTemplate from "../templates/HorChessTemplate";


import {Container} from "../ui/Container";

import UnderConstructionPanel from '../construction/UnderConstructionPanel'
import {useMappedState, useDispatch} from "redux-react-hook";
import ExerciseViewPanel from "../exercises/panels/ExerciseViewPanel";
import UserExercisePanel from "../exercises/panels/UserExercisePanel";

export default function ExerciseApp(props) {
    let {id} = props.match.params;

    return (
        <HorChessTemplate active={'exercise'}>

            <ExercisePlaceholder>
                <UserExercisePanel id={id}/>
            </ExercisePlaceholder>

        </HorChessTemplate>
    );
}

const TopPlaceholder = styled.div`
    
`;

const ExercisePlaceholder = styled.div`
    height: 80vh;
    width: 100%;
    background: whitesmoke;
    padding: 5px;
    box-sizing: border-box;
`;

const Heading = styled.div`
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
`;

