import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {API_ENDPOINT, MAIN_JITSI_URL} from "../../../constants/config";
import {BlueButton} from "../../ira/ui/Buttons";
import ReactHelper from "../../../helpers/ReactHelper";
import CommonHelper from "../../../helpers/CommonHelper";
import {translate} from "../../../utils/Translate";

export default function JitsiAccessOverlayPanel(props) {
    const {
        user,
        lessonId,
        onReady = () => {

        }
    } = props;

    let isTeacher = (user != undefined && user.userRole == 'teacher');
    const [loaded, setLoaded] = useState(false);
    const [isWaiting, setIsWaiting] = useState(false);
    let jitsiUrl = `${MAIN_JITSI_URL}/room_lesson_${lessonId}`;

    useEffect(() => {
        axios.get(`${API_ENDPOINT}/v2/key/room_status_${lessonId}`).then(d => d.data.result).then(a => {
            if (a == undefined) {
                setIsWaiting(true);
            }else{
                setIsWaiting(a?.waiting);
            }
            if (a?.waiting == false) {
                onReady();
            }
        });
    }, [lessonId]);

    ReactHelper.useInterval(() => {
        if (isWaiting == true) {
            axios.get(`${API_ENDPOINT}/v2/key/room_status_${lessonId}`).then(d => d.data.result).then(a => {
                if (a == undefined) {
                    setIsWaiting(true);
                }else{
                    setIsWaiting(a?.waiting);
                }
                setLoaded(true);
                if (a?.waiting == false) {
                    onReady();
                }
            });
        }
    }, 3000);

    if (isWaiting == false) {
        return null;
    }

    if (loaded == false) {
        return (
            <Wrapper>
                <InnerLoaded>
                    {`${translate('loading')}...`}
                </InnerLoaded>
            </Wrapper>
        )
    }

    if (isTeacher == false && isWaiting == true) {
        return (
            <Wrapper>
                <InnerLoaded>
                    {`${translate('waiting_for_the_teacher')}...`}
                </InnerLoaded>
            </Wrapper>
        )
    }


    return (
        <Wrapper>
            <InnerLoaded>
                <JitsiPlaceholder>
                    <iframe allow="display-capture; autoplay"
                            src={jitsiUrl}
                            frameBorder="0"
                            allowFullScreen="1"
                            allow="camera *;microphone *; autoplay; encrypted-media"
                            name="jitsiConferenceFrame01" id="jitsiConferenceFrame01"
                            style={{height: '100%', width: '100%', border: '0px'}}></iframe>
                </JitsiPlaceholder>
                <GoButtonPlaceholder>
                    <div style={{padding: 10}} >
                        Дождитесь, пока загрузится камера и нажмите на кнопку "Начать урок"
                    </div>
                    <BlueButton onClick={async () => {
                        await axios.post(`${API_ENDPOINT}/v2/key/room_status_${lessonId}`, {
                            data: {
                                waiting: false
                            }
                        });
                        setIsWaiting(false);
                        onReady();
                    }}>
                        Начать урок
                    </BlueButton>
                </GoButtonPlaceholder>
            </InnerLoaded>
        </Wrapper>
    );
}

const GoButtonPlaceholder = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const JitsiPlaceholder = styled.div`
  width: 400px;
  height: 300px;
  box-sizing: border-box;
`;

const InnerLoaded = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  position: fixed;
  z-index: 1000;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
`;