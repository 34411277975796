import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import CommonHelper from "../../../helpers/CommonHelper";
import PaykeeperHelper from "../../../helpers/PaykeeperHelper";

export default function PaymentTool(props) {
    const {userId = '', email} = props;
    const [text, setText] = useState('3000');

    return (
        <Wrapper>

            <Heading>
                Пополнить баланс
            </Heading>

            <P>
                Введите сумму, на которую хотите пополнить баланс
            </P>

            <Label>
                Сумма
            </Label>

            <ReplRow>

                <div style={{flex: 1}}>
                    <Input autoFocus={true} placeholder={'Сумма пополнения'} value={text} onChange={evt => {
                        setText(evt.target.value);
                    }}/>
                </div>

                <div style={{marginLeft: 20}}>
                    руб.
                </div>

            </ReplRow>

            <ButtonPlaceholder>
                <Button style={{marginLeft: 0}} onClick={async () => {
                    if (window.isNaN(text) == true) {
                        return window.alert('Incorrect value');
                    }
                    // let url = await CommonHelper.getReplenishmentUrl(userId, text);
                    // let win = window.open(url, '_blank');
                    // win.focus();
                    PaykeeperHelper.submitReplenishmentRequest({
                        sum: +text,
                        userId: userId,
                        orderId: `${userId}-${moment().format('DD-MM-YYYY-HH-mm-ss')}`,
                        email: email
                    })
                }}>
                    {`Пополнить на ${text} руб.`}
                </Button>
            </ButtonPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Heading = styled.div`
    color: #333539;
    font-weight: normal;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
`;

const P = styled.div`
    color: rgba(31, 32, 65, 0.7);
    margin-bottom: 30px;
    text-align: center;
`;

const Label = styled.div`
    color: #8593A8;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
`;

const ButtonPlaceholder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const ReplRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;
`;

const Input = styled.input`
    background: #FFFFFF;
    border: 1px solid #8593A8;
    box-sizing: border-box;
    border-radius: 4px;
    color: rgba(31, 32, 65, 0.75);;
    height: 44px;
    width: 100%;
    font-weight: normal;
    padding-left: 15px;
    :focus{
      outline: none;
      border-color: #FFA71B;
    }
    ::placeholder{
      color: rgba(31, 32, 65, 0.25);  
    }
`;


const Button = styled.div`
    border-radius: 100px;
    background: #085BFF;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 55px;
    padding-right: 55px;
    height: 44px;
    color: white;
    font-weight: bold;
    cursor: pointer;
    :hover{
      opacity: 0.9;
    }
`;

