import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import HorChessTemplate from "../templates/HorChessTemplate";

import {Container} from "../ui/Container";

import UnderConstructionPanel from '../construction/UnderConstructionPanel'

import AllCoursesPanel from '../courses/panels/AllCoursesPanel'
import IraTeacherTemplate from "../templates/IraTeacherTemplate";

export default function CoursesApp(props) {

    return (
        <IraTeacherTemplate active={'courses'}>

            <Wrapper>

                <AllCoursesPanel/>

            </Wrapper>

        </IraTeacherTemplate>
    );
}

const Wrapper = styled.div`
    padding: 40px;
`;
