import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';
import {useDispatch, useMappedState} from 'redux-react-hook'

import ls from 'local-storage'

import HorChessTemplate from '../templates/HorChessTemplate'
import StudentPersonalPanel from "../students/panels/StudentPersonalPanel";
import IraUserLessonsPanel from "../lessons/panels/IraUserLessonsPanel";
import IraTeacherTemplate from "../templates/IraTeacherTemplate";
import CommonHelper from "../../helpers/CommonHelper";
import IraUserHomeworkLessonsPanel from "../lessons/panels/IraUserHomeworkLessonsPanel";
import {translate} from "../../utils/Translate";

const TABS = [
    {
        label: translate('schedule'),
        value: 'schedule'
    },
    {
        label: translate('homework'),
        value: 'homework',
    }
]

export default function StudentIndexApp(props) {
    const dispatch = useDispatch();
    const [tab, setTab] = useState(ls('st_shagi_hw_tab') == undefined ? 'schedule' : ls('st_shagi_hw_tab'));

    const {currentUserId} = useMappedState(useCallback(state => ({
        currentUserId: state.users.currentUserId,
        currentUser: state.users.usersMap.get(state.users.currentUserId)
    }), []));

    useEffect(() => {
        ls('st_shagi_hw_tab', tab);
    }, [tab]);

    return (
        <IraTeacherTemplate active={'index'}>

            {/*<StudentPersonalPanel id={currentUserId} />*/}

            {/*<HorHeading>*/}
                {/*<TopLeft>*/}

                    {/*{TABS.map((a, i) => {*/}
                        {/*return (*/}
                            {/*<TabItem selected={(tab == a.value)} onClick={() => {*/}
                                {/*setTab(a.value);*/}
                            {/*}}>*/}
                                {/*{a.label}*/}
                            {/*</TabItem>*/}
                        {/*)*/}
                    {/*})}*/}

                {/*</TopLeft>*/}
                {/*<TopRight>*/}

                {/*</TopRight>*/}
            {/*</HorHeading>*/}

            <Wrapper key={`aa_${tab}`}>

                <IraUserLessonsPanel/>

                {/*{tab != 'schedule' ? null :*/}
                    {/*<IraUserLessonsPanel/>*/}
                {/*}*/}

                {/*{tab != 'homework' ? null :*/}
                    {/*<div>*/}
                        {/*<IraUserHomeworkLessonsPanel/>*/}
                    {/*</div>*/}
                {/*}*/}


            </Wrapper>

        </IraTeacherTemplate>
    );
}

const Wrapper = styled.div`
    padding-top: 31px;
    padding-left: 45px;
    padding-right: 45px;
    box-sizing: border-box;
    @media(max-width: 1300px){
      padding-left: 15px;
      padding-right: 15px;
    }
`;


const topHeight = 70;

const HorHeading = styled.div`
    width: 100%;
    height: ${topHeight}px;
    background: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding-left: 40px;
    padding-right: 40px;
`;

const TopLeft = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const TopRight = styled.div`
    
`;

const TabItem = styled.div`
    font-size: 16px;
    line-height: 24px;
    color: ${props => (props.selected ? '#085BFF' : '#8593A8')};
    font-weight: ${props => (props.selected ? 'bold' : 'normal')};
    cursor: ${props => (props.selected ? 'default' : 'pointer')};
    margin-right: 40px;
    :hover{
      color: #085BFF;
    }
`;
