import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const ExercisesFoldersAPI = {

    getAllExercisesFolders() {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/exercises_folders/all`).then(d => d.data).then(exercises => {
                if (exercises.error != undefined) {
                    return reject(exercises.error);
                }
                resolve(exercises);
            }).catch(err => reject(err));
        });
    },

    createExercisesFolder(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/exercises_folders/create`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    updateExercisesFolder(data) {
        console.log('updateExercisesFolder: data = ', data);
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/exercises_folders/update`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    deleteExercisesFolder(id) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/exercises_folders/delete/${id}`).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    getExercisesFolder(id) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/exercises_folder/${id}`).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

}

export default ExercisesFoldersAPI;
