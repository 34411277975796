import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import QualitySwitcher from "./tools/QualitySwitcher";
import PlayerProgress from "./tools/PlayerProgress";
import ReactHelper from "../../helpers/ReactHelper";
import SpeedControlTool from "./tools/SpeedControlTool";
import CommonHelper from "../../helpers/CommonHelper";
import VolumeControl from "./tools/VolumeControl";

import ReactPlayer from 'react-player'

const ie = a => (a == undefined || a.trim() == '');

export default function YoutubePlayer(props) {
    const {
        // youtubeUrl = 'https://vimeo.com/458162089',
        youtubeUrl = 'https://player.vimeo.com/video/416809002',

        autoPlay = false,
        onClose = () => {

        },
        canExpand = false,
        id,
        expanded = false,
        onExpandChange = (newExpanded) => {

        }
    } = props;

    const playerRef = useRef();
    const playerProgressRef = useRef();
    const currentTimeRef = useRef();
    const totalTimeRef = useRef();

    const [playing, setPlaying] = useState(autoPlay);
    const [speed, setSpeed] = useState(1);
    const [volume, setVolume] = useState(1);


    useEffect(() => {
        try {
            videoRef.current.playbackRate = +speed;
        } catch (eexc) {

        }
    }, [speed]);

    useEffect(() => {
        try {
            videoRef.current.volume = +volume;
        } catch (eexc) {

        }
    }, [volume]);

    useEffect(() => {
        try {
            if (playing) {
                videoRef.current.play();
            } else {
                videoRef.current.pause();
            }
        } catch (ee) {

        }
    }, [playing]);

    ReactHelper.useInterval(() => {
        try {
            // window.currVideoDuration = videoRef.current.duration;
            if (window.currVideoDuration == undefined) {
                return;
            }
            let currTime = playerRef.current.getCurrentTime();
            if (currTime != undefined) {
                console.log('current time = ', currTime);
            }
            try {
                currentTimeRef.current.innerText = CommonHelper.convertSecondstoTime(Math.floor(currTime));
                totalTimeRef.current.innerText = CommonHelper.convertSecondstoTime(Math.floor(window.currVideoDuration));
            } catch (eee) {

            }
            let progress = 100.0 * currTime / window.currVideoDuration;
            playerProgressRef.current.updateProgress(progress);
        } catch (e) {

        }
    }, 500);


    const videoRef = useRef();

    function downHandler({key}) {
        try {
            console.log('downHandler: key = ', key);
            let ct = undefined;
            if (key == 'ArrowRight') {
                ct = playerRef.current.getCurrentTime() + 10.0;
                playerRef.current.seekTo(ct, 'seconds');
            }
            if (key == 'ArrowLeft') {
                ct = Math.max(0, playerRef.current.getCurrentTime() - 10.0);
                playerRef.current.seekTo(ct, 'seconds');
            }
            if (key == ' ') {
                // setPlaying(videoRef.current.paused);
                setPlaying(!playing);
            }
            if (key == 'Escape') {
                onExpandChange(false);
            }

        } catch (e) {

        }
    }

    useEffect(() => {
        window.addEventListener('keydown', downHandler);
        return () => {
            window.removeEventListener('keydown', downHandler);
        };
    }, []);

    return (
        <Wrapper>

            <TopPlaceholder>
                <PlayerPlaceholder>
                    <VideoPlaceholder>
                        <ReactPlayer playbackRate={speed}
                                     volume={volume} url={youtubeUrl} ref={playerRef} playing={playing} controls={false}
                                     width='100%'
                                     height='100%'
                                     onDuration={(a, b, c) => {
                                         console.log('ReactPlayer: onDuration: a, b, c = ', a, b, c);
                                         window.currVideoDuration = a;
                                     }}
                        />
                    </VideoPlaceholder>
                </PlayerPlaceholder>
            </TopPlaceholder>

            <ControlsPlaceholder>
                <LeftPlayPlaceholder>
                    <PlayButton onClick={() => {
                        setPlaying(!playing);
                    }}>
                        <PlayImg
                            src={(playing == true) ? require('./images/pause.svg') : require('./images/play.svg')}/>
                    </PlayButton>

                    <VolumePlaceholder>
                        <VolumeControl volume={volume} onChange={newVolume => {
                            setVolume(newVolume);
                        }}/>
                    </VolumePlaceholder>

                    <CurrentTimePlaceholder>
                        <CurrentTimeSpan ref={currentTimeRef}>

                        </CurrentTimeSpan>
                    </CurrentTimePlaceholder>

                </LeftPlayPlaceholder>

                <ProgressbarPlaceholder>
                    <ProgressBarInnerPlaceholder>
                        <PlayerProgress ref={playerProgressRef} onSeek={frac => {
                            try {
                                // let pos = videoRef.current.duration * frac;
                                // videoRef.current.currentTime = pos;
                                playerRef.current.seekTo(frac, 'fraction');
                            } catch (e) {

                            }
                        }}/>
                    </ProgressBarInnerPlaceholder>
                </ProgressbarPlaceholder>

                <CurrentTimePlaceholder>
                    <CurrentTimeSpan ref={totalTimeRef}>

                    </CurrentTimeSpan>
                </CurrentTimePlaceholder>

                <RightSpeedPlaceholder>
                    <SpeedControlTool speed={speed} onChange={a => {
                        setSpeed(a);
                    }}/>
                </RightSpeedPlaceholder>

                {canExpand == false ? null :
                    <FullScreenButtonPlaceholder>
                        <FullScreenButtonImg
                            src={(expanded == true) ? require('./images/minimize.svg') : require('./images/expand.svg')}
                            onClick={() => {
                                onExpandChange(!expanded);
                            }}
                        />
                    </FullScreenButtonPlaceholder>
                }

            </ControlsPlaceholder>

        </Wrapper>
    );
}

const bottomHeight = 48;

const FullScreenButtonImg = styled.img`
    cursor: pointer;
    height: 16px;
    opacity: 0.6;
    :hover{
      opacity: 1;
    }
`;

const LeftChevImg = styled.img`
    width: 7px;
    opacity: 0.5;
`;

const RightSpeedPlaceholder = styled.div`
    margin-left: 10px;
`;

const FullScreenButtonPlaceholder = styled.div`
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const CurrentTimePlaceholder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const CurrentTimeSpan = styled.span`
    font-size: 12px;
    margin-left: 5px;
    margin-right: 5px;
    opacity: 0.7;
    width: 36px;
    text-align: center;
`;

const QualityPlaceholder = styled.div`
    height: 30px;
    width: 30px;
`;

const VolumePlaceholder = styled.div`
    height: 30px;
    width: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;


const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: white;
`;

const PlayerPlaceholder = styled.div`
    height: 100%;
    width: 100%;
`;

const PlayImg = styled.img`
    height: 16px;
`;

const VideoPlaceholder = styled.div`
    width: 100%;
    height: 100%;
    background: black;
`;

const Video = styled.video`
    width: 100%;
    height: 100%;
    background: black;
    cursor: pointer;
`;

const ControlsPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    height: ${bottomHeight}px;
    width: 100%;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
    background: whitesmoke;
    border-top: 1px solid lightgrey;
`;

const LeftPlayPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 15px;
`;


const PlayButton = styled.div`
    width: 50px;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background: #015F9B;
    cursor: pointer;
    margin-right: 10px;
    :hover{
      opacity: 0.8;
    }
`;

const ProgressbarPlaceholder = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const ProgressBarInnerPlaceholder = styled.div`
    width: 100%;
    height: 20px;
`;

const TopPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    height: calc(100% - ${bottomHeight}px);
`;

const ListPlaceholder = styled.div`
    width: 300px;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    display: inline-block;
    vertical-align: top;
`;
