import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import {Button} from "../../ui/Button";
// import {Input} from "../../ui/Input";
import {Textarea} from "../../ui/Textarea";
import {translate} from "../../../utils/Translate";

export default function UpdateTeacherGroupForm(props) {
    let [name, setName] = useState(props.name == undefined ? '' : props.name);
    let [minRating, setMinRating] = useState(props.minRating == undefined ? '' : props.minRating);
    let [maxRating, setMaxRating] = useState(props.maxRating == undefined ? '' : props.maxRating);

    let [description, setDescription] = useState(props.description == undefined ? '' : props.description);

    let {
        buttonName = translate('save'),
        onSave = d => {

        },
        loading = false
    } = props;

    return (
        <Wrapper>

            <Field>
                <Label>
                    Название группы
                </Label>
                <Input value={name}
                       autoFocus={true}
                       onChange={evt => {
                           setName(evt.target.value);
                       }}/>
            </Field>

            <Row>
                <Field style={{paddingRight: 15}}>
                    <Label>
                        Минимальный рейтинг
                    </Label>
                    <Input value={minRating}
                           onChange={evt => {
                               setMinRating(evt.target.value);
                           }}/>
                </Field>
                <Field style={{paddingLeft: 15}}>
                    <Label>
                        Максимальный рейтинг
                    </Label>
                    <Input value={maxRating}
                           onChange={evt => {
                               setMaxRating(evt.target.value);
                           }}/>
                </Field>
            </Row>

            {/*<Field>*/}
            {/*<Label>*/}
            {/*Описание группы*/}
            {/*</Label>*/}
            {/*<Textarea value={description} onChange={evt => {*/}
            {/*setDescription(evt.target.value);*/}
            {/*}}/>*/}
            {/*</Field>*/}

            <SavePlaceholder>
                <SaveButton onClick={() => {
                    onSave({name, description, minRating: +minRating, maxRating: +maxRating});
                }}>
                    <Spin visible={loading} theme={'alfa-on-color'}/>
                    {loading == true ? null : buttonName}
                </SaveButton>
            </SavePlaceholder>


        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const SaveButton = styled.div`
    height: 44px;
    background: #085BFF;
    border-radius: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px;
    color: white;
    font-weight: bold;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;

const SavePlaceholder = styled.div`
    text-align: center;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`;

const Field = styled.div`
    margin-bottom: 10px;
    flex: 1;
    box-sizing: border-box;
`;

const Label = styled.div`
    font-size: 12px;
    line-height: 15px;
    color: #8593A8;
`;

const Input = styled.input`
    outline: none;
    height: 44px;
    box-sizing: border-box;
    padding-left: 15px;
    border: 1px solid #8593A8;
    border-radius: 4px;
    background: white;
    line-height: 42px;
    color: black;
    width: 100%;
    ::placeholder{
      color: rgba(31, 32, 65, 0.25);  
    }
`;
