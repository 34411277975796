import React from 'react';
//Chess pieces originally by User:Cburnett in a sprite image for use in computer applications.

let standard = {
  wP: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="45"
      height="45"
    >
      <path
        d="M 22,9 C 19.79,9 18,10.79 18,13 C 18,13.89 18.29,14.71 18.78,15.38 C 16.83,16.5 15.5,18.59 15.5,21 C 15.5,23.03 16.44,24.84 17.91,26.03 C 14.91,27.09 10.5,31.58 10.5,39.5 L 33.5,39.5 C 33.5,31.58 29.09,27.09 26.09,26.03 C 27.56,24.84 28.5,23.03 28.5,21 C 28.5,18.59 27.17,16.5 25.22,15.38 C 25.71,14.71 26,13.89 26,13 C 26,10.79 24.21,9 22,9 z "
        style={{
          opacity: '1',
          fill: '#ffffff',
          fillOpacity: '1',
          fillRule: 'nonzero',
          stroke: '#000000',
          strokeWidth: '1.5',
          strokeLinecap: 'round',
          strokeLinejoin: 'miter',
          strokeMiterlimit: '4',
          strokeDasharray: 'none',
          strokeOpacity: '1'
        }}
      />
    </svg>
  ),
  wR: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="45"
      height="45"
    >
      <g
        style={{
          opacity: '1',
          fill: '#ffffff',
          fillOpacity: '1',
          fillRule: 'evenodd',
          stroke: '#000000',
          strokeWidth: '1.5',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: '4',
          strokeDasharray: 'none',
          strokeOpacity: '1'
        }}
      >
        <path
          d="M 9,39 L 36,39 L 36,36 L 9,36 L 9,39 z "
          style={{
            strokeLinecap: 'butt'
          }}
        />
        <path
          d="M 12,36 L 12,32 L 33,32 L 33,36 L 12,36 z "
          style={{
            strokeLinecap: 'butt'
          }}
        />
        <path
          d="M 11,14 L 11,9 L 15,9 L 15,11 L 20,11 L 20,9 L 25,9 L 25,11 L 30,11 L 30,9 L 34,9 L 34,14"
          style={{
            strokeLinecap: 'butt'
          }}
        />
        <path d="M 34,14 L 31,17 L 14,17 L 11,14" />
        <path
          d="M 31,17 L 31,29.5 L 14,29.5 L 14,17"
          style={{
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter'
          }}
        />
        <path d="M 31,29.5 L 32.5,32 L 12.5,32 L 14,29.5" />
        <path
          d="M 11,14 L 34,14"
          style={{
            fill: 'none',
            stroke: '#000000',
            strokeLinejoin: 'miter'
          }}
        />
      </g>
    </svg>
  ),
  wN: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="45"
      height="45"
    >
      <g
        style={{
          opacity: '1',
          fill: 'none',
          fillOpacity: '1',
          fillRule: 'evenodd',
          stroke: '#000000',
          strokeWidth: '1.5',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: '4',
          strokeDasharray: 'none',
          strokeOpacity: '1'
        }}
      >
        <path
          d="M 22,10 C 32.5,11 38.5,18 38,39 L 15,39 C 15,30 25,32.5 23,18"
          style={{
            fill: '#ffffff',
            stroke: '#000000'
          }}
        />
        <path
          d="M 24,18 C 24.38,20.91 18.45,25.37 16,27 C 13,29 13.18,31.34 11,31 C 9.958,30.06 12.41,27.96 11,28 C 10,28 11.19,29.23 10,30 C 9,30 5.997,31 6,26 C 6,24 12,14 12,14 C 12,14 13.89,12.1 14,10.5 C 13.27,9.506 13.5,8.5 13.5,7.5 C 14.5,6.5 16.5,10 16.5,10 L 18.5,10 C 18.5,10 19.28,8.008 21,7 C 22,7 22,10 22,10"
          style={{
            fill: '#ffffff',
            stroke: '#000000'
          }}
        />
        <path
          d="M 9.5 25.5 A 0.5 0.5 0 1 1 8.5,25.5 A 0.5 0.5 0 1 1 9.5 25.5 z"
          style={{
            fill: '#000000',
            stroke: '#000000'
          }}
        />
        <path
          d="M 15 15.5 A 0.5 1.5 0 1 1  14,15.5 A 0.5 1.5 0 1 1  15 15.5 z"
          transform="matrix(0.866,0.5,-0.5,0.866,9.693,-5.173)"
          style={{
            fill: '#000000',
            stroke: '#000000'
          }}
        />
      </g>
    </svg>
  ),
  wB: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="45"
      height="45"
    >
      <g
        style={{
          opacity: '1',
          fill: 'none',
          fillRule: 'evenodd',
          fillOpacity: '1',
          stroke: '#000000',
          strokeWidth: '1.5',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: '4',
          strokeDasharray: 'none',
          strokeOpacity: '1'
        }}
      >
        <g
          style={{
            fill: '#ffffff',
            stroke: '#000000',
            strokeLinecap: 'butt'
          }}
        >
          <path d="M 9,36 C 12.39,35.03 19.11,36.43 22.5,34 C 25.89,36.43 32.61,35.03 36,36 C 36,36 37.65,36.54 39,38 C 38.32,38.97 37.35,38.99 36,38.5 C 32.61,37.53 25.89,38.96 22.5,37.5 C 19.11,38.96 12.39,37.53 9,38.5 C 7.646,38.99 6.677,38.97 6,38 C 7.354,36.06 9,36 9,36 z" />
          <path d="M 15,32 C 17.5,34.5 27.5,34.5 30,32 C 30.5,30.5 30,30 30,30 C 30,27.5 27.5,26 27.5,26 C 33,24.5 33.5,14.5 22.5,10.5 C 11.5,14.5 12,24.5 17.5,26 C 17.5,26 15,27.5 15,30 C 15,30 14.5,30.5 15,32 z" />
          <path d="M 25 8 A 2.5 2.5 0 1 1  20,8 A 2.5 2.5 0 1 1  25 8 z" />
        </g>
        <path
          d="M 17.5,26 L 27.5,26 M 15,30 L 30,30 M 22.5,15.5 L 22.5,20.5 M 20,18 L 25,18"
          style={{
            fill: 'none',
            stroke: '#000000',
            strokeLinejoin: 'miter'
          }}
        />
      </g>
    </svg>
  ),
  wQ: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="45"
      height="45"
    >
      <g
        style={{
          opacity: '1',
          fill: '#ffffff',
          fillOpacity: '1',
          fillRule: 'evenodd',
          stroke: '#000000',
          strokeWidth: '1.5',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: '4',
          strokeDasharray: 'none',
          strokeOpacity: '1'
        }}
      >
        <path
          d="M 9 13 A 2 2 0 1 1  5,13 A 2 2 0 1 1  9 13 z"
          transform="translate(-1,-1)"
        />
        <path
          d="M 9 13 A 2 2 0 1 1  5,13 A 2 2 0 1 1  9 13 z"
          transform="translate(15.5,-5.5)"
        />
        <path
          d="M 9 13 A 2 2 0 1 1  5,13 A 2 2 0 1 1  9 13 z"
          transform="translate(32,-1)"
        />
        <path
          d="M 9 13 A 2 2 0 1 1  5,13 A 2 2 0 1 1  9 13 z"
          transform="translate(7,-4.5)"
        />
        <path
          d="M 9 13 A 2 2 0 1 1  5,13 A 2 2 0 1 1  9 13 z"
          transform="translate(24,-4)"
        />
        <path
          d="M 9,26 C 17.5,24.5 30,24.5 36,26 L 38,14 L 31,25 L 31,11 L 25.5,24.5 L 22.5,9.5 L 19.5,24.5 L 14,10.5 L 14,25 L 7,14 L 9,26 z "
          style={{
            strokeLinecap: 'butt'
          }}
        />
        <path
          d="M 9,26 C 9,28 10.5,28 11.5,30 C 12.5,31.5 12.5,31 12,33.5 C 10.5,34.5 10.5,36 10.5,36 C 9,37.5 11,38.5 11,38.5 C 17.5,39.5 27.5,39.5 34,38.5 C 34,38.5 35.5,37.5 34,36 C 34,36 34.5,34.5 33,33.5 C 32.5,31 32.5,31.5 33.5,30 C 34.5,28 36,28 36,26 C 27.5,24.5 17.5,24.5 9,26 z "
          style={{
            strokeLinecap: 'butt'
          }}
        />
        <path d="M 11.5,30 C 15,29 30,29 33.5,30" style={{ fill: 'none' }} />
        <path
          d="M 12,33.5 C 18,32.5 27,32.5 33,33.5"
          style={{ fill: 'none' }}
        />
      </g>
    </svg>
  ),
  wK: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="45"
      height="45"
    >
      <g
        style={{
          fill: 'none',
          fillOpacity: '1',
          fillRule: 'evenodd',
          stroke: '#000000',
          strokeWidth: '1.5',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: '4',
          strokeDasharray: 'none',
          strokeOpacity: '1'
        }}
      >
        <path
          d="M 22.5,11.63 L 22.5,6"
          style={{
            fill: 'none',
            stroke: '#000000',
            strokeLinejoin: 'miter'
          }}
        />
        <path
          d="M 20,8 L 25,8"
          style={{
            fill: 'none',
            stroke: '#000000',
            strokeLinejoin: 'miter'
          }}
        />
        <path
          d="M 22.5,25 C 22.5,25 27,17.5 25.5,14.5 C 25.5,14.5 24.5,12 22.5,12 C 20.5,12 19.5,14.5 19.5,14.5 C 18,17.5 22.5,25 22.5,25"
          style={{
            fill: '#ffffff',
            stroke: '#000000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter'
          }}
        />
        <path
          d="M 11.5,37 C 17,40.5 27,40.5 32.5,37 L 32.5,30 C 32.5,30 41.5,25.5 38.5,19.5 C 34.5,13 25,16 22.5,23.5 L 22.5,27 L 22.5,23.5 C 19,16 9.5,13 6.5,19.5 C 3.5,25.5 11.5,29.5 11.5,29.5 L 11.5,37 z "
          style={{
            fill: '#ffffff',
            stroke: '#000000'
          }}
        />
        <path
          d="M 11.5,30 C 17,27 27,27 32.5,30"
          style={{
            fill: 'none',
            stroke: '#000000'
          }}
        />
        <path
          d="M 11.5,33.5 C 17,30.5 27,30.5 32.5,33.5"
          style={{
            fill: 'none',
            stroke: '#000000'
          }}
        />
        <path
          d="M 11.5,37 C 17,34 27,34 32.5,37"
          style={{
            fill: 'none',
            stroke: '#000000'
          }}
        />
      </g>
    </svg>
  ),
  bP: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="45"
      height="45"
    >
      <path
        d="M 22,9 C 19.79,9 18,10.79 18,13 C 18,13.89 18.29,14.71 18.78,15.38 C 16.83,16.5 15.5,18.59 15.5,21 C 15.5,23.03 16.44,24.84 17.91,26.03 C 14.91,27.09 10.5,31.58 10.5,39.5 L 33.5,39.5 C 33.5,31.58 29.09,27.09 26.09,26.03 C 27.56,24.84 28.5,23.03 28.5,21 C 28.5,18.59 27.17,16.5 25.22,15.38 C 25.71,14.71 26,13.89 26,13 C 26,10.79 24.21,9 22,9 z "
        style={{
          opacity: '1',
          fill: '#000000',
          fillOpacity: '1',
          fillRule: 'nonzero',
          stroke: '#000000',
          strokeWidth: '1.5',
          strokeLinecap: 'round',
          strokeLinejoin: 'miter',
          strokeMiterlimit: '4',
          strokeDasharray: 'none',
          strokeOpacity: '1'
        }}
      />
    </svg>
  ),
  bR: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="45"
      height="45"
    >
      <g
        style={{
          opacity: '1',
          fill: '000000',
          fillOpacity: '1',
          fillRule: 'evenodd',
          stroke: '#000000',
          strokeWidth: '1.5',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: '4',
          strokeDasharray: 'none',
          strokeOpacity: '1'
        }}
      >
        <path
          d="M 9,39 L 36,39 L 36,36 L 9,36 L 9,39 z "
          style={{
            strokeLinecap: 'butt'
          }}
        />
        <path
          d="M 12.5,32 L 14,29.5 L 31,29.5 L 32.5,32 L 12.5,32 z "
          style={{
            strokeLinecap: 'butt'
          }}
        />
        <path
          d="M 12,36 L 12,32 L 33,32 L 33,36 L 12,36 z "
          style={{
            strokeLinecap: 'butt'
          }}
        />
        <path
          d="M 14,29.5 L 14,16.5 L 31,16.5 L 31,29.5 L 14,29.5 z "
          style={{
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter'
          }}
        />
        <path
          d="M 14,16.5 L 11,14 L 34,14 L 31,16.5 L 14,16.5 z "
          style={{
            strokeLinecap: 'butt'
          }}
        />
        <path
          d="M 11,14 L 11,9 L 15,9 L 15,11 L 20,11 L 20,9 L 25,9 L 25,11 L 30,11 L 30,9 L 34,9 L 34,14 L 11,14 z "
          style={{
            strokeLinecap: 'butt'
          }}
        />
        <path
          d="M 12,35.5 L 33,35.5 L 33,35.5"
          style={{
            fill: 'none',
            stroke: '#ffffff',
            strokeWidth: '1',
            strokeLinejoin: 'miter'
          }}
        />
        <path
          d="M 13,31.5 L 32,31.5"
          style={{
            fill: 'none',
            stroke: '#ffffff',
            strokeWidth: '1',
            strokeLinejoin: 'miter'
          }}
        />
        <path
          d="M 14,29.5 L 31,29.5"
          style={{
            fill: 'none',
            stroke: '#ffffff',
            strokeWidth: '1',
            strokeLinejoin: 'miter'
          }}
        />
        <path
          d="M 14,16.5 L 31,16.5"
          style={{
            fill: 'none',
            stroke: '#ffffff',
            strokeWidth: '1',
            strokeLinejoin: 'miter'
          }}
        />
        <path
          d="M 11,14 L 34,14"
          style={{
            fill: 'none',
            stroke: '#ffffff',
            strokeWidth: '1',
            strokeLinejoin: 'miter'
          }}
        />
      </g>
    </svg>
  ),
  bN: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="45"
      height="45"
    >
      <g
        style={{
          opacity: '1',
          fill: 'none',
          fillOpacity: '1',
          fillRule: 'evenodd',
          stroke: '#000000',
          strokeWidth: '1.5',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: '4',
          strokeDasharray: 'none',
          strokeOpacity: '1'
        }}
      >
        <path
          d="M 22,10 C 32.5,11 38.5,18 38,39 L 15,39 C 15,30 25,32.5 23,18"
          style={{
            fill: '#000000',
            stroke: '#000000'
          }}
        />
        <path
          d="M 24,18 C 24.38,20.91 18.45,25.37 16,27 C 13,29 13.18,31.34 11,31 C 9.958,30.06 12.41,27.96 11,28 C 10,28 11.19,29.23 10,30 C 9,30 5.997,31 6,26 C 6,24 12,14 12,14 C 12,14 13.89,12.1 14,10.5 C 13.27,9.506 13.5,8.5 13.5,7.5 C 14.5,6.5 16.5,10 16.5,10 L 18.5,10 C 18.5,10 19.28,8.008 21,7 C 22,7 22,10 22,10"
          style={{
            fill: '#000000',
            stroke: '#000000'
          }}
        />
        <path
          d="M 9.5 25.5 A 0.5 0.5 0 1 1 8.5,25.5 A 0.5 0.5 0 1 1 9.5 25.5 z"
          style={{
            fill: '#ffffff',
            stroke: '#ffffff'
          }}
        />
        <path
          d="M 15 15.5 A 0.5 1.5 0 1 1  14,15.5 A 0.5 1.5 0 1 1  15 15.5 z"
          transform="matrix(0.866,0.5,-0.5,0.866,9.693,-5.173)"
          style={{
            fill: '#ffffff',
            stroke: '#ffffff'
          }}
        />
        <path
          d="M 24.55,10.4 L 24.1,11.85 L 24.6,12 C 27.75,13 30.25,14.49 32.5,18.75 C 34.75,23.01 35.75,29.06 35.25,39 L 35.2,39.5 L 37.45,39.5 L 37.5,39 C 38,28.94 36.62,22.15 34.25,17.66 C 31.88,13.17 28.46,11.02 25.06,10.5 L 24.55,10.4 z "
          style={{
            fill: '#ffffff',
            stroke: 'none'
          }}
        />
      </g>
    </svg>
  ),
  bB: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="45"
      height="45"
    >
      <g
        style={{
          opacity: '1',
          fill: 'none',
          fillRule: 'evenodd',
          fillOpacity: '1',
          stroke: '#000000',
          strokeWidth: '1.5',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: '4',
          strokeDasharray: 'none',
          strokeOpacity: '1'
        }}
      >
        <g
          style={{
            fill: '#000000',
            stroke: '#000000',
            strokeLinecap: 'butt'
          }}
        >
          <path d="M 9,36 C 12.39,35.03 19.11,36.43 22.5,34 C 25.89,36.43 32.61,35.03 36,36 C 36,36 37.65,36.54 39,38 C 38.32,38.97 37.35,38.99 36,38.5 C 32.61,37.53 25.89,38.96 22.5,37.5 C 19.11,38.96 12.39,37.53 9,38.5 C 7.646,38.99 6.677,38.97 6,38 C 7.354,36.06 9,36 9,36 z" />
          <path d="M 15,32 C 17.5,34.5 27.5,34.5 30,32 C 30.5,30.5 30,30 30,30 C 30,27.5 27.5,26 27.5,26 C 33,24.5 33.5,14.5 22.5,10.5 C 11.5,14.5 12,24.5 17.5,26 C 17.5,26 15,27.5 15,30 C 15,30 14.5,30.5 15,32 z" />
          <path d="M 25 8 A 2.5 2.5 0 1 1  20,8 A 2.5 2.5 0 1 1  25 8 z" />
        </g>
        <path
          d="M 17.5,26 L 27.5,26 M 15,30 L 30,30 M 22.5,15.5 L 22.5,20.5 M 20,18 L 25,18"
          style={{
            fill: 'none',
            stroke: '#ffffff',
            strokeLinejoin: 'miter'
          }}
        />
      </g>
    </svg>
  ),
  bQ: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="45"
      height="45"
    >
      <g
        style={{
          opacity: '1',
          fill: '000000',
          fillOpacity: '1',
          fillRule: 'evenodd',
          stroke: '#000000',
          strokeWidth: '1.5',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: '4',
          strokeDasharray: 'none',
          strokeOpacity: '1'
        }}
      >
        <g
          style={{
            fill: '#000000',
            stroke: 'none'
          }}
        >
          <circle cx="6" cy="12" r="2.75" />
          <circle cx="14" cy="9" r="2.75" />
          <circle cx="22.5" cy="8" r="2.75" />
          <circle cx="31" cy="9" r="2.75" />
          <circle cx="39" cy="12" r="2.75" />
        </g>
        <path
          d="M 9,26 C 17.5,24.5 30,24.5 36,26 L 38.5,13.5 L 31,25 L 30.7,10.9 L 25.5,24.5 L 22.5,10 L 19.5,24.5 L 14.3,10.9 L 14,25 L 6.5,13.5 L 9,26 z"
          style={{
            strokeLinecap: 'butt',
            stroke: '#000000'
          }}
        />
        <path
          d="M 9,26 C 9,28 10.5,28 11.5,30 C 12.5,31.5 12.5,31 12,33.5 C 10.5,34.5 10.5,36 10.5,36 C 9,37.5 11,38.5 11,38.5 C 17.5,39.5 27.5,39.5 34,38.5 C 34,38.5 35.5,37.5 34,36 C 34,36 34.5,34.5 33,33.5 C 32.5,31 32.5,31.5 33.5,30 C 34.5,28 36,28 36,26 C 27.5,24.5 17.5,24.5 9,26 z"
          style={{
            strokeLinecap: 'butt'
          }}
        />
        <path
          d="M 11,38.5 A 35,35 1 0 0 34,38.5"
          style={{
            fill: 'none',
            stroke: '#000000',
            strokeLinecap: 'butt'
          }}
        />
        <path
          d="M 11,29 A 35,35 1 0 1 34,29"
          style={{
            fill: 'none',
            stroke: '#ffffff'
          }}
        />
        <path
          d="M 12.5,31.5 L 32.5,31.5"
          style={{
            fill: 'none',
            stroke: '#ffffff'
          }}
        />
        <path
          d="M 11.5,34.5 A 35,35 1 0 0 33.5,34.5"
          style={{
            fill: 'none',
            stroke: '#ffffff'
          }}
        />
        <path
          d="M 10.5,37.5 A 35,35 1 0 0 34.5,37.5"
          style={{
            fill: 'none',
            stroke: '#ffffff'
          }}
        />
      </g>
    </svg>
  ),
  bK: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="45"
      height="45"
    >
      <g
        style={{
          fill: 'none',
          fillOpacity: '1',
          fillRule: 'evenodd',
          stroke: '#000000',
          strokeWidth: '1.5',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: '4',
          strokeDasharray: 'none',
          strokeOpacity: '1'
        }}
      >
        <path
          d="M 22.5,11.63 L 22.5,6"
          style={{
            fill: 'none',
            stroke: '#000000',
            strokeLinejoin: 'miter'
          }}
          id="path6570"
        />
        <path
          d="M 22.5,25 C 22.5,25 27,17.5 25.5,14.5 C 25.5,14.5 24.5,12 22.5,12 C 20.5,12 19.5,14.5 19.5,14.5 C 18,17.5 22.5,25 22.5,25"
          style={{
            fill: '#000000',
            fillOpacity: '1',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter'
          }}
        />
        <path
          d="M 11.5,37 C 17,40.5 27,40.5 32.5,37 L 32.5,30 C 32.5,30 41.5,25.5 38.5,19.5 C 34.5,13 25,16 22.5,23.5 L 22.5,27 L 22.5,23.5 C 19,16 9.5,13 6.5,19.5 C 3.5,25.5 11.5,29.5 11.5,29.5 L 11.5,37 z "
          style={{
            fill: '#000000',
            stroke: '#000000'
          }}
        />
        <path
          d="M 20,8 L 25,8"
          style={{
            fill: 'none',
            stroke: '#000000',
            strokeLinejoin: 'miter'
          }}
        />
        <path
          d="M 32,29.5 C 32,29.5 40.5,25.5 38.03,19.85 C 34.15,14 25,18 22.5,24.5 L 22.51,26.6 L 22.5,24.5 C 20,18 9.906,14 6.997,19.85 C 4.5,25.5 11.85,28.85 11.85,28.85"
          style={{
            fill: 'none',
            stroke: '#ffffff'
          }}
        />
        <path
          d="M 11.5,30 C 17,27 27,27 32.5,30 M 11.5,33.5 C 17,30.5 27,30.5 32.5,33.5 M 11.5,37 C 17,34 27,34 32.5,37"
          style={{
            fill: 'none',
            stroke: '#ffffff'
          }}
        />
      </g>
    </svg>
  ),

  // Rock
  bW: (
    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
      viewBox="0 0 387.481 387.481">
      <g>
        <g id="XMLID_7_">
          <g>
            <polygon style={{ fill: "#E6E6E6" }} points="314.734,261.389 320.944,301.239 267.194,311.989 283.324,252.859 			" />
            <polygon style={{ fill: "#CCCCCC" }} points="320.944,301.239 286.904,365.729 267.194,311.989 			" />
            <polygon style={{ fill: "#F2F2F2" }} points="299.444,163.279 314.734,261.389 283.324,252.859 252.864,233.159 270.773,186.569 			" />
            <polygon style={{ fill: "#E6E6E6" }} points="292.184,116.719 299.444,163.279 270.773,186.569 236.734,165.079 233.154,131.039 			" />
            <polygon style={{ fill: "#F2F2F2" }} points="282.664,55.609 292.184,116.719 233.154,131.039 256.444,84.449 			" />
            <polygon style={{ fill: "#F2F2F2" }} points="267.194,311.989 286.904,365.729 188.364,387.229 125.664,303.029 			" />
            <polygon style={{ fill: "#CCCCCC" }} points="283.324,252.859 267.194,311.989 125.664,303.029 138.204,98.789 224.194,57.579 
      				256.444,84.449 233.154,131.039 236.734,165.079 270.773,186.569 252.864,233.159 			"/>
            <polygon style={{ fill: "#E6E6E6" }} points="277.944,25.329 282.664,55.609 256.444,84.449 224.194,57.579 			" />
            <polygon style={{ fill: "#CCCCCC" }} points="277.944,25.329 224.194,57.579 243.904,0.249 			" />
            <polygon style={{ fill: "#F2F2F2" }} points="243.904,0.249 224.194,57.579 138.204,98.789 174.034,18.169 			" />
            <polygon style={{ fill: "#B3B3B3" }} points="125.664,303.029 188.364,387.229 66.534,310.189 			" />
            <polygon style={{ fill: "#B3B3B3" }} points="174.034,18.169 138.204,98.789 86.244,91.619 			" />
            <polygon style={{ fill: "#999999" }} points="138.204,98.789 125.664,303.029 66.534,310.189 86.244,91.619 			" />
          </g>
          <g>
          </g>
        </g>
      </g>
    </svg>
  ),

  // Ghost
  bX: (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
      viewBox="0 0 512.002 512.002" style={{ enableBackground: "new 0 0 512.002 512.002" }}>
      <path style={{ fill: "#D6D9DB" }} d="M384.025,288c-11.736,0-23.04-6.488-28.64-17.696c-7.904-15.808-1.504-35.024,14.304-42.928
	l64-31.992c15.808-7.896,35.024-1.504,42.928,14.304c7.896,15.816,1.496,35.032-14.312,42.928l-63.992,31.992
	C393.721,286.904,388.833,288,384.025,288z"/>
      <g>
        <path style={{ fill: "#E2E5E7" }} d="M256.001,0c-79.52,0-144,64.472-144,144v350.424c0,17.48,10.12,22.832,22.488,11.92l27.032-26.36
		c12.368-10.912,32.6-10.912,44.968,0l27.032,23.832c12.368,10.912,32.6,10.912,44.968,0l27.032-23.832
		c12.368-10.912,32.6-10.912,44.968,0l27.032,26.36c12.36,10.912,22.48,5.552,22.48-11.92V144C400.001,64.472,335.529,0,256.001,0z"
        />
        <path style={{ fill: "#E2E5E7" }} d="M127.985,288c-4.816,0-9.688-1.096-14.288-3.392l-64-31.992
		c-15.808-7.896-22.208-27.12-14.312-42.928c7.904-15.808,27.112-22.2,42.928-14.304l64,31.992
		c15.808,7.904,22.208,27.128,14.312,42.928C151.017,281.512,139.721,288,127.985,288z"/>
      </g>
      <g>
        <path style={{ fill: "#BAC1C6" }} d="M112.001,283.784c0,0,0-54.728,0-43.784c0,16-19.464,34.056-19.464,34.056L112.001,283.784z" />
        <path style={{ fill: "#BAC1C6" }} d="M400.009,283.784c0,0,0-54.728,0-43.784c0,16,19.464,34.056,19.464,34.056L400.009,283.784z" />
      </g>
      <g>
        <circle style={{ fill: "#123247" }} cx="212.001" cy="172" r="20" />
        <circle style={{ fill: "#123247" }} cx="300.001" cy="172" r="20" />
      </g>
      <g>
        <circle style={{ fill: "#E2E5E7" }} cx="204.001" cy="164" r="4" />
        <circle style={{ fill: "#E2E5E7" }} cx="292.001" cy="164" r="4" />
      </g>
      <path style={{ fill: "#123247" }} d="M256.001,288c-17.672,0-32-14.328-32-32l0,0c0-17.672,14.328-32,32-32c17.664,0,32,14.328,32,32l0,0
	C288.001,273.672,273.673,288,256.001,288z"/>
      <path style={{ fill: "#F15642" }} d="M280.001,261.984c0,9.944-10.744,18.016-24,18.016c-13.256,0-24-8.072-24-18.016
	c0-9.936,10.744-4.496,24-4.496C269.257,257.488,280.001,252.048,280.001,261.984z"/>
      <path style={{ fill: "#FFFFFF" }} d="M277.625,17.976C270.577,16.768,263.393,16,256.001,16c-70.28,0-127.256,56.976-127.256,127.256
	c0,4.464,0.24,8.872,0.688,13.216C139.009,81.256,200.961,22.568,277.625,17.976z"/>
      <path style={{ fill: "#D6D9DB" }} d="M283.241,2.656c41.488,28.032,68.76,75.504,68.76,129.344c0,1.352-0.168,2.656-0.2,4h0.2v192
	c0,69.8-72,160-136.704,159.752l18.224,16.064c12.368,10.912,32.6,10.912,44.968,0l27.032-23.832
	c12.368-10.912,32.6-10.912,44.968,0l1.512,1.48l6.712,6.536h-0.008l1.296,1.264v-0.008l17.512,17.088
	c12.368,10.912,22.488,5.552,22.488-11.92V144C400.001,73.792,349.737,15.4,283.241,2.656z"/>
    </svg>

  ),
  
  // Cactus
  bZ: (
    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
      viewBox="0 0 433.258 433.258" style={{enableBackground: "new 0 0 433.258 433.258"}}>
      <g>
        <path style={{fill: "#CCFF66"}} d="M135.983,34.826c-1.463-14.15-13.275-24.82-27.477-24.82c-0.956,0-8.748-0.28-16.23,5.23
		c8.819,8.497,22.343,21.472,39.277,37.68C135.082,47.591,136.649,41.263,135.983,34.826z"/>
        <path style={{fill: "#669933"}} d="M294.002,143.167c0-59.166-31.852-107.727-71.974-111.48v208.646h32.885
		C279.06,220.397,294.002,183.376,294.002,143.167z"/>
        <path style={{fill: "#669933"}} d="M85.352,22.471c-3.51,5.316-5.064,11.624-4.4,18.044c1.462,14.15,13.274,24.821,27.477,24.821
		c0.956,0,9.523-0.065,16.198-5.208C105.057,41.401,92.912,29.764,85.352,22.471z"/>
        <path style={{fill: "#669933"}} d="M179.144,240.332h32.885V31.686c-40.122,3.754-71.974,52.315-71.974,111.48
		C140.056,183.378,154.998,220.399,179.144,240.332z"/>
        <path style={{fill: "#CCFF66"}} d="M140.11,240.332h24.429c-21.511-22.793-34.482-58.725-34.483-97.165
		c0-32.196,8.85-62.528,24.918-85.408c4.305-6.13,8.996-11.531,13.995-16.178c-49.336,24.398-70.544,69.32-70.544,108.469
		C98.424,184.924,113.595,217.726,140.11,240.332z"/>
        <path style={{fill: "#CCFF66"}} d="M265.187,41.672c4.962,4.626,9.62,9.997,13.897,16.087c16.068,22.88,24.918,53.212,24.918,85.408
		c0,38.439-12.972,74.371-34.483,97.165h24.428c26.515-22.605,41.687-55.407,41.687-90.282
		C335.633,101.788,306.655,60.171,265.187,41.672z"/>
        <path style={{fill: "#CC6633"}} d="M95.101,266.333c0,8.822,7.177,16,15.999,16h211.856c8.822,0,16-7.178,16-16
		c0-8.823-7.178-16.001-16-16.001H111.1C102.278,250.332,95.101,257.509,95.101,266.333z"/>
        <polygon style={{fill: "#FF6666"}} points="131.618,423.258 302.44,423.258 316.313,292.333 117.744,292.333 	" />
        <path style={{fill: "#663333"}} d="M357.458,146.241h-11.886c-0.54-18.455-4.984-35.947-12.537-51.677l10.821-5.439
		c2.467-1.241,3.462-4.246,2.221-6.714c-1.239-2.467-4.244-3.461-6.713-2.221l-11.009,5.533
		c-12.719-21.926-31.732-39.759-54.542-51.034l6.083-11.188c1.319-2.427,0.422-5.463-2.004-6.781
		c-2.428-1.322-5.463-0.422-6.781,2.004l-6.458,11.879c-14.737-5.898-30.804-9.158-47.622-9.158c-0.001,0-0.001,0-0.001,0h-0.001
		h-0.001h-0.001c-0.006,0-0.012,0-0.018,0c-21.506,0-33.227,3.383-47.439,9.029l-6.389-11.75c-1.318-2.426-4.355-3.326-6.781-2.004
		c-2.426,1.318-3.323,4.354-2.004,6.781l6.004,11.043c-5.036,2.479-9.925,5.307-14.65,8.463c0.43-3.016,0.5-6.102,0.179-9.21
		c-0.769-7.431-3.641-14.151-7.982-19.61c2.805-2.891,3.705-3.792,3.99-4.067c1.672-1.4,2.285-3.79,1.349-5.878
		c-1.129-2.52-4.088-3.648-6.608-2.517c-0.898,0.403-1.061,0.487-5.941,5.53c-6.243-4.571-13.936-7.249-22.23-7.249
		c-1.298,0-12.085-0.507-23.505,8.203c-2.248-2.181-3.644-3.55-4.085-4.008c-1.4-1.673-3.793-2.286-5.883-1.349
		c-2.52,1.132-3.644,4.091-2.512,6.609c0.254,0.566,0.411,0.912,5.559,5.956c-5.539,7.546-8.046,16.75-7.076,26.126
		c0.769,7.436,3.644,14.159,7.988,19.62l-4.317,4.513c-1.908,1.996-1.837,5.161,0.159,7.069c0.969,0.926,2.212,1.386,3.454,1.386
		c1.317,0,2.633-0.518,3.615-1.545l4.3-4.494c12.749,9.236,26.221,7.029,26.264,7.025c-2.437,3.389-4.704,6.885-6.789,10.48
		L94.916,80.19c-2.469-1.24-5.473-0.246-6.713,2.221c-1.241,2.468-0.246,5.473,2.221,6.714l10.573,5.314
		C93.336,110.421,89.01,128,88.481,146.241H76.599c-2.762,0-5,2.238-5,5s2.238,5,5,5h11.975c0.831,17.436,5.17,34.275,12.585,49.648
		l-10.579,5.317c-2.467,1.241-3.462,4.246-2.221,6.714c0.878,1.746,2.641,2.755,4.471,2.755c0.755,0,1.521-0.172,2.242-0.534
		l10.78-5.418c5.391,9.252,11.953,17.863,19.599,25.608H111.1c-14.336,0-25.999,11.664-25.999,26.001
		c0,13.172,9.847,24.078,22.563,25.766l14.483,136.688c0.27,2.543,2.415,4.473,4.973,4.473h179.817c2.558,0,4.703-1.93,4.972-4.474
		l14.483-136.687c12.717-1.688,22.563-12.594,22.563-25.766c0-14.337-11.663-26.001-26-26.001h-14.351
		c7.687-7.786,14.277-16.448,19.686-25.757l11.073,5.566c0.721,0.362,1.487,0.534,2.242,0.534c1.83,0,3.593-1.009,4.471-2.755
		c1.241-2.468,0.246-5.473-2.221-6.713l-10.884-5.472c7.37-15.331,11.682-32.118,12.511-49.495h11.975c2.762,0,5-2.238,5-5
		S360.22,146.241,357.458,146.241z M222.029,31.686c40.122,3.754,71.974,52.314,71.974,111.48c0,40.209-14.942,77.23-39.089,97.165
		h-32.885V31.686z M212.029,31.686v208.646h-32.885c-24.146-19.933-39.088-56.954-39.089-97.165
		C140.055,84.002,171.907,35.44,212.029,31.686z M108.506,10.006c14.201,0,26.014,10.67,27.477,24.82
		c0.666,6.438-0.9,12.765-4.43,18.09c-16.935-16.208-30.458-29.183-39.277-37.68C99.758,9.725,107.55,10.006,108.506,10.006z
		 M108.428,65.337c-14.202,0-26.015-10.671-27.477-24.821c-0.664-6.42,0.891-12.729,4.4-18.044
		c7.561,7.293,19.705,18.93,39.274,37.657C117.951,65.271,109.384,65.337,108.428,65.337z M98.424,150.049
		c0-39.148,21.208-84.071,70.544-108.469c-4.999,4.646-9.69,10.048-13.995,16.178c-16.068,22.88-24.918,53.212-24.918,85.408
		c0.001,38.44,12.973,74.372,34.483,97.165H140.11C113.595,217.726,98.424,184.924,98.424,150.049z M302.44,423.258H131.618
		l-13.874-130.926h198.568L302.44,423.258z M322.956,250.332c8.822,0,16,7.178,16,16.001c0,8.822-7.178,16-16,16H111.1
		c-8.822,0-15.999-7.178-15.999-16c0-8.823,7.177-16.001,15.999-16.001H322.956z M293.947,240.332h-24.428
		c21.512-22.794,34.483-58.726,34.483-97.165c0-32.196-8.85-62.528-24.918-85.408c-4.277-6.09-8.935-11.461-13.897-16.087
		c41.468,18.499,70.446,60.116,70.446,108.378C335.633,184.924,320.461,217.727,293.947,240.332z"/>
      </g>
    </svg>

  ),
  
  // Star
  bA: (
    <svg viewBox="0 0 282.3 270.1" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="a" gradientUnits="userSpaceOnUse" x1="338.9" x2="322.8" y1="292.1" y2="203.1">
          <stop offset="0" stopColor="#e6d82f" />
          <stop offset="1" stopColor="#faf26f" />
        </linearGradient>
        <linearGradient id="b" gradientUnits="userSpaceOnUse" x1="391.9" x2="341.2" y1="310.6" y2="310.6">
          <stop offset="0" stopColor="#d2c308" />
          <stop offset="1" stopColor="#e8da34" />
        </linearGradient>
      </defs>
      <g fillRule="evenodd" transform="translate(-198.9 -145.7)">
        <path d="m340.1 292.8l-.4-140.5-32.16 98.6z" fill="url(#a)" />
        <path d="m340.2 152.4l31.45 97.64-31.04 42.7z" fill="#d2c308" />
        <path d="m341.1 292.4l133.8-42.39-103.6-.01z" fill="#faf26f" />
        <path d="m341 293.1l50.85 17.34 83.1-60.32z" fill="#a29910" />
        <path d="m289.1 310.6l50.32-17.12-134.2-43.5z" fill="#d2c308" />
        <path d="m339.6 293.8l-82.67 116.1 32.22-99.1z" fill="#faf26f" />
        <path d="m341.1 293.6l50.78 16.82 31.72 97.69z" fill="url(#b)" />
        <path d="m340.2 348.6l83.08 59.65-82.56-114.4z" fill="#a29910" />
        <path d="m306.9 251.2l33.56 42.72-135.6-43.9z" fill="#faf26f" />
        <path d="m340.2 348.6l-82.9 59.3 83.03-114.5z" fill="#a29910" />
      </g>
    </svg>
  ),

  // Straberry
  bE: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" enableBackground="new 0 0 64 64">
      <path d="m23.5 13.1c1.5-3.9 12.2-11.1 12.2-11.1s3.4 12.9 2 16.7c-1.4 3.9-5.7 5.7-9.6 4.2-4-1.6-6-6-4.6-9.8" fill="#8cc63e" />
      <path d="m17.2 20.8c-1.8-3.7.8-16.5.8-16.5s11.4 6.7 13.1 10.5c1.7 3.7 0 8.1-3.9 9.8-3.8 1.6-8.3-.1-10-3.8" fill="#64892f" />
      <path d="m20.7 17.2c-3.7-1.7-16.4.8-16.4.8s6.7 11.5 10.4 13.3c3.7 1.7 8.1 0 9.7-3.9 1.7-3.9 0-8.5-3.7-10.2" fill="#8cc63e" />
      <path d="m13 23.6c-3.8 1.5-11 12.3-11 12.3s12.8 3.4 16.7 2c3.8-1.4 5.7-5.8 4.1-9.7-1.6-4-5.9-6-9.8-4.6" fill="#64892f" />
      <path d="m51.5 16.7c11.5 11.6 12.8 36.7 7.4 42.2-5.5 5.5-30.4 4.1-42-7.5-8.5-8.5-4.5-21.4 4.3-30.3 8.9-8.9 21.8-12.9 30.3-4.4" fill="#ef4d3c" />
      <g fill="#fff0f0">
        <path d="m40.7 15.7c-.5 0-1 .2-1.3.6-.5.8-.1 2 1.1 2.8.6.4 1.2.6 1.7.6.5 0 1-.2 1.2-.6.5-.8.1-2-1.1-2.8-.4-.4-1-.6-1.6-.6" />
        <path d="m44.4 33.2c-.3 0-.6.1-.9.3-.7.6-.5 1.9.3 3 .6.7 1.4 1.1 2 1.1.3 0 .6-.1.9-.3.7-.6.5-1.9-.3-3-.6-.7-1.4-1.1-2-1.1" />
        <path d="m35.8 24.6c-.3 0-.6.1-.9.3-.7.6-.6 1.9.3 3 .6.7 1.4 1.1 2 1.1.3 0 .6-.1.9-.3.7-.6.6-1.9-.3-3-.6-.7-1.4-1.1-2-1.1" />
        <path d="m50.5 25.6c-.2 0-.4 0-.6.1-.8.4-.9 1.8-.3 3 .5.9 1.3 1.5 2.1 1.5.2 0 .4 0 .6-.1.8-.4.9-1.8.3-3-.5-.9-1.4-1.5-2.1-1.5" />
        <path d="m55.1 38.8c-.1 0-.2 0-.3 0-.9.2-1.4 1.5-1 2.8.3 1.2 1.1 2 2 2 .1 0 .2 0 .3 0 .9-.2 1.4-1.5 1-2.8-.4-1.2-1.2-2-2-2" />
        <path d="m53.9 51.2c-.3 0-.7.1-.9.3-.7.6-.5 2 .4 3 .6.7 1.4 1 2 1 .3 0 .7-.1.9-.3.7-.6.5-2-.4-3-.6-.6-1.3-1-2-1" />
        <path d="m31.7 49.7c-.5 0-1 .2-1.3.6-.5.8 0 2 1.1 2.8.6.4 1.2.6 1.7.6.5 0 1-.2 1.3-.6.5-.8 0-2-1.1-2.8-.5-.4-1.1-.6-1.7-.6" />
        <path d="m20.2 46.4c-.6 0-1 .2-1.3.6-.5.8 0 2 1.1 2.8.6.4 1.2.6 1.7.6.5 0 1-.2 1.3-.6.5-.8 0-2-1.1-2.8-.6-.4-1.2-.6-1.7-.6" />
        <path d="m41.6 54.5c-.8 0-1.4.3-1.7.9-.3.9.4 2 1.7 2.5.4.2.9.3 1.3.3.8 0 1.4-.3 1.7-.9.3-.9-.4-2-1.7-2.5-.4-.2-.9-.3-1.3-.3" />
        <path d="m43.9 44.5c-.6 0-1.1.2-1.4.7-.5.8.1 2 1.3 2.7.5.3 1.1.5 1.6.5.6 0 1.1-.2 1.4-.7.5-.8-.1-2-1.3-2.7-.6-.3-1.1-.5-1.6-.5" />
        <path d="m33.2 37.4c-.5 0-.9.2-1.1.5-.6.7-.2 2 .8 2.9.6.5 1.3.7 1.9.7.5 0 .9-.2 1.1-.5.6-.7.2-2-.8-2.9-.6-.4-1.3-.7-1.9-.7" />
        <path d="m24.5 23.9c-.4 0-.7.1-1 .4-.7.7-.4 2 .5 2.9.6.6 1.3.9 2 .9.4 0 .7-.1 1-.4.7-.7.4-2-.5-2.9-.6-.6-1.3-.9-2-.9" />
        <path d="m21.7 34.7c-.5 0-.9.2-1.2.5-.6.7-.2 2 .9 2.9.6.5 1.3.7 1.8.7.5 0 .9-.2 1.2-.5.6-.7.2-2-.9-2.9-.6-.4-1.3-.7-1.8-.7" />
      </g>
    </svg>
  ),
  
  // Apple
  bI: (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
      viewBox="0 0 512 512" space="preserve">
      <path style={{fill: "#B27214"}} d="M256.003,166.965c-5.485,0-9.932-4.447-9.932-9.932v-29.968c0-35.247,17.457-68.082,46.697-87.833
	c4.546-3.071,10.72-1.875,13.79,2.671c3.071,4.546,1.875,10.72-2.671,13.79c-23.764,16.053-37.951,42.734-37.951,71.373v29.968
	C265.935,162.518,261.488,166.965,256.003,166.965z"/>
      <path style={{fill: "#9AD14B"}} d="M255.407,110.114c-60.811,0-110.114-49.303-110.114-110.114c30.405,0,57.937,12.329,77.867,32.259
	C243.078,52.177,255.407,79.708,255.407,110.114z"/>
      <path style={{fill: "#F95428"}} d="M293.506,137.698c109.412,0,185.65,108.604,148.478,211.514l-33.796,93.507
	c-2.794,7.734-6.41,15.017-10.727,21.745c-5.906,9.177-13.137,17.348-21.361,24.261h-0.013
	c-7.442,6.264-15.706,11.495-24.565,15.574c-20.685,9.482-44.35,10.078-65.777,2.49c-5.072-1.788-10.25-3.099-15.494-3.96
	c-4.714-0.768-9.495-1.139-14.249-1.139c-10.065,0-20.116,1.695-29.743,5.098c-7.138,2.516-14.501,4.145-21.93,4.834
	c-14.911,1.377-30.048-0.993-43.847-7.323c-25.837-11.852-46.588-33.676-56.679-61.579l-33.782-93.507
	c-37.186-102.91,39.053-211.514,148.465-211.514h37.517L293.506,137.698L293.506,137.698z"/>
      <path style={{fill: "#E54728"}} d="M193.854,442.719l-33.782-93.507c-36.328-100.535,35.601-206.484,140.949-211.323
	c-2.488-0.114-4.99-0.192-7.515-0.192h-37.504h-37.517c-109.412,0-185.65,108.604-148.465,211.514l33.782,93.507
	c10.091,27.903,30.842,49.727,56.679,61.579c13.799,6.33,28.935,8.701,43.847,7.323c7.429-0.689,14.792-2.317,21.93-4.834
	c6.531-2.308,13.26-3.811,20.054-4.553C222.447,490.009,203.389,469.083,193.854,442.719z"/>
      <path style={{fill: "#90BC42"}} d="M255.407,110.114c-60.811,0-110.114-49.303-110.114-110.114L255.407,110.114z" />
    </svg>

  ),
};

standard = { 
  ...standard,
  wW: standard.bW,
  wX: standard.bX,
  wZ: standard.bZ,
  wA: standard.bA,
  wE: standard.bE,
  wI: standard.bI,
}

export default standard;