import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import * as actions from "../../../redux/actions/UsersActions";
import {API_ENDPOINT} from "../../../constants/config";
import PaymentTool from "../../balance/tools/PaymentTool";
import NiceModal from "../../modals/NiceModal";

export default function UserProfileBalancePanel(props) {
    const {userId} = props;
    const dispatch = useDispatch();
    const [balance, setBalance] = useState(undefined);
    const [modalVisible, setModalVisible] = useState(false);
    let {user, loading, uId} = useMappedState(useCallback(state => {
        let uId = (userId == undefined) ? state.users.currentUserId : userId;
        let user = state.users.usersMap.get(uId);
        return {
            user: user,
            loading: state.users.loading,
            uId: uId
        }
    }, []));
    useEffect(() => {
        dispatch(actions.loadUserById(uId));
        axios.get(`${API_ENDPOINT}/user/${uId}/balance`).then(d => d.data).then(pld => {
            console.log('balance: pld = ', pld);
            setBalance(pld.balance);
        })
    }, [uId]);

    return (
        <Wrapper>

            <Row>
                <CurrentBalanceValue>
                    {`${balance == undefined ? '...' : `${balance} ₽`}`}
                </CurrentBalanceValue>
                <SubLabel>
                    На Вашем счёте
                </SubLabel>
                <ButtonPlaceholder>
                    <Button onClick={() => {
                        setModalVisible(true);
                    }}>
                        ПОПОЛНИТЬ СЧЁТ
                    </Button>
                </ButtonPlaceholder>
            </Row>

            {modalVisible == false ? null :
                <NiceModal
                           onClose={() => {
                               setModalVisible(false);
                           }}>

                    {user == undefined ? null :
                        <PaymentTool
                            userId={uId}
                            email={user.email}
                        />
                    }

                </NiceModal>
            }


        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Row = styled.div`
    text-align: center;
`;

const CurrentBalanceValue = styled.div`
    color: #FF624F;
    font-style: normal;
    font-weight: bold;
    font-size: 96px;
    line-height: 98px;
    margin-bottom: 20px;
    font-family: Montserrat;
`;

const SubLabel = styled.div`
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.171429px;
    color: rgba(31, 32, 65, 0.7);
    margin-bottom: 22px;
`;

const ButtonPlaceholder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Button = styled.div`
    border-radius: 100px;
    background: #085BFF;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 55px;
    padding-right: 55px;
    height: 44px;
    color: white;
    font-weight: bold;
    cursor: pointer;
    :hover{
      opacity: 0.9;
    }
`;

