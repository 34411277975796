import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {API_ENDPOINT} from "../../../constants/config";
import UpdateWebinarsTool from "./tools/UpdateWebinarsTool";

export default function UpdateWebinarsPanel(props) {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        axios.get(`${API_ENDPOINT}/key/landing_webinar_items`).then(d => d.data.result).then(a => {
            console.log('landing_webinar_items: a = ', a);
            let arr = (a == undefined) ? [] : a;
            setItems(arr);
            setLoading(false);
        });
    }, []);

    return (
        <Wrapper>
            <UpdateWebinarsTool loading={loading}
                                items={items}
                                onSave={async a => {
                                    setLoading(true);
                                    await axios.post(`${API_ENDPOINT}/key/landing_webinar_items`, {
                                        data: a
                                    });
                                    let arr = (await axios.get(`${API_ENDPOINT}/key/landing_webinar_items`)).data.result;
                                    setItems(arr);
                                    setLoading(false);
                                }}/>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;
