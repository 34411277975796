import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const SmartExercisesAPI = {

    async getUserExercises(userId) {
        let pld = (await axios.get(`${API_ENDPOINT}/user/${userId}/exercises`)).data.result;
        return pld;
    },

    async getUserRatingsArray(userId, tag) {
        let params = {};
        if (tag != undefined) {
            params.tag = tag;
        }
        let pld = (await axios.get(`${API_ENDPOINT}/user/${userId}/subs-exercises-ratings`, {
            params: params
        })).data.result;
        return pld;
    },

    async createSmartExercise(data) {
        let pld = (await axios.post(`${API_ENDPOINT}/smart_exercise`, data)).data;
        return pld.result;
    },

    async getSmartExercise(id) {
        let pld = (await axios.get(`${API_ENDPOINT}/smart_exercise/${id}`)).data;
        return pld.result;
    },

    async getAllSmartExercises() {
        let pld = (await axios.get(`${API_ENDPOINT}/smart_exercises/all`)).data;
        return pld.result;
    },

    async getUserSmartExercise(userId) {
        let options = (userId == undefined) ? undefined : {params: {userId: userId}}
        let pld = (await axios.get(`${API_ENDPOINT}/smart_exercises/all`, options)).data;
        return pld.result;
    },

    async updateSmartExercise(data) {
        let pld = (await axios.put(`${API_ENDPOINT}/smart_exercise`, data)).data;
        return pld.result;
    },

    async deleteSmartExercise(id) {
        let pld = (await axios.delete(`${API_ENDPOINT}/smart_exercise/${id}`)).data;
        return pld.result;
    },

    async saveUserExerciseAnswer(exerciseId, d) {
        console.log('saveUserExerciseAnswer: d = ', d);
        let pld = (await axios.post(`${API_ENDPOINT}/smart_exercise/${exerciseId}/save_user_answer`, {
            answer: d
        })).data;
        return pld.result;
    },

    async getUserExerciseResultsMap(exerciseId, d) {
        console.log('saveUserExerciseAnswer: d = ', d);
        let pld = (await axios.get(`${API_ENDPOINT}/smart_exercise/${exerciseId}/user_answers_map`)).data;
        return pld.result;
    }


}

export default SmartExercisesAPI;
