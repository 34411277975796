import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';
import {useDispatch, useMappedState} from 'redux-react-hook'

import HorChessTemplate from '../templates/HorChessTemplate'
import StudentPersonalPanel from "../students/panels/StudentPersonalPanel";
import IraUserLessonsPanel from "../lessons/panels/IraUserLessonsPanel";
import IraTeacherTemplate from "../templates/IraTeacherTemplate";
import CommonHelper from "../../helpers/CommonHelper";
import UserCopeLessonPanel from "../lessons/panels/UserCopeLessonPanel";
import StudentCoursePanel from "../courses/panels/StudentCoursePanel";


export default function StudentCourseApp(props) {
    let {id} = props.match.params;

    const {currentUserId, currentUser} = useMappedState(useCallback(state => ({
        currentUserId: state.users.currentUserId,
        currentUser: state.users.usersMap.get(state.users.currentUserId)
    }), []));
    const dispatch = useDispatch();

    if (currentUser == undefined) {
        return null;
    }

    return (
        <IraTeacherTemplate active={`course/${id}`}>

            <StudentCoursePanel id={id} />

        </IraTeacherTemplate>
    );
}

const Wrapper = styled.div`
    padding-top: 31px;
    padding-left: 65px;
    padding-right: 65px;
    box-sizing: border-box;
    @media(max-width: 1300px){
      padding-left: 15px;
      padding-right: 15px;
    }
`;


const topHeight = 70;

const HorHeading = styled.div`
    width: 100%;
    height: ${topHeight}px;
    background: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding-left: 40px;
    padding-right: 40px;
`;

