import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import LessonsAPI from "../../../api/LessonsAPI";
import PairEditorTool from "../tools/PairEditorTool";
import ChessHelper from "../../../helpers/ChessHelper";
import CommonHelper from "../../../helpers/CommonHelper";

const getUserById = (users, id) => {
    return (users.filter(a => (a.id == id)))[0];
}


export default function LessonPairsPanel(props) {
    const {
        id,
        users = [],
        onChange = newPairs => {

        },
        studentId
    } = props;
    const [lesson, setLesson] = useState(undefined);
    const [loading, setLoading] = useState(false);
    let canEdit = (studentId == undefined);

    useEffect(() => {
        setLoading(true);
        LessonsAPI.getLesson(id).then(l => {
            setLesson(l);
            setLoading(false);
        });
    }, [id]);

    let pairs = (lesson == undefined || lesson.pairs == undefined) ? [] : lesson.pairs;
    let enhPairs = pairs.map(a => ({
        ...a,
        blackUser: getUserById(users, a.blackUserId),
        whiteUser: getUserById(users, a.whiteUserId)
    }));

    let goodPairs = (studentId == undefined) ? enhPairs : enhPairs.filter(a => (a.blackUserId == studentId || a.whiteUserId == studentId));


    return (
        <Wrapper>

            <PairsList>

                {goodPairs.map((p, k) => {
                    const fenUrl = ChessHelper.getFenImageUrl(p.fen);
                    let {whiteUser, blackUser, active = true, whiteUserId, blackUserId} = p;
                    return (
                        <PairItem key={p.id} style={{cursor: (canEdit == true) ? 'default' : 'pointer'}}
                                  onClick={() => {
                                      if (canEdit == false) {
                                          CommonHelper.linkTo(`/play/board_two_l_${id}_w_${whiteUserId}_b_${blackUserId}`, true);
                                      }
                                  }}>
                            <BoardImage src={fenUrl}/>
                            <div>
                                <UserPlaceholder>
                                    <FigureImg src={require('../../../assets/images/pieces/white_king.svg')}/>
                                    {CommonHelper.getUserName(whiteUser)}
                                </UserPlaceholder>
                                <UserPlaceholder>
                                    <FigureImg src={require('../../../assets/images/pieces/black_king.svg')}/>
                                    {CommonHelper.getUserName(blackUser)}
                                </UserPlaceholder>

                                {canEdit == false ? null :
                                    <UserPlaceholder>
                                        <RowSpan
                                            style={{textDecoration: 'underline', marginRight: 20, cursor: 'pointer'}}
                                            onClick={() => {
                                                CommonHelper.linkTo(`/play/board_two_l_${id}_w_${whiteUserId}_b_${blackUserId}`, true);
                                            }}>
                                            {'перейти'}
                                        </RowSpan>

                                        <RowSpan style={{
                                            textDecoration: 'underline',
                                            marginRight: 20,
                                            cursor: 'pointer',
                                            color: 'red'
                                        }} onClick={() => {
                                            if (window.confirm('Вы уверены?') == false) {
                                                return;
                                            }
                                            let newPairs = pairs.filter(a => (a.id != p.id));
                                            onChange(newPairs);
                                        }}>
                                            {'удалить'}
                                        </RowSpan>

                                        <RowSpan>
                                            <FigureImg
                                                style={{height: 16}}
                                                src={active == true ? require('../../../assets/images/check-box-empty.svg') : require('../../../assets/images/check.svg')}
                                                onClick={() => {
                                                    let newPairs = pairs.map(a => (a.id == p.id ? ({
                                                        ...a,
                                                        active: !active,
                                                        updT: +new Date()
                                                    }) : a));
                                                    onChange(newPairs);
                                                }}
                                            />
                                            {'Завершена'}
                                        </RowSpan>

                                    </UserPlaceholder>
                                }
                                <UserPlaceholder>

                                </UserPlaceholder>
                            </div>
                        </PairItem>
                    )
                })}

            </PairsList>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const RowSpan = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const PairsList = styled.div`
    
`;

const PairItem = styled.div`
    margin-bottom: 10px;
    padding: 15px;
    border: 1px solid whitesmoke;
    background: whitesmoke;
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    :hover{
      
    }
`;

const UserPlaceholder = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    padding: 10px;
    font-size: 16px;
`;

const FigureImg = styled.img`
    height: 24px;
    margin-right: 7px;
`;

const BoardImage = styled.img`
    width: 120px;
    height: 120px;
`;
