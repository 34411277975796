import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import Tabs from '../../ui/Tabs'
import NameDescriptionForm from '../../common/forms/NameDescriptionForm'

import * as actions from '../../../redux/actions/CoursesActions'

import Sidebar from 'arui-feather/sidebar'

import UnitItemsEditor from '../../units/editors/UnitItemsEditor'
import CommonHelper from "../../../helpers/CommonHelper";
import UnitTestsPanel from "../../tests/panels/UnitTestsPanel";
import UnitExercisesPanel from "../../tests/panels/UnitExercisesPanel";
import UpdateUnitInfoForm from "../forms/UpdateUnitInfoForm";
import TeacherLessonsPanel from "../../lessons/panels/TeacherLessonsPanel";

import FirebaseAPI from '../../../api/FirebaseAPI'

import Chessboard from 'chessboardjsx'

export default function UpdateUnitPanel(props) {
    let {
        id, onDeleted = () => {

        }
    } = props;
    const {loading, unit, isAdmin, currentUserId, draggableLesson, allCourses} = useMappedState(useCallback(state => ({
        loading: state.courses.loading,
        currentUserId: state.users.currentUserId,
        unit: state.courses.unitsMap.get(id),
        isAdmin: ('admin' == CommonHelper.getCurrentUserRole(state) || 'methodist' == CommonHelper.getCurrentUserRole(state)),
        draggableLesson: state.ui.draggableLesson,
        allCourses: state.courses.coursesMap.toArray()
    }), []));
    const dispatch = useDispatch();
    const [currFen, setCurrFen] = useState(undefined);
    if (unit == undefined) {
        return null;
    }

    return (
        <Wrapper>

            <Tabs tabs={[
                {
                    label: `Контент`,
                    content: (
                        <TabContent>
                            <UnitItemsEditor {...unit}
                                             currentUserId={currentUserId}
                                             canEdit={isAdmin}
                                             loading={loading}
                                             autoSendFen={true}
                                             onFenSend={async fFen => {
                                                 setCurrFen(fFen);
                                                 if (draggableLesson != undefined) {
                                                     await FirebaseAPI.sendFenToSimpleBoard(`lesson_${draggableLesson.id}`, fFen);
                                                     // setCurrFen(undefined);
                                                 }
                                             }}
                                             onChange={newItems => {
                                                 dispatch(actions.updateUnit({
                                                     id: id,
                                                     items: newItems
                                                 }));
                                             }}/>
                        </TabContent>
                    )
                },
                isAdmin == false ? null :

                    {
                        label: 'Информация',
                        content: (
                            <TabContent>
                                <UpdateUnitInfoForm
                                    loading={loading} {...unit}
                                    canDelete={true}
                                    onSave={data => {
                                        dispatch(actions.updateUnit({
                                            id: id,
                                            ...data
                                        }));
                                    }}
                                    canEdit={isAdmin}
                                    onDelete={async () => {
                                        await dispatch(actions.deleteUnit(id))
                                    }}
                                />
                            </TabContent>
                        )
                    },

                {
                    label: 'Тесты',
                    content: (
                        <TabContent>
                            <UnitTestsPanel id={id} editable={isAdmin}/>
                        </TabContent>
                    )
                },
                {
                    label: 'Упражнения',
                    content: (
                        <TabContent>
                            <UnitExercisesPanel id={id} editable={isAdmin}/>
                        </TabContent>
                    )
                },
                isAdmin == false ? null :
                    {
                        label: 'Миграция',
                        content: (
                            <TabContent>
                                <p>
                                    Выберите курс, куда нужно скопировать данный модуль
                                </p>
                                {allCourses.map((a, i) => {
                                    return (
                                        <CopyItem key={a.id} onClick={() => {
                                            if (isAdmin == false) {
                                                return;
                                            }
                                            dispatch(actions.createUnit({
                                                ...unit,
                                                courseId: a.id,
                                                name: `${unit.name} (Copy) ${moment().format('HH:mm:ss')}`,
                                                description: `Без описания`
                                            })).then(pld => {
                                                window.alert('Done!');
                                            });
                                        }}>
                                            {a.name}
                                        </CopyItem>
                                    )
                                })}
                            </TabContent>
                        )
                    }


            ].filter(x => (x != undefined))}/>

            {/*<Sidebar width={Math.min(500, window.innerWidth / 2)} visible={(currFen != undefined)}*/}
            {/*onCloserClick={() => {*/}
            {/*setCurrFen(undefined);*/}
            {/*}}>*/}
            {/*{currFen == undefined ? null :*/}
            {/*<div>*/}
            {/*<div style={{*/}
            {/*marginBottom: 10,*/}
            {/*width: 400,*/}
            {/*margin: '0 auto',*/}
            {/*paddingBottom: 10,*/}
            {/*borderBottom: '1px solid whitesmoke'*/}
            {/*}}>*/}
            {/*<Chessboard position={currFen} draggable={false} width={200}/>*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*<p>*/}
            {/*Отправить доску в урок*/}
            {/*</p>*/}
            {/*</div>*/}
            {/*<TeacherLessonsPanel onSelect={async (lId) => {*/}
            {/*await FirebaseAPI.sendFenToSimpleBoard(`lesson_${lId}`, currFen);*/}
            {/*setCurrFen(undefined);*/}
            {/*}}/>*/}
            {/*</div>*/}
            {/*}*/}
            {/*</Sidebar>*/}

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const CopyItem = styled.div`
  padding: 10px;
  margin-bottom: 10px;
  background: white;
  cursor: pointer;
`;

const TabContent = styled.div`
  width: 100%;
  padding-top: 10px;
`;
