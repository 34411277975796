import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

export default function BrutalOverlayModal(props) {
    const {
        onClose = () => {

        },
        zIndex = 30
    } = props;

    return (
        <Wrapper zIndex={zIndex}>

            <Inner>
                <CloseImg
                    src={require('../../assets/images/close_x.svg')}
                    onClick={() => {
                        onClose();
                    }}
                />

                <InnerWrapper>
                    {props.children}
                </InnerWrapper>

            </Inner>

        </Wrapper>
    );
}

const InnerWrapper = styled.div`
    width: calc(100vw - 200px);
    margin: 0 auto;
    padding-top: 50px;
    box-sizing: border-box;
    height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
`;

const Wrapper = styled.div`
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: ${props => (props.zIndex == undefined ? 30 : props.zIndex)};
    background: #F4F4F6;
`;

const Inner = styled.div`
    position: relative;
    box-sizing: border-box;
`;

const CloseImg = styled.img`
    width: 70px;
    height: 70px;
    cursor: pointer;
    position: absolute;
    top: 0px;
    right: 0px;
    :hover{
      opacity: 0.7;
    }
`;
