import React, {useMemo, useState, useEffect} from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import PgnHelper, {Path} from './PgnHelper';

import styled from 'styled-components'
import './PgnViewer.css';

export default function PgnViewer(props) {
    const {
        className = 'pgn-viewer',
        pgn = '*',
        onMoveClick = e => e,
        groups: initGroups = null,
        allowHandleKeyEvents = true,
        selected: selectedProp
    } = props;

    let groups = useMemo(() => {
        if (initGroups) {
            return initGroups;
        }

        const { game } = PgnHelper.parse(pgn);
        const nodes = PgnHelper.nodes(game);

        return PgnHelper.groupList(nodes, game)
    }, [pgn, initGroups]);

    const [selected, setSelected] = useState();

    useMemo(() => {
        setSelected(selectedProp);
    }, [selectedProp]);

    if (selected) {
        PgnHelper.markAsSelected(groups, selected);
    }

    const keyDownListener = ({keyCode}) => {
        const select = node => {
            setSelected(node);
            onMoveClick({node});
        }

        if (keyCode === 37) {
            PgnHelper.selectPrev(groups, selected, select);
        } else if (keyCode === 39) {
            PgnHelper.selectNext(groups, selected, select);
        }
    };

    useEffect(() => {
        if (allowHandleKeyEvents) {
            window.addEventListener('keydown', keyDownListener);
        }

        return () => {
            window.removeEventListener('keydown', keyDownListener);
        };
    });

    const testPath = node => {
        groups.forEach(group => group.nodes.forEach(node => PgnHelper.setSelected(node, false)));

        const path = new Path();
        path.build(groups, node, ({ node }) => {
            groups.forEach(group => {
                const match = group.nodes.find(item => PgnHelper.getNodeId(item) === PgnHelper.getNodeId(node));

                if (match) {
                    PgnHelper.setSelected(match, true);
                }
            })
        });

        setSelected(null);
    };

    return (
        <Wrapper className={className}>
            <Scrollbars>
                {groups.map((group, idx) => {
                    return (
                        group.nodes.length ?
                            <Group key={idx} {...group} onMoveClick={e => {
                                setSelected(e.node);
                                onMoveClick(e);
                                //testPath(e.node);
                            }}/> :
                            null
                    )
                })}
            </Scrollbars>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
`;

function LevelBrackets({ children, level }) {
    return (
        <>
            { `${level ? '[' : ''}` }
            { children }
            { `${level ? ']' : ''}` }
        </>
    )
}

function Comment({ comment, node }) {
    const className = 'pgn-viewer__comment';
    comment = comment || PgnHelper.getComment(node);

    return comment ? <span className={className}>{`${comment} `}</span>: null
}

function Nag({ node }) {
    const nags = PgnHelper.getNags(node);
    return <span>{ nags }</span>;
}

function Notation({ node }) {
    const className = 'pgn-viewer__notation';
    const fontClassName = 'pgn-viewer__figure-font';
    const notation = PgnHelper.getNotation(node) ;
    const figureAndField = PgnHelper.getFigureAndField(node);

    return figureAndField ?
        <span className={className}>
            <span className={fontClassName}>{ figureAndField.figure }</span>
            { figureAndField.field }
        </span> :
        <span className={className}>{ notation }</span>
}

function Number(props) {
    const { node, first } = props;
    const isWColor = PgnHelper.isWColor(node);
    const text = PgnHelper.getMoveNumber(node) + (isWColor ? '.' : '\u2026');
    const className = 'pgn-viewer__number';

    return isWColor || first ? <span className={className}>{ text }</span> : null;
}

function Move(props) {
    const { node, level, first, onClick = e => e } = props;
    const className = 'pgn-viewer__move';
    const classesByLevel = `
        pgn-viewer_move-p
        pgn-viewer_hover
        pgn-viewer_grey${level ? '' : ' pgn-viewer_bold'}${PgnHelper.isSelected(node) ? ' pgn-viewer_selected' : ''}
    `;

    return (
        <span className={className}>
            <span className={classesByLevel} onClick={e => onClick({e, node})}>
                <Number node={node} level={level} first={first} />

                <Notation node={node} />

                <Nag node={node} />
            </span>

            <Comment node={node} />
        </span>
    );
}

function Group(props) {
    const { nodes, level, comment, onMoveClick = e => e } = props;
    const className = level ? 'pgn-viewer__sub-group' : 'pgn-viewer__group';

    let prevNode;
    let prevNodeHasComment = false;
    const isFirst = (node, i) => {
        prevNodeHasComment = !!PgnHelper.getComment(prevNode);
        prevNode = node;

        return i === 0 || prevNodeHasComment;
    };

    return (
        <div style={{ 'marginLeft': `${10 * level}px` }} className={className}>
            <LevelBrackets level={level}>
                <Comment comment={comment} />

                {nodes.map((node, i) =>
                    <Move node={node}
                          level={level}
                          key={PgnHelper.getNodeId(node)}
                          first={isFirst(node, i)}
                          onClick={onMoveClick} />)}
            </LevelBrackets>
        </div>
    );
}
