import TournamentsAPI from '../../api/TournamentsAPI'

import * as types from '../ActionTypes'

let loadTournaments_ = () => {
    return {
        type: types.LOAD_TOURNAMENTS
    }
}
let loadTournamentsSuccess = (tournaments) => {
    return {
        type: types.LOAD_TOURNAMENTS_SUCCESS,
        tournaments: tournaments
    }
}
let loadTournamentsFailed = (error) => {
    return {
        type: types.LOAD_TOURNAMENTS_FAIL,
        error: error
    }
}

//thunk
export function loadAllTournaments() {
    return (dispatch, getState) => {
        dispatch(loadTournaments_());
        return TournamentsAPI.getAllTournaments().then(
            tournaments => dispatch(loadTournamentsSuccess(tournaments)),
            error => dispatch(loadTournamentsFailed(error))
        )
    }
}

export function loadTournamentById(id) {
    return (dispatch, getState) => {
        dispatch(loadTournaments_());
        return TournamentsAPI.getTournament(id).then(
            tournament => dispatch(loadTournamentsSuccess([tournament])),
            error => dispatch(loadTournamentsFailed(error))
        )
    }
}

let createTournament_ = () => {
    return {
        type: types.CREATE_TOURNAMENT
    }
}
let createTournamentSuccess = (tournament) => {
    return {
        type: types.CREATE_TOURNAMENT_SUCCESS,
        tournament: tournament
    }
}
let createTournamentFailed = (error) => {
    return {
        type: types.CREATE_TOURNAMENT_FAIL,
        error: error
    }
}

//thunk
export function createTournament(data) {
    return (dispatch, getState) => {
        dispatch(createTournament_());
        return TournamentsAPI.createTournament(data).then(
            tournament => dispatch(createTournamentSuccess(tournament)),
            error => dispatch(createTournamentFailed(error))
        )
    }
}

let updateTournament_ = () => {
    return {
        type: types.UPDATE_TOURNAMENT
    }
}
let updateTournamentSuccess = (tournament) => {
    return {
        type: types.UPDATE_TOURNAMENT_SUCCESS,
        tournament: tournament
    }
}
let updateTournamentFailed = (error) => {
    return {
        type: types.UPDATE_TOURNAMENT_FAIL,
        error: error
    }
}

//thunk
export function updateTournament(data) {
    return (dispatch, getState) => {
        dispatch(updateTournament_());
        return TournamentsAPI.updateTournament(data).then(
            tournament => dispatch(updateTournamentSuccess(tournament)),
            error => dispatch(updateTournamentFailed(error))
        )
    }
}

let deleteTournament_ = () => {
    return {
        type: types.DELETE_TOURNAMENT
    }
}
let deleteTournamentSuccess = (id) => {
    return {
        type: types.DELETE_TOURNAMENT_SUCCESS,
        id: id
    }
}
let deleteTournamentFailed = (error) => {
    return {
        type: types.DELETE_TOURNAMENT_FAIL,
        error: error
    }
}

//thunk
export function deleteTournament(id) {
    return (dispatch, getState) => {
        dispatch(deleteTournament_());
        return TournamentsAPI.deleteTournament(id).then(
            () => dispatch(deleteTournamentSuccess(id)),
            error => dispatch(deleteTournamentFailed(error))
        )
    }
}
