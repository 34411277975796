import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import {Map, Set} from 'immutable'
import {Button} from "../../ui/Button";

export default function ExercisesGroupsList(props) {
    let {
        getGroupExercises = grId => {
            return [];
        },
        exercisesGroups = [], // all exercises groups
        parentId = undefined,
        level = 0,
        expandedSet = Set(),
        canEdit = true,
        canAddGroup = true,
        canAddExercise = true,
        onExercisesGroupToggleExpandClick =
            exId => {
                console.log('onExercisesGroupExpandClick: exGroupId = ', exId);
            },
        onAddExerciseClick = (groupId) => { //

        },
        onAddExercisesGroupClick = (groupId) => { //

        },
        onShowExercisesClick = (grId) => {

        },
        onEditClick = (grId) => {

        }
    } = props;
    const currentExercisesGroups = exercisesGroups.filter(g => (g.parentId == parentId));
    // if (currentExercisesGroups.length == 0) {
    //     return null;
    // }
    console.log('render: currentExercisesGroups = ', currentExercisesGroups);
    console.log('render: ExercisesGroupsList: parentId = ', parentId);

    return (
        <Wrapper>

            <ItemsList>
                {currentExercisesGroups.map((gr, k) => {
                    let {name, description} = gr;
                    let isExpanded = expandedSet.has(gr.id);
                    const childExercisesGroups = exercisesGroups.filter(a => (a.parentId == gr.id));
                    const exercises = getGroupExercises(gr.id);
                    return (
                        <Item key={gr.id}>
                            <Info>
                                <Name>
                                    {childExercisesGroups.length == 0 ? null :
                                        <ChevronImage
                                            src={(isExpanded == true) ? require('../../../assets/images/chevron_down.png') : require('../../../assets/images/chevron_right.png')}
                                            onClick={() => {
                                                onExercisesGroupToggleExpandClick(gr.id);
                                            }}
                                        />
                                    }
                                    <span>
                                        {gr.name}
                                    </span>
                                </Name>
                                <Description>
                                    {gr.description}
                                </Description>
                                <StatsPlaceholder>
                                    <div onClick={() => {
                                        onShowExercisesClick(gr.id);
                                    }}>
                                        Количество упражнений: {exercises.length}
                                    </div>
                                    <br/>
                                    Количество подкатегорий: {childExercisesGroups.length}
                                </StatsPlaceholder>
                            </Info>
                            <ItemControlsPlaceholder>
                                {canAddExercise == false ? null :
                                    <SpanButton onClick={() => {
                                        onAddExerciseClick(gr.id);
                                    }}>
                                        добавить упражнение
                                    </SpanButton>
                                }
                                {canAddGroup == false ? null :
                                    <SpanButton onClick={() => {
                                        onAddExercisesGroupClick(gr.id);
                                    }}>
                                        добавить подкатегорию
                                    </SpanButton>
                                }
                                {canEdit == false ? null :
                                    <SpanButton onClick={() => {
                                        onEditClick(gr.id);
                                    }}>
                                        редактировать
                                    </SpanButton>
                                }
                            </ItemControlsPlaceholder>
                            {childExercisesGroups.length == 0 || isExpanded == false ? null :
                                <ChildrenPlaceholder>
                                    <ExercisesGroupsList {...props} parentId={gr.id} level={+level + 1}/>
                                </ChildrenPlaceholder>
                            }
                        </Item>
                    )
                })}
            </ItemsList>

            <BottomControlsPlaceholder>
                <SpanButton onClick={() => {
                    onAddExercisesGroupClick(parentId);
                }}>
                    Добавить категорию
                </SpanButton>
            </BottomControlsPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const ItemsList = styled.div`
    
`;

const Item = styled.div`
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid whitesmoke;
    position: relative;
`;

const ItemControlsPlaceholder = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
`;

const Info = styled.div`
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid whitesmoke;
`;

const ChildrenPlaceholder = styled.div`
    
`;

const Name = styled.div`
    
`;

const Description = styled.div`
    
`;

const ChevronImage = styled.img`
    display: inline-block;
    vertical-align: middle;
    height: 16px;
    margin-right: 5px;
`;

const StatsPlaceholder = styled.div`
    
`;

const SpanButton = styled.div`
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
    opacity: 0.7;
    :hover{
      opacity: 1;
    }
`;

const BottomControlsPlaceholder = styled.div`
    
`;
