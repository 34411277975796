import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';
import SimpleRealtimeBoard from "../realtime/board/SimpleRealtimeBoard";



export default function HeadlessLessonApp(props) {
    let {id} = props.match.params;
    // let jitsiUrl = `https://jitsi3.shagi.online/room_lesson_${id}#config.startWithAudioMuted=true&config.startWithVideoMuted=true&config.disableInitialGUM=true&config.constraints.video.height.ideal=240&config.constraints.video.height.max=240&config.maxFullResolutionParticipants=1&config.startAudioOnly=true`;
    // let jitsiUrl = `https://jitsi3.shagi.online/room_lesson_${id}#config.startWithAudioMuted=true&config.startWithVideoMuted=true&config.disableInitialGUM=true&config.constraints.video.height.ideal=120&config.constraints.video.height.max=120&config.maxFullResolutionParticipants=1&config.DISABLE_JOIN_LEAVE_NOTIFICATIONS=true&config.VERTICAL_FILMSTRIP=false#config.disableSelfView=true&userInfo.displayEmail="shagi@cardiomood.com"&userInfo.displayName="Shagi Bot"&userInfo.avatarUrl="https://www.englishpatient.org/english-patient-files/f0ddd78b-4ddf-44a8-8e47-4a3bb86c28d8.jpg"`;
    let jitsiUrl = `https://videoconf2.sabir.pro/room_lesson_${id}#config.startWithAudioMuted=true&config.startWithVideoMuted=true&config.disableInitialGUM=true&config.constraints.video.height.ideal=120&config.constraints.video.height.max=120&config.maxFullResolutionParticipants=1&config.DISABLE_JOIN_LEAVE_NOTIFICATIONS=true&config.VERTICAL_FILMSTRIP=false#config.disableSelfView=true&userInfo.displayEmail="shagi@cardiomood.com"&userInfo.displayName="Shagi Bot"&userInfo.avatarUrl="https://www.englishpatient.org/english-patient-files/f0ddd78b-4ddf-44a8-8e47-4a3bb86c28d8.jpg"`;
    // let jitsiUrl = `https://jitsi3.shagi.online/room_lesson_${id}`;
    // let jitsiUrl = `https://www.youtube.com/embed/M7lc1UVf-VE?autoplay=1`;
    let roomId = `lesson_${id}`;

    const [buttonClicked, setButtonClicked] = useState(false);

    return (
        <Wrapper>

            <>
                <div>
                    <BoardPlaceholder>
                        <SimpleRealtimeBoard
                            isIllegal={false}
                            adminMode={false}
                            boardId={`${roomId}`}
                            participants={[]}
                            orientation={'white'}
                            onBoardStateChange={() => {
                            }}
                            onReady={d => {
                            }}
                        />
                    </BoardPlaceholder>
                </div>
                <div>
                    <JitsiPlaceholder>
                        <CoolIframe allow="display-capture; autoplay"
                                    src={jitsiUrl}
                                    frameborder="0"
                                    allowFullScreen="1"
                                    allow="camera; microphone; fullscreen; display-capture; autoplay"
                                    name="jitsiConferenceFrame0" id="jitsiConferenceFrame0"
                                    style={{border: '0px'}}></CoolIframe>

                        {/*<LessonCommutePanel id={id}*/}
                        {/*                    allowGuests={true}*/}
                        {/*/>*/}
                    </JitsiPlaceholder>
                    <div style={{height: 40, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}} >
                        <img style={{height: 36}} src={require('../../assets/images/chess_group.png')} />
                    </div>
                </div>
            </>

            <span id="start" style={{display: 'block', height: '1px', width: '1px', color: 'white'}} onClick={() => {
                setButtonClicked(true);
            }}>
                        start
                    </span>

        </Wrapper>
    );
}

const CoolIframe = styled.iframe`
  -moz-transform: scale(0.25);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.25);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.25);
  -webkit-transform-origin: 0 0;
  width: 1600px;
  //height: 1920px
  height: 1760px
`;

const Wrapper = styled.div`
  width: 800px;
  height: 480px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const BoardPlaceholder = styled.div`
  width: 400px;
  height: 400px;
`;

const JitsiPlaceholder = styled.div`
  width: 400px;
  height: 440px;
  box-sizing: border-box;
`;