import {Map} from 'immutable'
import * as types from '../ActionTypes.js'

const initialState = {
    loading: false,
    teacherGroupsMap: Map(),
    error: undefined
};

const TeacherGroupsReducer = (state = initialState, action = {}) => {

    switch (action.type) {
        
        case types.CREATE_TEACHER_GROUP:
        case types.UPDATE_TEACHER_GROUP:
        case types.DELETE_TEACHER_GROUP:
        case types.LOAD_TEACHER_GROUPS:
            return {
                ...state,
                loading: true,
                error: undefined
            }

        case types.CREATE_TEACHER_GROUP_FAIL:
        case types.UPDATE_TEACHER_GROUP_FAIL:
        case types.DELETE_TEACHER_GROUP_FAIL:
        case types.LOAD_TEACHER_GROUPS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case types.CREATE_TEACHER_GROUP_SUCCESS:
        case types.UPDATE_TEACHER_GROUP_SUCCESS:
            return {
                ...state,
                loading: false,
                teacherGroupsMap: state.teacherGroupsMap.set(action.teacherGroup.id, action.teacherGroup)
            }


        case types.DELETE_TEACHER_GROUP_SUCCESS:
            return {
                ...state,
                loading: false,
                teacherGroupsMap: state.teacherGroupsMap.delete(action.id),
            }


        case types.LOAD_TEACHER_GROUPS_SUCCESS:
            return {
                ...state,
                loading: false,
                teacherGroupsMap: state.teacherGroupsMap
                    .merge(action.teacherGroups.reduce((map, form) => map.set(form.id, form), Map()))
            }


        default:
            return state;
    }

}

export default TeacherGroupsReducer;
