import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'

import * as actions from '../../../redux/actions/TournamentsActions'
import * as gamesActions from '../../../redux/actions/GamesActions'

import {Resizable, ResizableBox} from 'react-resizable';
import CommonHelper from "../../../helpers/CommonHelper";
import JitsiPanel from "../../jitsi/panels/JitsiPanel";
import RealtimeBoard from "../../realtime/board/RealtimeBoard";
import TournamentGamesPanel from "../../games/panels/TournamentGamesPanel";
import FullPagePreloader from "../../preloader/FullPagePreloader";
import UpdateTournamentForm from "../forms/UpdateTournamentForm";

const SAMPLE_START_FEN = 'rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R b KQkq - 1 2';
const STARTING_FEN = 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1';

export default function TournamentPanel(props) {
    let {id, selectedGameId = undefined, userId = undefined} = props;
    const dispatch = useDispatch();
    const [settingsModalVisible, setSettingsModalVisible] = useState(false);
    let {currentUser, loading, currentUserId, tournament, selectedGame} = useMappedState(useCallback(state => {
        let currentUserId = state.users.currentUserId;
        let currentUser = state.users.usersMap.get(currentUserId);
        let tournament = state.tournaments.tournamentsMap.get(id);
        return {
            currentUser: currentUser,
            loading: state.users.loading || state.tournaments.loading || state.games.loading,
            tournament: tournament,
            currentUserId: currentUserId,
            selectedGame: state.games.gamesMap.get(selectedGameId)
        }
    }, [id, selectedGameId]));
    useEffect(() => {
        if (tournament == undefined) {
            dispatch(actions.loadTournamentById(id));
        }
    }, [id]);
    useEffect(() => {
        if (selectedGameId != undefined) {
            if (selectedGame == undefined) {
                dispatch(gamesActions.loadGameById(selectedGameId));
            }
        }
    }, [selectedGameId]);

    if (tournament == undefined) {
        return <FullPagePreloader/>;
    }
    let {name, studentsVideoEnabled} = tournament;
    console.log('--->>>   TournamentPanel: render: selectedGameId = ', selectedGameId);
    console.log('--->>>   TournamentPanel: render: selectedGame = ', selectedGame);
    console.log('--->>>   TournamentPanel: render: tournament = ', tournament);
    console.log('--->>>   TournamentPanel: render: currentUserId = ', currentUserId);
    let currId = (userId != undefined) ? userId : currentUserId;
    let orientation = (selectedGame == undefined) ? 'white' : (selectedGame.whiteUserId == currId ? 'white' : 'black');
    let myColor = (selectedGame == undefined) ? undefined : (selectedGame.whiteUserId == currentUserId ? 'w' : 'b');
    let gameDoesNotExist = ((loading == false) && (selectedGameId != undefined) && (selectedGame == undefined));
    let adminMode = ((currentUserId == tournament.creatorId) && (userId == undefined));

    return (
        <Wrapper>
            <TopPlaceholder>

                <TopLeft>
                    <TopName>

                        {userId != undefined ? null :
                            <BackArrowImage style={{marginRight: 10}}
                                            src={require('../../../assets/images/back_left_arrow.svg')}
                                            onClick={() => {
                                                CommonHelper.linkTo(`/tournaments`)
                                            }}
                            />
                        }

                        <span>
                            {name}
                        </span>

                        {adminMode == false ? null :
                            <EditSpan>
                                <EditImg src={require('../../../assets/images/pencil.svg')} onClick={() => {
                                    setSettingsModalVisible(true);
                                }}/>
                            </EditSpan>
                        }
                    </TopName>
                </TopLeft>

                <TopRight>
                    {selectedGame == undefined ? null :
                        <CurrentGameName>
                            <span>
                                {selectedGame.name}
                            </span>
                        </CurrentGameName>
                    }
                </TopRight>
            </TopPlaceholder>

            <ContentPlaceholder>
                {(studentsVideoEnabled == false && adminMode == false) ? null :
                    <ResizableBox width={300} axis="x" height={window.innerHeight - topHeight}>
                        <JitsiPanel roomName={`tournament_${id}`}
                                    canShare={adminMode}
                        />
                    </ResizableBox>
                }
                <BoardsPlaceholder
                    key={`tournament_${id}_game_${(selectedGame == undefined ? '__d' : selectedGame.id)}`}
                    src={require('../../../assets/images/real_board_back.jpg')}>

                    {gameDoesNotExist == true ?
                        <div>
                            Игра была удалена, либо вы перешли по неверной ссылке
                        </div>
                        :
                        <SquareBoardPlaceholder size={boardSize}>
                            {loading == true ? null :
                                (selectedGame == undefined ?
                                    <RealtimeBoard
                                        userId={userId}
                                        myColor={myColor}
                                        orientation={orientation}
                                        currentUserId={currId}
                                        outerLoading={true}
                                        showLoadingText={false}
                                        gameName={tournament.name}
                                        boardId={`tournament_${id}_game_${selectedGameId}`}
                                    />
                                    :
                                    <RealtimeBoard
                                        userId={userId}
                                        myColor={myColor}
                                        orientation={orientation}
                                        currentUserId={currId}
                                        gameName={tournament.name}
                                        startingFen={selectedGame.startingFen}
                                        adminMode={adminMode}
                                        boardId={`tournament_${id}_game_${selectedGame.id}`}
                                    />)
                            }
                        </SquareBoardPlaceholder>
                    }

                </BoardsPlaceholder>
                {currId != tournament.creatorId ? null :
                    <GamesList>
                        <TournamentGamesPanel
                            tournamentId={id}
                            currentUserId={currId}
                            selectedGameId={selectedGameId}
                            canDelete={adminMode}
                        />
                    </GamesList>
                }

                <Sidebar visible={settingsModalVisible} onCloserClick={() => {
                    setSettingsModalVisible(false);
                }}>

                    {settingsModalVisible == false ? null :
                        <div>
                            <UpdateTournamentForm {...tournament}
                                                  loading={loading}
                                                  onSubmit={async data => {
                                                      await dispatch(actions.updateTournament({
                                                          ...data,
                                                          id: tournament.id
                                                      }));
                                                      setSettingsModalVisible(false);
                                                  }}/>
                        </div>
                    }

                </Sidebar>

            </ContentPlaceholder>


        </Wrapper>
    );
}

const topHeight = 40;

const TopPlaceholder = styled.div`
    height: ${topHeight}px;
    width: 100%;
    background: white;
    box-sizing: border-box;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: space-between;
    border-bottom: 1px solid whitesmoke;
`;

const ContentPlaceholder = styled.div`
    height: calc(100% - ${topHeight}px);
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
`;

const GamesList = styled.div`
    background: white;
    width: 280px;
    border-left: 1px solid whitesmoke;
    box-sizing: border-box;
    padding: 5px;
    overflow-y: auto;
    max-height: 100%;
`;

const BoardsPlaceholder = styled.div`
    flex: 1;
    background: white;
    box-sizing: border-box;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-image: url(${props => props.src});
`;

const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    background: whitesmoke;
`;

const Heading = styled.div`
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
`;

const TopName = styled.div`
    font-weight: bold;
    font-size: 16px;
    flex-direction: row;
    display: flex;
    align-items: center;
`;

const BackArrowImage = styled.img`
    height: 18px;
    cursor: pointer;
    opacity: 0.5;
    :hover{
      opacity: 1;
    }
`;

const TopLeft = styled.div`
    
`;

const TopRight = styled.div`
    
`;

const sz = Math.min(window.innerWidth - 300 - 240, window.innerHeight);

const boardSize = 0.80 * sz;

const SquareBoardPlaceholder = styled.div`
    width: ${props => (props.size)}px;
    height: ${props => (props.size)}px;
`;

const CurrentGameName = styled.div`
    font-weight: bold;
`;

const EditSpan = styled.span`
    margin-left: 10px;
`;

const controlImageSize = 18;

const EditImg = styled.img`
    cursor: pointer;
    opacity: 0.5;
    width: ${controlImageSize}px;
    height: ${controlImageSize}px;
    :hover{
      opacity: 1;
    }
`;
