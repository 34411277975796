import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'


export default function TwoSectionedPanel(props) {
    const {rightContent, hasLeft = true} = props;

    return (
        <Wrapper>

            {hasLeft == false ? null :
                <Left>
                    <Cover src={require('../../../assets/images/shagi_white_bg.jpg')}>
                        <LogoPlaceholder>
                            <LogoImg src={require('../../../assets/images/wide_named_logo.png')}/>
                        </LogoPlaceholder>
                    </Cover>
                </Left>
            }

            <Right>
                {rightContent}
            </Right>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 15px;
`;

const Left = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 50%;
    height: 100%;
    box-sizing: border-box;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    @media(max-width: 720px){
      display: none;
    }
`;

const Right = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 50%;
    height: 100%;
    box-sizing: border-box;
    background: white;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    @media(max-width: 720px){
      display: block;
      width: 100%;
      border-radius: 15px;
    }
`;

const Cover = styled.div`
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${props => props.src});
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
`;

const LogoPlaceholder = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const LogoImg = styled.img`
    width: 80%;
`;

const LogoText = styled.div`
    
`;
