import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import ChessHelper from "../../../../helpers/ChessHelper";
import InlinePiece from "../../tools/InlinePiece";
import InlineMove from "../../tools/InlineMove";

export default function SmartPgnMovesViewer(props) {
    let {
        moves, level = 0, prevMoves = [], onMoveClick = (move, movesPath) => {

        },
        selectedMovesPath = [],
        startFromBlack = false
    } = props;
    const shift = (startFromBlack == false) ? 0 : 1;
    // console.log('shift = ', shift);
    // console.log('SmartPgnMovesViewer: render: moves = ', moves);

    return (
        moves.map((d, k) => {
                let {move_number, move, ravs = [], comment, nags} = d;
                let figureString = ChessHelper.getFigureStringByMoveString(move);
                let nagsString = ChessHelper.getSymbolsStringByNagsArray(nags);
                let hasDots = false;
                let currMoves = prevMoves.concat(moves.slice(0, +k + 1));
                let isSelected = (selectedMovesPath.length == currMoves.length);
                let isSubSelected = currMoves.length < selectedMovesPath.length;
                for (let i = 0; i < Math.min(selectedMovesPath.length, currMoves.length); i++) {
                    if ((selectedMovesPath[i].move_number != currMoves[i].move_number) || (selectedMovesPath[i].move != currMoves[i].move)) {
                        isSelected = false;
                        isSubSelected = false;
                    }
                }
                if (currMoves.length > 1 && currMoves[+currMoves.length - 2] != undefined) {
                    hasDots = (currMoves.length % 2 == shift && currMoves[+currMoves.length - 1].move_number != undefined);
                }
                if (currMoves.length == 1) {
                    hasDots = (currMoves.length % 2 == shift);
                }

                let moveColor = ((currMoves.length + +shift) % 2 == 0) ? 'black' : 'white';
                let shouldBreak = (moveColor == 'black') || (ravs.length > 0) || (k == moves.length - 1);

                let moveNumberString = (move_number == undefined) ? '' : `${move_number}.`;
                if (k < moves.length - 1 && moves[+k + 1].move_number != undefined && move_number != undefined && moves[+k + 1].move_number != move_number) {
                    shouldBreak = true;
                }


                return (
                    <MoveItem level={level} key={`${k}_${level}`}

                    >
                        <MoveItemInner onClick={() => {
                            onMoveClick(move, currMoves);
                        }}
                                       somethingIsSelected={(selectedMovesPath.length > 0)}
                                       subSelected={isSubSelected}
                                       selected={isSelected}
                        >

                            {`${moveNumberString}${hasDots == true ? '..' : ''}`}{' '}
                            <InlineMove move={move}/>
                            {nagsString == undefined ? '' : ` ${nagsString}`}

                            {/*{`${moveNumberString}${hasDots == true ? '..' : ''} ${move}${nagsString == undefined ? '' : ` ${nagsString}`}`}*/}

                            {comment == undefined || comment.trim() == '' ? null :
                                <CommentSpan>
                                    {/*<ChatImg src={require('../../../../assets/images/chat.svg')}/>*/}
                                    {comment.replace(/\[.*\]/g, '').trim()}
                                </CommentSpan>
                            }
                        </MoveItemInner>

                        {shouldBreak == false ? null :
                            <br/>
                        }

                        {ravs.length == 0 ? null :
                            <RavsPlaceholder className={'ravs_placeholder'}>
                                {ravs.map((rv, j) => (
                                    <SmartPgnMovesViewer
                                        key={j}
                                        startFromBlack={startFromBlack}
                                        parent={{...d}}
                                        moves={rv.moves}
                                        level={+level + 1}
                                        prevMoves={currMoves.slice(0, currMoves.length - 1)}
                                        selectedMovesPath={selectedMovesPath}
                                        onMoveClick={onMoveClick}
                                    />
                                ))}
                            </RavsPlaceholder>
                        }

                    </MoveItem>
                )
            }
        ))
}

const MoveItem = styled.span`
    vertical-align: top;
    padding-left: 3px;
`;

const MoveItemInner = styled.span`
    font-weight: ${props => (props.selected == true ? 'bold' : 'default')};
    text-decoration: ${props => (props.selected == true ? 'underline' : 'none')};
    background: ${props => (props.selected == true ? 'wheat' : (props.subSelected == true ? '#f5deb365' : 'transparent'))};
    opacity: ${props => (props.somethingIsSelected == false || (props.subSelected == true || props.selected == true) ? 1 : 0.5)};
    cursor: pointer;
    :hover{
      background: white;
      opacity: 1;
    }
`;

const CommentSpan = styled.span`
    font-size: 15px;
    font-style: italic;
    padding-left: 4px;
    vertical-align: top;
`;

const RavsPlaceholder = styled.div`
    border-left: 2px solid grey;
    padding-left: 12px;
    margin-left: 12px;
`;

const ChatImg = styled.img`
    height: 14px;
    display: inline-block;
    margin-left: 3px;
    opacity: 0.5;
`;
