import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import UpdateArrowTool from "./UpdateArrowTool";
import UpdateSquareTool from "./UpdateSquareTool";
import NchanAPI from "../../../api/NchanAPI";

import {useKeyPress} from "../../../helpers/HooksHelper";

import ls from 'local-storage'

const getCorrectCoordinates = (x, orientation) => {
    console.log('getCorrectCoordinates: x, orientation = ', x, orientation);
    if (orientation == 'white') {
        return x;
    }
    let arr = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h'];
    let a = x[0];
    let b = x[1];
    a = arr.indexOf(a) + 1;
    a = 9 - a;
    a = arr[a - 1];
    b = 9 - b;
    return `${a}${b}`;
}

export default function UpdateArrowsAndSquaresTool(props) {
    const {
        orientation = 'white',
        onChange = (d) => {

        },

        defaultVisible = false,

        roomId = 'sabir',
        fen = 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1',
        isRealtime = true,
        active = true,

        needSub = true
    } = props;

    const [arrows, setArrows] = useState(props.arrows == undefined ? [] : props.arrows);
    const [squares, setSquares] = useState(props.squares == undefined ? [] : props.squares);

    const [visible, setVisible] = useState(props.defaultVisible == undefined ? false : props.defaultVisible);
    const [mode, setMode] = useState('blue-arrow');

    const controlPress = useKeyPress('Control');

    const letterOnePress = useKeyPress('1');
    const letterTwoPress = useKeyPress('2');

    const letterQPress = useKeyPress('q');
    const letterWPress = useKeyPress('w');
    const letterEPress = useKeyPress('e');

    const letterAPress = useKeyPress('a');
    const letterSPress = useKeyPress('s');
    const letterDPress = useKeyPress('d');

    const letterXPress = useKeyPress('x');
    const letterZPress = useKeyPress('z');

    useEffect(() => {
        if (active == false) {
            return;
        }
        if (letterOnePress == true) {
            setVisible(true);
            setMode('blue-arrow');
        }
        if (letterTwoPress == true) {
            setVisible(false);
        }

        if (visible == false) {
            return;
        }

        if (letterXPress == true || letterZPress == true) {
            setArrows([]);
            setSquares([]);
        }

        if (letterQPress == true) {
            setMode('blue-arrow');
            setVisible(true);
        }
        if (letterWPress == true) {
            setMode('red-arrow');
            setVisible(true);
        }
        if (letterEPress == true) {
            setMode('green-arrow');
            setVisible(true);
        }

        if (letterAPress == true) {
            setMode('blue-square');
        }
        if (letterSPress == true) {
            setMode('red-square');
            setVisible(true);
        }
        if (letterDPress == true) {
            setMode('green-square');
            setVisible(true);
        }

    }, [letterXPress, controlPress, letterOnePress, letterZPress,
        letterTwoPress, letterQPress, letterWPress, letterEPress, letterAPress, letterSPress, letterDPress]);

    useEffect(() => {
        let dd = {
            arrows,
            squares
        };
        onChange(dd);
    }, [JSON.stringify(arrows), JSON.stringify(squares)]);

    // useEffect(() => {
    //     if (needSub == false || active == true) {
    //         return;
    //     }
    //     let key = `room_${roomId}_arrows`;
    //     NchanAPI.subscribe(key, (msg) => {
    //         let x = {};
    //         try {
    //             x = JSON.parse(msg);
    //             console.log('-->> x = ', x);
    //         } catch (exc) {
    //         }
    //         let xx = (JSON.parse(JSON.stringify(x)));
    //         xx = xx[fen];
    //         let arrows = (xx == undefined || xx.arrows == undefined) ? [] : xx.arrows;
    //         let squares = (xx == undefined || xx.squares == undefined) ? [] : xx.squares;
    //         setSquares(squares);
    //         setArrows(arrows);
    //     });
    //     return () => {
    //         try {
    //             NchanAPI.unsubscribe(key);
    //         } catch (exc) {
    //
    //         }
    //     }
    // }, [roomId, needSub]);

    if (visible == false) {
        return null;
    }

    let modeColor = (mode == undefined) ? undefined : mode.split('-')[0];
    let modeType = (mode == undefined) ? undefined : mode.split('-')[1];

    return (
        <Wrapper>

            {modeType != 'arrow' ? null :
                <React.Fragment key={`arr-${arrows.length}`}>
                    <UpdateArrowTool onChange={x => {
                        if (x == undefined) {
                            return;
                        }
                        let dd = {
                            ...x,
                            id: uuid(),
                            color: modeColor
                        }
                        console.log('UpdateArrowTool: dd = ', dd);
                        dd.from = getCorrectCoordinates(dd.from, orientation);
                        dd.to = getCorrectCoordinates(dd.to, orientation);
                        console.log('UpdateArrowTool: corrected dd = ', dd);
                        setArrows(arrows.concat([dd]));
                    }}/>
                </React.Fragment>
            }

            {modeType != 'square' ? null :
                <React.Fragment key={`sq-${squares.length}`}>
                    <UpdateSquareTool onChange={x => {
                        if (x == undefined) {
                            return;
                        }
                        let dd = {
                            ...x,
                            id: uuid(),
                            color: modeColor
                        }
                        console.log('UpdateSquareTool: dd = ', dd);
                        dd.position = getCorrectCoordinates(dd.position, orientation);
                        setSquares(squares.concat([dd]));
                    }}/>
                </React.Fragment>
            }


        </Wrapper>
    );
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.12);
`;

