import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import ChessHelper from "../../../helpers/ChessHelper";
import NiceConfirm from "../../modals/NiceConfirm";

import Chessboard from '../../rubius/chessboard/Chessboard'
import NiceModal from "../../modals/NiceModal";
import {RedButton} from "../../ira/ui/Buttons";

export default function MovesHistoryList(props) {
    const {
        boardId,
        onFenSelect = fen => {

        },
        updT
    } = props;
    const [arr, setArr] = useState(ChessHelper.getFensHistoryFromLocalStorage(boardId).sort((a, b) => (+b.t - +a.t)));
    const [selectedFen, setSelectedFen] = useState(undefined);

    useEffect(() => {
        setArr(ChessHelper.getFensHistoryFromLocalStorage(boardId).sort((a, b) => (+b.t - +a.t)));
    }, [updT]);

    return (
        <Wrapper>
            <ListPlaceholder>
                {arr.map((a, i) => {
                    let isSelected = (selectedFen == a.fen);
                    let num = `${arr.length - i}`;
                    return (
                        <Item key={`${a.fen}_${a.t}`} onClick={() => {
                            // onFenSelect(a.fen);
                            onFenSelect(a.fen);
                        }}>
                            <Name>
                                {`${num}) ${moment(a.t).format('HH:mm:ss')}`}
                            </Name>
                            {/*<EyeImg src={require('../../../assets/images/eye_opened.svg')} onClick={() => {*/}
                            {/*setSelectedFen(a.fen);*/}
                            {/*}}/>*/}
                        </Item>
                    )
                })}
            </ListPlaceholder>

            {selectedFen == undefined ? null :
                <NiceModal heading={'Select board'} subHeading={''} onClose={() => {
                    setSelectedFen(undefined);
                }}>
                    <ModalInner>
                        <div style={{height: 400, width: 400}}>
                            <Chessboard fen={selectedFen}/>
                        </div>
                        <ButtonPlaceholder>
                            <RedButton onClick={() => {
                                onFenSelect(selectedFen);
                                setSelectedFen(undefined);
                            }}>
                                Поставить позицию
                            </RedButton>
                        </ButtonPlaceholder>
                    </ModalInner>
                </NiceModal>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
`;

const ModalInner = styled.div`
    width: 100%;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ButtonPlaceholder = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ListPlaceholder = styled.div`
    
`;

const Item = styled.div`
    display: flex;
    box-sizing: border-box;
    padding: 5px;
    border-bottom: 1px solid whitesmoke;
    background: ${props => (props.selected == true ? 'blue' : 'white')};
    color: ${props => (props.selected == true ? 'white' : 'black')};
    cursor: pointer;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    :hover{
      opacity: 0.7;
    }
`;

const Name = styled.div`
    font-weight: bold;
`;

const EyeImg = styled.img`
    height: 16px;
    cursor: pointer;
`;
