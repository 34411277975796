import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import InlineMove from "../../chess/tools/InlineMove";


export default function HistoryView(props) {
    let {
        fen,
        history = []
    } = props;

    console.log('HistoryView: render: history = ', history);

    return (
        <Wrapper>

            {history.filter(a => (a != undefined)).map((h, k) => {
                let {san, color} = h;
                return (
                    <Item key={`${san}_${k}`}>

                        <ColorSquare color={color}/>

                        <InlineMove move={san}/>

                    </Item>
                )
            })}

        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;


const Item = styled.div`
    //display: inline-block;
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const ColorSquare = styled.div`
    width: 14px;
    height: 14px;
    background: ${props => (props.color == 'b' ? 'black' : 'white')};
    border: 2px solid grey;
    border-radius: 3px;
    display: inline-block;
    margin-right: 2px;
    box-sizing: border-box;
`;
