import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';
import * as actions from "../../../redux/actions/CoursesActions";
import CommonHelper from "../../../helpers/CommonHelper";
import {Map, Set} from 'immutable'

export default function CoursesUnitsSelectorPanel(props) {
    const {
        selectedUnitsIds = [],
        onChange = arr => {
            console.log('CoursesUnitsSelectorPanel: arr = ', arr);
        }
    } = props;

    const {courses, loading, units} = useMappedState(useCallback(state => {
        let units = state.courses.unitsMap.toArray()
            .map(a => (a.number == undefined ? {...a, number: a.timestamp} : a))
            .sort((a, b) => (+a.number - +b.number));
        return {
            courses: state.courses.coursesMap.toArray(),
            loading: state.courses.loading,
            isAdmin: ('admin' == CommonHelper.getCurrentUserRole(state)),
            units: units
        }
    }, []));
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.loadAllCourses());
        dispatch(actions.loadAllUnits());
    }, []);

    const [expandedCoursesSet, setExpandedCoursesSet] = useState(Set());


    return (
        <Wrapper>

            {courses.map((c, k) => {
                let courseUnits = units.filter(a => (a.courseId == c.id));
                let isExpanded = expandedCoursesSet.has(c.id);
                return (
                    <CourseItem key={c.id}>
                        <CourseName selected={isExpanded} onClick={() => {
                            if (isExpanded == true) {
                                setExpandedCoursesSet(expandedCoursesSet.delete(c.id));
                            } else {
                                setExpandedCoursesSet(expandedCoursesSet.add(c.id));
                            }
                        }}>
                            {c.name}
                        </CourseName>
                        {isExpanded == false ? null :
                            <CourseContent>
                                <UnitsList>
                                    {courseUnits.map((u, j) => {
                                        let isSelected = (selectedUnitsIds.indexOf(u.id) > -1);
                                        return (
                                            <UnitItem selected={isSelected} key={`${u.id}_${j}`} onClick={() => {
                                                if (isSelected == true) {
                                                    onChange(selectedUnitsIds.filter(a => (a != u.id)));
                                                } else {
                                                    onChange(selectedUnitsIds.concat([u.id]));
                                                }
                                            }}>
                                                {u.name}
                                            </UnitItem>
                                        )
                                    })}
                                </UnitsList>
                            </CourseContent>
                        }
                    </CourseItem>
                )
            })}

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const CourseItem = styled.div`
    box-sizing: border-box;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid lightgrey;
    margin-bottom: 10px;
`;

const CourseName = styled.div`
    font-weight: bold;
    cursor: pointer;
    text-decoration: ${props => (props.selected == true ? 'none' : 'underline')};
`;

const CourseContent = styled.div`
    
`;

const UnitsList = styled.div`
    
`;

const UnitItem = styled.div`
    padding: 5px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid ${props => (props.selected == true ? 'blue' : 'lightgrey')};
    cursor: pointer;
    margin-bottom: 5px;
`;
