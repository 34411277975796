import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import Select from 'react-select'
import CommonHelper from "../../../helpers/CommonHelper";

export default function PairEditorTool(props) {
    const {whiteUserId: whiteId, blackUserId: blackId, fen: initialFen, users = []} = props;

    let usersOptions = users.map(a => ({value: a.id, label: CommonHelper.getUserName(a)}));

    const [data, setData] = useState({
        whiteUserId: whiteId,
        blackUserId: blackId,
        fen: initialFen
    });

    let whiteValue = usersOptions.filter(a => (a.id == data.whiteUserId))[0];
    let blackValue = usersOptions.filter(a => (a.id == data.blackUserId))[0];

    return (
        <Wrapper>

            <Side>
                <Label>
                    Белые
                </Label>
                <Select options={usersOptions}
                        placeholder={'Выберите ученика'}
                        value={whiteValue}
                        onChange={a => {
                            setData({
                                ...data,
                                whiteUserId: a.value
                            })
                        }}
                />
            </Side>


            <Side>
                <Label>
                    Черные
                </Label>
                <Select options={usersOptions}
                        placeholder={'Выберите ученика'}
                        value={blackValue}
                        onChange={a => {
                            setData({
                                ...data,
                                blackUserId: a.value
                            })
                        }}
                />
            </Side>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const CenterPlaceholder = styled.div`
    flex: 1;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    background: lightgrey;
`;

const Side = styled.div`
    width: 260px;
`;

const Label = styled.div`
    font-weight: bold;
`;
