import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';
import {useDispatch, useMappedState} from 'redux-react-hook'

import HorChessTemplate from '../templates/HorChessTemplate'
import TeacherPersonalPanel from "../teachers/panels/TeacherPersonalPanel";
import IraTeacherTemplate from "../templates/IraTeacherTemplate";
import IraTeacherLessonsPanel from "../lessons/panels/IraTeacherLessonsPanel";

export default function TeacherIndexApp(props) {
    const dispatch = useDispatch();
    const {currentUserId} = useMappedState(useCallback(state => ({
        currentUserId: state.users.currentUserId,
        currentUser: state.users.usersMap.get(state.users.currentUserId)
    }), []));
    console.log('TeacherIndexApp: render');

    return (
        <IraTeacherTemplate active={'index'}>

            {/*<TeacherPersonalPanel id={currentUserId} />*/}

            <Wrapper>

                <IraTeacherLessonsPanel/>
            </Wrapper>

        </IraTeacherTemplate>
    );
}

const Wrapper = styled.div`
    padding-top: 31px;
    padding-left: 65px;
    padding-right: 65px;
    box-sizing: border-box;
    @media(max-width: 1300px){
      padding-left: 15px;
      padding-right: 15px;
    }
`;
