import * as types from '../ActionTypes'
import ParseAPI from '../../api/ParseAPI';
import UsersAPI from '../../api/UsersAPI';
import Parse from 'parse'


//LOGIN
let startLoggingIn = () => {
    return {
        type: types.LOGIN
    }
}
let onLoggedIn = (user) => {
    return {
        type: types.LOGIN_SUCCESS,
        user: user
    }
}
let onLoginFailed = (error) => {
    return {
        type: types.LOGIN_FAIL,
        error: error
    }
}

//thunk
export function logIn(data) {
    console.log('distpatched logIn: data = ', data);
    return (dispatch, getState) => {
        dispatch(startLoggingIn());
        return UsersAPI.parselessLogin(data.email, data.password).then(
            user => dispatch(onLoggedIn(user)),
            error => dispatch(onLoginFailed(error))
        )
    }
}

//SIGNUP
let startSigningUp = () => {
    return {
        type: types.SIGNUP
    }
}
let onSignedUp = (user) => {
    return {
        type: types.SIGNUP_SUCCESS,
        user: user
    }
}
let onSignUpFail = (error) => {
    return {
        type: types.SIGNUP_FAIL,
        error: error
    }
}

//thunk
export function signUp(data) {
    return (dispatch, getState) => {
        dispatch(startSigningUp())
        return UsersAPI.parselessSignup(data).then(
            user => dispatch(onSignedUp(user)),
            error => dispatch(onSignUpFail(error))
        )
    }
}

//LOGOUT
let startLoggingOut = () => {
    console.log('startLoggingOut occured');
    return {
        type: types.LOGOUT
    }
}
let onLogoutFail = () => {
    return {
        type: types.LOGOUT_FAIL
    }
}
let onLoggedOut = () => {
    return {
        type: types.LOGOUT_SUCCESS
    }
}

//thunk
export function logOut() {
    return (dispatch, getState) => {
        var usersState = getState().users;
        console.log('usersState = ', usersState);
        if (usersState.currentUserId == undefined) {
            return Promise.resolve()
        }
        dispatch(startLoggingOut());
        return UsersAPI.parselessLogout().then(
            () => dispatch(onLoggedOut()),
            () => dispatch(onLogoutFail())
        )
    }
}

//AUTH_INIT
let startAuthInit = () => {
    return {
        type: types.INITIALIZE_AUTH
    }
}
let authInitFailed = (err) => {
    return {
        type: types.INITIALIZE_AUTH_FAIL,
        error: err
    }
}
let authInitSuccess = (user) => {
    return {
        type: types.INITIALIZE_AUTH_SUCCESS,
        user: user
    }
}

//thunk
export function initializeAuthorization() {
    return (dispatch, getState) => {
        dispatch(startAuthInit());
        return UsersAPI.parselesslyGetCurrentUser().then(
            user => dispatch(authInitSuccess(user)),
            err => dispatch(authInitFailed())
        );
    }
}

//USERS
let loadUsers_ = () => {
    return {
        type: types.LOAD_USERS
    }
}

let loadUsersFail = (error) => {
    return {
        type: types.LOAD_USERS_FAIL,
        error: error
    }
}

let loadUsersSuccess = (users, links) => {
    return {
        type: types.LOAD_USERS_SUCCESS,
        users: users,
        links: (links == undefined) ? [] : links
    }
}

export function loadUsersByIds(ids) {
    return (dispatch, getState) => {
        dispatch(loadUsers_());
        return ParseAPI.getUsersByIds(ids).then(
            users => dispatch(loadUsersSuccess(users)),
            error => dispatch(loadUsersFail(error))
        )
    }
}




// update user

let updateUser_ = () => {
    return {
        type: types.UPDATE_USER
    }
}

let updateUserFail = (err) => {
    return {
        type: types.UPDATE_USER_FAIL,
        error: err
    }
}

let updateUserSuccess = (user) => {
    return {
        type: types.UPDATE_USER_SUCCESS,
        user: user
    }
}

//thunk
export function updateUser(data) {
    return (dispatch, getState) => {
        // let {currentUserId, usersMap} = getState().users;
        // data.id = currentUserId;
        dispatch(updateUser_());
        return UsersAPI.updateUser(data).then(
            user => dispatch(updateUserSuccess(user)),
            err => dispatch(updateUserFail(err))
        )

    }
}

export function updateMyUser(data) {
    return (dispatch, getState) => {
        let {currentUserId, usersMap} = getState().users;
        data.id = currentUserId;
        dispatch(updateUser_());
        return ParseAPI.updateObject(Parse.User, data, ParseAPI.transformUser).then(
            user => dispatch(updateUserSuccess(user)),
            err => dispatch(updateUserFail(err))
        )
        // return UsersAPI.updateUser(data).then(
        //     user => dispatch(updateUserSuccess(user)),
        //     err => dispatch(updateUserFail(err))
        // )

    }
}

//load all users
export function loadAllUsers() {
    return (dispatch, getState) => {
        dispatch(loadUsers_())
        return UsersAPI.getAllUsers().then(
            users => dispatch(loadUsersSuccess(users)),
            err => dispatch(loadUsersFail(err))
        )
    }
}

export function loadAllFranchisees() {
    return (dispatch, getState) => {
        dispatch(loadUsers_())
        return UsersAPI.getAllFranchisees().then(
            users => dispatch(loadUsersSuccess(users)),
            err => dispatch(loadUsersFail(err))
        )
    }
}

export function loadAllTrainers() {
    return (dispatch, getState) => {
        dispatch(loadUsers_())
        return UsersAPI.getAllTrainers().then(
            users => dispatch(loadUsersSuccess(users)),
            err => dispatch(loadUsersFail(err))
        )
    }
}

export function loadAllTrainersAndFranchisees() {
    return (dispatch, getState) => {
        dispatch(loadUsers_())
        return UsersAPI.getAllFranchiseesAndTrainers().then(
            users => dispatch(loadUsersSuccess(users)),
            err => dispatch(loadUsersFail(err))
        )
    }
}

export function loadUserById(userId) {
    return (dispatch, getState) => {
        dispatch(loadUsers_())
        return UsersAPI.getUserById(userId).then(
            u => dispatch(loadUsersSuccess([u])),
            err => dispatch(loadUsersFail(err))
        )
    }
}

let searchUsers_ = () => {
    return {
        type: types.SEARCH_USERS
    }
}

let searchUsersFail = (err) => {
    return {
        type: types.SEARCH_USERS_FAIL,
        error: err
    }
}
let searchUsersSuccess = (users) => {
    return {
        type: types.SEARCH_USERS_SUCCESS,
        users: users,
        links: []
    }
}

//thunk
export function searchUsers(searchText) {
    return (dispatch, getState) => {
        dispatch(searchUsers_());
        let data = {text: searchText};
        return ParseAPI.runCloudFunctionAsPromise('searchUsers', data).then(
            users => dispatch(searchUsersSuccess(users)),
            err => dispatch(searchUsersFail(err))
        )
    }
}

let createUser_ = () => {
    return {
        type: types.CREATE_USER
    }
}
let createUserSuccess = (user) => {
    return {
        type: types.CREATE_USER_SUCCESS,
        user: user
    }
}
let createUserFailed = (error) => {
    return {
        type: types.CREATE_USER_FAIL,
        error: error
    }
}

//thunk
export function createUser(data) {
    return (dispatch, getState) => {
        dispatch(createUser_());
        return UsersAPI.createUser(data).then(
            user => dispatch(createUserSuccess(user)),
            error => dispatch(createUserFailed(error))
        )
    }
}

let deleteUser_ = () => {
    return {
        type: types.DELETE_USER
    }
}
let deleteUserSuccess = (id) => {
    return {
        type: types.DELETE_USER_SUCCESS,
        id: id
    }
}
let deleteUserFailed = (error) => {
    return {
        type: types.DELETE_USER_FAIL,
        error: error
    }
}

//thunk
export function deleteUser(id) {
    return (dispatch, getState) => {
        dispatch(deleteUser_());
        return UsersAPI.deleteUser(id).then(
            data => dispatch(deleteUserSuccess(id)),
            error => dispatch(deleteUserFailed(error))
        )
    }
}

let createStudentTeacherLink_ = () => {
    return {
        type: types.CREATE_USER_LINK
    }
}
let createStudentTeacherLinkSuccess = (link) => {
    return {
        type: types.CREATE_USER_LINK_SUCCESS,
        link: link
    }
}
let createStudentTeacherLinkFailed = (error) => {
    return {
        type: types.CREATE_USER_LINK_FAIL,
        error: error
    }
}

//thunk
export function createStudentTeacherLink(d) { // {studentId, teacherId}
    return (dispatch, getState) => {
        dispatch(createStudentTeacherLink_());
        return UsersAPI.createStudentTeacherLink(d).then(
            link => dispatch(createStudentTeacherLinkSuccess(link)),
            error => dispatch(createStudentTeacherLinkFailed(error))
        )
    }
}

export function createStudentTeacherLinkByTeacherEmail(studentId, teacherEmail) {
    return (dispatch, getState) => {
        dispatch(createStudentTeacherLink_());
        return UsersAPI.createStudentTeacherLinkByTeacherEmail(studentId, teacherEmail).then(
            data => dispatch(createStudentTeacherLinkSuccess(data)),
            error => dispatch(createStudentTeacherLinkFailed(error))
        )
    }
}

export function createStudentTeacherLinkByStudentEmail(teacherId, studentEmail) {
    return (dispatch, getState) => {
        dispatch(createStudentTeacherLink_());
        return UsersAPI.createStudentTeacherLinkByStudentEmail(teacherId, studentEmail).then(
            data => dispatch(createStudentTeacherLinkSuccess(data)),
            error => dispatch(createStudentTeacherLinkFailed(error))
        )
    }
}

let deleteStudentTeacherLink_ = () => {
    return {
        type: types.DELETE_USER_LINK
    }
}
let deleteStudentTeacherLinkSuccess = (id) => {
    return {
        type: types.DELETE_USER_LINK_SUCCESS,
        id: id
    }
}
let deleteStudentTeacherLinkFailed = (error) => {
    return {
        type: types.DELETE_USER_LINK_FAIL,
        error: error
    }
}

//thunk
export function deleteStudentTeacherLink(id) {
    return (dispatch, getState) => {
        dispatch(deleteStudentTeacherLink_());
        return UsersAPI.deleteStudentTeacherLink(id).then(
            () => dispatch(deleteStudentTeacherLinkSuccess(id)),
            error => dispatch(deleteStudentTeacherLinkFailed(error))
        )
    }
}

let loadTeacherLinks_ = () => {
    return {
        type: types.LOAD_USER_LINKS
    }
}
let loadTeacherLinksSuccess = (links) => {
    return {
        type: types.LOAD_USER_LINKS_SUCCESS,
        links: links
    }
}
let loadTeacherLinksFailed = (error) => {
    return {
        type: types.LOAD_USER_LINKS_FAIL,
        error: error
    }
}

//thunk
export function loadTeacherLinks(teacherId) {
    return (dispatch, getState) => {
        dispatch(loadTeacherLinks_());
        return UsersAPI.getTeacherUserLinks(teacherId).then(
            links => dispatch(loadTeacherLinksSuccess(links)),
            error => dispatch(loadTeacherLinksFailed(error))
        )
    }
}
