import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';
import {FACELESS_AVATAR} from "../../constants/config";
import CommonHelper from "../../helpers/CommonHelper";
import * as usersActions from "../../redux/actions/UsersActions";
import NiceModal from "../modals/NiceModal";
import ExerciseTagsUserRatingPanel from "../ratings/panels/ExerciseTagsUserRatingPanel";
import {translate} from "../../utils/Translate";
import LangSwitcherDropdown from "../../utils/LangSwitcherDropdown";

export default function IraTeacherTemplate(props) {
    const {
        active = 'index',
        hasLeft = true
    } = props;
    const dispatch = useDispatch();
    let {currentUser, loading} = useMappedState(useCallback(state => ({
        currentUser: state.users.usersMap.get(state.users.currentUserId),
        loading: state.users.loading
    }), []));

    const [ratingModalVisible, setRatingModalVisible] = useState(false);

    if (currentUser == undefined) {
        return null;
    }

    const {email, firstName, lastName, avatar, name, userRole, lastRating} = currentUser;
    const ava = (avatar == undefined) ? FACELESS_AVATAR : avatar;
    let isTeacher = (userRole == 'teacher');
    let isStudent = (userRole == 'student');
    let isAdmin = (userRole == 'admin');
    let isMethodist = (userRole == 'methodist');
    let links = {student: STUDENT_LINKS, teacher: TEACHER_LINKS, admin: ADMIN_LINKS}[userRole];
    if (isMethodist == true) {
        links = METHODIST_LINKS;
    }
    if (links == undefined) {
        links = [];
    }
    console.log('active = ', active);

    return (
        <Wrapper>
            {hasLeft == false ? null :
                <Left>
                    <LogoPlaceholder>
                        <Logo src={require('./images/top_logo_frame.svg')}/>
                    </LogoPlaceholder>
                    <LeftContent>
                        <CurrentUserPlaceholder onClick={() => {

                        }}>
                            <CurrentUserAvatarPlaceholder src={ava}
                                                          onClick={() => {
                                                              CommonHelper.linkTo('/settings');
                                                          }}
                            />
                            <CurrentUserInfo>
                                <UserName
                                    style={{cursor: 'pointer'}}
                                    onClick={() => {
                                        CommonHelper.linkTo('/settings');
                                    }}>
                                    {CommonHelper.getUserName(currentUser)}
                                </UserName>
                                {(userRole == 'methodist' || userRole == 'admin') ?
                                    <UserName>
                                        {{'admin': 'Администратор', 'methodist': 'Методист'}[userRole]}
                                    </UserName>
                                    :
                                    <UserName
                                        style={{cursor: (lastRating == undefined ? 'default' : 'pointer')}}
                                        onClick={() => {
                                            CommonHelper.linkTo('/rating');
                                            // if (lastRating != undefined) {
                                            //     setRatingModalVisible(true);
                                            // }
                                        }}>
                                        {lastRating == undefined ? '' : `${translate('rating')}: ${lastRating}`}
                                    </UserName>
                                }
                            </CurrentUserInfo>
                        </CurrentUserPlaceholder>

                        <TopLinksPlaceholder>
                            {renderLinks(links.filter(a => (a.isTop == true)), active)}
                        </TopLinksPlaceholder>
                        <BottomLinksPlaceholder>
                            {renderLinks(links.filter(a => (a.isTop != true)), active)}
                        </BottomLinksPlaceholder>

                        <VeryBottomLinksPlaceholder>


                            <BottomLink selected={(active == 'settings')} onClick={() => {
                                CommonHelper.linkTo(`/settings`);
                            }}>
                                {translate('settings')}
                            </BottomLink>


                            <BottomLink onClick={async () => {
                                let pld = await dispatch(usersActions.logOut());
                                if (pld.error == undefined) {
                                    CommonHelper.linkTo('/');
                                    setTimeout(() => {
                                        window.location.reload();
                                    }, 100);
                                }
                            }}>
                                {translate('logout')}
                            </BottomLink>

                            <span style={{marginLeft: 10}}>
                                        <span>

                                        </span>
                                <LangSwitcherDropdown/>
                                    </span>

                        </VeryBottomLinksPlaceholder>

                    </LeftContent>
                </Left>
            }

            <Right id={'template_right_content'}>
                {props.children}
            </Right>

            {ratingModalVisible == false ? null :
                <NiceModal
                    onClose={() => {
                        setRatingModalVisible(false);
                    }}
                >
                    <div style={{width: 840}}>
                        {/*<UserRatingPanel id={currentUser.id}/>*/}
                        <ExerciseTagsUserRatingPanel id={currentUser.id}/>
                    </div>

                </NiceModal>
            }

        </Wrapper>
    );
}

const renderLinks = (links, active) => links.map((a, i) => {
    let isActive = (active == a.name);
    return (
        <LinkItem selected={isActive} key={`${a.name}_${i}`} onClick={() => {
            if (a.name == 'my_class') {
                window.open(`https://app.moyklass.com`, '_blank').focus();
            } else {
                if (a.name == 'my_class_student') {
                    window.open(`https://shagichess.tvoyklass.com`, '_blank').focus();
                } else {
                    CommonHelper.linkTo(a.link);
                }
            }
        }}>
            {/*<LinkImage src={a.icon} selectedSrc={a.selectedIcon} selected={(isActive)}/>*/}
            <LinkImage src={a.emojiIcon} selectedSrc={a.emojiIcon} selected={(isActive)}/>
            <LinkLabel>
                {a.label}
            </LinkLabel>
        </LinkItem>
    )
});

const topHeight = 70;
const leftWidth = 300;

const TopLinksPlaceholder = styled.div`
  border-bottom: 1px solid rgba(133, 147, 168, 0.51);
  margin-bottom: 10px;
  padding-bottom: 10px;
`;

const BottomLinksPlaceholder = styled.div`
  margin-top: 10px;
  margin-bottom: 40px;
`;

const VeryBottomLinksPlaceholder = styled.div`
  box-sizing: border-box;
  padding-left: 30px;
`;

const BottomLink = styled.div`
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 12px;
  cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
  color: ${props => (props.selected ? '#FFA71B' : '#8593A8')};

  :hover {
    color: #FFA71B;
  }
`;

const LinkItem = styled.div`
  padding-left: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  width: 100%;
  height: 56px;
  color: ${props => (props.selected ? '#FFA71B' : '#8593A8')};
  border-left: 8px solid ${props => (props.selected ? '#FFA71B' : 'transparent')};
  cursor: ${props => (props.selected == true ? 'default' : 'pointer')};

  :hover {
    border-left: 8px solid #FFA71B;
    color: #FFA71B;
  }
`;

const LinkImage = styled.div`
  height: 24px;
  width: 24px;
  margin-right: 15px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${props => (props.selected == true ? props.selectedSrc : props.src)});

  ${LinkItem}:hover & {
    background-image: url(${props => props.selectedSrc});
  }
`;

const LinkLabel = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  background: #EEF4F9;
  font-family: Montserrat;
`;

const Left = styled.div`
  width: ${leftWidth}px;
  max-height: 100vh;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: white;
`;

const LogoPlaceholder = styled.div`
  width: 100%;
  height: ${topHeight}px;
`;

const Logo = styled.img`
  width: 100%;
  height: 100%;
`;

const LeftContent = styled.div`
  height: calc(100vh - ${topHeight}px);
  max-height: calc(100vh - ${topHeight}px);
  overflow-y: auto;
  box-sizing: border-box;
  padding-top: 25px;
`;

const CurrentUserPlaceholder = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding-left: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
`;

const CurrentUserAvatarPlaceholder = styled.div`
  height: 45px;
  width: 45px;
  background-color: lightgrey;
  border-radius: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${props => props.src});
  margin-right: 9px;
  cursor: pointer;

  :hover {
    opacity: 0.85;
  }
`;

const CurrentUserInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 14px;
  line-height: 24px;
`;

const UserName = styled.div`
  color: #333333;
  font-weight: bold;
`;

const UserRating = styled.div`
  color: #939598;
`;

const Right = styled.div`
  height: 100vh;
  max-height: 100vh;
    // width: calc(100vw - ${leftWidth}px);
  overflow-y: auto;
  flex: 1;
`;

const TEACHER_LINKS = [
    {
        name: 'index',
        link: '/',
        label: translate('home'),
        emojiIcon: require('./images/icons/emoji_home.svg'),
        selectedIcon: require('./images/icons/sel_home.svg'),
        icon: require('./images/icons/home.svg'),
        isTop: true
    },
    // {
    //     name: 'schedule',
    //     link: '/schedule',
    //     label: translate('schedule'),
    //     emojiIcon: require('./images/icons/emoji_cal.svg'),
    //     selectedIcon: require('./images/icons/sel_calendar.svg'),
    //     icon: require('./images/icons/calendar.svg'),
    //     isTop: true
    // },

    {
        name: 'students',
        link: '/students',
        label: translate('my_students'),
        emojiIcon: require('./images/icons/smart_cap.svg'),
        selectedIcon: require('./images/icons/sel_calendar.svg'),
        icon: require('./images/icons/calendar.svg'),
        isTop: true
    },

    {
        name: 'groups',
        link: '/groups',
        label: translate('my_groups'),
        emojiIcon: require('./images/icons/emoji_group.svg'),
        selectedIcon: require('./images/icons/sel_group_l.svg'),
        icon: require('./images/icons/group_l.svg'),
        isTop: true
    },

    {
        name: 'my_class',
        link: '/groups',
        label: translate('my_class'),
        emojiIcon: require('./images/icons/my_class.png'),
        selectedIcon: require('./images/icons/my_class.png'),
        icon: require('./images/icons/my_class.png'),
        isTop: true
    },


    {
        name: 'courses',
        link: '/courses',
        label: translate('my_courses'),
        emojiIcon: require('./images/icons/emoji_courses.svg'),
        selectedIcon: require('./images/icons/sel_courses.svg'),
        icon: require('./images/icons/courses.svg'),
        isTop: true
    },

    {
        name: 'exercises',
        link: '/exercises',
        label: translate('exercises'),
        emojiIcon: require('./images/icons/emoji_exercises.svg'),
        selectedIcon: require('./images/icons/sel_exercises.svg'),
        icon: require('./images/icons/exercises.svg'),
    },

    // {
    //     name: 'tests',
    //     link: '/tests',
    //     label: translate('tests'),
    //     emojiIcon: require('./images/icons/emoji_tests.svg'),
    //     selectedIcon: require('./images/icons/sel_exercises.svg'),
    //     icon: require('./images/icons/exercises.svg'),
    // },

    // {
    //     name: 'events',
    //     link: '/events',
    //     label: 'Мероприятия',
    //     emojiIcon: require('./images/icons/emoji_events.svg'),
    //     selectedIcon: require('./images/icons/sel_events.svg'),
    //     icon: require('./images/icons/events.svg'),
    // },
    //
    // {
    //     name: 'knowledge',
    //     link: '/knowledge',
    //     label: 'База знаний',
    //     emojiIcon: require('./images/icons/emoji_knol.svg'),
    //     selectedIcon: require('./images/icons/sel_knol.svg'),
    //     icon: require('./images/icons/knol.svg'),
    // },

    // {
    //     name: 'friends',
    //     link: '/friends',
    //     label: 'Мои друзья',
    //     emojiIcon: require('./images/icons/emoji_friends.svg'),
    //     selectedIcon: require('./images/icons/sel_friends.svg'),
    //     icon: require('./images/icons/friends.svg'),
    // },
    //
    // {
    //     name: 'rating',
    //     link: '/rating',
    //     label: 'Рейтинг',
    //     emojiIcon: require('./images/icons/emoji_rating.svg'),
    //     selectedIcon: require('./images/icons/sel_rating.svg'),
    //     icon: require('./images/icons/rating.svg'),
    // },

    // {
    //     name: 'tournaments',
    //     link: '/tournaments',
    //     label: 'Турниры',
    //     emojiIcon: require('./images/icons/emoji_tournaments.svg'),
    //     selectedIcon: require('./images/icons/sel_tournaments.svg'),
    //     icon: require('./images/icons/tournaments.svg'),
    // }
];


const STUDENT_LINKS = [
    {
        name: 'index',
        link: '/',
        label: translate('home'),
        emojiIcon: require('./images/icons/emoji_cal.svg'),
        selectedIcon: require('./images/icons/sel_calendar.svg'),
        icon: require('./images/icons/calendar.svg'),
        isTop: true
    },
    {
        name: 'homework',
        link: '/homework',
        label: translate('homework'),
        emojiIcon: require('./images/icons/emoji_home.svg'),
        selectedIcon: require('./images/icons/sel_home.svg'),
        icon: require('./images/icons/home.svg'),
        isTop: true
    },
    {
        name: 'exercises',
        link: '/exercises',
        label: translate('gym'),
        emojiIcon: require('./images/icons/emoji_exercises.svg'),
        selectedIcon: require('./images/icons/sel_exercises.svg'),
        icon: require('./images/icons/exercises.svg'),
        isTop: true
    },
    {
        name: 'courses',
        link: '/courses',
        label: translate('courses'),
        emojiIcon: require('./images/icons/emoji_courses.svg'),
        selectedIcon: require('./images/icons/sel_courses.svg'),
        icon: require('./images/icons/courses.svg'),
        isTop: true
    },

    // {
    //     name: 'courses',
    //     link: '/courses',
    //     label: 'Мои курсы',
    //     emojiIcon: require('./images/icons/emoji_courses.svg'),
    //     selectedIcon: require('./images/icons/sel_courses.svg'),
    //     icon: require('./images/icons/courses.svg'),
    //     isTop: true
    // },
    {
        name: 'groups',
        link: '/groups',
        label: translate('my_groups'),
        emojiIcon: require('./images/icons/emoji_group.svg'),
        selectedIcon: require('./images/icons/sel_group_l.svg'),
        icon: require('./images/icons/group_l.svg'),
        isTop: true
    },

    {
        name: 'recordings',
        link: '/recordings',
        label: translate('lessons_recordings'),
        emojiIcon: require('./images/icons/video_emoji.png'),
        selectedIcon: require('./images/icons/video_emoji.png'),
        icon: require('./images/icons/video_emoji.png'),
        isTop: true
    },

    // {
    //     name: 'trainers',
    //     link: '/trainers',
    //     label: 'Тренеры',
    //     emojiIcon: require('./images/icons/emoji_trainers.svg'),
    //     selectedIcon: require('./images/icons/sel_trainers.svg'),
    //     icon: require('./images/icons/trainers.svg'),
    // },


    // {
    //     name: 'events',
    //     link: '/events',
    //     label: 'Мероприятия',
    //     emojiIcon: require('./images/icons/emoji_events.svg'),
    //     selectedIcon: require('./images/icons/sel_events.svg'),
    //     icon: require('./images/icons/events.svg'),
    // },

    // {
    //     name: 'knowledge',
    //     link: '/knowledge',
    //     label: 'База знаний',
    //     emojiIcon: require('./images/icons/emoji_knol.svg'),
    //     selectedIcon: require('./images/icons/sel_knol.svg'),
    //     icon: require('./images/icons/knol.svg'),
    // },

    // {
    //     name: 'friends',
    //     link: '/friends',
    //     label: 'Мои друзья',
    //     emojiIcon: require('./images/icons/emoji_friends.svg'),
    //     selectedIcon: require('./images/icons/sel_friends.svg'),
    //     icon: require('./images/icons/friends.svg'),
    // },

    {
        name: 'rating',
        link: '/rating',
        label: translate('rating'),
        emojiIcon: require('./images/icons/emoji_rating.svg'),
        selectedIcon: require('./images/icons/sel_rating.svg'),
        icon: require('./images/icons/rating.svg'),
        isTop: true
    },

    {
        name: 'my_class_student',
        link: '/groups',
        label: translate('my_balance'),
        emojiIcon: require('./images/icons/coins.png'),
        selectedIcon: require('./images/icons/coins.png'),
        icon: require('./images/icons/coins.png'),
        isTop: true
    },

    // {
    //     name: 'tournaments',
    //     link: '/tournaments',
    //     label: 'Турниры',
    //     emojiIcon: require('./images/icons/emoji_tournaments.svg'),
    //     selectedIcon: require('./images/icons/sel_tournaments.svg'),
    //     icon: require('./images/icons/tournaments.svg'),
    // }
];


const ADMIN_LINKS = [
    {
        name: 'index',
        link: '/',
        label: 'Главная',
        emojiIcon: require('./images/icons/emoji_cal.svg'),
        selectedIcon: require('./images/icons/sel_calendar.svg'),
        icon: require('./images/icons/calendar.svg'),
        isTop: true
    },
    {
        name: 'schedule',
        link: '/schedule',
        label: 'Мое расписание',
        emojiIcon: require('./images/icons/emoji_home.svg'),
        selectedIcon: require('./images/icons/sel_home.svg'),
        icon: require('./images/icons/home.svg'),
        isTop: true
    },
    {
        name: 'courses',
        link: '/courses',
        label: 'Курсы',
        emojiIcon: require('./images/icons/emoji_courses.svg'),
        selectedIcon: require('./images/icons/sel_courses.svg'),
        icon: require('./images/icons/courses.svg'),
        isTop: true
    },
    {
        name: 'groups',
        link: '/groups',
        label: 'Мои группы',
        emojiIcon: require('./images/icons/emoji_group.svg'),
        selectedIcon: require('./images/icons/sel_group_l.svg'),
        icon: require('./images/icons/group_l.svg'),
        isTop: true
    },

    {
        name: 'trainers',
        link: '/trainers',
        label: 'Тренеры',
        emojiIcon: require('./images/icons/emoji_trainers.svg'),
        selectedIcon: require('./images/icons/sel_trainers.svg'),
        icon: require('./images/icons/trainers.svg'),
    },
    {
        name: 'exercises',
        link: '/exercises',
        label: translate('gym'),
        emojiIcon: require('./images/icons/emoji_exercises.svg'),
        selectedIcon: require('./images/icons/sel_exercises.svg'),
        icon: require('./images/icons/exercises.svg'),
    },

    {
        name: 'events',
        link: '/events',
        label: 'Мероприятия',
        emojiIcon: require('./images/icons/emoji_events.svg'),
        selectedIcon: require('./images/icons/sel_events.svg'),
        icon: require('./images/icons/events.svg'),
    },

    {
        name: 'knowledge',
        link: '/knowledge',
        label: 'База знаний',
        emojiIcon: require('./images/icons/emoji_knol.svg'),
        selectedIcon: require('./images/icons/sel_knol.svg'),
        icon: require('./images/icons/knol.svg'),
    },

    {
        name: 'friends',
        link: '/friends',
        label: 'Мои друзья',
        emojiIcon: require('./images/icons/emoji_friends.svg'),
        selectedIcon: require('./images/icons/sel_friends.svg'),
        icon: require('./images/icons/friends.svg'),
    },

    {
        name: 'rating',
        link: '/rating',
        label: translate('rating'),
        emojiIcon: require('./images/icons/emoji_rating.svg'),
        selectedIcon: require('./images/icons/sel_rating.svg'),
        icon: require('./images/icons/rating.svg'),
    },

    {
        name: 'tournaments',
        link: '/tournaments',
        label: 'Турниры',
        emojiIcon: require('./images/icons/emoji_tournaments.svg'),
        selectedIcon: require('./images/icons/sel_tournaments.svg'),
        icon: require('./images/icons/tournaments.svg'),
    }
];

const METHODIST_LINKS = [
    {
        name: 'index',
        link: '/',
        label: 'Главная',
        emojiIcon: require('./images/icons/emoji_cal.svg'),
        selectedIcon: require('./images/icons/sel_calendar.svg'),
        icon: require('./images/icons/calendar.svg'),
        isTop: true
    },
    {
        name: 'courses',
        link: '/courses',
        label: 'Курсы',
        emojiIcon: require('./images/icons/emoji_courses.svg'),
        selectedIcon: require('./images/icons/sel_courses.svg'),
        icon: require('./images/icons/courses.svg'),
        isTop: true
    },
    {
        name: 'exercises',
        link: '/exercises',
        label: 'Упражнения',
        emojiIcon: require('./images/icons/emoji_exercises.svg'),
        selectedIcon: require('./images/icons/sel_exercises.svg'),
        icon: require('./images/icons/exercises.svg'),
    }
];
