import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import {Button} from '@blueprintjs/core'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'

import {Spinner} from '@blueprintjs/core'

import {Map} from 'immutable'

import Select from 'react-select'
import ExercisesFoldersAPI from "../../../api/ExercisesFoldersAPI";

export default function FoldersSelector(props) {
    const {
        foldersIds = [],
        onChange = newFolders => {

        }
    } = props;
    const [loading, setLoading] = useState(true);
    const [allFolders, setAllFolders] = useState([]);

    useEffect(() => {
        ExercisesFoldersAPI.getAllExercisesFolders().then(aFolders => {
            setAllFolders(aFolders);
            setLoading(false);
        })
    }, []);

    if (loading == true) {
        return (
            <Spinner size={20}/>
        )
    }
    const allFoldersMap = allFolders.reduce((mp, f) => mp.set(f.id, f), Map());
    const selectedOptions = foldersIds.map(fId => allFoldersMap.get(fId)).filter(a => (a != undefined)).map(a => ({
        value: a.id,
        label: a.name
    }));

    return (
        <Wrapper>

            <Select
                value={selectedOptions}
                options={allFolders.map(a => ({value: a.id, label: a.name}))}
                isMulti={true}
                isSearchable={true}
                placeholder={'Выберите папку'}
                onChange={a => {
                    onChange(a.map(aa => (aa.value)));
                }}
            />

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;
