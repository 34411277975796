import React, {useMemo, useState, useRef} from 'react';
import styled from 'styled-components';
import Timer from 'react-compound-timer';
import cloneDeep from 'lodash.clonedeep';
import Chessboard from '../../chessboard/Chessboard';
import PgnHelper, {
    getAttachedVariant,
    getAttachedVariantByUid,
    Iterator,
    Path,
    NodeIterator
} from '../../pgnViewer/PgnHelper'
import {SOLUTIONS_TYPES, SOLUTIONS_TYPES_OPTIONS, COLORS_MAP} from '../const';
import PgnViewer from '../../pgnViewer/PgnViewer';
import FinishWindow from '../FinishWindow/FinishWindow';
import useComponentSize from '@rehooks/component-size';
import {getOptimalBoardWidth} from './IllegalWorkspace';

import './Workspace.css';
import ChessHelper from "../../../../helpers/ChessHelper";
import {translate} from "../../../../utils/Translate";

let solutionIterator;
let nodeIterator;
let pathHelper;
let onEndComputerMoveCallback = () => {
};

export default function Workspace(props) {
    console.log('Workspace: props = ', props);
    const {
        allowHandleKeyEvents,
        shouldShowWindowOnFinish = false,
        boardWidthFraction = 0.5,
        fen: initFen = '',
        pgn,
        duration: initDuration,
        boardHeight = 0,
        minBoardHeight = 400,
        bottomPanelHeight = 54,
        topPanelHeight = 50,
        solutions: initSolutions = [],
        timeOffset = 0,
        selectedSolutionNumber = 0,
        color = 'b',
        onTimeFinished = () => {
        },
        onTimerTick = e => e,
        onSubExerciseStart = e => e,
        onSubExerciseEnd = e => e,
        onExerciseFinish = e => e,
    } = props;

    // const boardWidth = Math.max(boardHeight, minBoardHeight);
    const wrapperRef = useRef();
    const computerStopNode = useRef();
    const lastMoves = useRef([]);
    const taskNode = useRef();
    const timerRef = useRef();

    const wrapperSize = useComponentSize(wrapperRef);
    const [updT, setUpdT] = useState(0);

    const [showAnimation, setShowAnimation] = useState(false);
    const [duration, setDuration] = useState(0);
    const [selectedNode, setSelectedNode] = useState(null);
    const [selectedFen, setSelectedFen] = useState(initFen);

    const [currentSolution, setCurrentSolution] = useState({});
    const [path, setPath] = useState(null);
    const [computerMoves, setComputerMoves] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [inactiveBoard, setInactiveBoard] = useState(false);
    const [inactiveButtons, setInactiveButtons] = useState(true);
    const [visibleFinishWindow, setVisibleFinishWindow] = useState(false);

    const timerPause = () => {
        const timer = timerRef.current || {};

        if (typeof timer.pause === 'function') {
            timer.pause();
        }
    }
    const timerResume = () => {
        const timer = timerRef.current || {};

        if (typeof timer.resume === 'function') {
            timer.resume();
        }
    }
    const timerStop = () => {
        const timer = timerRef.current || {};

        if (typeof timer.stop === 'function') {
            timer.stop();
        }
    }
    const startComputerMove = computerMoves => {
        if (computerMoves.length) {
            disableBoard();
        }

        timerPause();
    }
    const endComputerMove = interval => {
        onEndComputerMoveCallback();
        clearInterval(interval);
        timerResume();
    }
    const startTaskExecution = () => {
        if (currentSolution.variants) {
            const tempTasks = [...tasks];
            let task = tasks.find(t => t.id === currentSolution.id);

            if (task) {
                task.assessment = null;
            } else {
                task = cloneDeep(currentSolution);
                tempTasks.push(task);
            }

            if (solutionIterator.index() === 0 && initDuration) {
                setDuration(initDuration - timeOffset);
            }

            onSubExerciseStart(task);
            setTasks(tempTasks);
            enableBoard();
        }
    };
    // const endTaskExecution = e => {
    //     // onSubExerciseEnd(JSON.parse(JSON.stringify(e)));
    //     console.log('endTaskExecution: e = ', e);
    //     onSubExerciseEnd(e);
    // };
    const evaluateTask = (fen, variants) => {
        const foundVariant = getAttachedVariant(fen, variants);
        const workTasks = [...tasks];
        const task = workTasks[workTasks.length - 1];
        const assessment = foundVariant ? foundVariant.type : SOLUTIONS_TYPES.LOSE;
        console.log('evaluateTask: task = ', task);
        console.log('evaluateTask: assessment = ', assessment);
        onSubExerciseEnd(JSON.parse(JSON.stringify({
            fen, task: {
                ...JSON.parse(JSON.stringify({...task})),
                assessment: `${assessment}`
            }
        })));
        // endTaskExecution({fen, task: JSON.parse(JSON.stringify({...task}))});

        task.result = task.result || assessment;
        task.assessment = assessment;
        setTasks(workTasks);

        return task.assessment;
    };
    const restartTask = () => {
        disableButtons();
        enableBoard();
        showCurrentPath(computerStopNode.current);
        startTaskExecution();
        initSelectedNode(computerStopNode.current, initFen);
    };
    const moveToNextTask = () => {
        initCurrentSolution(solutionIterator.next());

        disableButtons();
    };
    const showAnswer = () => {
        showFullPgn();
    };
    const showFullPgn = () => {
        setPath(nodeIterator.data());
    };
    const enableButtons = () => {
        setInactiveButtons(false);
    };
    const disableButtons = () => {
        setInactiveButtons(true);
    };
    const enableBoard = () => {
        setInactiveBoard(false);
    };
    const disableBoard = () => {
        setInactiveBoard(true);
    };
    const showCurrentPath = node => {
        if (node) {
            setPath(pathHelper.get(node));
        }
    };
    const initCurrentSolution = solution => {
        if (solution) {
            setCurrentSolution(solution);
        }
    };
    const initComputerMoves = (moves, callback = () => {
    }) => {
        onEndComputerMoveCallback = callback;
        setComputerMoves(moves);
    };
    const youWin = assessment => {
        return assessment === SOLUTIONS_TYPES.WIN;
    };
    const showFinishWindow = () => {
        setVisibleFinishWindow(true);
    };
    const hideFinishWindow = () => {
        setVisibleFinishWindow(false);
    };
    const initSelectedNode = (node, initFen) => {
        setSelectedNode(node);
        let _fen = node ? PgnHelper.getFen(node) : initFen;
        setSelectedFen(_fen);
    };
    const onPgnMoveClick = ({node}) => {
        console.log('node = ', node);
        let _fen = PgnHelper.getFen(node);
        setSelectedFen(_fen);
        let pieces = ChessHelper.fenToPieces(_fen);
        // setUpdT(`${pieces.length}_${node?._uid}`);
        // setUpdT(`${pieces.length}`);
        setTimeout(() => {
            setUpdT(`${pieces.length}_${node?._uid}`);
            // setUpdT(`${pieces.length}`);
        }, 400);
    };

    /**User moves*/
    const onUserMove = fen => {
        const variants = currentSolution.variants;
        const itLastTask = !solutionIterator.nextItem();

        setSelectedFen(fen);

        //
        setTimeout(() => {
            setUpdT(ChessHelper.fenToPieces(fen).length);
        }, 400);

        disableBoard();

        if (variants && variants.length) {
            const assessment = evaluateTask(fen, variants);

            if (itLastTask) {
                onExerciseFinish(tasks);
                timerStop();
            }

            if (youWin(assessment)) {
                if (itLastTask) {
                    showFullPgn();
                    showFinishWindow();
                } else {
                    moveToNextTask();
                }
            } else {
                const foundScenario = nodeIterator.foundScenario(fen, computerStopNode.current);

                if (foundScenario) {
                    const scenarioBranch = nodeIterator.getScenarioBranch(foundScenario, solutionIterator);

                    setPath([...path, ...scenarioBranch]);

                    initComputerMoves(scenarioBranch.reduce((acc, item) => {
                        return [...acc, ...item.nodes];
                    }, []), () => {
                        enableButtons();
                        disableBoard()
                    });
                } else {
                    enableButtons();
                }
            }
        }
    }

    /**Iterators init and set start solution*/
    useMemo(() => {
        const {game} = PgnHelper.parse(pgn);
        const nodes = PgnHelper.nodes(game);
        const groups = PgnHelper.groupList(nodes, game);

        solutionIterator = new Iterator(initSolutions.slice(selectedSolutionNumber));
        nodeIterator = new NodeIterator(groups);
        pathHelper = new Path(nodeIterator);

        initCurrentSolution(solutionIterator.current())
    }, [pgn, initSolutions, selectedSolutionNumber]);

    /**Prepare moves for computer and showed path*/
    useMemo(() => {
        const variants = currentSolution.variants;

        if (variants && variants.length) {
            let moves = [];

            do {
                const currentNode = nodeIterator.current();

                if (currentNode && getAttachedVariantByUid(PgnHelper.getUid(currentNode), variants)) {
                    pathHelper.build(nodeIterator.data(), currentNode, ({node}) => {
                        moves.unshift(node);
                    });

                    const lastPath = lastMoves.current;
                    lastMoves.current = moves;
                    moves = moves.filter(node => !lastPath.find(move => PgnHelper.getNodeId(move) === PgnHelper.getNodeId(node)));

                    taskNode.current = currentNode;

                    break;
                }
            } while (nodeIterator.next());

            computerStopNode.current = moves[moves.length - 1];

            showCurrentPath(computerStopNode.current);
            initComputerMoves(moves);
        }
    }, [currentSolution.variants]);

    /**Computer moves*/
    useMemo(() => {
        let moves = computerMoves;

        startComputerMove(moves);

        if (solutionIterator.index() === 0 && selectedSolutionNumber && !showAnimation) {
            moves = moves.slice(-1);
        }

        let index = 0;
        const interval = setInterval(
            () => {
                const node = moves[index];

                index++

                if (node) {
                    initSelectedNode(node, initFen);
                } else {
                    startTaskExecution();
                    endComputerMove(interval);
                    setShowAnimation(true);
                }
            },
            500
        );
    }, [computerMoves, selectedSolutionNumber]);

    // console.log('Workspace: render: wrapperSize = ', wrapperSize);
    const boardWidth = getOptimalBoardWidth(wrapperSize, boardWidthFraction);
    let isCollapsed = (wrapperSize.width == 0);

    console.log('render: updT = ', updT);

    return (
        <Wrapper ref={wrapperRef}>

            {isCollapsed ? null :
                <>
                    <Left width={boardWidth}>
                        <BoardContainer width={boardWidth} key={`${updT}_${0}`}>
                            <Chessboard fen={selectedFen}
                                        width={boardWidth}
                                        onMove={onUserMove}
                                        orientation={color === 'b' ? 'black' : 'white'}/>
                        </BoardContainer>

                        <Shadow visible={inactiveBoard} height={boardWidth} opacity={0.2}/>

                    </Left>

                    <Right width={boardWidth} height={boardWidth}>
                        <Top height={topPanelHeight}>
                            {!duration ? null :
                                <Time tasks={tasks}>
                                    <Timer formatValue={(value) => `${(value < 10 ? `0${value}` : value)}`}
                                           direction='backward'
                                           initialTime={duration * 1000}
                                           checkpoints={[{time: 0, 'callback': () => onTimeFinished()}]}
                                    >
                                        {e => {
                                            if (!timerRef.current) {
                                                timerRef.current = e;
                                            }

                                            if (e.getTime() >= 0) {
                                                onTimerTick(e);
                                            }

                                            return (
                                                <>
                                                    <Timer.Hours/>:
                                                    <Timer.Minutes/>:
                                                    <Timer.Seconds/>
                                                </>
                                            );
                                        }}
                                    </Timer>
                                </Time>
                            }

                            {!tasks.length ? null :
                                <Task task={tasks[tasks.length - 1]}
                                      node={taskNode.current}
                                      duration={duration}
                                      onTimeFinished={onTimeFinished}/>
                            }
                        </Top>

                        <Center bottomPanelHeight={bottomPanelHeight} topPanelHeight={topPanelHeight}>
                            <PgnViewerContainer>
                                <PgnViewer groups={path} selected={selectedNode} onMoveClick={onPgnMoveClick}
                                           allowHandleKeyEvents={allowHandleKeyEvents}/>
                            </PgnViewerContainer>
                        </Center>

                        <Bottom height={bottomPanelHeight}>
                            <Buttons disable={inactiveButtons}
                                     restartTask={restartTask}
                                     moveToNextTask={moveToNextTask}
                                     showAnswer={showAnswer}
                                     nextItem={solutionIterator.nextItem()}/>
                        </Bottom>
                    </Right>

                    {shouldShowWindowOnFinish &&
                    <FinishWindow visible={visibleFinishWindow} hide={hideFinishWindow} data={props}/>}

                </>
            }


        </Wrapper>
    );
}

function Task({task, node, duration}) {
    const Task = styled.div`
      color: #555;
      text-shadow: none;
      font-weight: 300;
      font-family: Sahadeva, serif;
      font-size: 20px;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      background: ${props => COLORS_MAP[props.assessment] || '#f4f6f8'};
      justify-content: ${props => props.assessment ? 'center' : 'baseline'};
    `;
    const MoveTitle = styled.div`
      border-right: 1px solid #c1cbd5;
      padding: 0 5px 0 10px;
      width: 150px;
      text-align: center;
    `;
    const TimeBlock = styled.div`
      border-right: 1px solid #c1cbd5;
      padding: 0 5px;
      width: 100px;
      text-align: center;
    `;
    const DescContainer = styled.span`
      height: 50px;
      display: flex;
      align-items: center;
      width: 100%;
      padding-left: 10px;
    `;
    const Desc = styled.span`
      line-height: 1.2;
    `;
    const Assessment = styled.span`
      color: #fff;
    `;

    return (
        <Task assessment={task.assessment}>
            {
                task.assessment ?
                    <Assessment>
                        {SOLUTIONS_TYPES_OPTIONS.find(s => s.value === task.assessment).label}
                    </Assessment>
                    :
                    <>
                        {duration ? <TimeBlock/> : null}

                        <MoveTitle>{PgnHelper.isWColor(node) ? translate('white_to_move') : translate('black_to_move')}</MoveTitle>

                        <DescContainer>
                            <Desc>{task.description}</Desc>
                        </DescContainer>
                    </>
            }
        </Task>
    )
}

function Shadow({visible, height, opacity = 0.3}) {
    const Shadow = styled.div`
      left: 0;
      right: 0;
      top: 0;
      height: ${height}px;
      position: absolute;
      z-index: 20000;
    `;

    const ShadowBackground = styled.div`
      opacity: ${props => (props.opacity == undefined ? 0.1 : props.opacity)};
      background: grey;
      height: 100%;
      width: 100%;
    `;

    return (
        visible ?
            <Shadow>
                <ShadowBackground/>
            </Shadow> :
            null
    )
}

function Buttons({disable, restartTask, moveToNextTask, showAnswer, nextItem}) {
    const className = `btn${disable ? ' btn_disabled' : ''}`
    const nextButtonClassName = `btn${disable || !nextItem ? ' btn_disabled' : ''}`

    return (
        <>
            <button className={className}
                    title={'Попробовать снова'}
                    onClick={() => disable ? null : restartTask()}>
                <i className='icon-reload'/>
            </button>

            <button className={nextButtonClassName}
                    title={'Продолжить решение'}
                    onClick={() => disable || !nextItem ? null : moveToNextTask()}>
                <i className='icon-control-end'/>
            </button>

            <button className={className}
                    title={'Показать решение'}
                    onClick={() => disable ? null : showAnswer()}>
                <i className='icon-eye'/>
            </button>
        </>
    )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
`;

const Left = styled.div`
  width: ${props => props.width}px;
  position: relative;
`;

const Right = styled.div`
  flex: 1;
  box-sizing: border-box;
  max-height: ${props => (props.height == undefined ? 'auto' : `${props.height}px`)};
  height: ${props => (props.height == undefined ? 'auto' : `${props.height}px`)};
`;

const Top = styled.div`
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  height: ${props => props.height}px;
  border-bottom: solid 1px #dfe4e4;
  background: #f4f6f8;
  display: flex;
`;

const Center = styled.div`
  position: relative;
  left: 0;
  right: 0;
  height: calc(100% - ${props => props.bottomPanelHeight}px - ${props => props.topPanelHeight}px);
`;

const Bottom = styled.div`
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  height: ${props => props.height}px;
  border-top: solid 1px #dfe4e4;
  padding: 9px;
  background: #f4f6f8;
  box-sizing: border-box;
`;

const BoardContainer = styled.div`
  width: ${props => props.width}px;
`;

const PgnViewerContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px 0 10px 10px;
  box-sizing: border-box;
`;

const Time = styled.div`
  position: absolute;
  border-right: 1px solid #c1cbd5;
  padding: 0 5px;
  width: 80px;
  text-align: center;
  color: ${props => props.tasks.length && props.tasks[props.tasks.length - 1].assessment ? '#fff' : '#555'};
  text-shadow: none;
  font-weight: 300;
  font-family: Sahadeva, serif;
  font-size: 20px;
  align-self: center;
`;
