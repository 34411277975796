import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {getCurrentLang} from "../../../utils/Translate";

const HOURS = Array.from({length: 24}).map((a, i) => (i * 3600000));

function getLessons(t, lessons) {
    // console.log('getLesson: t, lessons = ', t, lessons);
    let fLessons = lessons.filter(l => (+moment(+t).startOf('hour') <= l.startTimestamp && l.startTimestamp < +moment(+t).endOf('hour')));
    // if (lesson != undefined) {
    //     console.log('t = ', moment(t).format('HH:mm'));
    //     console.log('lesson = ', lesson);
    // }
    return fLessons;
    // return lesson;
}


export default function NewLessonsWeekCalendar(props) {
    const {
        weekTimestamp, lessons = [], selectedLessonId = undefined, getGroup = (id) => undefined,
        onLessonClick = lId => {

        },
        onAddClick = t => {

        }
    } = props;

    let start = +moment(weekTimestamp).startOf('isoweek').startOf('day');
    let days = Array.from({length: 7}).map((a, i) => (+start + i * 86400000));
    let hours = HOURS.map(a => (+start + +a));
    console.log('render: hours = ', hours);
    let startOfWeek = +moment(weekTimestamp).startOf('isoweek').startOf('day');
    let endOfWeek = +moment(weekTimestamp).endOf('isoweek').endOf('day');
    let fLessons = lessons.filter(l => (l.startTimestamp >= startOfWeek && l.startTimestamp <= endOfWeek));

    // let selectedLesson = fLessons.find(l => l.id == selectedLessonId);
    // let selectedLessonStart = selectedLesson == undefined ? undefined : selectedLesson.startTimestamp;
    // let selectedLessonEnd = selectedLesson == undefined ? undefined : selectedLesson.endTimestamp;
    // let selectedLessonGroup = selectedLesson == undefined ? undefined : getGroup(selectedLesson.groupId);
    // let selectedLessonVerticalOffset = selectedLesson == undefined ? undefined : selectedLessonStart - +moment(selectedLessonStart).startOf('day');

    console.log('NewLessonsWeekCalendar: fLessons = ', fLessons);

    return (<Wrapper>

        <TopPlaceholder>

            {days.map((d, i) => {
                let isToday = (+moment().startOf('day') == +moment(d).startOf('day'));
                return (<TopDayItem key={d} today={isToday}>
                    <TopDayItemInner today={isToday}>
                        <DayName today={isToday}>
                            {
                                (getCurrentLang() == 'ru' ?
                                    [
                                        'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'
                                    ] : [
                                        'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'
                                    ])[moment(d).isoWeekday() - 1]}
                        </DayName>
                        <DayNumberCircle today={isToday}>
                            {moment(d).format('D')}
                        </DayNumberCircle>
                    </TopDayItemInner>
                </TopDayItem>)
            })}

        </TopPlaceholder>

        <ContentPlaceholder>

            <ContentSubPlaceholder>

                <ContentInner>

                    <AxisPlaceholder>
                        {hours.map((hour, i) => {
                            return (<AxRow key={hour}>
                                {i == 0 ? null : <HourLabelSpan>
                                    {`${moment(hour).format('HH')}:00`}
                                </HourLabelSpan>}
                            </AxRow>)
                        })}
                    </AxisPlaceholder>

                    <DaysPlaceholder>
                        <DaysInner>
                            {HOURS.map((hour, i) => {
                                return (<Row key={hour}>
                                    {days.map((day, j) => {
                                        let t = hour + day;
                                        let startOfHour = +moment(t).startOf('hour');
                                        let lessons = getLessons(t, fLessons);
                                        // let lesson = getLessons(t, fLessons);
                                        return (<Day key={t}>
                                            <DayContentInner>
                                                {lessons.length == 0 ?
                                                    <VoidHour onClick={() => {
                                                        // setAddDayTimestamp(+moment(t).startOf('day'));
                                                        // setAddTimestamp(t);
                                                        onAddClick(t);
                                                    }}>
                                                        <PlusSquare
                                                            src={require('../../../assets/images/mdi_add_box.svg')}/>

                                                    </VoidHour>
                                                    :
                                                    <>
                                                        {lessons.map((lesson, k) => {
                                                            let duration = (lesson == undefined) ? undefined : (lesson.endTimestamp - lesson.startTimestamp);
                                                            let lessonHeight = (duration == undefined) ? undefined : (duration / 3600000) * hourHeight;
                                                            let isSelected = (lesson != undefined && lesson.id == selectedLessonId);
                                                            let group = (lesson == undefined) ? undefined : getGroup(lesson.teacherGroupId);
                                                            let offset = (lesson == undefined) ? undefined : (lesson.startTimestamp - startOfHour) / 3600000 * hourHeight;
                                                            return (
                                                                <LessonBox selected={isSelected}
                                                                           onClick={evt => {
                                                                               onLessonClick(lesson?.id, evt);
                                                                           }}
                                                                           style={{
                                                                               top: offset, height: lessonHeight
                                                                           }}>
                                                                    {group == undefined ? 'N/A/' : group.name}
                                                                </LessonBox>
                                                            )
                                                        })}
                                                    </>
                                                }


                                            </DayContentInner>
                                        </Day>);
                                    })}
                                </Row>)
                            })}
                        </DaysInner>
                    </DaysPlaceholder>

                </ContentInner>

            </ContentSubPlaceholder>


        </ContentPlaceholder>

    </Wrapper>);
}

const PlusSquare = styled.img`
  width: 24px;
  height: 24px;
  display: none;
`;

const VoidHour = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  :hover {
    background: rgba(134, 209, 255, 0.10);
  }

  &:hover ${PlusSquare} {
    display: block;
  }
`;

const LessonBox = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  cursor: pointer;
  background: ${props => (props.selected == true ? '#2B6CFF' : '#D7BFFF')};
  color: ${props => (props.selected == true ? 'white' : 'black')};
  font-weight: ${props => (props.selected == true ? 'bold' : 'normal')};
  box-sizing: border-box;
  padding: 4px;
`;

const DayContentInner = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  //overflow: hidden;
  position: relative;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 22px;
  background: #FFFFFF;
  box-shadow: 0px 5px 10px rgba(31, 32, 65, 0.05);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 22px;
`;

const hourHeight = 60;

const Row = styled.div`
  //border-bottom: 1px solid #D5D5D5;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: ${hourHeight}px;
`;

const AxRow = styled.div`
  height: ${hourHeight}px;
  position: relative;
`;

const HourLabelSpan = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #939598;
  top: -6px;
  right: 5px;
  position: absolute;
`;

const Day = styled.div`
  box-sizing: border-box;
  flex: 1;
  border-right: 1px solid #D5D5D5;
  border-bottom: 1px solid #D5D5D5;
`;

const leftAxisWidth = 65;
const topHeight = 78;

const TopPlaceholder = styled.div`
  height: ${topHeight}px;
  box-sizing: border-box;
  border-bottom: 2px solid #8593A8;
  padding-left: ${leftAxisWidth}px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
`;

const TopDayItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TopDayItemInner = styled.div`
  width: 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: ${props => (props.today == true ? '2px solid #FFA71B' : '2px solid #8798AD')};
`;

const DayName = styled.div`
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: #202224;
  opacity: 0.8;
  margin-top: 3px;
`;

const DayNumberCircle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: ${props => (props.today == true ? 'white' : '#525252')};
  background: ${props => (props.today == true ? '#FFA71B' : 'transparent')};
  border-radius: 1000px;
  width: 24px;
  height: 24px;
  margin-top: 7px;
`;

const ContentPlaceholder = styled.div`
  max-height: calc(100% - ${topHeight}px);
  height: calc(100% - ${topHeight}px);
`;

const ContentSubPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const ContentInner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
`;

const AxisPlaceholder = styled.div`
  box-sizing: border-box;
  border-right: 1px solid #D5D5D5;
  width: ${leftAxisWidth}px;
`;

const DaysPlaceholder = styled.div`
  width: 100%;
  flex: 1;
  position: relative;
`;

const DaysInner = styled.div`
  width: 100%;
  max-height: 100%;
  position: relative;
`;
