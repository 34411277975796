import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import HorChessTemplate from '../templates/HorChessTemplate'

import UnderConstructionPanel from '../construction/UnderConstructionPanel'
import AdminStatsPanel from "../admin/panels/AdminStatsPanel";
import IraTeacherTemplate from "../templates/IraTeacherTemplate";


export default function AdminIndexApp() {

    return (
        <IraTeacherTemplate active={'index'}>

            <AdminStatsPanel/>

        </IraTeacherTemplate>
    );
}

const Wrapper = styled.div`
    
`;
