import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import CommonHelper from "../../../helpers/CommonHelper";

export default function TeacherPersonalPanel(props) {
    let {id} = props;
    let {currentUser, loading, teacher} = useMappedState(useCallback(state => {
        let teacher = state.schools.schoolsMap.get(id);
        return {
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            loading: state.users.loading,
            teacher: teacher
        }
    }, [id]));

    return (
        <Wrapper>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const SchoolsList = styled.div`
    
`;

const SimpleSchoolItem = styled.div`
    margin-bottom: 40px;
`;

const SchoolItem = styled.div`
    margin-bottom: 10px;
    padding: 10px;
    background: white;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    :hover{
      opacity: 0.7;
    }
`;

const Name = styled.div`
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
`;
