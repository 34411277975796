import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'

import styled from 'styled-components';
import HorChessTemplate from "../templates/HorChessTemplate";

import {Container, Heading} from "../ui/Container";
import CommonHelper from "../../helpers/CommonHelper";

import CoursePanel from '../courses/panels/CoursePanel'
import StudentPersonalPanel from "../students/panels/StudentPersonalPanel";

import Sidebar from 'arui-feather/sidebar'
import UpdateUserForm from "../users/forms/UpdateUserForm";
import * as usersActions from "../../redux/actions/UsersActions";

export default function StudentApp(props) {
    let {id} = props.match.params;
    const dispatch = useDispatch();
    const [modalVisible, setModalVisible] = useState(false);
    const {student, currentUser, currentUserId, loading} = useMappedState(useCallback(state => ({
        student: state.users.usersMap.get(id),
        currentUser: state.users.usersMap.get(state.users.currentUserId),
        currentUserId: state.users.currentUserId,
        loading: state.users.loading
    }), [id]));
    let canEdit = (currentUser != undefined && currentUser.userRole == 'admin');
    if (student != undefined && student.franchiseeId == currentUserId) {
        canEdit = true;
    }

    return (
        <HorChessTemplate active={'student'}>

            {student == undefined ? null :
                <StudentName>

                    {`Ученик ${student.firstName} ${student.lastName}`}

                    {canEdit == false ? null :
                        <EditImg src={require('../../assets/images/edit.svg')} onClick={() => {
                            setModalVisible(true);
                        }}/>
                    }
                </StudentName>
            }

            <Container>
                <StudentPersonalPanel id={id}/>
            </Container>

            <Sidebar visible={modalVisible} onCloserClick={() => {
                setModalVisible(false);
            }}>

                {modalVisible == false || student == undefined ? null :
                    <div>
                        <UpdateUserForm
                            {...student}
                            loading={loading}
                            showStudentInfo={true}
                            showParentInfo={true}
                            avatarVisible={true}
                            passwordVisible={false}
                            emailVisible={false}
                            onSave={data => {
                                dispatch(usersActions.updateUser({
                                    ...data,
                                    id: student.id
                                })).then(pld => {
                                    setModalVisible(false);
                                })
                            }}
                        />
                    </div>
                }

            </Sidebar>

        </HorChessTemplate>
    );
}

const Wrapper = styled.div`
    
`;

const StudentName = styled.div`
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 10px;
    font-weight: bold;
`;

const TopLink = styled.div`
    display: inline-block;
    text-decoration: underline;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;

const EditImg = styled.img`
    height: 18px;
    width: 18px;
    margin-left: 4px;
    display: inline-block;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;
