import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import {Input} from "../../ui/Input";
import {Textarea} from "../../ui/Textarea";

import {Button} from "../../ui/Button";
import {translate} from "../../../utils/Translate";

export default function UpdateTestForm(props) {

    const {
        onSave = d => {

        },
        loading = false
    } = props;

    const [name, setName] = useState(props.name == undefined ? '' : props.name);
    const [description, setDescription] = useState(props.description == undefined ? '' : props.description);
    const [minRating, setMinRating] = useState(props.minRating == undefined ? 0 : props.minRating);
    const [maxRating, setMaxRating] = useState(props.maxRating == undefined ? 3000 : props.maxRating);
    const [duration, setDuration] = useState(props.duration == undefined ? 0 : props.duration);

    return (
        <Wrapper>


            <Field>
                <Label>
                    Название теста
                </Label>
                <Input value={name} onChange={evt => {
                    setName(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    Описание
                </Label>
                <Textarea value={description} onChange={evt => {
                    setDescription(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    Минимальный рейтинг
                </Label>
                <Input value={minRating} onChange={evt => {
                    setMinRating(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    Максимальный рейтинг
                </Label>
                <Input value={maxRating} onChange={evt => {
                    setMaxRating(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    Ограничение на время выполнения (в секундах)
                </Label>
                <Input value={duration} onChange={evt => {
                    setDuration(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Spin visible={loading}/>
                {loading == true ? null :
                    <Button onClick={() => {
                        onSave({
                            name,
                            description,
                            minRating: +minRating,
                            maxRating: +maxRating,
                            duration: +duration
                        });
                    }}>
                        {translate('save')}
                    </Button>
                }
            </Field>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Field = styled.div`
    
`;

const Label = styled.div`
    font-weight: bold;
`;
