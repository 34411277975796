import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import * as actions from '../../../redux/actions/SmartExercisesActions'
import ExerciseForm from "../../rubius/exercise/aliens/ExerciseForm";

import Tabs from "../../ui/Tabs";
import UpdateSmartExerciseForm from "../forms/UpdateSmartExerciseForm";
import IllegalWorkspace from "../../rubius/exercise/Workspace/IllegalWorkspace";
import Workspace from "../../rubius/exercise/Workspace/Workspace";
import Editor from "../../rubius/exercise/Editor/Editor";
import {translate} from "../../../utils/Translate";

const TABS = [
    {
        label: 'Информация',
        value: 'info',
        isOnlyForAdmin: true
    },
    {
        label: 'Редактор',
        value: 'editor',
        isOnlyForAdmin: true
    },
    {
        label: translate('view'),
        value: 'view',
    }
]

export default function UpdateSmartExercisePanel(props) {
    const {id} = props;

    let {currentUser, loading, smartExercise, userRole, isAdmin, currentUserId} = useMappedState(useCallback(state => {
        let userRole = (currentUser != undefined) ? currentUser.userRole : undefined;
        return {
            userRole: userRole,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            currentUserId: state.users.currentUserId,
            loading: state.smartExercises.loading,
            isAdmin: (userRole == 'admin' || userRole == 'methodist'),
            smartExercise: state.smartExercises.smartExercisesMap.get(id)
        }
    }, [id]));

    const [tab, setTab] = useState((isAdmin == true) ? 'info' : 'view');

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.loadSmartExerciseById(id));
    }, []);

    if (smartExercise == undefined) {
        return (
            <Code/>
        )
    }
    let {isIllegal = false} = smartExercise;
    let tabs = (isAdmin == true) ? TABS : TABS.filter(a => (a.isOnlyForAdmin != true));

    return (
        <Wrapper>

            <HorHeading>
                <TopLeft>
                    {tabs.map((a, i) => {
                        return (
                            <TabItem selected={(tab == a.value)} onClick={() => {
                                setTab(a.value);
                            }}>
                                {a.label}
                            </TabItem>
                        )
                    })}
                </TopLeft>
                <TopRight>

                </TopRight>
            </HorHeading>

            <Content key={tab}>

                {tab != 'info' ? null :
                    <div>
                        <UpdateSmartExerciseForm {...smartExercise}
                                                 loading={loading}
                                                 onSave={async d => {
                                                     let data = {...d, id: smartExercise.id};
                                                     await dispatch(actions.updateSmartExercise(data));
                                                 }}/>
                    </div>
                }

                {tab != 'view' ? null :
                    <div>

                        {isIllegal ?
                            <IllegalWorkspace {...smartExercise}
                                              boardWidthFraction={0.4}
                                              onExerciseFinish={() => {

                                              }}
                                              onStepLimitReached={() => {

                                              }}
                                              onSubExerciseStart={() => {

                                              }}
                                              onTimeFinished={() => {

                                              }}/>
                            :
                            <Workspace {...smartExercise}
                                       allowHandleKeyEvents={() => {

                                       }}
                                       boardWidthFraction={0.4}
                                       onSubExerciseStart={() => {

                                       }}
                                       onSubExerciseEnd={() => {

                                       }}
                                       onExerciseFinish={() => {

                                       }}
                                       onTimeFinished={() => {

                                       }}
                                       onTimerTick={() => {

                                       }}/>}
                    </div>
                }

                {tab != 'editor' ? null :
                    <div>
                        <Editor {...smartExercise}
                                onSave={async solutions => {
                                    let data = {solutions: solutions, id: smartExercise.id}
                                    await dispatch(actions.updateSmartExercise(data));
                                }}
                        />
                    </div>
                }

            </Content>


        </Wrapper>
    );
}

const Content = styled.div`
    
`;

const TabItem = styled.div`
    font-size: 16px;
    line-height: 24px;
    color: ${props => (props.selected ? '#085BFF' : '#8593A8')};
    font-weight: ${props => (props.selected ? 'bold' : 'normal')};
    cursor: ${props => (props.selected ? 'default' : 'pointer')};
    margin-right: 40px;
    :hover{
      color: #085BFF;
    }
`;

const Wrapper = styled.div`
    box-sizing: border-box;
`;

const topHeight = 70;

const HorHeading = styled.div`
    width: 100%;
    height: ${topHeight}px;
    background: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding-left: 40px;
    padding-right: 40px;
`;

const TopLeft = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const TopRight = styled.div`
    
`;
