import TagsAPI from '../../api/TagsAPI'

import * as types from '../ActionTypes'

let loadTags_ = () => {
    return {
        type: types.LOAD_TAGS
    }
}
let loadTagsSuccess = (tags) => {
    return {
        type: types.LOAD_TAGS_SUCCESS,
        tags: tags
    }
}
let loadTagsFailed = (error) => {
    return {
        type: types.LOAD_TAGS_FAIL,
        error: error
    }
}

//thunk
export function loadAllTags() {
    return (dispatch, getState) => {
        dispatch(loadTags_());
        return TagsAPI.getAllTags().then(
            tags => dispatch(loadTagsSuccess(tags)),
            error => dispatch(loadTagsFailed(error))
        )
    }
}

export function loadTagById(id) {
    return (dispatch, getState) => {
        dispatch(loadTags_());
        return TagsAPI.getTag(id).then(
            tag => dispatch(loadTagsSuccess([tag])),
            error => dispatch(loadTagsFailed(error))
        )
    }
}

let createTag_ = () => {
    return {
        type: types.CREATE_TAG
    }
}
let createTagSuccess = (tag) => {
    return {
        type: types.CREATE_TAG_SUCCESS,
        tag: tag
    }
}
let createTagFailed = (error) => {
    return {
        type: types.CREATE_TAG_FAIL,
        error: error
    }
}

//thunk
export function createTag(data) {
    return (dispatch, getState) => {
        dispatch(createTag_());
        return TagsAPI.createTag(data).then(
            tag => dispatch(createTagSuccess(tag)),
            error => dispatch(createTagFailed(error))
        )
    }
}

let updateTag_ = () => {
    return {
        type: types.UPDATE_TAG
    }
}
let updateTagSuccess = (tag) => {
    return {
        type: types.UPDATE_TAG_SUCCESS,
        tag: tag
    }
}
let updateTagFailed = (error) => {
    return {
        type: types.UPDATE_TAG_FAIL,
        error: error
    }
}

//thunk
export function updateTag(data) {
    return (dispatch, getState) => {
        dispatch(updateTag_());
        return TagsAPI.updateTag(data).then(
            tag => dispatch(updateTagSuccess(tag)),
            error => dispatch(updateTagFailed(error))
        )
    }
}

let deleteTag_ = () => {
    return {
        type: types.DELETE_TAG
    }
}
let deleteTagSuccess = (id) => {
    return {
        type: types.DELETE_TAG_SUCCESS,
        id: id
    }
}
let deleteTagFailed = (error) => {
    return {
        type: types.DELETE_TAG_FAIL,
        error: error
    }
}

//thunk
export function deleteTag(id) {
    return (dispatch, getState) => {
        dispatch(deleteTag_());
        return TagsAPI.deleteTag(id).then(
            () => dispatch(deleteTagSuccess(id)),
            error => dispatch(deleteTagFailed(error))
        )
    }
}
