import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import ChessHelper from "../../../helpers/ChessHelper";
import SabirChessBoard from "../../chessboard/tools/SabirChessBoard";

const COLORS_MAP = {
    lose: 'salmon',
    win: 'lightgreen',
    draw: 'whitesmoke'
}


export default function SolutionsList(props) {
    let {
        solutions = [],
        onItemClick = (itemId) => {

        },
        selectedId = undefined,
        onMove = () => {

        },
        onItemDelete = itemId => {

        }

    } = props;


    return (
        <Wrapper>

            {solutions.map((sol, k) => {
                let selected = (selectedId == sol.id);
                let col = COLORS_MAP[sol.type];
                if (col == undefined) {
                    col = 'white';
                }
                return (
                    <Item style={{backgroundColor: col}} selected={selected} key={`${sol.id}_${k}`}>
                        <Info onClick={() => {
                            onItemClick(sol.id);
                        }}>
                            <Name>
                                {sol.name}
                            </Name>
                            <Description>
                                {sol.description}
                            </Description>
                        </Info>

                        <Ello onClick={() => {
                            onItemClick(sol.id);
                        }}>
                            {sol.rating}
                        </Ello>

                        <Fen onClick={() => {
                            onItemClick(sol.id);
                        }}>
                            <FenImg src={ChessHelper.getFenImageUrl(sol.fen)}/>
                            {/*<SabirChessBoard fen={sol.fen} boardId={`${sol.id}`} />*/}
                        </Fen>

                        <ControlsPlaceholder>
                            {+k < solutions.length - 1 ?
                                <ArrowSpan style={{padding: 3}} onClick={() => {
                                    let i1 = k, i2 = k + 1;
                                    onMove(sol.id, i1, i2);
                                }}>
                                    <ArrowImg src={require('../../../assets/images/up-arrow.svg')} style={{transform: `rotate(180deg)`}} />
                                </ArrowSpan> : null
                            }
                            {+k == 0 ? null :
                                <ArrowSpan style={{padding: 3}} onClick={() => {
                                    let i1 = k - 1, i2 = k;
                                    onMove(sol.id, i1, i2);
                                }}>
                                    <ArrowImg src={require('../../../assets/images/up-arrow.svg')} style={{transform: `rotate(0deg)`}} />
                                </ArrowSpan>
                            }

                            <ArrowSpan style={{padding: 3}} onClick={() => {
                                if (window.confirm('Вы уверены?') == false){
                                    return;
                                }
                                onItemDelete(sol.id);
                            }} >
                                <ArrowImg src={require('../../../assets/images/clear.svg')} />
                            </ArrowSpan>

                        </ControlsPlaceholder>

                    </Item>
                )
            })}

        </Wrapper>
    );
}

const Wrapper = styled.div`
    padding: 5px;
`;

const Item = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background: white;
    margin-bottom: 10px;
    box-sizing: border-box;
    padding: 10px;
    background: ${props => (props.selected == true ? 'blue' : 'white')};
    color: ${props => (props.selected == true ? 'white' : 'black')};
    border-radius: 4px;
`;

const Fen = styled.div`
    width: 110px;
    height: 110px;
    cursor: pointer;
`;

const Ello = styled.div`
    width: 70px;
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    :hover{
      opacity: 0.75;
    }
`;

const Info = styled.div`
    flex: 1;
    cursor: pointer;
    :hover{
      opacity: 0.75;
    }
`;

const FenImg = styled.img`
    width: 100%;
    :hover{
      opacity: 0.75;
    }
`;

const Name = styled.div`
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
`;

const Description = styled.div`
    opacity: 0.7;
    font-size: 10px;
`;

const ControlsPlaceholder = styled.div`
    width: 80px;
    text-align: center;
    padding: 10px;
`;

const ArrowSpan = styled.span`
    padding: 3px;
    cursor: pointer;
    :hover{
      opacity: 0.7;
    }
`;

const ArrowImg = styled.img`
    width: 14px;
    height: 14px;
    margin: 3px;
`;
