import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'

export default function JitsiPanel(props) {
    let {roomName = 'sabir', displayName = 'Sabir', canShare = false} = props;
    const api = useRef();
    let divId = `jitsi_${roomName}`;
    useEffect(() => {
        const domain = 'meet.jit.si';
        const options = {
            roomName: roomName,
            parentNode: document.querySelector(`#${divId}`),
            muted: false,
            interfaceConfigOverwrite: {
                DEFAULT_REMOTE_DISPLAY_NAME: 'Собеседник',
                DISABLE_VIDEO_BACKGROUND: true,
                SHOW_BRAND_WATERMARK: false,
                SHOW_JITSI_WATERMARK: false,
                APP_NAME: 'ШАГИ',
                NATIVE_APP_NAME: 'ШАГИ',
                PROVIDER_NAME: 'ШАГИ',
                AUTHENTICATION_ENABLE: false,
                TOOLBAR_BUTTONS: [
                    'microphone', 'camera', 'fullscreen', 'fodeviceselection',
                    'etherpad', 'settings', 'videoquality', 'filmstrip', 'shortcuts'
                ],
                SETTINGS_SECTIONS: ['devices']
            },
            configOverwrite: {
                ignoreStartMuted: true,
                muted: false
            },
            onload: () => {
                try{
                    api.current.executeCommand('toggleFilmStrip');
                    api.current.executeCommand('displayName', displayName);
                }catch(exc){

                }

            }
        };
        setTimeout(() => {
            api.current = new window.JitsiMeetExternalAPI(domain, options);
        }, 1000)
        return () => {
            try {
                api.current.dispose();
            } catch (exc) {
                console.log('can not dispose jitsi');
            }
        }
    }, []);

    return (
        <Wrapper>
            <InnerWrapper id={divId}>

            </InnerWrapper>

            {canShare == false ? null :
                <SharePlaceholder>
                    <ShareImage src={require('../../../assets/images/share_ok.svg')} onClick={() => {
                        try {
                            api.current.executeCommand('toggleShareScreen');
                        } catch (exc) {

                        }
                    }}/>
                </SharePlaceholder>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;

const InnerWrapper = styled.div`
    width: 100%;
    height: 100%;
`;

const SharePlaceholder = styled.div`
    position: absolute;
    bottom: 20px;
    left: 10px;
    z-index: 100;
`;

const ShareImage = styled.img`
    width: 32px;
    height: 32px;
    cursor: pointer;
    opacity: 0.5;
    :hover{
      opacity: 1;
    }
`;
