import ExFoldersAPI from '../../api/ExFoldersAPI'

import * as types from '../ActionTypes'

let loadExFolders_ = () => {
    return {
        type: types.LOAD_EX_FOLDERS
    }
}
let loadExFoldersSuccess = (exFolders) => {
    return {
        type: types.LOAD_EX_FOLDERS_SUCCESS,
        exFolders: exFolders
    }
}
let loadExFoldersFailed = (error) => {
    return {
        type: types.LOAD_EX_FOLDERS_FAIL,
        error: error
    }
}

//thunk
export function loadAllExFolders() {
    return (dispatch, getState) => {
        dispatch(loadExFolders_());
        return ExFoldersAPI.getAllExFolders().then(
            exFolders => dispatch(loadExFoldersSuccess(exFolders)),
            error => dispatch(loadExFoldersFailed(error))
        )
    }
}

export function loadExFolderById(id) {
    return (dispatch, getState) => {
        dispatch(loadExFolders_());
        return ExFoldersAPI.getExFolder(id).then(
            exFolder => dispatch(loadExFoldersSuccess([exFolder])),
            error => dispatch(loadExFoldersFailed(error))
        )
    }
}

let createExFolder_ = () => {
    return {
        type: types.CREATE_EX_FOLDER
    }
}
let createExFolderSuccess = (exFolder) => {
    return {
        type: types.CREATE_EX_FOLDER_SUCCESS,
        exFolder: exFolder
    }
}
let createExFolderFailed = (error) => {
    return {
        type: types.CREATE_EX_FOLDER_FAIL,
        error: error
    }
}

//thunk
export function createExFolder(data) {
    return (dispatch, getState) => {
        dispatch(createExFolder_());
        return ExFoldersAPI.createExFolder(data).then(
            exFolder => dispatch(createExFolderSuccess(exFolder)),
            error => dispatch(createExFolderFailed(error))
        )
    }
}

let updateExFolder_ = () => {
    return {
        type: types.UPDATE_EX_FOLDER
    }
}
let updateExFolderSuccess = (exFolder) => {
    return {
        type: types.UPDATE_EX_FOLDER_SUCCESS,
        exFolder: exFolder
    }
}
let updateExFolderFailed = (error) => {
    return {
        type: types.UPDATE_EX_FOLDER_FAIL,
        error: error
    }
}

//thunk
export function updateExFolder(data) {
    return (dispatch, getState) => {
        dispatch(updateExFolder_());
        return ExFoldersAPI.updateExFolder(data).then(
            exFolder => dispatch(updateExFolderSuccess(exFolder)),
            error => dispatch(updateExFolderFailed(error))
        )
    }
}

let deleteExFolder_ = () => {
    return {
        type: types.DELETE_EX_FOLDER
    }
}
let deleteExFolderSuccess = (id) => {
    return {
        type: types.DELETE_EX_FOLDER_SUCCESS,
        id: id
    }
}
let deleteExFolderFailed = (error) => {
    return {
        type: types.DELETE_EX_FOLDER_FAIL,
        error: error
    }
}

//thunk
export function deleteExFolder(id) {
    return (dispatch, getState) => {
        dispatch(deleteExFolder_());
        return ExFoldersAPI.deleteExFolder(id).then(
            () => dispatch(deleteExFolderSuccess(id)),
            error => dispatch(deleteExFolderFailed(error))
        )
    }
}
