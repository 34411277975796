/**
 * Created by sabir on 19.07.17.
 */

import {Map, Stack, Set} from 'immutable'
import * as types from '../ActionTypes.js'

const initialState = {
    loading: false,

    smartExercisesMap: Map(),
    error: undefined

}

const startLoading = (state, action) => {
    return {...state, loading: true, error: undefined}
}

const stopLoading = (state, action) => {
    return {...state, loading: false, error: action.error}
}

const SmartExercisesReducer = (state = initialState, action = {}) => {

    switch (action.type) {


        case types.CREATE_SMART_EXERCISE:
        case types.UPDATE_SMART_EXERCISE:
        case types.DELETE_SMART_EXERCISE:
        case types.LOAD_SMART_EXERCISES:
            return {
                ...state,
                loading: true,
                error: undefined
            }

        case types.CREATE_SMART_EXERCISE_FAIL:
        case types.UPDATE_SMART_EXERCISE_FAIL:
        case types.DELETE_SMART_EXERCISE_FAIL:
        case types.LOAD_SMART_EXERCISES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case types.CREATE_SMART_EXERCISE_SUCCESS:
        case types.UPDATE_SMART_EXERCISE_SUCCESS:
            return {
                ...state,
                loading: false,
                smartExercisesMap: state.smartExercisesMap.set(action.smartExercise.id, action.smartExercise)
            }


        case types.DELETE_SMART_EXERCISE_SUCCESS:
            return {
                ...state,
                loading: false,
                smartExercisesMap: state.smartExercisesMap.delete(action.id),
            }


        case types.LOAD_SMART_EXERCISES_SUCCESS:
            return {
                ...state,
                loading: false,
                smartExercisesMap: state.smartExercisesMap
                    .merge(action.smartExercises.reduce((map, form) => map.set(form.id, form), Map()))
            }


        default:
            return state;
    }

}

export default SmartExercisesReducer;
