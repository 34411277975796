import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import * as cActions from '../../../redux/actions/CoursesActions'
import TestsList from "../lists/TestsList";
import TestsPicker from "../tools/TestsPicker";
import ManyExercisesPlayPanel from "../../lessons/view/ManyExercisesPlayPanel";

export default function UnitTestsPanel(props) {


    const {id, editable = true} = props;
    const [selectedId, setSelectedId] = useState(undefined);
    const [pickerVisible, setPickerVisible] = useState(false);

    let {currentUser, loading, tests, testsIds} = useMappedState(useCallback(state => {
        let currentUserId = state.users.currentUserId;
        let currentUser = state.users.usersMap.get(currentUserId);
        let unit = state.courses.unitsMap.get(id);
        let testsIds = (unit == undefined) ? [] : (unit.testsIds == undefined ? [] : unit.testsIds);
        let tests = testsIds.map(a => state.tests.testsMap.get(a)).filter(a => (a != undefined));
        return {
            currentUser: currentUser,
            loading: state.users.loading || state.tests.loading || state.courses.loading,
            tests: tests,
            testsIds: testsIds
        }
    }, []));

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(cActions.loadAllUnits()); // todo: optimize
    }, [id]);

    let selectedTest = (selectedId == undefined) ? undefined : tests.filter(a => (a.id == selectedId))[0];

    return (
        <Wrapper>

            {editable == false ? null :
                <TopPlaceholder>
                    <PickerSpan onClick={() => {
                        setPickerVisible(true);
                    }} >
                        Выбрать тесты
                    </PickerSpan>
                </TopPlaceholder>
            }


            <TestsPlaceholder>
                {tests.length == 0 ?
                    <div style={{textAlign: 'center', padding: 20}} >
                        В данном модуле нет тестов
                    </div>
                    :
                    <TestsList selectedId={selectedId} loading={loading} tests={tests} onSelect={a => {
                        setSelectedId(a);
                    }} />
                }

            </TestsPlaceholder>

            <Sidebar width={Math.min(820, window.innerWidth)} visible={pickerVisible} onCloserClick={() => {
                setPickerVisible(false);
            }} >

                {pickerVisible == false ? null :
                    <div>
                        <TestsPicker loading={loading} testsIds={testsIds} onChange={async newIds=> {
                            dispatch(cActions.updateUnit({id: id, testsIds: newIds}));
                        }} />
                    </div>
                }

            </Sidebar>


            <Sidebar width={Math.min(820, window.innerWidth)} visible={(selectedTest != undefined)} onCloserClick={() => {
                setSelectedId(undefined);
            }} >

                {selectedTest == undefined ? null :
                    <div>
                        <div style={{height: Math.min(0.7 * window.innerHeight, 320)}} >
                            <ManyExercisesPlayPanel exercisesIds={selectedTest.exercisesIds}/>
                        </div>
                    </div>
                }

            </Sidebar>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const TopPlaceholder = styled.div`
    text-align: right;
    margin-bottom: 10px;
`;

const PickerSpan = styled.span`
    text-decoration: underline;
    opacity: 0.8;
    cursor: pointer;
    :hover{
      opacity: 1;
    }
`;

const TestsPlaceholder = styled.div`
    
`;
