import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'

import {Button} from "../../ui/Button";
import {Input} from "../../ui/Input";
import ColorSelector from "../../chess/tools/ColorSelector";
import ChessHelper from "../../../helpers/ChessHelper";
import Spin from 'arui-feather/spin'

import {STARTING_FEN} from "../../../constants/config";

import {DangerButton} from "../../ui/Button";
import {translate} from "../../../utils/Translate";

export default function UpdateGameForm(props) {
    let {
        onSave = newData => {

        },
        onDelete = () => {

        },
        id = undefined,
        currentUserId = undefined,
        loading = false,
        buttonName = translate('save'),
        canDelete = false,
        opponentId = undefined
    } = props;
    const [wordId, setWordId] = useState(undefined);
    const [whiteUserId, setWhiteUserId] = useState((props.whiteUserId == undefined && props.blackUserId == undefined) ? currentUserId : props.whiteUserId);
    const [blackUserId, setBlackUserId] = useState(props.blackUserId);
    const [startingFen, setStartingFen] = useState(props.startingFen == undefined ? STARTING_FEN : props.startingFen);

    const [name, setName] = useState((props.name == undefined) ? '' : props.name);
    const [description, setDescription] = useState((props.description == undefined) ? '' : props.description);
    let color = (currentUserId == whiteUserId) ? 'w' : 'b';

    useEffect(() => {
        ChessHelper.generateWordId(1).then(wId => {
            if (whiteUserId == currentUserId) {
                setBlackUserId(wId);
            } else {
                setWhiteUserId(wId);
            }
            setWordId(wId);
        });
    }, []);

    return (
        <Wrapper>

            {id != undefined ? null :
                <Field>
                    <Label>
                        Цвет, за который вы играете
                    </Label>
                    <ColorSelector color={color} onChange={col => {
                        if (col == 'w') {
                            setWhiteUserId(currentUserId);
                            setBlackUserId(wordId);
                        } else {
                            setBlackUserId(currentUserId);
                            setWhiteUserId(wordId);
                        }
                    }}/>
                </Field>
            }

            <Field>
                <Label>
                    Название партии
                </Label>
                <Input value={name} onChange={evt => {
                    setName(evt.target.value);
                }}/>
            </Field>

            {id != undefined ? null :
                <Field>
                    <Label>
                        FEN начальной позиции
                    </Label>
                    <Input value={startingFen} onChange={evt => {
                        setStartingFen(evt.target.value);
                    }}/>
                </Field>
            }


            <Field>
                <Label>
                    ID оппонента
                </Label>
                {opponentId == undefined ?
                    <div>
                        {wordId}
                    </div> :
                    <div>
                        {opponentId}
                    </div>}

            </Field>

            <Field>
                <Spin visible={loading}/>
                {loading == true ? null :
                    <div>
                        <Button style={{marginLeft: 0}}
                                onClick={() => {
                                    onSave({
                                        name,
                                        description,
                                        whiteUserId,
                                        blackUserId,
                                        startingFen
                                    });
                                }}>
                            {buttonName}
                        </Button>

                        {canDelete == false ? null :
                            <DangerButton onClick={() => {
                                onDelete();
                            }}>
                                Удалить доску
                            </DangerButton>
                        }

                    </div>
                }
            </Field>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Field = styled.div`
    margin-bottom: 10px;
`;

const Label = styled.div`
    font-weight: bold;
`;
