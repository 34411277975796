import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import SimpleUploadPanel from "../../upload/SimpleUploadPanel";
import Tabs, {TabItem} from "../../ui/Tabs";

import {Input} from "../../ui/Input";
import {BlueButton} from "../../ira/ui/Buttons";
import {translate} from "../../../utils/Translate";

export default function VideoUrlSelectorTool(props) {
    const {
        url,
        onChange = u => {

        }
    } = props;

    const [text, setText] = useState(props.url == undefined ? '' : props.url);

    return (
        <Wrapper>

            <Tabs tabs={[
                {
                    label: 'Файл',
                    content: (
                        <TabItem>
                            {(url == undefined || url.indexOf('youtube') > -1) ? null :
                                <Video controls={true} key={url}>
                                    <source src={url}/>
                                </Video>
                            }
                            <SimpleUploadPanel onUploaded={url => {
                                onChange(url);
                            }}>
                                <UploadPlaceholder>
                                    загрузить другое видео (в формате .mp4)
                                </UploadPlaceholder>
                            </SimpleUploadPanel>
                        </TabItem>
                    )
                },
                {
                    label: 'Youtube',
                    content: (
                        <TabItem>
                            <Label>
                                Ссылка на YouTube
                            </Label>
                            <Input value={text} onChange={evt => {
                                setText(evt.target.value);
                            }} placeholder={'Ссылка вида https://www.youtube.com/watch?v=0_lfhVXT5oU'}/>
                            <div style={{marginTop: 20}} >
                                <BlueButton onClick={() => {
                                    onChange(text);
                                }}>
                                    {translate('save')}
                                </BlueButton>
                            </div>
                        </TabItem>
                    )
                }
            ]}/>


        </Wrapper>
    );
}

const UploadPlaceholder = styled.div`
    padding: 20px;
    border-radius: 4px;
    background: whitesmoke;
    text-align: center;
    margin-top: 10px;
    :hover{
      opacity: 0.7;
    }
`;

const Label = styled.div`
    
`;

const Wrapper = styled.div`
    
`;

const Video = styled.video`
    background: white;
    height: 300px;
    width: 100%;
`;
