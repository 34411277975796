import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'

import * as actions from '../../../redux/actions/TagsActions'

import {Dialog, Button, Card, Elevation} from '@blueprintjs/core'
import NameDescriptionForm from "../../common/forms/NameDescriptionForm";
import UpdateTagForm from "../forms/UpdateTagForm";

export default function AllTagsPanel(props) {
    const dispatch = useDispatch();
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedId, setSelectedId] = useState(undefined);
    const {tags, loading} = useMappedState(useCallback(state => {
        return {
            tags: state.tags.tagsMap.toArray().sort((a, b) => (+b.timestamp - +a.timestamp)),
            loading: state.tags.loading
        }
    }, []));

    const [type, setType] = useState('exercise');

    useDispatch(() => {
        dispatch(actions.loadAllTags());
    }, []);

    if (tags.length == 0 && loading == true) {
        return (
            <Code/>
        )
    }

    let selectedTag = (selectedId == undefined) ? undefined : tags.filter(a => (a.id == selectedId))[0];



    return (
        <Wrapper>

            <TabsPlaceholder>
                <TabNameItem selected={(type == 'exercise')} onClick={() => {
                    setType('exercise');
                }}>
                    Теги упражнений
                </TabNameItem>
                <TabNameItem selected={(type == 'sub-exercise')} onClick={() => {
                    setType('sub-exercise');
                }}>
                    Теги под-упражнений
                </TabNameItem>
            </TabsPlaceholder>

            <TopControlsPlaceholder>
                <Button onClick={() => {
                    setModalVisible(true);
                }}>
                    Добавить тег
                </Button>
            </TopControlsPlaceholder>

            <TagsList key={type}>

                {tags.filter(x => (x.type == type)).map((tag, k) => {
                    return (
                        <TagItem key={tag.id}>
                            <Card
                                elevation={Elevation.ONE}>
                                <Name>
                                    {tag.name}
                                </Name>
                                <p>
                                    {tag.description}
                                </p>
                                <Button icon={'edit'} text={'Редактировать'} onClick={() => {
                                    setSelectedId(tag.id);
                                }}/>
                            </Card>
                        </TagItem>
                    )
                })}

            </TagsList>

            <Dialog isOpen={(selectedTag != undefined)} title={'Редактирование тега'} onClose={() => {
                setSelectedId(undefined);
            }}>

                <InnerDialog key={type} >
                    <UpdateTagForm {...selectedTag} loading={loading} onSubmit={data => {
                        let d = {
                            ...selectedTag,
                            ...data
                        }
                        dispatch(actions.updateTag(d)).then(pld => {
                            setSelectedId(undefined);
                        });
                    }}/>
                </InnerDialog>

            </Dialog>

            <Dialog isOpen={modalVisible} title={'Создание тега'} onClose={() => {
                setModalVisible(false);
            }}>

                <InnerDialog key={type} >
                    <UpdateTagForm loading={loading} type={type} onSubmit={data => {
                        dispatch(actions.createTag(data)).then(pld => {
                            setModalVisible(false);
                        });
                    }}/>
                </InnerDialog>

            </Dialog>

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const TabsPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const TabNameItem = styled.div`
  border-bottom: ${props => (props.selected == true ? '2px solid blue' : '2px solid transparent')};
  cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
  margin-left: 15px;
  margin-right: 15px;
  padding: 7px;
`;

const TagsList = styled.div`

`;
const TagItem = styled.div`
  margin-bottom: 10px;
`;

const TopControlsPlaceholder = styled.div`
  margin-bottom: 10px;
`;

const InnerDialog = styled.div`
  padding: 10px;
`;

const Name = styled.div`
  font-weight: bold;
  font-size: 16px;
`;
