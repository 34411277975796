import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import SimpleBoardViewTool from "../tools/SimpleBoardViewTool";
import UiAPI from "../../../../api/UiAPI";
import UpdateBoardForm from "../forms/UpdateBoardForm";
import {Button} from "../../../ui/Button";

import Select from 'react-select'
import Tabs, {TabItem} from "../../../ui/Tabs";
import SimpleTexturesView from "../tools/SimpleTexturesView";
import SimpleFiguresView from "../tools/SimpleFiguresView";

const renderBoards = (boards, selectedId, onSelect, hasFigures = true) => {
    return boards.map((a, j) => {
        let s = {figures: 'Фигуры', squares: 'Клетки'}[a.type];
        return (
            <BoardItem selected={(a.id == selectedId)} key={a.id} onClick={() => {
                onSelect(a.id);
            }}>
                <Name>
                    {`${s} - ${a.name}`}
                </Name>
                <BoardPlaceholder>
                    <SimpleBoardViewTool {...a} data={a} hasFigures={hasFigures}/>
                </BoardPlaceholder>
            </BoardItem>
        )
    })
}

export default function AdminUpdateBoardsPanel(props) {
    const [boards, setBoards] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedId, setSelectedId] = useState(undefined);
    const [addModalVisible, setAddModalVisible] = useState(false);

    useEffect(() => {
        setLoading(true);
        UiAPI.getAllBoards().then(arr => {
            setBoards(arr);
            setLoading(false);
        })
    }, []);

    if (loading == true && boards.length == 0) {
        return (
            <Code/>
        );
    }

    const selectedBoard = (selectedId == undefined) ? undefined : boards.filter(a => (a.id == selectedId))[0];

    return (
        <Wrapper>

            <TopPlaceholder>
                <Button onClick={() => {
                    setAddModalVisible(true);
                }}>
                    Добавить оформление
                </Button>
            </TopPlaceholder>

            <Tabs tabs={[
                {
                    label: 'Клетки',
                    content: (
                        <TabItem>
                            <BoardsItems>
                                {boards.filter(a => (a.type == 'squares')).map((a, j) => {
                                    return (
                                        <Item selected={(a.id == selectedId)} key={a.id} onClick={() => {
                                            setSelectedId(a.id);
                                        }}>
                                            <SimpleTexturesView {...a} />
                                        </Item>
                                    )
                                })}
                                {/*{renderBoards(, selectedId, x => {*/}
                                {/*setSelectedId(x);*/}
                                {/*}, false)}*/}
                            </BoardsItems>
                        </TabItem>
                    )
                },
                {
                    label: 'Фигуры',
                    content: (
                        <TabItem>
                            <BoardsItems>
                                {boards.filter(a => (a.type == 'figures')).map((a, j) => {
                                    return (
                                        <Item selected={(a.id == selectedId)} key={a.id} onClick={() => {
                                            setSelectedId(a.id);
                                        }}>
                                            <SimpleFiguresView {...a} />
                                        </Item>
                                    )
                                })}
                                {/*{renderBoards(boards.filter(a => (a.type == 'figures')), selectedId, x => {*/}
                                {/*setSelectedId(x);*/}
                                {/*})}*/}
                            </BoardsItems>
                        </TabItem>
                    )
                },
            ]}/>

            <Sidebar width={Math.min(1100, window.innerWidth)} visible={(selectedBoard != undefined)}
                     onCloserClick={() => {
                         setSelectedId(undefined);
                     }}>

                {selectedBoard == undefined ? null :
                    <div>
                        <UpdateBoardForm {...selectedBoard} loading={loading} onSave={async a => {
                            setLoading(true);
                            await UiAPI.updateBoard({id: selectedBoard.id, ...a});
                            let arr = await UiAPI.getAllBoards();
                            setBoards(arr);
                            setSelectedId(undefined);
                            setLoading(false);
                        }}/>
                    </div>
                }

            </Sidebar>


            <Sidebar width={Math.min(1100, window.innerWidth)} visible={addModalVisible} onCloserClick={() => {
                setAddModalVisible(false);
            }}>

                {addModalVisible == false ? null :
                    <div>
                        <UpdateBoardForm loading={loading}
                                         onSave={async a => {
                                             setLoading(true);
                                             await UiAPI.createBoard(a);
                                             let arr = await UiAPI.getAllBoards();
                                             setBoards(arr);
                                             setAddModalVisible(false);
                                             setLoading(false);
                                         }}/>
                    </div>
                }

            </Sidebar>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const TopPlaceholder = styled.div`
    margin-bottom: 10px;
`;

const BoardsItems = styled.div`
    padding: 10px;
`;

const BoardItem = styled.div`
    padding: 10px;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 10px;
    border: ${props => (props.selected == true ? '2px solid blue' : '2px dashed lightgrey')};
    cursor: pointer;
    :hover{
      border: 2px solid blue;
      background: whitesmoke;
    }
`;

const Name = styled.div`
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
`;

const BoardPlaceholder = styled.div`
    width: 400px;
    height: 400px;
`;

const Item = styled.div`
    border: ${props => (props.selected == true ? '2px solid blue' : '2px dashed lightgrey')};
    padding: 5px;
    cursor: pointer;
    margin-bottom: 10px;
    :hover{
      opacity: 0.7;
    }
`;
