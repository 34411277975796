import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import * as actions from '../../../redux/actions/LessonsActions'
import UpdateLessonForm from "../forms/UpdateLessonForm";
import ExerciseViewPanel from "../../exercises/panels/ExerciseViewPanel";
import ManyExercisesPlayPanel from "../view/ManyExercisesPlayPanel";
import ManyUnitsPlayPanel from "../view/ManyUnitsPlayPanel";
import * as coursesActions from '../../../redux/actions/CoursesActions'


export default function LessonUnitsPanel(props) {
    let {id} = props;

    const {lesson, loading, unitsMap} = useMappedState(useCallback(state => {
        let lesson = state.lessons.lessonsMap.get(id);
        let sc = (lesson == undefined) ? undefined : state.schools.schoolsMap.get(lesson.schoolId);
        return {
            lesson: state.lessons.lessonsMap.get(id),
            school: sc,
            loading: state.lessons.loading,
            currentUserId: state.users.currentUserId,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            unitsMap: state.courses.unitsMap
        }
    }, [id]));
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(coursesActions.loadAllUnits())
    }, []);
    if (loading == true) {
        return (
            <Code/>
        )
    }
    if (lesson == undefined) {
        return null;
    }
    let {exercisesIds, unitsIds = []} = lesson;
    console.log('LessonUnitsPanel: render: unitsIds = ', unitsIds);


    return (
        <Wrapper>

            <ListPlaceholder>

                <ManyUnitsPlayPanel unitsIds={unitsIds} unitsMap={unitsMap}/>

            </ListPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const FormPlaceholder = styled.div`
    
`;

const ListPlaceholder = styled.div`
    width: 920px;
    box-sizing: border-box;
    margin: 0 auto;
    @media(max-width: 920px){
      width: 100%;
    }
`;

const Item = styled.div`
    
`;
