import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';

import {Map, Set} from 'immutable';
import styled from 'styled-components';
import axios from 'axios'
import {API_ENDPOINT} from "../../../constants/config";

export default function SimpleStudentExercisesStatsTool(props) {
    let {studentId, smartExercisesMap = Map(), student = undefined} = props;
    const [data, setData] = useState(data);
    const [selectedExerciseId, setSelectedExerciseId] = useState(undefined);
    const [selectedAnswer, setSelectedAnswer] = useState(undefined);

    useEffect(() => {
        if (studentId == undefined) {
            return;
        }
        axios.get(`${API_ENDPOINT}/user/${studentId}/subs-exercises-stats`, {
            params: {
                with_answers_map: 1
            }
        }).then(d => d.data.result).then(pld => {
            setData(pld);
        });
    }, [studentId]);

    useEffect(() => {
        setSelectedAnswer(undefined);
        if (selectedExerciseId == undefined) {
            return;
        }
        axios.get(`${API_ENDPOINT}/smart_exercise/${selectedExerciseId}/user/${studentId}`).then(d => d.data.result).then(pld => {
            setSelectedAnswer(pld);
        });
    }, [selectedExerciseId]);

    if (studentId == undefined || data == undefined) {
        return null;
    }

    let {exercisesIds = [], winsNumber, losesNumber, drawsNumber, subsAnswersSolutionsMap = {}} = data;
    let exercises = exercisesIds.map(exId => smartExercisesMap.get(exId)).filter(x => (x != undefined));
    let solutionsExercisesMap = [];

    console.log('Render: subsAnswersSolutionsMap = ', subsAnswersSolutionsMap);

    for (let key in subsAnswersSolutionsMap) {
        let a = subsAnswersSolutionsMap[key];
        if (solutionsExercisesMap[a.exerciseId] == undefined) {
            solutionsExercisesMap[a.exerciseId] = [];
        }
        solutionsExercisesMap[a.exerciseId].push(a);
    }
    let selectedExercise = (setSelectedExerciseId == undefined) ? undefined : exercises.filter(x => (x.id == selectedExerciseId))[0];
    console.log('Render: solutionsExercisesMap = ', solutionsExercisesMap);
    let selectedSolutions = (selectedExercise == undefined || selectedExercise.solutions == undefined) ? [] : selectedExercise.solutions;

    return (
        <Wrapper>

            <TopPlaceholder>
                <GreenSpan>
                    {winsNumber}
                </GreenSpan>
                <RedSpan>
                    {losesNumber}
                </RedSpan>
                <GreySpan>
                    {drawsNumber}
                </GreySpan>

            </TopPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;
const GreenSpan = styled.div`
  color: green;
  margin-left: 5px;
  margin-right: 5px;
`;

const RedSpan = styled.div`
  color: red;
  margin-left: 5px;
  margin-right: 5px;
`;

const GreySpan = styled.div`
  color: grey;
  margin-left: 5px;
  margin-right: 5px;
`;

const TopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
