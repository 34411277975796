import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import Spin from 'arui-feather/spin';
import {Textarea} from '../../ui/Textarea';
import {Input} from '../../ui/Input';
import {Button} from '../../ui/Button';
import PgnHelper from '../../pgnViewer/PgnHelper';
import Tabs from '../../ui/Tabs';
import ColorSelector from './ColorSelector';
import FinalExerciseDialogView from './FinalExerciseDialogView';
import ExerciseResultForm from './ExerciseResultForm';
import {translate} from "../../../../utils/Translate";


export default function ExerciseForm(props) {
    let {loading} = props;
    let onSave = !props.onSave ? () => {
    } : props.onSave;
    let [formData, setFormData] = useState(
        {
            name: props.name,

            minRating: props.minRating,
            maxRating: props.maxRating,

            isIllegal: props.isIllegal || false,
            description: props.description,
            color: props.color,
            tags: !props.tags ? [] : props.tags,
            foldersIds: !props.foldersIds ? [] : props.foldersIds,
            pgn: !props.pgn ? '' : props.pgn,
            fen: !props.fen ? '' : props.fen,
            stepLimit: props.stepLimit || 0,
            duration: props.duration || 0,
            finalImageUrl: props.finalImageUrl,
            finalAudioUrl: props.finalAudioUrl,
            finalVideoUrl: props.finalVideoUrl,
            finalText: !props.finalText ? '' : props.finalText,
            finalHtml: !props.finalHtml ? '' : props.finalHtml
        }
    );

    const setFen = () => {
        if (!formData.isIllegal) {
            const {game} = PgnHelper.parse(formData.pgn);
            const fen = game ? PgnHelper.getStartFen(game) : '';

            setFormData({...formData, fen});
        }
    }

    useEffect(() => setFen(), [formData.pgn]);

    return (
        <Wrapper>

            <Field>
                <Label>
                    Название упражнения
                </Label>
                <Input value={formData.name}
                       placeholder={'Название'}
                       autoFocus={true} onChange={evt => {
                    let newData = {...formData, name: evt.target.value};
                    setFormData(newData);
                }}/>
            </Field>

            <Field>
                <Label>
                    Нелегальная позиция
                </Label>
                <Input type='checkbox' checked={formData.isIllegal} onChange={evt => {
                    let newData = {...formData, isIllegal: evt.target.checked};
                    setFormData(newData);
                }}/>
            </Field>

            <Field>
                <Label>
                    Описание
                </Label>
                <Textarea value={formData.description} onChange={evt => {
                    let newData = {...formData, description: evt.target.value};
                    setFormData(newData);
                }}/>
            </Field>

            <Field>
                <Label>
                    PGN
                    <InfoSpan>
                        <a target={'_blank'} href={'https://lichess.org/editor'}>
                            <InfoImage src={infImg}/>
                        </a>
                    </InfoSpan>
                </Label>
                <Textarea value={formData.pgn}
                          placeholder={'Вставить PGN'}
                          style={{height: `20em`, minHeight: `20em`}}
                          onChange={evt => {
                              let newPgn = evt.target.value;
                              let newData = {...formData, pgn: newPgn};
                              setFormData(newData);
                          }}/>
            </Field>

            <Field>
                <Label>
                    FEN
                </Label>
                <Input value={formData.fen}
                       placeholder={'Вставить FEN начальной позиции'}
                       onChange={evt => {
                           let newData = {...formData, fen: evt.target.value};
                           setFormData(newData);
                       }}/>
                <div style={{textAlign: 'right'}}>
                    <BSpan onClick={() => setFen()}>
                        вставить FEN из PGN
                    </BSpan>
                </div>
            </Field>

            <Field>
                <Label>
                    Цвет игрока
                </Label>
                <ColorSelector color={formData.color} onChange={newColor => {
                    let newData = {...formData, color: newColor};
                    setFormData(newData);
                }}/>
            </Field>

            <Field>
                <Label>
                    Ограничение ходов
                </Label>

                <Input value={formData.stepLimit} onChange={evt => {
                    let newData = {...formData, stepLimit: +evt.target.value};
                    setFormData(newData);
                }}/>
            </Field>

            <Field>
                <Label>
                    Ограничение по времени
                </Label>

                <Input value={formData.duration} onChange={evt => {
                    let newData = {...formData, duration: +evt.target.value};
                    setFormData(newData);
                }}/>
            </Field>

            <h3>
                Визуальный вид финальной панельки
            </h3>

            <Tabs
                tabs={[
                    {
                        label: 'Редактор',
                        content: (
                            <div>
                                <ExerciseResultForm
                                    {...formData}
                                    onSave={dd => {
                                        setFormData({...formData, ...dd})
                                    }}
                                />
                            </div>
                        )
                    },
                    {
                        label: 'Превью',
                        content: (
                            <div>
                                <FinalExerciseDialogView
                                    imageUrl={formData.finalImageUrl}
                                    text={formData.finalText}
                                    videoUrl={formData.finalVideoUrl}
                                    audioUrl={formData.finalAudioUrl}
                                    html={formData.finalHtml}/>
                            </div>
                        )
                    }
                ]}
            />

            <Field>
                <Button onClick={() => onSave(formData)}>
                    {loading ? <Spin visible={true}/> : <span>{translate('save')}</span>}
                </Button>
            </Field>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    text-align: left;
    width: 770px;
    margin: 0 auto;
    background: white;
    padding: 30px;
    box-sizing: border-box;
    border: 1px solid rgba(8, 91, 255, 0.1);
    border-radius: 4px;
`;

const Field = styled.div`
    margin-bottom: 20px;
`;

const Label = styled.div`
    color: #8593A8;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 5px;
    font-weight: 500;
`;

const InfoSpan = styled.span`
    float: right;
`;

const infImg = require('../../assets/images/info_svg_.svg');

const InfoImage = styled.img`
    height: 16px;
    cursor: pointer;
    opacity: 0.4;
    :hover{
      opacity: 1;
    }
`;

const BSpan = styled.span`
    cursor: pointer;
    opacity: 0.4;
    font-size: 12px;
    font-style: italic;
    :hover{
      opacity: 0.8;
      text-decoration: underline;
    }
`;
