import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import ToolsAPI from "../../../api/ToolsAPI";

export default function FideInfoTool(props) {
    const {playerId} = props;
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();

    useEffect(() => {
        setLoading(true);
        setData(undefined);
        ToolsAPI.getFideData(playerId).then(d => {
            setLoading(false);
            setData(d);
        });
    }, [playerId]);

    if (loading == true && data == undefined) {
        return (<Spin visible={true}/>)
    }
    if (data == undefined) {
        return null;
    }
    let {title, standard_elo, rapid_elo, blitz_elo} = data;
    const fideUrl = `https://ratings.fide.com/profile/${playerId}`;

    return (
        <Wrapper>
            <Ul>
                <Li>
                    {`Звание: ${title}`}
                </Li>
                <Li>
                    {`Классические: ${standard_elo}`}
                </Li>
                <Li>
                    {`Быстрые: ${rapid_elo}`}
                </Li>
                <Li>
                    {`Блиц: ${blitz_elo}`}
                </Li>
            </Ul>

            <P>
                <a href={fideUrl} target={'_blank'} >
                    {`${fideUrl}`}
                </a>
            </P>

        </Wrapper>
    );
}

const Ul = styled.ul`
    padding-left: 0px;
    margin-left: 0px;
    list-style: none;
`;

const Li = styled.li`
    padding-left: 0px;
    margin-left: 0px;
`;

const Wrapper = styled.div`
    padding: 10px;
    box-sizing: border-box;
    background: whitesmoke;
    border: 1px dashed lightgrey;
    border-radius: 3px;
`;

const P = styled.div`
    font-size: 12px;
    opacity: 0.5;
    font-style: italic;
`;
