import React, {useState, useRef, useEffect} from 'react';
import styled from 'styled-components';
import PgnViewer from '../../pgnViewer/PgnViewer';
import PgnHelper from '../../pgnViewer/PgnHelper';
import useComponentSize from "@rehooks/component-size/index";

// guys, why don't you use flex boxes?
// it's 2020 - absolute positions are used as a last resort already

let idx = 0;
const ID = 'pgn-board-';

export default function PgnViewerWrapper(props) {
    const {
        pgn,
        pgnViewerClassName = '',
        pgnViewerStyle = {},
        boardWidthFraction = 0.4,
        allowHandleKeyEvents = true,
    } = props;

    const chessboardRef = useRef();
    const wrapperRef = useRef();

    const [fen, setFen] = useState('start');

    useEffect(() => {
        const {game} = PgnHelper.parse(pgn);
        setFen(game ? PgnHelper.getStartFen(game) : 'start');
    }, [])

    const [arrows, setArrows] = useState([]);
    const [highlightedSquares, setHighlightedSquares] = useState([]);

    const OnPgnMoveClick = ({node}) => {
        const nodeFen = PgnHelper.getFen(node)
        setFen(nodeFen);
        chessboardRef.current.loadFen(nodeFen);
        const [arrows, highlightedSquares] = PgnHelper.getArrowsAndHighlightedSquares(node);
        setArrows(arrows);
        setHighlightedSquares(highlightedSquares);
    }

    const wrapperSize = useComponentSize(wrapperRef);
    let sizeReady = (wrapperSize != undefined && wrapperSize.width > 0);
    console.log('render: PgnViewerWrapper: wrapperSize = ', wrapperSize);
    // let boardWidth = (sizeReady == false) ? undefined : Math.min(boardWidthFraction * wrapperSize.width, wrapperSize.height);
    let boardWidth = (sizeReady == false) ? undefined : boardWidthFraction * wrapperSize.width;

    console.log('boardWidth = ', boardWidth);


    return (
        <Wrapper ref={wrapperRef}>

            {sizeReady && <>
            <Left width={boardWidth} height={boardWidth}>
                <BoardContainer width={boardWidth}>
                    {React.cloneElement(props.children,
                        {
                            id: ID + (++idx),
                            ref: chessboardRef,
                            fen: fen,
                            width: boardWidth,
                            arrows: arrows,
                            highlightedSquares: highlightedSquares
                        })}
                </BoardContainer>
            </Left>
            <Right height={boardWidth}>
                <PgnViewerContainer>
                    <PgnViewer className={pgnViewerClassName} style={pgnViewerStyle} pgn={pgn}
                               onMoveClick={OnPgnMoveClick} allowHandleKeyEvents={allowHandleKeyEvents}/>
                </PgnViewerContainer>
            </Right>
            </>
            }
        </Wrapper>
    );
}

const Wrapper = styled.div`
  height: 100%;
  display: flex; 
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
`;

const Left = styled.div`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const Right = styled.div`
  flex: 1;
  height: ${props => props.height}px;
`;

const BoardContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const PgnViewerContainer = styled.div`
  width: 100%;
  height: 100%;    
  margin-left: 5px;    
  box-sizing: border-box;    
`;
