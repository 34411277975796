import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {API_ENDPOINT} from "../../../constants/config";
import BrutalOverlayModal from "../../modals/BrutalOverlayModal";
import NiceModalWithHeading from "../../modals/NiceModalWithHeading";
import NiceModal from "../../modals/NiceModal";

import {PgnViewerWrapper, Chessboard} from "../../rubius";
import ChessHelper from "../../../helpers/ChessHelper";

export default function StudentExecisesStatsTool(props) {
    let {studentId, smartExercisesMap = Map(), student = undefined} = props;
    const [data, setData] = useState(data);
    const [selectedExerciseId, setSelectedExerciseId] = useState(undefined);
    const [exerciseVisible, setExerciseVisible] = useState(false);
    const [selectedAnswer, setSelectedAnswer] = useState(undefined);

    useEffect(() => {
        if (studentId == undefined) {
            return;
        }
        axios.get(`${API_ENDPOINT}/user/${studentId}/subs-exercises-stats`, {
            params: {
                with_answers_map: 1
            }
        }).then(d => d.data.result).then(pld => {
            setData(pld);
        });
    }, [studentId]);

    useEffect(() => {
        setSelectedAnswer(undefined);
        if (selectedExerciseId == undefined) {
            return;
        }
        axios.get(`${API_ENDPOINT}/smart_exercise/${selectedExerciseId}/user/${studentId}`).then(d => d.data.result).then(pld => {
            setSelectedAnswer(pld);
        });
    }, [selectedExerciseId]);

    if (studentId == undefined || data == undefined) {
        return null;
    }

    let {exercisesIds = [], winsNumber, losesNumber, drawsNumber, subsAnswersSolutionsMap = {}} = data;
    let exercises = exercisesIds.map(exId => smartExercisesMap.get(exId)).filter(x => (x != undefined));
    let solutionsExercisesMap = [];

    console.log('Render: subsAnswersSolutionsMap = ', subsAnswersSolutionsMap);

    for (let key in subsAnswersSolutionsMap) {
        let a = subsAnswersSolutionsMap[key];
        if (solutionsExercisesMap[a.exerciseId] == undefined) {
            solutionsExercisesMap[a.exerciseId] = [];
        }
        solutionsExercisesMap[a.exerciseId].push(a);
    }
    let selectedExercise = (setSelectedExerciseId == undefined) ? undefined : exercises.filter(x => (x.id == selectedExerciseId))[0];
    console.log('Render: solutionsExercisesMap = ', solutionsExercisesMap);
    let selectedSolutions = (selectedExercise == undefined || selectedExercise.solutions == undefined) ? [] : selectedExercise.solutions;

    return (
        <Wrapper>

            <TopPlaceholder>
                <GreenSpan>
                    {winsNumber}
                </GreenSpan>
                <RedSpan>
                    {losesNumber}
                </RedSpan>
                <GreySpan>
                    {drawsNumber}
                </GreySpan>

            </TopPlaceholder>

            <ExercisesList>
                {exercises.map((ex, i) => {
                    let sols = solutionsExercisesMap[ex.id];
                    if (sols == undefined) {
                        sols = [];
                    }
                    let winSols = sols.filter(x => (x.assessment == 'win'));
                    let loseSols = sols.filter(x => (x.assessment == 'lose'));
                    let drawSols = sols.filter(x => (x.assessment == 'draw'));

                    return (
                        <ExerciseRow key={ex.id} onClick={() => {
                            setSelectedExerciseId(ex.id);
                        }}>

                            <ExName>
                                {ex.name}
                            </ExName>

                            <ExControls>
                                <GreenSpan>
                                    {winSols.length}
                                </GreenSpan>
                                <RedSpan>
                                    {loseSols.length}
                                </RedSpan>
                                <GreySpan>
                                    {drawSols.length}
                                </GreySpan>
                            </ExControls>

                        </ExerciseRow>
                    )
                })}
            </ExercisesList>

            {selectedExercise == undefined ? null :
                <NiceModalWithHeading
                    width={920}
                    heading={
                        <div style={{cursor: 'pointer'}} onClick={() => {
                            setExerciseVisible(true);
                        }}>
                            {selectedExercise.name}
                        </div>
                    }
                    subHeading={(student == undefined) ? '' : `${student.firstName} ${student.lastName}`}
                    onClose={() => {
                        setExerciseVisible(false);
                        setSelectedExerciseId(undefined);
                    }}>


                    <SelectedAnswerPlaceholder>
                        {selectedSolutions.map((sol, i) => {
                            let subResultsObject = (selectedAnswer == undefined) ? {} : (selectedAnswer.subResultsObject == undefined ? {} : selectedAnswer.subResultsObject);
                            let d = subResultsObject[sol.id];
                            let isWin = (d != undefined && d.assessment == 'win');
                            let isLose = (d != undefined && d.assessment == 'lose');
                            let isDraw = (d != undefined && d.assessment == 'draw');
                            let isSkip = (d == undefined);
                            let color = (isWin == true) ? 'green' : (isLose == true ? 'red' : (isDraw == true ? 'lightgrey' : 'grey'));
                            let label = (isWin == true) ? 'пройдено' : (isLose == true ? 'ошибка' : (isDraw == true ? 'не лучший ход' : 'пропущено'));
                            let winVariant = sol.variants.filter(x => (x.type == 'win'))[0];

                            return (
                                <SelaSol key={sol.id}>
                                    {winVariant == undefined ? null :
                                        <SelaSolBoardImg src={ChessHelper.getFenImageUrl(winVariant.fen)}/>
                                    }
                                    <div style={{color: color, width: 120, textAlign: 'left'}}>
                                        {label}
                                    </div>
                                </SelaSol>
                            )
                        })}
                    </SelectedAnswerPlaceholder>


                </NiceModalWithHeading>
            }

            {exerciseVisible == false ? null :
                <NiceModalWithHeading
                    heading={selectedExercise.name}
                    subHeading={''}
                    width={920} onClose={() => {
                    setExerciseVisible(false);
                }}>
                    <SelectedViewInner>

                        <PgnViewerWrapper
                            boardWidthFraction={0.5}
                            pgn={selectedExercise.pgn}>
                            <Chessboard/>
                        </PgnViewerWrapper>

                        {/*{JSON.stringify(selectedExercise)}*/}
                    </SelectedViewInner>

                </NiceModalWithHeading>
            }

        </Wrapper>
    );
}

const SelaSol = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid lightgrey;

  :last-of-type {
    border-bottom: none;
  }
`;

const SelaSolBoardImg = styled.img`
  width: 160px;
  height: 160px;
  margin-right: 20px;
`;

const SelectedAnswerPlaceholder = styled.div`
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Wrapper = styled.div`

`;

const SelectedViewInner = styled.div`
  width: 100%;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
`;

const ExerciseRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 0.4px solid rgba(81, 139, 219, 0.4);
  padding-left: 0px;
  padding-right: 0px;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const ExName = styled.div`
  font-weight: bold;
`;

const ExControls = styled.div`
  display: flex;
  flex-direction: row;
`;

const ExercisesList = styled.div`
  max-height: 60vh;
  overflow-y: auto;
`;

const GreenSpan = styled.div`
  color: green;
  margin-left: 5px;
  margin-right: 5px;
`;

const RedSpan = styled.div`
  color: red;
  margin-left: 5px;
  margin-right: 5px;
`;

const GreySpan = styled.div`
  color: grey;
  margin-left: 5px;
  margin-right: 5px;
`;

const TopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
