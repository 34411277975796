import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import DraggableRealtimeBoardWidget from "../board/DraggableRealtimeBoardWidget";
import TeacherLessonsPanel from "../../lessons/panels/TeacherLessonsPanel";
import {SET_DRAGGABLE_LESSON, UNSET_DRAGGABLE_LESSON} from "../../../redux/ActionTypes";

export default function TeacherLessonsDraggableBoardWidgetPanel(props) {
    // const [selectedLesson, setSelectedLesson] = useState(undefined);
    const [, updateState] = React.useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    let {draggableLesson} = useMappedState(useCallback(state => {
        return {
            draggableLesson: state.ui.draggableLesson
        }
    }, []));
    const dispatch = useDispatch();

    const lessRef = useRef();
    const {
        onClose = () => {

        }
    } = props;

    console.log('render: draggableLesson = ', draggableLesson);

    return (
        <Wrapper className={'TeacherLessonsDraggableBoardWidgetPanel_cl'} >
            {draggableLesson == undefined ? null :
                <DraggableRealtimeBoardWidget
                    boardId={`lesson_${draggableLesson.id}`}
                    topRightContent={(
                        <SettingsIcon src={require('../../../assets/images/cross_cancel.svg')} onClick={() => {
                            dispatch({type: SET_DRAGGABLE_LESSON, lesson: undefined})
                            onClose();
                        }}/>
                    )}
                    topLeftContent={(
                        <TopLeftPlaceholder style={{cursor: 'pointer'}} onClick={() => {

                        }}>
                            <NameSpan>
                                {`${moment(draggableLesson.startTimestamp).format('DD.MM.YYYY HH:mm')}${draggableLesson.name == undefined ? '' : ` - ${draggableLesson.name}`}`}
                            </NameSpan>
                            <SettingsIcon src={require('../../../assets/images/settings_a.svg')} style={{marginLeft: 5}}
                                          onClick={() => {
                                              // setSelectedLesson(undefined);
                                              dispatch({type: SET_DRAGGABLE_LESSON, lesson: undefined})
                                          }}/>

                        </TopLeftPlaceholder>
                    )}
                />
            }

            <Sidebar visible={(draggableLesson == undefined)} onCloserClick={() => {
                if (lessRef.current == undefined) {
                    onClose();
                } else {
                    // setSelectedLesson(lessRef.current);
                    dispatch({type: SET_DRAGGABLE_LESSON, lesson: lessRef.current})
                }
            }}>

                {draggableLesson != undefined ? null :
                    <div>
                        Выберите урок, куда вы хотите транслировать доску
                        <TeacherLessonsPanel onSelect={(lId, l) => {
                            lessRef.current = l;
                            // setSelectedLesson(l);
                            dispatch({type: SET_DRAGGABLE_LESSON, lesson: l})
                            // forceUpdate();
                        }}/>
                    </div>
                }

            </Sidebar>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    height: 0px;
    z-index: 100000;
    position: fixed;
    top: 30px;
    left: 30px;
`;

const SettingsIcon = styled.img`
    height: 16px;
    width: 16px;
    cursor: pointer;
    opacity: 0.7;
    :hover{
      opacity: 0.9;
    }
`;

const TopLeftPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 5px;
`;

const NameSpan = styled.span`
    font-size: 10px;
`;
