import EloRating from 'elo-rating'
import moment from 'moment'

const EloHelper = {

    getKFactor(x) {
        if (+x < 2100) {
            return 31;
        }
        if (+x < 2400) {
            return 24;
        }
        return 16;
    },

    getEloRating(a, b, assessment = 'win') { // win, lose, draw
        if (assessment == 'win') {
            return EloRating.calculate(a, b).playerRating;
        }
        if (assessment == 'lose') {
            return EloRating.calculate(b, a).playerRating;
        }
        let expected = EloRating.expected(a, b);
        let score = 0.5;
        let K = this.getKFactor(a);
        let newRating = a + K * (score - expected);
        return newRating;
    },

    getEloRatingArray(points = [], baseRating = 1200) {
        let pts = points.filter(x => (x.result != 'skip'));
        let arr = [];
        let currRating = baseRating;
        for (let i in pts) {
            let p = pts[i];
            let newRating = this.getEloRating(currRating, p.exRating, p.assessment);
            currRating = newRating;
            arr.push({
                ...p,
                rating: newRating
            });
        }
        return arr;
    },

    getDailyEloRating(points, baseRating = 1200) {
        console.log('getDailyEloRating: points = ', points);
        let pts = this.getEloRatingArray(points, baseRating);
        pts = pts.sort((a, b) => (+a.t - +b.t));
        let map = {};
        for (let i in pts) {
            let p = pts[i];
            let key = +moment(p.t).startOf('day');
            key = `${key}`;
            map[key] = p.rating;
        }
        return Object.keys(map).map(x => ({t: +x, rating: map[x]})).sort((a, b) => (+a.t - +b.t));
    }

}

export default EloHelper;
