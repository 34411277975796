import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled, {keyframes} from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'

import FirebaseAPI from '../../../api/FirebaseAPI'
import SizeDetector from '@atlaskit/size-detector'
import PlayableSabirChessBoard from "../../chessboard/tools/PlayableSabirChessBoard";

import {STARTING_FEN} from "../../../constants/config";
import ChessHelper from "../../../helpers/ChessHelper";


export default function RealtimeBoardImage(props) {
    let {
        boardId = 'sabir', orientation = 'white',
        loading = false,
        shouldShowBottomInfo = true, startingFen = STARTING_FEN,
        myColor = undefined
    } = props;
    const [boardData, setBoardData] = useState(undefined);
    const [initialized, setInitialized] = useState(false);
    const roomName = `room_${boardId}`;
    const currRef = useRef(undefined);

    useEffect(() => {
        FirebaseAPI.initFirebase();
        FirebaseAPI.subscribeOnChanges(roomName, chData => {
            console.log('chData = ', chData);
            if (chData == undefined) {
                return
            }
            setBoardData(chData.boardData);
        }, startingFen).then((pld) => {
            setInitialized(true);
            console.log('subscribeOnChanges: then: pld = ', pld);
            if (pld.unsubscribeFunction != undefined) {
                currRef.current = {unsubscribeFunction: pld.unsubscribeFunction};
            }
            if (pld == undefined || pld.data == undefined) {
                return;
            }
            setBoardData(pld.data);
        }).catch(err => {
            console.log('RealtimeBoard: can not subscribe!');
        });

        return () => {
            console.log('trying to unsubscribe!');
            try {
                currRef.current.unsubscribeFunction();
            } catch (exc) {
                console.log('can not unsubscribe');
            }
        }
    }, [boardId]);

    const history = (boardData == undefined) ? [] : (boardData.history == undefined ? [] : boardData.history);
    // const fen = (boardData == undefined) ? STARTING_FEN : (boardData.startingFen == undefined ? STARTING_FEN : boardData.startingFen);
    const fen = startingFen;
    // console.log('RealtimeBoardImage: history = ', history);
    let currentFen = ChessHelper.getFenByInitialFenAndMoves(fen, history.map(a => ({...a, move: a.san})));
    // console.log('RealtimeBoard: startingFen, boardData = ', startingFen, boardData);
    // console.log('-------fen = ', fen);
    // console.log('currentFen = ', currentFen);
    const fenUrl = ChessHelper.getFenImageUrl(currentFen);
    // console.log('fenUrl = ', fenUrl);
    let moveColor = currentFen.split(' ')[1];
    let isMyTurn = (moveColor == myColor);
    console.log('RealtimeBoardImage: moveColor, myColor = ', moveColor, myColor);
    const boardInfo = ChessHelper.getBoardInfoByFen(currentFen);
    let {inDraw, inCheckmate, inCheck, gameOver, inStalemate} = boardInfo;

    return (
        <Wrapper key={currentFen} className={(isMyTurn == true) ? 'animated' : ''}
                 src={require('../../../assets/images/void_square_placeholder.jpg')}
                 style={{opacity: boardInfo.inCheckmate == true ? 0.6 : 1}}
        >
            <SizeDetector onResize={d => {

            }}>
                {({width, height}) => (
                    <Inner style={{width: width, height: width}} src={fenUrl}>
                        {(loading == true || initialized == false) ? null :
                            <RealInner src={fenUrl}>
                                {gameOver == false ? null :
                                    <StatsPlaceholder>
                                        {(isMyTurn == true && inCheckmate == true) ?
                                            <span style={{color: 'red'}}>
                                                {`0:1`}
                                            </span> : null
                                        }
                                        {(isMyTurn == false && inCheckmate == true) ?
                                            <span style={{color: 'green'}}>
                                                {`1:0`}
                                            </span> : null
                                        }
                                        {(inDraw == true || inStalemate == true) ?
                                            <span style={{color: 'black'}}>
                                                {`1/2:1/2`}
                                            </span> : null
                                        }
                                    </StatsPlaceholder>
                                }
                            </RealInner>
                        }
                    </Inner>
                )}
            </SizeDetector>
        </Wrapper>
    );
}

const blink = keyframes`
  from, to {    
        border-color: transparent;
    }    
    50% {    
        border-color: green;
        border-radius: 5px;
        border-style: dashed;
    } 
`;

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    background: white;
    background-image: url("${props => props.src}");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-width: 2px;
    border-color: transparent;
    border-style: solid;
    box-sizing: border-box;
    &.animated{
      animation: ${blink} 2s linear infinite;
    }
`;

const Img = styled.img`
    width: 100%;
`;

const Inner = styled.div`
    
`;

const RealInner = styled.div`
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url(${props => (props.src)});
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StatsPlaceholder = styled.div`
    padding: 5px;
    border: 1px dashed grey;
    border-radius: 4px;
    background: whitesmoke;
    font-weight: bold;
`;
