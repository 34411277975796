import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import HorChessTemplate from "../templates/HorChessTemplate";


import {Container} from "../ui/Container";
import AllExercisesPanel from "../exercises/panels/AllExercisesPanel";

import Tabs from "../ui/Tabs";
import AllExercisesFoldersPanel from "../exercises_folders/panels/AllExercisesFoldersPanel";
import CoolExercisesFoldersPanel from "../exercises_folders/panels/CoolExercisesFoldersPanel";
import IraTeacherTemplate from "../templates/IraTeacherTemplate";
import AllSmartExercisesPanel from "../smart_exercises/panels/AllSmartExercisesUpdatePanel";
import CommonHelper from "../../helpers/CommonHelper";
import SmartExercisesAndFoldersViewTool from "../smart_exercises/tools/SmartExercisesAndFoldersViewTool";
import SmartExercisesAndFoldersPanel from "../smart_exercises/panels/SmartExercisesAndFoldersPanel";
import SmartExercisesTagsPanel from "../smart_exercises/panels/SmartExercisesTagsPanel";
import {useMappedState} from "redux-react-hook";
import {translate} from "../../utils/Translate";

const TABS = [
    // {
    //     label: 'Все упражнения',
    //     value: 'all'
    // },
    {
        label: translate('exercises_editor'),
        value: 'editor',
    },
    {
        label: translate('tags_and_rating'),
        value: 'tags',
    },

]

export default function AdminSmartExercisesApp(props) {
    const {currentUserId, currentUser} = useMappedState(useCallback(state => ({
        currentUserId: state.users.currentUserId,
        currentUser: state.users.usersMap.get(state.users.currentUserId)
    }), []));

    const [expandedFolderId, setExpandedFolderId] = useState(undefined);
    const [tab, setTab] = useState('editor');

    let userRole = (currentUser == undefined) ? undefined : currentUser.userRole;

    let tabs = (userRole == 'admin' || userRole == 'methodist') ? TABS : TABS.filter(x => (x.value != 'tags'));

    return (
        <IraTeacherTemplate active={'exercises'}>

            <HorHeading>
                <TopLeft>
                    {tabs.map((a, i) => {
                        return (
                            <TabItem selected={(tab == a.value)} onClick={() => {
                                setTab(a.value);
                            }}>
                                {a.label}
                            </TabItem>
                        )
                    })}
                </TopLeft>
                <TopRight>

                </TopRight>
            </HorHeading>

            <Wrapper key={tab}>

                {tab != 'editor' ? null :
                    <div>
                        <SmartExercisesAndFoldersPanel/>
                    </div>
                }

                {tab != 'tags' ? null :
                    <div>
                        <SmartExercisesTagsPanel/>
                    </div>
                }

            </Wrapper>

        </IraTeacherTemplate>
    );
}

const TabItem = styled.div`
    font-size: 16px;
    line-height: 24px;
    color: ${props => (props.selected ? '#085BFF' : '#8593A8')};
    font-weight: ${props => (props.selected ? 'bold' : 'normal')};
    cursor: ${props => (props.selected ? 'default' : 'pointer')};
    margin-right: 40px;
    :hover{
      color: #085BFF;
    }
`;

const Wrapper = styled.div`
    box-sizing: border-box;
    padding: 30px;
`;

const topHeight = 70;

const HorHeading = styled.div`
    width: 100%;
    height: ${topHeight}px;
    background: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding-left: 40px;
    padding-right: 40px;
`;

const TopLeft = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const TopRight = styled.div`
    
`;
