import GamesAPI from '../../api/GamesAPI'

import * as types from '../ActionTypes'

let loadGames_ = () => {
    return {
        type: types.LOAD_GAMES
    }
}
let loadGamesSuccess = (games) => {
    return {
        type: types.LOAD_GAMES_SUCCESS,
        games: games
    }
}
let loadGamesFailed = (error) => {
    return {
        type: types.LOAD_GAMES_FAIL,
        error: error
    }
}

//thunk
export function loadAllGames() {
    return (dispatch, getState) => {
        dispatch(loadGames_());
        return GamesAPI.getAllGames().then(
            games => dispatch(loadGamesSuccess(games)),
            error => dispatch(loadGamesFailed(error))
        )
    }
}

export function loadGameById(id) {
    return (dispatch, getState) => {
        dispatch(loadGames_());
        return GamesAPI.getGame(id).then(
            game => dispatch(loadGamesSuccess([game])),
            error => dispatch(loadGamesFailed(error))
        )
    }
}

let createGame_ = () => {
    return {
        type: types.CREATE_GAME
    }
}
let createGameSuccess = (game) => {
    return {
        type: types.CREATE_GAME_SUCCESS,
        game: game
    }
}
let createGameFailed = (error) => {
    return {
        type: types.CREATE_GAME_FAIL,
        error: error
    }
}

//thunk
export function createGame(data) {
    return (dispatch, getState) => {
        dispatch(createGame_());
        return GamesAPI.createGame(data).then(
            game => dispatch(createGameSuccess(game)),
            error => dispatch(createGameFailed(error))
        )
    }
}

let updateGame_ = () => {
    return {
        type: types.UPDATE_GAME
    }
}
let updateGameSuccess = (game) => {
    return {
        type: types.UPDATE_GAME_SUCCESS,
        game: game
    }
}
let updateGameFailed = (error) => {
    return {
        type: types.UPDATE_GAME_FAIL,
        error: error
    }
}

//thunk
export function updateGame(data) {
    return (dispatch, getState) => {
        dispatch(updateGame_());
        return GamesAPI.updateGame(data).then(
            game => dispatch(updateGameSuccess(game)),
            error => dispatch(updateGameFailed(error))
        )
    }
}

let deleteGame_ = () => {
    return {
        type: types.DELETE_GAME
    }
}
let deleteGameSuccess = (id) => {
    return {
        type: types.DELETE_GAME_SUCCESS,
        id: id
    }
}
let deleteGameFailed = (error) => {
    return {
        type: types.DELETE_GAME_FAIL,
        error: error
    }
}

//thunk
export function deleteGame(id) {
    return (dispatch, getState) => {
        dispatch(deleteGame_());
        return GamesAPI.deleteGame(id).then(
            () => dispatch(deleteGameSuccess(id)),
            error => dispatch(deleteGameFailed(error))
        )
    }
}
