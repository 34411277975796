import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import LessonTasksPanel from "../panels/LessonTasksPanel";
import ExerciseViewPanel from "../../exercises/panels/ExerciseViewPanel";
import UnitEditorPanel from "../../courses/panels/UpdateUnitPanel";
import UnitItemsEditor from "../../units/editors/UnitItemsEditor";
import ManyExercisesPlayPanel from "./ManyExercisesPlayPanel";

export default function ManyTestsPlayPanel(props) {
    const {testsIds = [], testsMap = Map()} = props;
    const [number, setNumber] = useState(0);
    console.log('ManyTestsPlayPanel: testsIds = ', testsIds);
    if (testsIds.length == 0) {
        return null;
    }
    let selectedTestId = testsIds[number];
    let selectedTest = (selectedTestId == undefined) ? undefined : testsMap.get(selectedTestId);

    return (
        <Wrapper>

            <NumbersPlaceholder>
                {testsIds.map((eId, k) => {
                    let selected = (eId == selectedTestId);
                    return (
                        <NumberItem
                            selected={selected}
                            key={`${k}_${eId}`}
                            onClick={() => {
                                setNumber(k);
                            }}
                        >
                            {`${+k + 1}`}
                        </NumberItem>
                    )
                })}
            </NumbersPlaceholder>

            <SelectedExercisePlaceholder key={`${number}_${selectedTestId}`}>
                {selectedTest == undefined ? null :
                    <>
                    <h3 style={{marginBottom: 20, textAlign: 'center'}}>
                        {selectedTest.name}
                    </h3>
                    <div style={{height: 400}}>
                        <ManyExercisesPlayPanel exercisesIds={selectedTest.exercisesIds}/>
                    </div>
                    </>
                }
            </SelectedExercisePlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const NumbersPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
`;

const NumberItem = styled.div`
    padding: 4px;
    background: ${props => (props.selected == true ? 'blue' : 'white')};
    color: ${props => (props.selected == true ? 'white' : 'black')};
    width: 32px;
    height: 32px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    :hover{
      opacity: 0.7;
    }
`;

const SelectedExercisePlaceholder = styled.div`
    height: 500px;
    //background: whitesmoke;  
`;
