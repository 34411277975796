import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import rec_img from './recorder.svg'
import NiceModal from "../../modals/NiceModal";
import LessonRecordingsPanel from "./LessonRecordingsPanel";
import RecordingsAPI from "../../../api/RecordingsAPI";
import ReactHelper from "../../../helpers/ReactHelper";

export default function RecordingInfoSpan(props) {
    const {
        lessonId
    } = props;

    const [modalOpen, setModalOpen] = useState(false);
    const [initialized, setInitialized] = useState(false);

    const [loading, setLoading] = useState(false);
    const [recordings, setRecordings] = useState([]);

    useEffect(() => {
        setLoading(true);
        RecordingsAPI.getLessonRecordings(lessonId).then(arr => {
            setRecordings(arr);
            setLoading(false);
            setInitialized(true);
        });
    }, [lessonId, modalOpen]);

    let activeRecordings = recordings.filter(x => (x.status == 'new'));
    let isRecording = (activeRecordings.length > 0);
    let spanText = 'Включить запись';
    if (isRecording == true) {
        spanText = 'Идет запись...'
    }

    if (initialized == false) {
        return null;
    }

    return (
        <OuterWrapper>

            <Wrapper onClick={() => {
                setModalOpen(true);
            }}>
                <Img src={rec_img}/>
                <Span recording={isRecording}>
                    {spanText}
                </Span>
            </Wrapper>

            {modalOpen == false ? null :
                <NiceModal onClose={() => {
                    setModalOpen(false);
                }}>

                    <InnerWrapper>
                        <LessonRecordingsPanel
                            {...props}
                            onChange={() => {
                                setModalOpen(false);
                            }}
                        />
                    </InnerWrapper>

                </NiceModal>
            }

        </OuterWrapper>

    );
}

const InnerWrapper = styled.div`
  width: 520px;
  box-sizing: border-box;
  padding: 10px;
`;

const OuterWrapper = styled.div`

`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

const Img = styled.img`
  height: 32px;
`;

const Span = styled.span`
  margin-left: 5px;
  margin-right: 5px;
  color: ${props => (props.recording == true ? 'green' : 'black')};
`;