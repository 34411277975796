import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';
import * as actions from '../../../redux/actions/UsersActions'
import UpdateProfileForm from "../forms/UpdateProfileForm";

export default function UpdateProfilePanel(props) {
    const {userId} = props;
    const dispatch = useDispatch();
    let {user, loading, uId} = useMappedState(useCallback(state => {
        let uId = (userId == undefined) ? state.users.currentUserId : userId;
        let user = state.users.usersMap.get(uId);
        return {
            user: user,
            loading: state.users.loading,
            uId: uId
        }
    }, []));
    useEffect(() => {
        dispatch(actions.loadUserById(uId));
    }, [uId]);

    if (user == undefined) {
        return null;
    }

    return (
        <Wrapper>

            <UpdateProfileForm {...user} loading={loading}
                               onSave={async d => {
                                   await dispatch(actions.updateUser({id: uId, ...d}))
                               }}/>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;
