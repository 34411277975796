import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';
import axios from 'axios'
import * as lessonsActions from "../../../redux/actions/LessonsActions";
import * as teacherGroupsActions from "../../../redux/actions/TeacherGroupsActions";
import * as usersActions from "../../../redux/actions/UsersActions";
import {API_ENDPOINT} from "../../../constants/config";
import NiceModalWithHeading from "../../modals/NiceModalWithHeading";
import ChessHelper from "../../../helpers/ChessHelper";

const voidCheckbox = require('../../../assets/images/void_checkbox.svg');
const check = require('../../../assets/images/chbx.svg');

export default function LessonAttendancePanel(props) {
    const {id} = props;
    let {currentUser, loading, lesson, groupsMap, uId, teacherGroup, students, exercises} = useMappedState(useCallback(state => {
        let uId = state.users.currentUserId;
        let lesson = state.lessons.lessonsMap.get(id);
        let teacherGroup = (lesson == undefined) ? undefined : state.teacherGroups.teacherGroupsMap.get(lesson.teacherGroupId);

        let students = (teacherGroup == undefined || teacherGroup.studentsIds == undefined) ? [] : teacherGroup.studentsIds.map(x => state.users.usersMap.get(x)).filter(a => (a != undefined));

        return {
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            loading: state.lessons.loading,
            lesson: state.lessons.lessonsMap.get(id),
            uId: uId,
            teacherGroup: teacherGroup,
            students: students
        }
    }, [id]));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(lessonsActions.loadAllLessons()); // todo: optimize
        dispatch(teacherGroupsActions.loadAllTeacherGroupsOfTeacher(uId));
        dispatch(usersActions.loadAllUsers());
    }, [uId]);

    if (lesson == undefined) {
        return null;
    }

    let {contentUsersIds = [], attendanceUsersIds = []} = lesson;

    return (
        <Wrapper>

            <InnerWrapper>

                <TopTableHeading>
                    <NameCol>
                        Ученик
                    </NameCol>
                    <ContCol style={{cursor: 'pointer'}} onClick={async () => {
                        if (attendanceUsersIds.length > 0) {
                            await dispatch(lessonsActions.updateLesson({
                                id: id,
                                attendanceUsersIds: []
                            }));
                        } else {
                            await dispatch(lessonsActions.updateLesson({
                                id: id,
                                attendanceUsersIds: students.map(a => a.id)
                            }));
                        }
                    }}>
                        Посетил урок
                    </ContCol>
                    <ContCol style={{cursor: 'pointer'}} onClick={async () => {
                        if (contentUsersIds.length > 0) {
                            await dispatch(lessonsActions.updateLesson({
                                id: id,
                                contentUsersIds: []
                            }));
                        } else {
                            await dispatch(lessonsActions.updateLesson({
                                id: id,
                                contentUsersIds: students.map(a => a.id)
                            }));
                        }
                    }}>
                        Доступны материалы
                    </ContCol>
                </TopTableHeading>

                {students.map((st, i) => {
                    let isAttended = (attendanceUsersIds.indexOf(st.id) > -1);
                    let isContentAvailable = (contentUsersIds.indexOf(st.id) > -1);
                    return (
                        <TaskRow key={st.id}>
                            <NameCol>
                                {`${st.firstName} ${st.lastName}`}
                            </NameCol>
                            <ContCol>
                                <CheckboxImg src={isAttended ? check : voidCheckbox} onClick={async () => {
                                    let newAttendanceUsersIds = (isAttended == true) ? attendanceUsersIds.filter(aId => (aId != st.id)) : attendanceUsersIds.concat([st.id]);
                                    await dispatch(lessonsActions.updateLesson({
                                        id: id,
                                        attendanceUsersIds: newAttendanceUsersIds
                                    }));
                                }}/>
                            </ContCol>
                            <ContCol>
                                <CheckboxImg src={isContentAvailable ? check : voidCheckbox} onClick={async () => {
                                    let newContentUsersIds = (isContentAvailable == true) ? contentUsersIds.filter(aId => (aId != st.id)) : contentUsersIds.concat([st.id]);
                                    await dispatch(lessonsActions.updateLesson({
                                        id: id,
                                        contentUsersIds: newContentUsersIds
                                    }));
                                }}/>
                            </ContCol>
                        </TaskRow>
                    )
                })}

            </InnerWrapper>

        </Wrapper>
    );
}

const CheckboxImg = styled.img`
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 17px;
`;

const Wrapper = styled.div`
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
    position: relative;
`;

const InnerWrapper = styled.div`
    background: white;
`;

const ContCol = styled.div`
    min-width: 200px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-right: 1px solid rgba(255,255,255,0.41);
    :last-of-type{
      border-right: none !important;
    }
`;

const NameCol = styled.div`
    width: 280px;
    min-width: 280px;
    box-sizing: border-box;
    padding-left: 31px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-right: 1px solid rgba(255,255,255,0.41);
`;

const TaskRow = styled.div`
    padding-top: 27px;
    padding-bottom: 27px;
    border-bottom: 0.4px solid rgba(81, 139, 219, 0.4);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 31px;
    box-sizing: border-box;
    background: ${props => (props.selected != true ? 'white' : 'linear-gradient(0deg, #EDF8FF, #EDF8FF), #FFFFFF;')};
`;


const TopTableHeading = styled.div`
    background: #FFA71B;
    border-radius: 4px;
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #202224;
    box-sizing: border-box;
    padding-right: 31px;
`;
