import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import HorChessTemplate from "../templates/HorChessTemplate";


import {Container} from "../ui/Container";

import UnderConstructionPanel from '../construction/UnderConstructionPanel'
import {useMappedState, useDispatch} from "redux-react-hook";
import ExerciseViewPanel from "../exercises/panels/ExerciseViewPanel";
import UserExercisePanel from "../exercises/panels/UserExercisePanel";
import UserTournamentsPanel from "../tournaments/panels/UserTournamentsPanel";

export default function TournamentApp(props) {
    let {id} = props.match.params;

    return (
        <HorChessTemplate active={`tournaments`}>
            <UserTournamentsPanel/>
        </HorChessTemplate>
    );
}

const TopPlaceholder = styled.div`
    
`;

const Heading = styled.div`
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
`;

