import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import PlayableSabirChessBoard from "../../chessboard/tools/PlayableSabirChessBoard";
import ChessHelper from "../../../helpers/ChessHelper";

import useComponentSize from '@rehooks/component-size'

import {Map, Set} from 'immutable'
import SolutionSquaresView from "./SolutionSquaresView";
import HistoryView from "./HistoryView";
import SmartSolutionSquaresView from "./SmartSolutionSquaresView";
import SmartPgnViewer from "../../chess/view/smart/SmartPgnViewer";

export default function SmartPlayExerciseTool(props) {
    const {
        fen,
        pgn = undefined,
        solutions = [],
        movesHistory = [],
        opponentMoveDelay = 1 * 1000,
        userSolutionsMap = Map(),
        onCorrectAnswer = (solutionId) => {

        },
        onWrongAnswer = (solutionId) => {

        },
        correctMessage = 'Правильное решение',
        incorrectMessage = 'Ошибка'
    } = props;
    const ref = useRef(undefined);
    let size = useComponentSize(ref);
    const [history, setHistory] = useState(props.movesHistory == undefined ? [] : props.movesHistory);
    const [pcMoving, setPcMoving] = useState(false);
    const [orientation, setOrientation] = useState('white');
    const [subFinishedMessage, setSubFinishedMessage] = useState(undefined);
    const sanHistory = history.filter(a => (a != undefined)).map(a => a.san);
    const memSolution = useRef(undefined);
    const currentPossibleSolutions = solutions.filter((sol, j) => {
        return ChessHelper.isSubArray(sanHistory, sol.history.map(aa => aa.san));
    });
    let currentSolution = (currentPossibleSolutions.length == 0) ? undefined : currentPossibleSolutions[0];
    let secondPossibleSolution = (currentPossibleSolutions.length < 2) ? undefined : currentPossibleSolutions[1];
    console.log('render: currentSolution = ', currentSolution);
    console.log('render: size = ', size);
    let {width, height} = size;

    useEffect(() => {
        console.log('history has changed! history = ', history);
        let index = history.length;
        if (currentSolution == undefined || currentSolution.history == undefined) {
            console.log('losing! memSolution.current = ', memSolution.current);
            try {
                onWrongAnswer(memSolution.current.id);
                setSubFinishedMessage(incorrectMessage);
            } catch (exc) {

            }
            return; // no need to make moves
        }
        memSolution.current = currentSolution;
        if (index > currentSolution.history.length - 1) {
            try {
                onCorrectAnswer(currentSolution.id);
            } catch (exc) {

            }
            if (currentPossibleSolutions.length == 1) {
                setSubFinishedMessage(correctMessage);
            } else {
                setTimeout(() => {
                    setPcMoving(false);
                    console.log('pc makes move! mv = ', mv);
                    setHistory(history.concat([secondPossibleSolution.history[index]]));
                }, opponentMoveDelay);
            }
            return;
        }
        if (index % 2 == 0) { // either starting position or aftermath of PC move
            return;
        }
        setPcMoving(true);
        const mv = currentSolution.history[index];
        setTimeout(() => {
            setPcMoving(false);
            console.log('pc makes move! mv = ', mv);
            setHistory(history.concat([mv]));
        }, opponentMoveDelay);
    }, [history.length]);

    let canMakeMoves = (subFinishedMessage == undefined);
    if (width == 0 || height == 0) {
        return <Wrapper ref={ref}></Wrapper>;
    }
    const bSize = Math.min(width, height) - 40;

    return (
        <Wrapper ref={ref} key={`sz_${width}_${height}`} >

            <BoardPlaceholder
                boardSize={bSize}
                key={`b_h_${(canMakeMoves == true) ? '1' : '0'}`}>
                <PlayableSabirChessBoard
                    orientation={orientation}
                    canMakeMoves={canMakeMoves}
                    startHistory={history}
                    fen={fen} onMove={(newFen, newPgn, newHistory) => {
                    console.log('newFen, newPgn, newHistory = ', newFen, newPgn, newHistory);
                    setHistory(newHistory);
                }}
                    onOrientationChange={() => {
                        setOrientation({'white': 'black', 'black': 'white'}[orientation]);
                    }}
                />

                {pcMoving == false ? null :
                    <PcOverlay>
                        Компьютер делает ход
                    </PcOverlay>
                }
            </BoardPlaceholder>

            <TasksPlaceholder boardSize={bSize} >

                {currentSolution == undefined ? null :
                    <CurrentInfoPlaceholder>
                        <CurrentName>
                            {currentSolution.name}
                        </CurrentName>
                        {((currentSolution == undefined) || (currentSolution.description == '')) ? null :
                            <CurrentDescription>
                                {currentSolution.description}
                            </CurrentDescription>
                        }
                    </CurrentInfoPlaceholder>
                }

                <SmartPgnViewer pgnString={pgn}
                                hasBoard={false}
                                onPositionChange={(newFen, newHistory) => {

                                }}/>

                <SolutionSquaresViewPlaceholder>

                    {/*<SmartPgnViewer pgnString={pgn}*/}
                    {/*onPositionChange={(newFen, newHistory) => {*/}

                    {/*}}/>*/}

                    {/*<SmartSolutionSquaresView*/}
                    {/*solutions={solutions}*/}
                    {/*currentIds={currentPossibleSolutions.map(a => a.id)}*/}
                    {/*userSolutionsMap={userSolutionsMap}*/}
                    {/*mainId={(currentSolution == undefined) ? undefined : currentSolution.id}*/}
                    {/*/>*/}
                </SolutionSquaresViewPlaceholder>


                {history.length > 0 ? null :
                    <NoMovesPlaceholder>
                        Сделайте первый ход
                    </NoMovesPlaceholder>
                }
                <HistoryView history={history}/>
            </TasksPlaceholder>

            {subFinishedMessage == undefined ? null :
                <SubfinishOverlay>
                    <FinishMessagePlaceholder>
                        <FinishMessage>
                            {`${subFinishedMessage}`}
                        </FinishMessage>
                        <FlushSpan onClick={() => {
                            setSubFinishedMessage(undefined);
                            setHistory([]);
                        }}>
                            начать с начала
                        </FlushSpan>

                        <FlushSpan onClick={() => {

                        }}>
                            показать решение
                        </FlushSpan>

                        <FlushSpan onClick={() => {

                        }}>
                            перейти к следующей задаче
                        </FlushSpan>

                    </FinishMessagePlaceholder>
                </SubfinishOverlay>
            }

        </Wrapper>
    );
}

// const verPad = 60;
const verPad = 120;

const boardSize = Math.min(window.innerHeight - verPad, window.innerWidth);

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    @media (orientation: portrait) {
      display: block;
    }
`;

const SubfinishOverlay = styled.div`
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
`;

const BoardPlaceholder = styled.div`
    width: ${props => (props.boardSize == undefined ? boardSize : props.boardSize)}px;
    position: relative;
    min-height: ${props => (props.boardSize == undefined ? boardSize : props.boardSize)}px;
`;

const TasksPlaceholder = styled.div`
    flex: 1;
    align-self: stretch;
    padding-left: 10px;
    padding-right: 10px;
    height: ${props => (props.boardSize == undefined ? boardSize : props.boardSize)}px;
    max-height: ${props => (props.boardSize == undefined ? boardSize : props.boardSize)}px;
    overflow-y: hidden;
    @media (orientation: portrait) {
      display: block;
      height: auto;
      max-height: none;
    }
`;

const SolutionSquaresViewPlaceholder = styled.div`
    height: calc(100% - 100px);
    max-height: calc(100% - 100px);
    overflow-y: auto;
    border-bottom: 1px solid whitesmoke;
    border-top: 1px solid whitesmoke;
    padding-top: 10px;
`;

const Li = styled.li`
    background: ${props => (props.selected == true ? 'wheat' : 'transparent')};
    font-style: ${props => (props.selected == true ? 'italic' : 'normal')};
    margin-bottom: 30px;
`;

const PcOverlay = styled.div`
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 1;
    background: rgba(255, 255, 255, 0.5);
    align-items: center;
    justify-content: center;
    display: flex;
`;

const FinishMessagePlaceholder = styled.div`
    text-align: center;
    margin-top: 10px;
`;

const FlushSpan = styled.span`
    cursor: pointer;
    opacity: 0.8;
    font-size: 14px;
    text-decoration: underline;
    margin-left: 10px;
    margin-right: 10px;
    :hover{
      opacity: 1;
    }
`;

const FinishMessage = styled.div`
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 10px;
`;

const CurrentInfoPlaceholder = styled.div`
    margin-bottom: 10px;
    padding-bottom: 10px;
    text-align: center;  
`;

const CurrentName = styled.div`
    font-size: 18px;
    font-weight: bold;
`;

const CurrentDescription = styled.div`
    opacity: 0.5;
    font-size: 12px;
`;

const NoMovesPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;
