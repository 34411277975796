import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'

import isEmail from 'validator/lib/isEmail'

import {Input} from "../../ui/Input";
import {Button} from "../../ui/Button";

import Spin from 'arui-feather/spin'

export default function AddByEmailForm(props) {
    const {
        onSubmit = (em) => {

        },
        hint = undefined,
        placeholder = 'Email',
        buttonName = 'Добавить',
        loading = false
    } = props;
    const [email, setEmail] = useState('');

    return (
        <Wrapper>

            <Field>
                <Input value={email} onChange={evt => {
                    setEmail(evt.target.value);
                }} placeholder={placeholder} autoFocus={true}/>
                {hint == undefined ? null :
                    <Hint>
                        {hint}
                    </Hint>
                }
            </Field>

            <Field>
                <Button onClick={() => {
                    if (isEmail(email) == false) {
                        return;
                    }
                    onSubmit(email);
                }}>
                    <Spin visible={loading}/>
                    {loading == true ? null : buttonName}
                </Button>
            </Field>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Field = styled.div`
    margin-bottom: 10px;
`;

const Hint = styled.div`
    opacity: 0.75;
    font-size: 10px;
    font-style: italic;
`;
