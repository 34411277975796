import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import HorChessTemplate from "../templates/HorChessTemplate";


import {Container} from "../ui/Container";

import UnderConstructionPanel from '../construction/UnderConstructionPanel'
import {useMappedState, useDispatch} from "redux-react-hook";
import ExerciseViewPanel from "../exercises/panels/ExerciseViewPanel";
import UserExercisePanel from "../exercises/panels/UserExercisePanel";
import TournamentPanel from "../tournaments/panels/TournamentPanel";

export default function TournamentApp(props) {
    let {id, gameId, userId} = props.match.params;

    return (
        <Wrapper>
            <TournamentPanel id={id}
                             selectedGameId={gameId}
                             userId={userId}
            />
        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;
