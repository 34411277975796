import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import useDebouncedCallback from "use-debounce/lib/callback";
import {LIGHT_BROWN} from "../../ui/Colors";

import './style.css'

import Tabs from "../../ui/Tabs";
import ExerciseForm from "../forms/ExerciseForm";
import SmartPgnViewer from "../../chess/view/smart/SmartPgnViewer";

import * as exercisesActions from '../../../redux/actions/ExercisesActions'

import Sidebar from 'arui-feather/sidebar'

import {Button} from "../../ui/Button";
import ExerciseSolutionsForm from "../forms/ExerciseSolutionsForm";
import PlayExerciseTool from "../tools/PlayExerciseTool";
import SmartPlayExerciseTool from "../tools/SmartPlayExerciseTool";
import SmartSizePlayExerciseTool from "../tools/SmartSizePlayExerciseTool";

export default function ExerciseViewPanel(props) {
    const dispatch = useDispatch();
    let {id} = props;
    let {currentUser, loading, userRole, exercise} = useMappedState(useCallback(state => {
        let userRole = (currentUser != undefined) ? currentUser.userRole : undefined;
        return {
            userRole: userRole,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            currentUserId: state.users.currentUserId,
            loading: state.exercises.loading,
            exercise: state.exercises.exercisesMap.get(props.id)
        }
    }, []));

    useEffect(() => {
        dispatch(exercisesActions.loadExerciseById(id)).then(pld => {
            console.log('exercise has been loading');
        });
    }, []);

    if (currentUser == undefined) {
        return (
            <Wrapper>
                Access denied
            </Wrapper>
        )
    }

    if (loading == true && exercise == undefined) {
        return (
            <Code/>
        )
    }

    if (exercise == undefined) {
        return null;
    }

    return (
        <Wrapper>

            <Heading>
                {exercise.name}
            </Heading>

            <Content>
                <SmartSizePlayExerciseTool {...exercise} />
            </Content>

            <Content>
                {/*<SmartPlayExerciseTool {...exercise} />*/}
            </Content>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Heading = styled.div`
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 24px;
`;

const Content = styled.div`
    
`;
