import React, { useState, useEffect, useRef, useCallback, useReducer, useMemo } from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import {LIGHT_BROWN} from "../../ui/Colors";

import {PIECES_MAP} from "../../../constants/config";

export default function ColorSelector(props) {
    const {color = 'b', onChange = () => {}} = props;

    return (
        <Wrapper>

            <Item selected={(color == 'w')} onClick={() => {
                onChange('w')
            }} >
                <Img src={PIECES_MAP['K'].smartIcon} />
            </Item>

            <Item selected={(color == 'b')} onClick={() => {
                onChange('b')
            }} >
                <Img src={PIECES_MAP['k'].smartIcon} />
            </Item>

        </Wrapper>
    );
}

const imgSize = 35;

const Wrapper = styled.div`
    
`;

const Item = styled.div`
    display: inline-block;
    box-sizing: border-box;
    padding: 5px;
    margin: 2px;
    text-align: center;
    background-color: ${props => (props.selected == true ? LIGHT_BROWN : 'transparent')};
    cursor: pointer;
    border: 1px solid whitesmoke;
    border-radius: 3px;
    :hover{
      opacity: 0.75;
    }
    :first-of-type{
      margin-left: 0px;
    }
`;

const Img = styled.img`
    width: ${imgSize}px;
    height: ${imgSize}px;
    display: inline-block;
`;
