import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'
import * as actions from "../../../redux/actions/TeacherGroupsActions";
import * as usersActions from '../../../redux/actions/UsersActions'
import CommonHelper from "../../../helpers/CommonHelper";

export default function UpdateTeacherGroupMembershipPanel(props) {
    let {teacherId, groupId} = props;
    const [addModalVisible, setAddModalVisible] = useState(false);
    const [selectedGroupId, setSelectedGroupId] = useState(undefined);
    const dispatch = useDispatch();
    let {currentUser, loading, currentUserId, allGroups, group, groupStudents, allStudents, studentsIds, spareStudents} = useMappedState(useCallback(state => {
        let teaId = (teacherId == undefined) ? currentUserId : teacherId;
        let teacherGroups = state.teacherGroups
            .teacherGroupsMap.toArray()
            .filter(g => (g.teacherId == teaId))
            .sort((a, b) => (+b.timestamp - +a.timestamp));
        let links = state.users.linksMap.toArray().filter(a => (a.teacherId == teaId));
        let allStudents = links.map(l => state.users.usersMap.get(l.studentId)).filter(a => (a != undefined));
        let group = state.teacherGroups.teacherGroupsMap.get(groupId);
        let studentsIds = (group.studentsIds == undefined) ? [] : group.studentsIds;
        let groupStudents = studentsIds.map(stId => state.users.usersMap.get(stId)).filter(a => (a != undefined));
        return {
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            loading: state.users.loading || state.teacherGroups.loading,
            currentUserId: state.users.currentUserId,
            allGroups: teacherGroups,
            group: group,
            allStudents: allStudents,
            groupStudents: groupStudents,
            studentsIds: studentsIds,
            spareStudents: allStudents.filter(u => (studentsIds.indexOf(u.id) == -1))
        }
    }, []));
    let teaId = (teacherId == undefined) ? currentUserId : teacherId;

    useEffect(() => {
        if (teaId != undefined) {
            dispatch(actions.loadAllTeacherGroupsOfTeacher(teaId))
        }
        dispatch(usersActions.loadTeacherLinks(teaId)).then(pld => {
            if (pld.links != undefined) {
                dispatch(usersActions.loadUsersByIds(pld.links.map(a => a.studentId)))
            }
        });
    }, [teaId]);
    if (group == undefined) {
        return null;
    }


    return (
        <Wrapper>

            <Left>

                <Heading>
                    Ученики группы ({groupStudents.length})
                </Heading>

                <StudentsList>
                    {groupStudents.map((u, k) => {
                        return (
                            <StudentItem key={u.id}>
                                <Name>
                                    {CommonHelper.getUserName(u)}
                                </Name>
                                <Control>
                                    <ControlImg
                                        src={require('../../../assets/images/clear.svg')}
                                        onClick={() => {
                                            dispatch(actions.toggleStudentMembership(groupId, u.id));
                                        }}
                                    />
                                </Control>
                            </StudentItem>
                        )
                    })}
                </StudentsList>

            </Left>

            <Right>

                <Heading>
                    Все ученики (вне группы) ({spareStudents.length})
                </Heading>

                <StudentsList>
                    {spareStudents.map((u, k) => {
                        return (
                            <StudentItem key={u.id}>
                                <Name>
                                    {CommonHelper.getUserName(u)}
                                </Name>
                                <Control>
                                    <ControlImg
                                        src={require('../../../assets/images/add.svg')}
                                        onClick={() => {
                                            dispatch(actions.toggleStudentMembership(groupId, u.id));
                                        }}
                                    />
                                </Control>
                            </StudentItem>
                        )
                    })}
                </StudentsList>

            </Right>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    box-sizing: border-box;
`;

const Left = styled.div`
    flex: 1;
`;

const Right = styled.div`
    flex: 1;
`;

const StudentItem = styled.div`
    background: white;
    margin-bottom: 5px;
    padding: 5px;
    border: 1px solid whitesmoke;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const StudentsList = styled.div`
    
`;

const Heading = styled.div`
    margin-bottom: 5px;
`;

const Name = styled.div`
    font-weight: bold;
`;

const Control = styled.div`
    
`;

const ControlImg = styled.img`
    width: 16px;
    height: 16px;
    opacity: 0.5;
    cursor: pointer;
    :hover{
      opacity: 1;
    }
`;
