import LessonsAPI from '../../api/LessonsAPI'

import * as types from '../ActionTypes'

let loadLessons_ = () => {
    return {
        type: types.LOAD_LESSONS
    }
}
let loadLessonsSuccess = (lessons) => {
    return {
        type: types.LOAD_LESSONS_SUCCESS,
        lessons: lessons
    }
}
let loadLessonsFailed = (error) => {
    return {
        type: types.LOAD_LESSONS_FAIL,
        error: error
    }
}

//thunk
export function loadAllLessons() {
    return (dispatch, getState) => {
        dispatch(loadLessons_());
        return LessonsAPI.getAllLessons().then(
            lessons => dispatch(loadLessonsSuccess(lessons)),
            error => dispatch(loadLessonsFailed(error))
        )
    }
}

export function loadLessonById(id) {
    return (dispatch, getState) => {
        dispatch(loadLessons_());
        return LessonsAPI.getLesson(id).then(
            lesson => dispatch(loadLessonsSuccess([lesson])),
            error => dispatch(loadLessonsFailed(error))
        )
    }
}

let createLesson_ = () => {
    return {
        type: types.CREATE_LESSON
    }
}
let createLessonSuccess = (lesson) => {
    return {
        type: types.CREATE_LESSON_SUCCESS,
        lesson: lesson
    }
}
let createLessonFailed = (error) => {
    return {
        type: types.CREATE_LESSON_FAIL,
        error: error
    }
}

//thunk
export function createLesson(data) {
    return (dispatch, getState) => {
        dispatch(createLesson_());
        return LessonsAPI.createLesson(data).then(
            lesson => dispatch(createLessonSuccess(lesson)),
            error => dispatch(createLessonFailed(error))
        )
    }
}

let updateLesson_ = () => {
    return {
        type: types.UPDATE_LESSON
    }
}
let updateLessonSuccess = (lesson) => {
    return {
        type: types.UPDATE_LESSON_SUCCESS,
        lesson: lesson
    }
}
let updateLessonFailed = (error) => {
    return {
        type: types.UPDATE_LESSON_FAIL,
        error: error
    }
}

//thunk
export function updateLesson(data) {
    return (dispatch, getState) => {
        dispatch(updateLesson_());
        return LessonsAPI.updateLesson(data).then(
            lesson => dispatch(updateLessonSuccess(lesson)),
            error => dispatch(updateLessonFailed(error))
        )
    }
}

let deleteLesson_ = () => {
    return {
        type: types.DELETE_LESSON
    }
}
let deleteLessonSuccess = (id) => {
    return {
        type: types.DELETE_LESSON_SUCCESS,
        id: id
    }
}
let deleteLessonFailed = (error) => {
    return {
        type: types.DELETE_LESSON_FAIL,
        error: error
    }
}

//thunk
export function deleteLesson(id) {
    return (dispatch, getState) => {
        dispatch(deleteLesson_());
        return LessonsAPI.deleteLesson(id).then(
            () => dispatch(deleteLessonSuccess(id)),
            error => dispatch(deleteLessonFailed(error))
        )
    }
}
