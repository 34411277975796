import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {API_ENDPOINT} from "../../constants/config";

import * as usersActions from '../../redux/actions/UsersActions'
import {useDispatch, useMappedState} from "redux-react-hook";
import TwoSectionedPanel from "../auth/tools/TwoSectionedPanel";
import StyledLoginForm from "../auth/forms/StyledLoginForm";
import RecoverPasswordForm from "../auth/forms/RecoverPasswordForm";
import StyledSignupForm from "../auth/forms/StyledSignupForm";

import axios from 'axios'

const bgImg = require('../../assets/images/bg_orange.svg');

export default function BrandedLoginApp(props) {

    const mapState = useCallback(
        state => ({
            initialized: state.users.initialized,
            loading: state.users.loading,
            currentUser: state.users.currentUserId == undefined ? null : state.users.usersMap.get(state.users.currentUserId)
        }), []
    );
    const {initialized, currentUser, loading} = useMappedState(mapState);
    const dispatch = useDispatch();
    const [mode, setMode] = useState('login');
    const [recovering, setRecovering] = useState(false);
    const [registering, setRegistering] = useState(false);

    return (
        <Wrapper>
            <Inner>

                {mode != 'login' ? null :
                    <LoginPanelPlaceholder>
                        <TwoSectionedPanel rightContent={(
                            <RightContentPlaceholder>
                                <StyledLoginForm loading={loading} onSubmit={data => {
                                    console.log('data = ', data);
                                    dispatch(usersActions.logIn(data)).then(pld => {
                                        if (pld.error != undefined) {
                                            if (pld.error.code == 101) {
                                                return window.alert('Неверный логин или пароль');
                                            }
                                        }
                                        window.location.reload();
                                    })
                                }}
                                                 onLostPasswordClick={() => {
                                                     setMode('lost_password');
                                                 }}
                                                 onSignUpClick={() => {
                                                     setMode('signup');
                                                 }}
                                />
                            </RightContentPlaceholder>
                        )}/>
                    </LoginPanelPlaceholder>
                }

                {mode != 'signup' ? null :
                    <Inner>
                        <SimpleWhitePlaceholder>
                            <StyledSignupForm
                                loading={registering}
                                onLostPasswordClick={() => {
                                    setMode('lost_password');
                                }}

                                onSignUpClick={() => {
                                    setMode('signup');
                                }}
                                onLoginClick={() => {
                                    setMode('login');
                                }}
                                onSubmit={data => {
                                    console.log('signup: data = ', data);
                                    setRegistering(true);
                                    axios.post(`${API_ENDPOINT}/users/create`, data).then(d => d.data).then(pld => {
                                        if (pld.error != undefined) {
                                            window.alert(pld.error.message);
                                            setRegistering(false);
                                            return;
                                        }
                                        setRegistering(false);
                                        dispatch(usersActions.logIn(data)).then(pld => {
                                            console.log('signing in');
                                            window.location.reload();
                                        });
                                    })
                                }}
                            />
                        </SimpleWhitePlaceholder>
                    </Inner>
                }

                {mode != 'lost_password' ? null :
                    <Inner>
                        <SimpleWhitePlaceholder>
                            <RecoverPasswordForm
                                loading={recovering}
                                onLostPasswordClick={() => {
                                    setMode('lost_password');
                                }}
                                onSignUpClick={() => {
                                    setMode('signup');
                                }}
                                onLoginClick={() => {
                                    setMode('login');
                                }}
                                onSubmit={email => {
                                    setRecovering(true);
                                    axios.post(`${API_ENDPOINT}/recover`, {email: email}).then(d => d.data).then(pld => {
                                        console.log('pld = ', pld);
                                        if (pld.error != undefined) {
                                            setRecovering(false);
                                            window.alert(pld.error.message);
                                            return;
                                        }
                                        window.alert('Ссылка восстановления пароля отправлена на ваш адрес');
                                        setRecovering(false);
                                    })
                                }}
                            />
                        </SimpleWhitePlaceholder>
                    </Inner>
                }

            </Inner>
        </Wrapper>
    );
}


const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 1;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: #E5E5E5;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${bgImg});
`;

const RightContentPlaceholder = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 15px;
`;

const LoginPanelPlaceholder = styled.div`
    height: 580px;
    width: 934px;
    border-radius: 15px;
    box-shadow: 0px 1.09726px 2.19451px rgba(0, 0, 0, 0.15);
    @media(max-width: 934px){
      width: 100%;
    }
    @media(max-height: 580px){
      height: 100%;
    }
`;


const Inner = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const LoginFormPlaceholder = styled.div`
    text-align: center;
    background: white;
    border-radius: 4px;
`;

const Heading = styled.div`
    font-size: 24px;
    line-height: 28px;
    font-weight: bold;
    padding: 20px;
    padding-bottom: 0px;
`;

const centerWidth = 320;

const CenterPlaceholder = styled.div`
    width: ${centerWidth}px;
    @media(max-width: ${centerWidth}px){
      width: 100%;
    }
`;

const FormPlaceholder = styled.div`
    text-align: left;
`;

const SimpleWhitePlaceholder = styled.div`
    background: white;
    border-radius: 15px;
    box-shadow: 0px 1.09726px 2.19451px rgba(0, 0, 0, 0.15);
`;
