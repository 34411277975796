import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'
import {API_ENDPOINT, WEB_APP_PUBLIC_URL} from "../../../constants/config";
import ChessHelper from "../../../helpers/ChessHelper";
import UpdateGameForm from "../forms/UpdateGameForm";
import GamesAPI from "../../../api/GamesAPI";
import CommonHelper from "../../../helpers/CommonHelper";
import RealtimeBoardImage from "../../realtime/board/RealtimeBoardImage";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ClipToolSpan from "../../tools/ClipToolSpan";
import {translate} from "../../../utils/Translate";

export default function TournamentGamesPanel(props) {
    let {tournamentId, currentUserId = undefined, selectedGameId = undefined, canDelete = false} = props;
    const [games, setGames] = useState([]);
    const [addModalVisible, setAddModalVisible] = useState(false);
    const [mode, setMode] = useState('settings');
    const [loading, setLoading] = useState(true);
    const [selectedId, setSelectedId] = useState(undefined);
    useEffect(() => {
        axios.get(`${API_ENDPOINT}/tournament/${tournamentId}/games`).then(d => d.data).then(gms => {
            setGames(gms);
            setLoading(false);
        })
    }, [tournamentId]);

    if (games.length == 0 && loading == true) {
        return (
            <Code/>
        )
    }
    let selectedFromNavigationGame = (selectedGameId == undefined) ? undefined : games.filter(a => (a.id == selectedGameId))[0];
    let selGame = (selectedId == undefined) ? undefined : games.filter(a => (a.id == selectedId))[0];
    let opponentId = (selGame == undefined) ? undefined : (selGame.whiteUserId == currentUserId ? selGame.blackUserId : selGame.whiteUserId);
    let myColor = (selectedFromNavigationGame == undefined) ? undefined : (selectedFromNavigationGame.whiteUserId == currentUserId ? 'w' : 'b')

    return (
        <Wrapper>

            {(loading == true || games.length > 0) ? null :
                <NoGamesPlaceholder>
                    В данном сеансе еще нет партий.
                    <br/>
                    Добавьте первую партию!
                </NoGamesPlaceholder>
            }

            <GamesList>
                {games.map((gm, k) => {
                    let isSelected = (selectedGameId == gm.id);

                    return (
                        <GameItem selected={isSelected} key={`game_${gm.id}_${k}`}>
                            <FenImgPlaceholder onClick={() => {
                                CommonHelper.linkTo(`/tournament/${tournamentId}/game/${gm.id}`);
                            }}>
                                <RealtimeBoardImage
                                    startingFen={gm.startingFen}
                                    myColor={myColor}
                                    loading={loading}
                                    boardId={`tournament_${gm.tournamentId}_game_${gm.id}`}/>
                            </FenImgPlaceholder>
                            <GameName selected={isSelected} onClick={() => {
                                CommonHelper.linkTo(`/tournament/${tournamentId}/game/${gm.id}`);
                            }}>
                                {gm.name}
                            </GameName>
                            <SettingsPlaceholder>
                                <SettingsImage
                                    src={require('../../../assets/images/share.svg')}
                                    onClick={() => {
                                        setSelectedId(gm.id);
                                        setMode('share');
                                    }}
                                    style={{marginRight: 10}}
                                />

                                <SettingsImage
                                    src={require('../../../assets/images/settings_a.svg')}
                                    onClick={() => {
                                        setSelectedId(gm.id);
                                        setMode('settings');
                                    }}
                                />
                            </SettingsPlaceholder>
                        </GameItem>
                    )
                })}
            </GamesList>

            <BottomPlaceholder>
                <AddButtonPlaceholder onClick={() => {
                    setAddModalVisible(true);
                }}>
                    <BackArrowImage style={{marginRight: 10}}
                                    src={require('../../../assets/images/add.svg')}
                    />
                    <span>
                        Добавить доску
                    </span>
                </AddButtonPlaceholder>
            </BottomPlaceholder>

            <Sidebar width={Math.min(720, window.innerWidth)} visible={addModalVisible} onCloserClick={() => {
                setAddModalVisible(false);
            }}>
                {addModalVisible == false ? null :
                    <div>
                        <UpdateGameForm
                            loading={loading}
                            currentUserId={currentUserId}
                            buttonName={'Создать игру!'}
                            onSave={async d => {
                                let data = {
                                    ...d,
                                    creatorId: currentUserId,
                                    tournamentId: tournamentId
                                };
                                setLoading(true);
                                let newGame = await GamesAPI.createGame(data);
                                console.log('new game hase been created!');
                                setGames(games.concat([newGame]));
                                setLoading(false);
                                setAddModalVisible(false);
                                CommonHelper.linkTo(`/tournament/${tournamentId}/game/${newGame.id}`)
                            }}/>
                    </div>
                }

            </Sidebar>

            <Sidebar width={Math.min(820, window.innerWidth)} visible={(selGame != undefined)} onCloserClick={() => {
                setSelectedId(undefined);
            }}>
                {selGame == undefined ? null :
                    <div>
                        {mode != 'settings' ? null :
                            <UpdateGameForm
                                id={selGame.id}
                                loading={loading}
                                opponentId={(currentUserId == selGame.whiteUserId) ? selGame.blackUserId : selGame.whiteUserId}
                                currentUserId={currentUserId}
                                buttonName={translate('save')}
                                canDelete={canDelete}
                                {...selGame}
                                onSave={async d => {
                                    let data = {
                                        ...d,
                                        id: selGame.id
                                    };
                                    setLoading(true);
                                    await GamesAPI.updateGame(data);
                                    let newGames = (await axios.get(`${API_ENDPOINT}/tournament/${tournamentId}/games`)).data;
                                    console.log('new game has been created!');
                                    setGames(newGames);
                                    setLoading(false);
                                    setSelectedId(undefined);
                                }}
                                onDelete={async () => {
                                    setLoading(true);
                                    await axios.post(`${API_ENDPOINT}/games/delete/${selectedGameId}`);
                                    let newGames = (await axios.get(`${API_ENDPOINT}/tournament/${tournamentId}/games`)).data;
                                    setGames(newGames);
                                    CommonHelper.linkTo(`/tournament/${tournamentId}`);
                                    setLoading(false);
                                    setSelectedId(undefined);
                                }}
                            />
                        }
                        {mode != 'share' ? null :
                            <div>
                                <h3>
                                    Пригласите друга
                                </h3>
                                <p>
                                    Отправьте другу ссылку
                                </p>
                                <p>
                                    <span style={{textDecoration: 'underline'}}>
                                        {`${WEB_APP_PUBLIC_URL}/#/tournament/${tournamentId}/game/${selGame.id}/user/${opponentId}`}
                                    </span>
                                </p>
                                <p>
                                    <ClipToolSpan
                                        text={`${WEB_APP_PUBLIC_URL}/#/tournament/${tournamentId}/game/${selGame.id}/user/${opponentId}`}>
                                        <span>
                                            скопировать в буфер обмена
                                        </span>
                                    </ClipToolSpan>
                                </p>
                            </div>
                        }

                    </div>
                }

            </Sidebar>

        </Wrapper>
    );
}

const NoGamesPlaceholder = styled.div`
    text-align: center;
    padding: 15px;
`;

const Wrapper = styled.div`
    
`;

const GamesList = styled.div`
    background: white;
    border-left: 1px solid whitesmoke;
    box-sizing: border-box;
    padding: 5px;
`;


const GameItem = styled.div`
    border-radius: 4px;
    border: 1px solid whitesmoke;
    padding: 5px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    position: relative;
    background: ${props => (props.selected == true ? '#F0D8B5' : 'white')};
    color: ${props => (props.selected == true ? 'white' : 'black')};
    :hover{
      opacity: 0.8;
    }
`;

const SettingsImage = styled.img`
    display: inline-block;
    width: 18px;
    height: 18px;
    opacity: 0.5;
    :hover{
      opacity: 1;
    }
`;

const SettingsPlaceholder = styled.div`
    position: absolute;
    bottom: 5px;
    right: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const FenImg = styled.img`
    width: 100px;
    margin-right: 5px;
`;

const FenImgPlaceholder = styled.div`
    width: 100px;
    height: 100px;
    min-width: 100px;
    margin-right: 5px;
`;

const GameName = styled.div`
    font-weight: ${props => (props.selected == true ? 'bold' : 'normal')};
`;

const BottomPlaceholder = styled.div`
    text-align: center;
`;

const AddButtonPlaceholder = styled.div`
    font-size: 16px;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0.5;
    :hover{
      opacity: 0.85;
    }
`;

const BackArrowImage = styled.img`
    height: 18px;
    cursor: pointer;
    opacity: 0.5;
    :hover{
      opacity: 1;
    }
`;

const ClipboardSpan = styled.span`
    cursor: pointer;
    opacity: 0.5;
    text-decoration: underline;
    :hover{
      opacity: 1;
    }
`;
