import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const ExFoldersAPI = {

    async createExFolder(data) {
        let pld = (await axios.post(`${API_ENDPOINT}/ex_folders`, data)).data;
        return pld.result;
    },

    async getExFolder(id) {
        let pld = (await axios.get(`${API_ENDPOINT}/ex_folders/${id}`)).data;
        return pld.result;
    },

    async getAllExFolders() {
        let pld = (await axios.get(`${API_ENDPOINT}/ex_folders/all`)).data;
        return pld.result;
    },

    async getUserExFolder(userId) {
        let options = (userId == undefined) ? undefined : {params: {userId: userId}}
        let pld = (await axios.get(`${API_ENDPOINT}/ex_folders/all`, options)).data;
        return pld.result;
    },

    async updateExFolder(data) {
        let pld = (await axios.put(`${API_ENDPOINT}/ex_folders`, data)).data;
        return pld.result;
    },

    async deleteExFolder(id) {
        let pld = (await axios.delete(`${API_ENDPOINT}/ex_folders/${id}`)).data;
        return pld.result;
    }


}

export default ExFoldersAPI;
