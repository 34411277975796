import FinalExerciseDialogView from '../aliens/FinalExerciseDialogView';
import React from 'react';
import Modal from 'simple-react-modal';

export default function FinishWindow ({visible, hide, data}) {
    const styles = {
        width: '760px',
        position: 'relative',
        margin: '10% auto',
        padding: '10px',
        background: 'rgb(255, 255, 255)'
    };

    const notEmpty = (data && (data.finalImageUrl || data.finalText || data.finalVideoUrl || data.finalAudioUrl || data.finalHtml));

    return (
        notEmpty ?
            <Modal show={visible} onClose={hide} containerStyle={styles}>
                <FinalExerciseDialogView
                    imageUrl={data.finalImageUrl}
                    text={data.finalText}
                    videoUrl={data.finalVideoUrl}
                    audioUrl={data.finalAudioUrl}
                    html={data.finalHtml}
                />
            </Modal> : null
    );
}