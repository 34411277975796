import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import MySidebar from './MySidebar'

import {useDispatch, useMappedState} from 'redux-react-hook';
import * as usersActions from '../../redux/actions/UsersActions'

import FullPagePreloader from '../preloader/FullPagePreloader'
import CommonHelper from "../../helpers/CommonHelper";

import {Container} from "../ui/Container";
import {Button} from "../ui/Button";

import Sidebar from 'arui-feather/sidebar'

import UpdateUserInfoForm from '../users/forms/UpdateUserInfoForm'
import MyTopLinksBar from "./MyTopLinksBar";
import {FACELESS_AVATAR} from "../../constants/config";
import JivoAPI from "../../api/JivoAPI";
import UserBalancePanel from "../balance/panels/UserBalancePanel";
import TeacherLessonsDraggableBoardWidgetPanel from "../realtime/panels/TeacherLessonsDraggableBoardWidgetPanel";

export default function HorChessTemplate(props) {
    let {children, active} = props;
    const dispatch = useDispatch();
    let {currentUser, loading} = useMappedState(useCallback(state => ({
        currentUser: state.users.usersMap.get(state.users.currentUserId),
        loading: state.users.loading
    }), []));
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [draggableBoardVisible, setDraggableBoardVisible] = useState(false);

    if (currentUser == undefined) {
        return null;
    }
    const {email, firstName, lastName, avatar, name, userRole} = currentUser;
    const ava = (avatar == undefined) ? FACELESS_AVATAR : avatar;


    return (
        <Wrapper>

            <Header>
                <VeryTopPlaceholder>
                    <VeryTopInnerPlaceholder>
                        <LogoPlaceholder>
                            {/*<LogoImage src={require('../../assets/images/round_logo_shagi.png')}/>*/}
                            <LogoImage src={require('../../assets/images/shagi_logo_wide_500_centered.png')}/>
                            {/*<LogoText>*/}
                            {/*ШАГИ*/}
                            {/*</LogoText>*/}
                        </LogoPlaceholder>

                        <CurrentUserPlaceholder>

                            <BoardSpan onClick={() => {

                            }}>
                                <LogoutImg src={require('../../assets/images/chess_b.svg')}/>
                                <LogoutText>
                                    доска
                                </LogoutText>
                                <BoardDropdown>
                                    <DropItem onClick={() => {
                                        setDraggableBoardVisible(true);
                                    }}>
                                        доска урока
                                    </DropItem>
                                    <DropItem onClick={() => {
                                        let url = window.location.origin + window.location.pathname + `#/shared/board-u-${currentUser.id}/board`;
                                        openInNewTab(url)
                                    }}>
                                        демо-доска
                                    </DropItem>
                                </BoardDropdown>
                            </BoardSpan>

                            <CallSpan onClick={() => {
                                JivoAPI.toggleHelp();
                            }}>
                                <LogoutImg src={require('../../assets/images/help.svg')}/>
                                <LogoutText>
                                    помощь
                                </LogoutText>
                            </CallSpan>

                            <CallSpan onClick={() => {

                            }}>
                                <LogoutImg src={require('../../assets/images/money.svg')}/>
                                <LogoutText>
                                    <UserBalancePanel/>
                                </LogoutText>
                            </CallSpan>

                            <CallSpan onClick={() => {
                                CommonHelper.linkTo(`/conf/${currentUser.id}`);
                            }}>
                                <LogoutImg src={require('../../assets/images/video-call.svg')}/>
                                <LogoutText>
                                    звонок
                                </LogoutText>
                            </CallSpan>

                            <LogoutPanel onClick={() => {
                                dispatch(usersActions.logOut()).then(pld => {
                                    if (pld.error == undefined) {
                                        CommonHelper.linkTo('/');
                                        setTimeout(() => {
                                            window.location.reload();
                                        }, 100);
                                    }
                                });
                            }}>
                                <LogoutImg src={require('../../assets/images/logout.svg')}/>
                                <LogoutText>
                                    выйти
                                </LogoutText>
                            </LogoutPanel>

                            <Ava src={ava} onClick={() => {
                                setSidebarVisible(true);
                            }}/>
                            <CurrentUserText onClick={() => {
                                setSidebarVisible(true);
                            }}>
                                {CommonHelper.getUserName(currentUser)}
                            </CurrentUserText>
                        </CurrentUserPlaceholder>

                    </VeryTopInnerPlaceholder>
                </VeryTopPlaceholder>
                <LinksPlaceholder>
                    <LinksInnerPlaceholder>
                        <MyTopLinksBar active={active} currentUser={currentUser} loading={loading}/>
                    </LinksInnerPlaceholder>
                </LinksPlaceholder>
            </Header>

            <MainContent>
                <HorContainer>
                    {draggableBoardVisible == false ? null :
                        <TeacherLessonsDraggableBoardWidgetPanel onClose={() => {
                            setDraggableBoardVisible(false);
                        }} />
                    }
                    {children}
                </HorContainer>
            </MainContent>

            <FullPagePreloader visible={loading}/>

            <Sidebar width={Math.min(820, window.innerWidth)} visible={sidebarVisible} onCloserClick={() => {
                setSidebarVisible(false);
            }}>

                {sidebarVisible == false ? null :
                    <div>

                        <Heading>
                            Редактирование профиля
                        </Heading>

                        <UpdateUserInfoForm loading={loading} {...currentUser} onSave={data => {
                            let d = {
                                id: currentUser.id,
                                ...data
                            };
                            dispatch(usersActions.updateUser(d)).then(pld => {
                                setSidebarVisible(false);
                            });
                        }}

                        />
                    </div>
                }

            </Sidebar>



        </Wrapper>
    );
}

const mainBreakWidth = 1080;

const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100vw;
    overflow: hidden;
    background: #F6F5F3;
`;

const Header = styled.div`
    background: white;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 0px;
`;

const linksHeight = 40;

const LinksPlaceholder = styled.div`
    height: ${linksHeight}px;
`;

const LinksInnerPlaceholder = styled.div`
    width: ${mainBreakWidth}px;
    margin: 0 auto;
    @media(max-width: ${mainBreakWidth}px){
      width: 100%;
    }
`;

const veryTopHeight = 50;

const VeryTopPlaceholder = styled.div`
    height: ${veryTopHeight}px;
    background: white;
    width: 100%;
    box-sizing: border-box;
`;

const VeryTopInnerPlaceholder = styled.div`
    margin: 0 auto;
    width: ${mainBreakWidth}px;
    display: flex;
    justify-content: space-between;
    align-self: center;
    @media(max-width: ${mainBreakWidth}px){
      width: 100%;
    }
`;

const LogoPlaceholder = styled.div`
    height: ${veryTopHeight}px;
    line-height: ${veryTopHeight}px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const LogoImage = styled.img`
    display: inline-block;
    vertical-align: top;
    height: ${veryTopHeight * 0.9}px;
    margin-right: 10px;
`;

const LogoText = styled.div`
    display: inline-block;
    vertical-align: top;
    opacity: 0.9;
    font-size: 20px;
    line-height: ${veryTopHeight}px;
    height: ${veryTopHeight}px;
`;

const CurrentUserPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-self: center;
`;

const avaSize = veryTopHeight * 0.8;

const Ava = styled.div`
    background-image: url(${props => props.src});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: ${avaSize}px;
    height: ${avaSize}px;
    border-radius: 1000px;
    border: 2px solid whitesmoke;
    cursor: pointer;
`;

const CurrentUserText = styled.div`
    display: flex;
    align-items: center;
    padding-left: 10px;
    font-size: 20px;
    cursor: pointer;
`;

const MainContent = styled.div`
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    box-sizing: border-box;
    min-height: calc(100vh - ${veryTopHeight + linksHeight}px);
`;

const LogoutPanel = styled.div`
    display: flex;
    margin-right: 40px;
    flex-direction: row;
    opacity: 0.3;
    cursor: pointer;
    align-items: center;
    justify-content: flex-start;
    :hover{
      opacity: 1;
    }
`;

const LogoutImg = styled.img`
    width: ${avaSize * 0.5}px;
    height: ${avaSize * 0.5}px;
    margin-right: 10px;
`;

const LogoutText = styled.div`
    font-size: 12px;
    :hover{
      opacity: 1;
    }
`;

const Heading = styled.div`
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;
`;

const HorContainer = styled.div`
    width: ${mainBreakWidth}px;
    margin: 0 auto;
    @media(max-width: ${mainBreakWidth}px){
      width: 100%;
    }
`;

const CallSpan = styled.span`
    cursor: pointer;  
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 20px;
    opacity: 0.3;
    :hover{
      opacity: 1;
    }
`;

const BoardSpan = styled(CallSpan)`
    opacity: 0.3;
    position: relative;
    :hover{
      opacity: 1;
    }
`;

const BoardDropdown = styled.div`
    position: absolute;
    top: 0px;
    background: white;
    left: 0px;
    border: 1px solid whitesmoke;
    border-radius: 3px;
    display: none;
    opacity: 1;
    z-index: 100;
    ${BoardSpan}:hover & {
      display: block;
    }
    :hover{
      display: block;
    }
`;

const DropItem = styled.div`
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid whitesmoke;
    cursor: pointer;
    padding: 5px;
    min-width: 100px;
    :hover{
      text-decoration: underline;
    }
`;

function openInNewTab(url) {
    try {
        let win = window.open(url, '_blank');
        win.focus();
    } catch (exc) {

    }
}
