import firebase from 'firebase'

import {FIREBASE_CREDENTIALS, STARTING_FEN} from "../constants/config";

const FirebaseAPI = {

    initFirebase() {
        if (!firebase.apps.length) {
            firebase.initializeApp(FIREBASE_CREDENTIALS);
        }
    },

    getRoom(roomName) {
        return new Promise((resolve, reject) => {
            let ref = firebase.database().ref(`/Board/${roomName}`).once('value').then((snapshot) => {
                // console.log('snapshot = ', snapshot);
                // console.log('Board: room snapshot = ', snapshot.val());
                resolve(snapshot.val());
            });
        })
    },

    getLazyRoom(roomName, startingFen = STARTING_FEN) {
        console.log('getLazyRoom: roomName, startingFen = ', roomName, startingFen);
        return new Promise((resolve, reject) => {
            // firebase.database().ref(`/Board`).child(roomName).transaction(currentImageData => {
            firebase.database().ref(`/Board`).child(roomName).once('value').then(currentImageData => {
                // firebase.database().ref(`/Board/${roomName}`).once('value').then(currentImageData => {
                // firebase.database().ref(`/Board/${roomName}`).transaction(currentImageData => {
                console.log('currentImageData = ', currentImageData);
                // return currentImageData;
                if (currentImageData === null) {
                    resolve({
                        boardData: {
                            lastUpdateTimestamp: +new Date(),
                            history: [],
                            startingFen: startingFen,
                            selectedUserId: 'nouser'
                        }
                    })
                    return {
                        boardData: {
                            lastUpdateTimestamp: +new Date(),
                            history: [],
                            startingFen: startingFen,
                            selectedUserId: 'nouser'
                        }
                    }
                }
                else {
                    // return currentImageData;
                    resolve(currentImageData.val());
                }
            }, (err, commited) => {
                console.log('transaction callback: err, commited = ', err, commited);
                if (err != undefined) {
                    // return reject(err)
                    return resolve();
                }
                resolve();
            })
        })
    },


    subscribeOnChanges(roomName, onChangeCallback, startingFen = STARTING_FEN) {
        console.log('!!! subscribeOnChanges occured: roomName, startingFen = ', roomName, startingFen);
        return new Promise((resolve, reject) => {
            this.getLazyRoom(roomName, startingFen).then(pld => {
                console.log('getLazyRoom pld = ', pld);
                let starCountRef = firebase.database().ref('/Board/' + roomName);
                starCountRef.on('value', function (snapshot) {
                    console.log('onUpdate: snapshot.val() = ', snapshot.val());
                    let v = snapshot.val();
                    console.log('v = ', v);
                    onChangeCallback(v);
                });
                resolve({
                    data: pld,
                    unsubscribeFunction: () => {

                    }
                });
            }).catch(exc => {
                console.log('CAUGHT error err = ', exc);
                reject(exc);
            });
        })
    },

    updateBoardFireState(roomName, newBoardData) {
        console.log('FirebaseAPI: updateBoardFireState: roomName, newBoardData = ', roomName, newBoardData);
        return new Promise((resolve, reject) => {
            let starCountRef = firebase.database().ref('/Board/' + roomName);
            // let newRoomState = {
            //     once: newBoardData
            // };
            // starCountRef.set(newRoomState);
            starCountRef.set(newBoardData);
            resolve(newBoardData);
        })
    },


    // simple board section
    getLazySimpleBoard(roomId, data) {
        console.log('getLazySimpleBoard: roomId, data = ', roomId, data);
        let defaultData = {
            ...data,
            lastUpdateTimestamp: +new Date(),
            selectedUserId: (data.selectedUserId == undefined) ? 'nouser' : data.selectedUserId,
            orientation: (data.orientation == undefined) ? 'white' : data.orientation
        };
        return new Promise((resolve, reject) => {
            let ref = firebase.database().ref(`/SimpleBoard/${roomId}`);
            ref.once('value').then(snapshot => {
                if (snapshot.exists()) {
                    let value = snapshot.val();
                    console.log('exists! value = ', value);
                    return resolve(value);
                }
                firebase.database().ref(`/SimpleBoard/${roomId}`).set(defaultData).then(pld => {
                    resolve(defaultData);
                });
            });
        });
    },

    subscribeOnSimpleBoardChanges(roomId, onChangeCallback = () => {
    }, defaultD = {}) {
        return new Promise((resolve, reject) => {
            this.getLazySimpleBoard(roomId, defaultD).then(pld => {
                firebase.database().ref(`/SimpleBoard/${roomId}`).on('value', (snapshot) => {
                    let v = snapshot.val();
                    onChangeCallback(v);
                });
                resolve({
                    data: pld,
                    unsubscribeFunction: () => {

                    }
                })
            });
        });
    },

    updateSimpleBoardData(roomId, newData) {
        console.log('updateSimpleBoardData: roomId, newData = ', roomId, newData);
        return new Promise((resolve, reject) => {
            firebase.database().ref(`/SimpleBoard/${roomId}`).set({
                ...newData,
                selectedUserId: (newData.selectedUserId == undefined) ? 'nouser' : newData.selectedUserId,
                orientation: (newData.orientation == undefined) ? 'white' : newData.orientation,
                lastUpdateTimestamp: +new Date()
            }).then(() => {
                resolve()
            }).catch(e => {
                reject(e);
            })
        });
    },

    sendFenToSimpleBoard(roomId, fen, selectedUserId, orientation, isSynthetic = false) {
        console.log('sendFenToSimpleBoard: roomId, fen = ', roomId, fen);
        let d = {
            fen: fen,
            selectedUserId: (selectedUserId == undefined) ? 'nouser' : selectedUserId,
            orientation: (orientation == undefined) ? 'white' : orientation,
            synthetic: isSynthetic
        }
        return new Promise((resolve, reject) => {
            this.getLazySimpleBoard(roomId, d).then(pld => {
                this.updateSimpleBoardData(roomId, d).then(() => {
                    resolve();
                });
            });
        });
    },


    // end of simple board section


    getLazyRoomSettings(roomId, data) {
        console.log('getLazyRoomSettings: roomId, data = ', roomId, data);
        let defaultData = {
            ...data,
            lastUpdateTimestamp: +new Date()
        };
        return new Promise((resolve, reject) => {
            let ref = firebase.database().ref(`/RoomSettings/${roomId}`);
            ref.once('value').then(snapshot => {
                if (snapshot.exists()) {
                    let value = snapshot.val();
                    console.log('exists! value = ', value);
                    return resolve(value);
                }
                firebase.database().ref(`/RoomSettings/${roomId}`).set(defaultData).then(pld => {
                    resolve(defaultData);
                });
            });
        });
    },

    subscribeOnRoomSettingsChanges(roomId, onChangeCallback = () => {
    }, defaultD = {}) {
        return new Promise((resolve, reject) => {
            this.getLazyRoomSettings(roomId, defaultD).then(pld => {
                firebase.database().ref(`/RoomSettings/${roomId}`).on('value', (snapshot) => {
                    let v = snapshot.val();
                    onChangeCallback(v);
                });
                resolve({
                    data: pld,
                    unsubscribeFunction: () => {

                    }
                })
            });
        });
    },

    updateRoomSettingsData(roomId, newData) {
        console.log('updateRoomSettingsData: roomId, newData = ', roomId, newData);
        return new Promise((resolve, reject) => {
            firebase.database().ref(`/RoomSettings/${roomId}`).set({
                ...newData,
                lastUpdateTimestamp: +new Date()
            }).then(() => {
                resolve()
            }).catch(e => {
                reject(e);
            })
        });
    },

    // chat room

    getLazyChatRoom(roomId, data) {
        console.log('getLazyChatRoom: roomId, data = ', roomId, data);
        let defaultData = {
            ...data,
            lastUpdateTimestamp: +new Date()
        };
        return new Promise((resolve, reject) => {
            let ref = firebase.database().ref(`/ChatRoom/${roomId}`);
            ref.once('value').then(snapshot => {
                if (snapshot.exists()) {
                    let value = snapshot.val();
                    console.log('exists! value = ', value);
                    return resolve(value);
                }
                firebase.database().ref(`/ChatRoom/${roomId}`).set(defaultData).then(pld => {
                    resolve(defaultData);
                });
            });
        });
    },

    subscribeOnRoomChat(roomId, onChangeCallback = () => {
    }, defaultD = {}) {
        return new Promise((resolve, reject) => {
            this.getLazyRoomSettings(roomId, defaultD).then(pld => {
                firebase.database().ref(`/ChatRoom/${roomId}`).on('value', (snapshot) => {
                    let v = snapshot.val();
                    onChangeCallback(v);
                });
                resolve({
                    data: pld,
                    unsubscribeFunction: () => {

                    }
                })
            });
        });
    },

    updateRoomChatData(roomId, newData) {
        console.log('updateRoomChatData: roomId, newData = ', roomId, newData);
        return new Promise((resolve, reject) => {
            firebase.database().ref(`/ChatRoom/${roomId}`).set({
                ...newData,
                lastUpdateTimestamp: +new Date()
            }).then(() => {
                resolve()
            }).catch(e => {
                reject(e);
            })
        });
    },

}

export default FirebaseAPI;
