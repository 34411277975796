import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';

import {OpenVidu} from 'openvidu-browser';

import UserVideoBox from "../tools/UserVideoBox";
import useComponentSize from "@rehooks/component-size/index";
import OpenviduAPI from "../../../api/OpenviduAPI";
import OpenviduHelper from "../../../helpers/OpenviduHelper";
import {OPENVIDU_ICE_SERVERS} from "../../../constants/config";
import BoxesLayoutTool from "../tools/BoxesLayoutTool";
import FirebaseAPI from "../../../api/FirebaseAPI";
import ls from "local-storage";

const getUserIdBySub = sub => {
    let aa = OpenviduHelper.getClientDataBySub(sub);
    if (aa != undefined && aa.user != undefined) {
        return aa.user.id;
    }
    return undefined;
}

const toggleControl = async (curRef, user, type = 'video', roomName) => {
    console.log('toggleControl: user = ', user);
    let mutedUsersIds = (curRef == undefined || curRef.mutedUsersIds == undefined) ? [] : curRef.mutedUsersIds;
    let noVideoUsersIds = (curRef == undefined || curRef.noVideoUsersIds == undefined) ? [] : curRef.noVideoUsersIds;
    let dd = curRef || {noVideoUsersIds: [], mutedUsersIds: []};
    if (type == 'video') {
        let updV = dd.noVideoUsersIds;
        if (noVideoUsersIds.indexOf(user.id) == -1) {
            updV = noVideoUsersIds.concat([user.id]);
        } else {
            updV = noVideoUsersIds.filter(a => (a != user.id));
        }
        await FirebaseAPI.updateRoomSettingsData(roomName, {
            ...dd,
            noVideoUsersIds: updV
        });
    } else {
        let updV = dd.mutedUsersIds;
        if (mutedUsersIds.indexOf(user.id) == -1) {
            updV = mutedUsersIds.concat([user.id]);
        } else {
            updV = mutedUsersIds.filter(a => (a != user.id));
        }
        await FirebaseAPI.updateRoomSettingsData(roomName, {
            ...dd,
            mutedUsersIds: updV
        });
    }
};

const videoEnabled = (chData, user) => {
    if (chData == undefined || user == undefined) {
        return true;
    }
    const usersIds = (chData.noVideoUsersIds == undefined) ? [] : chData.noVideoUsersIds;
    return (usersIds.indexOf(user.id) == -1);
}

const audioEnabled = (chData, user) => {
    if (chData == undefined || user == undefined) {
        return true;
    }
    const usersIds = (chData.mutedUsersIds == undefined) ? [] : chData.mutedUsersIds;
    return (usersIds.indexOf(user.id) == -1);
}

const smartConnect = (roomName, user, ovRef, sessionRef,
                      mainStreamRef, publisherRef, subscribersRef,
                      setUpdT = () => {
                      },
                      onProblem = () => {

                      },
                      onSuccess = () => {

                      }) => {
    console.log('smartConnect occured!');
    ovRef.current = new OpenVidu();
    try {
        ovRef.current.setAdvancedConfiguration({
            iceServers: OPENVIDU_ICE_SERVERS,
            forceMediaReconnectionAfterNetworkDrop: true
        })
    } catch (e) {

    }
    sessionRef.current = ovRef.current.initSession();
    console.log('INIT! sessionRef.current = ', sessionRef.current);
    sessionRef.current.on('streamCreated', (event) => {
        console.log('streamCreated! event = ', event);
        let subscriber = sessionRef.current.subscribe(event.stream, undefined);
        subscribersRef.current = subscribersRef.current
            .filter(a => (getUserIdBySub(a) != getUserIdBySub(event)))
            .concat(subscriber);
        setUpdT(+new Date());
        onSuccess(); // stream created
    });

    sessionRef.current.on('streamDestroyed', (event) => {
        console.log('streamDestroyed! event = ', event);
        let oldSubscribers = subscribersRef.current;
        subscribersRef.current = oldSubscribers.filter(a => (a.stream.streamId != event.stream.streamId));
        setUpdT(+new Date());
    });

    sessionRef.current.on('sessionDisconnected', (event) => {
        console.log('!!!!!_____>>>>> sessionDisconnected: event = ', event);
        if (event.reason === 'networkDisconnect') {
            console.warn('Dang-it... You lost your connection to the session');
            try {
                onProblem();
                // smartConnect(roomName, user, ovRef, sessionRef, mainStreamRef, publisherRef, subscribersRef, setUpdT);
            } catch (excep) {

            }
        } else {
            // Disconnected from the session for other reason than a network drop
        }
    });

    sessionRef.current.on('reconnecting', () => console.warn('Oops! Trying to reconnect to the session'));
    sessionRef.current.on('reconnected', () => console.log('Hurray! You successfully reconnected to the session'));

    sessionRef.current.on('connectionDisconnected', (event) => {
        console.log('connectionDisconnected occured!');
        // onProblem();
    });

    OpenviduAPI.getToken(roomName).then((token) => {
        console.log('--->>> GOT TOKEN = token = ', token);
        sessionRef.current.connect(
            token,
            {clientData: {user: user}}, // my user name
        )
            .then(() => {
                let publisher = ovRef.current.initPublisher(undefined, {
                    audioSource: iOS() ? undefined : ls('sh_cam_mic_id'), // The source of audio. If undefined default microphone
                    videoSource: iOS() ? undefined : ls('sh_cam_video_id'), // The source of video. If undefined default webcam
                    publishAudio: iOS() ? true : (ls('sh_cam_mic_id') != undefined), // Whether you want to start publishing with your audio unmuted or not
                    publishVideo: iOS() ? true : (ls('sh_cam_video_id') != undefined), // Whether you want to start publishing with your video enabled or not
                    resolution: '320x240', // The resolution of your video
                    frameRate: 30, // The frame rate of your video
                    insertMode: 'APPEND', // How the video is inserted in the target element 'video-container'
                    mirror: false, // Whether to mirror your local video or not
                });
                sessionRef.current.publish(publisher);
                mainStreamRef.current = publisher;
                publisherRef.current = publisher;
                setUpdT(+new Date());
            })
            .catch((error) => {
                console.log('There was an error connecting to the session:', error.code, error.message);
            });
    });
}

export default function OpenviduRoomPanel(props) {
    const {
        roomName = 'sabir',
        user = {},
        isTeacher = true,
        onSuccess = () => {

        },
        onProblem = () => {

        }
    } = props;
    const ovRef = useRef(undefined);
    const wrapperRef = useRef(undefined);
    const sessionRef = useRef(undefined);
    const subscribersRef = useRef([]);
    const [subscribers, setSubscribers] = useState([]);
    const mainStreamRef = useRef();
    const currSettingsRef = useRef(undefined);
    const publisherRef = useRef();
    const [updT, setUpdT] = useState(0);
    const [settingsUpdT, setSettingsUpdT] = useState(0);
    let wrapperSize = useComponentSize(wrapperRef);
    const [selectedBoxNumber, setSelectedBoxNumber] = useState(undefined);
    const subBoxSize = {
        width: wrapperSize.width * 1.0,
        height: wrapperSize.width * 0.75,
    };
    let mutedUsersIds = (currSettingsRef.current == undefined || currSettingsRef.current.mutedUsersIds == undefined) ? [] : currSettingsRef.current.mutedUsersIds;
    let noVideoUsersIds = (currSettingsRef.current == undefined || currSettingsRef.current.noVideoUsersIds == undefined) ? [] : currSettingsRef.current.noVideoUsersIds;
    let meIsMuted = (mutedUsersIds.indexOf(user.id) > -1);
    let meHasNoVideo = (noVideoUsersIds.indexOf(user.id) > -1);

    useEffect(() => {

        smartConnect(roomName, user, ovRef, sessionRef,
            mainStreamRef, publisherRef, subscribersRef,
            setUpdT,
            () => {
                console.log('problem!');
                onProblem();
            },
            () => {
                console.log('success!');
                onSuccess();
            }
        );

        // ovRef.current = new OpenVidu();
        // try {
        //     ovRef.current.setAdvancedConfiguration({
        //         iceServers: OPENVIDU_ICE_SERVERS,
        //         forceMediaReconnectionAfterNetworkDrop: true
        //     })
        // } catch (e) {
        //
        // }
        // sessionRef.current = ovRef.current.initSession();
        // console.log('INIT! sessionRef.current = ', sessionRef.current);
        // sessionRef.current.on('streamCreated', (event) => {
        //     console.log('streamCreated! event = ', event);
        //     let subscriber = sessionRef.current.subscribe(event.stream, undefined);
        //     subscribersRef.current = subscribersRef.current
        //         .filter(a => (getUserIdBySub(a) != getUserIdBySub(event)))
        //         .concat(subscriber);
        //     setUpdT(+new Date());
        // });
        //
        // sessionRef.current.on('streamDestroyed', (event) => {
        //     console.log('streamDestroyed! event = ', event);
        //     let oldSubscribers = subscribersRef.current;
        //     subscribersRef.current = oldSubscribers.filter(a => (a.stream.streamId != event.stream.streamId));
        //     setUpdT(+new Date());
        // });
        //
        // sessionRef.current.on('sessionDisconnected', (event) => {
        //     if (event.reason === 'networkDisconnect') {
        //         console.warn('Dang-it... You lost your connection to the session');
        //         sessionRef.current = ovRef.current.initSession();
        //     } else {
        //         // Disconnected from the session for other reason than a network drop
        //     }
        // });
        //
        // sessionRef.current.on('reconnecting', () => console.warn('Oops! Trying to reconnect to the session'));
        // sessionRef.current.on('reconnected', () => console.log('Hurray! You successfully reconnected to the session'));
        //
        // sessionRef.current.on('connectionDisconnected', (event) => {
        //     console.log('connectionDisconnected occured!');
        // });
        //
        //
        // OpenviduAPI.getToken(roomName).then((token) => {
        //     console.log('--->>> GOT TOKEN = token = ', token);
        //     sessionRef.current.connect(
        //         token,
        //         {clientData: {user: user}}, // my user name
        //     )
        //         .then(() => {
        //             let publisher = ovRef.current.initPublisher(undefined, {
        //                 audioSource: window.camMicId, // The source of audio. If undefined default microphone
        //                 videoSource: window.camVideoId, // The source of video. If undefined default webcam
        //                 publishAudio: true, // Whether you want to start publishing with your audio unmuted or not
        //                 publishVideo: true, // Whether you want to start publishing with your video enabled or not
        //                 resolution: '320x240', // The resolution of your video
        //                 frameRate: 30, // The frame rate of your video
        //                 insertMode: 'APPEND', // How the video is inserted in the target element 'video-container'
        //                 mirror: false, // Whether to mirror your local video or not
        //             });
        //             sessionRef.current.publish(publisher);
        //             mainStreamRef.current = publisher;
        //             publisherRef.current = publisher;
        //             setUpdT(+new Date());
        //         })
        //         .catch((error) => {
        //             console.log('There was an error connecting to the session:', error.code, error.message);
        //         });
        // });

        return () => {
            if (sessionRef.current) {
                sessionRef.current.disconnect();
            }
            ovRef.current = null;
            mainStreamRef.current = null;
            publisherRef.current = null;
            setSubscribers([]);
        }
    }, [roomName]);


    useEffect(() => {
        FirebaseAPI.initFirebase();
        FirebaseAPI.subscribeOnRoomSettingsChanges(roomName, chData => {
            let shouldChangeVideo = (videoEnabled(chData, user) != videoEnabled(currSettingsRef.current, user));
            let shouldChangeAudio = (audioEnabled(chData, user) != audioEnabled(currSettingsRef.current, user));
            currSettingsRef.current = chData;
            let vEn = videoEnabled(currSettingsRef.current, user);
            let auEn = audioEnabled(currSettingsRef.current, user);
            if (shouldChangeVideo) {
                try {
                    publisherRef.current.publishVideo(vEn);
                } catch (e) {

                }
            }
            if (shouldChangeAudio) {
                try {
                    publisherRef.current.publishAudio(auEn);
                } catch (e) {

                }
            }
            setSettingsUpdT(+new Date());
        }, {mutedUsersIds: ['ghost'], noVideoUsersIds: ['ghost']}).then(pld => {
        }).catch(err => {
        });
        return () => {
            console.log('trying to unsubscribe!');
            try {
                currSettingsRef.current.unsubscribeFunction();
            } catch (exc) {
                console.log('can not unsubscribe');
            }
        }
    }, [roomName]);

    let boxes = [];
    if (mainStreamRef.current != undefined) {
        boxes.push(
            {
                user: undefined,
                content: (
                    <UserVideoBox isMe={true}
                                  hasAudio={mutedUsersIds.indexOf(user.id) == -1}
                                  hasVideo={noVideoUsersIds.indexOf(user.id) == -1}
                                  streamManager={mainStreamRef.current}
                                  canToggle={true}
                                  toggleVideo={async () => {
                                      await toggleControl(currSettingsRef.current, user, 'video', roomName);
                                  }}
                                  toggleAudio={async () => {
                                      await toggleControl(currSettingsRef.current, user, 'audio', roomName);
                                  }}
                    />
                )
            }
        );
    }
    boxes = boxes.concat(subscribersRef.current.map((sub, j) => {
        // let cs = (sub.stream == undefined) ? undefined : (sub.stream.connection == undefined ? undefined : (sub.stream.connection.data == undefined ? undefined : sub.stream.connection.data));
        let uData = OpenviduHelper.getClientDataBySub(sub);
        uData = (uData == undefined) ? {
            id: `ghost_${+new Date()}`,
            name: `Ghost ${Math.floor(Math.random() * 100)}`
        } : uData.user;
        return (
            {
                user: uData,
                streamId: sub.stream.streamId,
                content: (
                    <UserVideoBox streamManager={sub}
                                  user={uData}
                                  canToggle={isTeacher}
                                  hasAudio={mutedUsersIds.indexOf(uData.id) == -1}
                                  hasVideo={noVideoUsersIds.indexOf(uData.id) == -1}
                                  toggleVideo={async () => {
                                      if (isTeacher == false) {
                                          return;
                                      }
                                      await toggleControl(currSettingsRef.current, uData, 'video', roomName);
                                  }}
                                  toggleAudio={async () => {
                                      if (isTeacher == false) {
                                          return;
                                      }
                                      await toggleControl(currSettingsRef.current, uData, 'audio', roomName);
                                  }}

                    />
                )
            }
        )
    }));

    console.log('render: currSettingsRef.current = ', currSettingsRef.current);

    return (
        <Wrapper ref={wrapperRef}>

            {wrapperSize.width == 0 ? null :
                <BoxesLayoutTool boxes={boxes}/>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
`;


function iOS() {
    return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}
