import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

const HOURS = Array.from({length: 24}).map((a, i) => (i * 3600000));

export default function WeekCalendar(props) {
    const {
        weekTimestamp,
        renderHour = t => {
            // console.log('renderHour: t = ', t);
            // return `${moment(t).format('DD.MM HH:mm')}`;
            return null;
        }
    } = props;

    let start = +moment(weekTimestamp).startOf('isoweek').startOf('day');
    let days = Array.from({length: 7}).map((a, i) => (+start + i * 86400000));
    let hours = HOURS.map(a => (+start + +a));
    console.log('render: hours = ', hours);

    return (
        <Wrapper>

            <TopPlaceholder>

                {days.map((d, i) => {
                    let isToday = (+moment().startOf('day') == +moment(d).startOf('day'));
                    return (
                        <TopDayItem key={d} today={isToday}>
                            <TopDayItemInner today={isToday}>
                                <DayName today={isToday}>
                                    {['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'][moment(d).isoWeekday() - 1]}
                                </DayName>
                                <DayNumberCircle today={isToday}>
                                    {moment(d).format('D')}
                                </DayNumberCircle>
                            </TopDayItemInner>
                        </TopDayItem>
                    )
                })}

            </TopPlaceholder>

            <ContentPlaceholder>

                <ContentSubPlaceholder>

                    <ContentInner>

                        <AxisPlaceholder>
                            {hours.map((hour, i) => {
                                return (
                                    <AxRow key={hour}>
                                        {i == 0 ? null :
                                            <HourLabelSpan>
                                                {`${moment(hour).format('HH')}:00`}
                                            </HourLabelSpan>
                                        }
                                    </AxRow>
                                )
                            })}
                        </AxisPlaceholder>

                        <DaysPlaceholder>
                            <DaysInner>
                                {HOURS.map((hour, i) => {
                                    return (
                                        <Row key={hour}>
                                            {days.map((day, j) => {
                                                let t = hour + day;
                                                let dayContent = renderHour(t);
                                                return (
                                                    <Day key={t}>
                                                        <DayContentInner>
                                                            {dayContent}
                                                        </DayContentInner>
                                                    </Day>
                                                );
                                            })}
                                        </Row>
                                    )
                                })}
                            </DaysInner>
                        </DaysPlaceholder>

                    </ContentInner>

                </ContentSubPlaceholder>


            </ContentPlaceholder>

        </Wrapper>
    );
}

const DayContentInner = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
`;

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 22px;
    background: #FFFFFF;
    box-shadow: 0px 5px 10px rgba(31, 32, 65, 0.05);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 22px;
`;

const hourHeight = 60;

const Row = styled.div`
    //border-bottom: 1px solid #D5D5D5;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    height: ${hourHeight}px;
`;

const AxRow = styled.div`
    height: ${hourHeight}px;
    position: relative;
`;

const HourLabelSpan = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: #939598;
    top: -6px;
    right: 5px;
    position: absolute;
`;

const Day = styled.div`
    box-sizing: border-box;
    flex: 1;
    border-right: 1px solid #D5D5D5;
    border-bottom: 1px solid #D5D5D5;
`;

const leftAxisWidth = 65;
const topHeight = 78;

const TopPlaceholder = styled.div`
    height: ${topHeight}px;
    box-sizing: border-box;
    border-bottom: 2px solid #8593A8;
    padding-left: ${leftAxisWidth}px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
`;

const TopDayItem = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const TopDayItemInner = styled.div`
    width: 72px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: ${props => (props.today == true ? '2px solid #FFA71B' : '2px solid #8798AD')};
`;

const DayName = styled.div`
    font-weight: normal;
    font-size: 20px;
    line-height: 32px;
    color: #202224;
    opacity: 0.8;
    margin-top: 3px;
`;

const DayNumberCircle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: ${props => (props.today == true ? 'white' : '#525252')};
    background: ${props => (props.today == true ? '#FFA71B' : 'transparent')};
    border-radius: 1000px;
    width: 24px;
    height: 24px;
    margin-top: 7px;
`;

const ContentPlaceholder = styled.div`
    max-height: calc(100% - ${topHeight}px);
    height: calc(100% - ${topHeight}px);
`;

const ContentSubPlaceholder = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: auto;
`;

const ContentInner = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
`;

const AxisPlaceholder = styled.div`
    box-sizing: border-box;
    border-right: 1px solid #D5D5D5;
    width: ${leftAxisWidth}px;  
`;

const DaysPlaceholder = styled.div`
    width: 100%;
    flex: 1;
`;

const DaysInner = styled.div`
    width: 100%;
    max-height: 100%;
    position: relative;
`;
