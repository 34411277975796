import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {BlueButton} from "../../../ira/ui/Buttons";
import NiceModal from "../../../modals/NiceModal";
import UpdateLandingWebinarCardForm from "../forms/UpdateLandingWebinarCardForm";
import {translate} from "../../../../utils/Translate";

export default function UpdateWebinarsTool(props) {
    const {
        loading = false,
        onSave = a => {

        },
        items = []
    } = props;

    // const [items, setItems] = useState(props.items == undefined ? [] : props.items);
    const [selectedId, setSelectedId] = useState(undefined);

    const [addVisible, setAddVisible] = useState(false);

    let selectedItem = (selectedId == undefined) ? undefined : items.filter(a => (a.id == selectedId))[0];

    return (
        <Wrapper>

            <TripletsPlaceholder>
                <ItemsPlaceholder>
                    {items.map((a, i) => {
                        return (
                            <Item key={`a_${i}`} className={'course-tile'} onClick={() => {
                                setSelectedId(a.id);
                            }}>
                                <ItemTopImage src={a.avatar}/>
                                <ItemCont>
                                    <ItemName>
                                        {a.name}
                                    </ItemName>
                                    <ItemRow>
                                        <ItValue>
                                            <ItLabel>
                                                {`${translate('level')}:`}
                                            </ItLabel>
                                            <div>
                                                {a.level}
                                            </div>
                                        </ItValue>
                                    </ItemRow>
                                    <ItemRow>
                                        <ItValue>
                                            <ItLabel>
                                                Дата:
                                            </ItLabel>
                                            <div>
                                                {a.date}
                                            </div>
                                        </ItValue>
                                    </ItemRow>
                                    <ItemRow>
                                        <ItValue>
                                            <ItLabel>
                                                Автор:
                                            </ItLabel>
                                            <div>
                                                {a.author}
                                            </div>
                                        </ItValue>
                                    </ItemRow>
                                    <ItemBottomPlaceholder>
                                        <ItemPrice>
                                            {`${a.price} ₽`}
                                        </ItemPrice>
                                    </ItemBottomPlaceholder>
                                </ItemCont>
                            </Item>
                        )
                    })}
                </ItemsPlaceholder>
            </TripletsPlaceholder>

            <div>
                {loading == true ? <Spin visible={loading} theme={'alfa-on-color'}/> :
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <BlueButton onClick={() => {
                            setAddVisible(true);
                        }}>
                            добавить
                        </BlueButton>
                    </div>
                }
            </div>

            {addVisible == false ? null :
                <NiceModal onClose={() => {
                    setAddVisible(false);
                }}>

                    <DialogInnerPlaceholder>
                        <UpdateLandingWebinarCardForm loading={loading} onSave={d => {
                            let newItems = items.concat([{
                                id: uuid(),
                                ...d
                            }]);
                            // setItems(newItems);
                            onSave(newItems);
                            setAddVisible(false);
                        }}/>
                    </DialogInnerPlaceholder>

                </NiceModal>
            }

            {selectedItem == undefined ? null :
                <NiceModal onClose={() => {
                    setSelectedId(undefined);
                }}>

                    <DialogInnerPlaceholder>
                        <UpdateLandingWebinarCardForm
                            {...selectedItem}
                            loading={loading}
                            onSave={d => {
                                let newItems = items.map(a => {
                                    if (a.id == selectedItem.id) {
                                        return {...selectedItem, ...d}
                                    }
                                    return a;
                                });
                                // setItems(newItems);
                                onSave(newItems);
                                setSelectedId(undefined);
                            }}/>
                    </DialogInnerPlaceholder>

                </NiceModal>
            }

        </Wrapper>
    );
}

// const Wrapper = styled.div`
//
// `;

const DialogInnerPlaceholder = styled.div`
    width: 640px;
    padding: 40px;
    background: white;
    border-radius: 5px;
`;

const ItemPrice = styled.div`
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #FF624F;
`;

// const BlueButton = styled.div`
//     background: #085BFF;
//     border-radius: 1000px;
//     height: 44px;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     color: white;
//     padding-left: 15px;
//     padding-right: 15px;
//     cursor: pointer;
//     :hover{
//        opacity: 0.9;
//     }
// `;

const ItemCont = styled.div`
    padding: 27px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    min-height: 90px;
`;

const ItemBottomPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const ItemRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
`;

const ItValue = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    line-height: 24px;
    color: #201F41;
`;

const ItLabel = styled.div`
    color: rgba(31, 32, 65, 0.5);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    margin-right: 4px;
`;

const RowImg = styled.img`
    margin-right: 10px;
    height: 22px;
`;

const ItemName = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #1F2041;
    margin-bottom: 20px;
    min-height: 90px;
`;

const Item = styled.div`
    width: calc(33.33% - 80px);
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
    //height: 400px;
    cursor: pointer;
    background: #FFFFFF;
    box-shadow: 0px 5px 10px rgba(31, 32, 65, 0.05);
    border-radius: 4px;
    margin: 20px;
`;

const ItemTopImage = styled.div`
    height: 230px;
    background-image: url(${props => props.src});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
`;

// const ItemBottomPlaceholder = styled.div`
//     box-sizing: border-box;
//     border-bottom-left-radius: 20px;
//     border-bottom-right-radius: 20px;
//     min-height: 100px;
//     padding: 20px;
//     font-weight: 800;
//     line-height: 1.25;
//     font-size: 20px;
//     margin-bottom: 18px;
//     background-color: ${props => props.bgColor};
//     height: 130px;
//     position: relative;
// `;


const ItemImage = styled.img`
    height: 80px;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
`;


const ImagePlaceholder = styled.div`
    margin-bottom: 30px;
    position: relative;
    height: 120px;
`;

const ItemInner = styled.div`
    padding: 5px;
    box-sizing: border-box;
`;


const ItemText = styled.div`
    font: 600 16px/1.56 "Open Sans",sans-serif;
    color: #000;
`;

const ItemsPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
`;

const TripletsPlaceholder = styled.div`
    margin-top: 40px;
`;

const MainText = styled.div`
    font: 600 20px/1.56 "Open Sans",sans-serif;
    color: #000;
    width: 600px;
`;

// const InnerPlaceholder = styled.div`
//     width: ${breakWidth}px;
//     margin: 0 auto;
//     box-sizing: border-box;
//     position: relative;
//     ::before{
//         content: '';
//         width: 1em;
//         height: 1em;
//         position: absolute;
//         top: -96px;
//         right: -154px;
//         z-index: -1;
//         background-image: url(${aboutImg});
//         background-size: cover;
//         font-size: 246px;
//         transform: rotate(30deg);
//     }
// `;

const Wrapper = styled.div`
    box-sizing: border-box;
    background: white;
    padding-top: 60px;
    padding-bottom: 60px;
`;

const Heading = styled.h2`
     font-size: 35px;
     margin-bottom: 44px;
     text-align: center;
`;
