import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import useComponentSize from "@rehooks/component-size";

// import './style.css'
import Chess from "chess.js";

const SAMPLE_START_FEN = 'rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R b KQkq - 1 2';
const STARTING_FEN = 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1';

const COOL_BG_IMAGE = require('../../../assets/images/nice_shagi_dot.png');


const greySquare = (boardId, square) => {
    let squareEl = window.$(`#${boardId} .square-` + square);
    let background = '#bfdaf3';
    let backgroundImage = `url('${COOL_BG_IMAGE}')`;
    if (squareEl.hasClass('black-3c85d') === true) {
        background = '#9bb2c7';
    }
    // squareEl.css('background', background);
    squareEl.css('background-image', backgroundImage);
};

const removeGreySquares = (boardId) => {
    window.$(`#${boardId} .square-55d63`).css('background-image', 'none');
};

const makeReplacement = (board, fig) => {
    let newFen = board.fen();
    let lines = newFen.split('/');
    lines[0] = lines[0].replace(/P/g, fig.toUpperCase());
    lines[7] = lines[7].replace(/p/g, fig.toLowerCase());
    newFen = lines.join('/');
    board.position(newFen);
}

export default function SimpleIllegalBoard(props) {
    const {
        canMakeMoves = true,
        boardId = `simple_board`,
        orientation = 'white',
        allowIllegal = true,
        fen = STARTING_FEN,
        startHistory = []
    } = props;
    const board = useRef(null);
    const game = useRef(null);
    const ref = useRef(undefined);
    const size = useComponentSize(ref);
    const [replacementModalOpen, setReplacementModalOpen] = useState(false);

    console.log('SimpleIllegalBoard: render: boardId = ', boardId);

    useEffect(() => {

        setTimeout(() => {

            game.current = new Chess(fen);
            for (let i in startHistory) {
                game.current.move(startHistory[i].san);
            }

            console.log(`initializing board ${boardId}`);

            board.current = new window.ChessBoard(boardId, {
                position: fen,
                draggable: canMakeMoves,
                moveSpeed: 'slow',
                snapbackSpeed: 500,
                snapSpeed: 100,
                orientation: orientation,
                onDragStart: (source, piece, position, orientation) => {
                    console.log('onDragStart occured! source, piece, position, orientation = ', source, piece, position, orientation);

                    if (canMakeMoves == false) {
                        return false;
                    }
                    // if (game.current.game_over() === true ||
                    //     (((game.current.turn() === 'w' && piece.search(/^b/) !== -1) ||
                    //         (game.current.turn() === 'b' && piece.search(/^w/) !== -1)) && (allowIllegal == false))
                    // ) {
                    //     return false;
                    // }
                },
                onDrop: (source, target) => {
                    if (canMakeMoves == false) {
                        return;
                    }
                    // let move = game.current.move({
                    //     from: source,
                    //     to: target,
                    //     promotion: 'q' // NOTE: always promote to a queen for example simplicity
                    // });
                    // // illegal move
                    // if ((move === null) && (allowIllegal == false)) return 'snapback';
                },
                onMouseoutSquare: (square, piece) => {
                    if (canMakeMoves == false) {
                        return;
                    }
                    removeGreySquares(boardId);
                },
                onMouseoverSquare: (square, piece) => {
                    if (canMakeMoves == false) {
                        return;
                    }
                    // console.log('onMouseoverSquare: square = ', square);
                    let moves = game.current.moves({
                        square: square,
                        verbose: true
                    });
                    // console.log('moves = ', moves);
                    if (moves.length === 0) return;
                    greySquare(boardId, square);
                    for (let i = 0; i < moves.length; i++) {
                        greySquare(boardId, moves[i].to);
                    }
                },
                onSnapEnd: () => {
                    if (canMakeMoves == false) {
                        return;
                    }
                    console.log('onSnapEnd: board.current.fen() = ', board.current.fen());
                    let newFen = board.current.fen();
                    console.log('snapped! newFen = ', newFen);
                    let lines = newFen.split('/');
                    let needToChange = ((lines[0].indexOf('P') > -1) || (lines[7].indexOf('p') > -1));
                    // console.log('replaced newFen! = ');
                    if (needToChange == true) {

                        // lines[0] = lines[0].replace(/P/g, 'Q');
                        // lines[7] = lines[7].replace(/p/g, 'q');
                        // newFen = lines.join('/');
                        // board.current.position(newFen);

                        setReplacementModalOpen(true);
                        // window.alert('!!!');
                        // setTimeout(() => {
                        //     console.log('setting new fen = ', newFen);
                        //     board.current.position(newFen);
                        // }, 100);
                    }
                    game.current = new Chess(newFen); // think!

                    // console.log('game.current = ', game.current);
                    // board.current.position(game.current.fen());
                }
            });

        }, 1000 / 60.0);

        return () => {
            try {
                board.current.destroy();
                console.log('board has been destroyed!');
            } catch (exc) {
                console.log('can not destroy board');
            }
        }
    }, [boardId]);

    return (
        <Wrapper ref={ref}>
            <BoardPlaceholder id={boardId} style={{
                width: size.width == undefined || size.width == 0 ? '100%' : size.width,
                height: size.width == undefined || size.width == 0 ? '100%' : size.width
            }}>

            </BoardPlaceholder>
            <Sidebar visible={replacementModalOpen}>
                {replacementModalOpen == false ? null :
                    <div>
                        <ReplacementRow>
                            <ReplacementImage src={require('../../../assets/images/pieces/black_knight.svg')}
                                              onClick={() => {
                                                  makeReplacement(board.current, 'n');
                                                  setReplacementModalOpen(false);
                                              }}/>

                            <ReplacementImage src={require('../../../assets/images/pieces/black_bishop.svg')}
                                              onClick={() => {
                                                  makeReplacement(board.current, 'b');
                                                  setReplacementModalOpen(false);
                                              }}/>

                            <ReplacementImage src={require('../../../assets/images/pieces/black_rook.svg')}
                                              onClick={() => {
                                                  makeReplacement(board.current, 'r');
                                                  setReplacementModalOpen(false);
                                              }}/>
                            <ReplacementImage src={require('../../../assets/images/pieces/black_queen.svg')}
                                              onClick={() => {
                                                  makeReplacement(board.current, 'q');
                                                  setReplacementModalOpen(false);
                                              }}/>
                        </ReplacementRow>
                    </div>
                }
            </Sidebar>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
`;

const BoardPlaceholder = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    //background: purple;
`;

const ReplacementRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const ReplacementImage = styled.img`
    width: 64px;
    height: 64px;
    margin: 5px;
`;
