import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import HorChessTemplate from "../templates/HorChessTemplate";
import TeacherStudentsPanel from "../teachers/panels/TeacherStudentsPanel";
import IraTeacherTemplate from "../templates/IraTeacherTemplate";
import TeacherLessonPanel from "../lessons/panels/TeacherLessonPanel";


export default function TeacherLessonApp(props) {
    let {id} = props.match.params;

    return (
        <IraTeacherTemplate active={`lesson/${id}`}>

            <TeacherLessonPanel id={id}/>

        </IraTeacherTemplate>
    );
}


