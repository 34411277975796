export const COLORS_MAP = {
    lose: 'salmon',
    win: 'green',
    draw: '#e2bd00'
}

export const SOLUTIONS_TYPES = {
    WIN: 'win',
    LOSE: 'lose',
    DRAW: 'draw'
};

export const SOLUTIONS_TYPES_OPTIONS = [
    {
        label: 'Правильное решение',
        value: SOLUTIONS_TYPES.WIN
    },
    {
        label: 'Ошибка',
        value: SOLUTIONS_TYPES.LOSE
    },
    {
        label: 'Не лучший ход',
        value: SOLUTIONS_TYPES.DRAW
    }
];

export const SOLUTIONS_TAGS = {
    ATTACK: 'attack',
    PROTECTION: 'protection'
};

export const SOLUTIONS_TAGS_OPTIONS = [
    {
        label: 'Нападение',
        value: SOLUTIONS_TAGS.ATTACK
    },
    {
        label: 'Защита',
        value: SOLUTIONS_TAGS.PROTECTION
    }
];

export const PIECES_MAP = {
    'p': {
        icon: require('../assets/images/pieces/black_pawn.svg'),
        smartIcon: 'https://images.chesscomfiles.com/chess-themes/pieces/neo/200/bp.png',
        chessDefinition: {
            color: 'b',
            type: 'p'
        }
    },
    'k': {
        icon: require('../assets/images/pieces/black_king.svg'),
        smartIcon: 'https://images.chesscomfiles.com/chess-themes/pieces/neo/200/bk.png',
        chessDefinition: {
            color: 'b',
            type: 'k'
        }
    },
    'n': {
        icon: require('../assets/images/pieces/black_knight.svg'),
        smartIcon: 'https://images.chesscomfiles.com/chess-themes/pieces/neo/200/bn.png',
        chessDefinition: {
            color: 'b',
            type: 'n'
        }
    },
    'b': {
        icon: require('../assets/images/pieces/black_bishop.svg'),
        smartIcon: 'https://images.chesscomfiles.com/chess-themes/pieces/neo/200/bb.png',
        chessDefinition: {
            color: 'b',
            type: 'b'
        }
    },
    'q': {
        icon: require('../assets/images/pieces/black_queen.svg'),
        smartIcon: 'https://images.chesscomfiles.com/chess-themes/pieces/neo/200/bq.png',
        chessDefinition: {
            color: 'b',
            type: 'q'
        }
    },
    'r': {
        icon: require('../assets/images/pieces/black_rook.svg'),
        smartIcon: 'https://images.chesscomfiles.com/chess-themes/pieces/neo/200/br.png',
        chessDefinition: {
            color: 'b',
            type: 'r'
        }
    },

    'P': {
        icon: require('../assets/images/pieces/white_pawn.svg'),
        smartIcon: 'https://images.chesscomfiles.com/chess-themes/pieces/neo/200/wp.png',
        chessDefinition: {
            color: 'w',
            type: 'p'
        }
    },
    'K': {
        icon: require('../assets/images/pieces/white_king.svg'),
        smartIcon: 'https://images.chesscomfiles.com/chess-themes/pieces/neo/200/wk.png',
        chessDefinition: {
            color: 'w',
            type: 'k'
        }
    },
    'N': {
        icon: require('../assets/images/pieces/white_knight.svg'),
        smartIcon: 'https://images.chesscomfiles.com/chess-themes/pieces/neo/200/wn.png',
        chessDefinition: {
            color: 'w',
            type: 'n'
        }
    },
    'B': {
        icon: require('../assets/images/pieces/white_bishop.svg'),
        smartIcon: 'https://images.chesscomfiles.com/chess-themes/pieces/neo/200/wb.png',
        chessDefinition: {
            color: 'w',
            type: 'b'
        }
    },
    'Q': {
        icon: require('../assets/images/pieces/white_queen.svg'),
        smartIcon: 'https://images.chesscomfiles.com/chess-themes/pieces/neo/200/wq.png',
        chessDefinition: {
            color: 'w',
            type: 'q'
        }
    },
    'R': {
        icon: require('../assets/images/pieces/white_rook.svg'),
        smartIcon: 'https://images.chesscomfiles.com/chess-themes/pieces/neo/200/wr.png',
        chessDefinition: {
            color: 'w',
            type: 'r'
        }
    }
}

export const UPLOAD_DO_ENDPOINT_URL = 'https://www.englishpatient.org/api/upload';
