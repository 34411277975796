import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import CommonHelper from "../../../helpers/CommonHelper";

import * as teacherGroupsActions from '../../../redux/actions/TeacherGroupsActions'
import * as lessonsActions from '../../../redux/actions/LessonsActions'

export default function TeacherLessonsPanel(props) {
    const {
        teacherId, onSelect = (lId, l) => {

        }
    } = props;
    let {currentUser, loading, lessons, groupsMap, uId} = useMappedState(useCallback(state => {
        let uId = (teacherId == undefined) ? state.users.currentUserId : teacherId;
        let teacher = state.users.usersMap.get(uId);
        let teacherGroups = state.teacherGroups.teacherGroupsMap.toArray().filter(g => (g.teacherId == uId));
        let grSet = teacherGroups.reduce((sst, gr) => sst.add(gr.id), Set());
        let grMap = teacherGroups.reduce((sst, gr) => sst.set(gr.id, gr), Map());
        let lessons = state.lessons.lessonsMap.toArray().filter(u => grSet.has(u.teacherGroupId)).sort((a, b) => (+b.startTimestamp - +a.startTimestamp));
        return {
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            loading: state.lessons.loading,
            lessons: lessons,
            uId: uId,
            currentUserIsTeacher: (teacher != undefined && 'teacher' == teacher.userRole),
            groupsMap: grMap
        }
    }, [teacherId]));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(lessonsActions.loadAllLessons()); // todo: optimize
        dispatch(teacherGroupsActions.loadAllTeacherGroupsOfTeacher(uId));
    }, [uId]);



    return (
        <Wrapper>
            <ItemsPlaceholder>
                {lessons.map((less, k) => {
                    let gr = groupsMap.get(less.teacherGroupId);
                    return (
                        <LessonItem key={less.id} onClick={() => {
                            onSelect(less.id, less);
                        }}>
                            <Name>
                                {`${gr == undefined ? '' : `${gr.name} `}- ${less.name == undefined ? '' : `${less.name} - `}${moment(less.startTimestamp).format('DD.MM.YYYY HH:mm')}`}
                            </Name>
                        </LessonItem>
                    )
                })}
            </ItemsPlaceholder>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const ItemsPlaceholder = styled.div`
    
`;

const LessonItem = styled.div`
    box-sizing: border-box;
    margin-bottom: 10px;
    padding: 5px;
    background-color: white;
    border-radius: 4px;
    border: 1px solid whitesmoke;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;

const Name = styled.div`
    
`;
