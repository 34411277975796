import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import {Label, Input} from "../../ira/ui/Inputs";
import {BlueButton} from "../../ira/ui/Buttons";
import {translate} from "../../../utils/Translate";

export default function UpdateShortExerciseForm(props) {
    const {
        onSave = d => {

        },
        loading = false
    } = props;

    const [name, setName] = useState(props.name == undefined ? '' : props.name);
    const [description, setDescription] = useState(props.description == undefined ? '' : props.description);
    const [fromRating, setFromRating] = useState(props.fromRating == undefined ? '0' : props.fromRating);
    const [toRating, setToRating] = useState(props.toRating == undefined ? '0' : props.toRating);


    return (
        <Wrapper>

            <TopRow>
                <Field>
                    <Label>
                        Название упражнения
                    </Label>
                    <Input value={name} onChange={evt => {
                        setName(evt.target.value);
                    }} placeholder={''}/>
                </Field>
            </TopRow>

            <Row>
                <Field style={{marginRight: 5}}>
                    <Label>
                        Минимальный рейтинг
                    </Label>
                    <Input value={fromRating} onChange={evt => {
                        setFromRating(evt.target.value);
                    }} placeholder={''}/>
                </Field>
                <Field style={{marginLeft: 5}}>
                    <Label>
                        Максимальный рейтинг
                    </Label>
                    <Input value={toRating} onChange={evt => {
                        setToRating(evt.target.value);
                    }} placeholder={''}/>
                </Field>
            </Row>

            <BottomButtonPlaceholder>
                {loading == true ? null :
                    <BlueButton onClick={() => {
                        onSave({
                            name: name,
                            fromRating: +fromRating,
                            toRating: +toRating,
                        });
                    }}>
                        {translate('save')}
                    </BlueButton>
                }
            </BottomButtonPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const TopRow = styled.div`
    
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

const Field = styled.div`
    margin-bottom: 10px;
    flex: 1;
`;

const BottomButtonPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
`;
