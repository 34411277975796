import styled from 'styled-components';

export const Input = styled.input`
    width: ${props => props.type === 'checkbox' ? 'auto' : '100%'};
    box-sizing: border-box;
    background: white;
    height: 44px;
    line-height: 42px;
    border: 1px solid #8593A8;
    border-radius: 4px;
    outline: none;
    padding-left: 15px;
    font-size: 14px;
    :focus{
      border-color: #085BFF;
      color: #1F2041;
    }
    ::placeholder {
      color: rgba(31, 32, 65, 0.25);
    }
`;
