import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import * as cActions from '../../../redux/actions/CoursesActions'
import * as exActions from '../../../redux/actions/ExercisesActions'
import TestsList from "../lists/TestsList";
import TestsPicker from "../tools/TestsPicker";
import ManyExercisesPlayPanel from "../../lessons/view/ManyExercisesPlayPanel";
import ExercisesPicker from "../tools/ExercisesPicker";
import SmartSizePlayExerciseTool from "../../exercises/tools/SmartSizePlayExerciseTool";
import NiceModalWithHeading from "../../modals/NiceModalWithHeading";
import IllegalWorkspace from "../../rubius/exercise/Workspace/IllegalWorkspace";
import Workspace from "../../rubius/exercise/Workspace/Workspace";
import * as lessonsActions from "../../../redux/actions/LessonsActions";
import SmartExercisesSelectorPanel from "../../smart_exercises/panels/SmartExercisesSelectorPanel";

export default function UnitExercisesPanel(props) {


    const {id, editable = true} = props;
    const [selectedId, setSelectedId] = useState(undefined);
    const [pickerVisible, setPickerVisible] = useState(false);

    let {currentUser, loading, exercises, exercisesIds} = useMappedState(useCallback(state => {
        let currentUserId = state.users.currentUserId;
        let currentUser = state.users.usersMap.get(currentUserId);
        let unit = state.courses.unitsMap.get(id);
        let exercisesIds = (unit == undefined) ? [] : (unit.exercisesIds == undefined ? [] : unit.exercisesIds);
        let exercises = exercisesIds.map(exId => state.smartExercises.smartExercisesMap.get(exId)).filter(a => (a != undefined))

        return {
            currentUser: currentUser,
            loading: state.users.loading || state.tests.loading || state.courses.loading,
            exercises: exercises,
            exercisesIds: exercisesIds
        }
    }, []));

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(cActions.loadAllUnits()); // todo: optimize
        dispatch(exActions.loadAllExercises()); // todo: optimize
    }, [id]);

    const viewExercise = (selectedId == undefined) ? undefined : exercises.filter(a => (a.id == selectedId))[0];


    return (
        <Wrapper>

            {editable == false ? null :
                <TopPlaceholder>
                    <PickerSpan onClick={() => {
                        setPickerVisible(true);
                    }}>
                        Выбрать упражнения
                    </PickerSpan>
                </TopPlaceholder>
            }


            <TestsPlaceholder>
                {exercises.map((st, k) => {
                    return (
                        <StudentRow key={`${st.id}_${k}`}>
                            <NameCol>
                            <span>
                            <span style={{fontWeight: 'bold', marginRight: 4}}>
                                Упражнение
                            </span>
                                {`${st.name}`}
                            </span>
                            </NameCol>
                            <FromCol>
                                {st.courseName}
                            </FromCol>
                            <LevelCol>
                                {st.level}
                            </LevelCol>
                            <OptionsCol>
                                <TrashImg
                                    src={require('../../../assets/images/eye.svg')}
                                    onClick={() => {
                                        setSelectedId(st.id);
                                    }}
                                />
                            </OptionsCol>
                        </StudentRow>
                    )
                })}

            </TestsPlaceholder>

            {pickerVisible == false ? null :
                <NiceModalWithHeading width={Math.min(920, window.innerWidth)}
                                      onClose={() => {
                                          setPickerVisible(false);
                                      }} heading={'Упражнения к модулю'}>

                    {pickerVisible == false ? null :
                        <SelectedViewInner>
                            <SmartExercisesSelectorPanel
                                exercisesIds={exercisesIds}
                                onChange={async newIds => {
                                    await dispatch(cActions.updateUnit({
                                        id: id,
                                        exercisesIds: newIds
                                    }));
                                }}
                            />
                        </SelectedViewInner>
                    }

                </NiceModalWithHeading>
            }

            {viewExercise == undefined ? null :
                <NiceModalWithHeading
                    width={940}
                    heading={viewExercise.name}
                    subHeading={``}
                    onClose={() => {
                        setSelectedId(undefined);
                    }}>

                    <SelectedViewInner>

                        {viewExercise.isIllegal ?
                            <IllegalWorkspace {...viewExercise}
                                              boardWidthFraction={0.4}
                                              onExerciseFinish={() => {

                                              }}
                                              onStepLimitReached={() => {

                                              }}
                                              onSubExerciseStart={() => {

                                              }}
                                              onTimeFinished={() => {

                                              }}/>
                            :
                            <Workspace {...viewExercise}
                                       allowHandleKeyEvents={() => {

                                       }}
                                       boardWidthFraction={0.4}
                                       onSubExerciseStart={() => {

                                       }}
                                       onSubExerciseEnd={() => {

                                       }}
                                       onExerciseFinish={() => {

                                       }}
                                       onTimeFinished={() => {

                                       }}
                                       onTimerTick={() => {

                                       }}/>}

                    </SelectedViewInner>

                </NiceModalWithHeading>
            }

        </Wrapper>
    );
}

const TrashImg = styled.img`
    width: 24px;
    height: 24px;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;

const SelectedViewInner = styled.div`
    width: 100%;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
`;

const NameCol = styled.div`
    width: 350px;
    box-sizing: border-box;
    padding-left: 31px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const OptionsCol = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;

const LevelCol = styled.div`
    width: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;

const StudentRow = styled.div`
    padding-top: 27px;
    padding-bottom: 27px;
    border-bottom: 0.4px solid rgba(81, 139, 219, 0.4);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 31px;
    box-sizing: border-box;
    background: ${props => (props.selected != true ? 'white' : 'linear-gradient(0deg, #EDF8FF, #EDF8FF), #FFFFFF;')};
`;

const FromCol = styled.div`
    width: 300px;
    box-sizing: border-box;
    padding-left: 31px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Wrapper = styled.div`
    
`;

const TopPlaceholder = styled.div`
    text-align: right;
    margin-bottom: 10px;
`;

const PickerSpan = styled.span`
    text-decoration: underline;
    opacity: 0.8;
    cursor: pointer;
    :hover{
      opacity: 1;
    }
`;

const TestsPlaceholder = styled.div`
    
`;
