import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const ToolsAPI = {

    async getFideData(playerId){
        let d = (await axios.get(`${API_ENDPOINT}/fide/${playerId}/info`)).data;
        return d;
    },

    async getRuchessData(playerId){
        let d = (await axios.get(`${API_ENDPOINT}/ruchess/${playerId}/info`)).data;
        return d;
    }


}

export default ToolsAPI;
