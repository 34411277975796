import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import CommonHelper from "../../helpers/CommonHelper";
import {DEFAULT_TIMEZONE} from "../../constants/config";

const DAY_DURATION = 86400000;


export default function MonthCalendar(props) {
    const {
        selectedTimestamp,
        borderColor = 'whitesmoke',
        showYearMovers = false,
        dayContent = t => {

        },
        canInteract = true
    } = props;
    const [monthTimestamp, setMonthTimestamp] = useState(+moment(props.selectedTimestamp).startOf('month'));
    const start = +moment(monthTimestamp).startOf('month').startOf('isoweek').startOf('day');
    const end = +moment(monthTimestamp).endOf('month').endOf('isoweek').startOf('day');
    let weeks = [], t = start;
    while (t < end) {
        weeks = weeks.concat(t);
        t = +t + 7 * DAY_DURATION;
    }
    // console.log('weeks = ', weeks);

    return (
        <Wrapper borderColor={borderColor}>

            <TopPlaceholder borderColor={borderColor}>

                {canInteract == false ? null :
                    <SideArrowPlaceholder>
                        {showYearMovers == false ? null :
                            <SideInner style={{marginRight: 20}}>
                                <ArrImg double={true} src={require('./left-arrows-couple.png')} onClick={() => {
                                    if (canInteract == false) {
                                        return;
                                    }
                                    setMonthTimestamp(+moment(+moment(monthTimestamp).add(-1, 'year')).startOf('month'))
                                }}/>
                            </SideInner>
                        }
                        <SideInner>
                            <ArrImg src={require('./left_arr.svg')} onClick={() => {
                                if (canInteract == false) {
                                    return;
                                }
                                setMonthTimestamp(+moment(+moment(monthTimestamp).startOf('month') - 2 * 86400000).startOf('month'))
                            }}/>
                        </SideInner>
                    </SideArrowPlaceholder>
                }


                <MonthPlaceholder borderColor={borderColor}>
                    {`${CommonHelper.getRuMonthByTimestamp(monthTimestamp)} ${moment(monthTimestamp).tz(DEFAULT_TIMEZONE).format('YYYY')}`}
                </MonthPlaceholder>

                {canInteract == false ? null :
                    <SideArrowPlaceholder>
                        <SideInner>
                            <ArrImg src={require('./right_arr.svg')} onClick={() => {
                                if (canInteract == false) {
                                    return;
                                }
                                setMonthTimestamp(+moment(+moment(monthTimestamp).endOf('month') + 2 * 86400000).startOf('month'));
                            }}/>
                        </SideInner>
                        {showYearMovers == false ? null :
                            <SideInner style={{marginLeft: 20}}>
                                <ArrImg double={true} src={require('./right-arrows-couple.png')} onClick={() => {
                                    setMonthTimestamp(+moment(+moment(monthTimestamp).add(1, 'year')).startOf('month'));
                                }}/>
                            </SideInner>
                        }
                    </SideArrowPlaceholder>
                }

            </TopPlaceholder>

            <ContentPlaceholder>
                <Weeks borderColor={borderColor}>
                    {weeks.map((w, k) => {
                        let days = Array.from(new Array(7)).map((x, i) => (+w + i * DAY_DURATION));
                        // console.log('days = ', days);
                        return (
                            <WeekRow key={`${w}_${k}`} borderColor={borderColor}>
                                <Days>
                                    {days.map((d, j) => {
                                        let cont = dayContent(d);
                                        return (
                                            <DayItem key={`${d}_${j}`} borderColor={borderColor}>
                                                <DayName>
                                                    {moment(d).format('D')}
                                                </DayName>
                                                {cont == undefined ? null :
                                                    <DayContentPlaceholder>
                                                        {cont}
                                                    </DayContentPlaceholder>
                                                }
                                            </DayItem>
                                        )
                                    })}
                                </Days>
                            </WeekRow>
                        )
                    })}
                </Weeks>
            </ContentPlaceholder>


        </Wrapper>
    );
}

const DEFAULT_BORDER_COLOR = 'whitesmoke';

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    border-left: 1px solid whitesmoke;
    border-right: 1px solid whitesmoke;
    border-top: 1px solid whitesmoke;
    border-color: ${props => (props.borderColor == undefined ? DEFAULT_BORDER_COLOR : props.borderColor)};
`;

const topHeight = 40;

const MonthPlaceholder = styled.div`
    text-align: center;
    //width: 100px;
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
    height: ${topHeight}px;
    line-height: ${topHeight}px;
    font-size: 18px;
`;

const TopPlaceholder = styled.div`
    height: ${topHeight}px;
    line-height: ${topHeight}px;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const SideArrowPlaceholder = styled.div`
    width: 90px;
    display: inline-flex;
    vertical-align: top;
    box-sizing: border-box;
    text-align: center;
    height: ${topHeight}px;
    line-height: ${topHeight}px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const SideInner = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const ArrImg = styled.img`
    display: inline-block;
    height: ${props => (props.double == true ? 16 : 20)}px;
    width: ${props => (props.double == true ? 16 : 20)}px;
    cursor: pointer;
    opacity: ${props => (props.double == true ? 0.4 : 1)};
    :hover{
      opacity: ${props => (props.double == true ? 1 : 0.7)};
    }
`;

const ContentPlaceholder = styled.div`
    height: calc(100% - ${topHeight}px);
    width: 100%;
`;

const Weeks = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border-top: 1px solid ${props => (props.borderColor == undefined ? DEFAULT_BORDER_COLOR : props.borderColor)};
`;

const WeekRow = styled.div`
    width: 100%;
    flex: 1;
    border-bottom: 1px solid ${props => (props.borderColor == undefined ? DEFAULT_BORDER_COLOR : props.borderColor)};
`;

const Days = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;  
    justify-content: center;
    width: 100%;
    height: 100%;
`;

const DayItem = styled.div`
    flex: 1;
    border-right: 1px solid ${props => (props.borderColor == undefined ? DEFAULT_BORDER_COLOR : props.borderColor)};
    width: ${100.0 / 7}px;
    box-sizing: border-box;
    height: 100%;
    position: relative;
    :last-of-type{
      border-right: none;
    }
`;

const DayName = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    font-size: 13px;
    opacity: 0.5;
`;

const DayContentPlaceholder = styled.div`
    width: 100%;
    height: 100%;
`;
