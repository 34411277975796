import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import axios from 'axios'

import * as actions from '../../../redux/actions/LessonsActions'
import * as smartExercisesActions from '../../../redux/actions/SmartExercisesActions'
import * as coursesActions from '../../../redux/actions/CoursesActions'
import UpdateLessonForm from "../forms/UpdateLessonForm";
import CommonHelper from "../../../helpers/CommonHelper";
import IllegalWorkspace from "../../rubius/exercise/Workspace/IllegalWorkspace";
import Workspace from "../../rubius/exercise/Workspace/Workspace";
import UnitView from "../../units/view/UnitView";
import SmartExercisesAPI from "../../../api/SmartExercisesAPI";
import ExerciseCopeViewTool from "../tools/ExerciseCopeViewTool";
import {API_ENDPOINT} from "../../../constants/config";
import ls from "local-storage";

import green_tick from '../../../assets/images/green_tick.svg'
import red_tick from '../../../assets/images/remove_icon.svg'
import back_button from '../../../assets/images/back_button.svg'
import {translate} from "../../../utils/Translate";

const TABS = [
    {
        label: 'Теория',
        value: ''
    },
    {
        label: 'Упражнения',
        value: ''
    },
    {
        label: 'Тесты',
        value: ''
    }
];

export const getSortedItems = arr => {
    return arr.sort((a, b) => {
        let s1 = `${a.name}`.toLowerCase();
        let s2 = `${b.name}`.toLowerCase();
        if (s1 > s2) {
            return 1;
        }
        if (s1 < s2) {
            return -1;
        }
        return 0;
    });
}

async function getExercisesOpenMap(exercisesIds = []) {
    console.log('exercisesIds = ', exercisesIds);
    let results = await Promise.all(exercisesIds.map(exId => SmartExercisesAPI.getUserExerciseResultsMap(exId)));
    let map = {};
    for (let i in exercisesIds) {
        let keys = Object.keys(results[i]);
        let vals = keys.map(xx => results[i][xx]);
        let wins = vals.filter(xx => (xx.assessment != 'lose'));
        let isWin = (vals.length == wins.length);
        let isPassed = (vals.length > 0);

        let val = Object.keys(results[i]).length;
        console.log('results[i] = ', results[i]);
        map[exercisesIds[i]] = (isPassed == false) ? 0 : (isWin ? 3 : 1);
    }
    console.log('getExercisesOpenMap: map = ', map);
    return map;
}

export default function UserCopeLessonPanel(props) {
    let {id} = props;
    const [tab, setTab] = useState('exercises');
    const [selectedId, setSelectedId] = useState(undefined);
    const [subCopeMap, setSubCopeMap] = useState({});
    const [copeT, setCopeT] = useState(0);
    const [attendanceMap, setAttendanceMap] = useState();
    const [openedMap, setOpenedMap] = useState({});

    const {lesson, loading, currentUserId, currentUser, getExercise, getUnit} = useMappedState(useCallback(state => {
        let lesson = state.lessons.lessonsMap.get(id);
        let sc = (lesson == undefined) ? undefined : state.schools.schoolsMap.get(lesson.schoolId);
        return {
            lesson: state.lessons.lessonsMap.get(id),
            school: sc,
            loading: state.lessons.loading || state.smartExercises.loading || state.courses.loading,
            currentUserId: state.users.currentUserId,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            getExercise: exId => state.smartExercises.smartExercisesMap.get(exId),
            getUnit: exId => state.courses.unitsMap.get(exId),
        }
    }, [id]));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.loadLessonById(id)).then(pld => {
            if (pld != undefined && pld.lessons != undefined) {
                let l = pld.lessons[0];
                if (l != undefined) {
                    let tx = (l.exercisesIds != undefined && l.exercisesIds.length > 0) ? 'exercises' : (l.unitsIds != undefined && l.unitsIds.length > 0 ? 'units' : undefined);
                    setTab(tx);
                }
            }
            setTimeout(() => {
                setCopeT(+new Date());
            }, 200);
        });
        dispatch(smartExercisesActions.loadAllSmartExercises());
        dispatch(coursesActions.loadAllUnits());
    }, [id]);

    useEffect(() => {
        let key = `hw_not_vis_${id}`;
        let alreadyVisited = (ls(key) == 'yes');
        if (alreadyVisited == true) {
            return;
        }
        ls(key, 'yes');
        axios.post(`${API_ENDPOINT}/observing/stats/signal`, {
            type: 'LESSON_HOMEWORK_PAGE_VISIT',
            lessonId: id
        }).then(pld => {

        });
    }, [id]);

    let {exercisesIds = [], unitsIds = [], testsIds = []} = (lesson == undefined ? {} : lesson);

    useEffect(() => {
        let exercises = exercisesIds.map(exId => getExercise(exId)).filter(a => (a != undefined));
        let units = unitsIds.map(exId => getUnit(exId)).filter(a => (a != undefined));
        let leftItems = getSortedItems((tab == 'exercises') ? exercises : units);
        // let leftItems = (tab == 'exercises') ? exercisesIds : unitsIds;
        if (leftItems.length > 0) {
            setSelectedId(leftItems[0].id);
        }
    }, [tab, (lesson == undefined ? '' : lesson.id), exercisesIds.length, unitsIds.length, loading]);

    // useEffect(() => {
    //     if (selectedId == undefined) {
    //         return;
    //     }
    //     // SmartExercisesAPI.getUserExerciseResultsMap(id).then(mp => {
    //     SmartExercisesAPI.getUserExerciseResultsMap(selectedId).then(mp => {
    //         console.log('getUserExerciseResultsMap: pld mp = ', mp);
    //         setSubCopeMap(mp);
    //     });
    // }, [copeT, selectedId]);

    useEffect(() => {
        getExercisesOpenMap(exercisesIds).then(pld => {
            console.log('getExercisesOpenMap: pld = ', pld);
            setOpenedMap(pld);
        });
    }, [copeT]);


    if (loading == true) {
        return (
            <Code/>
        )
    }
    if (lesson == undefined) {
        return null;
    }

    // let {exercisesIds = [], unitsIds = [], testsIds = []} = lesson;
    let tabs = [];
    if (unitsIds.length > 0) {
        tabs = tabs.concat([
            {
                label: 'Теория',
                value: 'units'
            }
        ]);
    }
    if (exercisesIds.length > 0) {
        tabs = tabs.concat([
            {
                label: 'Упражнения',
                value: 'exercises'
            }
        ]);
    }

    let exercises = exercisesIds.map(exId => getExercise(exId)).filter(a => (a != undefined));
    let units = unitsIds.map(exId => getUnit(exId)).filter(a => (a != undefined));
    let leftItems = (tab == 'exercises') ? exercises : units;
    leftItems = getSortedItems(leftItems);

    let selectedUnit = (selectedId == undefined) ? undefined : units.filter(a => (a.id == selectedId))[0];
    let selectedExercise = (selectedId == undefined) ? undefined : exercises.filter(a => (a.id == selectedId))[0];

    console.log('selectedExercise = ', selectedExercise);

    return (
        <Wrapper>

            <Left>

                <LogoPlaceholder>
                    <Logo src={require('../../templates/images/top_logo_frame.svg')}/>
                </LogoPlaceholder>

                <TopLeftBackPlaceholder>
                    <TopLeftBackSpan onClick={() => {
                        // CommonHelper.linkTo(`/lesson/${id}/homework`);
                        CommonHelper.linkTo(`/homework`);
                    }}>
                        <img src={back_button} style={{marginRight: 5, height: 32}}/>
                        {translate('back')}
                    </TopLeftBackSpan>
                </TopLeftBackPlaceholder>

                <LessonNamePlaceholder>
                    {`${translate('lesson')} ${moment(lesson.startTimestamp).format('DD.MM.YYYY HH:mm')}`}
                </LessonNamePlaceholder>

                <LeftTabsPlaceholder>
                    {leftItems.map((a, i) => {
                        let val = openedMap[a.id];
                        let isUsed = (val != undefined && +val > 0);
                        let isWin = (val == 3);
                        let isLose = (val == 1);
                        return (
                            <LeftTabItem
                                selected={(a.id == selectedId)}
                                key={a.id}
                                onClick={() => {
                                    setSelectedId(a.id);
                                }}>
                                {`${+i + 1}. ${a.name}`}
                                {isUsed == false ? null :
                                    <GreenTick src={isWin ? green_tick : red_tick}/>
                                }
                            </LeftTabItem>
                        )
                    })}
                </LeftTabsPlaceholder>

            </Left>

            <Right>
                <TopRightNavPlaceholder>
                    {tabs.map((a, i) => {
                        return (
                            <TopTab key={`${a.value}_${i}`}
                                    selected={(a.value == tab)}
                                    onClick={() => {
                                        setTab(a.value);
                                    }}>
                                {a.label}
                            </TopTab>
                        )
                    })}
                </TopRightNavPlaceholder>

                <RightContent key={`${selectedId}_${tab}`}>

                    {/*{(selectedExercise == undefined || selectedExercise.isIllegal == true) ? null :*/}
                    {/*<SelectedExerciseSolutionsPlaceholder>*/}
                    {/*<ExerciseCopeViewTool {...selectedExercise} copeMap={subCopeMap}/>*/}
                    {/*</SelectedExerciseSolutionsPlaceholder>*/}
                    {/*}*/}

                    {selectedExercise == undefined ? null :

                        <React.Fragment>

                            {selectedExercise.isIllegal ?
                                <IllegalWorkspace {...selectedExercise}
                                                  boardWidthFraction={0.6}
                                                  onExerciseFinish={() => {

                                                  }}
                                                  onStepLimitReached={() => {

                                                  }}
                                                  onSubExerciseStart={() => {

                                                  }}
                                                  onTimeFinished={() => {

                                                  }}/>
                                :
                                <Workspace {...selectedExercise}
                                           allowHandleKeyEvents={() => {

                                           }}
                                           boardWidthFraction={0.6}
                                           onSubExerciseStart={(a, b, c) => {
                                               console.log('onSubExerciseStart: a, b, c = ', a, b, c);
                                           }}
                                           onSubExerciseEnd={async (a, b, c) => {
                                               let tsk = JSON.parse(JSON.stringify(a.task));
                                               let dd = {
                                                   [tsk.id]: {
                                                       result: tsk.result,
                                                       rating: tsk.rating,
                                                       duration: tsk.duration,
                                                       assessment: tsk.assessment
                                                   }
                                               };
                                               await SmartExercisesAPI.saveUserExerciseAnswer(selectedExercise.id, dd);
                                               setCopeT(+new Date());
                                           }}
                                           onExerciseFinish={(a, b, c) => {
                                               console.log('onExerciseFinish: a, b, c = ', a, b, c);
                                           }}
                                           onTimeFinished={(a, b, c) => {
                                               // console.log('onTimeFinished: a, b, c = ', a, b, c);
                                           }}
                                           onTimerTick={(a, b, c) => {
                                               // console.log('onTimerTick: a, b, c = ', a, b, c);
                                           }}/>
                            }

                        </React.Fragment>

                    }

                    {selectedUnit == undefined ? null :
                        <React.Fragment>
                            <UnitView items={selectedUnit.items} canEdit={false}/>
                        </React.Fragment>
                    }

                </RightContent>

            </Right>

        </Wrapper>
    );
}

const topHeight = 70;
const leftWidth = 300;

const LeftTabsPlaceholder = styled.div`

`;

const GreenTick = styled.img`
  display: inline-block;
  height: 12px;
  margin-left: 5px;
`;

const SelectedExerciseSolutionsPlaceholder = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LeftTabItem = styled.div`
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.617143px;
  color: #333333;
  font-weight: ${props => (props.selected == true ? 'bold' : 'normal')};
  background: ${props => (props.selected == true ? 'rgba(134, 209, 255, 0.2)' : 'white')};
  cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
  box-sizing: border-box;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #D5D5D5;
`;

const LessonNamePlaceholder = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 32px;
  color: #333333;
  margin-top: 10px;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
`;

const RightContent = styled.div`
  background: #FFFFFF;
  border: 1px solid rgba(8, 91, 255, 0.1);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 30px;
  height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
`;

const TopLeftBackPlaceholder = styled.div`
  padding-left: 20px;
`;

const TopLeftBackSpan = styled.span`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  opacity: 1;
`;

const TopRightNavPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
`;

const TopTab = styled.div`
  margin-left: 20px;
  margin-right: 20px;
  font-size: 19px;
  line-height: 23px;
  font-weight: ${props => (props.selected == true ? 'bold' : 'normal')};
  color: ${props => (props.selected == true ? 'black' : 'color: rgba(31, 32, 65, 0.5)')};
  cursor: pointer;
`;

const LogoPlaceholder = styled.div`
  width: 100%;
  height: ${topHeight}px;
  margin-bottom: 20px;
`;

const Logo = styled.img`
  width: 100%;
  height: 100%;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: #F4F4F6;
  position: fixed;
  z-index: 1;
  top: 0px;
  left: 0px;
`;

const Left = styled.div`
  width: ${leftWidth}px;
  height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
  display: inline-block;
  vertical-align: top;
  background: white;
  box-sizing: border-box;
`;

const Right = styled.div`
  width: calc(100vw - ${leftWidth}px);
  box-sizing: border-box;
  height: 100vh;
  display: inline-block;
  vertical-align: top;
  padding-left: 50px;
  padding-right: 50px;
`;

const FormPlaceholder = styled.div`

`;
