import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import UpdateArrowTool from './UpdateArrowTool'

import Chessboard from '../../rubius/chessboard/Chessboard'
import useComponentSize from "@rehooks/component-size/index";
import UpdateArrowsAndSquaresTool from "./UpdateArrowsAndSquaresTool";

export default function BoardWithArrowsExampleTool(props) {
    const {
        orientation = 'white'
    } = props;

    const [arrows, setArrows] = useState([]);
    const [squares, setSquares] = useState([]);

    const wrapperRef = useRef();
    const size = useComponentSize(wrapperRef);

    console.log('render: arrows = ', arrows);

    return (
        <Wrapper ref={wrapperRef}>

            <BoardPlaceholder>
                <Chessboard arrows={arrows}
                            highlightedSquares={squares}
                            {...size}
                            orientation={orientation}/>
            </BoardPlaceholder>
            {orientation == 'black' ? null :
                <ToolPlaceholder>
                    <UpdateArrowsAndSquaresTool arrows={arrows} squares={squares} onChange={d => {
                        console.log('---->>> UpdateArrowsAndSquaresTool: onChange: d = ', d);
                        setArrows(d.arrows);
                        setSquares(d.squares);
                    }}/>
                </ToolPlaceholder>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: relative;
`;

const BoardPlaceholder = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
`;

const ToolPlaceholder = styled.div`
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 2;
`;
