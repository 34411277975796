import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const CommentsAPI = {

    getAllComments() {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/comments/all`).then(d => d.data).then(comments => {
                if (comments.error != undefined) {
                    return reject(comments.error);
                }
                resolve(comments);
            }).catch(err => reject(err));
        });
    },

    getUserComments(userId) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/user/${userId}/comments`).then(d => d.data).then(comments => {
                if (comments.error != undefined) {
                    return reject(comments.error);
                }
                resolve(comments.result);
            }).catch(err => reject(err));
        });
    },

    getSubjectCommentsWithUsersMap(subjectId) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/comment_subject/${subjectId}/comments`).then(d => d.data).then(comments => {
                if (comments.error != undefined) {
                    return reject(comments.error);
                }
                resolve(comments.result);
            }).catch(err => reject(err));
        });
    },

    getComment(id) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/comment/${id}`).then(d => d.data).then(comments => {
                if (comments.error != undefined) {
                    return reject(comments.error);
                }
                resolve(comments);
            }).catch(err => reject(err));
        });
    },

    createComment(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/comment`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    updateComment(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/comment`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex.result);
            }).catch(err => reject(err));
        });
    },

    deleteComment(id) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/comment/delete`, {id: id}).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex.result);
            }).catch(err => reject(err));
        });
    },

}

export default CommentsAPI;
