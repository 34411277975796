import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import './style.css'

import * as exercisesActions from '../../../redux/actions/ExercisesActions'
import * as answersActions from '../../../redux/actions/AnswersActions'

import Sidebar from 'arui-feather/sidebar'

import {Button} from "../../ui/Button";
import ExerciseSolutionsForm from "../forms/ExerciseSolutionsForm";
import PlayExerciseTool from "../tools/PlayExerciseTool";

import {Map, Set} from 'immutable'
import SmartPlayExerciseTool from "../tools/SmartPlayExerciseTool";

const updAnsArr = (arr, solId, correct = true) => {
    if (arr.map(a => a.id).indexOf(solId) > -1) {
        arr = arr.map(a => (a.id == solId ? {
            id: solId,
            correct: correct
        } : a))
    } else {
        arr = arr.concat([
            {
                id: solId,
                correct: correct
            }
        ]);
    }
    return arr;
}

export default function UserExercisePanel(props) {
    const dispatch = useDispatch();
    let {id, userId} = props;
    // console.log('UserExercisePanel: render: id, userId = ', id, userId);
    let {currentUser, loading, userRole, exercise, user, answer} = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let userRole = (currentUser != undefined) ? currentUser.userRole : undefined;
        let u = (userId == undefined) ? undefined : state.users.usersMap.get(userId);
        let uId = (u == undefined) ? state.users.currentUserId : userId;
        let ans = state.answers.answersMap.toArray().filter(aa => (aa.userId == uId && aa.exerciseId == id));
        let answer = (ans.length == 0) ? undefined : ans[0];
        return {
            userRole: userRole,
            currentUser: currentUser,
            currentUserId: state.users.currentUserId,
            loading: state.exercises.loading,
            exercise: state.exercises.exercisesMap.get(props.id),
            user: (u == undefined) ? currentUser : u,
            answer: answer
        }
    }, []));

    useEffect(() => {
        // dispatch(exercisesActions.loadExerciseById(id)).then(pld => {
        //     console.log('exercise has been loaded');
        //     console.log('user = ', user);
        // });
        dispatch(exercisesActions.loadAllExercises()).then(pld => {
            console.log('exercise has been loaded');
            console.log('user = ', user);
        });

    }, []);

    useEffect(() => {
        if (user != undefined) {
            dispatch(answersActions.loadLazyAnswerByUserIdAndExerciseId(user.id, id)).then(pld => {
                console.log('answer loaded: pld = ', pld);
            });
        }
    }, [`user_${user == undefined ? '' : user.id}`]);

    // console.log('UserExercisePanel: render: user, exercise = ', user, exercise);

    if (currentUser == undefined) {
        return (
            <Wrapper>
                Access denied
            </Wrapper>
        )
    }

    if (loading == true && exercise == undefined) {
        return (
            <Code/>
        )
    }

    if (exercise == undefined || user == undefined) {
        return null;
    }

    let answers = (answer == undefined ? [] : (answer.answers == undefined ? [] : answer.answers));
    let userSolutionsMap = answers.reduce((mp, a) => mp.set(a.id, a), Map());


    return (
        <Wrapper>

            <Top>
                <Heading>
                    {exercise.name}
                </Heading>

                {exercise.description == undefined ? null :
                    <SubHeading>
                        {exercise.description}
                    </SubHeading>
                }
            </Top>
            
            <Content>
                {answer == undefined ?
                    <Code/> :
                    <>
                    <SmartPlayExerciseTool {...exercise}
                                           userSolutionsMap={userSolutionsMap}
                                           onCorrectAnswer={solId => {
                                               let newAnswers = updAnsArr(answers, solId, true);
                                               dispatch(answersActions.updateAnswer({
                                                   id: answer.id,
                                                   answers: newAnswers
                                               })).then(pld => {
                                                   console.log('answer updated!: pld = ', pld);
                                               });
                                           }}
                                           onWrongAnswer={solId => {
                                               let newAnswers = updAnsArr(answers, solId, false);
                                               console.log('onWrongAnswer: newAnswers = ', newAnswers);
                                               dispatch(answersActions.updateAnswer({
                                                   id: answer.id,
                                                   answers: newAnswers
                                               })).then(pld => {
                                                   console.log('answer updated!: pld = ', pld);
                                               });
                                           }}/>

                    </>
                }

            </Content>

        </Wrapper>
    );
}


const Heading = styled.div`
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 24px;
`;

const SubHeading = styled.div`
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 24px;
`;

const Wrapper = styled.div`
    background: white;
    padding: 10px;
    border-radius: 4px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    box-sizing: border-box;
`;

const Top = styled.div`
    
`;

const Content = styled.div`
    flex: 1;
`;
