import React from 'react';
import styled from 'styled-components';

const ie = x => (x == undefined || x.trim() == '');

export default function FinalExerciseDialogView(props) {
    const {videoUrl, imageUrl, audioUrl, text, html} = props;

    const hasImage = (ie(imageUrl) == false);
    const hasVideo = (ie(videoUrl) == false);
    const hasAudio = (ie(audioUrl) == false);
    const hasText = (ie(text) == false);
    const hasHtml = (ie(html) == false);

    let hasNothing = (!hasImage && !hasVideo && !hasAudio && !hasText && !hasHtml);

    if (hasNothing == true) {
        return null;
    }

    return (
        <Wrapper>

            {hasVideo == false ? null :
                <VideoPlaceholder>
                    <Video controls={true}>
                        <source src={videoUrl}/>
                    </Video>
                </VideoPlaceholder>
            }

            {hasImage == false ? null :
                <ImagePlaceholder>
                    <Image src={imageUrl}/>
                </ImagePlaceholder>
            }

            {hasAudio == false ? null :
                <VideoPlaceholder>
                    <Audio controls={false} autoPlay={true}>
                        <source src={audioUrl}/>
                    </Audio>
                </VideoPlaceholder>
            }

            {hasText == false ? null :
                <TextPlaceholder>
                    <Text>
                        {text}
                    </Text>
                </TextPlaceholder>
            }

            {hasHtml == false ? null :
                <HtmlPlaceholder>
                    <div dangerouslySetInnerHTML={{__html: html}}></div>
                </HtmlPlaceholder>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
    padding: 20px;
    text-align: center;
    margin: 0 auto;
    width: 720px;
    border-radius: 4px;
    border: 1px solid lightgrey;
    background: whitesmoke;
    @media(max-width: 900px){
      width: 100%;
    }
`;

const VideoPlaceholder = styled.div`
    width: 100%;
    height: 300px;
    background-color: whitesmoke;
    box-sizing: border-box;
`;

const Video = styled.video`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
`;

const ImagePlaceholder = styled.div`
    text-align: center;
    padding: 5px;
    background-color: whitesmoke;
    border-radius: 4px;
`;

const Image = styled.img`
    height: 120px;
    display: inline-block;  
`;

const Audio = styled.audio`
    
`;

const TextPlaceholder = styled.div`
    text-align: center;
`;

const Text = styled.div`
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
`;

const HtmlPlaceholder = styled.div`
    
`;
