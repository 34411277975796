import axios from 'axios'

const ENDPOINT = 'https://recorder-master-api.shagi.online';

const RecordingsAPI = {

    async getLessonRecordings(lessonId) {
        let pld = (await axios.get(`${ENDPOINT}/sessions/${lessonId}/recordings`)).data.result;
        return pld;
    },


    async getManyRecordings(ids = []) {
        let pld = (await axios.post(`${ENDPOINT}/sessions/get-many-recordings`, {
            ids: ids
        })).data.result;
        return pld;
    },

    async stopRecording(lessonId) {
        let pld = (await axios.post(`${ENDPOINT}/recordings/stop`, {
            sessionId: lessonId
        }));
    },

    async startRecording(lessonId) {
        try {
            await axios.post(`${ENDPOINT}/recordings`, {
                sessionId: lessonId
            });
        } catch (exc) {

        }
    },


}

export default RecordingsAPI;
