import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import {PIECES_MAP} from "../../../constants/config";

export default function InlinePiece(props) {
    const {size = 20, piece = undefined} = props;
    if (piece == undefined){
        return null;
    }
    let p = PIECES_MAP[piece];
    if (p == undefined){
        return null;
    }
    const pieceSize = 0.9 * size;

    return (
        <Wrapper style={{width: size, height: size}}>
            <PieceImg style={{width: pieceSize, height: pieceSize}} src={p.smartIcon} />
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
`;

const PieceImg = styled.img`
    
`;
