import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const TeacherTeacherGroupsAPI = {

    getAllTeacherGroups() {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/teacher_groups/all`).then(d => d.data).then(exercises => {
                if (exercises.error != undefined) {
                    return reject(exercises.error);
                }
                resolve(exercises);
            }).catch(err => reject(err));
        });
    },

    getAllTeacherGroupsOfTeacher(teacherId) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/teacher_groups/teacher/${teacherId}`).then(d => d.data).then(exercises => {
                if (exercises.error != undefined) {
                    return reject(exercises.error);
                }
                resolve(exercises);
            }).catch(err => reject(err));
        });
    },

    getAllTeacherGroupsOfStudent(studentId) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/teacher_groups/student/${studentId}`).then(d => d.data).then(exercises => {
                if (exercises.error != undefined) {
                    return reject(exercises.error);
                }
                resolve(exercises);
            }).catch(err => reject(err));
        });
    },

    createTeacherGroup(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/teacher_groups/smart_create`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    updateTeacherGroup(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/teacher_groups/update`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    deleteTeacherGroup(id) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/teacher_groups/delete/${id}`).then(d => d.data).then(() => {
                resolve(id);
            }).catch(err => reject(err));
        });
    },

    getTeacherGroupById(id) {
        console.log('!!getTeacherGroupById occured! id = ', id);
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/teacher_group/${id}`).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    toggleStudentMembership(groupId, studentId) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/teacher_groups/toggle_membership/${groupId}/${studentId}`).then(d => d.data).then(group => {
                if (group.error != undefined) {
                    return reject(group.error)
                }
                resolve(group);
            }).catch(err => reject(err));
        });
    }

}

export default TeacherTeacherGroupsAPI;
