import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import selImg from '../../../assets/images/blue_radio_checked.svg';
import voidRadioImg from '../../../assets/images/void_radio.svg';

const TOOLS = [
    {
        label: 'Jitsi',
        value: 'jitsi'
    },
    {
        label: 'OpenVidu',
        value: 'openvidu'
    }
];


export default function CommunicationToolSwitcher(props) {
    const {
        communicationTool = 'jitsi', onChange = newTool => {

        }
    } = props;

    return (
        <Wrapper>
            {TOOLS.map((tool, i) => {
                let isSelected = (tool.value == communicationTool);
                return (
                    <ToolItem key={i} selected={isSelected} onClick={() => {
                        onChange(tool.value);
                    }}>
                        <RadioImg src={(isSelected == true) ? selImg : voidRadioImg}/>
                        <LabelSpan>
                            {tool.label}
                        </LabelSpan>
                    </ToolItem>
                )
            })}
        </Wrapper>
    );
}

const LabelSpan = styled.div`
    
`;

const RadioImg = styled.img`
    margin-right: 7px;
    height: 18px;
    width: 18px;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;  
    margin-bottom: 10px;  
`;

const ToolItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 20px;
    cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
`;
