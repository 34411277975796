import React, {useState, useRef} from 'react';
import styled from 'styled-components';
import Chessboard from '../../chessboard/Chessboard';
import FinishWindow from '../FinishWindow/FinishWindow';


import './Workspace.css';
import useComponentSize from "@rehooks/component-size";

let stepNumber = 0;
export const getOptimalBoardWidth = (size, fraction) => {
    let {height, width} = size;
    //console.log('getOptimalBoardWidth: height, width, fraction = ', height, width, fraction);

    if (height == undefined || height == 0) {
        return width * fraction;
    } else {
        return Math.min(size.height, size.width * fraction);
    }
}

export default function IllegalWorkspace(props) {
    const {
        boardWidthFraction = 0.4,
        shouldShowWindowOnFinish = false,
        fen = '',
        stepLimit,
        onStepLimitReached = e => e,
        onExerciseFinish = e => e,
        onSubExerciseStart = () => {
        },
    } = props;

    const wrapperRef = useRef();
    const wrapperSize = useComponentSize(wrapperRef);
    //console.log('IllegalWorkspace: wrapperSize = ', wrapperSize);

    const [visibleFinishWindow, setVisibleFinishWindow] = useState(false);

    const stepLimitCheck = () => {
        stepNumber++;

        if (stepLimit === stepNumber) {
            onStepLimitReached({stepNumber});
        }
    };
    const hideFinishWindow = () => {
        setVisibleFinishWindow(false);
    };
    const showFinishWindow = () => {
        setVisibleFinishWindow(true);
    };

    const onCapture = allAimsTaken => {
        if (allAimsTaken) {
            onExerciseFinish({allAimsTaken, stepNumber});
            showFinishWindow();
        }
    };

    const onUserMove = () => {
        if (!stepNumber) {
            onSubExerciseStart();
        }
        stepLimitCheck();
    };
    const boardWidth = getOptimalBoardWidth(wrapperSize, boardWidthFraction);
    let isCollapsed = (wrapperSize.width == 0);

    return (
        <Wrapper ref={wrapperRef}>
            {isCollapsed ? null :
                <>
                <Left width={boardWidth}>
                    <Chessboard fen={fen}
                                isIllegal={true}
                                width={boardWidth}
                                onMove={onUserMove}
                                onCapture={onCapture}/>
                </Left>
                {shouldShowWindowOnFinish &&
                <Right>
                    <FinishWindow visible={visibleFinishWindow} hide={hideFinishWindow} data={props}/>
                </Right>
                }

                </>
            }

        </Wrapper>
    );
}


const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
`;

const Left = styled.div`
    width: ${props => props.width}px;
`;

const Right = styled.div`
    flex: 1;
`;
