import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

import Select from 'react-select'
import {Set} from 'immutable'

export default function SimpleTagsSelector(props) {
    const {
        allTags = [], tags = [], onChange = x => {

        }
    } = props;

    console.log('SimpleTagsSelector: allTags = ', allTags);
    let mainTags = allTags.filter(x => x.isMain);
    let restTags = allTags.filter(x => (x.isMain != true));

    let restTagsOptions = restTags.map(x => ({label: x.name, value: x.name}));
    let restTagsSelectedOptions = restTagsOptions.filter(x => (tags.indexOf(x.value) > -1));

    return (
        <Wrapper>

            <TopPlaceholder>
                {mainTags.map((tg, i) => {
                    let isSelected = tags.indexOf(tg.name) > -1;
                    return (
                        <TagItem isMain={tg.isMain}
                                 selected={isSelected} key={`${tg.name}_${i}`}
                                 onClick={() => {
                                     let updTags = (isSelected == true) ? tags.filter(x => (x != tg.name)) : tags.concat([tg.name]);
                                     console.log('before upd: updTags = ', updTags);
                                     onChange(updTags);
                                 }}>
                            {tg.name}
                        </TagItem>
                    )
                })}
            </TopPlaceholder>

            <ClassicSelectorPlaceholder>
                <Select options={restTagsOptions}
                        placeholder={'Выберите теги'}
                        isMulti={true} value={restTagsSelectedOptions}
                        onChange={x => {
                            console.log('onChange: x = ', x);
                            let mainTagsNames = mainTags.map(aa => aa.name);
                            let updTags = tags.filter(a => (mainTagsNames.indexOf(a) > -1)).concat(x.map(a => a.value)).reduce((st, tt) => st.add(tt), Set()).toArray();
                            // onChange(x.map(a => a.value));
                            onChange(updTags);
                        }}/>
            </ClassicSelectorPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const TopPlaceholder = styled.div`

`;

const ClassicSelectorPlaceholder = styled.div`

`;

const TagItem = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 15px;
  padding-right: 15px;

  font-weight: ${props => (props.isMain == true ? 'bold' : 'normal')};
  border-radius: ${props => (props.isMain == true ? '4px' : '0px')};


  border: ${props => (props.isMain == true ? '2px solid grey' : '1px solid grey')};
  background: ${props => (props.selected == true ? 'rgb(255, 167, 27)' : 'transparent')};
  color: black;
  cursor: pointer;

  :first-of-type {
    margin-left: 0px;
  }

  :last-of-type {
    margin-right: 0px;
  }
`;
