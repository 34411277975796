import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import UiAPI from "../../../../api/UiAPI";

import Tabs, {TabItem} from "../../../ui/Tabs";
import SimpleTexturesView from "../tools/SimpleTexturesView";
import SimpleFiguresView from "../tools/SimpleFiguresView";

import * as usersActions from '../../../../redux/actions/UsersActions'
import SimpleBoardViewTool from "../tools/SimpleBoardViewTool";

export default function UpdateUserBoardSettingsPanel(props) {
    const {userId} = props;

    const dispatch = useDispatch();
    let {currentUser, loading, user, uId} = useMappedState(useCallback(state => {
        let uId = (userId == undefined) ? state.users.currentUserId : userId;
        let user = state.users.usersMap.get(uId);
        return {
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            loading: state.users.loading,
            user: user,
            uId: uId
        };
    }, []));

    const [boards, setBoards] = useState([]);

    useEffect(() => {
        UiAPI.getAllBoards().then(arr => {
            setBoards(arr);
        })
    }, []);

    if (user == undefined) {
        return null;
    }
    let {boardSettings = {}} = user;

    return (
        <Wrapper>

            <Half>
                <BoardPlaceholder>
                    <SimpleBoardViewTool {...boardSettings} data={boardSettings} />
                </BoardPlaceholder>
            </Half>

            <Half>
                <Tabs tabs={[
                    {
                        label: 'Клетки',
                        content: (
                            <TabItem>
                                <BoardsItems>
                                    {boards.filter(a => (a.type == 'squares')).map((a, j) => {
                                        let selected = (boardSettings.whiteTextureUrl == a.whiteTextureUrl);
                                        return (
                                            <Item selected={selected} key={a.id} onClick={async () => {
                                                let newBoardSettings = {
                                                    ...boardSettings,
                                                    whiteTextureUrl: a.whiteTextureUrl,
                                                    blackTextureUrl: a.blackTextureUrl
                                                };
                                                await dispatch(usersActions.updateUser({
                                                    id: uId,
                                                    boardSettings: newBoardSettings
                                                }));
                                            }}>
                                                <SimpleTexturesView {...a} />
                                            </Item>
                                        )
                                    })}
                                </BoardsItems>
                            </TabItem>
                        )
                    },
                    {
                        label: 'Фигуры',
                        content: (
                            <TabItem>
                                <BoardsItems>
                                    {boards.filter(a => (a.type == 'figures')).map((a, j) => {
                                        let selected = (boardSettings.r == a.r);
                                        return (
                                            <Item selected={selected} key={a.id} onClick={async () => {
                                                let newBoardSettings = {
                                                    ...boardSettings,
                                                    ...a
                                                };
                                                await dispatch(usersActions.updateUser({
                                                    id: uId,
                                                    boardSettings: newBoardSettings
                                                }));
                                            }}>
                                                <SimpleFiguresView {...a} />
                                            </Item>
                                        )
                                    })}
                                </BoardsItems>
                            </TabItem>
                        )
                    },
                ]}/>
            </Half>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`;

const BoardPlaceholder = styled.div`
    width: 400px;
    height: 400px;
`;

const Half = styled.div`
    flex: 1;
    box-sizing: border-box;
    padding: 5px;
`;

const BoardsItems = styled.div`
    padding: 10px;
`;

const BoardItem = styled.div`
    padding: 10px;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 10px;
    border: ${props => (props.selected == true ? '2px solid blue' : '2px dashed lightgrey')};
    cursor: pointer;
    :hover{
      border: 2px solid blue;
      background: whitesmoke;
    }
`;

const Item = styled.div`
    border: ${props => (props.selected == true ? '2px solid blue' : '2px dashed lightgrey')};
    padding: 5px;
    cursor: pointer;
    margin-bottom: 10px;
    :hover{
      opacity: 0.7;
    }
`;
