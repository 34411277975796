import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import LoginForm from "../auth/forms/LoginForm";
import {LOGIN_BACKGROUNR_IMAGE} from "../../constants/config";

import * as usersActions from '../../redux/actions/UsersActions'
import {useDispatch, useMappedState} from "redux-react-hook";

export default function LoginApp(props) {

    const mapState = useCallback(
        state => ({
            initialized: state.users.initialized,
            loading: state.users.loading,
            currentUser: state.users.currentUserId == undefined ? null : state.users.usersMap.get(state.users.currentUserId)
        }), []
    );
    const {initialized, currentUser, loading} = useMappedState(mapState);
    const dispatch = useDispatch();

    return (
        <Wrapper>
            <Inner>
                <LoginFormPlaceholder>

                    <CenterPlaceholder>
                        <FormPlaceholder>

                            <Heading>
                                Вход в систему
                            </Heading>

                            <LoginForm loading={loading} onSubmit={data => {
                                console.log('data = ', data);
                                dispatch(usersActions.logIn(data)).then(pld => {
                                    if (pld.error != undefined) {
                                        if (pld.error.code == 101) {
                                            return window.alert('Неверный логин или пароль');
                                        }
                                    }
                                    window.location.reload();
                                })
                            }}/>

                        </FormPlaceholder>
                    </CenterPlaceholder>

                </LoginFormPlaceholder>
            </Inner>
        </Wrapper>
    );
}


const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 1;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
`;


const Inner = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${LOGIN_BACKGROUNR_IMAGE});
`;

const LoginFormPlaceholder = styled.div`
    text-align: center;
    background: white;
    border-radius: 4px;
`;

const Heading = styled.div`
    font-size: 24px;
    line-height: 28px;
    font-weight: bold;
    padding: 20px;
    padding-bottom: 0px;
`;

const centerWidth = 320;

const CenterPlaceholder = styled.div`
    width: ${centerWidth}px;
    @media(max-width: ${centerWidth}px){
      width: 100%;
    }
`;

const FormPlaceholder = styled.div`
    text-align: left;
`;
