/**
 * Created by sabir on 19.07.17.
 */

import {Map, Stack, Set} from 'immutable'
import * as types from '../ActionTypes.js'

const initialState = {
    loading: false,

    exercisesMap: Map(),
    error: undefined

}

const startLoading = (state, action) => {
    return { ...state, loading: true, error: undefined}
}

const stopLoading = (state, action) => {
    return { ...state, loading: false, error: action.error}
}

const ExercisesReducer =  (state = initialState, action = {}) => {

    switch (action.type) {


        case types.CREATE_EXERCISE:
        case types.UPDATE_EXERCISE:
        case types.DELETE_EXERCISE:
        case types.LOAD_EXERCISES:
            return {
                ...state,
                loading: true,
                error: undefined
            }

        case types.CREATE_EXERCISE_FAIL:
        case types.UPDATE_EXERCISE_FAIL:
        case types.DELETE_EXERCISE_FAIL:
        case types.LOAD_EXERCISES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case types.CREATE_EXERCISE_SUCCESS:
        case types.UPDATE_EXERCISE_SUCCESS:
            return {
                ...state,
                loading: false,
                exercisesMap: state.exercisesMap.set(action.exercise.id, action.exercise)
            }


        case types.DELETE_EXERCISE_SUCCESS:
            return {
                ...state,
                loading: false,
                exercisesMap: state.exercisesMap.delete(action.id),
            }



        case types.LOAD_EXERCISES_SUCCESS:
            return {
                ...state,
                loading: false,
                exercisesMap: state.exercisesMap
                    .merge(action.exercises.reduce((map, form) => map.set(form.id, form), Map()))
            }


        default:
            return state;
    }

}

export default ExercisesReducer;
