import SmartExercisesAPI from '../../api/SmartExercisesAPI'

import * as types from '../ActionTypes'

let loadSmartExercises_ = () => {
    return {
        type: types.LOAD_SMART_EXERCISES
    }
}
let loadSmartExercisesSuccess = (smartExercises) => {
    return {
        type: types.LOAD_SMART_EXERCISES_SUCCESS,
        smartExercises: smartExercises
    }
}
let loadSmartExercisesFailed = (error) => {
    return {
        type: types.LOAD_SMART_EXERCISES_FAIL,
        error: error
    }
}



//thunk
export function loadAllSmartExercises() {
    return (dispatch, getState) => {
        dispatch(loadSmartExercises_());
        return SmartExercisesAPI.getAllSmartExercises().then(
            smartExercises => dispatch(loadSmartExercisesSuccess(smartExercises)),
            error => dispatch(loadSmartExercisesFailed(error))
        )
    }
}

export function loadSmartExerciseById(id) {
    return (dispatch, getState) => {
        dispatch(loadSmartExercises_());
        return SmartExercisesAPI.getSmartExercise(id).then(
            smartExercise => dispatch(loadSmartExercisesSuccess([smartExercise])),
            error => dispatch(loadSmartExercisesFailed(error))
        )
    }
}

let createSmartExercise_ = () => {
    return {
        type: types.CREATE_SMART_EXERCISE
    }
}
let createSmartExerciseSuccess = (smartExercise) => {
    return {
        type: types.CREATE_SMART_EXERCISE_SUCCESS,
        smartExercise: smartExercise
    }
}
let createSmartExerciseFailed = (error) => {
    return {
        type: types.CREATE_SMART_EXERCISE_FAIL,
        error: error
    }
}

//thunk
export function createSmartExercise(data) {
    return (dispatch, getState) => {
        dispatch(createSmartExercise_());
        return SmartExercisesAPI.createSmartExercise(data).then(
            smartExercise => dispatch(createSmartExerciseSuccess(smartExercise)),
            error => dispatch(createSmartExerciseFailed(error))
        )
    }
}

let updateSmartExercise_ = () => {
    return {
        type: types.UPDATE_SMART_EXERCISE
    }
}
let updateSmartExerciseSuccess = (smartExercise) => {
    return {
        type: types.UPDATE_SMART_EXERCISE_SUCCESS,
        smartExercise: smartExercise
    }
}
let updateSmartExerciseFailed = (error) => {
    return {
        type: types.UPDATE_SMART_EXERCISE_FAIL,
        error: error
    }
}

//thunk
export function updateSmartExercise(data) {
    return (dispatch, getState) => {
        dispatch(updateSmartExercise_());
        return SmartExercisesAPI.updateSmartExercise(data).then(
            smartExercise => dispatch(updateSmartExerciseSuccess(smartExercise)),
            error => dispatch(updateSmartExerciseFailed(error))
        )
    }
}

let deleteSmartExercise_ = () => {
    return {
        type: types.DELETE_SMART_EXERCISE
    }
}
let deleteSmartExerciseSuccess = (id) => {
    return {
        type: types.DELETE_SMART_EXERCISE_SUCCESS,
        id: id
    }
}
let deleteSmartExerciseFailed = (error) => {
    return {
        type: types.DELETE_SMART_EXERCISE_FAIL,
        error: error
    }
}

//thunk
export function deleteSmartExercise(id) {
    return (dispatch, getState) => {
        dispatch(deleteSmartExercise_());
        return SmartExercisesAPI.deleteSmartExercise(id).then(
            () => dispatch(deleteSmartExerciseSuccess(id)),
            error => dispatch(deleteSmartExerciseFailed(error))
        )
    }
}
