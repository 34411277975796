import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";


const UiAPI = {

    async getAllBoards() {
        let pld = (await axios.get(`${API_ENDPOINT}/ui/board/items`)).data.result;
        return pld;
    },

    async createBoard(data) {
        let pld = (await axios.post(`${API_ENDPOINT}/ui/board/items`, data)).data.result;
        return pld;
    },

    async updateBoard(data) {
        let pld = (await axios.post(`${API_ENDPOINT}/ui/board/items`, data)).data.result;
        return pld;
    },

    async deleteBoard(id) {
        let pld = (await axios.post(`${API_ENDPOINT}/ui/board/delete`, {id: id})).data.result;
        return pld;
    }

}

export default UiAPI;
