import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import CommonHelper from "../../../../helpers/CommonHelper";

let ie = a => (a == undefined || a.trim() == '');

export default function SimpleUserView(props) {
    let {user} = props;
    if (user == undefined) {
        return null;
    }
    let {avatar, firstName, lastName, email} = user;
    let uName = CommonHelper.getUserName(user);
    let shortAcronym = (ie(firstName) || ie(lastName)) ? email.toUpperCase().slice(0, 2) : `${firstName[0]}${lastName[0]}`.toUpperCase();


    return (
        <Wrapper>

            <Ava src={avatar}>
                {avatar == undefined ? shortAcronym : ''}
            </Ava>

            <Name>
                {uName}
            </Name>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Ava = styled.div`
    border-radius: 1000px;
    width: 64px;
    height: 64px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #939598;
    background-image: ${props => (props.src == undefined ? 'none' : `url(${props.src})`)};
    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 10px 20px rgba(31, 32, 65, 0.1);
    margin-right: 21px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #FFA71B;
    font-weight: bold;
`;

const Name = styled.div`
    font-weight: bold;
    font-size: 19px;
    line-height: 23px;
    color: #1F2041;
`;
