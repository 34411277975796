import {Map, Set} from 'immutable'
// import chesslib from 'chesslib'
import FENBoard from 'fen-chess-board'
import axios from 'axios'
import {API_ENDPOINT, PIECES_MAP} from "../constants/config";

import Chess from 'chess.js'
import moment from 'moment'
import ls from "local-storage";

export const DEFAULT_LINEUP = ["R@a1", "P@a2", "p@a7", "r@a8", "N@b1", "P@b2", "p@b7", "n@b8", "B@c1", "P@c2", "p@c7", "b@c8", "Q@d1", "P@d2", "p@d7", "q@d8", "K@e1", "P@e2", "p@e7", "k@e8", "B@f1", "P@f2", "p@f7", "b@f8", "N@g1", "P@g2", "p@g7", "n@g8", "R@h1", "P@h2", "p@h7", "r@h8"];

const maxFigureNumbersMap = DEFAULT_LINEUP.reduce((mp, p) => {
    let f = p.split('@')[0];
    return mp.set(f, DEFAULT_LINEUP.filter(a => (a.split('@')[0] == f)).length);
}, Map());

const START_FEN = 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1';

export const ALL_FIGURES = DEFAULT_LINEUP.reduce((st, f) => st.add(f.split('@')[0]), Set()).toArray().sort((a, b) => ((a > b) ? 1 : (a < b ? -1 : 0)));

export const SOLUTIONS_TYPES_OPTIONS = [
    {
        label: 'Правильное решение',
        value: 'win'
    },
    {
        label: 'Ошибка',
        value: 'lose'
    },
    {
        label: 'Не лучший ход',
        value: 'draw'
    }
];

const ChessHelper = {

    getAllFieldPositions() {
        let letters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h'];
        let numbers = [3, 4, 5, 6, 7, 8, 1, 2];
        let arr = [];
        for (let i in letters) {
            let l = letters[i];
            for (let j in numbers) {
                let num = numbers[j];
                arr.push(`${l}${num}`);
            }
        }
        return arr;
    },

    getDefaultPositionForNewFigure(currentLineup, figure) {
        let currentFigureNumbersMap = currentLineup.reduce((mp, p) => {
            let f = p.split('@')[0];
            return mp.set(f, currentLineup.filter(a => (a.split('@')[0] == f)).length);
        }, Map());
        let curNum = currentFigureNumbersMap.get(figure);
        let maxNum = maxFigureNumbersMap.get(figure);
        // if (curNum >= maxNum) {
        //     return undefined;
        // }
        // console.log('getDefaultPositionForNewFigure: currentLineup = ', currentLineup);
        let aLi = currentLineup.map(al => al.split('@')[1]); // busy fields
        let freePositions = this.getAllFieldPositions().filter(pos => (aLi.indexOf(pos) == -1));
        let possibleLineup = DEFAULT_LINEUP.filter(a => (a.split('@')[0] == figure));
        for (let i in possibleLineup) {
            let ln = possibleLineup[i];
            if (aLi.indexOf(ln.split('@')[1]) == -1) {
                return ln;
            }
        }
        return `${figure}@${freePositions[0]}`;
    },

    getFiguresThatYouCanAdd(currentLineup) {
        return ALL_FIGURES.filter(f => (this.getDefaultPositionForNewFigure(currentLineup, f) != undefined));
    },

    getFENBoardInstance() {
        if (this.fenBoard == undefined) {
            this.fenBoard = new FENBoard();
        }
        return this.fenBoard;
    },

    parseFEN(fenSource = '8/5N2/6p1/3P3k/1n6/8/8/7K w - - 0 1') {
        // return chesslib.FEN.parse(fenSource);
        let fenBoard = this.getFENBoardInstance();
        fenBoard.fen = fenSource;
        let brd = fenBoard.board;
        console.log('brd = ', brd);
        return JSON.parse(JSON.stringify(brd));
    },

    fenToPieces(fenSource = '8/5N2/6p1/3P3k/1n6/8/8/7K w - - 0 1') {
        // console.log('fenToPieces: fenSource = ', fenSource);
        let fenBoard = this.getFENBoardInstance();
        fenBoard.fen = fenSource;
        // let board = fenBoard.board[0];
        let board = fenBoard.board;
        let arr = [];
        // console.log('board = ', board);
        const alph = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h'];
        for (let i in board) {
            let list = board[i];
            // console.log('list = ', list);
            let k = 8 - i;
            for (let j in list) {
                let a = alph[j];
                let pos = `${a}${k}`;
                let figure = list[j];
                // console.log('checking figure = ', figure);
                if (figure == '') {
                    continue;
                }
                arr.push(`${figure}@${pos}`);
            }
        }
        return arr;
    },

    // pgnToPieces(pgnString){
    //     let ch = this.getHelperChess();
    //     ch.load_pgn(pgnString);
    //     let newFen = ch.fen();
    //     return this.fenToPieces(newFen);
    // },

    getPiecesByFenAndMoves(fen, moves) {
        let newFen = this.getFenByInitialFenAndMoves(fen, moves);
        return this.fenToPieces(newFen);
    },

    getFenByInitialFenAndMoves(fen, moves) {
        console.log('getFenByInitialFenAndMoves: fen, moves = ', fen, moves);
        let ch = new Chess(fen);
        for (let i in moves) {
            ch.move(moves[i].move)
        }
        let res = ch.fen();
        console.log('returning ', res);
        return res;
    },

    getHistoryByInitialFenAndMoves(fen, moves) {
        console.log('getHistoryByInitialFenAndMoves: fen, moves = ', fen, moves);
        let ch = new Chess(fen);
        for (let i in moves) {
            ch.move(moves[i].move)
        }
        let res = ch.history({verbose: true});
        console.log('returning ', res);
        return res;
    },

    getChessObjectByFenAndMoves(fen, moves) {
        let ch = new Chess(fen);
        for (let i in moves) {
            let mv = (moves[i].move == undefined ? moves[i].san : moves[i].move);
            ch.move(mv)
        }
        return ch;
    },

    piecesToFen(pieces) {
        let ch = this.getHelperChess();
        for (let i in pieces) {
            let p = pieces[i];
            let pos = p.split('@')[1];
            let fig = p.split('@')[0];
            ch.put(PIECES_MAP[fig].chessDefinition, pos);
        }
        let fen = ch.fen();
        ch.clear();
        return fen;
    },

    chessboardJsxPositionToFen(position) {
        let ch = this.getHelperChess();
        for (let key in position) {
            let a = position[key];
            let color = a[0];
            let type = a[1].toLowerCase();
            ch.put({type: type, color: color}, key);
        }
        let fen = ch.fen();
        ch.clear();
        return fen;
    },

    getPossibleMoves(pieces, selectedSquare) {
        if (selectedSquare == undefined) {
            return [];
        }
        let ch = this.getHelperChess();
        for (let i in pieces) {
            let p = pieces[i];
            let pos = p.split('@')[1];
            let fig = p.split('@')[0];
            ch.put(PIECES_MAP[fig].chessDefinition, pos);
        }
        const moves = ch.moves({verbose: true, square: selectedSquare});
        return moves;
    },

    getHelperChess(fen = undefined) {
        if (this.helperChess == undefined) {
            this.helperChess = new Chess();
        }
        this.helperChess.clear();
        return this.helperChess;
    },

    parsePgnString(s) {
        // s = s.replace('{[#]}', '');
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/pgn/parse`, {content: s}).then(d => d.data).then(pld => {
                resolve(pld)
            }).catch(err => reject(err));
        });
    },

    transformParsedPGNToMoves(pgnMoves) {
        const moves = pgnMoves.map((pgnMove, k) => {
            let {move_number, move, ravs = []} = pgnMove;
            if (move_number == undefined) {
                if (k > 0) {
                    move_number = pgnMoves[+k - 1].move_number;
                }
            }
            const children = ravs;

        })
    },

    getNagSymbolByCode(code) {
        const map = {
            '$1': '!',
            '$2': '?',
            '$3': '‼',
            '$4': '⁇',
            '$5': '⁉',
            '$6': '⁈',
            '$7': '□',

            '$10': '=',

            '$13': '∞',
            '$14': '⩲',
            '$15': '⩱',
            '$16': '±',
            '$17': '∓',
            '$18': '+−',
            '$19': '−+',

            '$22': '⨀',
            '$23': '⨀',

            '$32': '⟳',
            '$33': '⟳',

            '$36': '→',
            '$37': '→',

            '$40': '↑',
            '$41': '↑',

            '$132': '⇆',
            '$133': '⇆',

            '140': '∆',
            '142': '⌓',
            '145': 'RR',
            '146': 'N',

            '239': '⇔',
            '240': '⇗',
            '242': '⟫',
            '243': '⟪',
            '244': '✕',
            '245': '⊥',
        };
        let s = map[code];
        if (s == undefined || s == '') {
            return undefined;
        }
        return s;
    },

    getYoutubeVideoId(url) {
        let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        let match = url.match(regExp);
        return (match && match[7].length == 11) ? match[7] : false;
    },

    getYoutubeEmbedUrl(youtubeUrl) {
        let vId = this.getYoutubeVideoId(youtubeUrl);
        // return `https://www.youtube.com/embed/${vId}?mode=opaque&rel=0&autohide=1&showinfo=0&wmode=transparent`
        return `https://www.youtube-nocookie.com/embed/${vId}?mode=opaque&rel=0&autohide=1&showinfo=0&wmode=transparent`
    },

    getSymbolsStringByNagsArray(nags) {
        // console.log('getSymbolsStringByNagsArray: nags = ', nags);
        if (nags == undefined || nags.length == 0) {
            return undefined;
        }
        let s = nags.map(a => (this.getNagSymbolByCode(a))).filter(a => (a != undefined)).join(' ');
        if (s == '') {
            return undefined;
        }
        return s;
    },

    getFenImageUrl(fen) {
        if (fen == undefined) {
            return undefined;
        }
        // const fenUrl = `http://www.fen-to-image.com/image/36/${fen.split(' ')[0]}`;
        const fenUrl = `https://api-cloud1.shagi.online/ch/image/36/${fen.split(' ')[0]}`;
        return fenUrl;
    },

    getFigureStringByMoveString(moveString) {
        if (moveString == undefined) {
            return undefined;
        }
        let s = moveString.slice(0, moveString.length - 2);
        s = s.replace('x', '').trim();
        if (s.length > 0) {
            s = s.slice(0, 1);
        }
        const possibleFigures = Object.keys(PIECES_MAP);
        if (possibleFigures.indexOf(s) == -1) {
            return undefined;
        }
        return s.slice(0, 1);
    },

    isSubArray(firstArr, secondArr) { // checking if the first array is sub array of the second one
        let f = true;
        if (firstArr == undefined || secondArr == undefined) {
            return false;
        }
        if (firstArr.length > secondArr) {
            return firstArr;
        }
        for (let i in firstArr) {
            if (firstArr[i] != secondArr[i]) {
                f = false;
            }
        }
        return f;
    },

    getFenFromPgn(pgn) {
        let chess = new Chess();
        chess.load_pgn(pgn);
        return chess.fen();
    },

    getInitialFenFromPgn(pgn) {
        let re = /\[FEN \"(.*)\"\]/g;
        try {
            let match = re.exec(pgn);
            return match[1];
        } catch (e) {

        }
        return START_FEN;
    },

    findMovePathByHistory(moves, history) {
        console.log('findMovePathByHistory occured! moves, history = ', moves, history);
        let allFlatMoves = this.makeMovesFlat(moves, [], [], 0);
        console.log('allFlatMoves = ', allFlatMoves);
        let flMoves = allFlatMoves.filter(a => (a.length >= history.length));
        let res = [];
        console.log('flMoves = ', flMoves);
        for (let i in flMoves) {
            let moves_ = flMoves[i];
            let f = true, arr = [];
            for (let j in history) {
                if (f == false) {
                    break;
                }
                if (history[j].san == moves_[j].move) {
                    arr.push(moves_[j]);
                } else {
                    f = false;
                }
            }
            if (f == true) {
                res = arr;
                break;
            }
        }
        console.log('-->> res = ', res);
        return res;
    },

    makeMovesFlat(moves = [], prevMoves = [], currentRegistry = [], level = 0) {
        console.log('makeMovesFlat: level = ', level);
        currentRegistry.push(moves);
        for (let i in moves) {
            let mv = moves[i];
            let {move, ravs = []} = mv;
            let currMoves = prevMoves.concat(moves.slice(0, +i + 1));
            for (let j in ravs) {
                let rv = ravs[j];
                this.makeMovesFlat(rv.moves, currMoves, currentRegistry, +level + 1)
            }
        }
        if (level == 0) {
            return currentRegistry;
        }
    },

    async generateWordId(number = 2) {
        let words = (await axios.get(`${API_ENDPOINT}/random_words?number=${number}`)).data;
        let s = `${(number == 1 ? words : words.join('-'))}-${moment().format('DD-MM-YYYY-HH-mm-ss')}`;
        return s;
    },

    getBoardInfoByFen(fen) {
        let ch = new Chess(fen);
        return {
            inDraw: ch.in_draw(),
            inCheckmate: ch.in_checkmate(),
            inCheck: ch.in_check(),
            inStalemate: ch.in_stalemate(),
            gameOver: ch.game_over(),
        }
    },

    saveBoardFenToLocalHistory(newFen, boardId, shouldIgnore = false) {
        if (shouldIgnore == true) {
            return true;
        }
        let key = `hist_bo_${boardId}`;
        let arr = ls(key);
        if (arr == undefined) {
            ls(key, []);
            arr = [];
        }
        let lastFen = (arr.length == 0) ? undefined : arr[arr.length - 1].fen;
        if (lastFen == newFen) {
            return false;
        }
        arr = arr.concat([{t: +new Date(), fen: newFen}]);
        ls(key, arr);
        return true;
    },

    flushBoardFenLocalHistory(boardId) {
        let key = `hist_bo_${boardId}`;
        ls(key, []);
    },

    getFensHistoryFromLocalStorage(boardId) {
        let key = `hist_bo_${boardId}`;
        let arr = ls(key);
        if (arr == undefined) {
            ls(key, []);
            arr = [];
        }
        return arr;
    }


}

export default ChessHelper;
