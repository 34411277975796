import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const ExercisesAPI = {

    getAllExercisesGroups() {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/exercises_groups/all`).then(d => d.data).then(exercises => {
                if (exercises.error != undefined) {
                    return reject(exercises.error);
                }
                resolve(exercises);
            }).catch(err => reject(err));
        });
    },

    createExercisesGroup(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/exercises_groups/create`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    updateExercisesGroup(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/exercises_groups/update`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    getExercisesGroup(id) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/exercises_group/${id}`).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    deleteExercisesGroup(id) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/exercises_groups/delete/${id}`).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

}

export default ExercisesAPI;
