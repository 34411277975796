import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import {useDispatch, useMappedState} from 'redux-react-hook'

import * as coursesActions from '../../../redux/actions/CoursesActions'

import {Code} from 'react-content-loader'
import CommonHelper from "../../../helpers/CommonHelper";

import {Button} from "../../ui/Button";

function getSortedCourses(arr) {
    let gs = x => (x == undefined ? '' : x.toLowerCase());
    return arr.sort((a, b) => {
        let s1 = gs(a.name);
        let s2 = gs(b.name);
        if (s1 > s2) {
            return 1;
        }
        if (s1 < s2) {
            return -1;
        }
        return 0;
    })
}

export default function AllCoursesPanel() {

    const {loading, courses, currentUser, isAdmin} = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let isAdmin = ((currentUser != undefined) && (currentUser.userRole == 'admin' || currentUser.userRole == 'methodist'));
        return {
            loading: state.courses.loading,
            courses: state.courses.coursesMap.toArray().sort((a, b) => (+b.timestamp - +a.timestamp)),
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            isAdmin: isAdmin
        }
    }, []));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(coursesActions.loadAllCourses());
    }, []);

    if (courses.length == 0 && loading == true) {
        return (
            <Code/>
        )
    }

    let sortedCourses = getSortedCourses(courses);

    return (
        <Wrapper>

            <CoursesList>
                {sortedCourses.map((c, k) => {
                    let {name, description} = c;
                    return (
                        <CourseItem key={c.id} onClick={() => {
                            CommonHelper.linkTo(`/course/${c.id}`);
                        }}>

                            <Name>
                                {name}
                            </Name>

                            <Description>
                                {description}
                            </Description>

                        </CourseItem>
                    )
                })}
            </CoursesList>

            {isAdmin == false ? null :
                <BottomPlaceholder>
                    <Button onClick={() => {
                        dispatch(coursesActions.createCourse({
                            creatorId: currentUser.id,
                            name: `Новый курс (${moment().format('DD.MM.YYYY HH:mm:ss')})`,
                            description: `Без описания`
                        })).then(pld => {
                            if (pld.course != undefined) {
                                CommonHelper.linkTo(`/course/${pld.course.id}`);
                            }
                        })
                    }}>
                        Создать новый курс
                    </Button>
                </BottomPlaceholder>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const CoursesList = styled.div`

`;

const CourseItem = styled.div`
  padding: 10px;
  border: 1px solid whitesmoke;
  border-radius: 4px;
  margin-bottom: 10px;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const Name = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
`;

const Description = styled.div`

`;

const BottomPlaceholder = styled.div`
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid whitesmoke;
`;
