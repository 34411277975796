import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'
import CommonHelper from "../../../helpers/CommonHelper";

import {Button} from "../../ui/Button";

import NameDescriptionForm from "../../common/forms/NameDescriptionForm";

import * as tournamentsActions from '../../../redux/actions/TournamentsActions'

export default function UserTournamentsPanel(props) {
    let {userId} = props;
    const [addVisible, setAddVisible] = useState(false);
    const dispatch = useDispatch();
    let {currentUser, loading, currentUserId, tournaments, uId} = useMappedState(useCallback(state => {
        let currentUserId = state.users.currentUserId;
        let uId = (userId == undefined) ? currentUserId : userId;
        let tournaments = state.tournaments.tournamentsMap.toArray()
            .filter(g => (g.creatorId == uId))
            .sort((a, b) => (+b.timestamp - +a.timestamp));
        return {
            currentUser: state.users.usersMap.get(currentUserId),
            loading: (state.users.loading == true) || (state.tournaments.loading == true),
            tournaments: tournaments,
            uId: uId
        }
    }, []));

    useEffect(() => {
        dispatch(tournamentsActions.loadAllTournaments());
    }, []);

    if (loading == true && tournaments.length == 0) {
        return (
            <Code/>
        )
    }

    return (
        <Wrapper>

            <TopPlaceholder>
                <Button onClick={() => {
                    setAddVisible(true);
                }}>
                    Добавить турнир
                </Button>
            </TopPlaceholder>

            <TournamentsList>
                {tournaments.map((tour, k) => {
                    return (
                        <TournamentItem key={`${tour.id}`} onClick={() => {
                            CommonHelper.linkTo(`/tournament/${tour.id}`);
                        }}>
                            <Name>
                                {tour.name}
                            </Name>
                            {(tour.description == undefined || tour.description.trim() == '') ? null :
                                <Description>
                                    {tour.description}
                                </Description>
                            }
                        </TournamentItem>
                    )
                })}
            </TournamentsList>

            <Sidebar visible={addVisible} onCloserClick={() => {
                setAddVisible(false);
            }}>

                {addVisible == false ? null :
                    <div>
                        <NameDescriptionForm onSubmit={async d => {
                            let data = {
                                ...d,
                                creatorId: uId
                            };
                            await dispatch(tournamentsActions.createTournament(data));
                            setAddVisible(false);
                        }}/>
                    </div>
                }

            </Sidebar>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const TopPlaceholder = styled.div`
    margin-bottom: 10px;
`;

const TournamentsList = styled.div`
    
`;

const TournamentItem = styled.div`
    margin-bottom: 10px;
    border-radius: 4px;
    background: white;
    padding: 10px;
    cursor: pointer;
    opacity: 1;
    :hover{
      opacity: 0.75;
    }
`;

const Name = styled.div`
    font-weight: bold;
`;

const Description = styled.div`
    
`;
