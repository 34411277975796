import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import ChessHelper from "../../../helpers/ChessHelper";
import InlinePiece from "./InlinePiece";


export default function InlineMove(props) {
    let {move, size = 20} = props;
    let figureString = ChessHelper.getFigureStringByMoveString(move);
    let spareString = (figureString == undefined) ? move : move.slice(1);

    return (
        <Wrapper style={{height: size, lineHeight: `${size}px`}}>

            {figureString == undefined ? null :
                <PieceSpan style={{marginRight: 0}}>
                    <InlinePiece piece={figureString} size={size}/>
                </PieceSpan>
            }

            <SpareSpan>
                {spareString}
            </SpareSpan>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: inline-flex;
    flex-direction: row;
    align-items: center;
`;

const PieceSpan = styled.span`
    font-weight: bold;
`;

const SpareSpan = styled.span`
    font-weight: bold;
`;
