import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';
import {useDispatch, useMappedState} from 'redux-react-hook'

import IraTeacherTemplate from "../templates/IraTeacherTemplate";
import StudentCoursesPanel from "../courses/panels/StudentCoursesPanel";
import {translate} from "../../utils/Translate";

const TABS = [
    {
        label: translate('my_courses'),
        value: 'my_courses'
    },
    // {
    //     label: 'Курсы',
    //     value: 'courses',
    // },
    // {
    //     label: 'Все курсы',
    //     value: 'all_modules',
    // }
]

export default function StudentCoursesApp(props) {
    const dispatch = useDispatch();
    let {mode} = props.match.params;
    const [tab, setTab] = useState(mode == undefined ? 'my_courses' : mode);
    const {currentUserId} = useMappedState(useCallback(state => ({
        currentUserId: state.users.currentUserId,
        currentUser: state.users.usersMap.get(state.users.currentUserId)
    }), []));

    return (
        <IraTeacherTemplate active={'courses'}>

            <HorHeading>
                <TopLeft>
                    {TABS.map((a, i) => {
                        return (
                            <TabItem selected={(tab == a.value)} onClick={() => {
                                setTab(a.value);
                            }}>
                                {a.label}
                            </TabItem>
                        )
                    })}
                </TopLeft>
                <TopRight>

                </TopRight>
            </HorHeading>

            <Wrapper>
                <StudentCoursesPanel/>
            </Wrapper>

        </IraTeacherTemplate>
    );
}


const Wrapper = styled.div`
  padding: 40px;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
`;

const topHeight = 70;

const HorHeading = styled.div`
  width: 100%;
  height: ${topHeight}px;
  background: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding-left: 40px;
  padding-right: 40px;
`;

const TopLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TopRight = styled.div`

`;

const TabItem = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${props => (props.selected ? '#085BFF' : '#8593A8')};
  font-weight: ${props => (props.selected ? 'bold' : 'normal')};
  cursor: ${props => (props.selected ? 'default' : 'pointer')};
  margin-right: 40px;

  :hover {
    color: #085BFF;
  }
`;
//
// const Wrapper = styled.div`
//   padding-top: 31px;
//   padding-left: 65px;
//   padding-right: 65px;
//   box-sizing: border-box;
//   @media (max-width: 1300px) {
//     padding-left: 15px;
//     padding-right: 15px;
//   }
// `;
