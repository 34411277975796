import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';
import {useDispatch, useMappedState} from 'redux-react-hook'

import HorChessTemplate from '../templates/HorChessTemplate'
import StudentPersonalPanel from "../students/panels/StudentPersonalPanel";
import IraUserLessonsPanel from "../lessons/panels/IraUserLessonsPanel";
import IraTeacherTemplate from "../templates/IraTeacherTemplate";
import StudentGroupsPanel from "../teacher_groups/panels/StudentGroupsPanel";

export default function StudentGroupsApp(props) {
    const dispatch = useDispatch();
    const {currentUserId} = useMappedState(useCallback(state => ({
        currentUserId: state.users.currentUserId,
        currentUser: state.users.usersMap.get(state.users.currentUserId)
    }), []));

    return (
        <IraTeacherTemplate active={'groups'}>

            {/*<StudentPersonalPanel id={currentUserId} />*/}

            <Wrapper>
                <StudentGroupsPanel/>
            </Wrapper>

        </IraTeacherTemplate>
    );
}

const Wrapper = styled.div`
    padding-top: 31px;
    padding-left: 65px;
    padding-right: 65px;
    box-sizing: border-box;
    @media(max-width: 1300px){
      padding-left: 15px;
      padding-right: 15px;
    }
`;
