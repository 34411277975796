import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import ChessHelper from "../../../helpers/ChessHelper";
import SabirChessBoard from "../../chessboard/tools/SabirChessBoard";
import SmartItem from "./SmartItem";

import {Map, Set} from 'immutable'

import {useKeyPress} from "../../../helpers/HooksHelper";

const COLORS_MAP = {
    lose: 'salmon',
    win: 'lightgreen',
    draw: 'whitesmoke'
};

const getNumbersBySolutions = sols => {
    let nums = [];
    let st = Set();
    for (let i in sols) {
        let {number = 0} = sols[i];
        if (!st.has(number)) {
            st = st.add(number);
            nums.push(number);
        }
    }
    return nums.sort((a, b) => (+a - +b));
}

export default function SmartSolutionsList(props) {
    let {
        solutions = [],
        onSolutionClick = solId => {

        },
        selectedId = undefined,
        onMove = () => {

        },
        onItemDelete = itemId => {

        },
        selectedNumber = undefined,
        onNumberSelect = (num, sol) => {

        },
        onAdd = number => {

        },
        onSave = updSol => {

        },
        loading = false
    } = props;

    let numbers = getNumbersBySolutions(solutions);
    let selectedSolution = (selectedId == undefined) ? null : solutions.filter(s => (s.id == selectedId))[0];
    // const leftPress = useKeyPress('q');
    // const rightPress = useKeyPress('w');
    // useEffect(() => {
    //     console.log('some things pressed');
    // }, [leftPress, rightPress]);

    function downHandler({key, keyCode}) {
        if (keyCode == '37') { // left
            console.log('back');
            // onMove(selectedNumber)
        }
        if (keyCode == '39') { // right
            console.log('forward');
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', downHandler);
        // Remove event listeners on cleanup
        return () => {
            window.removeEventListener('keydown', downHandler);
        };
    }, []);


    return (
        <Wrapper>

            {numbers.map((num, k) => {
                let sols = solutions.filter(a => (+(a.number == undefined ? 0 : a.number) == +num));
                let isSelected = (selectedNumber == num);
                let fSol = (sols.length == 0) ? undefined : sols[0];
                let smrtSelSol = (selectedSolution == undefined || isSelected == false) ? fSol : selectedSolution;
                return (
                    <SupNumItem key={`${k}_${fSol == undefined ? 'aa' : fSol.id}`} selected={isSelected}>
                        {smrtSelSol == undefined ? null :
                            <div style={{
                                marginBottom: 5,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}>
                                <SupInfo>
                                    <Name>
                                        {`${+k + 1}) ${(smrtSelSol.name == undefined ? '-' : smrtSelSol.name)}`}
                                    </Name>
                                    <Description>
                                        {smrtSelSol.description}
                                    </Description>
                                </SupInfo>
                                {isSelected == false ? null :
                                    <div>
                                        {+k < numbers.length - 1 ?
                                            <ArrowSpan style={{padding: 3}} onClick={() => {
                                                let i1 = k, i2 = k + 1;
                                                onMove(num, i1, i2);
                                            }}>
                                                <ArrowImg src={require('../../../assets/images/up-arrow.svg')}
                                                          style={{transform: `rotate(180deg)`}}/>
                                            </ArrowSpan> : null
                                        }
                                        {+k == 0 ? null :
                                            <ArrowSpan style={{padding: 3}} onClick={() => {
                                                let i1 = k - 1, i2 = k;
                                                onMove(num, i1, i2);
                                            }}>
                                                <ArrowImg src={require('../../../assets/images/up-arrow.svg')}
                                                          style={{transform: `rotate(0deg)`}}/>
                                            </ArrowSpan>
                                        }
                                    </div>
                                }
                            </div>
                        }

                        <NumItem selected={isSelected} onClick={() => {
                            if (num != selectedNumber) {
                                onNumberSelect(num, sols.length > 0 ? sols[0] : undefined);
                            }
                        }}>
                            {isSelected == false ? null :
                                <SmartItem solutions={sols}
                                           loading={loading}
                                           selectedId={selectedId}
                                           number={num}
                                           onSolutionSelect={(solId, sol) => {
                                               onSolutionClick(solId, sol);
                                           }}
                                           onItemDelete={sId => {
                                               onItemDelete(sId, num);
                                           }}
                                           onAdd={() => {
                                               onAdd(+k);
                                           }}
                                           onSave={updSol => {
                                               onSave(updSol);
                                           }}
                                />
                            }

                            {(isSelected == true || fSol == undefined) ? null :
                                <NumInner>
                                    <FenImg src={ChessHelper.getFenImageUrl(fSol.fen)}/>
                                    <NumInfo>
                                        <Name>
                                            {fSol.name}
                                        </Name>
                                        <Description>
                                            {fSol.description}
                                        </Description>
                                    </NumInfo>
                                </NumInner>
                            }

                        </NumItem>
                    </SupNumItem>

                )
            })}

        </Wrapper>
    );
}

const Wrapper = styled.div`
    padding: 5px;
`;

const Item = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background: white;
    margin-bottom: 10px;
    box-sizing: border-box;
    padding: 10px;
    background: ${props => (props.selected == true ? 'blue' : 'white')};
    color: ${props => (props.selected == true ? 'white' : 'black')};
    border-radius: 4px;
`;

const Fen = styled.div`
    width: 110px;
    height: 110px;
    cursor: pointer;
`;

const Ello = styled.div`
    width: 70px;
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    :hover{
      opacity: 0.75;
    }
`;

const Info = styled.div`
    flex: 1;
    cursor: pointer;
    :hover{
      opacity: 0.75;
    }
`;


const Name = styled.div`
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
`;

const Description = styled.div`
    opacity: 0.7;
    font-size: 10px;
`;

const ControlsPlaceholder = styled.div`
    width: 80px;
    text-align: center;
    padding: 10px;
`;


const NumItem = styled.div`
    height: ${props => (props.selected == true ? 235 : 140)}px;
`;

const FenImg = styled.img`
    width: 120px;
    height: 120px;
    :hover{
      opacity: 0.75;
    }
`;

const NumInner = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
`;

const NumInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    box-sizing: border-box;  
    flex: 1;
`;

const SupNumItem = styled.div`
    margin-bottom: 10px;
    cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
    width: 100%;
    border: ${props => (props.selected == true ? '2px solid #293742' : '2px solid whitesmoke')};
    border-radius: 4px;
    padding: 4px;
    :hover{
      opacity: ${props => (props.selected == true ? 1 : 0.7)};
    }
`;

const SupInfo = styled.div`
    
`;


const ArrowSpan = styled.span`
    padding: 3px;
    cursor: pointer;
    :hover{
      opacity: 0.7;
    }
`;

const ArrowImg = styled.img`
    width: 14px;
    height: 14px;
    margin: 3px;
`;
